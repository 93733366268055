import {
  Locale,
  defaultLocale,
  loc_checkAllThatApply,
  loc_create,
  loc_requiredParens,
  loc_save,
  loc_selectOnlyOne
} from '@cumu/strings';
import { groupBy } from '../array';
import { checkIcon, icon, xIcon } from '../icons';
import {
  loginMaxLength,
  passwordMaxLength,
  passwordMinLength
} from '../login-regex';
import { TemplateResult, attr, booleanAttr, html } from '../template';
import { dateInputTemplate } from './date-input';
import { newElementId } from './element-id';

type FormGroupConfigBase = {
  locale?: Locale;
  name: string;
  label: string | TemplateResult;
  classes?: string;
  headerClasses?: string;
  required?: boolean;
  description?: string | TemplateResult;
  attrs?: unknown[];
  disabled?: boolean;
  id?: string;
  form?: string;
  autofocus?: boolean;
  small?: boolean;
  target?: string;
  action?: string;
  inputAttrs?: unknown[];
};

export type FormGroupConfig =
  | ({
      type: 'name' | 'text';
      maxLength: number;
      minLength?: number;
      value?: string;
      spellcheck?: boolean;
      autocomplete?: string;
      autocompleteList?: TemplateResult;
      inputClasses?: string;
      inputWrapper?: (content: TemplateResult) => TemplateResult;
    } & FormGroupConfigBase)
  | ({
      type: 'date';
      value?: Date | null;
      relative?: boolean;
      parametric?: boolean;
    } & FormGroupConfigBase)
  | ({
      type: 'time';
      value?: string | null;
    } & FormGroupConfigBase)
  | ({
      type: 'email';
      value?: string;
      autocomplete?: string;
      maxLength?: number;
    } & FormGroupConfigBase)
  | ({
      type: 'url';
      https?: boolean;
      value?: string;
    } & FormGroupConfigBase)
  | ({
      type: 'tel';
      value?: string;
      autocomplete?: string;
    } & FormGroupConfigBase)
  | ({
      type: 'zip';
      value?: string;
      autocomplete?: string;
    } & FormGroupConfigBase)
  | ({
      type: 'numeric-code';
      minLength?: number;
      maxLength: number;
      value?: string;
    } & FormGroupConfigBase)
  | ({
      type: 'login';
      maxLength?: number;
      value?: string;
    } & FormGroupConfigBase)
  | ({
      type: 'password';
    } & FormGroupConfigBase)
  | ({
      type: 'textarea';
      maxLength: number;
      minLength?: number;
      value?: string;
      rows?: number;
      placeholder?: string;
      spellcheck?: boolean;
    } & FormGroupConfigBase)
  | ({
      type: 'select';
      options: {
        value: string;
        text: string;
        disabled?: boolean;
        group?: string;
      }[];
      value: string;
    } & FormGroupConfigBase)
  | ({
      type: 'rich-select';
      options: {
        value: string;
        text: string | TemplateResult;
        description?: string;
        disabled?: boolean;
      }[];
      value: string;
      summaryId?: string;
    } & FormGroupConfigBase)
  | ({
      type: 'remote-rich-select';
      src: string;
      value: TemplateResult;
      menuAction?: string;
      footerContent?: TemplateResult;
    } & FormGroupConfigBase)
  | ({
      type: 'integer' | 'currency';
      min?: number;
      max?: number;
      value?: number;
    } & FormGroupConfigBase)
  | ({
      type: 'number';
      min?: number;
      max?: number;
      minimumFractionDigits: number;
      maximumFractionDigits: number;
      value?: number;
    } & FormGroupConfigBase);

export function requiredAsterisk(locale: Locale) {
  return html`&nbsp;<abbr
      class="form-group-required-asterisk"
      title="${loc_requiredParens[locale]}"
      >*</abbr
    >`;
}

export function formGroupTemplate(config: FormGroupConfig) {
  let {
    locale = defaultLocale,
    name,
    label,
    required = false,
    classes = '',
    headerClasses = '',
    description,
    attrs,
    disabled = false,
    id = newElementId(),
    form,
    small = false,
    target,
    action,
    inputAttrs,
    autofocus = false
  } = config;
  const descriptionId = description ? newElementId() : '';
  const describedBy = descriptionId;
  const desc = description
    ? html`<p class="note" id="${descriptionId}">${description}</p>`
    : null;
  let input: TemplateResult;
  switch (config.type) {
    case 'name':
    case 'text': {
      const {
        value = '',
        spellcheck = true,
        maxLength,
        minLength,
        autocomplete = 'off',
        autocompleteList,
        inputClasses,
        inputWrapper = (content: TemplateResult) => content
      } = config;
      input = inputWrapper(
        html`<input
            class="form-control${small ? ' input-sm' : ''}${inputClasses
              ? ` ${inputClasses}`
              : ''}"
            id="${id}"
            name="${name}"
            type="text"
            value="${value}"
            spellcheck="${spellcheck}"
            autocomplete="${autocomplete}"
            autocapitalize="${config.type === 'name' ? 'words' : 'sentences'}"
            maxlength="${maxLength}"
            ${attr('minlength', minLength?.toString())}
            ${booleanAttr('required', required)}
            ${booleanAttr('disabled', disabled)}
            ${attr('form', form)}
            ${booleanAttr('autofocus', autofocus)}
            ${attr('aria-describedby', describedBy)}
            ${attr('data-target', target)}
            ${attr('data-action', action)}
            ${inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
          />${autocompleteList}`
      );
      break;
    }
    case 'email': {
      const { value = '', autocomplete = 'none', maxLength = 256 } = config;
      input = html`<input
        class="form-control${small ? ' input-sm' : ''}"
        id="${id}"
        name="${name}"
        type="email"
        value="${value}"
        autocomplete="${autocomplete}"
        minlength="4"
        maxlength="${maxLength}"
        ${booleanAttr('required', required)}
        ${booleanAttr('disabled', disabled)}
        ${attr('form', form)}
        ${booleanAttr('autofocus', autofocus)}
        ${attr('aria-describedby', describedBy)}
        ${attr('data-target', target)}
        ${attr('data-action', action)}
        ${inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
      />`;
      break;
    }
    case 'url': {
      const { value = '', https = true } = config;
      input = html`<input
        class="form-control${small ? ' input-sm' : ''}"
        id="${id}"
        name="${name}"
        type="url"
        value="${value}"
        autocomplete="none"
        pattern="${https ? 'https' : 'http(s)?'}://.*"
        minlength="14"
        maxlength="256"
        ${booleanAttr('required', required)}
        ${booleanAttr('disabled', disabled)}
        ${attr('form', form)}
        ${booleanAttr('autofocus', autofocus)}
        ${attr('aria-describedby', describedBy)}
        ${attr('data-target', target)}
        ${attr('data-action', action)}
        ${inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
      />`;
      break;
    }
    case 'login': {
      const { value = '', maxLength = loginMaxLength } = config;
      const autocomplete = 'none';
      input = html`<input
        class="form-control${small ? ' input-sm' : ''}"
        id="${id}"
        name="${name}"
        type="text"
        value="${value}"
        autocomplete="${autocomplete}"
        maxlength="${maxLength}"
        validate-login
        ${booleanAttr('required', required)}
        ${booleanAttr('disabled', disabled)}
        ${attr('form', form)}
        ${booleanAttr('autofocus', autofocus)}
        ${attr('aria-describedby', describedBy)}
        ${attr('data-target', target)}
        ${attr('data-action', action)}
        ${inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
      />`;
      break;
    }
    case 'password': {
      const autocomplete = 'new-password';
      input = html`<input
        class="form-control${small ? ' input-sm' : ''}"
        id="${id}"
        name="${name}"
        type="password"
        autocomplete="${autocomplete}"
        minlength="${passwordMinLength}"
        maxlength="${passwordMaxLength}"
        ${booleanAttr('required', required)}
        ${booleanAttr('disabled', disabled)}
        ${attr('form', form)}
        ${booleanAttr('autofocus', autofocus)}
        ${attr('aria-describedby', describedBy)}
        ${attr('data-target', target)}
        ${attr('data-action', action)}
        ${inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
      />`;
      break;
    }
    case 'textarea': {
      const {
        value = '',
        spellcheck = true,
        maxLength,
        minLength,
        rows = Math.floor(maxLength / 125),
        placeholder
      } = config;
      input = html`<textarea
        class="form-control${small ? ' input-sm' : ''}"
        id="${id}"
        name="${name}"
        autocapitalize="sentences"
        autocomplete="off"
        maxlength="${maxLength}"
        spellcheck="${spellcheck}"
        ${attr('minlength', minLength?.toString())}
        rows="${rows}"
        ${booleanAttr('required', required)}
        ${booleanAttr('disabled', disabled)}
        ${attr('form', form)}
        ${booleanAttr('autofocus', autofocus)}
        ${attr('aria-describedby', describedBy)}
        ${attr('placeholder', placeholder)}
        ${attr('data-target', target)}
        ${attr('data-action', action)}
        ${inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
      >
${value}</textarea
      >`;
      break;
    }
    case 'select': {
      const { value = '', options } = config;
      const opts = groupedSelectOptionsTemplate(options, value);
      if (options.length === 0) {
        classes += ' form-group--empty-select';
        opts.unshift(selectOptionTemplate('', '', '', false));
      } else if (!required && !options.find(x => x.value === '')) {
        opts.unshift(selectOptionTemplate('', '', value, false));
      }
      input = html`<select
        class="form-select${small ? ' select-sm' : ''}"
        id="${id}"
        name="${name}"
        ${booleanAttr('required', required)}
        ${booleanAttr('disabled', disabled)}
        ${attr('form', form)}
        ${booleanAttr('autofocus', autofocus)}
        ${attr('aria-describedby', describedBy)}
        ${attr('data-target', target)}
        ${attr('data-action', action)}
        ${inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
      >
        ${opts}
      </select>`;
      break;
    }
    case 'rich-select': {
      const { value = '', options, small, summaryId } = config;
      const opts = Array.from(options);
      const none = html`<span class="color-fg-muted text-italic">None</span>`;
      if (opts.length === 0) {
        classes += ' form-group--empty-select';
        opts.unshift({
          value: '',
          text: none,
          disabled
        });
      } else if (!required && !opts.find(x => x.value === '')) {
        opts.unshift({ value: '', text: none, disabled });
      }
      const cur = opts.find(o => o.value === value);
      if (!cur) {
        throw new Error(
          `Option with value matching ${JSON.stringify(value)} not found.`
        );
      }
      const summaryContent = html`<span class="sr-only">${label}:</span>
        <span class="flex-1" data-menu-button style="min-width: 0"
          >${cur.text}</span
        >
        <span class="dropdown-caret"></span>`;
      if (disabled) {
        input = html`<span
          class="form-control${small
            ? ' input-sm'
            : ''} d-flex flex-items-center gap-2"
          disabled
          >${summaryContent}</span
        >`;
      } else {
        const filterTargetId = newElementId();
        input = html`<details
          class="details-reset details-overlay position-relative"
          id="${id}"
        >
          <summary
            class="form-control${small
              ? ' input-sm'
              : ''} d-flex flex-items-center gap-2"
            ${attr('id', summaryId)}
            style="scroll-margin-top: 60px"
            ${booleanAttr('autofocus', autofocus)}
            ${attr('aria-describedby', describedBy)}
            ${attr('data-target', target)}
            ${attr('data-action', action)}
          >
            ${summaryContent}
          </summary>
          <details-menu
            class="SelectMenu ${opts.length > 6
              ? 'SelectMenu--hasFilter'
              : ''} min-width-full"
            role="menu"
          >
            <div class="SelectMenu-modal min-width-full">
              <header class="SelectMenu-header">
                <h3 class="SelectMenu-title">Select ${label}</h3>
                <button
                  class="SelectMenu-closeButton"
                  type="button"
                  data-toggle-for="${id}"
                  aria-label="Close menu"
                >
                  ${icon(xIcon)}
                </button>
              </header>
              <filter-input
                class="SelectMenu-filter"
                aria-owns="${filterTargetId}"
                ${booleanAttr('hidden', opts.length <= 6)}
              >
                <input
                  class="SelectMenu-input form-control"
                  type="text"
                  autofocus
                  autocomplete="off"
                  placeholder="Filter"
                  aria-label="Filter"
                  form="undefined-form"
                />
              </filter-input>
              <div
                class="SelectMenu-list"
                id="${filterTargetId}"
                data-filter-list
              >
                ${opts.map(
                  o =>
                    html`<label
                      class="SelectMenu-item"
                      role="menuitemradio"
                      aria-checked="${o.value === value}"
                      tabindex="0"
                    >
                      ${icon(
                        checkIcon,
                        'SelectMenu-icon SelectMenu-icon--check f4'
                      )}
                      <input
                        class="sr-only"
                        type="radio"
                        name="${name}"
                        value="${o.value}"
                        tabindex="-1"
                        ${booleanAttr('checked', o.value === value)}
                        ${booleanAttr('required', required)}
                        ${booleanAttr('disabled', disabled)}
                        ${attr('form', form)}
                        ${inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
                      />
                      ${o.description
                        ? html`<span
                            ><span
                              data-menu-button-contents
                              style="min-width: 0"
                              >${o.text}</span
                            ><span
                              class="text-normal color-fg-muted f6 d-block"
                              style="min-width: 0"
                              >${o.description}</span
                            ></span
                          >`
                        : html`<span
                            data-menu-button-contents
                            style="min-width: 0"
                            >${o.text}</span
                          >`}
                    </label>`
                )}
              </div>
            </div>
          </details-menu>
        </details>`;
      }
      break;
    }
    case 'remote-rich-select': {
      const { value, src, small, menuAction, footerContent } = config;
      const summaryContent = html`<span class="sr-only">${label}:</span>
        <span class="flex-1" data-menu-button style="min-width: 0"
          >${value}</span
        >
        <span class="dropdown-caret"></span>`;
      if (disabled) {
        input = html`<span
          class="form-control${small
            ? ' input-sm'
            : ''} d-flex flex-items-center gap-2"
          disabled
          >${summaryContent}</span
        >`;
      } else {
        const filterTargetId = newElementId();
        input = html`<details
          class="details-reset details-overlay position-relative"
          id="${id}"
          ${attr('data-target', target)}
          ${attr('data-action', action)}
        >
          <summary
            class="form-control${small
              ? ' input-sm'
              : ''} d-flex flex-items-center gap-2"
            ${booleanAttr('autofocus', autofocus)}
            ${attr('aria-describedby', describedBy)}
          >
            ${summaryContent}
          </summary>
          <details-menu
            class="SelectMenu SelectMenu--hasFilter min-width-full"
            role="menu"
            ${attr('data-action', menuAction)}
          >
            <div class="SelectMenu-modal min-width-full">
              <header class="SelectMenu-header">
                <h3 class="SelectMenu-title">Select ${label}</h3>
                <button
                  class="SelectMenu-closeButton"
                  type="button"
                  data-toggle-for="${id}"
                  aria-label="Close menu"
                >
                  ${icon(xIcon)}
                </button>
              </header>
              <remote-input
                class="SelectMenu-filter"
                aria-owns="${filterTargetId}"
                src="${src}"
              >
                <input
                  class="SelectMenu-input form-control"
                  type="text"
                  autofocus
                  autocomplete="off"
                  placeholder="Search..."
                  aria-label="Search"
                  maxlength="100"
                  form="undefined-form"
                />
              </remote-input>
              <div class="SelectMenu-list" id="${filterTargetId}"></div>
              ${footerContent
                ? html`<footer class="SelectMenu-footer">
                    ${footerContent}
                  </footer>`
                : null}
            </div>
          </details-menu>
        </details>`;
      }
      break;
    }
    case 'date': {
      const { value = null, relative = false, parametric = false } = config;
      input = html`<date-input
        class="d-flex position-relative"
        ${booleanAttr('relative', relative)}
        ${booleanAttr('parametric', parametric)}
        >${dateInputTemplate({
          id,
          name,
          value,
          required,
          disabled,
          autofocus,
          form,
          small,
          target,
          action,
          inputAttrs
        })}</date-input
      >`;
      break;
    }
    case 'time': {
      const { value = '' } = config;
      input = html`<input
        class="form-control${small ? ' input-sm' : ''}"
        id="${id}"
        name="${name}"
        type="time"
        autocomplete="none"
        value="${value}"
        ${booleanAttr('required', required)}
        ${booleanAttr('disabled', disabled)}
        ${attr('form', form)}
        ${booleanAttr('autofocus', autofocus)}
        ${attr('aria-describedby', describedBy)}
        ${attr('data-target', target)}
        ${attr('data-action', action)}
        ${inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
      />`;
      break;
    }
    case 'integer':
    case 'currency':
    case 'number': {
      const { value, min, max } = config;
      const minimumFractionDigits =
        config.type === 'number'
          ? config.minimumFractionDigits
          : config.type === 'integer'
            ? 0
            : 2;
      const maximumFractionDigits =
        config.type === 'number'
          ? config.maximumFractionDigits
          : config.type === 'integer'
            ? 0
            : 2;
      input = html`<input
        class="form-control${small ? ' input-sm' : ''}${config.type ===
        'currency'
          ? ' form-control-currency'
          : ''}"
        id="${id}"
        name="${name}"
        type="number"
        autocomplete="none"
        value="${value === undefined
          ? ''
          : config.type === 'integer'
            ? value
            : new Intl.NumberFormat('en-us', {
                useGrouping: false,
                minimumFractionDigits,
                maximumFractionDigits,
                roundingMode: 'floor'
              }).format(value)}"
        step="${config.type === 'integer' ? '1' : 'any'}"
        ${attr('min', min?.toString())}
        ${attr('max', max?.toString())}
        ${booleanAttr('required', required)}
        ${booleanAttr('disabled', disabled)}
        ${attr('form', form)}
        ${booleanAttr('autofocus', autofocus)}
        ${attr('aria-describedby', describedBy)}
        ${attr('data-target', target)}
        ${attr('data-action', action)}
        ${inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
      />`;
      if (config.type === 'currency' || config.type === 'number') {
        input = html`<number-input
          minimum-fraction-digits="${minimumFractionDigits}"
          maximum-fraction-digits="${maximumFractionDigits}"
          ${attr(
            'class',
            config.type === 'currency' ? 'form-control-symbol-container' : null
          )}
          >${input}${config.type === 'currency'
            ? html`<span class="form-control-symbol" aria-hidden="true"
                >$</span
              >`
            : null}</number-input
        >`;
      }
      break;
    }
    case 'tel': {
      const { value = '', autocomplete = 'none' } = config;
      input = html`<mask-input pattern="(###) ###-####">
        <input
          class="form-control${small ? ' input-sm' : ''}"
          id="${id}"
          name="${name}"
          type="tel"
          autocomplete="${autocomplete}"
          value="${value}"
          maxlength="14"
          pattern="^\\(\\d{3}\\) \\d{3}-\\d{4}$"
          ${booleanAttr('required', required)}
          ${booleanAttr('disabled', disabled)}
          ${attr('form', form)}
          ${booleanAttr('autofocus', autofocus)}
          ${attr('aria-describedby', describedBy)}
          ${attr('data-target', target)}
          ${attr('data-action', action)}
          ${inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
        />
      </mask-input>`;
      break;
    }
    case 'zip': {
      const { value = '', autocomplete = 'none' } = config;
      input = html`<mask-input pattern="#####-####">
        <input
          class="form-control${small ? ' input-sm' : ''}"
          id="${id}"
          name="${name}"
          type="text"
          autocomplete="${autocomplete}"
          value="${value}"
          maxlength="10"
          pattern="^\\d{5}(?:-\\d{4})?$"
          ${booleanAttr('required', required)}
          ${booleanAttr('disabled', disabled)}
          ${attr('form', form)}
          ${booleanAttr('autofocus', autofocus)}
          ${attr('aria-describedby', describedBy)}
          ${attr('data-target', target)}
          ${attr('data-action', action)}
          ${inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
        />
      </mask-input>`;
      break;
    }
    case 'numeric-code': {
      const { value = '', maxLength, minLength } = config;
      input = html`<input
        class="form-control${small ? ' input-sm' : ''}"
        id="${id}"
        name="${name}"
        type="tel"
        autocomplete="none"
        value="${value}"
        pattern="^\\d+$"
        maxlength="${maxLength}"
        ${attr('minlength', minLength?.toString())}
        ${booleanAttr('required', required)}
        ${booleanAttr('disabled', disabled)}
        ${attr('form', form)}
        ${booleanAttr('autofocus', autofocus)}
        ${attr('aria-describedby', describedBy)}
        ${attr('data-target', target)}
        ${attr('data-action', action)}
        ${inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
      />`;
      break;
    }
    default:
      throw new Error(`Unexpected form group type`);
  }
  return html`<div
    class="form-group break-inside-avoid ${classes}"
    ${attrs?.map((x, i) => [i == 0 ? null : ' ', x])}
  >
    <label class="form-group-header d-inline-block ${headerClasses}" for="${id}"
      ><span data-label-text>${label}</span>${required &&
      config.type !== 'select' &&
      config.type !== 'rich-select'
        ? requiredAsterisk(locale)
        : null}
    </label>
    <div class="form-group-body">${input}</div>
    ${desc}
  </div>`;
}

export function formCheckboxTemplate({
  label,
  name,
  checked,
  disabled,
  description,
  classes,
  attrs,
  form,
  target,
  action,
  inputAttrs,
  autofocus,
  required,
  id = newElementId(),
  trueValue = true,
  falseValue = false
}: {
  checked?: boolean;
  trueValue?: string | boolean | number;
  falseValue?: string | boolean | number;
} & FormGroupConfigBase) {
  const labelContent = html`<span data-label-text>${label}</span>`;
  return html`<div
    class="form-group ${classes}"
    ${attrs?.map((x, i) => [i == 0 ? null : ' ', x])}
  >
    <div class="form-group-body">
      <label
        class="d-inline-flex ${description
          ? 'flex-items-start'
          : 'flex-items-center'} gap-1"
        for="${id}"
      >
        <input
          type="hidden"
          name="${name}"
          value="${falseValue}"
          ${attr('form', form)}
        />
        <input
          id="${id}"
          class="flex-shrink-0${description ? ' mt-1' : ''}"
          type="checkbox"
          name="${name}"
          value="${trueValue}"
          ${booleanAttr('checked', checked)}
          ${booleanAttr('required', required)}
          ${booleanAttr('disabled', disabled)}
          ${booleanAttr('autofocus', autofocus)}
          ${attr('form', form)}
          ${attr('data-target', target)}
          ${attr('data-action', action)}
          ${inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
        />
        ${description
          ? html`<span class="d-flex flex-column">
              ${labelContent}
              <span class="color-fg-muted f6 text-normal">${description}</span>
            </span>`
          : labelContent}</label
      >
    </div>
  </div>`;
}

export function saveButtonTemplate({
  locale = defaultLocale,
  form,
  small,
  isNew,
  label = isNew ? loc_create[locale] : loc_save[locale],
  describedBy
}: {
  locale?: Locale;
  form?: string;
  small?: boolean;
  label?: string;
  isNew?: boolean;
  describedBy?: string;
} = {}) {
  return html`<button
    type="submit"
    class="btn btn-primary${small
      ? ' btn-sm'
      : ''} justify-self-start d-print-none"
    ${attr('form', form)}
    ${booleanAttr('new', isNew)}
    ${attr('aria-describedby', describedBy)}
    disabled
  >
    <span class="saved-indicator"></span>
    <span>${label}</span>
  </button>`;
}

export function* selectOptions<T>(
  items: T[],
  valueProperty: keyof T,
  textProperty: keyof T
) {
  for (const item of items) {
    const value = String(item[valueProperty]);
    const text = String(item[textProperty]);
    yield { value, text };
  }
}

function selectOptionsTemplate(
  items: { value: string; text: string; disabled?: boolean }[],
  selectedValue: string
) {
  return items.map(({ value, text, disabled = false }) =>
    selectOptionTemplate(text, value, selectedValue, disabled)
  );
}

export function groupedSelectOptionsTemplate(
  options: {
    value: string;
    text: string;
    disabled?: boolean;
    group?: string;
  }[],
  value: string
) {
  const opts: TemplateResult[] = [];
  for (const [group, items] of Object.entries(
    groupBy(options, x => x.group ?? '')
  )) {
    if (group) {
      opts.push(
        html`<optgroup label="${group}">
          ${selectOptionsTemplate(items, value)}
        </optgroup>`
      );
    } else {
      opts.push(...selectOptionsTemplate(items, value));
    }
  }
  return opts;
}

function selectOptionTemplate(
  text: string,
  value: string,
  selectedValue: string,
  disabled: boolean
) {
  return html`<option
    value="${value}"
    ${booleanAttr('selected', value === selectedValue)}
    ${booleanAttr('disabled', disabled)}
  >
    ${text}
  </option>`;
}

export function formChoicesTemplate({
  label,
  name,
  options,
  multiple,
  value,
  required = false,
  disabled = false,
  description,
  classes,
  attrs,
  form,
  autofocus = false,
  locale = defaultLocale
}: {
  options: {
    value: string;
    text: string;
    exclusive?: boolean;
    disabled?: boolean;
    id?: string;
    description?: string;
    selectedContent?: TemplateResult;
    action?: string;
  }[];
  multiple: boolean;
  value: string | string[];
  locale?: Locale;
} & FormGroupConfigBase) {
  let printOnlyDesc = false;
  if (!description) {
    printOnlyDesc = true;
    description = multiple
      ? loc_checkAllThatApply[locale]
      : loc_selectOnlyOne[locale];
  }
  const desc = description
    ? html`<p
        class="note text-normal mt-0 mb-1${printOnlyDesc
          ? ' d-screen-none'
          : ''}"
      >
        ${description}
      </p>`
    : null;

  const inline =
    options.length <= 4 &&
    !options.some(c => c.description) &&
    options.reduce((acc, c) => acc + c.text.length, 0) <= 40;

  const type = multiple ? 'checkbox' : 'radio';

  const choices: TemplateResult[] = [];

  for (const option of options) {
    const checked =
      option.value === value ||
      (Array.isArray(value) && value.includes(option.value));
    choices.push(
      choiceTemplate({
        type,
        name,
        value: option.value,
        label: option.text,
        description: option.description,
        checked,
        exclusive: option.exclusive ?? false,
        required,
        disabled: disabled || (option.disabled ?? false),
        describedBy: '',
        form,
        autofocus,
        inputId: option.id ?? newElementId(),
        selectedContent: option.selectedContent,
        action: option.action
      })
    );
  }

  return html`<fieldset
    class="form-group break-inside-avoid ${classes}"
    ${attrs?.map((x, i) => [i == 0 ? null : ' ', x])}
  >
    <choice-group class="d-block">
      <legend class="form-group-header text-bold">
        <span data-label-text>${label}</span>${required
          ? requiredAsterisk(locale)
          : null}
        ${desc}
      </legend>
      <div
        class="form-group-body d-flex flex-items-start ${inline
          ? 'gap-3 flex-wrap'
          : 'flex-column gap-2'}"
      >
        ${!required && !multiple
          ? html`<input type="hidden" name="${name}" value="" />`
          : null}
        ${choices}
      </div>
    </choice-group>
  </fieldset>`;
}

function choiceTemplate({
  type,
  name,
  value,
  label,
  checked,
  description,
  exclusive,
  required,
  disabled,
  describedBy,
  form,
  autofocus,
  inputId,
  selectedContent,
  action
}: {
  type: 'radio' | 'checkbox';
  name: string;
  value: string;
  label: string;
  description?: string;
  checked: boolean;
  exclusive: boolean;
  required: boolean;
  disabled: boolean;
  describedBy: string;
  form: string | undefined;
  autofocus: boolean;
  inputId: string;
  selectedContent?: TemplateResult;
  action?: string;
}) {
  const labelContent = html`<span
    data-label-text
    class="${value ? '' : 'text-italic'}"
    >${label}</span
  >`;
  return html`<label
      class="text-normal d-inline-flex ${description
        ? 'flex-items-start'
        : 'flex-items-center'} gap-1${value === '' ? ' d-print-none' : ''}"
      for="${inputId}"
    >
      <input
        id="${inputId}"
        class="flex-shrink-0${description ? ' mt-1' : ''}"
        type="${type}"
        name="${name}"
        value="${value}"
        ${booleanAttr('checked', checked)}
        ${booleanAttr('exclusive', exclusive)}
        ${booleanAttr('required', required)}
        ${booleanAttr('disabled', disabled)}
        ${booleanAttr('autofocus', autofocus)}
        ${attr('aria-describedby', describedBy)}
        ${attr('form', form)}
        ${attr('data-action', action)}
      />
      ${description
        ? html`<span class="d-flex flex-column">
            ${labelContent}
            <span class="color-fg-muted f6">${description}</span>
          </span>`
        : labelContent}</label
    >${selectedContent
      ? html`<div class="ml-3 display-if-preceding-checked">
          ${selectedContent}
        </div>`
      : null}`;
}
