import { dispatchChange } from './util';

export class ChoiceGroupElement extends HTMLElement {
  clearCheckedRadioTimeout = 0;

  connectedCallback() {
    this.addEventListener('change', this, true);
    this.addEventListener('click', this);
    this.addEventListener('keydown', this);
    this.addEventListener('keyup', this);
    this.setTabindexes();
  }

  disconnectedCallback() {
    this.removeEventListener('change', this, true);
    this.removeEventListener('click', this);
    this.removeEventListener('keydown', this);
    this.removeEventListener('keyup', this);
  }

  handleEvent(event: Event) {
    if (
      event.type === 'click' &&
      event.target instanceof HTMLElement &&
      event.target.closest('legend')
    ) {
      this.querySelector<HTMLInputElement>('[tabindex="0"]')?.focus();
      return;
    }
    if (
      event.target instanceof HTMLInputElement &&
      (event.target.type === 'checkbox' || event.target.type === 'radio')
    ) {
      switch (event.type) {
        case 'click':
          if (event.target.type === 'radio' && event.target.checked) {
            // Click happens before the change event.
            // Wait for the change event to fire, if it does, we do not need to clear the radio because it just became checked.
            const checkedRadio = event.target;
            clearTimeout(this.clearCheckedRadioTimeout);
            this.clearCheckedRadioTimeout = setTimeout(() => {
              checkedRadio.checked = false;
              dispatchChange(checkedRadio);
            });
          }
          return;
        case 'change':
          clearTimeout(this.clearCheckedRadioTimeout);
          this.handleExclusive(event);
          this.setTabindexes();
          return;
        case 'keydown':
          switch ((event as KeyboardEvent).key) {
            case 'ArrowUp':
            case 'ArrowLeft':
              event.preventDefault();
              this.moveFocus(event.target, -1);
              return;
            case 'ArrowDown':
            case 'ArrowRight':
              event.preventDefault();
              this.moveFocus(event.target, 1);
              return;
          }
          return;
        case 'keyup':
          switch ((event as KeyboardEvent).key) {
            case ' ':
              if (event.target.type === 'radio' && event.target.checked) {
                event.preventDefault();
                event.target.checked = false;
                dispatchChange(event.target);
              }
              return;
          }
          return;
      }
    }
  }

  moveFocus(from: HTMLInputElement, delta: -1 | 1) {
    const inputs = Array.from(
      this.querySelectorAll<HTMLInputElement>(
        'input[type="checkbox"],input[type="radio"]'
      )
    );
    let index = inputs.indexOf(from) + delta;
    if (index < 0) {
      index = inputs.length - 1;
    } else if (index >= inputs.length) {
      index = 0;
    }
    const to = inputs[index];
    to.focus();
    if (to.checked) {
      from.tabIndex = -1;
      to.tabIndex = 0;
    }
  }

  setTabindexes() {
    const inputs = this.querySelectorAll<HTMLInputElement>(
      'input[type="checkbox"],input[type="radio"]'
    );
    let x: HTMLInputElement | undefined = undefined;
    for (const input of inputs) {
      if (!x) {
        input.tabIndex = 0;
        x = input;
      } else if (x && !x.checked && input.checked) {
        input.tabIndex = 0;
        x.tabIndex = -1;
        x = input;
      } else {
        input.tabIndex = -1;
      }
    }
  }

  handleExclusive({ target }: Event) {
    if (
      target instanceof HTMLInputElement &&
      target.type === 'checkbox' &&
      target.checked
    ) {
      const isExclusive = target.hasAttribute('exclusive');
      const inputs = Array.from(
        this.querySelectorAll<HTMLInputElement>(`input[name="${target.name}"]`)
      );
      /**
       * 1. checked an exclusive: uncheck everything else
       * 2. check non-exclusive: uncheck all exclusive
       */
      for (const input of inputs) {
        if (input === target) {
          continue;
        }
        if (
          (isExclusive && input.checked) ||
          (input.checked && input.hasAttribute('exclusive'))
        ) {
          input.checked = false;
        }
      }
    }
  }
}

declare global {
  interface Window {
    ChoiceGroupElement: typeof ChoiceGroupElement;
  }
  interface HTMLElementTagNameMap {
    'choice-group': ChoiceGroupElement;
  }
}

if (!window.customElements.get('choice-group')) {
  window.ChoiceGroupElement = ChoiceGroupElement;
  window.customElements.define('choice-group', ChoiceGroupElement);
}
