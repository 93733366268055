import { target } from '@github/catalyst';
import { Attachment } from '@github/file-attachment-element';
import { uploadAttachments } from '../attachments';
import { getLocale } from '../locale';
import { controller } from './controller';
import { SignatureAcceptedEventDetail } from './signature-pad';

const locale = getLocale();

@controller('signature-submitter')
class SignatureSubmitterElement extends HTMLElement {
  @target declare signaturePadButton: HTMLButtonElement;
  @target declare input: HTMLInputElement;
  objectUrls: string[] = [];

  get uploadUrl() {
    return new URL(this.getAttribute('upload-href')!, location.origin);
  }

  get jwt() {
    return this.getAttribute('jwt');
  }

  disconnectedCallback() {
    this.objectUrls.forEach(URL.revokeObjectURL);
  }

  async signatureAccepted({
    detail: { blob }
  }: CustomEvent<SignatureAcceptedEventDetail>) {
    try {
      this.signaturePadButton.classList.add('btn-busy');
      const file = new File([blob], 'signature.png', { type: 'image/png' });
      const attachment = new Attachment(file);
      attachment.name = file.name;
      await uploadAttachments({
        locale,
        attachments: [attachment],
        uploadUrl: this.uploadUrl,
        attachmentSaved: () => {},
        jwt: this.jwt ?? ''
      });

      const href = URL.createObjectURL(blob);
      this.objectUrls.unshift(href);

      this.input.value = attachment.id!;
      this.closest('form')?.requestSubmit();
    } catch (err) {
      console.error(err);
      return;
    } finally {
      this.signaturePadButton.classList.remove('btn-busy');
      this.signaturePadButton.focus();
    }
  }
}

declare global {
  interface Window {
    SignatureSubmitterElement: typeof SignatureSubmitterElement;
  }

  interface HTMLElementTagNameMap {
    'signature-submitter': SignatureSubmitterElement;
  }
}
