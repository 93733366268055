import {
  Locale,
  loc_deleteTitle,
  loc_multipleEarlierVersions,
  loc_singularEarlierVersion
} from '@cumu/strings';
import { icon, trashIcon } from '../icons';
import {
  attachmentTypesByExtension,
  getFileExtension
} from '../model/attachment';
import { attr, html, unsafeHTML } from '../template';

export interface AttachmentDisplayVersion {
  id: string;
  href: string;
  name: string;
  date_iso: string;
  user_unsafe_html: string;
}

export function attachmentGroupTemplate({
  locale,
  readonly = false,
  display,
  inserted_property_id,
  versions
}: {
  locale: Locale;
  readonly?: boolean;
  display: 'link' | 'card' | 'signature';
  inserted_property_id?: number;
  versions: AttachmentDisplayVersion[];
}) {
  if (versions.length === 0) {
    return null;
  }
  const extension = getFileExtension(versions[0].name);
  const type = attachmentTypesByExtension[extension];
  if (!type) {
    throw new Error(`Unknown attachment type for extension ${extension}`);
  }
  return html`<attachment-group
    class="d-flex flex-column gap-1 lh-condensed${display === 'card'
      ? ' py-1 px-2 border rounded color-bg-default'
      : ''}"
    ${attr(
      'style',
      display === 'card' ? `min-width: 250px; max-width: 350px` : null
    )}
    display="${display}"
    versions="${JSON.stringify(versions)}"
    version-count="${versions.length}"
  >
    ${inserted_property_id
      ? html`<input
          type="hidden"
          name="attachments/-:json"
          value=${JSON.stringify({
            attachment_id: versions[0].id,
            property_id: inserted_property_id
          })}
        />`
      : null}
    <span
      class="d-flex gap-3 ${display === 'signature'
        ? 'align-items-start'
        : 'align-items-center'} flex-justify-between"
    >
      ${display === 'signature'
        ? html`<img
            class="signature-image"
            src="${versions[0].href}"
            alt="Signature"
            style="height: 40px"
          />`
        : html`<a
            class="Link--primary Truncate text-bold"
            href="${versions[0].href}"
            title="${versions[0].name}"
          >
            <span class="Truncate-text">
              ${icon(type.icon48(), {
                size: 18,
                viewBox: 48
              })}
              ${versions[0].name}</span
            >
          </a>`}
      ${readonly ? null : deleteButton(locale, versions[0])}
    </span>
    <span class="color-fg-muted text-capitalize-first-letter">
      <relative-time datetime="${versions[0].date_iso}"></relative-time>
      by ${unsafeHTML(versions[0].user_unsafe_html)}
    </span>
    ${versions.length < 2
      ? null
      : html`<details class="details-reset">
          <summary class="color-fg-muted f6">
            ${versions.length - 1 === 1
              ? loc_singularEarlierVersion[locale]
              : loc_multipleEarlierVersions[locale](versions.length - 1)}
            <span class="dropdown-caret"></span>
          </summary>
          <span class="pl-3 d-flex flex-column gap-1 mt-1">
            ${versions.slice(1).map(x =>
              display === 'signature'
                ? html`<span
                      class="d-flex gap-3 align-items-start flex-justify-between"
                    >
                      <img
                        class="signature-image"
                        src="${x.href}"
                        alt="Signature"
                        style="height: 40px"
                      />
                      ${readonly ? null : deleteButton(locale, x)}
                    </span>
                    <span class="color-fg-muted text-capitalize-first-letter">
                      <relative-time datetime="${x.date_iso}"></relative-time>
                      by ${unsafeHTML(x.user_unsafe_html)}
                    </span>`
                : html`<span
                    class="d-flex flex-items-center gap-3 flex-justify-between color-fg-muted"
                  >
                    <span
                      ><a
                        class="Link--primary text-capitalize-first-letter"
                        href="${x.href}"
                        ><relative-time datetime="${x.date_iso}"></relative-time
                      ></a>
                      <span>by</span>
                      ${unsafeHTML(x.user_unsafe_html)}</span
                    >
                    ${readonly ? null : deleteButton(locale, x)}
                  </span>`
            )}
          </span>
        </details>`}
  </attachment-group>`;
}

function deleteButton(
  locale: Locale,
  { id, name }: { id: string; name: string }
) {
  return html`<button
    type="button"
    class="btn-octicon btn-octicon-danger mt-n1 mb-n1 ml-n1 mr-n1"
    aria-label="${loc_deleteTitle[locale](name)}"
    data-action="click:attachment-group#deleteClick"
    attachment-id="${id}"
  >
    ${icon(trashIcon)}
  </button>`;
}
