import {
  defineObservedAttributes,
  initializeAttrs
} from '@github/catalyst/lib//attr.js';
import { autoShadowRoot } from '@github/catalyst/lib/auto-shadow-root.js';
import { bind, bindShadow } from '@github/catalyst/lib/bind.js';
import type { CustomElementClass } from '@github/catalyst/lib/custom-element.js';

export function controller(name: string) {
  return function (classObject: CustomElementClass): void {
    const connect = classObject.prototype.connectedCallback;
    classObject.prototype.connectedCallback = function (this: HTMLElement) {
      this.toggleAttribute('data-catalyst', true);
      autoShadowRoot(this);
      initializeAttrs(this);
      bind(this);
      if (connect) connect.call(this);
      if (this.shadowRoot) bindShadow(this.shadowRoot);
    };
    defineObservedAttributes(classObject);
    if (!window.customElements.get(name)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window[classObject.name] = classObject;
      window.customElements.define(name, classObject);
    }
  };
}
