// GENERATED FILE - DO NOT EDIT

import { Locale } from './locale';

interface TemplateResult {
  readonly strings: TemplateStringsArray;
  readonly values: unknown[];
}

const TEMPLATE_RESULT = Symbol.for('cumulus.templates.result');

/*#__NO_SIDE_EFFECTS__*/ function html(
  strings: TemplateStringsArray,
  ...values: any[]
): TemplateResult {
  return {
    [TEMPLATE_RESULT]: true,
    strings,
    values
  } as TemplateResult;
}

export function cleanupTranslation(s: string) {
  return (
    s
      // Remove trailing underscores, including the whitespace preceding the trailing underscores
      .replace(/(?:\s+)?_+$/, '')
      // Replace "covid-19" with "COVID-19"
      .replace(/([^\s])?covid-19([\s$])?/g, '$1COVID-19$2')
  );
}

export function applyTranslationOverride(locale: Locale, s: string) {
  const overrides: { [locale in Locale]?: Record<string, string> } = {
    es: {
      Hembra: 'Femenino'
    }
  };
  return overrides[locale]?.[s] ?? s;
}
export const loc_questionnaire: Record<Locale, string> = {
	ar: `استبيان`,
	de: `Fragebogen`,
	es: `Cuestionario`,
	fa: `پرسشنامه`,
	fil: `Talatanungan`,
	fr: `Questionnaire`,
	hi: `प्रश्‍नावली`,
	ht: `Kesyonè`,
	it: `Questionario`,
	ja: `アンケート`,
	ko: `설문지`,
	mww: `Lus nug`,
	ps: `پوښتنلیک`,
	pt: `Questionário`,
	'pt-pt': `Questionário`,
	ru: `Анкета`,
	sm: `Fesili`,
	so: `haku`,
	th: `แบบสอบถาม`,
	ur: `استفسار نامہ`,
	vi: `Bản câu hỏi`,
	'zh-hans': `问卷`,
	'zh-hant': `問卷`,
	en: `Questionnaire`
};

export const loc_referral: Record<Locale, string> = {
	ar: `الاحاله`,
	de: `Weiterempfehlung`,
	es: `Recomendación`,
	fa: `ارجاع`,
	fil: `Referral`,
	fr: `Ajournement`,
	hi: `रेफरल`,
	ht: `Rekòmandasyon`,
	it: `Deferimento`,
	ja: `紹介`,
	ko: `추천`,
	mww: `Xa mus cuag`,
	ps: `حواله`,
	pt: `Referência`,
	'pt-pt': `Encaminhamento`,
	ru: `Направление`,
	sm: `Faailoa Atu`,
	so: `Referral`,
	th: `อ้าง อิง`,
	ur: `ریفریل`,
	vi: `Giới thiệu`,
	'zh-hans': `转 介`,
	'zh-hant': `轉 介`,
	en: `Referral`
};

export const loc_task: Record<Locale, string> = {
	ar: `مهمة`,
	de: `Aufgabe`,
	es: `Tarea`,
	fa: `وظیفه`,
	fil: `Gawain`,
	fr: `Tâche`,
	hi: `नियत कार्य`,
	ht: `Travay`,
	it: `Compito`,
	ja: `タスク`,
	ko: `과업`,
	mww: `hauj lwm`,
	ps: `دنده`,
	pt: `Tarefa`,
	'pt-pt': `Tarefa`,
	ru: `Задача`,
	sm: `Galuega`,
	so: `Hawlaha`,
	th: `งาน`,
	ur: `کام`,
	vi: `Nhiệm vụ`,
	'zh-hans': `任务`,
	'zh-hant': `任務`,
	en: `Task`
};

export const loc_event: Record<Locale, string> = {
	ar: `حدث`,
	de: `Ereignis`,
	es: `Evento`,
	fa: `رویداد`,
	fil: `Kaganapan`,
	fr: `Événement`,
	hi: `घटना`,
	ht: `Evenman`,
	it: `Evento`,
	ja: `出来事`,
	ko: `이벤트`,
	mww: `kev tshwm sim`,
	ps: `پېښه`,
	pt: `Acontecimento`,
	'pt-pt': `Evento`,
	ru: `Событие`,
	sm: `Mea na Tupu`,
	so: `Dhacdooyinka`,
	th: `เหตุการณ์`,
	ur: `احوال`,
	vi: `Sự kiện`,
	'zh-hans': `事件`,
	'zh-hant': `事件`,
	en: `Event`
};

export const loc_selectLanguage: Record<Locale, string> = {
	ar: `اختر اللغة`,
	de: `Sprache auswählen`,
	es: `Seleccionar idioma`,
	fa: `انتخاب زبان`,
	fil: `Pumili ng wika`,
	fr: `Sélectionner une langue`,
	hi: `भाषा का चयन करें`,
	ht: `Chwazi lang`,
	it: `Seleziona lingua`,
	ja: `言語の選択`,
	ko: `언어 선택`,
	mww: `Xaiv lus`,
	ps: `ژبه غوره کړئ`,
	pt: `Selecionar idioma`,
	'pt-pt': `Selecionar idioma`,
	ru: `Выбрать язык`,
	sm: `Filifili le gagana`,
	so: `Luqadda select`,
	th: `เลือกภาษา`,
	ur: `زبان منتخب کریں`,
	vi: `Chọn ngôn ngữ`,
	'zh-hans': `选择语言`,
	'zh-hant': `選擇語言`,
	en: `Select language`
};

export const loc_selectALanguage: Record<Locale, string> = {
	ar: `اختر لغة`,
	de: `Wählen Sie eine Sprache aus`,
	es: `Seleccione un idioma`,
	fa: `یک زبان را انتخاب کنید`,
	fil: `Pumili ng wika`,
	fr: `Sélectionnez une langue`,
	hi: `एक भाषा चुनें`,
	ht: `Chwazi yon lang`,
	it: `Seleziona una lingua`,
	ja: `言語を選択`,
	ko: `언어 선택`,
	mww: `Xaiv ib hom lus`,
	ps: `یوه ژبه غوره کړئ`,
	pt: `Selecione um idioma`,
	'pt-pt': `Selecione um idioma`,
	ru: `Выберите язык`,
	sm: `Filifili se gagana`,
	so: `Dalbo luqad`,
	th: `เลือกภาษา`,
	ur: `ایک زبان منتخب کریں`,
	vi: `Chọn ngôn ngữ`,
	'zh-hans': `选择语言`,
	'zh-hant': `選擇語言`,
	en: `Select a language`
};

export const loc_activityDefinitions: Record<Locale, string> = {
	ar: `تعريفات النشاط`,
	de: `Definitionen von Aktivitäten`,
	es: `Definiciones de actividades`,
	fa: `تعاریف فعالیت`,
	fil: `Mga kahulugan ng aktibidad`,
	fr: `Définitions des activités`,
	hi: `गतिविधि परिभाषाएं`,
	ht: `Definisyon aktivite`,
	it: `Definizioni delle attività`,
	ja: `アクティビティ定義`,
	ko: `활동 정의`,
	mww: `Kev txhais cov ntsiab lus txhais cov ntsiab lus`,
	ps: `د فعالیت تعریفونه`,
	pt: `Definições de atividade`,
	'pt-pt': `Definições de atividade`,
	ru: `Определения действий`,
	sm: `Faauigaga o gaoioiga`,
	so: `Qeexidda waxqabadka`,
	th: `คําจํากัดความของกิจกรรม`,
	ur: `سرگرمی کی تعریفیں`,
	vi: `Định nghĩa hoạt động`,
	'zh-hans': `活动定义`,
	'zh-hant': `活動定義`,
	en: `Activity definitions`
};

export const loc_activityDefinition: Record<Locale, string> = {
	ar: `تعريف النشاط`,
	de: `Definition der Aktivität`,
	es: `Definición de la actividad`,
	fa: `تعریف فعالیت`,
	fil: `Kahulugan ng aktibidad`,
	fr: `Définition de l’activité`,
	hi: `गतिविधि परिभाषा`,
	ht: `Definisyon aktivite`,
	it: `Definizione dell'attività`,
	ja: `アクティビティの定義`,
	ko: `활동 정의`,
	mww: `Kev txhais cov ntsiab lus txhais`,
	ps: `د فعالیت تعریف`,
	pt: `Definição de atividade`,
	'pt-pt': `Definição da atividade`,
	ru: `Определение деятельности`,
	sm: `Faauigaga o le gaoioiga`,
	so: `Qeexidda waxqabadka`,
	th: `คําจํากัดความของกิจกรรม`,
	ur: `سرگرمی کی تعریف`,
	vi: `Định nghĩa hoạt động`,
	'zh-hans': `活动定义`,
	'zh-hant': `活動定義`,
	en: `Activity definition`
};

export const loc_projects: Record<Locale, string> = {
	ar: `المشاريع`,
	de: `Projekte`,
	es: `Proyectos`,
	fa: `پروژه`,
	fil: `Mga Proyekto`,
	fr: `Projets`,
	hi: `परियोजनाओं`,
	ht: `Pwojè`,
	it: `Progetti`,
	ja: `プロジェクト`,
	ko: `프로젝트`,
	mww: `Tej yaam num`,
	ps: `پروژې`,
	pt: `Projetos`,
	'pt-pt': `Projetos`,
	ru: `Проекты`,
	sm: `Galuega Faatino`,
	so: `Mashaariicda`,
	th: `โครงการ`,
	ur: `منصوبوں`,
	vi: `Dự án`,
	'zh-hans': `项目`,
	'zh-hant': `專案`,
	en: `Projects`
};

export const loc_newEntity: Record<Locale, (entity: unknown) => string> = {
	ar: (entity) => `${entity} جديد`,
	de: (entity) => `Neuer ${entity}`,
	es: (entity) => `${entity}`,
	fa: (entity) => `${entity} جدید`,
	fil: (entity) => `Bagong ${entity}`,
	fr: (entity) => `Nouvel ${entity}`,
	hi: (entity) => `नया ${entity}`,
	ht: (entity) => `Nouvo ${entity}`,
	it: (entity) => `Nuovo ${entity}`,
	ja: (entity) => `新 ${entity}`,
	ko: (entity) => `신 ${entity}`,
	mww: (entity) => `${entity}`,
	ps: (entity) => `نوی ${entity}`,
	pt: (entity) => `Novo ${entity}`,
	'pt-pt': (entity) => `Novo ${entity}`,
	ru: (entity) => `${entity}`,
	sm: (entity) => `Aitema ${entity} fou`,
	so: (entity) => `${entity} cusub`,
	th: (entity) => `${entity}ใหม่`,
	ur: (entity) => `نئی ${entity}`,
	vi: (entity) => `${entity} mới`,
	'zh-hans': (entity) => `新建 ${entity}`,
	'zh-hant': (entity) => `新建 ${entity}`,
	en: (entity) => `New ${entity}`
};

export const loc_new: Record<Locale, string> = {
	ar: `الجديد`,
	de: `Neu`,
	es: `Nuevo`,
	fa: `جدید`,
	fil: `Bagong`,
	fr: `Nouveau`,
	hi: `नया`,
	ht: `New`,
	it: `Nuovo`,
	ja: `新機能`,
	ko: `새로운`,
	mww: `Tshiab`,
	ps: `نوی`,
	pt: `Novo`,
	'pt-pt': `Novo`,
	ru: `Новые функции`,
	sm: `Fou`,
	so: `Cusub`,
	th: `ใหม่`,
	ur: `نیا`,
	vi: `Mới`,
	'zh-hans': `新增功能`,
	'zh-hant': `新增功能`,
	en: `New`
};

export const loc_clearLangPref: Record<Locale, TemplateResult> = {
	ar: html`<button class="btn-link" type="submit" name="locale" value="">مسح تفضيلات اللغة</button> على هذا الموقع`,
	de: html`<button class="btn-link" type="submit" name="locale" value="">Meine Spracheinstellung löschen</button> auf dieser Seite`,
	es: html`<button class="btn-link" type="submit" name="locale" value="">Borrar mi preferencia de idioma</button> En este sitio`,
	fa: html`<button class="btn-link" type="submit" name="locale" value="">ترجیحات زبانی من را پاک کنید</button> در این سایت`,
	fil: html`<button class="btn-link" type="submit" name="locale" value="">Clear my language preference</button> sa site na ito`,
	fr: html`<button class="btn-link" type="submit" name="locale" value="">Effacer ma préférence de langue</button> Sur ce site`,
	hi: html`<button class="btn-link" type="submit" name="locale" value="">मेरी भाषा प्राथमिकता साफ़ करें</button> इस साइट पर`,
	ht: html`<button class="btn-link" type="submit" name="locale" value="">Klè preferans lang mwen an</button> sou sit sa a`,
	it: html`<button class="btn-link" type="submit" name="locale" value="">Cancella la mia lingua preferita</button> su questo sito`,
	ja: html`<button class="btn-link" type="submit" name="locale" value="">言語設定をクリアする</button> このサイトの内容`,
	ko: html`<button class="btn-link" type="submit" name="locale" value="">내 언어 기본 설정 지우기</button> 이 사이트 내용`,
	mww: html`<button class="btn-link" type="submit" name="locale" value="">Tseeb kuv hom lus nyiam</button> ntawm no`,
	ps: html`<button class="btn-link" type="submit" name="locale" value="">زما د ژبې غوره توب پاکول</button> په دې ځای کې`,
	pt: html`<button class="btn-link" type="submit" name="locale" value="">Limpar minha preferência de idioma</button> neste site`,
	'pt-pt': html`<button class="btn-link" type="submit" name="locale" value="">Limpar a minha preferência de idioma</button> neste site`,
	ru: html`<button class="btn-link" type="submit" name="locale" value="">Очистить языковые настройки</button> на этом сайте`,
	sm: html`<button class="btn-link" type="submit" name="locale" value="">Faamama la'u gagana e fiafia i ai</button> i luga o lenei saite`,
	so: html`<button class="btn-link" type="submit" name="locale" value="">Clear aan afkayga doorbidid</button> on this site`,
	th: html`<button class="btn-link" type="submit" name="locale" value="">ล้างการตั้งค่าภาษาของฉัน</button> บนเว็บไซต์นี้`,
	ur: html`<button class="btn-link" type="submit" name="locale" value="">میری زبان کی ترجیح کو صاف کریں</button> اس سائٹ پر`,
	vi: html`<button class="btn-link" type="submit" name="locale" value="">Xóa tùy chọn ngôn ngữ của tôi</button> trên trang web này`,
	'zh-hans': html`<button class="btn-link" type="submit" name="locale" value="">清除我的语言首选项</button> 在本站`,
	'zh-hant': html`<button class="btn-link" type="submit" name="locale" value="">清除我的語言首選項</button> 在本站`,
	en: html`<button class="btn-link" type="submit" name="locale" value="">Clear my language preference</button> on this site`
};

export const loc_currentLangSelection: Record<Locale, (name: unknown) => TemplateResult> = {
	ar: (name) => html`الاختيار الحالي: <strong>${name}</strong>`,
	de: (name) => html`Aktuelle Auswahl: <strong>${name}</strong>`,
	es: (name) => html`Selección actual: <strong>${name}</strong>`,
	fa: (name) => html`انتخاب فعلی: <strong>${name}</strong>`,
	fil: (name) => html`Kasalukuyang pagpipilian: <strong>${name}</strong>`,
	fr: (name) => html`Sélection actuelle : <strong>${name}</strong>`,
	hi: (name) => html`वर्तमान चयन: <strong>${name}</strong>`,
	ht: (name) => html`Lòt <strong>${name}</strong>`,
	it: (name) => html`Selezione corrente: <strong>${name}</strong>`,
	ja: (name) => html`現在の選択: <strong>${name}</strong>`,
	ko: (name) => html`현재 선택: <strong>${name}</strong>`,
	mww: (name) => html`Xaiv tam sim no: <strong>${name}</strong>`,
	ps: (name) => html`انتخاب: <strong>${name}</strong>`,
	pt: (name) => html`Seleção atual: <strong>${name}</strong>`,
	'pt-pt': (name) => html`Seleção atual: <strong>${name}</strong>`,
	ru: (name) => html`Текущий выбор: <strong>${name}</strong>`,
	sm: (name) => html`Filifiliga o loo i ai nei: <strong>${name}</strong>`,
	so: (name) => html`Xulashada hadda: <strong>${name}</strong>`,
	th: (name) => html`การเลือกปัจจุบัน: <strong>${name}</strong>`,
	ur: (name) => html`موجودہ انتخاب: <strong>${name}</strong>`,
	vi: (name) => html`Lựa chọn hiện tại: <strong>${name}</strong>`,
	'zh-hans': (name) => html`当前选择： <strong>${name}</strong>`,
	'zh-hant': (name) => html`當前選擇： <strong>${name}</strong>`,
	en: (name) => html`Current selection: <strong>${name}</strong>`
};

export const loc_findLang: Record<Locale, string> = {
	ar: `البحث عن اللغة`,
	de: `Sprache finden`,
	es: `Buscar idioma`,
	fa: `زبان را پیدا کنید`,
	fil: `Maghanap ng wika`,
	fr: `Trouver une langue`,
	hi: `भाषा खोजें`,
	ht: `Jwenn lang`,
	it: `Trova la lingua`,
	ja: `言語の検索`,
	ko: `언어 찾기`,
	mww: `Nrhiav lus`,
	ps: `ژبه موندل`,
	pt: `Encontrar idioma`,
	'pt-pt': `Procurar idioma`,
	ru: `Найти язык`,
	sm: `Saili le gagana`,
	so: `Raadi luqad`,
	th: `ค้นหาภาษา`,
	ur: `زبان تلاش کریں`,
	vi: `Tìm ngôn ngữ`,
	'zh-hans': `查找语言`,
	'zh-hant': `查找語言`,
	en: `Find language`
};

export const loc_submit: Record<Locale, string> = {
	ar: `إرسال`,
	de: `Senden`,
	es: `Enviar`,
	fa: `ارسال`,
	fil: `Isumite ang`,
	fr: `Envoyer`,
	hi: `जमा करें`,
	ht: `Soumèt`,
	it: `Invia`,
	ja: `送信`,
	ko: `전송`,
	mww: `Xa`,
	ps: `وړاندې کړه`,
	pt: `Enviar`,
	'pt-pt': `Enviar`,
	ru: `Отправить`,
	sm: `Alu e ave i totonu`,
	so: `Gudbi`,
	th: `ส่ง`,
	ur: `پیش کریں`,
	vi: `Trình`,
	'zh-hans': `提交`,
	'zh-hant': `提交`,
	en: `Submit`
};

export const loc_submitted: Record<Locale, string> = {
	ar: `مقدم`,
	de: `Vorgelegt`,
	es: `Presentado`,
	fa: `ارسال`,
	fil: `Isinumite na`,
	fr: `Soumis`,
	hi: `प्रस्तुत`,
	ht: `Soumèt`,
	it: `Sommesso`,
	ja: `提出`,
	ko: `제출`,
	mww: `Xa`,
	ps: `سپارل شوی`,
	pt: `Enviada`,
	'pt-pt': `Enviada`,
	ru: `Представлены`,
	sm: `Auina Atu`,
	so: `Gudbiyey`,
	th: `ส่ง`,
	ur: `پیش`,
	vi: `Gửi`,
	'zh-hans': `提交`,
	'zh-hant': `提交`,
	en: `Submitted`
};

export const loc_theme: Record<Locale, string> = {
	ar: `موضوع`,
	de: `Thema`,
	es: `Tema`,
	fa: `تم`,
	fil: `Tema`,
	fr: `Thème`,
	hi: `विषय`,
	ht: `Tèm`,
	it: `Tema`,
	ja: `テーマ`,
	ko: `주제`,
	mww: `ntsiab lus`,
	ps: `ویینه`,
	pt: `Tema`,
	'pt-pt': `Tema`,
	ru: `Тема`,
	sm: `Autu`,
	so: `Mawduuca`,
	th: `หัวข้อ`,
	ur: `موضوع`,
	vi: `Đề tài`,
	'zh-hans': `主题`,
	'zh-hant': `主題`,
	en: `Theme`
};

export const loc_chooseTheme: Record<Locale, string> = {
	ar: `اختر موضوعا`,
	de: `Wählen Sie ein Thema`,
	es: `Elige un tema`,
	fa: `انتخاب طرح زمینه`,
	fil: `Pumili ng isang tema`,
	fr: `Choisissez un thème`,
	hi: `एक थीम चुनें`,
	ht: `Chwazi yon tèm`,
	it: `Scegli un tema`,
	ja: `テーマを選択する`,
	ko: `테마 선택`,
	mww: `Xaiv ib lub ntsiab`,
	ps: `یوه موضوع وټاکئ`,
	pt: `Escolha um tema`,
	'pt-pt': `Escolha um tema`,
	ru: `Выберите тему`,
	sm: `Filifili se autu`,
	so: `Dooro mawduuc`,
	th: `เลือกธีม`,
	ur: `موضوع منتخب کریں`,
	vi: `Chọn một chủ đề`,
	'zh-hans': `选择主题`,
	'zh-hant': `選擇主題`,
	en: `Choose a theme`
};

export const loc_poweredByCumulus: Record<Locale, (cumulus: unknown) => string> = {
	ar: (cumulus) => `مدعوم من ${cumulus}`,
	de: (cumulus) => `Angetrieben von ${cumulus}`,
	es: (cumulus) => `Desarrollado por ${cumulus}`,
	fa: (cumulus) => `طراحی شده توسط ${cumulus}`,
	fil: (cumulus) => `Pinapatakbo ng ${cumulus}`,
	fr: (cumulus) => `Propulsé par ${cumulus}`,
	hi: (cumulus) => `${cumulus} द्वारा संचालित`,
	ht: (cumulus) => `Patwone pa ${cumulus}`,
	it: (cumulus) => `Realizzato da ${cumulus}`,
	ja: (cumulus) => `${cumulus} を搭載`,
	ko: (cumulus) => `${cumulus} 제공`,
	mww: (cumulus) => `Powered los ${cumulus}`,
	ps: (cumulus) => `د. ${cumulus}`,
	pt: (cumulus) => `Desenvolvido por ${cumulus}`,
	'pt-pt': (cumulus) => `Desenvolvido por ${cumulus}`,
	ru: (cumulus) => `Работает на ${cumulus}`,
	sm: (cumulus) => `Faapaoaina e ${cumulus}`,
	so: (cumulus) => `Waxaa laga awoodaa ${cumulus}`,
	th: (cumulus) => `ขับเคลื่อนโดย ${cumulus}`,
	ur: (cumulus) => `کمولس کے ذریعہ ${cumulus}`,
	vi: (cumulus) => `Được cung cấp bởi ${cumulus}`,
	'zh-hans': (cumulus) => `由 ${cumulus} 提供支持`,
	'zh-hant': (cumulus) => `由 ${cumulus} 提供支援`,
	en: (cumulus) => `Powered by ${cumulus}`
};

export const loc_unanswered: Record<Locale, string> = {
	ar: `دون إجابة`,
	de: `Unbeantwortet`,
	es: `Por contestar`,
	fa: `بی پاسخ`,
	fil: `Hindi Nasagot`,
	fr: `Sans réponse`,
	hi: `अनुत्तरित`,
	ht: `San repons`,
	it: `Senza risposta`,
	ja: `未解決`,
	ko: `답`,
	mww: `unanswered`,
	ps: `بې ځوابه`,
	pt: `Sem resposta`,
	'pt-pt': `Sem resposta`,
	ru: `Безответный`,
	sm: `E le'i Taliina`,
	so: `Jawaab la'aan`,
	th: `ไม่มีคําตอบ`,
	ur: `جواب نہیں دیا گیا`,
	vi: `Unanswered`,
	'zh-hans': `悬而未决`,
	'zh-hant': `懸而未決`,
	en: `Unanswered`
};

export const loc_requiredParens: Record<Locale, string> = {
	ar: `(مطلوب)`,
	de: `(Erforderlich)`,
	es: `(Obligatorio)`,
	fa: `(ضروری)`,
	fil: `(Kailangan)`,
	fr: `(Obligatoire)`,
	hi: `(आवश्यक)`,
	ht: `(Obligatwa)`,
	it: `(Obbligatorio)`,
	ja: `(必須項目)`,
	ko: `(필수)`,
	mww: `(Yuav tsum tau)`,
	ps: `(ضروری)`,
	pt: `(Obrigatório)`,
	'pt-pt': `(Obrigatório)`,
	ru: `(Обязательно)`,
	sm: `(E manaomia)`,
	so: `(Baahan yahay)`,
	th: `(จําเป็น)`,
	ur: `(ضروری ہے)`,
	vi: `(Bắt buộc)`,
	'zh-hans': `（必填）`,
	'zh-hant': `（必填）`,
	en: `(Required)`
};

export const loc_thankYouForParticipating: Record<Locale, string> = {
	ar: `شكرا لك على المشاركة في الاستبيان! يمكنك إغلاق هذه الصفحة.`,
	de: `Vielen Dank, dass Sie an der Umfrage teilgenommen haben! Sie können diese Seite schließen.`,
	es: `¡Gracias por participar en la encuesta! Puede cerrar esta página.`,
	fa: `با تشکر از شما برای شرکت در نظرسنجی! می توانید این صفحه را ببندید.`,
	fil: `Salamat sa pakikilahok sa survey! Maaari mong isara ang pahinang ito.`,
	fr: `Merci de participer au sondage ! Vous pouvez fermer cette page.`,
	hi: `सर्वेक्षण में भाग लेने के लिए धन्यवाद! आप इस पृष्ठ को बंद कर सकते हैं।`,
	ht: `Mèsi pou patisipe nan sondaj la! Ou ka fèmen paj sa a.`,
	it: `Grazie per aver partecipato al sondaggio! Puoi chiudere questa pagina.`,
	ja: `この度は、アンケートにご協力いただき、誠にありがとうございました。このページは閉じてもかまいません。`,
	ko: `설문조사에 참여해 주셔서 감사합니다! 이 페이지를 닫을 수 있습니다.`,
	mww: `Ua tsaug rau koj koom rau hauv daim ntawv ntsuam xyuas! Koj yuav kaw tau nplooj ntawv no.`,
	ps: `په سروې کې د ګډون لپاره مننه! تاسو کولی شئ دا پا pageه بنده کړئ.`,
	pt: `Obrigado por participar da pesquisa! Você pode fechar esta página.`,
	'pt-pt': `Obrigado por participar no inquérito! Pode fechar esta página.`,
	ru: `Благодарим Вас за участие в опросе! Вы можете закрыть эту страницу.`,
	sm: `Faafetai mo le auai i le sailiiliga! E mafai ona e tapunia le itulau lenei.`,
	so: `Waad ku mahadsan tahay ka qayb qaadashada baadhitaanka! Waxaa laga yaabaa inaad ku xirato boggan.`,
	th: `ขอขอบคุณที่เข้าร่วมแบบสํารวจ! คุณสามารถปิดหน้านี้ได้`,
	ur: `سروے میں حصہ لینے کے لئے آپ کا شکریہ! آپ اس صفحے کو بند کر سکتے ہیں.`,
	vi: `Cảm ơn bạn đã tham gia khảo sát! Bạn có thể đóng trang này.`,
	'zh-hans': `感谢您参与调查！您可以关闭此页面。`,
	'zh-hant': `感謝您參與調查！您可以關閉此頁面。`,
	en: `Thank you for participating in the survey! You may close this page.`
};

export const loc_fillSurveyOnBehalfOfSomeoneElse: Record<Locale, (href: unknown) => TemplateResult> = {
	ar: (href) => html`<a href="${href}">إرسال استطلاع آخر</a> لشخص آخر.`,
	de: (href) => html`<a href="${href}">Reichen Sie eine weitere Umfrage</a> für eine andere Person ein.`,
	es: (href) => html`<a href="${href}">Envíe otra encuesta</a> para otra persona.`,
	fa: (href) => html`<a href="${href}">نظرسنجی دیگری</a> برای شخص دیگری ارسال کنید.`,
	fil: (href) => html`<a href="${href}">Magsumite ng isa pang survey</a> para sa ibang tao.`,
	fr: (href) => html`<a href="${href}">Soumettez un autre sondage</a> pour quelqu’un d’autre.`,
	hi: (href) => html`किसी अन्य व्यक्ति के लिए <a href="${href}">कोई अन्य सर्वेक्षण सबमिट करें</a>.`,
	ht: (href) => html`<a href="${href}">Soumèt yon lòt sondaj pou yon</a> lòt moun.`,
	it: (href) => html`<a href="${href}">Invia un'altra indagine</a> per qualcun altro.`,
	ja: (href) => html`他のユーザーのために<a href="${href}">別のアンケートを送信します</a>。`,
	ko: (href) => html`다른 사람을 위해 <a href="${href}">다른 설문조사를 제출</a>합니다.`,
	mww: (href) => html`<a href="${href}">Xa lwm daim ntawv ntsuam xyuas</a> rau lwm tus neeg.`,
	ps: (href) => html`د بل چا لپاره <a href="${href}">یو بل تحقیق وړاندې کړئ</a>.`,
	pt: (href) => html`<a href="${href}">Envie outra pesquisa</a> para outra pessoa.`,
	'pt-pt': (href) => html`<a href="${href}">Envie outra pesquisa</a> para outra pessoa.`,
	ru: (href) => html`<a href="${href}">Отправьте еще один опрос</a> для другого человека.`,
	sm: (href) => html`<a href="${href}">Auina atu se isi sailiiliga</a> mo se isi tagata.`,
	so: (href) => html`<a href="${href}">U samee sahan kale</a> qof kale.`,
	th: (href) => html`<a href="${href}">ส่งแบบสํารวจอื่น</a> ให้คนอื่น`,
	ur: (href) => html`کسی اور کے لئے <a href="${href}">ایک اور سروے پیش کریں</a>.`,
	vi: (href) => html`<a href="${href}">Gửi khảo sát khác</a> cho người khác.`,
	'zh-hans': (href) => html`为其他人<a href="${href}">提交另一个调查</a>。`,
	'zh-hant': (href) => html`為其他人<a href="${href}">提交另一個調查</a>。`,
	en: (href) => html`<a href="${href}">Submit another survey</a> for someone else.`
};

export const loc_recaptchaLegal: Record<Locale, TemplateResult> = {
	ar: html`هذا الموقع محمي بواسطة reCAPTCHA وتطبق <a class="Link--secondary" href="https://policies.google.com/privacy">سياسة خصوصية</a> Google <a class="Link--secondary" href="https://policies.google.com/terms">وشروط الخدمة</a> .`,
	de: html`Diese Website ist durch reCAPTCHA geschützt und es gelten die <a class="Link--secondary" href="https://policies.google.com/privacy">Datenschutzbestimmungen</a> und <a class="Link--secondary" href="https://policies.google.com/terms">Nutzungsbedingungen von</a> Google.`,
	es: html`Este sitio está protegido por reCAPTCHA y se aplican la <a class="Link--secondary" href="https://policies.google.com/privacy">Política de privacidad</a> y los <a class="Link--secondary" href="https://policies.google.com/terms">Términos de servicio de</a> Google.`,
	fa: html`این سایت توسط reCAPTCHA محافظت می شود و <a class="Link--secondary" href="https://policies.google.com/privacy">سیاست حفظ حریم خصوصی</a> و <a class="Link--secondary" href="https://policies.google.com/terms">شرایط خدمات</a> Google اعمال می شود.`,
	fil: html`Ang site na ito ay protektado ng reCAPTCHA at ang <a class="Link--secondary" href="https://policies.google.com/privacy">Google Patakaran sa Privacy</a> at <a class="Link--secondary" href="https://policies.google.com/terms">Mga Tuntunin ng Serbisyo</a> ay nalalapat.`,
	fr: html`Ce site est protégé par reCAPTCHA et les <a class="Link--secondary" href="https://policies.google.com/privacy">règles de confidentialité</a> et <a class="Link--secondary" href="https://policies.google.com/terms">les conditions d’utilisation de Google s’appliquent</a> .`,
	hi: html`यह साइट reCAPTCHA द्वारा सुरक्षित है और Google <a class="Link--secondary" href="https://policies.google.com/privacy">गोपनीयता नीति</a> और <a class="Link--secondary" href="https://policies.google.com/terms">सेवा की शर्तें</a> लागू होती हैं।`,
	ht: html`Sit sa a pwoteje pa reCAPTCHA ak Règleman <a class="Link--secondary" href="https://policies.google.com/privacy">sou enfòmasyon prive</a> Google ak <a class="Link--secondary" href="https://policies.google.com/terms">Kondisyon sèvis aplike</a> .`,
	it: html`Questo sito è protetto da reCAPTCHA e si applicano le <a class="Link--secondary" href="https://policies.google.com/privacy">Norme sulla privacy</a> e i <a class="Link--secondary" href="https://policies.google.com/terms">Termini di servizio di</a> Google.`,
	ja: html`このサイトはreCAPTCHAによって保護されており、Googleの <a class="Link--secondary" href="https://policies.google.com/privacy">プライバシーポリシー</a> と <a class="Link--secondary" href="https://policies.google.com/terms">利用規約</a> が適用されます。`,
	ko: html`이 사이트는 reCAPTCHA에 의해 보호되며 Google <a class="Link--secondary" href="https://policies.google.com/privacy">개인 정보 보호 정책</a> 및 <a class="Link--secondary" href="https://policies.google.com/terms">서비스 약관이</a> 적용됩니다.`,
	mww: html`Qhov chaw no yog tiv thaiv los ntawm reCAPTCHA thiab cov Google <a class="Link--secondary" href="https://policies.google.com/privacy">Privacy Policy</a> thiab <a class="Link--secondary" href="https://policies.google.com/terms">cov ntsiab lus uas</a> siv.`,
	ps: html`دا سایټ د reCAPTCHA لخوا خوندی دی او د ګوګل د <a class="Link--secondary" href="https://policies.google.com/privacy">محرمیت تګلاره</a> او <a class="Link--secondary" href="https://policies.google.com/terms">د خدماتو شرایط</a> پلی کیږی.`,
	pt: html`Este site é protegido por reCAPTCHA e a <a class="Link--secondary" href="https://policies.google.com/privacy">Política de Privacidade</a> e os <a class="Link--secondary" href="https://policies.google.com/terms">Termos de Serviço do</a> Google se aplicam.`,
	'pt-pt': html`Este site é protegido pelo reCAPTCHA e aplicam-se a <a class="Link--secondary" href="https://policies.google.com/privacy">Política de Privacidade</a> e <a class="Link--secondary" href="https://policies.google.com/terms">os Termos de Serviço do</a> Google.`,
	ru: html`Этот сайт защищен reCAPTCHA, и на него распространяются <a class="Link--secondary" href="https://policies.google.com/privacy">Политика конфиденциальности</a> и <a class="Link--secondary" href="https://policies.google.com/terms">Условия предоставления услуг</a> Google.`,
	sm: html`O lenei saite e puipuia e le reCAPTCHA ma e faatatau i le Google <a class="Link--secondary" href="https://policies.google.com/privacy">Privacy Policy</a> ma <a class="Link--secondary" href="https://policies.google.com/terms">Tuutuuga o le Auaunaga</a> .`,
	so: html`Boggan waxaa ilaaliya reCAPTCHA iyo <a class="Link--secondary" href="https://policies.google.com/privacy">Siyaasadda Khaaska ah</a> ee Google iyo <a class="Link--secondary" href="https://policies.google.com/terms">Shuruudaha Adeeggu waxay</a> codsan karaan.`,
	th: html`ไซต์นี้ได้รับการคุ้มครองโดย reCAPTCHA และมีผลบังคับใช้ <a class="Link--secondary" href="https://policies.google.com/privacy">นโยบายความเป็นส่วนตัว</a> และ <a class="Link--secondary" href="https://policies.google.com/terms">ข้อกําหนดในการให้บริการ</a> ของ Google`,
	ur: html`یہ سائٹ ری کیپچا کے ذریعہ محفوظ ہے اور گوگل <a class="Link--secondary" href="https://policies.google.com/privacy">پرائیویسی پالیسی</a> اور <a class="Link--secondary" href="https://policies.google.com/terms">سروس کی شرائط لاگو</a> ہوتی ہیں۔`,
	vi: html`Trang web này được bảo vệ bởi reCAPTCHA và <a class="Link--secondary" href="https://policies.google.com/privacy">Chính sách bảo mật</a> và <a class="Link--secondary" href="https://policies.google.com/terms">Điều khoản dịch vụ của</a> Google được áp dụng.`,
	'zh-hans': html`本网站受 reCAPTCHA 保护，并适用 Google <a class="Link--secondary" href="https://policies.google.com/privacy">隐私政策</a> 和 <a class="Link--secondary" href="https://policies.google.com/terms">服务条款</a> 。`,
	'zh-hant': html`本網站受 reCAPTCHA 保護，並適用 Google <a class="Link--secondary" href="https://policies.google.com/privacy">隱私政策</a> 和 <a class="Link--secondary" href="https://policies.google.com/terms">服務條款</a> 。`,
	en: html`This site is protected by reCAPTCHA and the Google <a class="Link--secondary" href="https://policies.google.com/privacy">Privacy Policy</a> and <a class="Link--secondary" href="https://policies.google.com/terms">Terms of Service</a> apply.`
};

export const loc_lightDefault: Record<Locale, string> = {
	ar: `الضوء الافتراضي`,
	de: `Leichter Ausfall`,
	es: `Luz predeterminada`,
	fa: `پیش فرض نور`,
	fil: `Light Default`,
	fr: `Lumière par défaut`,
	hi: `लाइट डीफॉल्ट`,
	ht: `Limyè default`,
	it: `Luce predefinita`,
	ja: `ライトのデフォルト`,
	ko: `라이트 디폴트`,
	mww: `Teeb Default`,
	ps: `تلوالیز نور`,
	pt: `Padrão leve`,
	'pt-pt': `Padrão de luz`,
	ru: `Светлый по умолчанию`,
	sm: `Tiumasani o le Malamalama`,
	so: `Light Default`,
	th: `ค่าเริ่มต้นของแสง`,
	ur: `لائٹ ڈیفالٹ`,
	vi: `Ánh sáng mặc định`,
	'zh-hans': `Light Default`,
	'zh-hant': `Light Default`,
	en: `Light Default`
};

export const loc_lightHighContrast: Record<Locale, string> = {
	ar: `ضوء التباين العالي`,
	de: `Licht Hoher Kontrast`,
	es: `Luz de alto contraste`,
	fa: `کنتراست بالا نور`,
	fil: `Banayad na Mataas na Kaibahan`,
	fr: `Lumière à contraste élevé`,
	hi: `लाइट हाई कंट्रास्ट`,
	ht: `Limyè gwo kontras`,
	it: `Luce ad alto contrasto`,
	ja: `ライトハイコントラスト`,
	ko: `가벼운 고대비`,
	mww: `Siab contrast`,
	ps: `رڼا لوړ رڼښت`,
	pt: `Luz de alto contraste`,
	'pt-pt': `Luz de Alto Contraste`,
	ru: `Свет, высокая контрастность`,
	sm: `Malamalama Maualuga le Eseesega`,
	so: `Light High Contrast`,
	th: `แสง คอนทราสต์สูง`,
	ur: `لائٹ ہائی کنٹراسٹ`,
	vi: `Độ tương phản cao ánh sáng`,
	'zh-hans': `Light High Contrast`,
	'zh-hant': `Light High Contrast`,
	en: `Light High Contrast`
};

export const loc_lightColorblind: Record<Locale, string> = {
	ar: `بروتانوبيا خفيفة وديوتيرانوبيا`,
	de: `Leichte Protanopie & Deuteranopie`,
	es: `Protanopia ligera y deuteranopia`,
	fa: `پروتانوپیا نور و دوترانوپیا`,
	fil: `Banayad na Protanopia & Deuteranopia`,
	fr: `Protanopie légère et deutéranopie`,
	hi: `लाइट प्रोटानोपिया और ड्यूटेरोनोपिया`,
	ht: `Limyè Protanopia & Deuteranopia`,
	it: `Protanopia leggera e deuteranopia`,
	ja: `ライトプロタノピアとデューセラノピア`,
	ko: `라이트 프로타노피아 & 듀테라노피아`,
	mww: `Teeb Protanopia & Deuteranopia`,
	ps: `رڼا Protanopia & Deuteranopia`,
	pt: `Protanopia Leve & Deuteranopia`,
	'pt-pt': `Protanopia leve & Deuteranopia`,
	ru: `Легкая протанопия и дейтеранопия`,
	sm: `Malamalama Protanopia & Deuteranopia`,
	so: `Light Protanopia & Deuteranopia`,
	th: `โปรตาโนเปียเบา และ Deuteranopia`,
	ur: `Light Protanopeia &Deuteranوپیا`,
	vi: `Protanopia ánh sáng & Deuteranopia`,
	'zh-hans': `轻红色盲 & 绿色盲`,
	'zh-hant': `輕紅色盲 & 綠色盲`,
	en: `Light Protanopia & Deuteranopia`
};

export const loc_lightTritanopia: Record<Locale, string> = {
	ar: `تريتانوبيا الخفيفة`,
	de: `Leichte Tritanopie`,
	es: `Tritanopia ligera`,
	fa: `Tritanopia سبک`,
	fil: `Banayad na Tritanopia`,
	fr: `Tritanopie légère`,
	hi: `प्रकाश ट्रिटेनोपिया`,
	ht: `Limyè Tritanopia`,
	it: `Tritanopia Chiara`,
	ja: `ライトトリタノピア`,
	ko: `라이트 트리타노피아`,
	mww: `Teeb Tritanopia`,
	ps: `رڼا Tritanopia`,
	pt: `Tritanopia leve`,
	'pt-pt': `Tritanopia leve`,
	ru: `Светлая тританопия`,
	sm: `Tritanopia Mama`,
	so: `Light Tritanopia`,
	th: `ไลท์ Tritanopia`,
	ur: `Light Tritanopia`,
	vi: `Tritanopia ánh sáng`,
	'zh-hans': `轻度蓝色盲`,
	'zh-hant': `輕度藍色盲`,
	en: `Light Tritanopia`
};

export const loc_darkDefault: Record<Locale, string> = {
	ar: `افتراضي مظلم`,
	de: `Dunkler Standard`,
	es: `Oscuro por defecto`,
	fa: `پیش فرض تاریک`,
	fil: `Madilim na Default`,
	fr: `Sombre par défaut`,
	hi: `डार्क डिफॉल्ट`,
	ht: `Default nwa`,
	it: `Scuro predefinito`,
	ja: `ダークデフォルト`,
	ko: `다크 디폴트`,
	mww: `Tsaus default`,
	ps: `تیاره تلوالیز`,
	pt: `Padrão escuro`,
	'pt-pt': `Padrão escuro`,
	ru: `Темный по умолчанию`,
	sm: `Tiumasani Pogisa`,
	so: `Dark Default`,
	th: `ค่าเริ่มต้นที่มืด`,
	ur: `ڈارک ڈیفالٹ`,
	vi: `Mặc định tối`,
	'zh-hans': `深色默认`,
	'zh-hant': `深色預設`,
	en: `Dark Default`
};

export const loc_darkHighContrast: Record<Locale, string> = {
	ar: `تباين عالي داكن`,
	de: `Dunkel Hoher Kontrast`,
	es: `Oscuro de alto contraste`,
	fa: `کنتراست بالا تیره`,
	fil: `madilim na mataas na kaibahan`,
	fr: `Contraste élevé foncé`,
	hi: `डार्क हाई कंट्रास्ट`,
	ht: `Nwa segondè kontras`,
	it: `Scuro ad alto contrasto`,
	ja: `ダークハイコントラスト`,
	ko: `어두운 고대비`,
	mww: `Tsaus nti contrast`,
	ps: `تیاره لوړ رڼښت`,
	pt: `Alto contraste escuro`,
	'pt-pt': `Alto contraste escuro`,
	ru: `Темный высококонтрастный`,
	sm: `Eseesega Maualuga Pogisa`,
	so: `Dark High Contrast`,
	th: `ความคมชัดสูงเข้ม`,
	ur: `ڈارک ہائی کنٹراسٹ`,
	vi: `Độ tương phản cao tối`,
	'zh-hans': `深色高对比度`,
	'zh-hant': `深色高對比度`,
	en: `Dark High Contrast`
};

export const loc_darkColorblind: Record<Locale, string> = {
	ar: `البروتانوبيا الداكنة والديوتيرانوبيا`,
	de: `Dunkle Protanopie & Deuteranopie`,
	es: `Protanopia oscura y deuteranopia`,
	fa: `پروتانوپیا تاریک و دوترانوپیا`,
	fil: `Madilim na Protanopia & Deuteranopia`,
	fr: `Protanopie foncée et deutéranopie`,
	hi: `डार्क प्रोटानोपिया & ड्यूटेरोनोपिया`,
	ht: `Pwotans nwa & Deuteranopia`,
	it: `Protanopia scura e deuteranopia`,
	ja: `ダークプロタノピアとデューセラノピア`,
	ko: `다크 프로타노피아 & 듀테라노피아`,
	mww: `Tsaus Protanopia & Deuteranopia`,
	ps: `Dark Protanopia & Deuteranopia`,
	pt: `Protanopia Escura & Deuteranopia`,
	'pt-pt': `Protanopia Escura & Deuteranopia`,
	ru: `Темная протанопия и дейтеранопия`,
	sm: `Protanopia Pogisa & Deuteranopia`,
	so: `Dark Protanopia & Deuteranopia`,
	th: `Dark Protanopia และ Deuteranopia`,
	ur: `ڈارک پروٹینوپیا اور ڈیوٹیرانوپیا`,
	vi: `Protanopia tối & Deuteranopia`,
	'zh-hans': `深色红色盲和绿色盲`,
	'zh-hant': `深色紅色盲和綠色盲`,
	en: `Dark Protanopia & Deuteranopia`
};

export const loc_darkTritanopia: Record<Locale, string> = {
	ar: `تريتانوبيا الظلام`,
	de: `Dunkle Tritanopie`,
	es: `Tritanopia oscura`,
	fa: `Tritanopia تاریک`,
	fil: `Madilim na Tritanopia`,
	fr: `Tritanopie foncée`,
	hi: `डार्क ट्रिटेनोपिया`,
	ht: `Tritanopia nwa`,
	it: `Tritanopia Scura`,
	ja: `ダークトリタノピア`,
	ko: `다크 트리타노피아`,
	mww: `Tsaus Nti Tritanopia`,
	ps: `Dark Tritanopia`,
	pt: `Tritanopia Escura`,
	'pt-pt': `Tritanopia escura`,
	ru: `Темная тританопия`,
	sm: `Tritanopia Pogisa`,
	so: `Dark Tritanopia`,
	th: `Dark Tritanopia`,
	ur: `Dark Tritanopia`,
	vi: `Tritanopia tối`,
	'zh-hans': `深色蓝色盲`,
	'zh-hant': `深色藍色盲`,
	en: `Dark Tritanopia`
};

export const loc_darkDimmed: Record<Locale, string> = {
	ar: `خافتة داكنة`,
	de: `Dunkel abgedunkelt`,
	es: `Atenuado oscuro`,
	fa: `تاریک کم نور`,
	fil: `madilim na dimmed`,
	fr: `Sombre atténué`,
	hi: `डार्क डिम्ड`,
	ht: `Nwa Dimmed`,
	it: `Scuro Oscurato`,
	ja: `ダーク ダーク`,
	ko: `어둡게 흐리게`,
	mww: `Tsaus Nti Dimmed`,
	ps: `تیاره Dimmed`,
	pt: `Escuro esmaecido`,
	'pt-pt': `Escurecido escuro`,
	ru: `Темный затемненный`,
	sm: `Ua Faavaivaia le Pogisa`,
	so: `Diirada madow`,
	th: `มืดสลัว`,
	ur: `اندھیرا دھندلا`,
	vi: `Tối mờ`,
	'zh-hans': `暗暗 暗`,
	'zh-hant': `暗暗 暗`,
	en: `Dark Dimmed`
};

export const loc_systemDefault: Record<Locale, string> = {
	ar: `النظام الافتراضي`,
	de: `Systemstandard`,
	es: `Predeterminado del sistema`,
	fa: `پیش فرض سیستم`,
	fil: `System Default`,
	fr: `Système par défaut`,
	hi: `सिस्टम डिफ़ॉल्ट`,
	ht: `Sistèm Default`,
	it: `Impostazioni predefinite del sistema`,
	ja: `システムデフォルト`,
	ko: `시스템 기본값`,
	mww: `System Default`,
	ps: `د غونډال تلوالیز`,
	pt: `Padrão do sistema`,
	'pt-pt': `Padrão do sistema`,
	ru: `Системные настройки по умолчанию`,
	sm: `Tiumasani o le Polokalama`,
	so: `System Default`,
	th: `ค่าเริ่มต้นของระบบ`,
	ur: `نظام طے شدہ`,
	vi: `Mặc định hệ thống`,
	'zh-hans': `系统默认值`,
	'zh-hant': `系統預設值`,
	en: `System Default`
};

export const loc_individualReferral: Record<Locale, string> = {
	ar: `الاحاله`,
	de: `Weiterempfehlung`,
	es: `Recomendación`,
	fa: `ارجاع`,
	fil: `Referral`,
	fr: `Ajournement`,
	hi: `रेफरल`,
	ht: `Rekòmandasyon`,
	it: `Deferimento`,
	ja: `紹介`,
	ko: `추천`,
	mww: `Xa mus cuag`,
	ps: `حواله`,
	pt: `Referência`,
	'pt-pt': `Encaminhamento`,
	ru: `Направление`,
	sm: `Faailoa Atu`,
	so: `Referral`,
	th: `อ้าง อิง`,
	ur: `ریفریل`,
	vi: `Giới thiệu`,
	'zh-hans': `转 介`,
	'zh-hant': `轉 介`,
	en: `Referral`
};

export const loc_individualTask: Record<Locale, string> = {
	ar: `مهمة`,
	de: `Aufgabe`,
	es: `Tarea`,
	fa: `وظیفه`,
	fil: `Gawain`,
	fr: `Tâche`,
	hi: `नियत कार्य`,
	ht: `Travay`,
	it: `Compito`,
	ja: `タスク`,
	ko: `과업`,
	mww: `hauj lwm`,
	ps: `دنده`,
	pt: `Tarefa`,
	'pt-pt': `Tarefa`,
	ru: `Задача`,
	sm: `Galuega`,
	so: `Hawlaha`,
	th: `งาน`,
	ur: `کام`,
	vi: `Nhiệm vụ`,
	'zh-hans': `任务`,
	'zh-hant': `任務`,
	en: `Task`
};

export const loc_individualQuestionnaire: Record<Locale, string> = {
	ar: `استبيان`,
	de: `Fragebogen`,
	es: `Cuestionario`,
	fa: `پرسشنامه`,
	fil: `Talatanungan`,
	fr: `Questionnaire`,
	hi: `प्रश्‍नावली`,
	ht: `Kesyonè`,
	it: `Questionario`,
	ja: `アンケート`,
	ko: `설문지`,
	mww: `Lus nug`,
	ps: `پوښتنلیک`,
	pt: `Questionário`,
	'pt-pt': `Questionário`,
	ru: `Анкета`,
	sm: `Fesili`,
	so: `haku`,
	th: `แบบสอบถาม`,
	ur: `استفسار نامہ`,
	vi: `Bản câu hỏi`,
	'zh-hans': `问卷`,
	'zh-hant': `問卷`,
	en: `Questionnaire`
};

export const loc_individualEncounter: Record<Locale, string> = {
	ar: `التقى`,
	de: `Treffen`,
	es: `Encuentro`,
	fa: `برخورد`,
	fil: `Makipagtagpo`,
	fr: `Rencontrer`,
	hi: `मुठभेड़`,
	ht: `Rankont`,
	it: `Incontro`,
	ja: `出会い`,
	ko: `만남`,
	mww: `ces yuav tsum`,
	ps: `مقابله`,
	pt: `Encontrar`,
	'pt-pt': `Encontro`,
	ru: `Встреча`,
	sm: `Feiloai`,
	so: `La kulanka`,
	th: `พบ`,
	ur: `ملاقات`,
	vi: `Gặp`,
	'zh-hans': `遇到`,
	'zh-hant': `遇到`,
	en: `Encounter`
};

export const loc_individualEpisode: Record<Locale, string> = {
	ar: `حلقة من الرعاية`,
	de: `Episode der Pflege`,
	es: `Episodio de cuidado`,
	fa: `قسمت مراقبت`,
	fil: `Episode ng pag aalaga`,
	fr: `Épisode de soins`,
	hi: `देखभाल का प्रकरण`,
	ht: `Epizòd swen`,
	it: `Episodio di cura`,
	ja: `お手入れのエピソード`,
	ko: `보살핌의 에피소드`,
	mww: `rov xyuas dua`,
	ps: `د پاملرنې برخه`,
	pt: `Episódio de cuidado`,
	'pt-pt': `Episódio de cuidado`,
	ru: `Эпизод ухода`,
	sm: `Vaega o le tausiga`,
	so: `Dhacdooyinka daryeelka`,
	th: `ตอนของการดูแล`,
	ur: `دیکھ بھال کی قسط`,
	vi: `Tập chăm sóc`,
	'zh-hans': `护理发作`,
	'zh-hant': `護理髮作`,
	en: `Episode of care`
};

export const loc_individualConsent: Record<Locale, string> = {
	ar: `موافقه`,
	de: `Einwilligen`,
	es: `Consentimiento`,
	fa: `رضایت`,
	fil: `Pahintulot`,
	fr: `Consentement`,
	hi: `अनुमति`,
	ht: `Konsantman`,
	it: `Consenso`,
	ja: `同意`,
	ko: `승낙`,
	mww: `Kev Tso Cai`,
	ps: `رضایت`,
	pt: `Consentimento`,
	'pt-pt': `Consentimento`,
	ru: `Согласие`,
	sm: `Maliega`,
	so: `Ogolaanshaha`,
	th: `ยินยอม`,
	ur: `اجازت`,
	vi: `Đồng ý`,
	'zh-hans': `同意`,
	'zh-hant': `同意`,
	en: `Consent`
};

export const loc_organizationTask: Record<Locale, string> = {
	ar: `مهمة المنظمة`,
	de: `Aufgabe der Organisation`,
	es: `Tarea de organización`,
	fa: `وظیفه سازمان`,
	fil: `Gawain ng organisasyon`,
	fr: `Tâche d’organisation`,
	hi: `संगठन का कार्य`,
	ht: `Travay òganizasyon`,
	it: `Attività organizzativa`,
	ja: `組織のタスク`,
	ko: `조직 작업`,
	mww: `Koom haum ua hauj lwm`,
	ps: `د سازمان دنده`,
	pt: `Tarefa da organização`,
	'pt-pt': `Tarefa da organização`,
	ru: `Задача организации`,
	sm: `Galuega a le faalapotopotoga`,
	so: `Hay'adda Hawsha`,
	th: `งานขององค์กร`,
	ur: `تنظیم کا کام`,
	vi: `Nhiệm vụ tổ chức`,
	'zh-hans': `组织任务`,
	'zh-hant': `組織任務`,
	en: `Organization task`
};

export const loc_organizationQuestionnaire: Record<Locale, string> = {
	ar: `استبيان المنظمة`,
	de: `Fragebogen zur Organisation`,
	es: `Cuestionario de organización`,
	fa: `پرسشنامه سازمان`,
	fil: `Talatanungan ng organisasyon`,
	fr: `Questionnaire d’organisation`,
	hi: `संगठन प्रश्नावली`,
	ht: `Kesyonè òganizasyon`,
	it: `Questionario sull'organizzazione`,
	ja: `組織アンケート`,
	ko: `조직 설문지`,
	mww: `Koom haum questionnaire`,
	ps: `د سازمان پوښتنلیک`,
	pt: `Questionário da organização`,
	'pt-pt': `Questionário de organização`,
	ru: `Организационная анкета`,
	sm: `Fesili a le faalapotopotoga`,
	so: `Ururka ee su'aalaha`,
	th: `แบบสอบถามองค์กร`,
	ur: `تنظیم کا سوالنامہ`,
	vi: `Bảng câu hỏi tổ chức`,
	'zh-hans': `组织调查问卷`,
	'zh-hant': `組織調查問卷`,
	en: `Organization questionnaire`
};

export const loc_organizationEvent: Record<Locale, string> = {
	ar: `حدث`,
	de: `Ereignis`,
	es: `Evento`,
	fa: `رویداد`,
	fil: `Kaganapan`,
	fr: `Événement`,
	hi: `घटना`,
	ht: `Evenman`,
	it: `Evento`,
	ja: `出来事`,
	ko: `이벤트`,
	mww: `kev tshwm sim`,
	ps: `پېښه`,
	pt: `Acontecimento`,
	'pt-pt': `Evento`,
	ru: `Событие`,
	sm: `Mea na Tupu`,
	so: `Dhacdooyinka`,
	th: `เหตุการณ์`,
	ur: `احوال`,
	vi: `Sự kiện`,
	'zh-hans': `事件`,
	'zh-hant': `事件`,
	en: `Event`
};

export const loc_individualReferralDesc: Record<Locale, string> = {
	ar: `سجل لطلب الخدمة مثل الفحوصات التشخيصية أو العلاجات أو العمليات التي يتعين إجراؤها.`,
	de: `Eine Aufzeichnung einer Serviceanforderung, z. B. diagnostische Untersuchungen, Behandlungen oder durchzuführende Operationen.`,
	es: `Un registro de una solicitud de servicio, como investigaciones diagnósticas, tratamientos u operaciones que se realizarán.`,
	fa: `سابقه درخواست خدمات مانند تحقیقات تشخیصی، درمان ها یا عملیاتی که باید انجام شود.`,
	fil: `Isang talaan ng isang kahilingan para sa serbisyo tulad ng mga pagsisiyasat sa diagnostic, paggamot, o operasyon na isasagawa.`,
	fr: `Enregistrement d’une demande de service, comme des investigations diagnostiques, des traitements ou des opérations à effectuer.`,
	hi: `सेवा के लिए अनुरोध का एक रिकॉर्ड, जैसे नैदानिक जांच, उपचार, या ऑपरेशन किए जाने के लिए।`,
	ht: `Yon dosye sou yon demann pou sèvis tankou ankèt dyagnostik, tretman, oswa operasyon yo dwe fèt.`,
	it: `Una registrazione di una richiesta di servizio come indagini diagnostiche, trattamenti o operazioni da eseguire.`,
	ja: `診断調査、治療、または実行する操作などのサービスの要求の記録。`,
	ko: `진단 조사, 치료 또는 수행할 수술과 같은 서비스 요청 기록입니다.`,
	mww: `Ib daim ntawv thov kev pab xws li kev tshawb nrhiav txog kev tshawb nrhiav, kev kho mob, los yog haujlwm ua tau.`,
	ps: `د خدمت لپاره د غوښتنې ریکارډ لکه د تشخیص تحقیقات ، درملنې ، یا عملیات چې ترسره کیږی.`,
	pt: `Um registro de uma solicitação de serviço, como investigações diagnósticas, tratamentos ou operações a serem realizadas.`,
	'pt-pt': `Um registro de uma solicitação de serviço, como investigações de diagnóstico, tratamentos ou operações a serem realizadas.`,
	ru: `Запись о запросе на такую услугу, как диагностические исследования, лечение или операции, которые должны быть выполнены.`,
	sm: `O se faamaumauga o se talosaga mo se auaunaga e pei o suesuega o suesuega, togafitiga, po o taotoga e tatau ona faia.`,
	so: `Diiwaanka codsi adeeg sida baaritaan baaris, daaweyn, ama qalliin la sameyn doono.`,
	th: `บันทึกคําขอบริการ เช่น การตรวจวินิจฉัย การรักษา หรือการผ่าตัดที่จะดําเนินการ`,
	ur: `خدمات کی درخواست کا ایک ریکارڈ جیسے تشخیصی تحقیقات، علاج، یا انجام دیئے جانے والے آپریشن.`,
	vi: `Hồ sơ yêu cầu dịch vụ như điều tra chẩn đoán, điều trị hoặc phẫu thuật sẽ được thực hiện.`,
	'zh-hans': `服务请求的记录，例如诊断调查、治疗或要执行的操作。`,
	'zh-hant': `服務請求的記錄，例如診斷調查、治療或要執行的操作。`,
	en: `A record of a request for service such as diagnostic investigations, treatments, or operations to be performed.`
};

export const loc_individualTaskDesc: Record<Locale, string> = {
	ar: `مهمة تتعلق بعميل مثل المتابعة التي يمكن تنفيذها.`,
	de: `Eine Aufgabe, die sich auf einen Kunden bezieht, z. B. eine Wiederverfolgung, die ausgeführt werden kann.`,
	es: `Una tarea relacionada con un cliente, como un seguimiento que se puede realizar.`,
	fa: `یک کار مربوط به مشتری مانند پیگیری که می تواند انجام شود.`,
	fil: `Isang gawain na may kaugnayan sa isang kliyente tulad ng isang follow up na maaaring isagawa.`,
	fr: `Tâche liée à un client, telle qu’un suivi, qui peut être effectuée.`,
	hi: `क्लाइंट से संबंधित एक कार्य, जैसे अनुवर्ती कार्रवाई जिसे निष्पादित किया जा सकता है।`,
	ht: `Yon travay ki gen rapò ak yon kliyan tankou yon swivi ki ka fèt.`,
	it: `Un'attività correlata a un client, ad esempio un follow-up che può essere eseguito.`,
	ja: `クライアントに関連するタスク (フォローアップなど、実行可能なもの)。`,
	ko: `수행할 수 있는 후속 작업과 같은 클라이언트와 관련된 작업입니다.`,
	mww: `Ib tug neeg ua hauj lwm hais txog ib tug neeg tau txais kev pab xws li ib tug ua raws li uas yuav tau.`,
	ps: `د پیرودونکی پورې اړوند دنده لکه تعقیب چې ترسره کیدی شی.`,
	pt: `Uma tarefa relacionada a um cliente, como um acompanhamento que pode ser executado.`,
	'pt-pt': `Uma tarefa relacionada a um cliente, como um acompanhamento que pode ser executado.`,
	ru: `Задача, связанная с клиентом, например, последующая деятельность, которую можно выполнить.`,
	sm: `O se galuega e faatatau i se tagata e faaaogaina e pei o se tulitatao e mafai ona faatinoina.`,
	so: `Hawl la xiriira macmiilka, sida dabagal la sameyn karo oo kale.`,
	th: `งานที่เกี่ยวข้องกับลูกค้า เช่น การติดตามผลที่สามารถทําได้`,
	ur: `کلائنٹ سے متعلق ایک کام جیسے فالو اپ جو انجام دیا جاسکتا ہے۔`,
	vi: `Một nhiệm vụ liên quan đến khách hàng như theo dõi có thể được thực hiện.`,
	'zh-hans': `与客户相关的任务，例如可以执行的后续行动。`,
	'zh-hant': `與客戶相關的任務，例如可以執行的後續行動。`,
	en: `A task related to a client such as a follow-up that can be performed.`
};

export const loc_individualQuestionnaireDesc: Record<Locale, string> = {
	ar: `مجموعة منظمة من الأسئلة المتعلقة بالعميل ، تهدف إلى توجيه مجموعة الإجابات من المستخدمين النهائيين.`,
	de: `Ein strukturierter Satz von Fragen zu einem Kunden, der das Sammeln von Antworten von Endbenutzern leiten soll.`,
	es: `Un conjunto estructurado de preguntas relacionadas con un cliente, destinadas a guiar la recopilación de respuestas de los usuarios finales.`,
	fa: `مجموعه ای ساختاریافته از سوالات مربوط به مشتری که برای هدایت مجموعه پاسخ ها از کاربران نهایی در نظر گرفته شده است.`,
	fil: `Ang isang nakabalangkas na hanay ng mga katanungan na may kaugnayan sa isang kliyente, na nilayon upang gabayan ang koleksyon ng mga sagot mula sa mga end user.`,
	fr: `Ensemble structuré de questions relatives à un client, destiné à guider la collecte des réponses des utilisateurs finaux.`,
	hi: `क्लाइंट से संबंधित प्रश्नों का एक संरचित सेट, जिसका उद्देश्य अंतिम उपयोगकर्ताओं से उत्तरों के संग्रह का मार्गदर्शन करना है।`,
	ht: `Yon seri estriktire nan kesyon ki gen rapò ak yon kliyan, gen entansyon gide koleksyon an nan repons soti nan itilizatè fen-itilizatè yo.`,
	it: `Un insieme strutturato di domande relative a un cliente, destinato a guidare la raccolta delle risposte da parte degli utenti finali.`,
	ja: `クライアントに関連する構造化された一連の質問で、エンドユーザーからの回答の収集をガイドすることを目的としています。`,
	ko: `최종 사용자의 답변 수집을 안내하기 위한 클라이언트와 관련된 구조화된 질문 집합입니다.`,
	mww: `Ib tug qauv teev cov lus nug txog ib tug neeg tau txais kev pab, npaj los qhia cov lus teb los ntawm cov lus teb los ntawm cov neeg siv end-users.`,
	ps: `د مراجعینو پورې اړوند د پوښتنو جوړ شوی سیټ ، موخه یې د پای کاروونکو څخه د ځوابونو راټولولو لارښود کول دی.`,
	pt: `Um conjunto estruturado de perguntas relacionadas a um cliente, destinado a orientar a coleta de respostas dos usuários finais.`,
	'pt-pt': `Um conjunto estruturado de perguntas relacionadas com um cliente, destinado a orientar a recolha de respostas dos utilizadores finais.`,
	ru: `Структурированный набор вопросов, связанных с клиентом, предназначенный для руководства сбором ответов от конечных пользователей.`,
	sm: `O se seti faatulagaina o fesili e faatatau i se tagata e faaaogaina, ua faamoemoe e taialaina ai le aoina mai o tali mai tagata e faaaogaina.`,
	so: `Su'aalo qaabaysan oo la xidhiidha macmiilka, loogu talagalay in lagu hago ururinta jawaabaha laga helayo dhammaystirka isticmaalayaasha.`,
	th: `ชุดคําถามที่มีโครงสร้างที่เกี่ยวข้องกับลูกค้า โดยมีจุดประสงค์เพื่อเป็นแนวทางในการรวบรวมคําตอบจากผู้ใช้ปลายทาง`,
	ur: `کلائنٹ سے متعلق سوالات کا ایک منظم سیٹ ، جس کا مقصد اختتامی صارفین سے جوابات کے مجموعہ کی رہنمائی کرنا ہے۔`,
	vi: `Một tập hợp các câu hỏi có cấu trúc liên quan đến khách hàng, nhằm hướng dẫn thu thập câu trả lời từ người dùng cuối.`,
	'zh-hans': `与客户相关的一组结构化问题，旨在指导最终用户收集答案。`,
	'zh-hant': `與客戶相關的一組結構化問題，旨在指導最終使用者收集答案。`,
	en: `A structured set of questions related to a client, intended to guide the collection of answers from end-users.`
};

export const loc_individualEncounterDesc: Record<Locale, string> = {
	ar: `تفاعل بين المريض ومقدم (مقدمي) الرعاية الصحية لغرض تقديم خدمة (خدمات) الرعاية الصحية أو تقييم الحالة الصحية للمريض.`,
	de: `Eine Interaktion zwischen einem Patienten und einem oder mehreren Gesundheitsdienstleistern zum Zwecke der Erbringung von Gesundheitsdienstleistungen oder zur Beurteilung des Gesundheitszustands eines Patienten.`,
	es: `Una interacción entre un paciente y los proveedores de atención médica con el fin de proporcionar servicios de atención médica o evaluar el estado de salud de un paciente.`,
	fa: `تعامل بین بیمار و ارائه دهنده(های) مراقبت های بهداشتی به منظور ارائه خدمات(های) مراقبت های بهداشتی یا ارزیابی وضعیت سلامت بیمار.`,
	fil: `Isang interaksyon sa pagitan ng isang pasyente at (mga) healthcare provider para sa layunin ng pagbibigay ng (mga) serbisyo sa pangangalagang pangkalusugan o pagtatasa ng katayuan ng kalusugan ng isang pasyente.`,
	fr: `Interaction entre un patient et un ou plusieurs fournisseurs de soins de santé dans le but de fournir des services de santé ou d’évaluer l’état de santé d’un patient.`,
	hi: `स्वास्थ्य देखभाल सेवा प्रदान करने या रोगी के स्वास्थ्य की स्थिति का आकलन करने के उद्देश्य से रोगी और स्वास्थ्य सेवा प्रदाता (प्रदाताओं) के बीच एक बातचीत।`,
	ht: `Yon entèraksyon ant yon pasyan ak founisè swen sante (yo) nan bi pou yo bay sèvis swen sante (yo) oswa evalye estati sante yon pasyan.`,
	it: `Un'interazione tra un paziente e uno o più operatori sanitari allo scopo di fornire servizi sanitari o valutare lo stato di salute di un paziente.`,
	ja: `医療サービスの提供または患者の健康状態の評価を目的とした、患者と医療提供者との間の相互作用。`,
	ko: `의료 서비스를 제공하거나 환자의 건강 상태를 평가하기 위한 목적의 환자와 의료 서비스 제공자 간의 상호 작용.`,
	mww: `Kev sib tshuam ntawm ib tug neeg mob thiab tus neeg muab kev pab kho mob(cov) rau lub hom phiaj ntawm kev muab kev pab kho mob los yog ntsuam xyuas txoj kev noj qab haus huv ntawm ib tug neeg mob.`,
	ps: `د روغتیا پاملرنې خدماتو چمتو کولو یا د ناروغ روغتیا وضعیت ارزولو لپاره د ناروغ او روغتیا پاملرنې چمتو کونکو ترمینځ متقابل عمل.`,
	pt: `Uma interação entre um paciente e o(s) provedor(es) de saúde com a finalidade de fornecer serviço(s) de saúde ou avaliar o estado de saúde de um paciente.`,
	'pt-pt': `Interação entre um doente e o(s) prestador(es) de cuidados de saúde para efeitos de prestação de serviços de saúde ou de avaliação do estado de saúde de um doente.`,
	ru: `Взаимодействие между пациентом и поставщиком (поставщиками) медицинских услуг с целью предоставления медицинской услуги (услуг) или оценки состояния здоровья пациента.`,
	sm: `O se fegalegaleaiga i le va o se gasegase ma se tagata e tuuina atu le tausiga faalesoifua maloloina mo le faamoemoega o le tuuina atu o auaunaga faalesoifua maloloina po o le iloiloina o le tulaga faalesoifua maloloina o se gasegase.`,
	so: `Isdhexgalka u dhexeeya bukaanka iyo bixiyaha daryeelka caafimaadka (s) ujeedada laga leeyahay bixinta adeega daryeelka caafimaadka (s) ama qiimaynta xaaladda caafimaad ee bukaanka.`,
	th: `ปฏิสัมพันธ์ระหว่างผู้ป่วยและผู้ให้บริการด้านการดูแลสุขภาพเพื่อวัตถุประสงค์ในการให้บริการด้านสุขภาพหรือประเมินสถานะสุขภาพของผู้ป่วย`,
	ur: `صحت کی دیکھ بھال کی خدمات فراہم کرنے یا مریض کی صحت کی حالت کا اندازہ کرنے کے مقصد کے لئے مریض اور صحت کی دیکھ بھال فراہم کرنے والے کے درمیان تعامل۔`,
	vi: `Tương tác giữa bệnh nhân và (các) nhà cung cấp dịch vụ chăm sóc sức khỏe nhằm mục đích cung cấp (các) dịch vụ chăm sóc sức khỏe hoặc đánh giá tình trạng sức khỏe của bệnh nhân.`,
	'zh-hans': `患者与医疗保健提供者之间为提供医疗保健服务或评估患者健康状况而进行的互动。`,
	'zh-hant': `患者與醫療保健提供者之間為提供醫療保健服務或評估患者健康情況而進行的互動。`,
	en: `An interaction between a patient and healthcare provider(s) for the purpose of providing healthcare service(s) or assessing the health status of a patient.`
};

export const loc_individualEpisodeDesc: Record<Locale, string> = {
	ar: `ارتباط بين المريض ومنظمة / مقدم (مقدمي) الرعاية الصحية قد تحدث خلاله لقاءات. تتحمل المنظمة الإدارية مستوى من المسؤولية عن المريض خلال هذا الوقت.`,
	de: `Eine Verbindung zwischen einem Patienten und einer Organisation / einem oder mehreren Gesundheitsdienstleistern, in deren Verlauf Begegnungen auftreten können. Die leitende Organisation übernimmt in dieser Zeit ein gewisses Maß an Verantwortung für den Patienten.`,
	es: `Una asociación entre un paciente y una organización/proveedor(es) de atención médica durante la cual pueden ocurrir encuentros temporales. La organización gestora asume un nivel de responsabilidad para con el paciente durante este tiempo.`,
	fa: `ارتباط بین بیمار و یک سازمان / ارائه دهنده مراقبت های بهداشتی که در طی آن ممکن است برخوردهایی رخ دهد. سازمان مدیریت در این مدت سطحی از مسئولیت را در قبال بیمار بر عهده می گیرد.`,
	fil: `Ang isang asosasyon sa pagitan ng isang pasyente at isang organisasyon / (mga) healthcare provider sa panahon na kung saan ang mga nakatagpo ay maaaring mangyari. Ang namamahala sa organisasyon ay nagpapalagay ng isang antas ng responsibilidad para sa pasyente sa panahong ito.`,
	fr: `Association entre un patient et une organisation ou un ou plusieurs prestataires de soins de santé au cours de laquelle des rencontres peuvent avoir lieu. L’organisation de gestion assume un certain niveau de responsabilité pour le patient pendant cette période.`,
	hi: `एक रोगी और एक संगठन / स्वास्थ्य सेवा प्रदाता (ओं) के बीच एक संबंध जिसके दौरान समय मुठभेड़ हो सकती है। प्रबंध संगठन इस समय के दौरान रोगी के लिए जिम्मेदारी का एक स्तर मानता है।`,
	ht: `Yon asosyasyon ant yon pasyan ak yon òganizasyon / founisè swen sante (yo) pandan ki tan rankont ka rive. Òganizasyon jere a pran yon nivo responsablite pou pasyan an pandan tan sa a.`,
	it: `Un'associazione tra un paziente e un'organizzazione/operatori sanitari durante la quale possono verificarsi incontri di tempo. L'organizzazione di gestione si assume un livello di responsabilità nei confronti del paziente durante questo periodo.`,
	ja: `患者と組織/医療提供者との間の関連付けで、その間に遭遇が発生する可能性があります。この期間中、管理組織は患者に対してある程度の責任を引き受けます。`,
	ko: `환자와 조직/의료 서비스 제공자 간의 연관성으로, 이 기간 동안 만남이 발생할 수 있습니다. 관리 조직은 이 기간 동안 환자에 대한 일정 수준의 책임을 집니다.`,
	mww: `Ib lub koom haum los ntawm ib tug neeg mob thiab ib lub koom haum / ib lub koom haum / cov kws kho mob /cov kws kho mob yuav tshwm sim thaum lub sij hawm uas tej zaum yuav tshwm sim. Lub koom haum tswj lub koom haum assumes ib theem ntawm lub luag hauj lwm rau tus neeg mob lub sij hawm no.`,
	ps: `د ناروغ او یوې ادارې / روغتیا پاملرنې چمتو کونکی ترمینځ اړیکه چې په کوم وخت کې پیښې پیښیږی. د دې وخت په جریان کې د ناروغ لپاره د مدیریت اداره مسؤلیت په غاړه اخلی.`,
	pt: `Uma associação entre um paciente e uma organização / provedor (es) de saúde durante o qual os encontros podem ocorrer. A organização gestora assume um nível de responsabilidade pelo paciente durante esse período.`,
	'pt-pt': `Uma associação entre um paciente e uma organização/prestador de cuidados de saúde durante o qual podem ocorrer encontros. A organização gestora assume um nível de responsabilidade pelo paciente durante este tempo.`,
	ru: `Связь между пациентом и организацией/поставщиком медицинских услуг, в течение которой могут происходить встречи. Управляющая организация берет на себя определенный уровень ответственности за пациента в течение этого времени.`,
	sm: `O se sootaga i le va o se gasegase ma se faalapotopotoga/tagata e tuuina atu le tausiga faalesoifua maloloina i le taimi e ono tupu ai ni fetaiaiga. O le faalapotopotoga pule e tauaveina se tulaga o le tiutetauave mo le gasegase i le taimi lea.`,
	so: `Xidhiidh dhexmara bukaanka iyo hay'ad bixiyaha /daryeelka caafimaadka(s) kaas oo ay dhici karto waqti kulmay. Ururka maamulku waxa uu xil ka saaran yahay mas'uuliyadda bukaanka muddadan.`,
	th: `ความสัมพันธ์ระหว่างผู้ป่วยกับองค์กร / ผู้ให้บริการด้านการดูแลสุขภาพในช่วงเวลาที่อาจเกิดการเผชิญหน้า องค์กรจัดการจะรับผิดชอบต่อผู้ป่วยในระดับหนึ่งในช่วงเวลานี้`,
	ur: `ایک مریض اور ایک تنظیم / صحت کی دیکھ بھال فراہم کرنے والے کے درمیان ایک تعلق جس کے دوران ملاقاتیں ہوسکتی ہیں۔ مینیجنگ آرگنائزیشن اس وقت کے دوران مریض کے لئے ذمہ داری کی ایک سطح قبول کرتی ہے۔`,
	vi: `Mối liên hệ giữa bệnh nhân và (các) tổ chức / nhà cung cấp dịch vụ chăm sóc sức khỏe trong thời gian đó các cuộc gặp gỡ có thể xảy ra. Tổ chức quản lý đảm nhận một mức độ trách nhiệm đối với bệnh nhân trong thời gian này.`,
	'zh-hans': `患者与组织/医疗保健提供者之间的关联，在此期间可能会发生相遇。在此期间，管理组织对患者承担一定程度的责任。`,
	'zh-hant': `患者與組織/醫療保健提供者之間的關聯，在此期間可能會發生相遇。在此期間，管理組織對患者承擔一定程度的責任。`,
	en: `An association between a patient and an organization / healthcare provider(s) during which time encounters may occur. The managing organization assumes a level of responsibility for the patient during this time.`
};

export const loc_individualConsentDesc: Record<Locale, string> = {
	ar: `سجل لاتفاقية جمع المعلومات أو الوصول إليها أو استخدامها أو الكشف عنها (مشاركتها).`,
	de: `Eine Aufzeichnung einer Vereinbarung über die Erfassung, den Zugriff, die Nutzung oder die Offenlegung (Weitergabe) von Informationen.`,
	es: `Un registro de un acuerdo para recopilar, acceder, usar o divulgar (compartir) información.`,
	fa: `سابقه توافق برای جمع آوری، دسترسی، استفاده یا افشای (اشتراک گذاری) اطلاعات.`,
	fil: `Isang talaan ng isang kasunduan upang mangolekta, ma access, gamitin o ibunyag (ibahagi) ang impormasyon.`,
	fr: `Enregistrement d’une entente de collecte, d’accès, d’utilisation ou de divulgation (partage) de renseignements.`,
	hi: `सूचना एकत्र करने, एक्सेस करने, उपयोग करने या प्रकट करने (साझा करने) के लिए किसी करार का रिकॉर्ड ।`,
	ht: `Yon dosye sou yon akò pou kolekte, aksè, itilize oswa divilge enfòmasyon (pataje) enfòmasyon.`,
	it: `Una registrazione di un accordo per la raccolta, l'accesso, l'utilizzo o la divulgazione (condivisione) di informazioni.`,
	ja: `情報の収集、アクセス、使用、または開示(共有)に関する合意の記録。`,
	ko: `정보를 수집, 액세스, 사용 또는 공개(공유)하기 위한 계약 기록.`,
	mww: `Ib daim ntawv cog lus los mus sau, siv los yog sib faib tawm (sib faib tawm)`,
	ps: `د معلوماتو راټولولو ، لاسرسی ، کارولو یا افشا کولو لپاره د تړون ریکارډ.`,
	pt: `Um registro de um contrato para coletar, acessar, usar ou divulgar (compartilhar) informações.`,
	'pt-pt': `Um registo de um acordo para recolher, aceder, utilizar ou divulgar (partilhar) informações.`,
	ru: `Запись о согласии на сбор, доступ, использование или раскрытие (обмен) информации.`,
	sm: `O se faamaumauga o se maliega e ao mai, faaaoga, faaaoga pe faailoa atu (faasoa atu) faamatalaga.`,
	so: `Diiwaanka heshiiska macluumaadka la ururinayo, ku helitaanka, la isticmaalayo ama la shaacinayo (Share) macluumaadka.`,
	th: `บันทึกข้อตกลงในการเก็บรวบรวม เข้าถึง ใช้ หรือเปิดเผย (แบ่งปัน) ข้อมูล`,
	ur: `معلومات جمع کرنے ، رسائی حاصل کرنے ، استعمال کرنے یا ظاہر کرنے (اشتراک) کرنے کے معاہدے کا ریکارڈ۔`,
	vi: `Hồ sơ thỏa thuận thu thập, truy cập, sử dụng hoặc tiết lộ (chia sẻ) thông tin.`,
	'zh-hans': `收集、访问、使用或披露（共享）信息的协议记录。`,
	'zh-hant': `收集、訪問、使用或披露（共用）信息的協議記錄。`,
	en: `A record of an agreement to collect, access, use or disclose (share) information.`
};

export const loc_organizationTaskDesc: Record<Locale, string> = {
	ar: `مهمة تستهدف مؤسسة أو مستخدما، لا علاقة لها بعميل معين.`,
	de: `Eine Aufgabe, die auf eine Organisation oder einen Benutzer abzielt und sich nicht auf einen bestimmten Client bezieht.`,
	es: `Una tarea dirigida a una organización o usuario, no relacionada con un cliente específico.`,
	fa: `وظیفه ای که یک سازمان یا کاربر را هدف قرار می دهد و به یک مشتری خاص مرتبط نیست.`,
	fil: `Isang gawain na nagta target sa isang organisasyon o gumagamit, na walang kaugnayan sa isang tiyak na kliyente.`,
	fr: `Tâche ciblant une organisation ou un utilisateur, sans rapport avec un client spécifique.`,
	hi: `किसी संगठन या उपयोगकर्ता को लक्षित करने वाला कार्य, जो किसी विशिष्ट क्लाइंट से संबंधित नहीं है.`,
	ht: `Yon travay ki vize yon òganizasyon oswa itilizatè, ki pa gen rapò ak yon kliyan espesifik.`,
	it: `Un'attività destinata a un'organizzazione o a un utente, non correlata a un cliente specifico.`,
	ja: `特定のクライアントとは無関係の組織またはユーザーを対象とするタスク。`,
	ko: `특정 클라이언트와 관련이 없는 조직 또는 사용자를 대상으로 하는 작업입니다.`,
	mww: `Ib tug neeg ua hauj lwm targeting ib lub koom haum los yog neeg siv, unrelated rau ib tug neeg tau txais kev pab.`,
	ps: `یوه دنده چې یو سازمان یا کارونکی په نښه کوی ، د ځانګړی پیرودونکی سره تړاو نلری.`,
	pt: `Uma tarefa direcionada a uma organização ou usuário, não relacionada a um cliente específico.`,
	'pt-pt': `Uma tarefa direcionada a uma organização ou usuário, não relacionada a um cliente específico.`,
	ru: `Задача, ориентированная на организацию или пользователя, не связанная с конкретным клиентом.`,
	sm: `O se galuega e tulimataia ai se faalapotopotoga po o se tagata e faaaogaina, e le fesootai ma se tagata e faapitoa lona tautuaina.`,
	so: `Hawl lagu beegsanayo urur ama isticmaale, oo aan xiriir la lahayn macmiil gaar ah.`,
	th: `งานที่กําหนดเป้าหมายไปยังองค์กรหรือผู้ใช้ที่ไม่เกี่ยวข้องกับลูกค้าที่เฉพาะเจาะจง`,
	ur: `ایک ایسا کام جو کسی تنظیم یا صارف کو نشانہ بناتا ہے ، جس کا کسی مخصوص کلائنٹ سے کوئی تعلق نہیں ہے۔`,
	vi: `Một tác vụ nhắm mục tiêu đến một tổ chức hoặc người dùng, không liên quan đến một khách hàng cụ thể.`,
	'zh-hans': `以组织或用户为目标的任务，与特定客户端无关。`,
	'zh-hant': `以組織或用戶為目標的任務，與特定客戶端無關。`,
	en: `A task targeting an organization or user, unrelated to a specific client.`
};

export const loc_organizationQuestionnaireDesc: Record<Locale, string> = {
	ar: `مجموعة من الأسئلة المتعلقة بمؤسسة أو مستخدم ، لا علاقة لها بعميل معين.`,
	de: `Eine Reihe von Fragen, die sich auf eine Organisation oder einen Benutzer beziehen, die sich nicht auf einen bestimmten Client beziehen.`,
	es: `Un conjunto de preguntas relacionadas con una organización o usuario, no relacionadas con un cliente específico.`,
	fa: `مجموعه ای از سوالات مربوط به یک سازمان یا کاربر، غیر مرتبط با یک مشتری خاص.`,
	fil: `Isang hanay ng mga tanong na may kaugnayan sa isang organisasyon o gumagamit, na walang kaugnayan sa isang tiyak na kliyente.`,
	fr: `Ensemble de questions relatives à une organisation ou à un utilisateur, sans rapport avec un client spécifique.`,
	hi: `किसी संगठन या प्रयोक्ता से संबंधित प्रश्नों का समूह, जो किसी विशिष्ट ग्राहक से संबंधित नहीं होता है.`,
	ht: `Yon seri kesyon ki gen rapò ak yon òganizasyon oswa itilizatè, ki pa gen rapò ak yon kliyan espesifik.`,
	it: `Un insieme di domande relative a un'organizzazione o a un utente, non correlate a un cliente specifico.`,
	ja: `特定のクライアントとは無関係で、組織またはユーザーに関連する一連の質問。`,
	ko: `특정 클라이언트와 관련이 없는 조직 또는 사용자와 관련된 질문 집합입니다.`,
	mww: `Ib cov lus nug txog ib lub koom haum los yog neeg siv, unrelated rau ib tug neeg tau txais kev pab.`,
	ps: `د یوې ادارې یا کارونکی پورې اړوند د پوښتنو سیټ ، د ځانګړی پیرودونکی سره تړاو نلری.`,
	pt: `Um conjunto de perguntas relacionadas a uma organização ou usuário, não relacionadas a um cliente específico.`,
	'pt-pt': `Um conjunto de perguntas relacionadas a uma organização ou usuário, não relacionadas a um cliente específico.`,
	ru: `Набор вопросов, относящихся к организации или пользователю, не относящихся к конкретному клиенту.`,
	sm: `O se seti o fesili e faatatau i se faalapotopotoga po o se tagata e faaaogaina, e le fesootai ma se tagata e faaaogaina patino.`,
	so: `Su'aalo badan oo la xiriira urur ama isticmaale, oo aan la xiriirin macmiil gaar ah.`,
	th: `ชุดคําถามที่เกี่ยวข้องกับองค์กรหรือผู้ใช้ที่ไม่เกี่ยวข้องกับลูกค้าเฉพาะ`,
	ur: `کسی تنظیم یا صارف سے متعلق سوالات کا ایک مجموعہ ، جس کا کسی مخصوص کلائنٹ سے کوئی تعلق نہیں ہے۔`,
	vi: `Một tập hợp các câu hỏi liên quan đến một tổ chức hoặc người dùng, không liên quan đến một khách hàng cụ thể.`,
	'zh-hans': `与组织或用户相关、与特定客户端无关的一组问题。`,
	'zh-hant': `與組織或使用者相關、與特定客戶端無關的一組問題。`,
	en: `A set of questions related to an organization or user, unrelated to a specific client.`
};

export const loc_organizationEventDesc: Record<Locale, string> = {
	ar: `حدث مباشر شخصي أو حملة أو نشاط آخر يستهدف مجموعة من الأفراد.`,
	de: `Ein persönliches Live-Event, eine Kampagne oder eine andere Aktivität, die sich an eine Gruppe von Personen richtet.`,
	es: `Un evento en vivo, una campaña u otra actividad en persona dirigida a un grupo de personas.`,
	fa: `یک رویداد زنده حضوری، کمپین یا فعالیت دیگری که گروهی از افراد را هدف قرار می دهد.`,
	fil: `Isang in person live na kaganapan, kampanya, o iba pang aktibidad na naka target sa isang grupo ng mga indibidwal.`,
	fr: `Un événement en direct, une campagne ou toute autre activité en personne ciblant un groupe de personnes.`,
	hi: `व्यक्तियों के समूह को लक्षित करने वाला व्यक्तिगत लाइव इवेंट, अभियान या अन्य गतिविधि.`,
	ht: `Yon evènman an pèsòn ap viv, kanpay, oswa lòt aktivite ki vize yon gwoup moun.`,
	it: `Un evento dal vivo, una campagna o un'altra attività di persona rivolta a un gruppo di individui.`,
	ja: `個人のグループを対象とした対面式のライブ イベント、キャンペーン、またはその他のアクティビティ。`,
	ko: `개인 그룹을 대상으로 하는 대면 라이브 이벤트, 캠페인 또는 기타 활동입니다.`,
	mww: `Ib tug neeg nyob hauv-tus neeg nyob kev tshwm sim, kev sib tw, los yog lwm yam kev ua targeting ib pawg neeg.`,
	ps: `په شخصی توګه ژوندۍ پیښه ، کمپاین ، یا نور فعالیتونه چې د افرادو ډله په نښه کوی.`,
	pt: `Um evento presencial ao vivo, campanha ou outra atividade direcionada a um grupo de indivíduos.`,
	'pt-pt': `Um evento ao vivo presencial, campanha ou outra atividade direcionada a um grupo de indivíduos.`,
	ru: `Очное мероприятие в прямом эфире, кампания или другое действие, ориентированное на группу людей.`,
	sm: `O se gaoioiga tuusao, faalauiloaga, po o se isi gaoioiga e taulai atu i se vaega o tagata taitoatasi.`,
	so: `Dhacdo qof ahaan toos u ah, olole, ama hawlo kale oo lagu beegsanayo koox shaqsiyaad ah.`,
	th: `กิจกรรมถ่ายทอดสด แคมเปญ หรือกิจกรรมอื่นๆ ที่กําหนดเป้าหมายไปยังกลุ่มบุคคล`,
	ur: `افراد کے ایک گروپ کو نشانہ بنانے والا ذاتی طور پر براہ راست پروگرام ، مہم ، یا دیگر سرگرمی۔`,
	vi: `Sự kiện trực tiếp trực tiếp, chiến dịch hoặc hoạt động khác nhắm mục tiêu đến một nhóm cá nhân.`,
	'zh-hans': `针对一组个人的现场直播活动、活动或其他活动。`,
	'zh-hant': `針對一組個人的現場直播活動、活動或其他活動。`,
	en: `An in-person live event, campaign, or other activity targeting a group of individuals.`
};

export const loc_cancel: Record<Locale, string> = {
	ar: `إلغاء الأمر`,
	de: `Abbrechen`,
	es: `Cancelar`,
	fa: `لغو`,
	fil: `Kanselahin`,
	fr: `Annuler`,
	hi: `रद्द करना`,
	ht: `Anile`,
	it: `Annulla`,
	ja: `キャンセル`,
	ko: `취소`,
	mww: `raug muab tso tseg`,
	ps: `لغوه کول`,
	pt: `Cancelar`,
	'pt-pt': `Cancelar`,
	ru: `Отмена`,
	sm: `Fa'alēāogāina`,
	so: `Jooji`,
	th: `ยกเลิก`,
	ur: `منسوخ`,
	vi: `Hủy`,
	'zh-hans': `取消`,
	'zh-hant': `取消`,
	en: `Cancel`
};

export const loc_chooseActivityType: Record<Locale, string> = {
	ar: `اختر نوع النشاط`,
	de: `Wählen Sie die Aktivitätsart`,
	es: `Elegir tipo de actividad`,
	fa: `نوع فعالیت را انتخاب کنید`,
	fil: `Pumili ng uri ng aktibidad`,
	fr: `Choisissez le type d’activité`,
	hi: `गतिविधि प्रकार चुनें`,
	ht: `Chwazi kalite aktivite`,
	it: `Scegli il tipo di attività`,
	ja: `アクティビティの種類を選択`,
	ko: `활동 유형 선택`,
	mww: `Xaiv hom kev ua ub no`,
	ps: `د فعالیت ځېل وچوڼئ`,
	pt: `Escolher tipo de atividade`,
	'pt-pt': `Escolha o tipo de atividade`,
	ru: `Выберите тип активности`,
	sm: `Filifili le ituaiga gaoioiga`,
	so: `Dooro nooca waxqabadka`,
	th: `เลือกประเภทกิจกรรม`,
	ur: `سرگرمی کی قسم منتخب کریں`,
	vi: `Chọn loại hoạt động`,
	'zh-hans': `选择活动类型`,
	'zh-hant': `選擇活動類型`,
	en: `Choose activity type`
};

export const loc_newActivityDefinition: Record<Locale, string> = {
	ar: `تعريف جديد للنشاط`,
	de: `Neue Aktivitätsdefinition`,
	es: `Nueva definición de actividad`,
	fa: `تعریف فعالیت جدید`,
	fil: `Bagong kahulugan ng aktibidad`,
	fr: `Définition d’une nouvelle activité`,
	hi: `नई गतिविधि परिभाषा`,
	ht: `Nouvo definisyon aktivite`,
	it: `Nuova definizione di attività`,
	ja: `新しいアクティビティ定義`,
	ko: `새 활동 정의`,
	mww: `Kev ua tshiab txhais`,
	ps: `د فعالیت نوی تعریف`,
	pt: `Nova definição de atividade`,
	'pt-pt': `Nova definição de atividade`,
	ru: `Новое определение деятельности`,
	sm: `Faauigaga fou o le gaoioiga`,
	so: `Qeexidda waxqabadka cusub`,
	th: `คําจํากัดความของกิจกรรมใหม่`,
	ur: `سرگرمی کی نئی تعریف`,
	vi: `Định nghĩa hoạt động mới`,
	'zh-hans': `新活动定义`,
	'zh-hant': `新活動定義`,
	en: `New activity definition`
};

export const loc_public: Record<Locale, string> = {
	ar: `علني`,
	de: `Öffentlich`,
	es: `Público`,
	fa: `عمومی`,
	fil: `Publiko`,
	fr: `Public`,
	hi: `सार्वजनिक`,
	ht: `Piblik`,
	it: `Pubblico`,
	ja: `公共`,
	ko: `공공의`,
	mww: `Pej Xeem`,
	ps: `خپور`,
	pt: `Público`,
	'pt-pt': `Público`,
	ru: `Общественный`,
	sm: `Tagata Lautele`,
	so: `Dadweynaha`,
	th: `สาธารณะ`,
	ur: `عوام`,
	vi: `Công cộng`,
	'zh-hans': `公共`,
	'zh-hant': `公共`,
	en: `Public`
};

export const loc_publicQuestionnaireOptionDesc: Record<Locale, (cumulus: unknown) => string> = {
	ar: (cumulus) => `يمكن ربط الاستبيانات العامة بالأحداث وتعبئتها من قبل الأفراد الذين ليس لديهم حساب ${cumulus} .`,
	de: (cumulus) => `Öffentliche Fragebögen können mit Veranstaltungen verknüpft und von Personen ohne ${cumulus} ausgefüllt werden.`,
	es: (cumulus) => `Los cuestionarios públicos pueden asociarse con eventos y ser completados por personas sin una cuenta de ${cumulus} .`,
	fa: (cumulus) => `پرسشنامه های عمومی را می توان با رویدادها مرتبط کرد و توسط افراد بدون حساب ${cumulus} پر کرد.`,
	fil: (cumulus) => `Ang mga pampublikong questionnaires ay maaaring maiugnay sa mga kaganapan at punan ng mga indibidwal na walang account ${cumulus} .`,
	fr: (cumulus) => `Les questionnaires publics peuvent être associés à des événements et remplis par des personnes sans compte ${cumulus} .`,
	hi: (cumulus) => `सार्वजनिक प्रश्नावली घटनाओं से जुड़ी हो सकती है और ${cumulus} खाते के व्यक्तियों द्वारा भरी जा सकती है।`,
	ht: (cumulus) => `Kesyonè piblik yo ka asosye ak evènman ak ranpli pa moun san yo pa yon kont ${cumulus} .`,
	it: (cumulus) => `I questionari pubblici possono essere associati a eventi e compilati da persone che non dispongono di un account ${cumulus} .`,
	ja: (cumulus) => `公開アンケートは、イベントに関連付けて、 ${cumulus} アカウントを持たない個人が記入できます。`,
	ko: (cumulus) => `공개 설문지는 이벤트와 연결할 수 있으며 ${cumulus} 계정이 없는 개인이 작성할 수 있습니다.`,
	mww: (cumulus) => `Pej xeem questionnaires yuav muaj kab mob nrog cov txheej xwm thiab sau los ntawm cov neeg tsis muaj ib ${cumulus} nyiaj.`,
	ps: (cumulus) => `عامه پوښتنلیکونه د پیښو سره تړاو لری او د ${cumulus} حساب پرته د افرادو لخوا ډک شوی.`,
	pt: (cumulus) => `Os questionários públicos podem ser associados a eventos e preenchidos por indivíduos sem uma conta ${cumulus} .`,
	'pt-pt': (cumulus) => `Os questionários públicos podem ser associados a eventos e preenchidos por indivíduos sem uma conta ${cumulus} .`,
	ru: (cumulus) => `Публичные анкеты могут быть связаны с событиями и заполняться лицами, не имеющими учетной записи ${cumulus} .`,
	sm: (cumulus) => `O fesili lautele e mafai ona fesootai ma mea e tutupu ma faatumuina e tagata taitoatasi e aunoa ma se faitotoa ${cumulus} .`,
	so: (cumulus) => `Su'aalaha dadweynaha waxaa la xiriirin karaa dhacdooyinka ayadoo shakhsiyaadka ay buuxiyaan koontada ${cumulus} la'aanta.`,
	th: (cumulus) => `แบบสอบถามสาธารณะสามารถเชื่อมโยงกับเหตุการณ์และกรอกโดยบุคคลที่ไม่มีบัญชี ${cumulus}`,
	ur: (cumulus) => `عوامی سوالنامے واقعات کے ساتھ منسلک کیے جاسکتے ہیں اور کمولس اکاؤنٹ کے بغیر افراد کے ذریعہ ${cumulus} ۔`,
	vi: (cumulus) => `Bảng câu hỏi công khai có thể được liên kết với các sự kiện và được điền bởi các cá nhân không có tài khoản ${cumulus} .`,
	'zh-hans': (cumulus) => `公共调查问卷可以与事件相关联，并由没有 ${cumulus} 账户的个人填写。`,
	'zh-hant': (cumulus) => `公共調查問卷可以與事件相關聯，並由沒有 ${cumulus} 帳戶的個人填寫。`,
	en: (cumulus) => `Public questionnaires can be associated with events and filled out by individuals without a ${cumulus} account.`
};

export const loc_permitAnonymous: Record<Locale, string> = {
	ar: `تصريح مجهول`,
	de: `Anonym zulassen`,
	es: `Permiso anónimo`,
	fa: `اجازه ناشناس`,
	fil: `Permit anonymous`,
	fr: `Permis anonyme`,
	hi: `अनाम अनुमति दें`,
	ht: `Pèmèt anonim`,
	it: `Permesso anonimo`,
	ja: `匿名で許可`,
	ko: `익명 허용`,
	mww: `Daim Ntawv Tso Cai Anonymous`,
	ps: `بېنومه اجازه ورکول`,
	pt: `Permitir anônimo`,
	'pt-pt': `Permitir anónimo`,
	ru: `Разрешить анонимно`,
	sm: `Faataga le le mailoa`,
	so: `qarsoodiga ah`,
	th: `อนุญาตที่ไม่ระบุชื่อ`,
	ur: `گمنام کی اجازت دیں`,
	vi: `Giấy phép ẩn danh`,
	'zh-hans': `允许匿名`,
	'zh-hant': `允許匿名`,
	en: `Permit anonymous`
};

export const loc_permitAnonymousOptionDesc: Record<Locale, (cumulus: unknown) => string> = {
	ar: (cumulus) => `يمكن تسجيل الاستبيانات التي تسمح بالدخول المجهول دون الارتباط بشخص مسجل في ${cumulus}.`,
	de: (cumulus) => `Fragebögen, die eine anonyme Eingabe ermöglichen, können ohne Zuordnung zu einer registrierten Person in ${cumulus} erfasst werden.`,
	es: (cumulus) => `Los cuestionarios que permiten la entrada anónima pueden ser grabados sin asociación con una persona registrada en ${cumulus}.`,
	fa: (cumulus) => `پرسشنامه هایی که اجازه ورود ناشناس را می دهند را می توان بدون ارتباط با شخص ثبت نام شده در ${cumulus} ثبت کرد.`,
	fil: (cumulus) => `Questionnaires kung aling permit anonymous entry ang maaaring itala nang walang kaugnayan sa isang rehistradong tao sa ${cumulus}.`,
	fr: (cumulus) => `Les questionnaires qui permettent une saisie anonyme peuvent être enregistrés sans association avec une personne enregistrée dans ${cumulus}.`,
	hi: (cumulus) => `प्रश्नावली जो अनाम प्रविष्टि की अनुमति देती है, ${cumulus} में पंजीकृत व्यक्ति के साथ संबंध के बिना दर्ज किया जा सकता है।`,
	ht: (cumulus) => `Kesyonè ki pèmèt antre anonim ka anrejistre san asosyasyon ak yon moun ki anrejistre nan ${cumulus}.`,
	it: (cumulus) => `I questionari che consentono l'inserimento anonimo possono essere registrati senza essere associati a una persona registrata in ${cumulus}.`,
	ja: (cumulus) => `匿名で参加できるアンケートは、 ${cumulus} に登録された人物との関連付けなしに記録できます。`,
	ko: (cumulus) => `익명으로 입력할 수 있는 설문지는 ${cumulus}에 등록된 사람과 연관되지 않고 기록될 수 있습니다.`,
	mww: (cumulus) => `Questionnaires uas tso cai anonymous nkag teb chaws yuav raug kaw tsis muaj koom haum nrog ib tug neeg sau npe nyob ${cumulus}.`,
	ps: (cumulus) => `هغه پوښتنلیکونه چې مستعار ننوتلو ته اجازه ورکوی په ${cumulus} کې د راجستر شوی کس سره د اړیکې پرته ثبت کیدی شی.`,
	pt: (cumulus) => `Os questionários que permitem a entrada anônima podem ser gravados sem associação com uma pessoa registrada no ${cumulus}.`,
	'pt-pt': (cumulus) => `Os questionários que permitem a entrada anónima podem ser registados sem associação com uma pessoa registada no ${cumulus}.`,
	ru: (cumulus) => `Анкеты, разрешающие анонимный въезд, могут быть зарегистрированы без связи с зарегистрированным лицом в ${cumulus}.`,
	sm: (cumulus) => `O fesili e faatagaina ai le ulufale mai e le mailoa e mafai ona faamaumauina e aunoa ma le fegalegaleaiga ma se tagata ua lesitala i ${cumulus}.`,
	so: (cumulus) => `Su'aalaha ogolaanaya soo gelitaanka qarsoodi lagu galo, waxaa lagu diiwaangelin karaa haddii aan lala xiriirin dadka ka diiwaangashan ${cumulus}.`,
	th: (cumulus) => `แบบสอบถามที่อนุญาตให้เข้าโดยไม่ระบุตัวตนสามารถบันทึกได้โดยไม่ต้องเกี่ยวข้องกับบุคคลที่ลงทะเบียนใน ${cumulus}`,
	ur: (cumulus) => `سوالنامے جو گمنام داخلے کی اجازت دیتے ہیں ${cumulus} میں رجسٹرڈ شخص کے ساتھ وابستگی کے بغیر ریکارڈ کیا جاسکتا ہے۔`,
	vi: (cumulus) => `Bảng câu hỏi cho phép nhập cảnh ẩn danh có thể được ghi lại mà không cần liên kết với một người đã đăng ký trong ${cumulus}.`,
	'zh-hans': (cumulus) => `允许匿名输入的问卷可以记录，而无需与 ${cumulus} 中的注册人员相关联。`,
	'zh-hant': (cumulus) => `允許匿名輸入的問卷可以記錄，而無需與 ${cumulus} 中的註冊人員相關聯。`,
	en: (cumulus) => `Questionnaires which permit anonymous entry can be recorded without association with a registered person in ${cumulus}.`
};

export const loc_activities: Record<Locale, string> = {
	ar: `الانشطه`,
	de: `Aktivitäten`,
	es: `Actividades`,
	fa: `فعالیت`,
	fil: `Mga Aktibidad`,
	fr: `Activités`,
	hi: `गतिविधियों`,
	ht: `Aktivite`,
	it: `Attività`,
	ja: `活動`,
	ko: `활동`,
	mww: `Yam`,
	ps: `فعالیتونه`,
	pt: `Atividades`,
	'pt-pt': `Atividades`,
	ru: `Деятельности`,
	sm: `Gaoioiga`,
	so: `Waxqabadyada`,
	th: `กิจกรรม`,
	ur: `سرگرمیوں`,
	vi: `Hoạt động`,
	'zh-hans': `活动`,
	'zh-hant': `活動`,
	en: `Activities`
};

export const loc_save: Record<Locale, string> = {
	ar: `أنقذ`,
	de: `Retten`,
	es: `Salvar`,
	fa: `ذخیره`,
	fil: `Mag-ipon`,
	fr: `Sauvegarder`,
	hi: `रक्षा कर`,
	ht: `Sove`,
	it: `Salvare`,
	ja: `セーブ`,
	ko: `구해내다`,
	mww: `Tseg`,
	ps: `ساتل`,
	pt: `Salvar`,
	'pt-pt': `Gravar`,
	ru: `Спасать`,
	sm: `Sefe`,
	so: `Xaree`,
	th: `ประหยัด`,
	ur: `بچانا`,
	vi: `Cứu`,
	'zh-hans': `救`,
	'zh-hant': `救`,
	en: `Save`
};

export const loc_navigation: Record<Locale, string> = {
	ar: `ملاحة`,
	de: `Navigation`,
	es: `Navegación`,
	fa: `ناوبری`,
	fil: `Nabigasyon`,
	fr: `Navigation`,
	hi: `नौसंचालन`,
	ht: `Navigasyon`,
	it: `Navigazione`,
	ja: `航法`,
	ko: `항행`,
	mww: `navigation`,
	ps: `چلونه`,
	pt: `Navegação`,
	'pt-pt': `Navegação`,
	ru: `Навигация`,
	sm: `Faatautaiga`,
	so: `Navigation`,
	th: `การเดินเรือ`,
	ur: `گشت`,
	vi: `Menu`,
	'zh-hans': `导航`,
	'zh-hant': `導航`,
	en: `Navigation`
};

export const loc_dueDate: Record<Locale, string> = {
	ar: `تاريخ الاستحقاق`,
	de: `Fälligkeitsdatum`,
	es: `Fecha de vencimiento`,
	fa: `سررسید`,
	fil: `Takdang petsa`,
	fr: `Échéance`,
	hi: `नियत तारीख`,
	ht: `Dat limit limit`,
	it: `Data di scadenza`,
	ja: `期日`,
	ko: `마감일`,
	mww: `Hnub Tim`,
	ps: `د کولو نېټه`,
	pt: `Data de vencimento`,
	'pt-pt': `Data de vencimento`,
	ru: `Срок`,
	sm: `Aso e tatau ai`,
	so: `Due date`,
	th: `วันครบกําหนด`,
	ur: `مقررہ تاریخ`,
	vi: `Ngày đáo hạn`,
	'zh-hans': `期限`,
	'zh-hant': `期限`,
	en: `Due date`
};

export const loc_startDate: Record<Locale, string> = {
	ar: `تاريخ البدء`,
	de: `Startdatum`,
	es: `Fecha de inicio`,
	fa: `تاریخ شروع`,
	fil: `Petsa ng pagsisimula`,
	fr: `Date de début`,
	hi: `शुरू होने की तारीख`,
	ht: `Kòmanse dat`,
	it: `Data di inizio`,
	ja: `開始日`,
	ko: `시작 날짜`,
	mww: `Pib hnub tim`,
	ps: `پېل نېټه`,
	pt: `Data de início`,
	'pt-pt': `Data de início`,
	ru: `Дата начала`,
	sm: `Aso amata`,
	so: `Taariikhda Bilowga`,
	th: `วันที่เริ่มต้น`,
	ur: `آغاز کی تاریخ`,
	vi: `Ngày bắt đầu`,
	'zh-hans': `开始日期`,
	'zh-hant': `開始日期`,
	en: `Start date`
};

export const loc_endDate: Record<Locale, string> = {
	ar: `انتهاء العرض`,
	de: `Enddatum`,
	es: `Fecha final`,
	fa: `تاریخ پایان`,
	fil: `Petsa ng pagtatapos`,
	fr: `Date de fin`,
	hi: `खत्म होने की तारीख`,
	ht: `Dat fen`,
	it: `Data di fine`,
	ja: `終了日`,
	ko: `종료 날짜`,
	mww: `Hnub Kawg`,
	ps: `پای نېټه`,
	pt: `Data de término`,
	'pt-pt': `Data de fim`,
	ru: `Дата окончания`,
	sm: `Aso faaiu`,
	so: `Taariikhda dhammaad`,
	th: `วันที่สิ้นสุด`,
	ur: `اختتام کی تاریخ`,
	vi: `Ngày kết thúc`,
	'zh-hans': `结束日期`,
	'zh-hant': `結束日期`,
	en: `End date`
};

export const loc_assignee: Record<Locale, string> = {
	ar: `المحال`,
	de: `Abtretungsempfänger`,
	es: `Cesionario`,
	fa: `وکیل`,
	fil: `Assignee`,
	fr: `Cessionnaire`,
	hi: `समनुदेशिती`,
	ht: `Devwa`,
	it: `Assegnatario`,
	ja: `担当者`,
	ko: `담당자`,
	mww: `Assignee`,
	ps: `سپارل`,
	pt: `Cessionário`,
	'pt-pt': `Cessionário`,
	ru: `Правопреемник`,
	sm: `Tagata ua Tofia`,
	so: `assignee`,
	th: `ผู้รับมอบหมาย`,
	ur: `Assignee`,
	vi: `Chuyển nhượng`,
	'zh-hans': `受让人`,
	'zh-hant': `受讓人`,
	en: `Assignee`
};

export const loc_none: Record<Locale, string> = {
	ar: `اي`,
	de: `Nichts`,
	es: `Ninguno`,
	fa: `هیچ کدام`,
	fil: `Wala na`,
	fr: `Aucun`,
	hi: `कोई नहीं`,
	ht: `anyen`,
	it: `Nessuno`,
	ja: `何一つ`,
	ko: `없음`,
	mww: `Tsis muaj`,
	ps: `هېڅ`,
	pt: `Nenhum`,
	'pt-pt': `Nenhum`,
	ru: `Никакой`,
	sm: `Leai`,
	so: `None`,
	th: `ไม่มีใคร`,
	ur: `کوئی`,
	vi: `Không ai`,
	'zh-hans': `没有`,
	'zh-hant': `沒有`,
	en: `None`
};

export const loc_notAssigned: Record<Locale, string> = {
	ar: `غير معين`,
	de: `Nicht zugeordnet`,
	es: `No asignado`,
	fa: `اختصاص داده نشده است`,
	fil: `Hindi nakatalaga`,
	fr: `Non attribué`,
	hi: `असाइन नहीं किया गया`,
	ht: `Pa asiyen`,
	it: `Non assegnato`,
	ja: `割り当てられていない`,
	ko: `할당되지 않음`,
	mww: `Tsis raug muab xa mus rau lub tsev kawm ntawv`,
	ps: `ناسپارل شوی`,
	pt: `Não atribuído`,
	'pt-pt': `Não atribuído`,
	ru: `Не назначено`,
	sm: `E le'i tofiaina`,
	so: `Looma xilsaarin`,
	th: `ไม่ได้มอบหมาย`,
	ur: `تفویض نہیں کیا گیا`,
	vi: `Không được chỉ định`,
	'zh-hans': `未分配`,
	'zh-hant': `未分配`,
	en: `Not assigned`
};

export const loc_write: Record<Locale, string> = {
	ar: `يكتب`,
	de: `Schreiben`,
	es: `Escribir`,
	fa: `نوشتن`,
	fil: `Isulat ang`,
	fr: `Écrire`,
	hi: `लिखना`,
	ht: `Ekri`,
	it: `Scrivere`,
	ja: `書く`,
	ko: `쓰다`,
	mww: `Sau`,
	ps: `لیکل`,
	pt: `Escrever`,
	'pt-pt': `Escrever`,
	ru: `Писать`,
	sm: `Tusi`,
	so: `Ku qor`,
	th: `เขียน`,
	ur: `لکھنا`,
	vi: `Viết`,
	'zh-hans': `写`,
	'zh-hant': `寫`,
	en: `Write`
};

export const loc_preview: Record<Locale, string> = {
	ar: `معاينه`,
	de: `Vorschau`,
	es: `Vista previa`,
	fa: `پیشنمایش`,
	fil: `Preview`,
	fr: `Aperçu`,
	hi: `पूर्वसमीक्षा`,
	ht: `Preview`,
	it: `Anteprima`,
	ja: `プレビュー`,
	ko: `미리 보기`,
	mww: `saib ua ntej`,
	ps: `مخلید`,
	pt: `Visualizar`,
	'pt-pt': `Previsualizar`,
	ru: `Предварительный просмотр`,
	sm: `Muai Vaaiga`,
	so: `Faah faafinta`,
	th: `ดูตัวอย่าง`,
	ur: `پیش`,
	vi: `Download`,
	'zh-hans': `预览`,
	'zh-hant': `預覽`,
	en: `Preview`
};

export const loc_leaveAComment: Record<Locale, string> = {
	ar: `اترك تعليقا`,
	de: `Hinterlasse einen Kommentar`,
	es: `Deja un comentario`,
	fa: `دیدگاهتان را بنویسید`,
	fil: `Mag iwan ng komento`,
	fr: `Laisser un commentaire`,
	hi: `एक टिप्पणी छोड़ें`,
	ht: `Kite yon kòmantè`,
	it: `Lascia un commento`,
	ja: `コメントを書く`,
	ko: `코멘트를 남겨주세요`,
	mww: `Cia ib saib`,
	ps: `خپل نظر ورکړئ`,
	pt: `Deixe um comentário`,
	'pt-pt': `Deixe um comentário`,
	ru: `Оставить комментарий`,
	sm: `Tuu se faamatalaga`,
	so: `Leave a comment`,
	th: `ทิ้งข้อความไว้`,
	ur: `ایک تبصرہ چھوڑیں`,
	vi: `Để lại một bình luận`,
	'zh-hans': `发表评论`,
	'zh-hant': `發表評論`,
	en: `Leave a comment`
};

export const loc_stylingWithMarkdown: Record<Locale, string> = {
	ar: `يتم دعم التصميم باستخدام Markdown`,
	de: `Styling mit Markdown wird unterstützt`,
	es: `Se admite el estilo con Markdown`,
	fa: `استایل دهی با Markdown پشتیبانی می شود`,
	fil: `Pag istilo sa Markdown ay suportado`,
	fr: `Le style avec Markdown est pris en charge`,
	hi: `मार्कडाउन के साथ स्टाइलिंग समर्थित है`,
	ht: `Styling ak Markdown sipòte`,
	it: `L'applicazione di stili con Markdown è supportata`,
	ja: `Markdown によるスタイル設定がサポートされています`,
	ko: `Markdown을 사용한 스타일링이 지원됩니다.`,
	mww: `Styling nrog Markdown yog txaus siab`,
	ps: `د مارکډون سره سټایل ملاتړ کیږی`,
	pt: `Há suporte para o estilo com Markdown`,
	'pt-pt': `O estilo com Markdown é suportado`,
	ru: `Поддерживается стилизация с помощью Markdown`,
	sm: `E lagolagoina le sitaili i le Markdown`,
	so: `Qalabaynta Markdown waxaa taageeray`,
	th: `รองรับการจัดรูปแบบด้วย Markdown`,
	ur: `مارک ڈاؤن کے ساتھ اسٹائل کی حمایت کی جاتی ہے`,
	vi: `Kiểu dáng với Markdown được hỗ trợ`,
	'zh-hans': `支持使用 Markdown 进行样式设置`,
	'zh-hant': `支援使用 Markdown 進行樣式設置`,
	en: `Styling with Markdown is supported`
};

export const loc_nothingToPreview: Record<Locale, string> = {
	ar: `لا شيء للمعاينة`,
	de: `Nichts zur Vorschau`,
	es: `Nada que previsualizar`,
	fa: `چیزی برای پیش نمایش نیست`,
	fil: `Walang dapat i preview`,
	fr: `Rien à prévisualiser`,
	hi: `पूर्वावलोकन करने के लिए कुछ भी नहीं`,
	ht: `Pa gen anyen pou prevwa`,
	it: `Niente da visualizzare in anteprima`,
	ja: `プレビューするものはありません`,
	ko: `미리 볼 항목이 없습니다.`,
	mww: `Tsis muaj dab tsi los saib ua ntej`,
	ps: `د مخلید لپاره هیڅ شی`,
	pt: `Nada para visualizar`,
	'pt-pt': `Nada para pré-visualizar`,
	ru: `Ничего для предварительного просмотра`,
	sm: `Leai se mea e muai vaai i ai`,
	so: `Waxba kama hordhac`,
	th: `ไม่มีอะไรให้ดูตัวอย่าง`,
	ur: `پیش کرنے کے لئے کچھ بھی نہیں`,
	vi: `Không có gì để xem trước`,
	'zh-hans': `没有要预览的内容`,
	'zh-hant': `沒有要預覽的內容`,
	en: `Nothing to preview`
};

export const loc_comments: Record<Locale, string> = {
	ar: `التعليقات`,
	de: `Kommentare`,
	es: `Comentarios`,
	fa: `نظرات`,
	fil: `Mga komento`,
	fr: `Commentaires`,
	hi: `टिप्पणियाँ`,
	ht: `Kòmantè`,
	it: `Commenti`,
	ja: `コメント`,
	ko: `코멘트`,
	mww: `Lus`,
	ps: `څرګندونونه`,
	pt: `Comentários`,
	'pt-pt': `Observações`,
	ru: `Комментарии`,
	sm: `Manatu Faaalia`,
	so: `Comments`,
	th: `ความ คิด เห็น`,
	ur: `تبصرے`,
	vi: `Ý kiến`,
	'zh-hans': `评论`,
	'zh-hant': `評論`,
	en: `Comments`
};

export const loc_comment: Record<Locale, string> = {
	ar: `التعليق`,
	de: `Kommentar`,
	es: `Comentario`,
	fa: `نظر`,
	fil: `Magkomento`,
	fr: `Commentaire`,
	hi: `सम्‍मति देना`,
	ht: `Kòmantè`,
	it: `Commento`,
	ja: `コメント`,
	ko: `주석`,
	mww: `Saib`,
	ps: `څرګندون`,
	pt: `Comentário`,
	'pt-pt': `Comentar`,
	ru: `Комментарий`,
	sm: `Faamatalaga`,
	so: `comment`,
	th: `ติชม`,
	ur: `تبصرہ`,
	vi: `Bình luận`,
	'zh-hans': `评论`,
	'zh-hant': `評論`,
	en: `Comment`
};

export const loc_age: Record<Locale, string> = {
	ar: `عمر`,
	de: `Alter`,
	es: `Edad`,
	fa: `سن`,
	fil: `Edad`,
	fr: `Âge`,
	hi: `उम्र`,
	ht: `Laj`,
	it: `Età`,
	ja: `年齢`,
	ko: `연령`,
	mww: `hnub nyoog`,
	ps: `عمر`,
	pt: `Idade`,
	'pt-pt': `Idade`,
	ru: `Возраст`,
	sm: `Tausaga`,
	so: `Age`,
	th: `อายุ`,
	ur: `عمر`,
	vi: `Tuổi`,
	'zh-hans': `年龄`,
	'zh-hant': `年齡`,
	en: `Age`
};

export const loc_questionnaires: Record<Locale, string> = {
	ar: `الاستبيانات`,
	de: `Fragebögen`,
	es: `Cuestionarios`,
	fa: `پرسشنامه`,
	fil: `Mga questionnaire`,
	fr: `Questionnaire`,
	hi: `प्रश्नावली`,
	ht: `Kesyonè yo`,
	it: `Questionari`,
	ja: `アンケート`,
	ko: `설문 조사`,
	mww: `Questionnaires`,
	ps: `پوښتنلیکونه`,
	pt: `Questionário`,
	'pt-pt': `Questionários`,
	ru: `Анкеты`,
	sm: `Fesili`,
	so: `Haku`,
	th: `แบบ สอบ ถาม`,
	ur: `سوالنامے`,
	vi: `Câu hỏi`,
	'zh-hans': `问卷`,
	'zh-hant': `問卷`,
	en: `Questionnaires`
};

export const loc_qrCode: Record<Locale, string> = {
	ar: `رمز ريال قطري`,
	de: `QR-Code`,
	es: `Código QR`,
	fa: `کد QR`,
	fil: `QR code`,
	fr: `Code QR`,
	hi: `QR कोड`,
	ht: `Kòd QR`,
	it: `Codice QR`,
	ja: `QRコード`,
	ko: `QR 코드`,
	mww: `QR code`,
	ps: `QR کوډ`,
	pt: `Código QR`,
	'pt-pt': `Código QR`,
	ru: `QR-код`,
	sm: `QR code`,
	so: `QR code`,
	th: `คิวอาร์โค้ด`,
	ur: `QR code`,
	vi: `Mã QR`,
	'zh-hans': `二维码`,
	'zh-hant': `二維碼`,
	en: `QR code`
};

export const loc_print: Record<Locale, string> = {
	ar: `طبع`,
	de: `Drucken`,
	es: `Impresión`,
	fa: `چاپ`,
	fil: `Mag-print`,
	fr: `Imprimer`,
	hi: `फ़ोटो`,
	ht: `Enprime`,
	it: `Stampare`,
	ja: `プリント`,
	ko: `인쇄하다`,
	mww: `Sau`,
	ps: `چاپول`,
	pt: `Imprimir`,
	'pt-pt': `Impressão`,
	ru: `Печатать`,
	sm: `Lolomi`,
	so: `Haku`,
	th: `พิมพ์`,
	ur: `چھاپنا`,
	vi: `In`,
	'zh-hans': `打印`,
	'zh-hant': `列印`,
	en: `Print`
};

export const loc_people: Record<Locale, string> = {
	ar: `شعب`,
	de: `Volk`,
	es: `Gente`,
	fa: `مردم`,
	fil: `Mga Tao`,
	fr: `Gens`,
	hi: `लोग`,
	ht: `Moun`,
	it: `Gente`,
	ja: `人`,
	ko: `사람`,
	mww: `Neeg`,
	ps: `خلک`,
	pt: `Povo`,
	'pt-pt': `Pessoas`,
	ru: `Народ`,
	sm: `Tagata`,
	so: `Dadka`,
	th: `ประชาชน`,
	ur: `لوگ`,
	vi: `Dân tộc`,
	'zh-hans': `人`,
	'zh-hant': `人`,
	en: `People`
};

export const loc_newActivity: Record<Locale, string> = {
	ar: `نشاط جديد`,
	de: `Neue Aktivität`,
	es: `Nueva actividad`,
	fa: `فعالیت جدید`,
	fil: `Bagong aktibidad`,
	fr: `Nouvelle activité`,
	hi: `नई गतिविधि`,
	ht: `Nouvo aktivite`,
	it: `Nuova attività`,
	ja: `新しいアクティビティ`,
	ko: `새 활동`,
	mww: `kev ua ub no tshiab`,
	ps: `نوی فعالیت`,
	pt: `Nova atividade`,
	'pt-pt': `Nova atividade`,
	ru: `Новая активность`,
	sm: `Gaoioiga fou`,
	so: `Shaqoo hor leh`,
	th: `กิจกรรมใหม่`,
	ur: `نئی سرگرمی`,
	vi: `Hoạt động mới`,
	'zh-hans': `新活动`,
	'zh-hant': `新活動`,
	en: `New activity`
};

export const loc_noApplicableActivityDefinitions: Record<Locale, string> = {
	ar: `لا توجد تعريفات للأنشطة قابلة للتطبيق`,
	de: `Keine anwendbaren Aktivitätsdefinitionen`,
	es: `No hay definiciones de actividad aplicables`,
	fa: `بدون تعاریف فعالیت قابل اجرا`,
	fil: `Walang naaangkop na mga kahulugan ng aktibidad`,
	fr: `Aucune définition d’activité applicable`,
	hi: `कोई लागू गतिविधि परिभाषा नहीं`,
	ht: `Pa gen definisyon aktivite aplikab`,
	it: `Nessuna definizione di attività applicabile`,
	ja: `適用可能なアクティビティ定義はありません`,
	ko: `적용 가능한 활동 정의가 없습니다.`,
	mww: `Tsis muaj kev txhais cov ntsiab lus tseem ceeb`,
	ps: `د فعالیت د تطبیق وړ پېژنداوی نشته`,
	pt: `Nenhuma definição de atividade aplicável`,
	'pt-pt': `Sem definições de atividade aplicáveis`,
	ru: `Нет применимых определений видов деятельности`,
	sm: `Leai ni faauigaga o gaoioiga e faatatau i ai`,
	so: `Qeexitaanka waxqabadka ee khuseeya ma jiro`,
	th: `ไม่มีคําจํากัดความของกิจกรรมที่เกี่ยวข้อง`,
	ur: `کوئی قابل اطلاق سرگرمی کی تعریف نہیں`,
	vi: `Không có định nghĩa hoạt động áp dụng`,
	'zh-hans': `无适用的活动定义`,
	'zh-hant': `無適用的活動定義`,
	en: `No applicable activity definitions`
};

export const loc_howToManageActivityDefs: Record<Locale, string> = {
	ar: `تتم إدارة تعريفات النشاط في إعدادات مشروع مؤسستك.`,
	de: `Aktivitätsdefinitionen werden in den Projekteinstellungen Ihrer Organisation verwaltet.`,
	es: `Las definiciones de actividad se administran en la configuración del proyecto de su organización.`,
	fa: `تعاریف فعالیت در تنظیمات پروژه سازمان شما مدیریت می شوند.`,
	fil: `Ang mga kahulugan ng aktibidad ay pinamamahalaan sa mga setting ng proyekto ng iyong organisasyon.`,
	fr: `Les définitions d’activités sont gérées dans les paramètres de projet de votre organisation.`,
	hi: `गतिविधि परिभाषाएँ आपके संगठन की प्रोजेक्ट सेटिंग्स में प्रबंधित की जाती हैं.`,
	ht: `Definisyon aktivite yo jere nan anviwònman pwojè òganizasyon ou an.`,
	it: `Le definizioni delle attività vengono gestite nelle impostazioni del progetto dell'organizzazione.`,
	ja: `アクティビティの定義は、組織のプロジェクト設定で管理されます。`,
	ko: `활동 정의는 조직의 프로젝트 설정에서 관리됩니다.`,
	mww: `Cov ntsiab lus txhais cov ntsiab lus yog tswj nyob rau hauv koj lub koom haum qhov project project.`,
	ps: `د فعالیت تعریفونه ستاسو د سازمان د پروژې په ترتیباتو کې اداره کیږی.`,
	pt: `As definições de atividade são gerenciadas nas configurações de projeto da sua organização.`,
	'pt-pt': `As definições de atividade são gerenciadas nas configurações de projeto da sua organização.`,
	ru: `Управление определениями действий осуществляется в параметрах проекта организации.`,
	sm: `O faauigaga o gaoioiga e puleaina i faatulagaga o galuega faatino a lau faalapotopotoga.`,
	so: `Qeexidda waxqabadka waxaa lagu maamulaa goobaha mashruuca ee ururkaaga.`,
	th: `คําจํากัดความของกิจกรรมได้รับการจัดการในการตั้งค่าโครงการขององค์กรของคุณ`,
	ur: `سرگرمی کی تعریفیں آپ کی تنظیم کے منصوبے کی ترتیبات میں منظم کی جاتی ہیں۔`,
	vi: `Định nghĩa hoạt động được quản lý trong cài đặt dự án của tổ chức bạn.`,
	'zh-hans': `活动定义在组织的项目设置中进行管理。`,
	'zh-hant': `活動定義在組織的項目設置中進行管理。`,
	en: `Activity definitions are managed in your organization's project settings.`
};

export const loc_create: Record<Locale, string> = {
	ar: `خلق`,
	de: `Schaffen`,
	es: `Crear`,
	fa: `ایجاد`,
	fil: `Lumikha ng`,
	fr: `Créer`,
	hi: `बनाना`,
	ht: `Kreye`,
	it: `Creare`,
	ja: `創造する`,
	ko: `창조하다`,
	mww: `Rhawv`,
	ps: `پنځول`,
	pt: `Criar`,
	'pt-pt': `Criar`,
	ru: `Создавать`,
	sm: `Fatu`,
	so: `Abuuri`,
	th: `สร้าง`,
	ur: `تخلیق`,
	vi: `Tạo`,
	'zh-hans': `创造`,
	'zh-hant': `創造`,
	en: `Create`
};

export const loc_projectSettings: Record<Locale, string> = {
	ar: `إعدادات المشروع`,
	de: `Projekt-Einstellungen`,
	es: `Configuración del proyecto`,
	fa: `تنظیمات پروژه`,
	fil: `Mga setting ng proyekto`,
	fr: `Paramètres du projet`,
	hi: `प्रोजेक्ट सेटिंग्स`,
	ht: `Anviwònman pwojè`,
	it: `Impostazioni del progetto`,
	ja: `プロジェクト設定`,
	ko: `프로젝트 설정`,
	mww: `Tej yaam num teev tseg`,
	ps: `د پروژې تنظیمات`,
	pt: `Configurações do projeto`,
	'pt-pt': `Configurações do projeto`,
	ru: `Настройки проекта`,
	sm: `Faatulagaga o galuega faatino`,
	so: `Goobaha Mashruuca`,
	th: `การตั้งค่าโครงการ`,
	ur: `پروجیکٹ کی ترتیبات`,
	vi: `Cài đặt dự án`,
	'zh-hans': `项目设置`,
	'zh-hant': `項目設置`,
	en: `Project settings`
};

export const loc_instructions: Record<Locale, string> = {
	ar: `تعليمات`,
	de: `Anweisungen`,
	es: `Instrucciones`,
	fa: `دستورالعمل`,
	fil: `Mga tagubilin`,
	fr: `Instructions`,
	hi: `निर्देश`,
	ht: `Sa pou enstriksyon yo`,
	it: `Disposizioni`,
	ja: `指示`,
	ko: `지시`,
	mww: `Qhia`,
	ps: `لارښوونې`,
	pt: `Instruções`,
	'pt-pt': `Instruções`,
	ru: `Резолюция`,
	sm: `Faatonuga`,
	so: `Tilmaamaha`,
	th: `คำ แนะ นำ`,
	ur: `ہدایات`,
	vi: `Chỉ thị`,
	'zh-hans': `指示`,
	'zh-hant': `指示`,
	en: `Instructions`
};

export const loc_activityDefinitionInstructionsDescription: Record<Locale, (entityType: unknown) => string> = {
	ar: (entityType) => `التعليمات اختيارية وستظهر في أعلى كل ${entityType}.`,
	de: (entityType) => `Anweisungen sind optional und werden oben in jeder ${entityType} angezeigt.`,
	es: (entityType) => `Las instrucciones son opcionales y aparecerán en la parte superior de cada ${entityType}.`,
	fa: (entityType) => `دستورالعمل ها اختیاری هستند و در بالای هر ${entityType} ظاهر می شوند.`,
	fil: (entityType) => `Ang mga tagubilin ay opsyonal at lilitaw sa tuktok ng bawat ${entityType}.`,
	fr: (entityType) => `Les instructions sont facultatives et apparaîtront en haut de chaque ${entityType}.`,
	hi: (entityType) => `निर्देश वैकल्पिक हैं और प्रत्येक ${entityType} के शीर्ष पर दिखाई देंगे।`,
	ht: (entityType) => `Enstriksyon yo si ou vle epi yo pral parèt nan tèt la nan chak ${entityType}.`,
	it: (entityType) => `Le istruzioni sono facoltative e appariranno nella parte superiore di ogni ${entityType}.`,
	ja: (entityType) => `手順はオプションであり、各 ${entityType}の上部に表示されます。`,
	ko: (entityType) => `지침은 선택 사항이며 각 ${entityType} 맨 위에 표시됩니다.`,
	mww: (entityType) => `Cov lus qhia yog xaiv thiab yuav tshwm sim nyob saum txhua ${entityType}.`,
	ps: (entityType) => `لارښوونې اختیاری دی او د هرې دندې په سر کې به څرګند ${entityType}.`,
	pt: (entityType) => `As instruções são opcionais e aparecerão na parte superior de cada ${entityType}.`,
	'pt-pt': (entityType) => `As instruções são opcionais e aparecerão na parte superior de cada ${entityType}.`,
	ru: (entityType) => `Инструкции не являются обязательными и отображаются в верхней части каждой ${entityType}.`,
	sm: (entityType) => `O faatonuga e le filifilia ma o le a faaali mai i le pito i luga o ${entityType} taitasi.`,
	so: (entityType) => `Tilmaamaha waa optional oo ka soo muuqan doona ugu sareysa ${entityType} kasta.`,
	th: (entityType) => `คําแนะนําเป็นทางเลือกและจะปรากฏที่ด้านบนของ${entityType}`,
	ur: (entityType) => `ہدایات اختیاری ہیں اور ہر ${entityType} کے اوپری حصے میں ظاہر ہوں گی۔`,
	vi: (entityType) => `Hướng dẫn là tùy chọn và sẽ xuất hiện ở đầu mỗi ${entityType}.`,
	'zh-hans': (entityType) => `说明是可选的，将显示在每个 ${entityType}的顶部。`,
	'zh-hant': (entityType) => `說明是可選的，將顯示在每個 ${entityType}的頂部。`,
	en: (entityType) => `Instructions are optional and will appear at the top of each ${entityType}.`
};

export const loc_instructionsPlaceholder: Record<Locale, (entityType: unknown) => string> = {
	ar: (entityType) => `توفير تعليمات ${entityType}`,
	de: (entityType) => `Geben ${entityType} Sie Aufgabenanweisungen`,
	es: (entityType) => `Proporcionar instrucciones ${entityType}`,
	fa: (entityType) => `دستورالعمل ${entityType} را ارائه دهید`,
	fil: (entityType) => `Magbigay ng ${entityType}`,
	fr: (entityType) => `Fournir des instructions sur ${entityType}`,
	hi: (entityType) => `${entityType} निर्देश प्रदान करें`,
	ht: (entityType) => `Bay ${entityType}`,
	it: (entityType) => `Fornire istruzioni ${entityType}`,
	ja: (entityType) => `${entityType}の指示を提供する`,
	ko: (entityType) => `${entityType} 지침 제공`,
	mww: (entityType) => `Npaj ${entityType}`,
	ps: (entityType) => `د ${entityType} لارښوونې برابرې کړئ`,
	pt: (entityType) => `Fornecer instruções ${entityType}`,
	'pt-pt': (entityType) => `Fornecer instruções ${entityType}`,
	ru: (entityType) => `Предоставьте инструкции по ${entityType}`,
	sm: (entityType) => `Tuuina atu faatonuga o ${entityType}`,
	so: (entityType) => `Bixiya ${entityType}`,
	th: (entityType) => `ให้คําแนะนํา${entityType}`,
	ur: (entityType) => `${entityType} کی ہدایات فراہم کریں`,
	vi: (entityType) => `Cung cấp hướng dẫn ${entityType}`,
	'zh-hans': (entityType) => `提供 ${entityType} 说明`,
	'zh-hant': (entityType) => `提供 ${entityType} 說明`,
	en: (entityType) => `Provide ${entityType} instructions`
};

export const loc_view: Record<Locale, string> = {
	ar: `منظر`,
	de: `Ansehen`,
	es: `Vista`,
	fa: `مشاهده`,
	fil: `Tingnan ang`,
	fr: `Vue`,
	hi: `देखना`,
	ht: `View`,
	it: `Vista`,
	ja: `眺める`,
	ko: `보기`,
	mww: `Saib`,
	ps: `لید`,
	pt: `Vista`,
	'pt-pt': `Visão`,
	ru: `Вид`,
	sm: `Maimoa`,
	so: `View`,
	th: `ทิวทัศน์`,
	ur: `منظر`,
	vi: `Cảnh`,
	'zh-hans': `视图`,
	'zh-hant': `視圖`,
	en: `View`
};

export const loc_edit: Record<Locale, string> = {
	ar: `حرر`,
	de: `Redigieren`,
	es: `Editar`,
	fa: `ویرایش`,
	fil: `I-edit`,
	fr: `Éditer`,
	hi: `संपादन करना`,
	ht: `Edit`,
	it: `Redigere`,
	ja: `編集`,
	ko: `편집하다`,
	mww: `Edit`,
	ps: `سمون`,
	pt: `Editar`,
	'pt-pt': `Editar`,
	ru: `Редактировать`,
	sm: `Faasa'o`,
	so: `bedel`,
	th: `แก้ไข`,
	ur: `تدوین`,
	vi: `Biên tập`,
	'zh-hans': `编辑`,
	'zh-hant': `編輯`,
	en: `Edit`
};

export const loc_updateComment: Record<Locale, string> = {
	ar: `تحديث التعليق`,
	de: `Kommentar aktualisieren`,
	es: `Comentario de actualización`,
	fa: `به روز رسانی نظر`,
	fil: `Update ng komento`,
	fr: `Mettre à jour le commentaire`,
	hi: `टिप्पणी अपडेट करें`,
	ht: `Kòmantè Mizajou`,
	it: `Aggiorna commento`,
	ja: `コメントの更新`,
	ko: `댓글 업데이트`,
	mww: `Hloov tshiab saib`,
	ps: `څرګندون اوسمهالیزول`,
	pt: `Atualizar comentário`,
	'pt-pt': `Atualizar comentário`,
	ru: `Обновить комментарий`,
	sm: `Faafou faamatalaga`,
	so: `Faallo cusub`,
	th: `อัปเดตความคิดเห็น`,
	ur: `تازہ ترین تبصرہ`,
	vi: `Cập nhật bình luận`,
	'zh-hans': `更新评论`,
	'zh-hant': `更新評論`,
	en: `Update comment`
};

export const loc_organizationActivities: Record<Locale, string> = {
	ar: `أنشطة المنظمة`,
	de: `Organisatorische Aktivitäten`,
	es: `Actividades de la organización`,
	fa: `فعالیت های سازمان`,
	fil: `Mga aktibidad ng organisasyon`,
	fr: `Activités de l’organisation`,
	hi: `संगठन की गतिविधियाँ`,
	ht: `Aktivite òganizasyon yo`,
	it: `Attività dell'organizzazione`,
	ja: `組織活動`,
	ko: `조직 활동`,
	mww: `Koom haum kev ua ub no`,
	ps: `فعالیت های سازمانی`,
	pt: `Atividades da organização`,
	'pt-pt': `Atividades da organização`,
	ru: `Деятельность организации`,
	sm: `Gaoioiga a le faalapotopotoga`,
	so: `Hawlaha ururka`,
	th: `กิจกรรมขององค์กร`,
	ur: `تنظیم کی سرگرمیاں`,
	vi: `Hoạt động tổ chức`,
	'zh-hans': `组织活动`,
	'zh-hant': `組織活動`,
	en: `Organization activities`
};

export const loc_details: Record<Locale, string> = {
	ar: `التفاصيل`,
	de: `Details`,
	es: `Detalles`,
	fa: `جزئیات`,
	fil: `Mga Detalye`,
	fr: `Détails`,
	hi: `ब्यौरा`,
	ht: `Detay`,
	it: `Dettagli`,
	ja: `細部`,
	ko: `세부 정보`,
	mww: `paub meej`,
	ps: `خبرتیاوې`,
	pt: `Detalhes`,
	'pt-pt': `Detalhes`,
	ru: `Подробности`,
	sm: `Faamatalaga Auiliili`,
	so: `Details`,
	th: `ราย ละเอียด`,
	ur: `تفصیلات`,
	vi: `Chi tiết`,
	'zh-hans': `详`,
	'zh-hant': `詳`,
	en: `Details`
};

export const loc_unsubscribe: Record<Locale, string> = {
	ar: `إلغاء الاشتراك`,
	de: `Abmelden`,
	es: `Anular la suscripción`,
	fa: `لغو اشتراک`,
	fil: `Mag-unsubscribe`,
	fr: `Se désabonner`,
	hi: `सदस्यता रद्द करें`,
	ht: `Abònman`,
	it: `Disiscrizione`,
	ja: `配信停止`,
	ko: `구독 취소`,
	mww: `Unsubscribe`,
	ps: `ناګډونول`,
	pt: `Cancelar inscrição`,
	'pt-pt': `Cancelar inscrição`,
	ru: `Отписаться`,
	sm: `Le lesitala`,
	so: `Unsubscribe`,
	th: `ยกเลิกการสมัคร`,
	ur: `Unsubscribe`,
	vi: `Hủy đăng ký`,
	'zh-hans': `取消订阅`,
	'zh-hant': `取消訂閱`,
	en: `Unsubscribe`
};

export const loc_subscribe: Record<Locale, string> = {
	ar: `اشترك`,
	de: `Abonnieren`,
	es: `Subscribir`,
	fa: `اشتراک`,
	fil: `Mag-subscribe`,
	fr: `S’inscrire`,
	hi: `सदस्यता लें`,
	ht: `Abònman`,
	it: `Abbonarsi`,
	ja: `購読する`,
	ko: `구독하다`,
	mww: `Subscribe`,
	ps: `خبرونه`,
	pt: `Subscrever`,
	'pt-pt': `Subscrever`,
	ru: `Подписываться`,
	sm: `Lesitala`,
	so: `Subscribe`,
	th: `ลงนาม`,
	ur: `رکنیت`,
	vi: `Đăng ký`,
	'zh-hans': `订阅`,
	'zh-hant': `訂閱`,
	en: `Subscribe`
};

export const loc_singularParticipant: Record<Locale, string> = {
	ar: `1 مشارك`,
	de: `1 Teilnehmer`,
	es: `1 participante`,
	fa: `1 شرکت کننده`,
	fil: `1 kalahok`,
	fr: `1 participant`,
	hi: `1 प्रतिभागी`,
	ht: `1 patisipan`,
	it: `1 partecipante`,
	ja: `参加者1名`,
	ko: `참가자 1명`,
	mww: `1 tus neeg tau txais kev pab`,
	ps: `1 ګډونوال`,
	pt: `1 participante`,
	'pt-pt': `1 participante`,
	ru: `1 участник`,
	sm: `1 tagata auai`,
	so: `1 ka qaybgalayaasha`,
	th: `ผู้เข้าร่วม 1 คน`,
	ur: `1 شرکاء`,
	vi: `1 người tham gia`,
	'zh-hans': `1 名参与者`,
	'zh-hant': `1 名參與者`,
	en: `1 participant`
};

export const loc_multipleParticipants: Record<Locale, (count: unknown) => string> = {
	ar: (count) => `${count} مشاركين`,
	de: (count) => `${count} Teilnehmer`,
	es: (count) => `${count} participantes`,
	fa: (count) => `${count} شرکت کننده`,
	fil: (count) => `${count} kalahok`,
	fr: (count) => `${count} participants`,
	hi: (count) => `${count} प्रतिभागियों`,
	ht: (count) => `${count} patisipan yo`,
	it: (count) => `${count} partecipanti`,
	ja: (count) => `${count} 名`,
	ko: (count) => `참가자 ${count}명`,
	mww: (count) => `${count} cov neeg koom`,
	ps: (count) => `${count} ګډون کونکی`,
	pt: (count) => `${count} participantes`,
	'pt-pt': (count) => `${count} participantes`,
	ru: (count) => `${count} участников`,
	sm: (count) => `${count} tagata auai`,
	so: (count) => `${count} ka qaybgalayaasha`,
	th: (count) => `ผู้เข้าร่วม ${count} คน`,
	ur: (count) => `${count} شرکاء`,
	vi: (count) => `${count} người tham gia`,
	'zh-hans': (count) => `${count} 名参与者`,
	'zh-hant': (count) => `${count} 名參與者`,
	en: (count) => `${count} participants`
};

export const loc_notificationReasonNone: Record<Locale, (type: unknown) => string> = {
	ar: (type) => `لا تتلقى إشعارات لهذا ${type}.`,
	de: (type) => `Sie erhalten keine Benachrichtigungen für diese ${type}.`,
	es: (type) => `No recibes notificaciones de esta ${type}.`,
	fa: (type) => `برای این ${type} اعلان دریافت نمی کنید.`,
	fil: (type) => `Hindi ka nakakatanggap ng mga notification para sa ${type} na ito.`,
	fr: (type) => `Vous ne recevez pas de notifications pour cette ${type}.`,
	hi: (type) => `आपको इस गतिविधि के लिए सूचनाएं नहीं मिल रही ${type}.`,
	ht: (type) => `Ou pa resevwa notifikasyon pou ${type} sa a.`,
	it: (type) => `Non ricevi notifiche per questa ${type}.`,
	ja: (type) => `この ${type}に関する通知は届いていません。`,
	ko: (type) => `이 ${type} 대한 알림을 받지 못하고 있습니다.`,
	mww: (type) => `Koj nyob nraum tsis tau txais ntawv ceeb toom rau ${type} no.`,
	ps: (type) => `تاسو د دې ${type} لپاره خبرتیاوې نه ترلاسه کوئ.`,
	pt: (type) => `Você não está recebendo notificações para esta ${type}.`,
	'pt-pt': (type) => `Você não está recebendo notificações sobre essa ${type}.`,
	ru: (type) => `Вы не получаете уведомления об этом ${type}.`,
	sm: (type) => `E te le o mauaina ni faaaliga mo lenei ${type}.`,
	so: (type) => `Adigu ma hesho ogeysiisyo ku saabsan ${type}.`,
	th: (type) => `คุณไม่ได้รับการแจ้งเตือนสําหรับ ${type}นี้`,
	ur: (type) => `آپ کو اس ${type} کے لئے اطلاعات موصول نہیں ہو رہی ہیں۔`,
	vi: (type) => `Bạn không nhận được thông báo cho ${type} này.`,
	'zh-hans': (type) => `您没有收到此 ${type}的通知。`,
	'zh-hant': (type) => `您沒有收到此 ${type}的通知。`,
	en: (type) => `You're not receiving notifications for this ${type}.`
};

export const loc_notificationReasonUnsubscribed: Record<Locale, (type: unknown) => string> = {
	ar: (type) => `لا تتلقى إشعارات لهذا ${type} لأنك ألغيت اشتراكك.`,
	de: (type) => `Sie erhalten keine Benachrichtigungen für diese ${type} , da Sie sich abgemeldet haben.`,
	es: (type) => `No recibes notificaciones de esta ${type} porque has cancelado la suscripción.`,
	fa: (type) => `به دلیل لغو اشتراک، اعلان هایی برای این ${type} دریافت نمی کنید.`,
	fil: (type) => `Hindi ka nakakatanggap ng mga notification para sa ${type} na ito dahil nag unsubscribe ka.`,
	fr: (type) => `Vous ne recevez pas de notifications pour cette ${type} , car vous vous êtes désabonné.`,
	hi: (type) => `आपको इस ${type} के लिए नोटिफ़िकेशन इसलिए नहीं मिल रहे हैं क्योंकि आपने सदस्यता छोड़ दी है.`,
	ht: (type) => `Ou pa resevwa notifikasyon pou ${type} sa a paske ou pa respekte.`,
	it: (type) => `Non ricevi notifiche per questa ${type} perché hai annullato l'iscrizione.`,
	ja: (type) => `登録を解除したため、この ${type} に関する通知が届いていません。`,
	ko: (type) => `구독을 취소했기 때문에 이 ${type} 대한 알림을 받지 못하고 있습니다.`,
	mww: (type) => `Koj nyob nraum tsis tau txais ntawv ceeb toom rau ${type} no vim hais tias koj unsubscribed.`,
	ps: (type) => `تاسو د دې ${type} لپاره خبرتیاوې نه ترلاسه کوئ ځکه چې تاسو ګډون نه کوئ.`,
	pt: (type) => `Você não está recebendo notificações para esta ${type} porque cancelou a assinatura.`,
	'pt-pt': (type) => `Não está a receber notificações para esta ${type} porque cancelou a subscrição.`,
	ru: (type) => `Вы не получаете уведомления об этом ${type} , так как отменили подписку.`,
	sm: (type) => `E te le o mauaina ni faaaliga mo lenei ${type} ona ua e le lesitala.`,
	so: (type) => `Adigu ma helaysid ogeysiisyo ${type} sababtoo ah waxaad ka shaqaynaysid si aan la qori karin.`,
	th: (type) => `คุณไม่ได้รับการแจ้งเตือนสําหรับ ${type} นี้เนื่องจากคุณยกเลิกการสมัครรับข้อมูล`,
	ur: (type) => `آپ کو اس ${type} کے لئے اطلاعات موصول نہیں ہو رہی ہیں کیونکہ آپ نے سبسکرائب نہیں کیا ہے۔`,
	vi: (type) => `Bạn không nhận được thông báo cho ${type} này vì bạn đã hủy đăng ký.`,
	'zh-hans': (type) => `由于您取消订阅，您没有收到此 ${type} 的通知。`,
	'zh-hant': (type) => `由於您取消訂閱，您沒有收到此 ${type} 的通知。`,
	en: (type) => `You're not receiving notifications for this ${type} because you unsubscribed.`
};

export const loc_notificationReasonManual: Record<Locale, (type: unknown) => string> = {
	ar: (type) => `تتلقى إشعارات لأنك اشتركت في هذا ${type}.`,
	de: (type) => `Sie erhalten Benachrichtigungen, weil Sie diese ${type} abonniert haben.`,
	es: (type) => `Recibes notificaciones porque te has suscrito a esta ${type}.`,
	fa: (type) => `به این دلیل اعلان دریافت می کنید که در این ${type} مشترک شده اید.`,
	fil: (type) => `Nakakatanggap ka ng mga notification dahil nag subscribe ka sa ${type} na ito.`,
	fr: (type) => `Vous recevez des notifications parce que vous vous êtes abonné à cette ${type}.`,
	hi: (type) => `आपको सूचनाएं इसलिए मिल रही हैं क्योंकि आपने इस ${type} की सदस्यता ली है.`,
	ht: (type) => `W ap resevwa notifikasyon paske w enskri nan ${type} sa a.`,
	it: (type) => `Stai ricevendo notifiche perché ti sei iscritto a questa ${type}.`,
	ja: (type) => `この ${type}を購読したため、通知が届いています。`,
	ko: (type) => `이 ${type} 가입했기 때문에 알림을 받고 있습니다.`,
	mww: (type) => `Koj nyob nraum tau txais ntawv ceeb toom vim hais tias koj subscribed rau ${type} no.`,
	ps: (type) => `تاسو خبرتیاوې ترلاسه کوئ ځکه چې تاسو دې ${type} کې ګډون کړی.`,
	pt: (type) => `Você está recebendo notificações porque se inscreveu nessa ${type}.`,
	'pt-pt': (type) => `Está a receber notificações porque subscreveu esta ${type}.`,
	ru: (type) => `Вы получаете уведомления, потому что подписались на это ${type}.`,
	sm: (type) => `Ua e mauaina ni faaaliga ona ua e lesitala i lenei ${type}.`,
	so: (type) => `Waxaa kuu imaanaya ogeysiisyo sababtoo ah waxaad subscribed to ${type}.`,
	th: (type) => `คุณได้รับการแจ้งเตือนเนื่องจากคุณสมัครใช้งาน ${type}นี้`,
	ur: (type) => `آپ کو اطلاعات موصول ہو رہی ہیں کیونکہ آپ نے اس ${type} کو سبسکرائب کیا ہے۔`,
	vi: (type) => `Bạn sẽ nhận được thông báo vì bạn đã đăng ký ${type} này.`,
	'zh-hans': (type) => `您之所以收到通知，是因为您订阅了此 ${type}。`,
	'zh-hant': (type) => `您之所以收到通知，是因為您訂閱了此 ${type}。`,
	en: (type) => `You're receiving notifications because you subscribed to this ${type}.`
};

export const loc_notificationReasonCreator: Record<Locale, (type: unknown) => string> = {
	ar: (type) => `أنت تتلقى إشعارات لأنك أنشأت هذا ${type}.`,
	de: (type) => `Sie erhalten Benachrichtigungen, weil Sie diese ${type} erstellt haben.`,
	es: (type) => `Recibes notificaciones porque has creado esta ${type}.`,
	fa: (type) => `به این دلیل اعلان دریافت می کنید که این ${type} را ایجاد کرده اید.`,
	fil: (type) => `Nakakatanggap ka ng mga notification dahil nilikha mo ang ${type} na ito.`,
	fr: (type) => `Vous recevez des notifications car vous avez créé cette ${type}.`,
	hi: (type) => `आपको सूचनाएं इसलिए मिल रही हैं क्योंकि आपने यह ${type} बनाई है.`,
	ht: (type) => `W ap resevwa notifikasyon paske ou te kreye ${type} sa a.`,
	it: (type) => `Stai ricevendo notifiche perché hai creato questa ${type}.`,
	ja: (type) => `この ${type}を作成したため、通知が届いています。`,
	ko: (type) => `이 ${type} 만들었기 때문에 알림을 받고 있습니다.`,
	mww: (type) => `Koj nyob nraum tau txais ntawv ceeb toom vim hais tias koj tsim ${type} no.`,
	ps: (type) => `تاسو خبرتیاوې ترلاسه کوئ ځکه چې تاسو دا ${type} رامینځته کړی.`,
	pt: (type) => `Você está recebendo notificações porque criou essa ${type}.`,
	'pt-pt': (type) => `Está a receber notificações porque criou esta ${type}.`,
	ru: (type) => `Вы получаете уведомления, потому что создали это ${type}.`,
	sm: (type) => `Ua e mauaina ni faaaliga ona ua e fatuina lenei ${type}.`,
	so: (type) => `Waxaad helaysaa ogeysiisyo sababtoo ah waxaad abuurtay ${type} this.`,
	th: (type) => `คุณได้รับการแจ้งเตือนเนื่องจากคุณสร้าง ${type}นี้`,
	ur: (type) => `آپ کو اطلاعات موصول ہو رہی ہیں کیونکہ آپ نے یہ ${type} بنائی ہے۔`,
	vi: (type) => `Bạn sẽ nhận được thông báo vì bạn đã tạo ${type} này.`,
	'zh-hans': (type) => `您之所以收到通知，是因为您创建了此 ${type}。`,
	'zh-hant': (type) => `您之所以收到通知，是因為您創建了此 ${type}。`,
	en: (type) => `You're receiving notifications because you created this ${type}.`
};

export const loc_notificationReasonAssignee: Record<Locale, (type: unknown) => string> = {
	ar: (type) => `أنت تتلقى إشعارات لأنه تم تعيين هذا النشاط ${type}`,
	de: (type) => `Sie erhalten Benachrichtigungen, weil Ihnen diese ${type} zugewiesen wurde.`,
	es: (type) => `Recibes notificaciones porque se te asignó esta ${type}.`,
	fa: (type) => `به این دلیل اعلان دریافت می کنید که این ${type} به شما اختصاص داده شده است.`,
	fil: (type) => `Tumatanggap ka ng mga notification dahil inatasan ka ng ${type} na ito.`,
	fr: (type) => `Vous recevez des notifications car cette ${type} vous a été attribuée.`,
	hi: (type) => `आपको सूचनाएँ इसलिए मिल रही हैं क्योंकि आपको यह ${type} असाइन की गई थी.`,
	ht: (type) => `W ap resevwa notifikasyon paske ou te ${type} sa a.`,
	it: (type) => `Si ricevono notifiche perché ${type} è stata assegnata.`,
	ja: (type) => `この ${type}が割り当てられたため、通知が届いています。`,
	ko: (type) => `이 ${type} 할당되었기 때문에 알림을 받고 있습니다.`,
	mww: (type) => `Koj nyob nraum tau txais ntawv ceeb toom vim hais tias koj tau raug xa mus ${type} no.`,
	ps: (type) => `تاسو خبرتیاوې ترلاسه کوئ ځکه چې تاسو دا ${type} ګمارلی و.`,
	pt: (type) => `Você está recebendo notificações porque recebeu essa ${type}.`,
	'pt-pt': (type) => `Está a receber notificações porque lhe foi atribuída esta ${type}.`,
	ru: (type) => `Вы получаете уведомления, потому что вам назначено это ${type}.`,
	sm: (type) => `Ua e mauaina ni faaaliga ona sa tofia oe i lenei ${type}.`,
	so: (type) => `Waxaa laguu ogaysiinayaa sababtoo ah waxaa laguu xil saaray ${type}.`,
	th: (type) => `คุณได้รับการแจ้งเตือนเนื่องจากคุณได้รับมอบหมาย ${type}นี้`,
	ur: (type) => `آپ کو اطلاعات موصول ہو رہی ہیں کیونکہ آپ کو یہ ${type} تفویض کی گئی تھی۔`,
	vi: (type) => `Bạn sẽ nhận được thông báo vì bạn đã được chỉ định ${type} này.`,
	'zh-hans': (type) => `您收到通知是因为您被分配了此 ${type}。`,
	'zh-hant': (type) => `您收到通知是因為您被分配了此 ${type}。`,
	en: (type) => `You're receiving notifications because you were assigned this ${type}.`
};

export const loc_notificationReasonCommentor: Record<Locale, (type: unknown) => string> = {
	ar: (type) => `تتلقى إشعارات لأنك علقت على هذا ${type}.`,
	de: (type) => `Sie erhalten Benachrichtigungen, weil Sie diese ${type} kommentiert haben.`,
	es: (type) => `Recibes notificaciones porque has comentado esta ${type}.`,
	fa: (type) => `به این دلیل اعلان دریافت می کنید که درباره این ${type} نظر داده اید.`,
	fil: (type) => `Nakatanggap ka ng mga notification dahil nagkomento ka sa ${type} na ito.`,
	fr: (type) => `Vous recevez des notifications parce que vous avez commenté cette ${type}.`,
	hi: (type) => `आपको सूचनाएं इसलिए मिल रही हैं क्योंकि आपने इस ${type} पर टिप्पणी की है.`,
	ht: (type) => `W ap resevwa notifikasyon paske w ap ${type} sa a.`,
	it: (type) => `Stai ricevendo notifiche perché hai commentato questa ${type}.`,
	ja: (type) => `この ${type}にコメントしたため、通知が届いています。`,
	ko: (type) => `이 ${type} 댓글을 달았기 때문에 알림을 받고 있습니다.`,
	mww: (type) => `Koj nyob nraum tau txais ntawv ceeb toom vim hais tias koj commented rau ${type} no.`,
	ps: (type) => `تاسو خبرتیاوې ترلاسه کوئ ځکه چې تاسو د دې ${type} په اړه تبصره کړې.`,
	pt: (type) => `Você está recebendo notificações porque comentou sobre essa ${type}.`,
	'pt-pt': (type) => `Está a receber notificações porque comentou esta ${type}.`,
	ru: (type) => `Вы получаете уведомления, потому что оставили комментарий к этому ${type}.`,
	sm: (type) => `Ua e mauaina ni faaaliga ona sa e faia ni faamatalaga e uiga i lenei ${type}.`,
	so: (type) => `Waxaa laguu ogaysiiyay wargaysyo sababtoo ah waxaad ka ${type}.`,
	th: (type) => `คุณได้รับการแจ้งเตือนเนื่องจากคุณแสดงความคิดเห็นเกี่ยวกับ ${type}นี้`,
	ur: (type) => `آپ کو اطلاعات موصول ہو رہی ہیں کیونکہ آپ نے اس ${type} پر تبصرہ کیا ہے۔`,
	vi: (type) => `Bạn sẽ nhận được thông báo vì bạn đã nhận xét về ${type} này.`,
	'zh-hans': (type) => `您之所以收到通知，是因为您对此 ${type}发表了评论。`,
	'zh-hant': (type) => `您之所以收到通知，是因為您對此 ${type}發表了評論。`,
	en: (type) => `You're receiving notifications because you commented on this ${type}.`
};

export const loc_notificationReasonMentioned: Record<Locale, (type: unknown) => string> = {
	ar: (type) => `أنت تتلقى إشعارات لأنه تمت الإشارة إليك مباشرة في هذا ${type}.`,
	de: (type) => `Sie erhalten Benachrichtigungen, weil Sie in dieser ${type} direkt erwähnt wurden.`,
	es: (type) => `Recibes notificaciones porque se te ha mencionado directamente en esta ${type}.`,
	fa: (type) => `شما اعلان هایی دریافت می کنید زیرا مستقیما در این ${type} از شما نام برده شده است.`,
	fil: (type) => `Nakatanggap ka ng mga notification dahil direkta kang nabanggit sa ${type} na ito.`,
	fr: (type) => `Vous recevez des notifications car vous avez été directement mentionné dans cette ${type}.`,
	hi: (type) => `आपको सूचनाएं इसलिए मिल रही हैं क्योंकि इस ${type} में आपका सीधे तौर पर उल्लेख किया गया था.`,
	ht: (type) => `W ap resevwa notifikasyon paske ou te dirèkteman mansyone nan ${type} sa a.`,
	it: (type) => `Stai ricevendo notifiche perché sei stato menzionato direttamente in questa ${type}.`,
	ja: (type) => `この ${type}で直接メンションされたため、通知が届いています。`,
	ko: (type) => `이 ${type}에서 직접 언급되었기 때문에 알림을 받고 있습니다.`,
	mww: (type) => `Koj nyob nraum tau txais ntawv ceeb toom vim hais tias koj tau ncaj qha mus hais nyob rau hauv ${type} no.`,
	ps: (type) => `تاسو خبرتیاوې ترلاسه کوئ ځکه چې تاسو په مستقیم ډول پدې ${type} کې ذکر شوی یاست.`,
	pt: (type) => `Você está recebendo notificações porque foi mencionado diretamente nesta ${type}.`,
	'pt-pt': (type) => `Você está recebendo notificações porque foi mencionado diretamente nesta ${type}.`,
	ru: (type) => `Вы получаете уведомления, потому что вас прямо упомянули в этом ${type}.`,
	sm: (type) => `Ua e mauaina ni faaaliga aua sa ta'ua tonu lava oe i lenei ${type}.`,
	so: (type) => `Waxaa lagaa helayaa ogeysiisyo sababtoo ah waxaa si toos ah laguugu sheegay ${type}.`,
	th: (type) => `คุณได้รับการแจ้งเตือนเนื่องจากมีการกล่าวถึงคุณโดยตรงใน ${type}นี้`,
	ur: (type) => `آپ اطلاعات وصول کر رہے ہیں کیونکہ آپ کو اس ${type} میں براہ راست ذکر کیا گیا تھا۔`,
	vi: (type) => `Bạn sẽ nhận được thông báo vì bạn đã được đề cập trực tiếp trong ${type} này.`,
	'zh-hans': (type) => `您收到通知是因为此 ${type}中直接提到了您。`,
	'zh-hant': (type) => `您收到通知是因為此 ${type}中直接提到了您。`,
	en: (type) => `You're receiving notifications because you were directly mentioned in this ${type}.`
};

export const loc_notificationReasonUpdator: Record<Locale, (type: unknown) => string> = {
	ar: (type) => `تتلقى إشعارات لأنك قمت بتحديث هذا ${type}.`,
	de: (type) => `Sie erhalten Benachrichtigungen, weil Sie diese ${type} aktualisiert haben.`,
	es: (type) => `Recibes notificaciones porque has actualizado esta ${type}.`,
	fa: (type) => `به این دلیل اعلان دریافت می کنید که این ${type} را به روزرسانی کرده اید.`,
	fil: (type) => `Nakatanggap ka ng mga notification dahil na update mo ang ${type} na ito.`,
	fr: (type) => `Vous recevez des notifications car vous avez mis à jour cette ${type}.`,
	hi: (type) => `आपको सूचनाएं इसलिए मिल रही हैं क्योंकि आपने यह ${type} अपडेट की है.`,
	ht: (type) => `W ap resevwa notifikasyon paske w mete ajou ${type} sa a.`,
	it: (type) => `Stai ricevendo notifiche perché hai aggiornato questa ${type}.`,
	ja: (type) => `この ${type}を更新したため、通知が届いています。`,
	ko: (type) => `이 ${type} 업데이트했기 때문에 알림을 받고 있습니다.`,
	mww: (type) => `Koj nyob nraum tau txais ntawv ceeb toom vim hais tias koj muab kho ${type} no.`,
	ps: (type) => `تاسو خبرتیاوې ترلاسه کوئ ځکه چې تاسو دا ${type}.`,
	pt: (type) => `Você está recebendo notificações porque atualizou essa ${type}.`,
	'pt-pt': (type) => `Está a receber notificações porque atualizou esta ${type}.`,
	ru: (type) => `Вы получаете уведомления, потому что обновили это ${type}.`,
	sm: (type) => `Ua e mauaina ni faaaliga ona ua e faafouina lenei ${type}.`,
	so: (type) => `Waxaa laguu ogaysiiyay ogeysiisyo sababtoo ah waxaad ${type}.`,
	th: (type) => `คุณได้รับการแจ้งเตือนเนื่องจากคุณอัปเดต ${type}นี้`,
	ur: (type) => `آپ کو اطلاعات موصول ہو رہی ہیں کیونکہ آپ نے اس ${type} کو اپ ڈیٹ کیا ہے۔`,
	vi: (type) => `Bạn sẽ nhận được thông báo vì bạn đã cập nhật ${type} này.`,
	'zh-hans': (type) => `您收到通知是因为您更新了此 ${type}记录。`,
	'zh-hant': (type) => `您收到通知是因為您更新了此 ${type}記錄。`,
	en: (type) => `You're receiving notifications because you updated this ${type}.`
};

export const loc_notificationReasonTeamMentioned: Record<Locale, (type: unknown) => string> = {
	ar: (type) => `أنت تتلقى إعلامات لأنه تم ذكر فريق أنت عضو فيه في هذا ${type}.`,
	de: (type) => `Sie erhalten Benachrichtigungen, weil ein Team, in dem Sie Mitglied sind, in dieser ${type} erwähnt wurde.`,
	es: (type) => `Recibes notificaciones porque un equipo del que eres miembro se mencionó en esta ${type}.`,
	fa: (type) => `شما اعلان هایی دریافت می کنید زیرا تیمی که عضو آن هستید در این ${type} ذکر شده است.`,
	fil: (type) => `Nakatanggap ka ng mga notification dahil ang isang koponan na miyembro mo ay nabanggit sa ${type} na ito.`,
	fr: (type) => `Vous recevez des notifications car une équipe dont vous êtes membre a été mentionnée dans cette ${type}.`,
	hi: (type) => `आपको सूचनाएँ इसलिए मिल रही हैं क्योंकि इस ${type} में उस टीम का उल्लेख किया गया था जिसके आप सदस्य हैं.`,
	ht: (type) => `W ap resevwa notifikasyon paske yon ekip ou se yon manm nan te mansyone nan ${type} sa a.`,
	it: (type) => `Stai ricevendo notifiche perché un team di cui fai parte è stato menzionato in questa ${type}.`,
	ja: (type) => `この ${type}で自分がメンバーであるチームがメンションされたため、通知が届いています。`,
	ko: (type) => `회원님이 속한 팀이 이 ${type}에서 언급되었기 때문에 알림을 받고 있습니다.`,
	mww: (type) => `Koj nyob nraum tau txais ntawv ceeb toom vim ib pab neeg uas koj nyob nraum ib tug nyob hauv ${type} no.`,
	ps: (type) => `تاسو خبرتیاوې ترلاسه کوئ ځکه چې یو ټیم چې تاسو یې غړی یاست پدې ${type} کې یادونه شوې.`,
	pt: (type) => `Você está recebendo notificações porque uma equipe da qual você é membro foi mencionada nesta ${type}.`,
	'pt-pt': (type) => `Está a receber notificações porque uma equipa da qual é membro foi mencionada nesta ${type}.`,
	ru: (type) => `Вы получаете уведомления, потому что команда, в которой вы состоите, была упомянута в этом ${type}.`,
	sm: (type) => `Ua e mauaina ni faaaliga ona o se au o oe o se tasi o le au na ta'ua i lenei ${type}.`,
	so: (type) => `Waxaa laguu ogaysiinayaa sababtoo ah koox aad xubin ka tahay ayaa lagu sheegay ${type}.`,
	th: (type) => `คุณได้รับการแจ้งเตือนเนื่องจากมีการกล่าวถึงทีมที่คุณเป็นสมาชิกใน${type}`,
	ur: (type) => `آپ کو اطلاعات موصول ہو رہی ہیں کیونکہ اس ${type} میں جس ٹیم کا آپ رکن ہیں اس کا ذکر کیا گیا تھا۔`,
	vi: (type) => `Bạn sẽ nhận được thông báo vì một nhóm mà bạn là thành viên đã được đề cập trong ${type} này.`,
	'zh-hans': (type) => `您收到通知是因为此 ${type}中提到了您所属的团队。`,
	'zh-hant': (type) => `您收到通知是因為此 ${type}中提到了您所屬的團隊。`,
	en: (type) => `You're receiving notifications because a team you're a member of was mentioned in this ${type}.`
};

export const loc_notificationReasonAutomatic: Record<Locale, (type: unknown) => string> = {
	ar: (type) => `تتلقى إشعارات لأنك اشتركت تلقائيا في هذا ${type}.`,
	de: (type) => `Sie erhalten Benachrichtigungen, weil Sie diese ${type} automatisch abonniert haben.`,
	es: (type) => `Recibes notificaciones porque te has suscrito automáticamente a esta ${type}.`,
	fa: (type) => `شما اعلان دریافت می کنید زیرا به طور خودکار در این ${type} مشترک شده اید.`,
	fil: (type) => `Nakatanggap ka ng mga notification dahil awtomatikong na-subscribe ka sa ${type} na ito.`,
	fr: (type) => `Vous recevez des notifications car vous avez été automatiquement abonné à cette ${type}.`,
	hi: (type) => `आपको सूचनाएं इसलिए मिल रही हैं क्योंकि आपने इस ${type} की अपने आप सदस्यता ले ली है.`,
	ht: (type) => `W ap resevwa notifikasyon paske ou te otomatikman enskri nan ${type} sa a.`,
	it: (type) => `Stai ricevendo notifiche perché sei stato automaticamente iscritto a questa ${type}.`,
	ja: (type) => `この ${type}に自動的に登録されたため、通知が届いています。`,
	ko: (type) => `이 ${type} 자동으로 가입되었기 때문에 알림을 받고 있습니다.`,
	mww: (type) => `Koj nyob nraum tau txais ntawv ceeb toom vim hais tias koj twb tau subscribed rau ${type} no.`,
	ps: (type) => `تاسو خبرتیاوې ترلاسه کوئ ځکه چې تاسو په اتوماتیک ډول په دې ${type} کې ګډون کړی و.`,
	pt: (type) => `Você está recebendo notificações porque foi inscrito automaticamente nessa ${type}.`,
	'pt-pt': (type) => `Está a receber notificações porque foi automaticamente inscrito nesta ${type}.`,
	ru: (type) => `Вы получаете уведомления, потому что были автоматически подписаны на это ${type}.`,
	sm: (type) => `Ua e mauaina ni faaaliga aua sa otometi lava ona e lesitala i lenei ${type}.`,
	so: (type) => `Waxaa laguu ogaysiiyay ogeysiisyo sababtoo ah si toos ah ayaa loogu qoray ${type}.`,
	th: (type) => `คุณได้รับการแจ้งเตือนเนื่องจากคุณสมัครใช้งาน ${type}นี้โดยอัตโนมัติ`,
	ur: (type) => `آپ کو اطلاعات موصول ہو رہی ہیں کیونکہ آپ خود بخود اس ${type} میں سبسکرائب ہو گئے تھے۔`,
	vi: (type) => `Bạn sẽ nhận được thông báo vì bạn đã tự động đăng ký ${type} này.`,
	'zh-hans': (type) => `您收到通知是因为您已自动订阅此 ${type}。`,
	'zh-hant': (type) => `您收到通知是因為您已自動訂閱此 ${type}。`,
	en: (type) => `You're receiving notifications because you were automatically subscribed to this ${type}.`
};

export const loc_closeEdit: Record<Locale, string> = {
	ar: `إغلاق التحرير`,
	de: `Bearbeiten schließen`,
	es: `Cerrar edición`,
	fa: `بستن ویرایش`,
	fil: `Isara ang edit`,
	fr: `Fermer modifier`,
	hi: `बंद करें संपादित करें`,
	ht: `Edit fèmen`,
	it: `Chiudi modifica`,
	ja: `編集を閉じる`,
	ko: `편집 닫기`,
	mww: `Kaw edit`,
	ps: `سمون بندول`,
	pt: `Fechar edição`,
	'pt-pt': `Fechar edição`,
	ru: `Закрыть редактирование`,
	sm: `Tapuni le faasa'o`,
	so: `Edit Close`,
	th: `ปิดการแก้ไข`,
	ur: `قریبی ترمیم`,
	vi: `Đóng chỉnh sửa`,
	'zh-hans': `关闭编辑`,
	'zh-hant': `關閉編輯`,
	en: `Close edit`
};

export const loc_yes: Record<Locale, string> = {
	ar: `نعم`,
	de: `Ja`,
	es: `Sí`,
	fa: `بله`,
	fil: `Oo nga`,
	fr: `Oui`,
	hi: `हाँ`,
	ht: `Wi`,
	it: `Sì`,
	ja: `はい`,
	ko: `예`,
	mww: `Muaj`,
	ps: `هو`,
	pt: `Sim`,
	'pt-pt': `Sim`,
	ru: `Да`,
	sm: `Ioe`,
	so: `Haa`,
	th: `ใช่`,
	ur: `ہاں`,
	vi: `Có`,
	'zh-hans': `是的`,
	'zh-hant': `是的`,
	en: `Yes`
};

export const loc_no: Record<Locale, string> = {
	ar: `لا`,
	de: `Nein`,
	es: `No`,
	fa: `نه`,
	fil: `Hindi`,
	fr: `Non`,
	hi: `नहीं`,
	ht: `Non`,
	it: `No`,
	ja: `いいえ`,
	ko: `아니요`,
	mww: `Tsis`,
	ps: `نا`,
	pt: `Não`,
	'pt-pt': `Não`,
	ru: `Нет`,
	sm: `Leai`,
	so: `Maya`,
	th: `ไม่ใช่`,
	ur: `نہيں`,
	vi: `Không`,
	'zh-hans': `不`,
	'zh-hant': `不`,
	en: `No`
};

export const loc_invalidRequired: Record<Locale, (label: unknown) => string> = {
	ar: (label) => `${label} مطلوب.`,
	de: (label) => `${label} ist erforderlich.`,
	es: (label) => `${label} es obligatorio.`,
	fa: (label) => `${label} الزامی است.`,
	fil: (label) => `Kailangan ang ${label}.`,
	fr: (label) => `${label} est obligatoire.`,
	hi: (label) => `${label} आवश्यक है.`,
	ht: (label) => `${label} obligatwa.`,
	it: (label) => `${label} è obbligatorio.`,
	ja: (label) => `${label} は必須です。`,
	ko: (label) => `${label} 필수입니다.`,
	mww: (label) => `${label} yuav tsum muaj.`,
	ps: (label) => `نوم لیکنه ${label}.`,
	pt: (label) => `${label} é obrigatório.`,
	'pt-pt': (label) => `${label} é obrigatório.`,
	ru: (label) => `${label} обязательно для заполнения.`,
	sm: (label) => `E manaomia le ${label}.`,
	so: (label) => `${label} ayaa loo baahan yahay.`,
	th: (label) => `ต้องระบุ${label}`,
	ur: (label) => `${label} کی ضرورت ہے.`,
	vi: (label) => `${label} là bắt buộc.`,
	'zh-hans': (label) => `${label} 为必填项。`,
	'zh-hant': (label) => `${label} 為必填項。`,
	en: (label) => `${label} is required.`
};

export const loc_invalidFormat: Record<Locale, (label: unknown) => string> = {
	ar: (label) => `تم تنسيق ${label} بشكل غير صحيح.`,
	de: (label) => `${label} ist falsch formatiert.`,
	es: (label) => `${label} tiene un formato incorrecto.`,
	fa: (label) => `${label} به اشتباه قالب بندی شده است.`,
	fil: (label) => `Mali ang format ng ${label}.`,
	fr: (label) => `${label} n’est pas correct.`,
	hi: (label) => `${label} ग़लत स्वरूपित किया गया है.`,
	ht: (label) => `${label} se kòrèkteman fòma.`,
	it: (label) => `${label} non è formattato correttamente.`,
	ja: (label) => `${label} の形式が正しくありません。`,
	ko: (label) => `${label} 형식이 잘못되었습니다.`,
	mww: (label) => `${label} yog incorrectly formatted.`,
	ps: (label) => `د ${label}، اصلی، د نوم نوم مطابقت غلط.`,
	pt: (label) => `${label} está formatado incorretamente.`,
	'pt-pt': (label) => `${label} está formatado incorretamente.`,
	ru: (label) => `${label} отформатировано неправильно.`,
	sm: (label) => `E le sao le faatulagaina o le ${label}.`,
	so: (label) => `${label} si qaldan formatted.`,
	th: (label) => `${label} ถูกจัดรูปแบบไม่ถูกต้อง`,
	ur: (label) => `${label} غلط فارمیٹ کیا گیا ہے۔`,
	vi: (label) => `${label} được định dạng không chính xác.`,
	'zh-hans': (label) => `${label} 格式不正确。`,
	'zh-hant': (label) => `${label} 格式不正確。`,
	en: (label) => `${label} is incorrectly formatted.`
};

export const loc_invalidInteger: Record<Locale, (label: unknown) => string> = {
	ar: (label) => `${label} لا يسمح بالكسور.`,
	de: (label) => `${label} lässt keine Brüche zu.`,
	es: (label) => `${label} no admite fracciones.`,
	fa: (label) => `${label} اجازه کسری را نمی دهد.`,
	fil: (label) => `Hindi pinapayagan ng ${label} ang mga fraction.`,
	fr: (label) => `${label} n’autorise pas les fractions.`,
	hi: (label) => `${label} भिन्नों की अनुमति नहीं देता है।`,
	ht: (label) => `${label} pa pèmèt fraksyon.`,
	it: (label) => `${label} non consente frazioni.`,
	ja: (label) => `${label} は分数を許可しません。`,
	ko: (label) => `${label} 에는 분수를 사용할 수 없습니다.`,
	mww: (label) => `${label} tsis pub muaj feem tau feem ( fractions).`,
	ps: (label) => `د ${label}، اصلی، د نوم نوم مطابقت 2.`,
	pt: (label) => `${label} não permite frações.`,
	'pt-pt': (label) => `${label} não permite frações.`,
	ru: (label) => `${label} не допускает дробей.`,
	sm: (label) => `E le faatagaina e le ${label} ia vaega itiiti.`,
	so: (label) => `${label} ma ogola in kala jajabku uu kala jajabku dhaco.`,
	th: (label) => `${label} ไม่อนุญาตให้ใช้เศษส่วน`,
	ur: (label) => `${label} اجازت نہیں دیتا ہے۔`,
	vi: (label) => `${label} không cho phép phân số.`,
	'zh-hans': (label) => `${label} 不允许使用分数。`,
	'zh-hant': (label) => `${label} 不允許使用分數。`,
	en: (label) => `${label} does not permit fractions.`
};

export const loc_invalidMultiple: Record<Locale, (label: unknown, step: unknown) => string> = {
	ar: (label, step) => `يجب أن يكون ${label} من مضاعفات ${step}`,
	de: (label, step) => `${label} muss ein Vielfaches von ${step} sein`,
	es: (label, step) => `${label} debe ser un múltiplo de ${step}`,
	fa: (label, step) => `${label} باید مضربی ${step} باشد`,
	fil: (label, step) => `${label} ay dapat na isang multiple ng ${step}`,
	fr: (label, step) => `${label} doit être un multiple de ${step}`,
	hi: (label, step) => `${label} ${step} का गुणज होना आवश्यक है`,
	ht: (label, step) => `${label} dwe yon miltip ${step}`,
	it: (label, step) => `${label} deve essere un multiplo di ${step}`,
	ja: (label, step) => `${label}は ${step} の倍数である必要があります`,
	ko: (label, step) => `${label} ${step}의 배수여야 합니다.`,
	mww: (label, step) => `${label} yuav tsum muaj ntau ${step}`,
	ps: (label, step) => `د ${label}، اصلی، د ${step}.`,
	pt: (label, step) => `${label} deve ser um múltiplo de ${step}`,
	'pt-pt': (label, step) => `${label} deve ser um múltiplo de ${step}`,
	ru: (label, step) => `${label} должно быть кратно ${step}`,
	sm: (label, step) => `O le ${label} e tatau ona tele i le ${step}`,
	so: (label, step) => `${label} waa inuu noqdaa mid badan oo ${step} ah`,
	th: (label, step) => `${label} เป็นทวีคูณของ ${step}`,
	ur: (label, step) => `${label} ${step} کا ملٹی پل ہونا ضروری ہے`,
	vi: (label, step) => `${label} phải là bội số của ${step}`,
	'zh-hans': (label, step) => `${label}必须是 ${step} 的倍数`,
	'zh-hant': (label, step) => `${label}必須是 ${step} 的倍數`,
	en: (label, step) => `${label} must be a multiple of ${step}`
};

export const loc_invalidLogin: Record<Locale, (label: unknown) => string> = {
	ar: (label) => `قد يحتوي ${label} فقط على أحرف أو أرقام أو واصلات مفردة ، ولا يمكن أن يبدأ أو ينتهي بواصلة.`,
	de: (label) => `${label} darf nur Buchstaben, Zahlen oder einzelne Bindestriche enthalten und darf nicht mit einem Bindestrich beginnen oder enden.`,
	es: (label) => `${label} solo puede contener letras, números o guiones simples, y no puede comenzar ni terminar con un guión.`,
	fa: (label) => `${label} ممکن است فقط شامل حروف، اعداد یا خط تیره باشد و نمی تواند با خط فاصله شروع یا پایان یابد.`,
	fil: (label) => `Ang ${label} ay maaari lamang maglaman ng mga titik, numero, o solong mga pantig, at hindi maaaring magsimula o magtapos sa isang hyphen.`,
	fr: (label) => `${label} ne peut contenir que des lettres, des chiffres ou des traits d’union simples, et ne peut pas commencer ou se terminer par un trait d’union.`,
	hi: (label) => `${label} में केवल अक्षर, संख्याएँ या एकल हाइफ़न हो सकते हैं, और हाइफ़न के साथ प्रारंभ या समाप्त नहीं हो सकते.`,
	ht: (label) => `${label} li ka sèlman gen lèt, nimewo, oswa yon sèl tifi, epi yo pa ka kòmanse oswa fini ak yon tirè.`,
	it: (label) => `${label} può contenere solo lettere, numeri o singoli trattini e non può iniziare o terminare con un trattino.`,
	ja: (label) => `${label} には、文字、数字、または単一のハイフンのみを含めることができ、ハイフンで開始または終了することはできません。`,
	ko: (label) => `${label} 은 문자, 숫자 또는 단일 하이픈만 포함할 수 있으며 하이픈으로 시작하거나 끝날 수 없습니다.`,
	mww: (label) => `${label} yuav tsuas muaj cov ntawv, zauv, los yog ib zaug xwb hyphens, thiab tsis tau pib los yog xaus nrog ib hyphen.`,
	ps: (label) => `${label} ممکن یوازې لیکونه ، شمیرې ، یا واحد نښلونکی ولری ، او نشی کولی د نښلونکی سره پیل یا پای ته ورسیږی.`,
	pt: (label) => `${label} pode conter apenas letras, números ou hífens simples e não pode começar ou terminar com um hífen.`,
	'pt-pt': (label) => `${label} só pode conter letras, números ou hífenes únicos, e não pode começar ou terminar com um hífen.`,
	ru: (label) => `${label} может содержать только буквы, цифры или одинарные дефисы и не может начинаться или заканчиваться дефисом.`,
	sm: (label) => `E na o mataitusi, fuainumera, po o faailoga e tasi, e mafai ona i ai i le ${label}, ma e le mafai ona amata pe faaiu i se faailoga.`,
	so: (label) => `${label} wuxuu ku jiri karaa xarafaha, lambarada, ama xarfaha hal mar, mana ku bilaaban karo kumana dhamaan karo xarfaha xarfaha.`,
	th: (label) => `${label} เฉพาะตัวอักษร ตัวเลข หรือยัติภังค์ตัวเดียว และไม่สามารถขึ้นต้นหรือลงท้ายด้วยยัติภังค์ได้`,
	ur: (label) => `${label} میں صرف حروف ، نمبر ، یا سنگل ہائیفین شامل ہوسکتے ہیں ، اور ہائفین کے ساتھ شروع یا ختم نہیں ہوسکتا ہے۔`,
	vi: (label) => `${label} chỉ có thể chứa chữ cái, số hoặc dấu gạch nối đơn và không thể bắt đầu hoặc kết thúc bằng dấu gạch nối.`,
	'zh-hans': (label) => `${label} ） 只能包含字母、数字或单个连字符，不能以连字符开头或结尾。`,
	'zh-hant': (label) => `${label} ） 只能包含字母、數位或單個連字元，不能以連字元開頭或結尾。`,
	en: (label) => `${label} may only contain letters, numbers, or single hyphens, and cannot begin or end with a hyphen.`
};

export const loc_invalidGeneral: Record<Locale, (label: unknown) => string> = {
	ar: (label) => `${label} غير صالح.`,
	de: (label) => `${label} ist ungültig.`,
	es: (label) => `${label} no es válido.`,
	fa: (label) => `${label} نامعتبر است.`,
	fil: (label) => `Hindi valid ang ${label}.`,
	fr: (label) => `${label} n’est pas valide.`,
	hi: (label) => `${label} अमान्य है.`,
	ht: (label) => `${label} se envalid.`,
	it: (label) => `${label} non è valido.`,
	ja: (label) => `${label} が無効です。`,
	ko: (label) => `${label} 잘못되었습니다.`,
	mww: (label) => `${label} yog invalid.`,
	ps: (label) => `نوم ${label} بې اعتباره دی.`,
	pt: (label) => `${label} é inválido.`,
	'pt-pt': (label) => `${label} é inválido.`,
	ru: (label) => `${label} неверно.`,
	sm: (label) => `E le o aoga le ${label}.`,
	so: (label) => `${label} waa mid aan dhab ahayn.`,
	th: (label) => `${label}`,
	ur: (label) => `${label} غیر قانونی ہے۔`,
	vi: (label) => `${label} không hợp lệ.`,
	'zh-hans': (label) => `${label} 无效。`,
	'zh-hant': (label) => `${label} 無效。`,
	en: (label) => `${label} is invalid.`
};

export const loc_invalidMinLength: Record<Locale, (label: unknown, min: unknown) => string> = {
	ar: (label, min) => `يجب أن يتكون ${label} ${min} أحرف على الأقل.`,
	de: (label, min) => `${label} muss mindestens ${min} Zeichen lang sein.`,
	es: (label, min) => `${label} debe tener al menos ${min} caracteres.`,
	fa: (label, min) => `${label} باید حداقل ${min} کاراکتر باشد.`,
	fil: (label, min) => `${label} ay dapat na hindi bababa sa ${min} character.`,
	fr: (label, min) => `${label} doit comporter au moins ${min} caractères.`,
	hi: (label, min) => `${label} कम से कम ${min} वर्णों का होना चाहिए.`,
	ht: (label, min) => `${label} dwe omwen ${min} karaktè.`,
	it: (label, min) => `${label} deve essere composto da almeno ${min} caratteri.`,
	ja: (label, min) => `${label} は ${min} 文字以上にする必要があります。`,
	ko: (label, min) => `${label} ${min} 자 이상이어야 합니다.`,
	mww: (label, min) => `${label} yuav tsum muaj tsawg kawg yog ${min} cim.`,
	ps: (label, min) => `نوم ${label} تر لږه ${min} حروف وی.`,
	pt: (label, min) => `${label} deve ter pelo menos ${min} caracteres.`,
	'pt-pt': (label, min) => `${label} deve ter pelo menos ${min} caracteres.`,
	ru: (label, min) => `${label} должно состоять не менее ${min} символов.`,
	sm: (label, min) => `O le ${label} e tatau ona le itiiti ifo ma le ${min} mataitusi.`,
	so: (label, min) => `${label} waa inuu ahaadaa ugu yaraan ${min} characters.`,
	th: (label, min) => `${label} อย่างน้อย ${min} อักขระ`,
	ur: (label, min) => `${label} کم از کم ${min} حروف کا ہونا ضروری ہے.`,
	vi: (label, min) => `${label} phải có ít nhất ${min} ký tự.`,
	'zh-hans': (label, min) => `${label} 必须至少为 ${min} 个字符。`,
	'zh-hant': (label, min) => `${label} 必須至少為 ${min} 個字元。`,
	en: (label, min) => `${label} must be at least ${min} characters.`
};

export const loc_invalidMaxLength: Record<Locale, (label: unknown, max: unknown) => string> = {
	ar: (label, max) => `لا يمكن أن يكون ${label} أطول من ${max} حرفا.`,
	de: (label, max) => `${label} darf nicht länger als ${max} Zeichen sein.`,
	es: (label, max) => `${label} no puede tener más de ${max} caracteres.`,
	fa: (label, max) => `${label} نمی تواند بیشتر ${max} کاراکتر باشد.`,
	fil: (label, max) => `${label} ay hindi maaaring mas mahaba kaysa sa ${max} character.`,
	fr: (label, max) => `${label} ne peut pas comporter plus de ${max} caractères.`,
	hi: (label, max) => `${label} ${max} वर्णों से अधिक लंबा नहीं हो सकता.`,
	ht: (label, max) => `${label} pa ka pi long pase ${max} karaktè.`,
	it: (label, max) => `${label} non può contenere più di ${max} caratteri.`,
	ja: (label, max) => `${label} は ${max} 文字以内にする必要があります。`,
	ko: (label, max) => `${label} ${max} 자를 초과할 수 없습니다.`,
	mww: (label, max) => `${label} yuav tsis ntev tshaj ${max} cim.`,
	ps: (label, max) => `د ${label} له ${max} تورو څخه زیات نشی.`,
	pt: (label, max) => `${label} não pode ter mais de ${max} caracteres.`,
	'pt-pt': (label, max) => `${label} não pode ter mais de ${max} caracteres.`,
	ru: (label, max) => `${label} не может быть длиннее ${max} символов.`,
	sm: (label, max) => `E le mafai ona umi atu le ${label} nai lo le ${max} mataitusi.`,
	so: (label, max) => `${label} ma noqon karo wax ka badan ${max} jimo.`,
	th: (label, max) => `${label} ไม่เกิน ${max} อักขระ`,
	ur: (label, max) => `${label} 30 حروف سے زیادہ لمبا نہیں ${max} ۔`,
	vi: (label, max) => `${label} không được dài hơn ${max} ký tự.`,
	'zh-hans': (label, max) => `${label} 不能超过 ${max} 个字符。`,
	'zh-hant': (label, max) => `${label} 不能超過 ${max} 個字元。`,
	en: (label, max) => `${label} cannot be longer than ${max} characters.`
};

export const loc_invalidMinNumber: Record<Locale, (label: unknown, min: unknown) => string> = {
	ar: (label, min) => `لا يمكن أن يكون ${label} أقل من ${min}.`,
	de: (label, min) => `${label} darf nicht kleiner als ${min} sein.`,
	es: (label, min) => `${label} no puede ser inferior a ${min}.`,
	fa: (label, min) => `${label} نمی تواند کمتر از ${min} باشد.`,
	fil: (label, min) => `${label} maaaring mas mababa sa ${min} ang pangalan.`,
	fr: (label, min) => `${label} ne peut pas être inférieur à ${min}.`,
	hi: (label, min) => `${label} ${min} से कम नहीं हो सकता।`,
	ht: (label, min) => `${label} pa kapab mwens pase ${min}.`,
	it: (label, min) => `${label} non può essere inferiore a ${min}.`,
	ja: (label, min) => `${label} を ${min} 未満にすることはできません。`,
	ko: (label, min) => `${label} ${min}보다 작을 수 없습니다.`,
	mww: (label, min) => `${label} yuav tsis tau tsawg tshaj ${min}.`,
	ps: (label, min) => `د ${label} د نوم نوم مطابقت ${min}.`,
	pt: (label, min) => `${label} não pode ser inferior a ${min}.`,
	'pt-pt': (label, min) => `${label} não pode ser inferior a ${min}.`,
	ru: (label, min) => `${label} не может быть меньше ${min}.`,
	sm: (min, label) => `E le mafai ona itiiti ifo ma le ${min} le ${label}.`,
	so: (label, min) => `${label} kama yaraan karo ${min}.`,
	th: (label, min) => `${label}ไม่น้อยกว่า ${min}`,
	ur: (label, min) => `${label} ${min} سے کم نہیں ہو سکتا۔`,
	vi: (label, min) => `${label} không được nhỏ hơn ${min}.`,
	'zh-hans': (label, min) => `${label} 不能小于 ${min}。`,
	'zh-hant': (label, min) => `${label} 不能小於 ${min}。`,
	en: (label, min) => `${label} cannot be less than ${min}.`
};

export const loc_invalidMaxNumber: Record<Locale, (label: unknown, max: unknown) => string> = {
	ar: (label, max) => `لا يمكن أن يكون ${label} أكبر من ${max}.`,
	de: (label, max) => `${label} darf nicht größer als ${max} sein.`,
	es: (label, max) => `${label} no puede ser mayor que ${max}.`,
	fa: (label, max) => `${label} نمی تواند بزرگتر ${max} باشد.`,
	fil: (label, max) => `Hindi maaaring mas malaki ang ${label} kaysa sa ${max}.`,
	fr: (label, max) => `${label} ne peut pas être supérieur à ${max}.`,
	hi: (label, max) => `${label} ${max} से अधिक नहीं हो सकता.`,
	ht: (label, max) => `${label} pa ka pi gran pase ${max}.`,
	it: (label, max) => `${label} non può essere maggiore di ${max}.`,
	ja: (label, max) => `${label} は ${max} より大きくすることはできません。`,
	ko: (label, max) => `${label} ${max}보다 클 수 없습니다.`,
	mww: (label, max) => `${label} yuav tsis tau ntau tshaj ${max}.`,
	ps: (label, max) => `دا ${label} له ${max} څخه ډیر نشی کیدی.`,
	pt: (label, max) => `${label} não pode ser maior que ${max}.`,
	'pt-pt': (label, max) => `${label} não pode ser maior que ${max}.`,
	ru: (label, max) => `${label} не может быть больше ${max}.`,
	sm: (label, max) => `E le mafai ona sili atu le ${label} nai lo le ${max}.`,
	so: (label, max) => `${label} kama weynaan karaan ${max} jir.`,
	th: (label, max) => `${label}ไม่เกิน ${max}`,
	ur: (label, max) => `${label} ${max} سے زیادہ نہیں ہو سکتا۔`,
	vi: (label, max) => `${label} không được lớn hơn ${max}.`,
	'zh-hans': (label, max) => `${label} 不能大于 ${max}。`,
	'zh-hant': (label, max) => `${label} 不能大於 ${max}。`,
	en: (label, max) => `${label} cannot be greater than ${max}.`
};

export const loc_invalidMaxCount: Record<Locale, (length: unknown) => string> = {
	ar: (length) => `الحد الأقصى لعدد العناصر هو ${length}.`,
	de: (length) => `Die maximale Anzahl von Elementen beträgt ${length}.`,
	es: (length) => `El número máximo de artículos es ${length}.`,
	fa: (length) => `حداکثر تعداد اقلام ${length} است.`,
	fil: (length) => `Maximum na bilang ng mga item ay ${length}.`,
	fr: (length) => `Le nombre maximum d’articles est de ${length}.`,
	hi: (length) => `आइटम्स की अधिकतम संख्या ${length} है.`,
	ht: (length) => `Nimewo maksimòm atik yo se ${length}.`,
	it: (length) => `Il numero massimo di oggetti è ${length}.`,
	ja: (length) => `最大アイテム数は ${length}個です。`,
	ko: (length) => `최대 항목 수는 ${length}개입니다.`,
	mww: (length) => `Cov khoom muaj tsawg tshaj plaws yog ${length}.`,
	ps: (length) => `د توکو اعظمی شمیر ${length} دی.`,
	pt: (length) => `O número máximo de itens é ${length}.`,
	'pt-pt': (length) => `O número máximo de itens é ${length}.`,
	ru: (length) => `Максимальное количество предметов - ${length}.`,
	sm: (length) => `O le aofaiga maualuga o aitema e ${length}.`,
	so: (length) => `Tirada ugu badan ee sheyga waa ${length}.`,
	th: (length) => `จํานวนรายการสูงสุดคือ ${length} รายการ`,
	ur: (length) => `اشیاء کی زیادہ سے زیادہ تعداد ${length} ہے.`,
	vi: (length) => `Số lượng mặt hàng tối đa là ${length}.`,
	'zh-hans': (length) => `最大项数为 ${length}。`,
	'zh-hant': (length) => `最大項數為 ${length}。`,
	en: (length) => `Maximum number of items is ${length}.`
};

export const loc_invalidUnique: Record<Locale, (label: unknown) => string> = {
	ar: (label) => `يجب أن يكون ${label} فريدا.`,
	de: (label) => `${label} muss eindeutig sein.`,
	es: (label) => `${label} debe ser único.`,
	fa: (label) => `${label} باید منحصر به فرد باشد.`,
	fil: (label) => `Kailangang unique ang ${label}.`,
	fr: (label) => `${label} doit être unique.`,
	hi: (label) => `${label} अद्वितीय होना चाहिए.`,
	ht: (label) => `${label} yo dwe inik.`,
	it: (label) => `${label} deve essere univoco.`,
	ja: (label) => `${label} は一意である必要があります。`,
	ko: (label) => `${label} 고유해야 합니다.`,
	mww: (label) => `${label} yuav tsum muaj nws lub npe.`,
	ps: (label) => `نوم ${label} وی.`,
	pt: (label) => `${label} deve ser exclusivo.`,
	'pt-pt': (label) => `${label} deve ser exclusivo.`,
	ru: (label) => `${label} должно быть уникальным.`,
	sm: (label) => `E tatau ona tulaga ese le ${label}.`,
	so: (label) => `${label} waa inuu ahaadaa mid aan caadi aheyn.`,
	th: (label) => `${label} ไม่ซ้ํากัน`,
	ur: (label) => `${label} منفرد ہونا چاہئے.`,
	vi: (label) => `${label} phải là duy nhất.`,
	'zh-hans': (label) => `${label} 必须唯一。`,
	'zh-hant': (label) => `${label} 必須唯一。`,
	en: (label) => `${label} must be unique.`
};

export const loc_invalidUniqueCount: Record<Locale, (label: unknown, count: unknown) => string> = {
	ar: (label, count) => `يمكن تحديد نفس ${label} فقط ${count} مرات.`,
	de: (label, count) => `Derselbe ${label} kann nur ${count} Mal ausgewählt werden.`,
	es: (label, count) => `El mismo ${label} solo se puede seleccionar ${count} veces.`,
	fa: (label, count) => `همین ${label} را فقط ${count} بار انتخاب کرد.`,
	fil: (label, count) => `Ang parehong ${label} ay maaari ${count} beses.`,
	fr: (label, count) => `Le même ${label} ne peut être sélectionné que ${count} fois.`,
	hi: (label, count) => `एक ही ${label} केवल ${count} बार चुना जा सकता है।`,
	ht: (label, count) => `Menm ${label} an ka sèlman chwazi ${count} fwa.`,
	it: (label, count) => `Lo stesso ${label} può essere selezionato solo ${count} volte.`,
	ja: (label, count) => `同じ ${label} は ${count} 回しか選択できません。`,
	ko: (label, count) => `같은 ${label} ${count} 번만 선택할 수 있습니다.`,
	mww: (label, count) => `Tib ${label} tsuas xaiv ${count} zaug xwb.`,
	ps: (label, count) => `د نوم ${label} یوازې ${count} ځله ټاکل کیدی شی.`,
	pt: (label, count) => `O mesmo ${label} só pode ser selecionado ${count} vezes.`,
	'pt-pt': (label, count) => `O mesmo ${label} só pode ser selecionado ${count} vezes.`,
	ru: (label, count) => `Одно и то же ${label} может быть выбрано только ${count} раза.`,
	sm: (count, label) => `E na o le ${count} taimi e mafai ona filifilia ai le ${label} lava e tasi.`,
	so: (label, count) => `${label} isku midka ah waxaa la dooran karaa oo keliya ${count} jeer.`,
	th: (label, count) => `สามารถเลือก ${label} ได้เพียง ${count} ครั้งเท่านั้น`,
	ur: (label, count) => `ایک ہی ${label} صرف ${count} بار منتخب کیا جا سکتا ہے.`,
	vi: (label, count) => `Cùng một ${label} chỉ có thể được chọn ${count} lần.`,
	'zh-hans': (label, count) => `相同的 ${label} 只能选择 ${count} 次。`,
	'zh-hant': (label, count) => `相同的 ${label} 只能選擇 ${count} 次。`,
	en: (label, count) => `The same ${label} can only be selected ${count} times.`
};

export const loc_invalidLessOrEqual: Record<Locale, (label: unknown, maxLabel: unknown) => string> = {
	ar: (label, maxLabel) => `يجب أن يكون ${label} أقل من أو يساوي ${maxLabel}.`,
	de: (label, maxLabel) => `${label} muss kleiner oder gleich dem ${maxLabel} sein.`,
	es: (label, maxLabel) => `${label} debe ser menor o igual que la ${maxLabel}.`,
	fa: (label, maxLabel) => `${label} باید کمتر یا مساوی ${maxLabel} باشد.`,
	fil: (label, maxLabel) => `Ang ${label} ay dapat na mas mababa o katumbas ng ${maxLabel}.`,
	fr: (label, maxLabel) => `${label} doit être inférieure ou égale à ${maxLabel}.`,
	hi: (label, maxLabel) => `${label} ${maxLabel} कम या उसके बराबर होना आवश्यक है.`,
	ht: (label, maxLabel) => `${label} dwe mwens pase oswa egal a ${maxLabel}.`,
	it: (label, maxLabel) => `${label} deve essere minore o uguale alla ${maxLabel}.`,
	ja: (label, maxLabel) => `${label} は ${maxLabel}以下である必要があります。`,
	ko: (label, maxLabel) => `${label} 는 ${maxLabel}보다 작거나 같아야 합니다.`,
	mww: (label, maxLabel) => `${label} tsum tsawg tshaj li ${maxLabel}.`,
	ps: (label, maxLabel) => `د ${label} باید د ${maxLabel} څخه لږ یا مساوی وی.`,
	pt: (label, maxLabel) => `${label} deve ser menor ou igual à ${maxLabel}.`,
	'pt-pt': (label, maxLabel) => `${label} deve ser menor ou igual à ${maxLabel}.`,
	ru: (label, maxLabel) => `${label} должна быть меньше или равна ${maxLabel}.`,
	sm: (label, maxLabel) => `O le ${label} e tatau ona itiiti ifo pe tutusa ma le ${maxLabel}.`,
	so: (label, maxLabel) => `${label} waa in ay ahaataa wax ka yar ama loo siman yahay ${maxLabel}.`,
	th: (label, maxLabel) => `${label}ต้องน้อยกว่าหรือเท่ากับ${maxLabel}`,
	ur: (label, maxLabel) => `${label} ${maxLabel} سے کم یا برابر ہونی چاہئے۔`,
	vi: (label, maxLabel) => `${label} phải nhỏ hơn hoặc bằng ${maxLabel}.`,
	'zh-hans': (label, maxLabel) => `${label} 必须小于或等于 ${maxLabel}。`,
	'zh-hant': (label, maxLabel) => `${label} 必須小於或等於 ${maxLabel}。`,
	en: (label, maxLabel) => `${label} must be less than or equal to ${maxLabel}.`
};

export const loc_validationErrors: Record<Locale, string> = {
	ar: `أخطاء التحقق من الصحة`,
	de: `Fehler bei der Validierung`,
	es: `Errores de validación`,
	fa: `خطاهای اعتبارسنجی`,
	fil: `Mga error sa pagpapatunay`,
	fr: `Erreurs de validation`,
	hi: `पुष्टि करने से जुड़ी गड़बड़ियां`,
	ht: `Erè validasyon`,
	it: `Errori di convalida`,
	ja: `検証エラー`,
	ko: `유효성 검사 오류`,
	mww: `Validation errors`,
	ps: `باورکړنې تېروتنې`,
	pt: `Erros de validação`,
	'pt-pt': `Erros de validação`,
	ru: `Ошибки валидации`,
	sm: `Mea sese o le faamaoniga`,
	so: `Khaladaadka anshaxinta`,
	th: `ข้อผิดพลาดในการตรวจสอบความถูกต้อง`,
	ur: `توثیق کی غلطیاں`,
	vi: `Lỗi xác thực`,
	'zh-hans': `验证错误`,
	'zh-hant': `驗證錯誤`,
	en: `Validation errors`
};

export const loc_somethingWentWrong: Record<Locale, TemplateResult> = {
	ar: html`حدث خطأ ما. لقد سجلنا الخطأ. يرجى <a class="Link--primary text-bold" href="/support">الاتصال بالدعم</a> إذا استمرت المشكلة.`,
	de: html`Irgendetwas ist schief gelaufen. Wir haben den Fehler protokolliert. <a class="Link--primary text-bold" href="/support">Wenden Sie sich an den Support</a> , wenn das Problem weiterhin besteht.`,
	es: html`Algo salió mal. Hemos registrado el error. <a class="Link--primary text-bold" href="/support">Póngase en contacto con el servicio de asistencia</a> si el problema persiste.`,
	fa: html`مشکلی پیش آمد. ما خطا را ثبت کرده ایم. لطفا در صورت ادامه <a class="Link--primary text-bold" href="/support">مشکل با پشتیبانی</a> تماس بگیرید.`,
	fil: html`May nangyaring mali. Na log namin ang error. Mangyaring <a class="Link--primary text-bold" href="/support">makipag ugnay sa suporta</a> kung ang isyu ay patuloy.`,
	fr: html`Quelque chose a mal tourné. Nous avons consigné l’erreur. Veuillez <a class="Link--primary text-bold" href="/support">contacter l’assistance</a> si le problème persiste.`,
	hi: html`कुछ गलत हो गया। हमने त्रुटि लॉग की है। यदि समस्या बनी रहती है, तो कृपया <a class="Link--primary text-bold" href="/support">समर्थन से संपर्क</a> करें।`,
	ht: html`Gen yon bagay ki te pase mal. Nou te louvri sesyon an erè. Tanpri <a class="Link--primary text-bold" href="/support">kontakte sipò</a> si pwoblèm nan pèsiste.`,
	it: html`Qualcosa è andato storto. L'errore è stato registrato. <a class="Link--primary text-bold" href="/support">Contatta l'assistenza</a> se il problema persiste.`,
	ja: html`問題が発生しました。エラーをログに記録しました。問題が解決しない場合は <a class="Link--primary text-bold" href="/support">、サポートにお問い合わせください</a> 。`,
	ko: html`문제가 발생했습니다. 오류를 기록했습니다. 문제가 지속되면 <a class="Link--primary text-bold" href="/support">지원팀에 문의</a> 하세요.`,
	mww: html`Tej yam mus tsis ncaj ncees lawm. Peb twb logged qhov yuam kev. Thov <a class="Link--primary text-bold" href="/support">hu rau kev them nyiaj yug</a> yog hais tias qhov teeb meem persists.`,
	ps: html`یو څه غلط شو. موږ خطا په نښه کړه. مهربانی وکړئ <a class="Link--primary text-bold" href="/support">د ملاتړ سره اړیکه ونیسئ</a> که مسله دوام ولری.`,
	pt: html`Algo deu errado. Registramos o erro. Entre <a class="Link--primary text-bold" href="/support">em contato com o suporte</a> se o problema persistir.`,
	'pt-pt': html`Algo correu mal. Registamos o erro. <a class="Link--primary text-bold" href="/support">Entre em contato com o suporte</a> se o problema persistir.`,
	ru: html`Что-то пошло не так. Мы зарегистрировали ошибку. <a class="Link--primary text-bold" href="/support">Обратитесь в службу поддержки</a> , если проблема не устранена.`,
	sm: html`Sa i ai se mea na faaletonu. Ua uma ona matou faamaumauina le mea sese. Faamolemole <a class="Link--primary text-bold" href="/support">faafesootai le lagolago</a> pe afai e tumau pea le faafitauli.`,
	so: html`Wax baa khaldamay. Waxaanu qaadnay khaladka. Fadlan <a class="Link--primary text-bold" href="/support">la xiriir taageero</a> haddii ay arrintu ka taagantahay.`,
	th: html`มีบางอย่างผิดพลาด เราได้บันทึกข้อผิดพลาดแล้ว โปรดติดต่อ <a class="Link--primary text-bold" href="/support">ฝ่ายสนับสนุน</a> หากปัญหายังคงอยู่`,
	ur: html`کچھ غلط ہو گیا. ہم نے غلطی لاگ ان کر لی ہے۔ اگر <a class="Link--primary text-bold" href="/support">مسئلہ</a> برقرار رہتا ہے تو براہ کرم سپورٹ سے رابطہ کریں۔`,
	vi: html`Đã xảy ra sự cố. Chúng tôi đã ghi lại lỗi. Vui lòng <a class="Link--primary text-bold" href="/support">liên hệ với bộ phận hỗ trợ</a> nếu sự cố vẫn tiếp diễn.`,
	'zh-hans': html`出了点问题。我们已记录此错误。如果问题仍然存在， <a class="Link--primary text-bold" href="/support">请联系支持人员</a> 。`,
	'zh-hant': html`出了點問題。我們已記錄此錯誤。如果問題仍然存在， <a class="Link--primary text-bold" href="/support">請聯繫支持人員</a> 。`,
	en: html`Something went wrong. We've logged the error. Please <a class="Link--primary text-bold" href="/support">contact support</a> if the issue persists.`
};

export const loc_theresAProblem: Record<Locale, string> = {
	ar: `هناك مشكلة`,
	de: `Es gibt ein Problem`,
	es: `Hay un problema`,
	fa: `مشکلی وجود دارد`,
	fil: `May problema nga ba`,
	fr: `Il y a un problème`,
	hi: `एक समस्या है`,
	ht: `Genyen yon pwoblèm`,
	it: `C'è un problema`,
	ja: `問題があります`,
	ko: `문제가 있습니다`,
	mww: `Nws muaj nws ib qho teeb meem`,
	ps: `دلته ستونزه ده`,
	pt: `Há um problema`,
	'pt-pt': `Há um problema`,
	ru: `Есть проблема`,
	sm: `E i ai se faafitauli`,
	so: `Waxaa jira dhibaato`,
	th: `มีปัญหา`,
	ur: `ایک مسئلہ ہے`,
	vi: `Có một vấn đề`,
	'zh-hans': `有问题`,
	'zh-hant': `有問題`,
	en: `There's a problem`
};

export const loc_languageSelector: Record<Locale, (language: unknown) => string> = {
	ar: (language) => `محدد لغة المحتوى. مضبوط حاليا على ${language}`,
	de: (language) => `Auswahl der Inhaltssprache. Derzeit auf ${language} eingestellt`,
	es: (language) => `Selector de idioma de contenido. Actualmente configurado en ${language}`,
	fa: (language) => `انتخابگر زبان محتوا. در حال حاضر روی ${language} تنظیم شده است`,
	fil: (language) => `Tagapili ng content language. Kasalukuyang nakatakda sa ${language}`,
	fr: (language) => `Sélecteur de langue de contenu. Actuellement réglé sur ${language}`,
	hi: (language) => `सामग्री भाषा चयनकर्ता. वर्तमान में ${language} पर सेट है`,
	ht: (language) => `Seleksyon lang kontni. Kounye a mete ${language}`,
	it: (language) => `Selettore della lingua del contenuto. Attualmente impostato su ${language}`,
	ja: (language) => `コンテンツ言語セレクター。現在は${language}に設定されています`,
	ko: (language) => `콘텐츠 언어 선택기. 현재 ${language} 설정되어 있습니다.`,
	mww: (language) => `Cov lus xaiv. Tam sim no teem caij mus ${language}`,
	ps: (language) => `د منځپانګې ژبې ټاکونکی. اوس مهال ${language} شئ`,
	pt: (language) => `Seletor de idioma do conteúdo. Atualmente definido como ${language}`,
	'pt-pt': (language) => `Seletor de idioma de conteúdo. Atualmente definido para ${language}`,
	ru: (language) => `Селектор языка содержимого. В настоящее время настроено на ${language} язык`,
	sm: (language) => `Filifili le gagana o le anotusi. Seti i le taimi nei i le ${language}`,
	so: (language) => `Xulashada luqada content. Waxaa hadda lagu wadaa in ay ${language}`,
	th: (language) => `ตัวเลือกภาษาของเนื้อหา ปัจจุบันตั้งค่าเป็น ${language}`,
	ur: (language) => `مواد کی زبان کے سلیکٹر. فی الحال انگریزی میں سیٹ کیا ${language}`,
	vi: (language) => `Bộ chọn ngôn ngữ nội dung. Hiện được đặt thành ${language}`,
	'zh-hans': (language) => `内容语言选择器。当前设置为 ${language}`,
	'zh-hant': (language) => `內容語言選擇器。當前設置為 ${language}`,
	en: (language) => `Content language selector. Currently set to ${language}`
};

export const loc_yourOrganizations: Record<Locale, string> = {
	ar: `مؤسساتك`,
	de: `Ihre Organisationen`,
	es: `Sus organizaciones`,
	fa: `سازمان های شما`,
	fil: `Ang iyong mga organisasyon`,
	fr: `Vos organisations`,
	hi: `आपके संगठन`,
	ht: `Òganizasyon ou yo`,
	it: `Le tue organizzazioni`,
	ja: `お客様の組織`,
	ko: `귀사의 조직`,
	mww: `Koj lub koom haum`,
	ps: `ستاسو سازمانونه`,
	pt: `Suas organizações`,
	'pt-pt': `As suas organizações`,
	ru: `Ваши организации`,
	sm: `O au faalapotopotoga`,
	so: `Ururadaada`,
	th: `องค์กรของคุณ`,
	ur: `آپ کی تنظیمیں`,
	vi: `Tổ chức của bạn`,
	'zh-hans': `您的组织`,
	'zh-hant': `您的組織`,
	en: `Your organizations`
};

export const loc_closeMenu: Record<Locale, string> = {
	ar: `إغلاق القائمة`,
	de: `Menü schließen`,
	es: `Cerrar menú`,
	fa: `بستن منو`,
	fil: `Isara ang menu`,
	fr: `Fermer le menu`,
	hi: `मेनू बंद करें`,
	ht: `Meni fèmen`,
	it: `Chiudi menu`,
	ja: `メニューを閉じる`,
	ko: `메뉴 닫기`,
	mww: `Kaw zaub mov`,
	ps: `بندول غورنۍ`,
	pt: `Fechar menu`,
	'pt-pt': `Fechar menu`,
	ru: `Закрыть меню`,
	sm: `Tapuni le lisi autu`,
	so: `Xir menu`,
	th: `ปิดเมนู`,
	ur: `مینو بند کریں`,
	vi: `Đóng menu`,
	'zh-hans': `关闭菜单`,
	'zh-hant': `關閉功能表`,
	en: `Close menu`
};

export const loc_menuIsLoading: Record<Locale, string> = {
	ar: `يتم تحميل القائمة`,
	de: `Menü wird geladen`,
	es: `El menú se está cargando`,
	fa: `منو در حال بارگیری است`,
	fil: `Menu ay naglo load`,
	fr: `Le menu est en cours de chargement`,
	hi: `मेनू लोड हो रहा है`,
	ht: `Meni ap chaje`,
	it: `Il menu è in fase di caricamento`,
	ja: `メニューが読み込まれています`,
	ko: `메뉴가 로딩 중입니다.`,
	mww: `Zaub mov yog loading`,
	ps: `غورنۍ لېښل کیږی`,
	pt: `O menu está carregando`,
	'pt-pt': `O menu está a carregar`,
	ru: `Меню загружается`,
	sm: `Ua utaina le lisi autu`,
	so: `menu waxaa loading`,
	th: `กําลังโหลดเมนู`,
	ur: `مینو لوڈ ہو رہا ہے`,
	vi: `Menu đang tải`,
	'zh-hans': `菜单正在加载`,
	'zh-hant': `功能表正在載入`,
	en: `Menu is loading`
};

export const loc_loadingEllipses: Record<Locale, string> = {
	ar: `تحميل...`,
	de: `Laden...`,
	es: `Carga...`,
	fa: `بارگذاری...`,
	fil: `Naglo-load...`,
	fr: `Chargement...`,
	hi: `लोड।।।`,
	ht: `Chaje ...`,
	it: `Caricamento...`,
	ja: `積載。。。`,
	ko: `로드...`,
	mww: `Loading...`,
	ps: `لېښل کیږی...`,
	pt: `Carregamento...`,
	'pt-pt': `A carregar...`,
	ru: `Погрузка...`,
	sm: `Faaaogaina...`,
	so: `Loading...`,
	th: `การโหลด   `,
	ur: `لوڈنگ...`,
	vi: `Tải...`,
	'zh-hans': `装载。。。`,
	'zh-hant': `裝載。。。`,
	en: `Loading...`
};

export const loc_toggleSearch: Record<Locale, string> = {
	ar: `تبديل البحث`,
	de: `Suche umschalten`,
	es: `Alternar búsqueda`,
	fa: `تغییر وضعیت جستجو`,
	fil: `Toggle ang paghahanap`,
	fr: `Basculer la recherche`,
	hi: `टॉगल खोज`,
	ht: `Rechèch togle`,
	it: `Attiva/disattiva ricerca`,
	ja: `検索の切り替え`,
	ko: `검색 전환`,
	mww: `Toggle nrhiav`,
	ps: `لټون څرنګتیاونج`,
	pt: `Alternar pesquisa`,
	'pt-pt': `Alternar pesquisa`,
	ru: `Переключить поиск`,
	sm: `Fesuiai le sailiga`,
	so: `Haku`,
	th: `สลับการค้นหา`,
	ur: `Toggle search`,
	vi: `Chuyển đổi tìm kiếm`,
	'zh-hans': `切换搜索`,
	'zh-hant': `切換搜索`,
	en: `Toggle search`
};

export const loc_searchEllipses: Record<Locale, string> = {
	ar: `بحث...`,
	de: `Suchen...`,
	es: `Buscar...`,
	fa: `جستجو...`,
	fil: `Maghanap...`,
	fr: `Rechercher...`,
	hi: `ढूँढ।।।`,
	ht: `Fouye...`,
	it: `Ricerca...`,
	ja: `捜索。。。`,
	ko: `검색...`,
	mww: `Nrhiav...`,
	ps: `لټون:`,
	pt: `Procurar...`,
	'pt-pt': `Pesquisar...`,
	ru: `Искать...`,
	sm: `Saili...`,
	so: `Haku...`,
	th: `ค้น   `,
	ur: `تلاش...`,
	vi: `Tìm kiếm...`,
	'zh-hans': `搜索。。。`,
	'zh-hant': `搜索。。。`,
	en: `Search...`
};

export const loc_search: Record<Locale, string> = {
	ar: `بحث`,
	de: `Suchen`,
	es: `Buscar`,
	fa: `جستجو`,
	fil: `Maghanap`,
	fr: `Rechercher`,
	hi: `ढूँढ`,
	ht: `Fouye`,
	it: `Ricerca`,
	ja: `捜索`,
	ko: `검색`,
	mww: `nrhiav`,
	ps: `لټون`,
	pt: `Procurar`,
	'pt-pt': `Pesquisar`,
	ru: `Искать`,
	sm: `Saili`,
	so: `Haku`,
	th: `ค้น`,
	ur: `تلاش`,
	vi: `Tìm kiếm`,
	'zh-hans': `搜索`,
	'zh-hant': `搜索`,
	en: `Search`
};

export const loc_organization: Record<Locale, string> = {
	ar: `منظمة`,
	de: `Organisation`,
	es: `Organización`,
	fa: `سازمان`,
	fil: `Organisasyon`,
	fr: `Organisation`,
	hi: `संगठन`,
	ht: `Òganizasyon`,
	it: `Organizzazione`,
	ja: `組織`,
	ko: `조직`,
	mww: `Koom haum`,
	ps: `سازمان`,
	pt: `Organização`,
	'pt-pt': `Organização`,
	ru: `Организация`,
	sm: `Faalapotopotoga`,
	so: `Hay'ad`,
	th: `องค์กร`,
	ur: `تنظیم`,
	vi: `Tổ chức`,
	'zh-hans': `组织`,
	'zh-hant': `組織`,
	en: `Organization`
};

export const loc_home: Record<Locale, string> = {
	ar: `وطن`,
	de: `Heim`,
	es: `Hogar`,
	fa: `خانه`,
	fil: `Home`,
	fr: `Domicile`,
	hi: `घर`,
	ht: `Lakay`,
	it: `Casa`,
	ja: `家`,
	ko: `집`,
	mww: `Tsev`,
	ps: `کور`,
	pt: `Casa`,
	'pt-pt': `Casa`,
	ru: `Дом`,
	sm: `Aiga`,
	so: `Haku`,
	th: `บ้าน`,
	ur: `گھر`,
	vi: `Nhà`,
	'zh-hans': `家`,
	'zh-hant': `家`,
	en: `Home`
};

export const loc_reports: Record<Locale, string> = {
	ar: `التقارير`,
	de: `Berichte`,
	es: `Informes`,
	fa: `گزارش`,
	fil: `Mga Ulat`,
	fr: `Rapports`,
	hi: `रिपोर्ट`,
	ht: `Rapò`,
	it: `Rapporti`,
	ja: `レポート`,
	ko: `보고서`,
	mww: `ntaub ntawv qhia txog`,
	ps: `راپورونه`,
	pt: `Relatórios`,
	'pt-pt': `Relatórios`,
	ru: `Отчеты`,
	sm: `Lipoti`,
	so: `Wararka`,
	th: `รายงาน`,
	ur: `رپورٹیں`,
	vi: `Báo cáo`,
	'zh-hans': `报告`,
	'zh-hant': `報告`,
	en: `Reports`
};

export const loc_settings: Record<Locale, string> = {
	ar: `اعدادات`,
	de: `Einstellungen`,
	es: `Configuración`,
	fa: `تنظیمات`,
	fil: `Mga Setting`,
	fr: `Paramètres`,
	hi: `सेटिंग्स`,
	ht: `Anviwònman`,
	it: `Impostazioni`,
	ja: `設定`,
	ko: `설정`,
	mww: `Teev tseg`,
	ps: `امستنې`,
	pt: `Configurações`,
	'pt-pt': `Configurações`,
	ru: `Параметры`,
	sm: `Faatulagaga`,
	so: `Settings`,
	th: `การตั้งค่า`,
	ur: `سیٹنگیں`,
	vi: `Cài đặt`,
	'zh-hans': `设置`,
	'zh-hant': `設置`,
	en: `Settings`
};

export const loc_support: Record<Locale, string> = {
	ar: `دعم`,
	de: `Unterstützen`,
	es: `Apoyo`,
	fa: `پشتیبانی`,
	fil: `Suporta`,
	fr: `Soutien`,
	hi: `जीविका`,
	ht: `Sipò`,
	it: `Appoggiare`,
	ja: `支える`,
	ko: `지원`,
	mww: `kev them nyiaj yug me`,
	ps: `ملاتړ`,
	pt: `Apoio`,
	'pt-pt': `Suporte`,
	ru: `Поддержка`,
	sm: `Lagolago`,
	so: `Taageero`,
	th: `สนับสนุน`,
	ur: `معاونت`,
	vi: `Hỗ trợ`,
	'zh-hans': `支持`,
	'zh-hant': `支援`,
	en: `Support`
};

export const loc_viewProfileAndMore: Record<Locale, string> = {
	ar: `عرض الملف الشخصي والمزيد`,
	de: `Profil anzeigen und mehr`,
	es: `Ver perfil y más`,
	fa: `مشاهده نمایه و موارد دیگر`,
	fil: `Tingnan ang profile at marami pang iba`,
	fr: `Voir le profil et plus encore`,
	hi: `प्रोफ़ाइल और अधिक देखें`,
	ht: `Gade pwofil ak plis ankò`,
	it: `Visualizza il profilo e altro ancora`,
	ja: `プロフィールなどを表示`,
	ko: `프로필 등 보기`,
	mww: `Saib profile thiab ntau dua`,
	ps: `پېژنیال او نور وګورئ`,
	pt: `Ver perfil e muito mais`,
	'pt-pt': `Visualizar perfil e muito mais`,
	ru: `Посмотреть профиль и многое другое`,
	sm: `Maimoa i le talaaga otooto ma isi mea`,
	so: `View profile iyo in ka badan`,
	th: `ดูโพรไฟล์และอื่นๆ`,
	ur: `پروفائل دیکھیں اور مزید`,
	vi: `Xem hồ sơ và hơn thế nữa`,
	'zh-hans': `查看个人资料等`,
	'zh-hant': `查看個人資料等`,
	en: `View profile and more`
};

export const loc_toggleSidebar: Record<Locale, string> = {
	ar: `تبديل الشريط الجانبي`,
	de: `Seitenleiste umschalten`,
	es: `Alternar barra lateral`,
	fa: `تغییر کلید نوار کناری`,
	fil: `Toggle sidebar`,
	fr: `Basculer la barre latérale`,
	hi: `टॉगल साइडबार`,
	ht: `Toggle sidebar`,
	it: `Attiva/disattiva la barra laterale`,
	ja: `サイドバーの切り替え`,
	ko: `사이드바 전환`,
	mww: `Toggle sidebar`,
	ps: `څنګپټه څرنګتیاونج`,
	pt: `Alternar barra lateral`,
	'pt-pt': `Alternar barra lateral`,
	ru: `Переключить боковую панель`,
	sm: `Toggle pa itu`,
	so: `Toggle sidebar`,
	th: `สลับแถบด้านข้าง`,
	ur: `Toggle sidebar`,
	vi: `Chuyển đổi thanh bên`,
	'zh-hans': `切换侧边栏`,
	'zh-hant': `切換側邊欄`,
	en: `Toggle sidebar`
};

export const loc_organizations: Record<Locale, string> = {
	ar: `المنظمات`,
	de: `Organisationen`,
	es: `Organizaciones`,
	fa: `سازمان`,
	fil: `Mga Organisasyon`,
	fr: `Organisations`,
	hi: `संगठनों`,
	ht: `Òganizasyon`,
	it: `Organizzazioni`,
	ja: `組織`,
	ko: `조직`,
	mww: `Koom haum`,
	ps: `سازمانونه`,
	pt: `Organizações`,
	'pt-pt': `Organizações`,
	ru: `Организаций`,
	sm: `Faalapotopotoga`,
	so: `Ururada`,
	th: `องค์กร`,
	ur: `تنظیموں`,
	vi: `Tổ chức`,
	'zh-hans': `组织`,
	'zh-hant': `組織`,
	en: `Organizations`
};

export const loc_contact: Record<Locale, string> = {
	ar: `الاتصال`,
	de: `Kontakt`,
	es: `Contacto`,
	fa: `تماس`,
	fil: `Makipag ugnay sa`,
	fr: `Contact`,
	hi: `संपर्क`,
	ht: `Kontak`,
	it: `Contatto`,
	ja: `接触`,
	ko: `접촉`,
	mww: `Hu`,
	ps: `اړیکلوری`,
	pt: `Contato`,
	'pt-pt': `Contato`,
	ru: `Контакт`,
	sm: `Faafesootai`,
	so: `Xiriirka`,
	th: `ติดต่อ`,
	ur: `رابطہ`,
	vi: `Sự tiếp xúc`,
	'zh-hans': `联系`,
	'zh-hant': `聯繫`,
	en: `Contact`
};

export const loc_news: Record<Locale, string> = {
	ar: `خبر`,
	de: `Nachrichten`,
	es: `Noticia`,
	fa: `اخبار`,
	fil: `Balita`,
	fr: `Nouvelles`,
	hi: `समाचार`,
	ht: `Nouvèl`,
	it: `Notizie`,
	ja: `ニュース`,
	ko: `소식`,
	mww: `Moo`,
	ps: `خبرونه`,
	pt: `Notícia`,
	'pt-pt': `Notícias`,
	ru: `Новости`,
	sm: `Talafou`,
	so: `Wararka`,
	th: `ข่าว`,
	ur: `خبر`,
	vi: `Tin tức`,
	'zh-hans': `新闻`,
	'zh-hant': `新聞`,
	en: `News`
};

export const loc_about: Record<Locale, string> = {
	ar: `عن`,
	de: `Über`,
	es: `Acerca de`,
	fa: `مورد`,
	fil: `Tungkol sa`,
	fr: `Environ`,
	hi: `करीबन`,
	ht: `Sou`,
	it: `Circa`,
	ja: `に関しては`,
	ko: `약`,
	mww: `Txog`,
	ps: `په اړه`,
	pt: `Sobre`,
	'pt-pt': `Sobre nós`,
	ru: `Около`,
	sm: `E Uiga i le`,
	so: `Ku saabsan`,
	th: `ประมาณ`,
	ur: `تقریباً`,
	vi: `Về`,
	'zh-hans': `大约`,
	'zh-hant': `大約`,
	en: `About`
};

export const loc_signIn: Record<Locale, string> = {
	ar: `تسجيل الدخول`,
	de: `Anmelden`,
	es: `Inicia sesión`,
	fa: `ورود به برنامه`,
	fil: `Mag-sign in`,
	fr: `Connexion`,
	hi: `साइन इन करें`,
	ht: `Antre`,
	it: `Accedi`,
	ja: `サインイン`,
	ko: `서명하세요`,
	mww: `Sau npe nkag mus`,
	ps: `دننه شه`,
	pt: `Entrar`,
	'pt-pt': `Iniciar sessão`,
	ru: `Войти`,
	sm: `Saini`,
	so: `Gal`,
	th: `เข้าสู่ระบบ`,
	ur: `سائن ان کریں`,
	vi: `Đăng nhập`,
	'zh-hans': `登录`,
	'zh-hant': `登錄`,
	en: `Sign in`
};

export const loc_signUp: Record<Locale, string> = {
	ar: `التسجيل`,
	de: `Anmelden`,
	es: `Únete`,
	fa: `ثبت نام`,
	fil: `Mag sign up`,
	fr: `S’enregistrer`,
	hi: `नाम लिखो`,
	ht: `Enskri`,
	it: `Registrati`,
	ja: `サインアップ`,
	ko: `등록하세요`,
	mww: `Cuv npe`,
	ps: `ګډون وکړه`,
	pt: `Inscrição`,
	'pt-pt': `Registar-me`,
	ru: `Регистрация`,
	sm: `Resitala`,
	so: `Diiwaangeli`,
	th: `ไซน์อัพ`,
	ur: `سائن اپ کریں`,
	vi: `Đăng ký`,
	'zh-hans': `登记`,
	'zh-hant': `登記`,
	en: `Sign up`
};

export const loc_signedInAs: Record<Locale, (login: unknown) => string> = {
	ar: (login) => `تم تسجيل الدخول باسم ${login}`,
	de: (login) => `Angemeldet als ${login}`,
	es: (login) => `Iniciado sesión como ${login}`,
	fa: (login) => `ثبت نام به عنوان ${login}`,
	fil: (login) => `Naka-sign in bilang ${login}`,
	fr: (login) => `Connecté en tant que ${login}`,
	hi: (login) => `${login} के रूप में साइन इन किया`,
	ht: (login) => `Siyen ${login}`,
	it: (login) => `Ha effettuato l'accesso come ${login}`,
	ja: (login) => `${login} としてサインイン`,
	ko: (login) => `${login}으로 로그인함`,
	mww: (login) => `Kos npe raws li ${login}`,
	ps: (login) => `د جان ${login} وکړئ`,
	pt: (login) => `Conectado como ${login}`,
	'pt-pt': (login) => `Entrou como ${login}`,
	ru: (login) => `Вошел в систему как ${login}`,
	sm: (login) => `Saini e alu i totonu o ${login}`,
	so: (login) => `Saxiixay sida ${login}`,
	th: (login) => `ลงชื่อเข้าใช้เป็น ${login}`,
	ur: (login) => `${login} کے طور پر سائن ان کیا`,
	vi: (login) => `Đăng nhập với tư cách ${login}`,
	'zh-hans': (login) => `以 ${login} 身份登录`,
	'zh-hant': (login) => `以 ${login} 身份登錄`,
	en: (login) => `Signed in as ${login}`
};

export const loc_editProfileAndAccount: Record<Locale, string> = {
	ar: `تعديل الملف الشخصي والحساب`,
	de: `Profil & Konto bearbeiten`,
	es: `Editar perfil y cuenta`,
	fa: `ویرایش پروفایل و حساب کاربری`,
	fil: `I-edit ang profile at account`,
	fr: `Modifier le profil et le compte`,
	hi: `प्रोफ़ाइल और खाता संपादित करें`,
	ht: `Edit pwofil & kont`,
	it: `Modifica profilo e account`,
	ja: `プロフィールとアカウントの編集`,
	ko: `프로필 및 계정 편집`,
	mww: `Edit profile & account`,
	ps: `پېژنیال او ګڼون سمول`,
	pt: `Editar perfil e conta`,
	'pt-pt': `Editar perfil & conta`,
	ru: `Редактирование профиля и учетной записи`,
	sm: `Faasa'o le talaaga otooto & faitotoa`,
	so: `Bedelaada borofayl & account`,
	th: `แก้ไขโปรไฟล์และบัญชี`,
	ur: `پروفائل اور اکاؤنٹ میں ترمیم کریں`,
	vi: `Chỉnh sửa hồ sơ và tài khoản`,
	'zh-hans': `编辑个人资料和账户`,
	'zh-hant': `編輯個人資料和帳戶`,
	en: `Edit profile & account`
};

export const loc_signOut: Record<Locale, string> = {
	ar: `تسجيل الخروج`,
	de: `Abmelden`,
	es: `Cerrar sesión`,
	fa: `خروج از سیستم`,
	fil: `Mag-sign out`,
	fr: `Se déconnecter`,
	hi: `साइन आउट करें`,
	ht: `Siyen`,
	it: `Disconnettersi`,
	ja: `サインアウト`,
	ko: `로그아웃`,
	mww: `kos npe rau`,
	ps: `پښتو`,
	pt: `Sair`,
	'pt-pt': `Sair`,
	ru: `Выйти из системы`,
	sm: `Saini e alu ese`,
	so: `Saxiix`,
	th: `ลงชื่อออก`,
	ur: `سائن آؤٹ کریں`,
	vi: `Đăng xuất`,
	'zh-hans': `登出`,
	'zh-hant': `登出`,
	en: `Sign out`
};

export const loc_translationInProgress: Record<Locale, string> = {
	ar: `نحن نعمل على توفير الترجمة للغات المدرجة أدناه. ستظهر مناطق الموقع التي لم تتم ترجمتها بعد باللغة الإنجليزية.`,
	de: `Wir arbeiten daran, Übersetzungen für die unten aufgeführten Sprachen bereitzustellen. Bereiche der Website, die noch nicht übersetzt wurden, werden auf Englisch angezeigt.`,
	es: `Estamos trabajando para proporcionar traducción a los idiomas que se enumeran a continuación. Las áreas del sitio que aún no han sido traducidas aparecerán en inglés.`,
	fa: `ما در حال کار بر روی ارائه ترجمه برای زبان های ذکر شده در زیر هستیم. مناطقی از سایت که هنوز ترجمه نشده اند به زبان انگلیسی ظاهر می شوند.`,
	fil: `Nagtatrabaho kami sa pagbibigay ng pagsasalin para sa mga wikang nakalista sa ibaba. Mga lugar ng site na kung saan ay hindi pa isinalin ay lilitaw sa Ingles.`,
	fr: `Nous travaillons à la traduction pour les langues énumérées ci-dessous. Les parties du site qui n’ont pas encore été traduites apparaîtront en anglais.`,
	hi: `हम नीचे सूचीबद्ध भाषाओं के लिए अनुवाद प्रदान करने पर काम कर रहे हैं। साइट के जिन क्षेत्रों का अभी तक अनुवाद नहीं किया गया है, वे अंग्रेजी में दिखाई देंगे।`,
	ht: `N ap travay sou bay tradiksyon pou lang ki nan lis anba a. Zòn nan sit la ki poko tradui ap parèt nan lang angle.`,
	it: `Stiamo lavorando per fornire la traduzione per le lingue elencate di seguito. Le aree del sito che non sono ancora state tradotte appariranno in inglese.`,
	ja: `現在、以下の言語の翻訳に取り組んでいます。サイトのまだ翻訳されていない領域は英語で表示されます。`,
	ko: `아래 나열된 언어에 대한 번역을 제공하기 위해 노력하고 있습니다. 아직 번역되지 않은 사이트 영역은 영어로 표시됩니다.`,
	mww: `Peb nyob nraum ua hauj lwm rau kev muab txhais lus rau cov lus teev nyob rau hauv qab no. Tej thaj chaw uas tsis tau tau txhais lus Askiv yuav tshwm sim nyob rau lus Askiv.`,
	ps: `موږ د لاندې لیست شوی ژبو لپاره د ژباړې چمتو کولو لپاره کار کوو. د سایټ ساحې چې لاهم ژباړل شوی ندی به په انګلیسی کې څرګند شی.`,
	pt: `Estamos trabalhando para fornecer tradução para os idiomas listados abaixo. As áreas do site que ainda não foram traduzidas aparecerão em inglês.`,
	'pt-pt': `Estamos trabalhando para fornecer tradução para os idiomas listados abaixo. As áreas do sítio que ainda não foram traduzidas serão apresentadas em inglês.`,
	ru: `Мы работаем над переводом на перечисленные ниже языки. Разделы сайта, которые еще не были переведены, будут отображаться на английском языке.`,
	sm: `O loo matou galulue i le sauniaina o faaliliuga mo gagana o loo lisiina i lalo. O vaega o le saite e lei faaliliuina o le a faaali mai i le gagana Peretania.`,
	so: `Waxaan ka shaqaynaynaa bixinta turjumaadda luuqadaha hoos ku qoran. Meelaha ka mid ah goobta oo aan weli la turjumay ku muuqan doona ingriiska.`,
	th: `เรากําลังดําเนินการแปลภาษาตามรายการด้านล่าง พื้นที่ของเว็บไซต์ที่ยังไม่ได้แปลจะปรากฏเป็นภาษาอังกฤษ`,
	ur: `ہم ذیل میں درج زبانوں کے لئے ترجمہ فراہم کرنے پر کام کر رہے ہیں. سائٹ کے وہ علاقے جو ابھی تک ترجمہ نہیں کیے گئے ہیں انگریزی میں ظاہر ہوں گے۔`,
	vi: `Chúng tôi đang nỗ lực cung cấp bản dịch cho các ngôn ngữ được liệt kê bên dưới. Các khu vực của trang web chưa được dịch sẽ xuất hiện bằng tiếng Anh.`,
	'zh-hans': `我们正在努力为下列语言提供翻译。网站中尚未翻译的区域将以英文显示。`,
	'zh-hant': `我們正在努力為下列語言提供翻譯。網站中尚未翻譯的區域將以英文顯示。`,
	en: `We're working on providing translation for the languages listed below. Areas of the site which have not yet been translated will appear in English.`
};

export const loc_close: Record<Locale, string> = {
	ar: `غلق`,
	de: `Schließen`,
	es: `Cerrar`,
	fa: `نزدیک`,
	fil: `Isara ang`,
	fr: `Fermer`,
	hi: `बंद करना`,
	ht: `Fèmen`,
	it: `Chiudere`,
	ja: `閉める`,
	ko: `닫다`,
	mww: `Kaw`,
	ps: `بندول`,
	pt: `Fechar`,
	'pt-pt': `Fechar`,
	ru: `Закрывать`,
	sm: `Tapuni`,
	so: `Kuuma`,
	th: `ปิด`,
	ur: `بند کرنا`,
	vi: `Đóng`,
	'zh-hans': `关闭`,
	'zh-hant': `關閉`,
	en: `Close`
};

export const loc_publicUrlNote: Record<Locale, string> = {
	ar: `سيتوفر عنوان url العام ورمز الاستجابة السريعة وورقة العمل القابلة للطباعة بعد الحفظ.`,
	de: `Die öffentliche URL, der QR-Code und das druckbare Arbeitsblatt sind nach dem Speichern verfügbar.`,
	es: `La URL pública, el código QR y la hoja de trabajo imprimible estarán disponibles después de guardar.`,
	fa: `آدرس اینترنتی عمومی، کد QR و کاربرگ قابل چاپ پس از ذخیره در دسترس خواهند بود.`,
	fil: `Ang pampublikong url, QR code, at mai print na worksheet ay magagamit pagkatapos mag save.`,
	fr: `L’URL publique, le code QR et la feuille de travail imprimable seront disponibles après l’enregistrement.`,
	hi: `सार्वजनिक url, QR कोड और प्रिंट करने योग्य वर्कशीट सहेजने के बाद उपलब्ध होगी।`,
	ht: `Kòd piblik, kòd QR, ak Fèy kalkil enprime ap disponib apre ekonomize.`,
	it: `L'URL pubblico, il codice QR e il foglio di lavoro stampabile saranno disponibili dopo il salvataggio.`,
	ja: `公開URL、QRコード、印刷可能なワークシートは、保存後に利用可能になります。`,
	ko: `공개 URL, QR 코드 및 인쇄 가능한 워크시트는 저장 후 사용할 수 있습니다.`,
	mww: `Pej xeem url, QR code, thiab printable worksheet yuav muaj tom qab txuag.`,
	ps: `عامه URL ، د QR کوډ ، او د چاپ وړ کاری پا willه به د خوندی کولو وروسته شتون ولری.`,
	pt: `URL público, código QR e planilha para impressão estarão disponíveis após salvar.`,
	'pt-pt': `URL pública, código QR e planilha imprimível estarão disponíveis após salvar.`,
	ru: `Общедоступный URL-адрес, QR-код и распечатываемый лист будут доступны после сохранения.`,
	sm: `O le a maua le url lautele, QR code, ma le pepa o galuega e mafai ona lolomiina pe a uma ona sefe.`,
	so: `Public url, QR code, iyo wargeyska daabacan karo ayaa la heli doonaa ka dib marka la kaydiyo.`,
	th: `URL สาธารณะ รหัส QR และแผ่นงานที่พิมพ์ได้จะพร้อมใช้งานหลังจากบันทึก`,
	ur: `پبلک یو آر ایل ، کیو آر کوڈ ، اور پرنٹ ایبل ورک شیٹ محفوظ کرنے کے بعد دستیاب ہوگی۔`,
	vi: `URL công khai, mã QR và trang tính có thể in sẽ khả dụng sau khi lưu.`,
	'zh-hans': `保存后，公共 URL、QR 码和可打印工作表将可用。`,
	'zh-hant': `保存后，公共 URL、QR 碼和可列印工作表將可用。`,
	en: `Public url, QR code, and printable worksheet will be available after saving.`
};

export const loc_draft: Record<Locale, string> = {
	ar: `مسودة`,
	de: `Entwurf`,
	es: `Corriente de aire`,
	fa: `پیش نویس`,
	fil: `Draft`,
	fr: `Brouillon`,
	hi: `मसौदा`,
	ht: `Pwojè`,
	it: `Bozza`,
	ja: `ドラフト`,
	ko: `초안`,
	mww: `cua ntsawj ntshab`,
	ps: `ګارلیک`,
	pt: `Rascunho`,
	'pt-pt': `Projeto`,
	ru: `Черновик`,
	sm: `Faataitaiga`,
	so: `Dastuurka`,
	th: `ร่าง`,
	ur: `مسودہ`,
	vi: `Dự thảo`,
	'zh-hans': `草案`,
	'zh-hant': `草案`,
	en: `Draft`
};

export const loc_ready: Record<Locale, string> = {
	ar: `جاهز`,
	de: `Fertig`,
	es: `Listo`,
	fa: `آماده`,
	fil: `Handa na`,
	fr: `Prêt`,
	hi: `तैयार`,
	ht: `Pare`,
	it: `Pronto`,
	ja: `用意`,
	ko: `준비된`,
	mww: `npaj txhij`,
	ps: `چمتو دی`,
	pt: `Pronto`,
	'pt-pt': `Pronto`,
	ru: `Готовый`,
	sm: `Saunia`,
	so: `Ready`,
	th: `พร้อม`,
	ur: `تیار`,
	vi: `Sẵn sàng`,
	'zh-hans': `准备`,
	'zh-hant': `準備`,
	en: `Ready`
};

export const loc_inProgress: Record<Locale, string> = {
	ar: `قيد التنفيذ`,
	de: `Im Gange`,
	es: `En curso`,
	fa: `در حال انجام است`,
	fil: `Patuloy na ginagawa`,
	fr: `En cours`,
	hi: `प्रगति पर है`,
	ht: `Nan pwogrè`,
	it: `In corso`,
	ja: `進行中で`,
	ko: `진행 중`,
	mww: `Kev kawm tau zoo`,
	ps: `په پرمختګ کې`,
	pt: `Em andamento`,
	'pt-pt': `Em curso`,
	ru: `Развиваться`,
	sm: `O loo faagasolo`,
	so: `In la socdo`,
	th: `อยู่ระหว่างดําเนินการ`,
	ur: `جاری ہے`,
	vi: `Đang tiến hành`,
	'zh-hans': `进行中`,
	'zh-hant': `進行中`,
	en: `In progress`
};

export const loc_completed: Record<Locale, string> = {
	ar: `اكمال`,
	de: `Abgeschlossen`,
	es: `Completado`,
	fa: `تکمیل`,
	fil: `Nakumpleto na`,
	fr: `Terminé`,
	hi: `पूरा किया`,
	ht: `Ranpli`,
	it: `Finito`,
	ja: `完了`,
	ko: `완료`,
	mww: `tiav`,
	ps: `بشپړ شویSTART (DUE`,
	pt: `Concluído`,
	'pt-pt': `Concluída |`,
	ru: `Завершённый`,
	sm: `Faamaeaina`,
	so: `Dhameystiran`,
	th: `เสร็จ สมบูรณ์`,
	ur: `مکمل`,
	vi: `Hoàn thành`,
	'zh-hans': `完成`,
	'zh-hant': `完成`,
	en: `Completed`
};

export const loc_cancelled: Record<Locale, string> = {
	ar: `الغاء`,
	de: `Abgebrochen`,
	es: `Anulado`,
	fa: `لغو`,
	fil: `Kinansela ang`,
	fr: `Annulé`,
	hi: `रद्द`,
	ht: `anile`,
	it: `Annullato`,
	ja: `キャンセル`,
	ko: `취소`,
	mww: `lawm os`,
	ps: `بند شوی`,
	pt: `Cancelado`,
	'pt-pt': `Cancelado`,
	ru: `Отменен`,
	sm: `Faaleaogaina`,
	so: `La joojiyay`,
	th: `ยก เลิก`,
	ur: `منسوخ`,
	vi: `Bị hủy bỏ`,
	'zh-hans': `取消`,
	'zh-hant': `取消`,
	en: `Cancelled`
};

export const loc_onHold: Record<Locale, string> = {
	ar: `قيد الانتظار`,
	de: `In der Warteschleife`,
	es: `En espera`,
	fa: `در حالت انتظار`,
	fil: `On hold na`,
	fr: `En attente`,
	hi: `होल्ड पर`,
	ht: `sou kenbe`,
	it: `In sospeso`,
	ja: `保留中`,
	ko: `보류 중`,
	mww: `tuav`,
	ps: `په تمه`,
	pt: `Em espera`,
	'pt-pt': `Em espera`,
	ru: `На удержании`,
	sm: `Faatalitali`,
	so: `Oo la qabanayaa`,
	th: `พักสาย`,
	ur: `روک دیا گیا`,
	vi: `Đang chờ`,
	'zh-hans': `暂停`,
	'zh-hant': `暫停`,
	en: `On hold`
};

export const loc_setStatus: Record<Locale, (status: unknown) => string> = {
	ar: (status) => `${status}`,
	de: (status) => `Set ${status}`,
	es: (status) => `${status}`,
	fa: (status) => `مجموعه ${status} شد`,
	fil: (status) => `Itakda ${status}`,
	fr: (status) => `${status}`,
	hi: (status) => `सेट ${status}`,
	ht: (status) => `Mete ${status}`,
	it: (status) => `${status}`,
	ja: (status) => `セット ${status}`,
	ko: (status) => `세트 ${status}`,
	mww: (status) => `Teev ${status}`,
	ps: (status) => `${status} امستل`,
	pt: (status) => `Conjunto ${status}`,
	'pt-pt': (status) => `${status}`,
	ru: (status) => `Набор ${status}`,
	sm: (status) => `${status}`,
	so: (status) => `Set ${status}`,
	th: (status) => `ตั้งค่า ${status}`,
	ur: (status) => `${status} ہونے والا سیٹ`,
	vi: (status) => `Bộ ${status}`,
	'zh-hans': (status) => `Set ${status}`,
	'zh-hant': (status) => `Set ${status}`,
	en: (status) => `Set ${status}`
};

export const loc_unassigned: Record<Locale, string> = {
	ar: `غير معين`,
	de: `Unbestimmt`,
	es: `Sin asignar`,
	fa: `تخصیص داده نشده`,
	fil: `Hindi nakatalaga`,
	fr: `Non attribué`,
	hi: `अनअसाइन्ड`,
	ht: `Moun ki pa asiyen`,
	it: `Nessuno`,
	ja: `未割り当て`,
	ko: `할당`,
	mww: `Unassigned`,
	ps: `ناسپارل شوی`,
	pt: `Não atribuído`,
	'pt-pt': `Não atribuído`,
	ru: `Неназначенные`,
	sm: `E Le'i Tofiaina`,
	so: `Waxna ma tarin`,
	th: `ไม่ได้รับมอบหมาย`,
	ur: `Unassigned`,
	vi: `Chưa được chỉ định`,
	'zh-hans': `未分配`,
	'zh-hant': `未分配`,
	en: `Unassigned`
};

export const loc_draftStatusDesc: Record<Locale, string> = {
	ar: `النشاط ليس جاهزا بعد للعمل عليه.`,
	de: `Die Aktivität ist noch nicht bereit, in die Tat umgesetzt zu werden.`,
	es: `La actividad aún no está lista para ser llevada a cabo.`,
	fa: `این فعالیت هنوز آماده عمل نیست.`,
	fil: `Hindi pa handa ang aktibidad na aaksyunan.`,
	fr: `L’activité n’est pas encore prête à être mise en œuvre.`,
	hi: `गतिविधि अभी तक कार्रवाई करने के लिए तैयार नहीं है।`,
	ht: `Aktivite a poko pare pou yo aji sou li.`,
	it: `L'attività non è ancora pronta per essere messa in pratica.`,
	ja: `このアクティビティは、まだ実行する準備ができていません。`,
	ko: `활동은 아직 조치를 취할 준비가 되지 않았습니다.`,
	mww: `Txoj kev ua no tsis tau npaj txhij raws li.`,
	ps: `دغه فعالیت لا تر اوسه د عمل کولو لپاره چمتو نه دی.`,
	pt: `A atividade ainda não está pronta para ser posta em prática.`,
	'pt-pt': `A atividade ainda não está pronta para ser executada.`,
	ru: `Эта деятельность еще не готова к тому, чтобы ее можно было реализовать.`,
	sm: `E lei saunia le gaoioiga e faatino.`,
	so: `Howshaan ayaan diyaar u aheyn in wax laga qabto.`,
	th: `กิจกรรมยังไม่พร้อมที่จะดําเนินการ`,
	ur: `یہ سرگرمی ابھی تک عمل کرنے کے لئے تیار نہیں ہے۔`,
	vi: `Hoạt động vẫn chưa sẵn sàng để được hành động.`,
	'zh-hans': `该活动尚未准备好执行操作。`,
	'zh-hant': `該活動尚未準備好執行操作。`,
	en: `The activity is not yet ready to be acted upon.`
};

export const loc_readyStatusDesc: Record<Locale, string> = {
	ar: `النشاط جاهز للتنفيذ ، ولكن لم يتم اتخاذ أي إجراء بعد. `,
	de: `Die Aktivität kann ausgeführt werden, aber es wurde noch keine Aktion ausgeführt. `,
	es: `La actividad está lista para realizarse, pero aún no se ha realizado ninguna acción. `,
	fa: `فعالیت آماده انجام است، اما هنوز هیچ اقدامی انجام نشده است. `,
	fil: `Handa na ang aktibidad na isasagawa, ngunit wala pang aksyon. `,
	fr: `L’activité est prête à être réalisée, mais aucune mesure n’a encore été prise. `,
	hi: `गतिविधि निष्पादित किए जाने के लिए तैयार है, लेकिन अभी तक कोई कार्रवाई नहीं की गई है। `,
	ht: `Aktivite a pare yo dwe fèt, men pa gen okenn aksyon ki poko pran. `,
	it: `L'attività è pronta per essere eseguita, ma non è stata ancora intrapresa alcuna azione. `,
	ja: `アクティビティを実行する準備ができていますが、まだアクションは実行されていません。 `,
	ko: `활동을 수행할 준비가 되었지만 아직 아무 작업도 수행되지 않았습니다. `,
	mww: `Txoj kev ua yog npaj tau, tab sis tsis muaj tes hauj lwm twb tau noj. `,
	ps: `دغه عملیات د ترسره کېدو لپاره چمتو دی، خو تر اوسه یې کوم اقدام نه دی شوی. `,
	pt: `A atividade está pronta para ser executada, mas nenhuma ação foi tomada ainda. `,
	'pt-pt': `A atividade está pronta para ser realizada, mas nenhuma ação ainda foi tomada. `,
	ru: `Действие готово к выполнению, но никаких действий еще не предпринято. `,
	sm: `Ua saunia le gaoioiga e fai, ae e leai se gaoioiga e faia. `,
	so: `Howlgalka ayaa diyaar u ah in la fuliyo, balse ma jiraan wax tallaabo ah oo laga qaaday wali. `,
	th: `กิจกรรมพร้อมที่จะดําเนินการ แต่ยังไม่มีการดําเนินการใดๆ `,
	ur: `یہ سرگرمی انجام دینے کے لئے تیار ہے ، لیکن ابھی تک کوئی کارروائی نہیں کی گئی ہے۔ `,
	vi: `Hoạt động đã sẵn sàng để được thực hiện, nhưng chưa có hành động nào được thực hiện. `,
	'zh-hans': `该活动已准备好执行，但尚未执行任何操作。 `,
	'zh-hant': `該活動已準備好執行，但尚未執行任何操作。 `,
	en: `The activity is ready to be performed, but no action has yet been taken. `
};

export const loc_cancelledStatusDesc: Record<Locale, string> = {
	ar: `لم يكتمل النشاط.`,
	de: `Die Aktivität wurde nicht abgeschlossen.`,
	es: `La actividad no se completó.`,
	fa: `فعالیت کامل نشده است.`,
	fil: `Hindi natapos ang aktibidad.`,
	fr: `L’activité n’a pas été menée à terme.`,
	hi: `गतिविधि पूरी नहीं हुई.`,
	ht: `Aktivite a pa te fini.`,
	it: `L'attività non è stata completata.`,
	ja: `アクティビティは完了しませんでした。`,
	ko: `활동이 완료되지 않았습니다.`,
	mww: `Qhov kev ua yog tsis tiav.`,
	ps: `فعالیت بشپړ شوی نه و.`,
	pt: `A atividade não foi concluída.`,
	'pt-pt': `A atividade não foi concluída.`,
	ru: `Мероприятие не было завершено.`,
	sm: `E lei maea le gaoioiga.`,
	so: `Howshaasi lama dhameystirin.`,
	th: `กิจกรรมยังไม่เสร็จสมบูรณ์`,
	ur: `سرگرمی مکمل نہیں ہوئی تھی۔`,
	vi: `Hoạt động đã không được hoàn thành.`,
	'zh-hans': `活动未完成。`,
	'zh-hant': `活動未完成。`,
	en: `The activity was not completed.`
};

export const loc_inProgressStatusDesc: Record<Locale, string> = {
	ar: `تم بدء النشاط ولكنه لم يكتمل بعد.`,
	de: `Die Aktivität wurde gestartet, ist aber noch nicht abgeschlossen.`,
	es: `La actividad se ha iniciado, pero aún no ha finalizado.`,
	fa: `فعالیت آغاز شده است اما هنوز کامل نشده است.`,
	fil: `Nasimulan na ang aktibidad ngunit hindi pa ito kumpleto.`,
	fr: `L’activité a commencé mais n’est pas encore terminée.`,
	hi: `गतिविधि शुरू की गई है लेकिन अभी तक पूरी नहीं हुई है।`,
	ht: `Aktivite a te kòmanse men li poko konplè.`,
	it: `L'attività è stata avviata ma non è ancora completata.`,
	ja: `アクティビティは開始されましたが、まだ完了していません。`,
	ko: `활동이 시작되었지만 아직 완료되지 않았습니다.`,
	mww: `Qhov kev ua tau pib tab sis tsis tau tiav.`,
	ps: `دغه عملیات پیل شوی خو تر اوسه بشپړ شوی نه دی.`,
	pt: `A atividade foi iniciada, mas ainda não está concluída.`,
	'pt-pt': `A atividade foi iniciada, mas ainda não está concluída.`,
	ru: `Мероприятие начато, но еще не завершено.`,
	sm: `Ua amata le gaoioiga ae e lei maea.`,
	so: `Hawsha waa la billaabay, hase yeeshee weli ma dhamaato.`,
	th: `กิจกรรมได้เริ่มแล้ว แต่ยังไม่เสร็จสมบูรณ์`,
	ur: `سرگرمی شروع کردی گئی ہے لیکن ابھی مکمل نہیں ہوئی ہے۔`,
	vi: `Hoạt động đã được bắt đầu nhưng chưa hoàn thành.`,
	'zh-hans': `活动已启动，但尚未完成。`,
	'zh-hant': `活動已啟動，但尚未完成。`,
	en: `The activity has been started but is not yet complete.`
};

export const loc_onHoldStatusDesc: Record<Locale, string> = {
	ar: `تم بدء النشاط ولكن تم إيقاف العمل مؤقتا.`,
	de: `Die Aktivität wurde gestartet, aber die Arbeit wurde pausiert.`,
	es: `La actividad se ha iniciado, pero los trabajos se han pausado.`,
	fa: `فعالیت آغاز شده است اما کار متوقف شده است.`,
	fil: `Nasimulan na ang aktibidad ngunit natigil ang trabaho.`,
	fr: `L’activité a démarré mais les travaux ont été interrompus.`,
	hi: `गतिविधि शुरू कर दी गई है लेकिन काम रोक दिया गया है।`,
	ht: `Aktivite a te kòmanse men travay te poze.`,
	it: `L'attività è stata avviata, ma il lavoro è stato sospeso.`,
	ja: `アクティビティは開始されましたが、作業は一時停止されています。`,
	ko: `활동이 시작되었지만 작업이 일시 중지되었습니다.`,
	mww: `Txoj kev ua tau pib tab sis ua hauj lwm muaj tau paused.`,
	ps: `عملیات پیل شوی، خو کار یې ځنډول شوی دی.`,
	pt: `A atividade foi iniciada, mas o trabalho foi interrompido.`,
	'pt-pt': `A atividade foi iniciada, mas o trabalho foi interrompido.`,
	ru: `Деятельность начата, но работа приостановлена.`,
	sm: `Ua amata le gaoioiga ae ua taofia le galuega.`,
	so: `Hawshii waa la bilaabay, shaqaduse waa hakisay.`,
	th: `กิจกรรมได้เริ่มต้นแล้ว แต่งานถูกหยุดชั่วคราว`,
	ur: `سرگرمی شروع کردی گئی ہے لیکن کام روک دیا گیا ہے۔`,
	vi: `Hoạt động đã được bắt đầu nhưng công việc đã bị tạm dừng.`,
	'zh-hans': `活动已启动，但工作已暂停。`,
	'zh-hant': `活動已啟動，但工作已暫停。`,
	en: `The activity has been started but work has been paused.`
};

export const loc_completedStatusDesc: Record<Locale, string> = {
	ar: `تم الانتهاء من النشاط.`,
	de: `Die Aktivität wurde abgeschlossen.`,
	es: `La actividad se ha completado.`,
	fa: `فعالیت به پایان رسیده است.`,
	fil: `Natapos na ang aktibidad.`,
	fr: `L’activité est terminée.`,
	hi: `गतिविधि पूरी हो गई है।`,
	ht: `Aktivite a fini.`,
	it: `L'attività è stata completata.`,
	ja: `アクティビティが完了しました。`,
	ko: `활동이 완료되었습니다.`,
	mww: `Qhov kev ua tau tiav lawm.`,
	ps: `فعالیت یې بشپړ شوی دی.`,
	pt: `A atividade foi concluída.`,
	'pt-pt': `A atividade foi concluída.`,
	ru: `Мероприятие завершено.`,
	sm: `Ua maea le gaoioiga.`,
	so: `Howlgalka waa la dhammeystiray.`,
	th: `กิจกรรมเสร็จสิ้นแล้ว`,
	ur: `سرگرمی مکمل ہو چکی ہے۔`,
	vi: `Hoạt động đã hoàn thành.`,
	'zh-hans': `活动已完成。`,
	'zh-hant': `活動已完成。`,
	en: `The activity has been completed.`
};

export const loc_chooseStatus: Record<Locale, string> = {
	ar: `اختر الحالة`,
	de: `Status wählen`,
	es: `Elegir estado`,
	fa: `انتخاب وضعیت`,
	fil: `Pumili ng katayuan`,
	fr: `Choisir le statut`,
	hi: `स्थिति चुनें`,
	ht: `Chwazi estati`,
	it: `Scegli lo stato`,
	ja: `ステータスを選択`,
	ko: `상태 선택`,
	mww: `Xaiv cov txheej xwm raws li txoj`,
	ps: `انکړ`,
	pt: `Escolher status`,
	'pt-pt': `Escolher estado`,
	ru: `Выберите статус`,
	sm: `Filifili le tulaga`,
	so: `Dooro status`,
	th: `เลือกสถานะ`,
	ur: `حیثیت منتخب کریں`,
	vi: `Chọn trạng thái`,
	'zh-hans': `选择状态`,
	'zh-hant': `選擇狀態`,
	en: `Choose status`
};

export const loc_toggleStatusMenu: Record<Locale, string> = {
	ar: `تبديل قائمة الحالة`,
	de: `Statusmenü umschalten`,
	es: `Alternar menú de estado`,
	fa: `منوی وضعیت را تغییر دهید`,
	fil: `Menu ng katayuan ng togel`,
	fr: `Basculer le menu d’état`,
	hi: `टॉगल स्थिति मेनू`,
	ht: `Meni estati Toggle`,
	it: `Attiva/disattiva il menu di stato`,
	ja: `ステータスメニューの切り替え`,
	ko: `상태 메뉴 전환`,
	mww: `Toggle raws li txoj cai zaub mov`,
	ps: `انکړ غورنۍ څرنګتیاونج`,
	pt: `Alternar menu de status`,
	'pt-pt': `Alternar menu de status`,
	ru: `Переключение меню состояния`,
	sm: `Lisi autu o le tulaga o le Toggle`,
	so: `xaaladda Toggle menu`,
	th: `สลับเมนูสถานะ`,
	ur: `Toggle status menu`,
	vi: `Chuyển đổi menu trạng thái`,
	'zh-hans': `切换状态菜单`,
	'zh-hant': `切換狀態功能表`,
	en: `Toggle status menu`
};

export const loc_delete: Record<Locale, string> = {
	ar: `حذف`,
	de: `Löschen`,
	es: `Borrar`,
	fa: `حذف`,
	fil: `Tanggalin ang`,
	fr: `Supprimer`,
	hi: `मिटाना`,
	ht: `efase`,
	it: `Cancellare`,
	ja: `削除`,
	ko: `삭제하다`,
	mww: `Delete`,
	ps: `ړنګول`,
	pt: `Excluir`,
	'pt-pt': `Suprimir`,
	ru: `Удалить`,
	sm: `Tapeese`,
	so: `delete`,
	th: `ลบ`,
	ur: `مٹانا`,
	vi: `Xóa`,
	'zh-hans': `删除`,
	'zh-hant': `刪除`,
	en: `Delete`
};

export const loc_confirmDelete: Record<Locale, string> = {
	ar: `تأكيد الحذف`,
	de: `Löschen bestätigen`,
	es: `Confirmar eliminación`,
	fa: `تأیید حذف`,
	fil: `Kumpirmahin ang pagtanggal`,
	fr: `Confirmer la suppression`,
	hi: `हटाने की पुष्टि करें`,
	ht: `Confirm et`,
	it: `Conferma l'eliminazione`,
	ja: `削除の確認`,
	ko: `삭제 확인`,
	mww: `Tshawb kom paub meej tias delete`,
	ps: `ړنګول باوری کړئ`,
	pt: `Confirmar exclusão`,
	'pt-pt': `Confirmar exclusão`,
	ru: `Подтвердить, удалить`,
	sm: `Faamautu le tapeese`,
	so: `Xaqiijiso delete`,
	th: `ยืนยันการลบ`,
	ur: `حذف کی تصدیق کریں`,
	vi: `Xác nhận xóa`,
	'zh-hans': `确认删除`,
	'zh-hant': `確認刪除`,
	en: `Confirm delete`
};

export const loc_deleteConfirmation: Record<Locale, (title: unknown) => string> = {
	ar: (title) => `هل أنت متأكد من أنك تريد حذف "${title}"؟`,
	de: (title) => `Sind Sie sicher, dass Sie "${title}" löschen möchten?`,
	es: (title) => `¿Estás seguro de que quieres eliminar "${title}"?`,
	fa: (title) => `آیا مطمئن هستید که می خواهید "${title} را حذف کنید؟`,
	fil: (title) => `Sigurado ka bang gusto mong tanggalin ang "${title}"?`,
	fr: (title) => `Êtes-vous sûr de vouloir supprimer « ${title} » ?`,
	hi: (title) => `क्या आप वाकई "${title}" हटाना चाहते हैं?`,
	ht: (title) => `Èske ou sèten ou vle efase "${title}"?`,
	it: (title) => `Sei sicuro di voler eliminare "${title}"?`,
	ja: (title) => `「${title}」を削除してもよろしいですか?`,
	ko: (title) => `"${title}"를 삭제하시겠습니까?`,
	mww: (title) => `Koj puas paub tseeb tias koj xav delete "${title}"?`,
	ps: (title) => `ایا تاسو ډاډه یاست چې تاسو غواړئ د "${title}" څخه لرې کړئ؟`,
	pt: (title) => `Tem certeza de que deseja excluir "${title}"?`,
	'pt-pt': (title) => `Tem certeza de que deseja excluir "${title}"?`,
	ru: (title) => `Вы уверены, что хотите удалить "${title}"?`,
	sm: (title) => `E te mautinoa e te manao e tapeese le "${title}"?`,
	so: (title) => `Ma hubtaa inaad rabto in aad tirtiro "${title}"?`,
	th: (title) => `คุณแน่ใจหรือไม่ว่าต้องการลบ "${title}"`,
	ur: (title) => `کیا آپ واقعی "جان ڈو" کو حذف کرنا ${title}؟`,
	vi: (title) => `Bạn có chắc chắn muốn xóa "${title}" không?`,
	'zh-hans': (title) => `您确定要删除 “${title}” 吗？`,
	'zh-hant': (title) => `您確定要刪除 「${title}」 嗎？`,
	en: (title) => `Are you sure you want to delete "${title}"?`
};

export const loc_deleteTitle: Record<Locale, (title: unknown) => string> = {
	ar: (title) => `حذف ${title}`,
	de: (title) => `${title} löschen`,
	es: (title) => `Eliminar ${title}`,
	fa: (title) => `حذف ${title}`,
	fil: (title) => `Tanggalin ang ${title}`,
	fr: (title) => `Supprimer ${title}`,
	hi: (title) => `${title} को हटाएँ`,
	ht: (title) => `${title}`,
	it: (title) => `Eliminare ${title}`,
	ja: (title) => `${title}の削除`,
	ko: (title) => `${title} 삭제`,
	mww: (title) => `Delete ${title}`,
	ps: (title) => `${title} حذف کړئ`,
	pt: (title) => `Excluir ${title}`,
	'pt-pt': (title) => `Excluir ${title}`,
	ru: (title) => `Удалить ${title}`,
	sm: (title) => `Tapeese ${title}`,
	so: (title) => `Delete ${title}`,
	th: (title) => `ลบ ${title}`,
	ur: (title) => `${title}`,
	vi: (title) => `Xóa ${title}`,
	'zh-hans': (title) => `删除 ${title}`,
	'zh-hant': (title) => `刪除 ${title}`,
	en: (title) => `Delete ${title}`
};

export const loc_usingActivities: Record<Locale, string> = {
	ar: `استخدام الأنشطة`,
	de: `Verwenden von Aktivitäten`,
	es: `Uso de actividades`,
	fa: `استفاده از فعالیت ها`,
	fil: `Paggamit ng mga aktibidad`,
	fr: `Utilisation des activités`,
	hi: `गतिविधियों का उपयोग करना`,
	ht: `Sèvi ak aktivite`,
	it: `Utilizzo delle attività`,
	ja: `アクティビティの使用`,
	ko: `활동 사용`,
	mww: `Siv kev ua ub no`,
	ps: `د فعالیتونو کارول`,
	pt: `Usando atividades`,
	'pt-pt': `Utilização de atividades`,
	ru: `Использование действий`,
	sm: `Faaaogaina o gaoioiga`,
	so: `U isticmaalka waxqabadka`,
	th: `การใช้กิจกรรม`,
	ur: `سرگرمیوں کا استعمال`,
	vi: `Sử dụng hoạt động`,
	'zh-hans': `使用活动`,
	'zh-hant': `使用活動`,
	en: `Using activities`
};

export const loc_publicEventNotStarted: Record<Locale, string> = {
	ar: `لم يبدأ الحدث. لا يتم قبول الطلبات في الوقت الحالي.`,
	de: `Das Ereignis hat noch nicht begonnen. Einreichungen werden derzeit nicht angenommen.`,
	es: `El evento no ha comenzado. No se aceptan inscripciones en este momento.`,
	fa: `این رویداد شروع نشده است. در حال حاضر مطالب ارسالی پذیرفته نمی شوند.`,
	fil: `Hindi pa nagsisimula ang event. Hindi pa natatanggap ang mga isinumite sa ngayon.`,
	fr: `L’événement n’a pas encore commencé. Les soumissions ne sont pas acceptées pour le moment.`,
	hi: `इवेंट शुरू नहीं हुआ है। प्रस्तुतियाँ इस समय स्वीकार नहीं की जा रही हैं।`,
	ht: `Evènman an pa te kòmanse. Soumèt yo pa aksepte nan moman sa a.`,
	it: `L'evento non è iniziato. Al momento non si accettano candidature.`,
	ja: `イベントが開始されていません。現時点では、投稿は受け付けておりません。`,
	ko: `이벤트가 시작되지 않았습니다. 현재 제출은 수락되지 않습니다.`,
	mww: `Cov kev tshwm sim twb tsis pib. Submissions yuav tsis tau txais thaum lub sij hawm no.`,
	ps: `پیښه نه ده پیل شوې. په اوس وخت کې وړاندیزونه نه منل کیږی.`,
	pt: `O evento não começou. As inscrições não estão sendo aceitas no momento.`,
	'pt-pt': `O evento ainda não começou. As submissões não estão sendo aceitas no momento.`,
	ru: `Мероприятие еще не началось. В настоящее время заявки не принимаются.`,
	sm: `E lei amataina le gaoioiga. E le taliaina tusiga e auina atu i le taimi lenei.`,
	so: `Munaasabaddu ma bilaaban. Soo gudbinta lama aqbalayo waqtigan.`,
	th: `กิจกรรมยังไม่เริ่ม การส่งผลงานยังไม่ได้รับการยอมรับในขณะนี้`,
	ur: `ایونٹ ابھی شروع نہیں ہوا ہے. اس وقت درخواستیں قبول نہیں کی جا رہی ہیں۔`,
	vi: `Sự kiện vẫn chưa bắt đầu. Bài nộp không được chấp nhận tại thời điểm này.`,
	'zh-hans': `事件尚未开始。目前不接受提交。`,
	'zh-hant': `事件尚未開始。目前不接受提交。`,
	en: `The event has not started. Submissions are not being accepted at this time.`
};

export const loc_publicEventAlreadyEnded: Record<Locale, string> = {
	ar: `انتهى الحدث بالفعل. لم يعد يتم قبول الطلبات.`,
	de: `Die Veranstaltung ist bereits beendet. Einreichungen werden nicht mehr akzeptiert.`,
	es: `El evento ya terminó. Ya no se aceptan presentaciones.`,
	fa: `این رویداد قبلا به پایان رسیده بود. مطالب ارسالی دیگر پذیرفته نمی شوند.`,
	fil: `Natapos na ang event. Hindi na tinatanggap ang mga isinumite.`,
	fr: `L’événement est déjà terminé. Les soumissions ne sont plus acceptées.`,
	hi: `कार्यक्रम पहले ही समाप्त हो चुका है। प्रस्तुतियाँ अब स्वीकार नहीं की जा रही हैं।`,
	ht: `Evènman an te deja fini. Soumèt yo pa aksepte ankò.`,
	it: `L'evento è già terminato. Le candidature non vengono più accettate.`,
	ja: `イベントはすでに終了しています。投稿は受け付けられなくなりました。`,
	ko: `이벤트는 이미 종료되었습니다. 제출은 더 이상 수락되지 않습니다.`,
	mww: `Cov kev tshwm sim twb twb. Submissions yuav tsis tau txais.`,
	ps: `پیښه له دې مخکې هم پای ته رسیدلې وه. په دې اړه نور جزیات نه دی ورکړل شوی.`,
	pt: `O evento já terminou. As inscrições não estão mais sendo aceitas.`,
	'pt-pt': `O evento já terminou. As submissões já não são aceites.`,
	ru: `Мероприятие уже закончилось. Заявки больше не принимаются.`,
	sm: `Ua uma le gaoioiga. Ua le toe taliaina ia tusiga e auina atu.`,
	so: `Xafladdii wey dhammaatay. Soo gudbinta mar dambe lama aqbalayo.`,
	th: `งานสิ้นสุดลงแล้ว ไม่ยอมรับการส่งอีกต่อไป`,
	ur: `تقریب پہلے ہی ختم ہو چکی ہے۔ درخواستیں اب قبول نہیں کی جا رہی ہیں۔`,
	vi: `Sự kiện đã kết thúc. Bài gửi không còn được chấp nhận.`,
	'zh-hans': `活动已结束。不再接受提交。`,
	'zh-hant': `活動已結束。不再接受提交。`,
	en: `The event already ended. Submissions are no longer being accepted.`
};

export const loc_publicEventNotStarted2: Record<Locale, (startDate: unknown) => string> = {
	ar: (startDate) => `هذا الحدث لم يبدأ بعد. سيتم قبول الطلبات في ${startDate}.`,
	de: (startDate) => `Diese Veranstaltung hat noch nicht begonnen. Einreichungen werden ab dem ${startDate} entgegengenommen.`,
	es: (startDate) => `Este evento aún no ha comenzado. Las inscripciones se aceptarán el ${startDate}.`,
	fa: (startDate) => `این رویداد هنوز آغاز نشده است. آثار ارسالی در ${startDate} پذیرفته می شوند.`,
	fil: (startDate) => `Hindi pa nagsisimula ang event na ito. Ang mga isinumite ay tatanggapin sa ${startDate}.`,
	fr: (startDate) => `Cet événement n’a pas encore commencé. Les soumissions seront acceptées le ${startDate}.`,
	hi: (startDate) => `यह इवेंट अभी शुरू नहीं हुआ है। प्रविष्टियां ${startDate} स्वीकार की जाएंगी।`,
	ht: (startDate) => `Evènman sa a poko kòmanse. Soumèt yo pral aksepte ${startDate}.`,
	it: (startDate) => `Questo evento non è ancora iniziato. Le iscrizioni saranno accettate il ${startDate}.`,
	ja: (startDate) => `このイベントはまだ始まっていません。応募は ${startDate}受け付けられます。`,
	ko: (startDate) => `이 이벤트는 아직 시작되지 않았습니다. 제출은 ${startDate}일에 접수됩니다.`,
	mww: (startDate) => `Qhov kev tshwm sim no twb tsis tau pib. Submissions yuav tau txais rau ${startDate}.`,
	ps: (startDate) => `دا پېښه تر اوسه نه ده پیل شوې. وړاندیزونه به د ${startDate} ومنل شی.`,
	pt: (startDate) => `Este evento ainda não começou. As inscrições serão aceitas no ${startDate}.`,
	'pt-pt': (startDate) => `Este evento ainda não começou. As inscrições serão aceitas no dia ${startDate}.`,
	ru: (startDate) => `Это событие еще не началось. Заявки будут приниматься ${startDate}.`,
	sm: (startDate) => `E le'i amataina lenei mea. O le a taliaina tusiga e auina atu i le ${startDate}.`,
	so: (startDate) => `Xafladan ayaan weli bilaaban. Soo gudbinta waxaa la aqbali doonaa ${startDate}.`,
	th: (startDate) => `งานนี้ยังไม่เริ่ม การส่งผลงานจะได้รับการยอมรับในวันที่ ${startDate}`,
	ur: (startDate) => `یہ واقعہ ابھی شروع نہیں ہوا ہے۔ درخواستیں ${startDate} کو قبول کی جائیں گی۔`,
	vi: (startDate) => `Sự kiện này vẫn chưa bắt đầu. Các tác phẩm dự thi sẽ được nhận vào ${startDate}.`,
	'zh-hans': (startDate) => `此活动尚未开始。提交将于 ${startDate} 日接受。`,
	'zh-hant': (startDate) => `此活動尚未開始。提交將於 ${startDate} 日接受。`,
	en: (startDate) => `This event has not yet started. Submissions will be accepted on ${startDate}.`
};

export const loc_publicEventAlreadyEnded2: Record<Locale, (endDate: unknown) => string> = {
	ar: (endDate) => `انتهى هذا الحدث في ${endDate} لم يعد يتم قبول الطلبات.`,
	de: (endDate) => `Dieses Event ${endDate}. Einreichungen werden nicht mehr akzeptiert.`,
	es: (endDate) => `Este evento finalizó el ${endDate}. Ya no se aceptan presentaciones.`,
	fa: (endDate) => `این رویداد در ${endDate} به پایان رسید. مطالب ارسالی دیگر پذیرفته نمی شوند.`,
	fil: (endDate) => `Natapos ang event na ito noong ${endDate}. Hindi na tinatanggap ang mga isinumite.`,
	fr: (endDate) => `Cet événement s’est terminé ${endDate}. Les soumissions ne sont plus acceptées.`,
	hi: (endDate) => `यह आयोजन ${endDate} समाप्त हुआ। प्रस्तुतियाँ अब स्वीकार नहीं की जा रही हैं।`,
	ht: (endDate) => `Evènman sa a te fini ${endDate}. Soumèt yo pa aksepte ankò.`,
	it: (endDate) => `Questo evento si è concluso il ${endDate}. Le candidature non vengono più accettate.`,
	ja: (endDate) => `このイベントは ${endDate}。投稿は受け付けられなくなりました。`,
	ko: (endDate) => `이 이벤트는 ${endDate} 종료되었습니다. 제출은 더 이상 수락되지 않습니다.`,
	mww: (endDate) => `Qhov kev tshwm sim no twb rau ${endDate}. Submissions yuav tsis tau txais.`,
	ps: (endDate) => `دغه غونډه د جولای پر ${endDate}. په دې اړه نور جزیات نه دی ورکړل شوی.`,
	pt: (endDate) => `Este evento terminou em ${endDate}. As inscrições não estão mais sendo aceitas.`,
	'pt-pt': (endDate) => `Este evento terminou no dia ${endDate}. As submissões já não são aceites.`,
	ru: (endDate) => `Это событие завершилось ${endDate}. Заявки больше не принимаются.`,
	sm: (endDate) => `Na faaiuina lenei mea i le ${endDate}. Ua le toe taliaina ia tusiga e auina atu.`,
	so: (endDate) => `Xafladan ayaa soo dhamaatay ${endDate}. Soo gudbinta mar dambe lama aqbalayo.`,
	th: (endDate) => `งานนี้สิ้นสุดลงเมื่อวันที่ ${endDate} ไม่ยอมรับการส่งอีกต่อไป`,
	ur: (endDate) => `یہ تقریب ${endDate}۔ درخواستیں اب قبول نہیں کی جا رہی ہیں۔`,
	vi: (endDate) => `Sự kiện này kết thúc vào ${endDate}. Bài gửi không còn được chấp nhận.`,
	'zh-hans': (endDate) => `此活动于 ${endDate} 日结束。不再接受提交。`,
	'zh-hant': (endDate) => `此活動於 ${endDate} 日結束。不再接受提交。`,
	en: (endDate) => `This event ended on ${endDate}. Submissions are no longer being accepted.`
};

export const loc_publicEventOnHold: Record<Locale, string> = {
	ar: `هذا الحدث معلق. لا يتم قبول الطلبات في الوقت الحالي.`,
	de: `Diese Veranstaltung ist in der Warteschleife. Einreichungen werden derzeit nicht angenommen.`,
	es: `Este evento está en espera. No se aceptan inscripciones en este momento.`,
	fa: `این رویداد در حالت تعلیق است. در حال حاضر مطالب ارسالی پذیرفته نمی شوند.`,
	fil: `On hold ang event na ito. Hindi pa natatanggap ang mga isinumite sa ngayon.`,
	fr: `Cet événement est en attente. Les soumissions ne sont pas acceptées pour le moment.`,
	hi: `यह ईवेंट ऑन-होल्ड है. प्रस्तुतियाँ इस समय स्वीकार नहीं की जा रही हैं।`,
	ht: `Evènman sa a se sou-kenbe. Soumèt yo pa aksepte nan moman sa a.`,
	it: `Questo evento è in sospeso. Al momento non si accettano candidature.`,
	ja: `このイベントは保留中です。現時点では、投稿は受け付けておりません。`,
	ko: `이 이벤트는 보류 중입니다. 현재 제출은 수락되지 않습니다.`,
	mww: `Qhov kev tshwm sim no yog on-tuav. Submissions yuav tsis tau txais thaum lub sij hawm no.`,
	ps: `دا پیښه په بند کې ده. په اوس وخت کې وړاندیزونه نه منل کیږی.`,
	pt: `Este evento está em espera. As inscrições não estão sendo aceitas no momento.`,
	'pt-pt': `Este evento está suspenso. As submissões não estão sendo aceitas no momento.`,
	ru: `Это мероприятие приостановлено. В настоящее время заявки не принимаются.`,
	sm: `O lenei gaoioiga o loo taofia. E le taliaina tusiga e auina atu i le taimi lenei.`,
	so: `Xafladan waa is-waafqsan tahay. Soo gudbinta lama aqbalayo waqtigan.`,
	th: `กิจกรรมนี้ถูกระงับไว้ การส่งผลงานยังไม่ได้รับการยอมรับในขณะนี้`,
	ur: `یہ تقریب روک دی گئی ہے۔ اس وقت درخواستیں قبول نہیں کی جا رہی ہیں۔`,
	vi: `Sự kiện này đang tạm hoãn. Bài nộp không được chấp nhận tại thời điểm này.`,
	'zh-hans': `此活动处于暂停状态。目前不接受提交。`,
	'zh-hant': `此活動處於暫停狀態。目前不接受提交。`,
	en: `This event is on-hold. Submissions are not being accepted at this time.`
};

export const loc_publicEventCancelled: Record<Locale, string> = {
	ar: `تم إلغاء هذا الحدث. لا يتم قبول الطلبات في الوقت الحالي.`,
	de: `Diese Veranstaltung wurde abgesagt. Einreichungen werden derzeit nicht angenommen.`,
	es: `Este evento fue cancelado. No se aceptan inscripciones en este momento.`,
	fa: `این رویداد لغو شد. در حال حاضر مطالب ارسالی پذیرفته نمی شوند.`,
	fil: `Kinansela ang pangyayaring ito. Hindi pa natatanggap ang mga isinumite sa ngayon.`,
	fr: `Cet événement a été annulé. Les soumissions ne sont pas acceptées pour le moment.`,
	hi: `यह कार्यक्रम रद्द कर दिया गया था. प्रस्तुतियाँ इस समय स्वीकार नहीं की जा रही हैं।`,
	ht: `Evènman sa a te anile. Soumèt yo pa aksepte nan moman sa a.`,
	it: `L'evento è stato annullato. Al momento non si accettano candidature.`,
	ja: `このイベントは中止となりました。現時点では、投稿は受け付けておりません。`,
	ko: `이 이벤트는 취소되었습니다. 현재 제출은 수락되지 않습니다.`,
	mww: `Qhov kev tshwm sim no tau lawm os. Submissions yuav tsis tau txais thaum lub sij hawm no.`,
	ps: `دا پیښه لغوه شوه. په اوس وخت کې وړاندیزونه نه منل کیږی.`,
	pt: `Este evento foi cancelado. As inscrições não estão sendo aceitas no momento.`,
	'pt-pt': `Este evento foi cancelado. As submissões não estão sendo aceitas no momento.`,
	ru: `Это мероприятие было отменено. В настоящее время заявки не принимаются.`,
	sm: `Na faaleaogaina lenei gaoioiga. E le taliaina tusiga e auina atu i le taimi lenei.`,
	so: `Xafladan ayaa baaqatay. Soo gudbinta lama aqbalayo waqtigan.`,
	th: `งานนี้ถูกยกเลิก การส่งผลงานยังไม่ได้รับการยอมรับในขณะนี้`,
	ur: `یہ تقریب منسوخ کر دی گئی۔ اس وقت درخواستیں قبول نہیں کی جا رہی ہیں۔`,
	vi: `Sự kiện này đã bị hủy bỏ. Bài nộp không được chấp nhận tại thời điểm này.`,
	'zh-hans': `此活动已取消。目前不接受提交。`,
	'zh-hant': `此活動已取消。目前不接受提交。`,
	en: `This event was cancelled. Submissions are not being accepted at this time.`
};

export const loc_publicEventRedirectedFrom: Record<Locale, (activityHref: unknown, title: unknown, endDate: unknown) => TemplateResult> = {
	ar: (activityHref, title, endDate) => html`بالتحويل من <a href="${activityHref}">${title}</a> التي انتهت في ${endDate}`,
	de: (activityHref, title, endDate) => html`Weitergeleitet von <a href="${activityHref}">${title}</a> endete am ${endDate}.`,
	es: (activityHref, title, endDate) => html`Redirigido desde <a href="${activityHref}">${title}</a> que terminó el ${endDate}.`,
	fa: (activityHref, title, endDate) => html`تغییرمسیر از <a href="${activityHref}">${title}</a> که در ${endDate} به پایان رسید.`,
	fil: (activityHref, title, endDate) => html`Nag redirect mula sa <a href="${activityHref}">${title}</a> na nagtapos noong ${endDate}.`,
	fr: (activityHref, title, endDate) => html`Redirigé depuis <a href="${activityHref}">${title}</a> qui a terminé le ${endDate}.`,
	hi: (activityHref, title, endDate) => html`<a href="${activityHref}">${title}</a> से अनुप्रेषित, जो ${endDate} को समाप्त हुआ।`,
	ht: (activityHref, title, endDate) => html`Redireksyon soti nan <a href="${activityHref}">${title}</a> ki te fini ${endDate}.`,
	it: (activityHref, title, endDate) => html`Reindirizzato da <a href="${activityHref}">${title}</a> che si è conclusa il ${endDate}.`,
	ja: (endDate, activityHref, title) => html`${endDate}終了した<a href="${activityHref}">${title}</a>からリダイレクトされました。`,
	ko: (endDate, activityHref, title) => html`${endDate} 일에 종료 된 <a href="${activityHref}">${title}</a>에서 리디렉션되었습니다.`,
	mww: (activityHref, title, endDate) => html`Redirected ntawm <a href="${activityHref}">${title}</a> uas twb rau ${endDate}.`,
	ps: (activityHref, title, endDate) => html`د <a href="${activityHref}">${title}</a> څخه لیږدول شوی چې د ${endDate}.`,
	pt: (activityHref, title, endDate) => html`Redirecionado de <a href="${activityHref}">${title}</a> que terminou em ${endDate}.`,
	'pt-pt': (activityHref, title, endDate) => html`Redirecionado de <a href="${activityHref}">${title}</a> que terminou em ${endDate}.`,
	ru: (activityHref, title, endDate) => html`Перенаправлено с « <a href="${activityHref}">${title}</a> », которая закончилась ${endDate}.`,
	sm: (activityHref, title, endDate) => html`Toe faasino mai <a href="${activityHref}">${title}</a> lea na faaiuina i le ${endDate}.`,
	so: (activityHref, title, endDate) => html`Waxaa si toos ah looga soo saaray <a href="${activityHref}">${title}</a> oo dhammaatay ${endDate}.`,
	th: (activityHref, title, endDate) => html`เปลี่ยนทางจาก <a href="${activityHref}">${title}</a> ซึ่งสิ้นสุดเมื่อวันที่ ${endDate}`,
	ur: (activityHref, title, endDate) => html`<a href="${activityHref}">${title}</a> سے ری ڈائریکٹ کیا گیا جو ${endDate} کو ختم ہوا۔`,
	vi: (activityHref, title, endDate) => html`Chuyển hướng từ <a href="${activityHref}">${title}</a> kết thúc vào ${endDate}.`,
	'zh-hans': (endDate, activityHref, title) => html`重定向自7${endDate}结束的<a href="${activityHref}">${title}</a>。`,
	'zh-hant': (endDate, activityHref, title) => html`重定向自7${endDate}結束的<a href="${activityHref}">${title}</a>。`,
	en: (activityHref, title, endDate) => html`Redirected from <a href="${activityHref}">${title}</a> which ended on ${endDate}.`
};

export const loc_title: Record<Locale, string> = {
	ar: `عنوان`,
	de: `Titel`,
	es: `Título`,
	fa: `عنوان`,
	fil: `Pamagat`,
	fr: `Titre`,
	hi: `उपाधि`,
	ht: `Tit`,
	it: `Titolo`,
	ja: `タイトル`,
	ko: `타이틀`,
	mww: `Title`,
	ps: `سرلیک`,
	pt: `Título`,
	'pt-pt': `Título`,
	ru: `Титул`,
	sm: `Autu`,
	so: `Title`,
	th: `ชื่อเรื่อง`,
	ur: `عنوان`,
	vi: `Tiêu đề`,
	'zh-hans': `标题`,
	'zh-hant': `標題`,
	en: `Title`
};

export const loc_startEndDates: Record<Locale, string> = {
	ar: `تواريخ البدء/الانتهاء`,
	de: `Start-/Enddatum`,
	es: `Fechas de inicio/finalización`,
	fa: `تاریخ شروع/پایان`,
	fil: `Mga petsa ng pagsisimula / pagtatapos`,
	fr: `Dates de début et de fin`,
	hi: `प्रारंभ/समाप्ति दिनांक`,
	ht: `Kòmanse/fini dat`,
	it: `Date di inizio/fine`,
	ja: `開始日/終了日`,
	ko: `시작일/종료일`,
	mww: `Pib/hnub tim`,
	ps: `د پیل / پای نیټې`,
	pt: `Datas de início/término`,
	'pt-pt': `Datas de início/fim`,
	ru: `Даты начала/окончания`,
	sm: `Aso amata/faaiuga`,
	so: `Taariikhda Bilowga/dhamaadka`,
	th: `วันที่เริ่มต้น/สิ้นสุด`,
	ur: `آغاز / اختتام کی تاریخیں`,
	vi: `Ngày bắt đầu/kết thúc`,
	'zh-hans': `开始/结束日期`,
	'zh-hant': `開始/結束日期`,
	en: `Start/end dates`
};

export const loc_addresses: Record<Locale, string> = {
	ar: `عناوين`,
	de: `Adressen`,
	es: `Direcciones`,
	fa: `آدرس`,
	fil: `Mga Address`,
	fr: `Adresses`,
	hi: `पता`,
	ht: `Adrès`,
	it: `Indirizzi`,
	ja: `アドレス`,
	ko: `주소`,
	mww: `Chaw Nyob`,
	ps: `پتې`,
	pt: `Endereços`,
	'pt-pt': `Endereços`,
	ru: `Адреса`,
	sm: `Tuatusi`,
	so: `Cinwaanada`,
	th: `ที่อยู่`,
	ur: `پتے`,
	vi: `Địa chỉ`,
	'zh-hans': `地址`,
	'zh-hant': `位址`,
	en: `Addresses`
};

export const loc_address: Record<Locale, string> = {
	ar: `عنوان`,
	de: `Adresse`,
	es: `Dirección`,
	fa: `آدرس`,
	fil: `Address`,
	fr: `Adresse`,
	hi: `पता`,
	ht: `Adrès`,
	it: `Indirizzo`,
	ja: `住所`,
	ko: `주소`,
	mww: `Address`,
	ps: `پته`,
	pt: `Endereço`,
	'pt-pt': `Endereço`,
	ru: `Адрес`,
	sm: `Tuatusi`,
	so: `Cinwaanka`,
	th: `ที่อยู่`,
	ur: `پتہ`,
	vi: `Địa chỉ`,
	'zh-hans': `地址`,
	'zh-hant': `位址`,
	en: `Address`
};

export const loc_openCount: Record<Locale, (count: unknown) => string> = {
	ar: (count) => `${count} مفتوح`,
	de: (count) => `${count} geöffnet`,
	es: (count) => `${count} abiertos`,
	fa: (count) => `${count} باز`,
	fil: (count) => `${count} bukas`,
	fr: (count) => `${count} Ouvrir`,
	hi: (count) => `${count} उघडा`,
	ht: (count) => `${count} louvri`,
	it: (count) => `${count} apri`,
	ja: (count) => `${count} オープン`,
	ko: (count) => `${count} 오픈`,
	mww: (count) => `${count} qhib`,
	ps: (count) => `${count} پرانیستی`,
	pt: (count) => `${count} aberto`,
	'pt-pt': (count) => `${count} aberto`,
	ru: (count) => `${count} открытых`,
	sm: (count) => `${count} tatala`,
	so: (count) => `${count} furan`,
	th: (count) => `${count} เปิด`,
	ur: (count) => `${count} کھلی`,
	vi: (count) => `${count} Mở`,
	'zh-hans': (count) => `${count} 打开`,
	'zh-hant': (count) => `${count} 打開`,
	en: (count) => `${count} open`
};

export const loc_closedCount: Record<Locale, (count: unknown) => string> = {
	ar: (count) => `${count} مغلق`,
	de: (count) => `${count} geschlossen`,
	es: (count) => `${count} cerrados`,
	fa: (count) => `${count} بسته`,
	fil: (count) => `${count} sarado na`,
	fr: (count) => `${count} fermé`,
	hi: (count) => `${count} बंद`,
	ht: (count) => `${count} fèmen`,
	it: (count) => `${count} chiusi`,
	ja: (count) => `${count} 定休日`,
	ko: (count) => `${count} 닫힘`,
	mww: (count) => `${count} kaw`,
	ps: (count) => `${count} تړل شوی`,
	pt: (count) => `${count} Fechado`,
	'pt-pt': (count) => `${count} fechados`,
	ru: (count) => `${count} закрыто`,
	sm: (count) => `${count} tapuni`,
	so: (count) => `${count} xidhan`,
	th: (count) => `${count} ปิด`,
	ur: (count) => `${count} بند`,
	vi: (count) => `${count} Đóng cửa`,
	'zh-hans': (count) => `${count} 关闭`,
	'zh-hant': (count) => `${count} 關閉`,
	en: (count) => `${count} closed`
};

export const loc_noOpenActivities: Record<Locale, string> = {
	ar: `لا توجد أنشطة مفتوحة`,
	de: `Keine offenen Aktivitäten`,
	es: `No hay actividades abiertas`,
	fa: `بدون فعالیت باز`,
	fil: `Walang mga bukas na aktibidad`,
	fr: `Pas d’activités ouvertes`,
	hi: `कोई खुली गतिविधि नहीं`,
	ht: `Pa gen aktivite ouvè`,
	it: `Nessuna attività aperta`,
	ja: `オープンなアクティビティはありません`,
	ko: `열린 활동 없음`,
	mww: `Tsis muaj kev ua ub no`,
	ps: `هیڅ پرانیستې فعالیتونه`,
	pt: `Sem atividades abertas`,
	'pt-pt': `Sem atividades abertas`,
	ru: `Нет открытых мероприятий`,
	sm: `Leai ni gaoioiga tatala`,
	so: `Wax shaqo ah oo furan ma qabanayo`,
	th: `ไม่มีกิจกรรมที่เปิดอยู่`,
	ur: `کوئی کھلی سرگرمیاں نہیں`,
	vi: `Không có hoạt động mở`,
	'zh-hans': `无开放活动`,
	'zh-hant': `無開放活動`,
	en: `No open activities`
};

export const loc_noClosedActivities: Record<Locale, string> = {
	ar: `لا توجد أنشطة مغلقة`,
	de: `Keine geschlossenen Aktivitäten`,
	es: `No hay actividades cerradas`,
	fa: `بدون فعالیت بسته`,
	fil: `Walang mga saradong aktibidad`,
	fr: `Pas d’activités fermées`,
	hi: `कोई बंद गतिविधियाँ नहीं`,
	ht: `Pa gen aktivite fèmen`,
	it: `Nessuna attività chiusa`,
	ja: `閉鎖された活動はありません`,
	ko: `폐쇄된 활동 없음`,
	mww: `Tsis muaj kev ua ub no kaw`,
	ps: `هیڅ تړل شوی فعالیتونه`,
	pt: `Sem atividades fechadas`,
	'pt-pt': `Sem atividades fechadas`,
	ru: `Нет закрытых активностей`,
	sm: `Leai ni gaoioiga e tapunia`,
	so: `Wax hawl ah oo xiran ma jiro`,
	th: `ไม่มีกิจกรรมปิด`,
	ur: `کوئی بند سرگرمیاں نہیں`,
	vi: `Không có hoạt động khép kín`,
	'zh-hans': `无已关闭的活动`,
	'zh-hant': `無已關閉的活動`,
	en: `No closed activities`
};

export const loc_openActivitiesDesc: Record<Locale, string> = {
	ar: `سيتم سرد أنشطة المسودة والجاهزة والجارية والمعلقة هنا.`,
	de: `Entwurfs-, Bereit-, In Bearbeitung- und Zurückgestellte Aktivitäten werden hier aufgelistet.`,
	es: `Las actividades en borrador, listas, en curso y en espera se enumerarán aquí.`,
	fa: `پیش نویس، آماده، در حال انجام و فعالیت های در حال انتظار در اینجا فهرست می شوند.`,
	fil: `Ang mga draft, handa, in progress, at on-hold na aktibidad ay ilista dito.`,
	fr: `Les activités en brouillon, prêtes, en cours et en attente seront répertoriées ici.`,
	hi: `ड्राफ्ट, रेडी, इन-प्रोग्रेस और ऑन-होल्ड गतिविधियों को यहां सूचीबद्ध किया जाएगा।`,
	ht: `Pwogrè, nan-pwogrè, ak sou-kenbe aktivite yo pral ki nan lis isit la.`,
	it: `Le attività in bozza, pronte, in corso e in sospeso saranno elencate qui.`,
	ja: `ドラフト、準備完了、進行中、および保留中のアクティビティがここに一覧表示されます。`,
	ko: `초안, 준비, 진행 중 및 보류 활동이 여기에 나열됩니다.`,
	mww: `Cua ntsawj ntshab, npaj, in-progress, thiab on-tuav cov kev ua ub no yuav muaj teev nyob ntawm no.`,
	ps: `د پروژې مسوده ، چمتو ، په پرمختګ کې ، او د ساتلو فعالیتونه به دلته لیست شی.`,
	pt: `As atividades de rascunho, prontas, em andamento e em espera serão listadas aqui.`,
	'pt-pt': `As atividades de rascunho, prontas, em andamento e em espera serão listadas aqui.`,
	ru: `Здесь будут перечислены черновые, готовые, выполняемые и отложенные действия.`,
	sm: `O le a lisiina iinei ia faataitaiga, gaoioiga ua saunia, o loo faagasolo, ma gaoioiga o loo taofia.`,
	so: `Qabyo qoraal, diyaar, horumar iyo hawlo kusaabsan ayaa halkan lagu soo koobi doonaa.`,
	th: `กิจกรรมแบบร่าง พร้อม กําลังดําเนินการ และรออยู่จะแสดงรายการที่นี่`,
	ur: `مسودہ، تیار، جاری اور جاری سرگرمیوں کو یہاں درج کیا جائے گا.`,
	vi: `Các hoạt động dự thảo, sẵn sàng, đang tiến hành và tạm dừng sẽ được liệt kê tại đây.`,
	'zh-hans': `此处将列出 Draft、Ready、in-progress 和 on-hold 活动。`,
	'zh-hant': `此處將列出 Draft、Ready、in-progress 和 on-hold 活動。`,
	en: `Draft, ready, in-progress, and on-hold activities will be listed here.`
};

export const loc_closedActivitiesDesc: Record<Locale, string> = {
	ar: `ستظهر الأنشطة المكتملة والملغاة هنا.`,
	de: `Abgeschlossene und stornierte Aktivitäten werden hier angezeigt.`,
	es: `Las actividades completadas y canceladas aparecerán aquí.`,
	fa: `فعالیت های تکمیل شده و لغو شده در اینجا ظاهر می شوند.`,
	fil: `Ang mga nakumpleto at kinansela na aktibidad ay lilitaw dito.`,
	fr: `Les activités terminées et annulées apparaîtront ici.`,
	hi: `पूर्ण और रद्द की गई गतिविधियाँ यहां दिखाई देंगी।`,
	ht: `Ranpli ak anile aktivite anile ap parèt isit la.`,
	it: `Le attività completate e annullate appariranno qui.`,
	ja: `完了したアクティビティとキャンセルされたアクティビティはここに表示されます。`,
	ko: `완료된 활동과 취소된 활동이 여기에 표시됩니다.`,
	mww: `Ua tiav thiab lawm os cov kev ua ub no yuav tshwm sim ntawm no.`,
	ps: `بشپړ شوی او لغوه شوی فعالیتونه به دلته څرګند شی.`,
	pt: `As atividades concluídas e canceladas aparecerão aqui.`,
	'pt-pt': `As atividades concluídas e canceladas aparecerão aqui.`,
	ru: `Здесь будут отображаться завершенные и отмененные активности.`,
	sm: `O gaoioiga ua maea ma ua faaleaogaina o le a faaali mai iinei.`,
	so: `Hawlaha lasoo gabagabeeyay ee baajiyay ayaa halkan kasoo muuqan doona.`,
	th: `กิจกรรมที่เสร็จสมบูรณ์และยกเลิกจะปรากฏที่นี่`,
	ur: `مکمل اور منسوخ شدہ سرگرمیاں یہاں ظاہر ہوں گی۔`,
	vi: `Các hoạt động đã hoàn thành và bị hủy sẽ xuất hiện tại đây.`,
	'zh-hans': `已完成和已取消的活动将显示在此处。`,
	'zh-hant': `已完成和已取消的活動將顯示在此處。`,
	en: `Completed and cancelled activities will appear here.`
};

export const loc_assignedToLogin: Record<Locale, (login: unknown) => TemplateResult> = {
	ar: (login) => html`المخصصة <span>${login}</span>`,
	de: (login) => html`<span>${login} zugewiesen</span>`,
	es: (login) => html`asignado a <span>${login}</span>`,
	fa: (login) => html`به جین اختصاص داده شده است <span>${login}</span>`,
	fil: (login) => html`na assign kay <span>${login}</span>`,
	fr: (login) => html`attribué à <span>${login}</span>`,
	hi: (login) => html`<span>${login} को सौंपा</span>`,
	ht: (login) => html`asiyen nan <span>${login}</span>`,
	it: (login) => html`assegnato a <span>${login}</span>`,
	ja: (login) => html`<span>${login}に割り当てられました</span>`,
	ko: (login) => html`Jane에게 할당됨 <span>${login}</span>`,
	mww: (login) => html`raug xa mus rau <span>${login}</span>`,
	ps: (login) => html`جینس ته لاړ <span>${login}</span>`,
	pt: (login) => html`atribuído a <span>${login}</span>`,
	'pt-pt': (login) => html`atribuído a <span>${login}</span>`,
	ru: (login) => html`закреплено за <span>${login}</span>`,
	sm: (login) => html`tofia ia <span>${login}</span>`,
	so: (login) => html`loo xilsaaray <span>${login}</span>`,
	th: (login) => html`มอบหมายให้ <span>${login}</span>`,
	ur: (login) => html`جین کو تفویض کیا گیا <span>${login}</span>`,
	vi: (login) => html`được giao cho <span>${login}</span>`,
	'zh-hans': (login) => html`分配给 <span>${login}</span>`,
	'zh-hant': (login) => html`分配給 <span>${login}</span>`,
	en: (login) => html`assigned to <span>${login}</span>`
};

export const loc_createdByLogin: Record<Locale, (login: unknown) => TemplateResult> = {
	ar: (login) => html`تم إنشاؤها بواسطة <span>${login}</span>`,
	de: (login) => html`Erstellt von <span>${login}</span>`,
	es: (login) => html`creado por <span>${login}</span>`,
	fa: (login) => html`ایجاد شده توسط <span>${login}</span>`,
	fil: (login) => html`nilikha ni <span>${login}</span>`,
	fr: (login) => html`créé par <span>${login}</span>`,
	hi: (login) => html`जेन द्वारा बनाया गया <span>${login}</span>`,
	ht: (login) => html`kreye pa <span>${login}</span>`,
	it: (login) => html`creato da <span>${login}</span>`,
	ja: (login) => html`作成者 <span>${login}</span>`,
	ko: (login) => html`만든 사람 <span>${login}</span>`,
	mww: (login) => html`Tsim los ntawm <span>${login}</span>`,
	ps: (login) => html`د جین لخوا جوړ شوی <span>${login}</span>`,
	pt: (login) => html`criado por <span>${login}</span>`,
	'pt-pt': (login) => html`criado por <span>${login}</span>`,
	ru: (login) => html`создано <span>${login}</span>`,
	sm: (login) => html`na fatuina e <span>${login}</span>`,
	so: (login) => html`abuuray by <span>${login}</span>`,
	th: (login) => html`สร้างโดย <span>${login}</span>`,
	ur: (login) => html`جین کے ذریعہ تخلیق کیا گیا <span>${login}</span>`,
	vi: (login) => html`tạo bởi <span>${login}</span>`,
	'zh-hans': (login) => html`由 <span>${login} 创建</span>`,
	'zh-hant': (login) => html`由 <span>${login} 建立</span>`,
	en: (login) => html`created by <span>${login}</span>`
};

export const loc_commentCountSingular: Record<Locale, string> = {
	ar: `1 تعليق`,
	de: `1 Kommentar`,
	es: `1 comentario`,
	fa: `1 نظر`,
	fil: `1 komento`,
	fr: `1 commentaire`,
	hi: `1 टिप्पणी`,
	ht: `1 kòmantè`,
	it: `1 commento`,
	ja: `1コメント`,
	ko: `1 코멘트`,
	mww: `1 saib`,
	ps: `1 تبصره`,
	pt: `1 comentário`,
	'pt-pt': `1 comentário`,
	ru: `1 комментарий`,
	sm: `1 faamatalaga`,
	so: `1 comment`,
	th: `1 ความคิดเห็น`,
	ur: `1 تبصرہ`,
	vi: `1 bình luận`,
	'zh-hans': `1评论`,
	'zh-hant': `1評論`,
	en: `1 comment`
};

export const loc_commentCountPlural: Record<Locale, (count: unknown) => string> = {
	ar: (count) => `${count} تعليقات`,
	de: (count) => `${count} Kommentare`,
	es: (count) => `${count} comentarios`,
	fa: (count) => `${count} نظرات`,
	fil: (count) => `${count} mga puna`,
	fr: (count) => `${count} commentaires`,
	hi: (count) => `${count} टिप्पणियाँ`,
	ht: (count) => `${count} kòmantè`,
	it: (count) => `${count} commenti`,
	ja: (count) => `${count} 件のコメント`,
	ko: (count) => `${count} 코멘트`,
	mww: (count) => `${count} lus`,
	ps: (count) => `${count} تبصرې`,
	pt: (count) => `${count} comentários`,
	'pt-pt': (count) => `${count} comentários`,
	ru: (count) => `${count} комментариев`,
	sm: (count) => `${count} faamatalaga`,
	so: (count) => `${count} comments`,
	th: (count) => `${count}`,
	ur: (count) => `${count} تبصرے`,
	vi: (count) => `${count} bình luận`,
	'zh-hans': (count) => `${count} 评论`,
	'zh-hant': (count) => `${count} 評論`,
	en: (count) => `${count} comments`
};

export const loc_dueOnDate: Record<Locale, (dueDate: unknown) => string> = {
	ar: (dueDate) => `موعد التسليم ${dueDate}`,
	de: (dueDate) => `fällig am ${dueDate}`,
	es: (dueDate) => `Fecha de vencimiento el ${dueDate}`,
	fa: (dueDate) => `سررسید در ${dueDate}`,
	fil: (dueDate) => `dapat sa ${dueDate}`,
	fr: (dueDate) => `dû le ${dueDate}`,
	hi: (dueDate) => `${dueDate} को देय`,
	ht: (dueDate) => `akòz sou ${dueDate}`,
	it: (dueDate) => `Scadenza il ${dueDate}`,
	ja: (dueDate) => `${dueDate}に期限`,
	ko: (dueDate) => `마감: ${dueDate}일`,
	mww: (dueDate) => `vim ${dueDate}`,
	ps: (dueDate) => `د ${dueDate}`,
	pt: (dueDate) => `Vencimento em ${dueDate}`,
	'pt-pt': (dueDate) => `Vencimento em ${dueDate}`,
	ru: (dueDate) => `срок сдачи ${dueDate}`,
	sm: (dueDate) => `faagata i le aso ${dueDate}`,
	so: (dueDate) => `sababta on ${dueDate}`,
	th: (dueDate) => `ครบกําหนดวันที่ ${dueDate}`,
	ur: (dueDate) => `${dueDate} کو واجب الادا`,
	vi: (dueDate) => `đáo hạn vào ${dueDate}`,
	'zh-hans': (dueDate) => `截止日期：${dueDate} 日`,
	'zh-hant': (dueDate) => `截止日期：${dueDate}日`,
	en: (dueDate) => `due on ${dueDate}`
};

export const loc_statusAsOfDate: Record<Locale, (status: unknown, statusDate: unknown) => TemplateResult> = {
	ar: (status, statusDate) => html`<span>${status} اعتبارا من ${statusDate}</span>`,
	de: (status, statusDate) => html`<span>${status} ab ${statusDate}</span>`,
	es: (status, statusDate) => html`<span>${status} a partir del ${statusDate}</span>`,
	fa: (status, statusDate) => html`<span>${status} از ${statusDate}</span>`,
	fil: (status, statusDate) => html`<span>${status} noong ${statusDate}</span>`,
	fr: (status, statusDate) => html`<span>${status} ${statusDate}</span>`,
	hi: (statusDate, status) => html`<span>${statusDate} से ${status}</span>`,
	ht: (status, statusDate) => html`<span>${status} nan ${statusDate}</span>`,
	it: (status, statusDate) => html`<span>${status} dal ${statusDate}</span>`,
	ja: (statusDate, status) => html`<span>${statusDate}日現在${status}</span>`,
	ko: (statusDate, status) => html`<span>${statusDate} ${status}</span>`,
	mww: (status, statusDate) => html`<span>${status} raws li ${statusDate}</span>`,
	ps: (statusDate, status) => html`<span>د ${statusDate} ${status}</span>`,
	pt: (status, statusDate) => html`<span>${status} a ${statusDate}</span>`,
	'pt-pt': (status, statusDate) => html`<span>${status} a partir de ${statusDate}</span>`,
	ru: (status, statusDate) => html`<span>${status} с ${statusDate}</span>`,
	sm: (status, statusDate) => html`<span>${status} le ${statusDate}</span>`,
	so: (status, statusDate) => html`<span>${status} yahay sida ${statusDate}</span>`,
	th: (status, statusDate) => html`<span>${status} ณ วันที่ ${statusDate}</span>`,
	ur: (statusDate, status) => html`<span>${statusDate} کو ${status}</span>`,
	vi: (status, statusDate) => html`<span>${status} kể từ ngày ${statusDate}</span>`,
	'zh-hans': (statusDate, status) => html`<span>自 ${statusDate} 日起${status}</span>`,
	'zh-hant': (statusDate, status) => html`<span>自 ${statusDate} 日起${status}</span>`,
	en: (status, statusDate) => html`<span>${status} as of ${statusDate}</span>`
};

export const loc_eventDetails: Record<Locale, string> = {
	ar: `تفاصيل الحدث`,
	de: `Details zur Veranstaltung`,
	es: `Detalles del evento`,
	fa: `جزئیات رویداد`,
	fil: `Mga detalye ng kaganapan`,
	fr: `Détails de l’événement`,
	hi: `स्पर्धा विवरण`,
	ht: `Detay evènman`,
	it: `Dettagli dell'evento`,
	ja: `イベント詳細`,
	ko: `이벤트 세부 정보`,
	mww: `Cov txheej xwm kom paub meej`,
	ps: `د پېښې سپړاوی`,
	pt: `Detalhes do evento`,
	'pt-pt': `Detalhes do evento`,
	ru: `Информация о мероприятии`,
	sm: `Faamatalaga auiliili o le mea na tupu`,
	so: `Faahfaahinta dhacdada`,
	th: `รายละเอียดกิจกรรม`,
	ur: `واقعہ کی تفصیلات`,
	vi: `Chi tiết sự kiện`,
	'zh-hans': `活动详情`,
	'zh-hant': `活動詳情`,
	en: `Event details`
};

export const loc_youCompletedSurveyRecently: Record<Locale, string> = {
	ar: `لقد أكملت هذا الاستطلاع مؤخرا.`,
	de: `Sie haben diese Umfrage vor kurzem abgeschlossen.`,
	es: `Has completado esta encuesta recientemente.`,
	fa: `شما این نظرسنجی را اخیرا تکمیل کرده اید.`,
	fil: `Nakumpleto mo ang survey na ito kamakailan.`,
	fr: `Vous avez récemment répondu à ce sondage.`,
	hi: `आपने हाल ही में इस सर्वेक्षण को पूरा किया है।`,
	ht: `Ou ranpli sondaj sa a dènyèman.`,
	it: `Hai completato questo sondaggio di recente.`,
	ja: `このアンケートは最近完了しました。`,
	ko: `최근에 이 설문조사를 완료하셨습니다.`,
	mww: `Koj ua daim ntawv ntsuam xyuas no tsis ntev los no.`,
	ps: `تاسو پدې وروستیو کې دا څیړنه بشپړه کړه.`,
	pt: `Você completou esta pesquisa recentemente.`,
	'pt-pt': `Concluiu este inquérito recentemente.`,
	ru: `Вы недавно прошли этот опрос.`,
	sm: `Sa e faamaeaina talu ai nei lenei sailiiliga.`,
	so: `Waad dhameysay baadhitaan dhaweyd.`,
	th: `คุณทําแบบสํารวจนี้เสร็จเมื่อเร็ว ๆ นี้`,
	ur: `آپ نے حال ہی میں یہ سروے مکمل کیا ہے.`,
	vi: `Bạn đã hoàn thành khảo sát này gần đây.`,
	'zh-hans': `您最近完成了此调查。`,
	'zh-hant': `您最近完成了此調查。`,
	en: `You completed this survey recently.`
};

export const loc_qrCodeInstructions: Record<Locale, TemplateResult> = {
	ar: html`<ol><li>افتح تطبيق الكاميرا.</li><li>حدد الكاميرا الخلفية في وضع الصور أو الكاميرا.</li><li>قم بتوسيط رمز الاستجابة السريعة على الشاشة وثبت هاتفك لبضع ثوان.</li><li>اضغط على الإشعار المنبثق لفتح الرابط.</li></ol>`,
	de: html`<ol><li>Öffnen Sie die Kamera-App.</li><li>Wählen Sie die Rückfahrkamera im Foto- oder Kameramodus aus.</li><li>Zentrieren Sie den QR-Code auf dem Bildschirm und halten Sie Ihr Telefon einige Sekunden lang ruhig.</li><li>Tippen Sie auf die Benachrichtigung, die angezeigt wird, um den Link zu öffnen.</li></ol>`,
	es: html`<ol><li>Abre la aplicación de la cámara.</li><li>Seleccione la cámara trasera en el modo Foto o Cámara.</li><li>Centra el código QR en la pantalla y mantén el teléfono firme durante un par de segundos.</li><li>Toca la notificación que aparece para abrir el enlace.</li></ol>`,
	fa: html`<ol><li>برنامه دوربین را باز کنید.</li><li>دوربین عقب را در حالت عکس یا دوربین انتخاب کنید.</li><li>کد QR را روی صفحه در مرکز قرار دهید و گوشی خود را برای چند ثانیه ثابت نگه دارید.</li><li>روی اعلانی که باز می شود ضربه بزنید تا پیوند باز شود.</li></ol>`,
	fil: html`<ol><li>Buksan ang camera app.</li><li>Piliin ang camera na nakaharap sa likuran sa Photo o Camera mode.</li><li>Center ang QR code sa screen at hawakan ang iyong telepono matatag para sa isang pares ng mga segundo.</li><li>Tapikin ang notification na pop up upang buksan ang link.</li></ol>`,
	fr: html`<ol><li>Ouvrez l’application appareil photo.</li><li>Sélectionnez l’appareil photo arrière en mode Photo ou Appareil photo.</li><li>Centrez le code QR sur l’écran et maintenez votre téléphone stable pendant quelques secondes.</li><li>Appuyez sur la notification qui s’affiche pour ouvrir le lien.</li></ol>`,
	hi: html`<ol><li>कैमरा ऐप खोलें।</li><li>फ़ोटो या कैमरा मोड में रियर-फ़ेसिंग कैमरा चुनें।</li><li>क्यूआर कोड को स्क्रीन पर केंद्रित करें और अपने फोन को कुछ सेकंड के लिए स्थिर रखें।</li><li>लिंक खोलने के लिए पॉप अप होने वाली सूचना पर टैप करें।</li></ol>`,
	ht: html`<ol><li>Louvri app a kamera.</li><li>Chwazi kamera a re-fè fas a nan Foto oswa mòd Kamera.</li><li>Sant kòd QR sou ekran an epi kenbe telefòn ou fiks pou yon koup la segonn.</li><li>Tape notifikasyon an ki pòp jiska louvri lyen an.</li></ol>`,
	it: html`<ol><li>Apri l'app della fotocamera.</li><li>Selezionare la fotocamera posteriore in modalità Foto o Fotocamera.</li><li>Centra il codice QR sullo schermo e tieni fermo il telefono per un paio di secondi.</li><li>Tocca la notifica che si apre per aprire il link.</li></ol>`,
	ja: html`<ol><li>カメラアプリを開きます。</li><li>写真モードまたはカメラモードで背面カメラを選択します。</li><li>QRコードを画面の中央に配置し、電話を数秒間安定させます。</li><li>ポップアップする通知をタップして、リンクを開きます。</li></ol>`,
	ko: html`<ol><li>카메라 앱을 엽니다.</li><li>사진 또는 카메라 모드에서 후면 카메라를 선택합니다.</li><li>QR 코드를 화면 중앙에 놓고 휴대전화를 몇 초 동안 흔들리지 않게 유지합니다.</li><li>팝업되는 알림을 눌러 링크를 엽니다.</li></ol>`,
	mww: html`<ol><li>Qhib lub koob yees duab app.</li><li>Xaiv lub koob yees duab rear-txojkev koob yees duab hauv Diam duab los yog Koob yees duab hom.</li><li>Center lub QR code ntawm qhov screen thiab tuav koj lub xov tooj khov kho rau ob peb vib nas this.</li><li>Coj mus rhaub daim ntawv ceeb toom tias pops qhib qhov txuas.</li></ol>`,
	ps: html`<ol><li>د کیمرې اپلیکیشن خلاص کړئ.</li><li>په عکس یا کیمرې حالت کې د شا سره مخ کیمره غوره کړئ.</li><li>په سکرین کې د QR کوډ مرکز کړئ او د څو ثانیو لپاره خپل تلیفون ثابت وساتئ.</li><li>په خبرتیا کلیک وکړئ چې د لینک خلاصولو لپاره پاپ اپ کوی.</li></ol>`,
	pt: html`<ol><li>Abra o aplicativo da câmera.</li><li>Selecione a câmera traseira no modo Foto ou Câmera.</li><li>Centralize o código QR na tela e segure o telefone com firmeza por alguns segundos.</li><li>Toque na notificação que aparece para abrir o link.</li></ol>`,
	'pt-pt': html`<ol><li>Abra a aplicação da câmara.</li><li>Selecione a câmara traseira no modo Fotografia ou Câmara.</li><li>Centralize o código QR no ecrã e mantenha o telemóvel estável durante alguns segundos.</li><li>Toque na notificação que aparece para abrir o link.</li></ol>`,
	ru: html`<ol><li>Откройте приложение камеры.</li><li>Выберите заднюю камеру в режиме «Фото» или «Камера».</li><li>Отцентрируйте QR-код на экране и удерживайте телефон в неподвижном положении в течение нескольких секунд.</li><li>Нажмите всплывающее уведомление, чтобы открыть ссылку.</li></ol>`,
	sm: html`<ol><li>Tatala le polokalama o le mea pueata.</li><li>Filifili le mea pueata e faasaga i tua i le tulaga o le Ata po o le Meapueata.</li><li>Ogatotonu le numera faailo QR i luga o le lau ma taofi mau lau telefoni mo ni nai sekone.</li><li>Oomi le faaaliga e oso ae e tatala ai le sootaga.</li></ol>`,
	so: html`<ol><li>Fur barnaamijka kamaraduhu.</li><li>Dooro camera gadaal u jeeda in Photo ama hab Camera.</li><li>Xarunta QR code-ka shaashadda oo telefoonkaaga ku xaji si joogto ah laba ilbidhiqsi.</li><li>Taaba wargelinta in aruurin ilaa in la furo link ah.</li></ol>`,
	th: html`<ol><li>เปิดแอปกล้อง</li><li>เลือกกล้องหลังในโหมดภาพถ่ายหรือกล้อง</li><li>จัดรหัส QR ไว้ตรงกลางหน้าจอและถือโทรศัพท์ของคุณให้นิ่งสักสองสามวินาที</li><li>แตะการแจ้งเตือนที่ปรากฏขึ้นเพื่อเปิดลิงก์</li></ol>`,
	ur: html`<ol><li>کیمرہ ایپ کھولیں۔</li><li>فوٹو یا کیمرہ موڈ میں رئیر فیسنگ کیمرہ منتخب کریں۔</li><li>اسکرین پر کیو آر کوڈ کو مرکز بنائیں اور اپنے فون کو کچھ سیکنڈ کے لئے مستحکم رکھیں۔</li><li>لنک کھولنے کے لئے پاپ اپ ہونے والے نوٹیفکیشن پر ٹیپ کریں۔</li></ol>`,
	vi: html`<ol><li>Mở ứng dụng máy ảnh.</li><li>Chọn camera phía sau ở chế độ Ảnh hoặc Máy ảnh.</li><li>Căn giữa mã QR trên màn hình và giữ điện thoại ổn định trong vài giây.</li><li>Nhấn vào thông báo bật lên để mở liên kết.</li></ol>`,
	'zh-hans': html`<ol><li>打开相机应用程序。</li><li>在 Photo （照片） 或 Camera （相机） 模式下选择后置摄像头。</li><li>将 QR 码在屏幕上居中并稳定手机几秒钟。</li><li>点击弹出的通知以打开链接。</li></ol>`,
	'zh-hant': html`<ol><li>打開相機應用程式。</li><li>在 Photo （照片） 或 Camera （相機） 模式下選擇後置攝像頭。</li><li>將QR碼在螢幕上居中並穩定手機幾秒鐘。</li><li>點擊彈出的通知以打開連結。</li></ol>`,
	en: html`<ol><li>Open the camera app.</li><li>Select the rear-facing camera in Photo or Camera mode.</li><li>Center the QR code on the screen and hold your phone steady for a couple of seconds.</li><li>Tap the notification that pops up to open the link.</li></ol>`
};

export const loc_smallQRCode: Record<Locale, string> = {
	ar: `عرض رمز الاستجابة السريعة الصغير`,
	de: `Kleinen QR-Code anzeigen`,
	es: `Ver código QR pequeño`,
	fa: `مشاهده کد QR کوچک`,
	fil: `Tingnan ang maliit na QR code`,
	fr: `Voir le petit code QR`,
	hi: `छोटा क्यूआर कोड देखें`,
	ht: `Gade ti kòd QR`,
	it: `Visualizza il piccolo codice QR`,
	ja: `小さなQRコードを見る`,
	ko: `작은 QR 코드 보기`,
	mww: `Saib me QR code`,
	ps: `کوچنۍ QR کوډ وګورئ`,
	pt: `Ver pequeno código QR`,
	'pt-pt': `Ver código QR pequeno`,
	ru: `Посмотреть маленький QR-код`,
	sm: `Maimoa i le numera faailo laitiiti QR`,
	so: `Eeg QR code yar`,
	th: `ดูรหัส QR ขนาดเล็ก`,
	ur: `چھوٹا QR کوڈ دیکھیں`,
	vi: `Xem mã QR nhỏ`,
	'zh-hans': `查看小二维码`,
	'zh-hant': `查看小二維碼`,
	en: `View small QR code`
};

export const loc_largeQRCode: Record<Locale, string> = {
	ar: `عرض رمز الاستجابة السريعة الكبير`,
	de: `Großer QR-Code anzeigen`,
	es: `Ver código QR grande`,
	fa: `مشاهده کد QR بزرگ`,
	fil: `Tingnan ang malaking QR code`,
	fr: `Afficher le code QR grand format`,
	hi: `बड़ा क्यूआर कोड देखें`,
	ht: `View gwo kòd QR`,
	it: `Visualizza il codice QR grande`,
	ja: `大きなQRコードを表示する`,
	ko: `대형 QR 코드 보기`,
	mww: `Saib loj QR code`,
	ps: `لوی QR کوډ وګورئ`,
	pt: `Exibir código QR grande`,
	'pt-pt': `Ver código QR grande`,
	ru: `Посмотреть большой QR-код`,
	sm: `Maimoa i le numera faailo tele QR`,
	so: `Eeg QR code weyn`,
	th: `ดูคิวอาร์โค้ดขนาดใหญ่`,
	ur: `بڑا QR کوڈ دیکھیں`,
	vi: `Xem mã QR lớn`,
	'zh-hans': `查看大型二维码`,
	'zh-hant': `查看大型二維碼`,
	en: `View large QR code`
};

export const loc_checkAllThatApply: Record<Locale, string> = {
	ar: `حدد كل ما ينطبق`,
	de: `Markieren Sie alles, was zutrifft`,
	es: `Marque todo lo que corresponda`,
	fa: `همه موارد مربوط به آن را بررسی کنید`,
	fil: `Suriin ang lahat ng naaangkop`,
	fr: `Cochez toutes les réponses qui s’appliquent`,
	hi: `लागू होने वाले सभी की जाँच करें`,
	ht: `Tcheke tout sa ki aplike`,
	it: `Seleziona tutte le risposte pertinenti`,
	ja: `該当するものをすべてチェックしてください`,
	ko: `해당 항목을 모두 체크하세요.`,
	mww: `Kos kom tag nrho cov uas siv tau`,
	ps: `ټول کارول شوی وګورئ`,
	pt: `Marque todas as opções aplicáveis`,
	'pt-pt': `Marque todas as opções aplicáveis`,
	ru: `Отметьте все, что применимо`,
	sm: `Siaki mea uma e faatatau i ai`,
	so: `Hubi in dhan in codsan`,
	th: `ตรวจสอบทั้งหมดที่เกี่ยวข้อง`,
	ur: `لاگو ہونے والے تمام چیزوں کو چیک کریں`,
	vi: `Kiểm tra tất cả những gì áp dụng`,
	'zh-hans': `勾选所有适用项`,
	'zh-hant': `勾選所有適用項`,
	en: `Check all that apply`
};

export const loc_selectOnlyOne: Record<Locale, string> = {
	ar: `اختر واحدا فقط`,
	de: `Wählen Sie nur eine aus`,
	es: `Seleccione solo uno`,
	fa: `فقط یکی را انتخاب کنید`,
	fil: `Pumili lamang ng isa`,
	fr: `N’en sélectionner qu’un seul`,
	hi: `केवल एक का चयन करें`,
	ht: `Chwazi yon sèl`,
	it: `Selezionane solo uno`,
	ja: `1つだけ選択してください`,
	ko: `하나만 선택하십시오.`,
	mww: `Xaiv ib qho xwb`,
	ps: `یوازې یو ټاکل`,
	pt: `Selecione apenas um`,
	'pt-pt': `Selecione apenas um`,
	ru: `Выберите только один`,
	sm: `Filifili na o le tasi`,
	so: `Dalbo mid kaliya`,
	th: `เลือกเพียงหนึ่งรายการ`,
	ur: `صرف ایک منتخب کریں`,
	vi: `Chỉ chọn một`,
	'zh-hans': `仅选一个`,
	'zh-hant': `僅選一個`,
	en: `Select only one`
};

export const loc_dateAtLocation: Record<Locale, (eventDate: unknown, location: unknown) => string> = {
	ar: (eventDate, location) => `${eventDate} في ${location}`,
	de: (eventDate, location) => `${eventDate} in ${location}`,
	es: (eventDate, location) => `${eventDate} en ${location}`,
	fa: (eventDate, location) => `${eventDate} در ${location}`,
	fil: (eventDate, location) => `${eventDate} at ${location}`,
	fr: (eventDate, location) => `${eventDate} au ${location}`,
	hi: (eventDate, location) => `${eventDate} ${location} पर`,
	ht: (eventDate, location) => `${eventDate} nan ${location}`,
	it: (eventDate, location) => `${eventDate} presso ${location}`,
	ja: (eventDate, location) => `${eventDate} 日、 ${location}`,
	ko: (eventDate, location) => `${eventDate} 일 ${location}`,
	mww: (eventDate, location) => `${eventDate} ntawm ${location}`,
	ps: (eventDate, location) => `${eventDate} په ${location}`,
	pt: (eventDate, location) => `${eventDate} em ${location}`,
	'pt-pt': (eventDate, location) => `${eventDate} em ${location}`,
	ru: (eventDate, location) => `${eventDate} по адресу ${location}`,
	sm: (eventDate, location) => `${eventDate} i le ${location}`,
	so: (eventDate, location) => `${eventDate} at ${location}`,
	th: (eventDate, location) => `${eventDate} ที่ ${location}`,
	ur: (eventDate, location) => `${eventDate} ${location}`,
	vi: (eventDate, location) => `${eventDate} tại ${location}`,
	'zh-hans': (eventDate, location) => `${eventDate} 日，地址： ${location}`,
	'zh-hant': (eventDate, location) => `${eventDate} 日，位址： ${location}`,
	en: (eventDate, location) => `${eventDate} at ${location}`
};

export const loc_publicQuestionnairePrintable: Record<Locale, string> = {
	ar: `استبيان عام (قابل للطباعة)`,
	de: `Öffentlicher Fragebogen (druckbar)`,
	es: `Cuestionario público (imprimible)`,
	fa: `پرسشنامه عمومی (قابل چاپ)`,
	fil: `Pampublikong kwestyunaryo (maaaring i print)`,
	fr: `Questionnaire public (imprimable)`,
	hi: `सार्वजनिक प्रश्नावली (मुद्रण योग्य)`,
	ht: `Kesyonè piblik (printable)`,
	it: `Questionario pubblico (stampabile)`,
	ja: `公開アンケート(印刷可能)`,
	ko: `공개 설문지(인쇄 가능)`,
	mww: `Lus nug pej xeem (printable)`,
	ps: `د عامه پوښتنلیک (د چاپ وړ)`,
	pt: `Questionário público (imprimível)`,
	'pt-pt': `Questionário público (imprimível)`,
	ru: `Общедоступная анкета (для печати)`,
	sm: `Fesili mo tagata lautele (e mafai ona lolomiina)`,
	so: `Su'aalaha dadweynaha (printable)`,
	th: `แบบสอบถามสาธารณะ (พิมพ์ได้)`,
	ur: `عوامی سوالنامہ (قابل اشاعت)`,
	vi: `Bảng câu hỏi công khai (có thể in)`,
	'zh-hans': `公共问卷（可打印）`,
	'zh-hant': `公共問卷（可列印）`,
	en: `Public questionnaire (printable)`
};

export const loc_publicAccessToThisQuestionnaire: Record<Locale, string> = {
	ar: `وصول الجمهور إلى هذا الاستبيان`,
	de: `Öffentlicher Zugang zu diesem Fragebogen`,
	es: `Acceso público a este cuestionario`,
	fa: `دسترسی عمومی به این پرسشنامه`,
	fil: `Access ng publiko sa questionnaire na ito`,
	fr: `Accès du public à ce questionnaire`,
	hi: `इस प्रश्नावली तक सार्वजनिक पहुंच`,
	ht: `Aksè piblik nan kesyonè sa a`,
	it: `Accesso del pubblico a questo questionario`,
	ja: `このアンケートへの一般公開`,
	ko: `이 설문지에 대한 공개 액세스`,
	mww: `Pej xeem mus saib tau lo lus nug no`,
	ps: `دې پوښتنلیک ته د خلکو لاس رسی`,
	pt: `Acesso do público a este questionário`,
	'pt-pt': `Acesso do público a este questionário`,
	ru: `Открытый доступ к данной анкете`,
	sm: `Faaaogaina e tagata lautele o lenei aluese`,
	so: `Galitaanka dadweynaha ee su'aalahan`,
	th: `การเข้าถึงแบบสอบถามนี้โดยสาธารณะ`,
	ur: `اس سوالنامے تک عوام کی رسائی`,
	vi: `Truy cập công khai vào bảng câu hỏi này`,
	'zh-hans': `此调查问卷的公众访问权限`,
	'zh-hant': `此調查問卷的公眾訪問許可權`,
	en: `Public access to this questionnaire`
};

export const loc_associatedActivities: Record<Locale, string> = {
	ar: `الأنشطة المرتبطة`,
	de: `Zugehörige Aktivitäten`,
	es: `Actividades asociadas`,
	fa: `فعالیت های مرتبط`,
	fil: `Mga kaugnay na aktibidad`,
	fr: `Activités associées`,
	hi: `संबद्ध गतिविधियाँ`,
	ht: `Aktivite ki asosye`,
	it: `Attività associate`,
	ja: `関連活動`,
	ko: `관련 활동`,
	mww: `Kev ua ub no`,
	ps: `اړوند فعالیتونه`,
	pt: `Atividades associadas`,
	'pt-pt': `Atividades associadas`,
	ru: `Сопутствующие мероприятия`,
	sm: `Gaoioiga e fesootai ai`,
	so: `Shaqooyinyada la xidhiidha`,
	th: `กิจกรรมที่เกี่ยวข้อง`,
	ur: `متعلقہ سرگرمیاں`,
	vi: `Hoạt động liên quan`,
	'zh-hans': `关联活动`,
	'zh-hant': `關聯活動`,
	en: `Associated activities`
};

export const loc_noAssociatedActivities: Record<Locale, string> = {
	ar: `لا توجد أنشطة مرتبطة`,
	de: `Es gibt keine zugehörigen Aktivitäten`,
	es: `No hay actividades asociadas`,
	fa: `هیچ فعالیت مرتبطی وجود ندارد`,
	fil: `Walang mga kaugnay na aktibidad`,
	fr: `Il n’y a pas d’activités associées`,
	hi: `कोई संबद्ध गतिविधियाँ नहीं हैं`,
	ht: `Pa gen okenn aktivite ki asosye`,
	it: `Non ci sono attività associate`,
	ja: `関連するアクティビティはありません`,
	ko: `연결된 활동이 없습니다.`,
	mww: `Tsis muaj ib yam kev ua ub no uas ua ub no`,
	ps: `هیڅ اړونده فعالیتونه شتون نلری`,
	pt: `Não há atividades associadas`,
	'pt-pt': `Não existem atividades associadas`,
	ru: `Никаких связанных действий нет`,
	sm: `E leai ni gaoioiga e fesootai ai`,
	so: `Mana jiro wax lala wadaajinayo.`,
	th: `ไม่มีกิจกรรมที่เกี่ยวข้อง`,
	ur: `کوئی متعلقہ سرگرمیاں نہیں ہیں`,
	vi: `Không có hoạt động liên quan`,
	'zh-hans': `没有关联的活动`,
	'zh-hant': `沒有關聯的活動`,
	en: `There are no associated activities`
};

export const loc_noAssociatedActivitiesOnThisPage: Record<Locale, string> = {
	ar: `لا توجد أنشطة مرتبطة في هذه الصفحة`,
	de: `Es gibt keine zugehörigen Aktivitäten auf dieser Seite`,
	es: `No hay actividades asociadas en esta página`,
	fa: `هیچ فعالیت مرتبطی در این صفحه وجود ندارد`,
	fil: `Walang mga kaugnay na aktibidad sa pahinang ito`,
	fr: `Il n’y a pas d’activités associées sur cette page`,
	hi: `इस पृष्ठ पर कोई संबद्ध गतिविधियाँ नहीं हैं`,
	ht: `Pa gen okenn aktivite ki asosye nan paj sa a`,
	it: `Non ci sono attività associate in questa pagina`,
	ja: `このページには関連するアクティビティはありません`,
	ko: `이 페이지에는 관련 활동이 없습니다.`,
	mww: `Tsis muaj ib yam kev ua ub no rau ntawm nplooj ntawv no`,
	ps: `پدې ویب پا onه کې هیڅ اړونده فعالیتونه شتون نلری`,
	pt: `Não existem atividades associadas nesta página`,
	'pt-pt': `Não há atividades associadas nesta página`,
	ru: `На этой странице нет связанных действий`,
	sm: `E leai ni gaoioiga e fesootai i lenei itulau`,
	so: `Boggan kuma jiro wax hawlo ah oo lala xiriirinayo.`,
	th: `ไม่มีกิจกรรมที่เกี่ยวข้องในหน้านี้`,
	ur: `اس صفحے پر کوئی متعلقہ سرگرمیاں نہیں ہیں`,
	vi: `Không có hoạt động liên quan nào trên trang này`,
	'zh-hans': `此页面上没有关联的活动`,
	'zh-hant': `此頁面上沒有關聯的活動`,
	en: `There are no associated activities on this page`
};

export const loc_organizationMember: Record<Locale, string> = {
	ar: `عضو المنظمة`,
	de: `Mitglied der Organisation`,
	es: `Miembro de la organización`,
	fa: `عضو سازمان`,
	fil: `Miyembro ng organisasyon`,
	fr: `Membre de l’organisation`,
	hi: `संगठन के सदस्य`,
	ht: `manm òganizasyon`,
	it: `Membro dell'organizzazione`,
	ja: `組織メンバー`,
	ko: `조직 구성원`,
	mww: `Koom haum`,
	ps: `عضویت در انجمن`,
	pt: `Membro da organização`,
	'pt-pt': `Membro da organização`,
	ru: `Член организации`,
	sm: `Tagata o le faalapotopotoga`,
	so: `xubin ka ah Ururka`,
	th: `สมาชิกองค์กร`,
	ur: `تنظیم کے رکن`,
	vi: `Thành viên tổ chức`,
	'zh-hans': `组织成员`,
	'zh-hant': `組織成員`,
	en: `Organization member`
};

export const loc_inviteAMemberOf: Record<Locale, (login: unknown) => string> = {
	ar: (login) => `دعوة عضو في ${login}`,
	de: (login) => `Laden Sie ein Mitglied von ${login} ein`,
	es: (login) => `Invitar a un miembro de ${login}`,
	fa: (login) => `دعوت از عضوی ${login}`,
	fil: (login) => `Mag imbita ng isang miyembro ng ${login}`,
	fr: (login) => `Invitez un membre de ${login}`,
	hi: (login) => `${login} के सदस्य को आमंत्रित करें`,
	ht: (login) => `Envite yon manm nan ${login}`,
	it: (login) => `Invita un membro di ${login}`,
	ja: (login) => `${login}のメンバーを招待する`,
	ko: (login) => `${login} 회원 초대`,
	mww: (login) => `Caw ib tug nyob hauv ${login}`,
	ps: (login) => `د یو غړی څخه د کار کولو لپاره ${login}`,
	pt: (login) => `Convide um membro da ${login}`,
	'pt-pt': (login) => `Convide um membro da ${login}`,
	ru: (login) => `Пригласите участника ${login}`,
	sm: (login) => `Valaaulia se tagata o le ${login}`,
	so: (login) => `Ku martiqaad xubin ka mid ah ${login}`,
	th: (login) => `เชิญสมาชิกของ ${login}`,
	ur: (login) => `${login} کے ایک رکن کو مدعو کریں`,
	vi: (login) => `Mời một thành viên của ${login}`,
	'zh-hans': (login) => `邀请 ${login} 成员`,
	'zh-hant': (login) => `邀請${login}成員`,
	en: (login) => `Invite a member of ${login}`
};

export const loc_providedByOrgViaProgram: Record<Locale, (org: unknown, project: unknown) => TemplateResult> = {
	ar: (org, project) => html`<span>مقدمة من ${org} من خلال مشروع ${project} .</span>`,
	de: (org, project) => html`<span>Zur Verfügung gestellt von ${org} im Rahmen des ${project} .</span>`,
	es: (org, project) => html`<span>Proporcionado por ${org} a través del proyecto ${project} .</span>`,
	fa: (org, project) => html`<span>ارائه شده توسط ${org} از طریق پروژه ${project} .</span>`,
	fil: (org, project) => html`<span>Ibinigay ng ${org} sa pamamagitan ng proyekto ng ${project} .</span>`,
	fr: (org, project) => html`<span>Fourni par ${org} dans le cadre du ${project} .</span>`,
	hi: (project, org) => html`<span>${project} परियोजना के माध्यम से ${org} द्वारा प्रदान किया गया।</span>`,
	ht: (org, project) => html`<span>Bay pa ${org} atravè ${project} a.</span>`,
	it: (org, project) => html`<span>Fornito da ${org} attraverso il progetto ${project} .</span>`,
	ja: (org, project) => html`<span>${org}が${project}プロジェクトを通じて提供。</span>`,
	ko: (org, project) => html`<span>${org})이 ${project}) 프로젝트를 통해 제공합니다.</span>`,
	mww: (org, project) => html`<span>Muab los ntawm ${org} los ntawm lub ${project} project.</span>`,
	ps: (project, org) => html`<span>د ${project} له لارې د ${org} لخوا چمتو شوی.</span>`,
	pt: (org, project) => html`<span>Fornecido por ${org} através do projeto ${project} .</span>`,
	'pt-pt': (org, project) => html`<span>Fornecido pela ${org} através do projeto ${project} .</span>`,
	ru: (org, project) => html`<span>Предоставлено ${org} в рамках проекта ${project} .</span>`,
	sm: (org, project) => html`<span>Saunia e ${org} e ala i le galuega faatino o le ${project} .</span>`,
	so: (org, project) => html`<span>Waxaa bixiya ${org} iyada oo loo marayo ${project} .</span>`,
	th: (org, project) => html`<span>จัดทําโดย ${org} ผ่านโครงการ ${project} Project</span>`,
	ur: (project, org) => html`<span>${project} کے ذریعے ${org} کی طرف سے فراہم کی گئی۔</span>`,
	vi: (org, project) => html`<span>Được cung cấp bởi ${org} thông qua dự án ${project} .</span>`,
	'zh-hans': (org, project) => html`<span>由 ${org} 通过 ${project} 项目提供。</span>`,
	'zh-hant': (org, project) => html`<span>由 ${org} 透過 ${project} 專案提供。</span>`,
	en: (org, project) => html`<span>Provided by ${org} through the ${project} project.</span>`
};

export const loc_noResultsMatchedSearch: Record<Locale, string> = {
	ar: `لا توجد نتائج مطابقة لبحثك.`,
	de: `Es gab keine Ergebnisse, die mit Ihrer Suche übereinstimmten.`,
	es: `No hay resultados que coincidan con su búsqueda.`,
	fa: `هیچ نتیجه ای با جستجوی شما مطابقت نداشت.`,
	fil: `Walang mga resulta na tumutugma sa iyong paghahanap.`,
	fr: `Aucun résultat ne correspondait à votre recherche.`,
	hi: `आपकी खोज से कोई परिणाम मेल नहीं खाता.`,
	ht: `Pa gen rezilta matche ak rechèch ou an.`,
	it: `Nessun risultato corrisponde alla tua ricerca.`,
	ja: `検索条件に一致する結果が見つかりませんでした。`,
	ko: `검색어와 일치하는 결과가 없습니다.`,
	mww: `Tsis tau matched koj nrhiav.`,
	ps: `هیڅ نتیجه ستاسو د لټون سره سمون نه لری.`,
	pt: `Nenhum resultado correspondeu à sua pesquisa.`,
	'pt-pt': `Nenhum resultado correspondeu à sua pesquisa.`,
	ru: `Ни один результат не соответствует вашему запросу.`,
	sm: `E leai ni taunuuga e fetaui ma lau sailiga.`,
	so: `No natiijada waafqsanayd raadintaada.`,
	th: `ไม่มีผลลัพธ์ที่ตรงกับการค้นหาของคุณ`,
	ur: `کوئی نتیجہ آپ کی تلاش سے میل نہیں کھاتا۔`,
	vi: `Không có kết quả nào khớp với tìm kiếm của bạn.`,
	'zh-hans': `没有与您的搜索匹配的结果。`,
	'zh-hant': `沒有與您的搜索匹配的結果。`,
	en: `No results matched your search.`
};

export const loc_parentActivity: Record<Locale, string> = {
	ar: `نشاط الوالدين`,
	de: `Übergeordnete Aktivität`,
	es: `Actividad de los padres`,
	fa: `فعالیت والدین`,
	fil: `Aktibidad ng magulang`,
	fr: `Activité des parents`,
	hi: `अभिभावक गतिविधि`,
	ht: `Aktivite paran`,
	it: `Attività padre`,
	ja: `親アクティビティ`,
	ko: `학부모 활동`,
	mww: `Niam txiv kev ua niam txiv`,
	ps: `د پلرین فعالیت`,
	pt: `Atividade dos pais`,
	'pt-pt': `Atividade dos pais`,
	ru: `Активность родителей`,
	sm: `Gaoioiga a matua`,
	so: `Howlaha waalidka`,
	th: `กิจกรรมผู้ปกครอง`,
	ur: `والدین کی سرگرمی`,
	vi: `Hoạt động của phụ huynh`,
	'zh-hans': `父活动`,
	'zh-hant': `父活動`,
	en: `Parent activity`
};

export const loc_singularOrganization: Record<Locale, string> = {
	ar: `1 منظمة`,
	de: `1 Organisation`,
	es: `1 organización`,
	fa: `1 سازمان`,
	fil: `1 Organisasyon`,
	fr: `1 organisation`,
	hi: `1 संगठन`,
	ht: `1 òganizasyon`,
	it: `1 organizzazione`,
	ja: `1団体`,
	ko: `1개 단체`,
	mww: `1 lub koom haum`,
	ps: `1 سازمان`,
	pt: `1 organização`,
	'pt-pt': `1 organização`,
	ru: `1 организация`,
	sm: `1 faalapotopotoga`,
	so: `1 ururka`,
	th: `1 องค์กร`,
	ur: `1 تنظیم`,
	vi: `1 Tổ chức`,
	'zh-hans': `1 个组织`,
	'zh-hant': `1 個組織`,
	en: `1 organization`
};

export const loc_multipleOrganizations: Record<Locale, (count: unknown) => string> = {
	ar: (count) => `${count} منظمات`,
	de: (count) => `${count} Organisationen`,
	es: (count) => `${count} organizaciones`,
	fa: (count) => `${count} سازمان`,
	fil: (count) => `${count} mga organisasyon`,
	fr: (count) => `${count} organismes`,
	hi: (count) => `${count} संगठन`,
	ht: (count) => `${count} òganizasyon`,
	it: (count) => `${count} organizzazioni`,
	ja: (count) => `${count} 団体`,
	ko: (count) => `${count} 개 단체`,
	mww: (count) => `${count} lub koom haum`,
	ps: (count) => `${count} سازمانونه`,
	pt: (count) => `${count} organizações`,
	'pt-pt': (count) => `${count} organizações`,
	ru: (count) => `${count} организаций`,
	sm: (count) => `${count} faalapotopotoga`,
	so: (count) => `${count} ururo`,
	th: (count) => `${count} องค์กร`,
	ur: (count) => `${count} تنظیمیں`,
	vi: (count) => `${count} tổ chức`,
	'zh-hans': (count) => `${count} 个组织`,
	'zh-hant': (count) => `${count} 個組織`,
	en: (count) => `${count} organizations`
};

export const loc_attachment: Record<Locale, string> = {
	ar: `مرفق`,
	de: `Anhang`,
	es: `Archivo adjunto`,
	fa: `پیوست`,
	fil: `Attachment`,
	fr: `Attachement`,
	hi: `संलग्नक`,
	ht: `Atachman`,
	it: `Allegato`,
	ja: `愛着`,
	ko: `첨부 파일`,
	mww: `rhais nrog daim`,
	ps: `نښلن`,
	pt: `Anexo`,
	'pt-pt': `Acessório`,
	ru: `Прикрепление`,
	sm: `Faapipiiina`,
	so: `Lifaaqa`,
	th: `การผูกติด`,
	ur: `منسلکہ`,
	vi: `Đính kèm`,
	'zh-hans': `附件`,
	'zh-hant': `附件`,
	en: `Attachment`
};

export const loc_attachments: Record<Locale, string> = {
	ar: `المرفقات`,
	de: `Anhängsel`,
	es: `Accesorios`,
	fa: `پیوست`,
	fil: `Mga Attachment`,
	fr: `Pièces jointes`,
	hi: `अनुलग्नक`,
	ht: `Atachman`,
	it: `Allegati`,
	ja: `添付 ファイル`,
	ko: `첨부 파일`,
	mww: `Daim ntawv rhais nrog rau daim`,
	ps: `نښلن`,
	pt: `Anexos`,
	'pt-pt': `Anexos`,
	ru: `Вложения`,
	sm: `O Mea e Faapipii i Ai`,
	so: `Lifaaqyada`,
	th: `เอกสาร แนบ`,
	ur: `منسلکات`,
	vi: `Đính kèm`,
	'zh-hans': `附件`,
	'zh-hant': `附件`,
	en: `Attachments`
};

export const loc_singularAttachment: Record<Locale, string> = {
	ar: `1 مرفق`,
	de: `1 Anhang`,
	es: `1 archivo adjunto`,
	fa: `1 پیوست`,
	fil: `1 attachment`,
	fr: `1 pièce jointe`,
	hi: `1 संलग्नक`,
	ht: `1 atachman`,
	it: `1 allegato`,
	ja: `1つの添付ファイル`,
	ko: `1 첨부 파일`,
	mww: `1 attachment`,
	ps: `1 نښلن`,
	pt: `1 acessório`,
	'pt-pt': `1 anexo`,
	ru: `1 вложение`,
	sm: `1 faapipii`,
	so: `1 lifaaqa`,
	th: `1 ไฟล์แนบ`,
	ur: `1 اٹیچمنٹ`,
	vi: `1 Tệp đính kèm`,
	'zh-hans': `1 个附件`,
	'zh-hant': `1 個附件`,
	en: `1 attachment`
};

export const loc_multipleAttachments: Record<Locale, (count: unknown) => string> = {
	ar: (count) => `${count} مرفقات`,
	de: (count) => `${count} Anhänge`,
	es: (count) => `${count} archivos adjuntos`,
	fa: (count) => `${count} پیوست`,
	fil: (count) => `${count} Mga Attachment`,
	fr: (count) => `${count} pièces jointes`,
	hi: (count) => `${count} संलग्नक`,
	ht: (count) => `${count} atachman`,
	it: (count) => `${count} allegati`,
	ja: (count) => `${count} つの添付ファイル`,
	ko: (count) => `${count} 첨부 파일`,
	mww: (count) => `${count} attachments`,
	ps: (count) => `${count} نښلن`,
	pt: (count) => `${count} acessórios`,
	'pt-pt': (count) => `${count} anexos`,
	ru: (count) => `${count} вложений`,
	sm: (count) => `${count} faapipii`,
	so: (count) => `${count} lifaaqyada`,
	th: (count) => `${count} ไฟล์แนบ`,
	ur: (count) => `${count} منسلکات`,
	vi: (count) => `${count} tệp đính kèm`,
	'zh-hans': (count) => `${count} 附件`,
	'zh-hant': (count) => `${count} 附件`,
	en: (count) => `${count} attachments`
};

export const loc_singularEarlierVersion: Record<Locale, string> = {
	ar: `1 إصدار سابق`,
	de: `1 frühere Version`,
	es: `1 versión anterior`,
	fa: `1 نسخه قبلی`,
	fil: `1 naunang bersyon`,
	fr: `1 version antérieure`,
	hi: `1 पूर्व संस्करण`,
	ht: `1 vèsyon pi bonè`,
	it: `1 versione precedente`,
	ja: `1 以前のバージョン`,
	ko: `1 이전 버전`,
	mww: `1 ua ntej lawm version`,
	ps: `1 مخکینی نسخه`,
	pt: `1 versão anterior`,
	'pt-pt': `1 versão anterior`,
	ru: `1 более ранняя версия`,
	sm: `1 lomiga muamua`,
	so: `1 version hore`,
	th: `1 เวอร์ชันก่อนหน้า`,
	ur: `1 سابقہ ورژن`,
	vi: `1 Phiên bản trước đó`,
	'zh-hans': `1 个早期版本`,
	'zh-hant': `1 個早期版本`,
	en: `1 earlier version`
};

export const loc_multipleEarlierVersions: Record<Locale, (count: unknown) => string> = {
	ar: (count) => `${count} الإصدارات السابقة`,
	de: (count) => `${count} frühere Versionen`,
	es: (count) => `${count} versiones anteriores`,
	fa: (count) => `${count} نسخه قبلی`,
	fil: (count) => `${count} mas naunang mga bersyon`,
	fr: (count) => `${count} versions antérieures`,
	hi: (count) => `${count} पुराने संस्करण`,
	ht: (count) => `${count} vèsyon pi bonè`,
	it: (count) => `${count} versioni precedenti`,
	ja: (count) => `${count} 以前のバージョン`,
	ko: (count) => `${count} 이전 버전`,
	mww: (count) => `${count} ua ntej lawm versions`,
	ps: (count) => `${count} پخوانۍ نسخې`,
	pt: (count) => `${count} versões anteriores`,
	'pt-pt': (count) => `${count} versões anteriores`,
	ru: (count) => `${count} Более ранние версии`,
	sm: (count) => `${count} lomiga na muamua atu`,
	so: (count) => `${count} noocyada hore`,
	th: (count) => `${count} เวอร์ชันก่อนหน้า`,
	ur: (count) => `${count} پرانے ورژن`,
	vi: (count) => `${count} Các phiên bản trước đó`,
	'zh-hans': (count) => `${count} 早期版本`,
	'zh-hant': (count) => `${count} 早期版本`,
	en: (count) => `${count} earlier versions`
};

export const loc_docs: Record<Locale, string> = {
	ar: `مستندات`,
	de: `Doktoren`,
	es: `Docs`,
	fa: `اسناد`,
	fil: `Mga Dok`,
	fr: `Docs`,
	hi: `डॉक्स`,
	ht: `Dòk`,
	it: `Documenti`,
	ja: `ドキュメント`,
	ko: `문서`,
	mww: `Docs`,
	ps: `لاسوندونه`,
	pt: `Docs`,
	'pt-pt': `Documentos`,
	ru: `Документы`,
	sm: `Docs`,
	so: `Docs`,
	th: `เอกสาร`,
	ur: `Docs`,
	vi: `Documents`,
	'zh-hans': `文档`,
	'zh-hant': `文件`,
	en: `Docs`
};

export const loc_history: Record<Locale, string> = {
	ar: `تاريخ`,
	de: `Geschichte`,
	es: `Historia`,
	fa: `تاریخ`,
	fil: `Kasaysayan`,
	fr: `Histoire`,
	hi: `इतिहास`,
	ht: `Istwa`,
	it: `Storia`,
	ja: `歴史`,
	ko: `역사`,
	mww: `keeb kwm`,
	ps: `مخینه`,
	pt: `História`,
	'pt-pt': `História`,
	ru: `История`,
	sm: `Talafaasolopito`,
	so: `Taariikhda`,
	th: `ประวัติศาสตร์`,
	ur: `تاریخ`,
	vi: `Lịch sử`,
	'zh-hans': `历史`,
	'zh-hant': `歷史`,
	en: `History`
};

export const loc_top: Record<Locale, string> = {
	ar: `أعلى`,
	de: `Nach oben`,
	es: `Arriba`,
	fa: `بالا`,
	fil: `Tuktok`,
	fr: `Retour au début`,
	hi: `लट्टू`,
	ht: `tèt`,
	it: `In alto`,
	ja: `ページのトップへ`,
	ko: `맨 위로`,
	mww: `saum toj kawg nkaus`,
	ps: `بره`,
	pt: `Início`,
	'pt-pt': `Início`,
	ru: `Вверх`,
	sm: `Luga`,
	so: `Hore`,
	th: `ด้านบน`,
	ur: `چوٹی`,
	vi: `Đỉnh`,
	'zh-hans': `返回页首`,
	'zh-hant': `返回頁首`,
	en: `Top`
};

export const loc_responses: Record<Locale, string> = {
	ar: `الاستجابات`,
	de: `Antworten`,
	es: `Respuestas`,
	fa: `پاسخ`,
	fil: `Mga Tugon`,
	fr: `Réponses`,
	hi: `जवाब`,
	ht: `Repons`,
	it: `Risposte`,
	ja: `応答`,
	ko: `응답`,
	mww: `Responses`,
	ps: `غبرګونونه`,
	pt: `Respostas`,
	'pt-pt': `Respostas`,
	ru: `Ответы`,
	sm: `Tali`,
	so: `Jawaabaha`,
	th: `ตอบ สนอง`,
	ur: `جوابات`,
	vi: `Phản ứng`,
	'zh-hans': `反应`,
	'zh-hant': `反應`,
	en: `Responses`
};

export const loc_dates: Record<Locale, string> = {
	ar: `بلح`,
	de: `Datteln`,
	es: `Fechas`,
	fa: `تاریخ`,
	fil: `Mga Petsa`,
	fr: `Dattes`,
	hi: `दिनांकों`,
	ht: `Dat`,
	it: `Datteri`,
	ja: `日付`,
	ko: `날짜`,
	mww: `Hnub Tim`,
	ps: `نېټې`,
	pt: `Datas`,
	'pt-pt': `Datas`,
	ru: `Финики`,
	sm: `Aso`,
	so: `Taariikhda`,
	th: `วัน`,
	ur: `تاریخوں`,
	vi: `Ngày`,
	'zh-hans': `日期`,
	'zh-hant': `日期`,
	en: `Dates`
};

export const loc_createdThisActivity: Record<Locale, string> = {
	ar: `إنشاء هذا النشاط.`,
	de: `Sie haben diese Aktivität erstellt.`,
	es: `Creó esta actividad.`,
	fa: `این فعالیت را ایجاد کرد.`,
	fil: `Nilikha ang aktibidad na ito.`,
	fr: `J’ai créé cette activité.`,
	hi: `यह गतिविधि बनाई गई.`,
	ht: `Te kreye aktivite sa a.`,
	it: `Creata questa attività.`,
	ja: `このアクティビティを作成しました。`,
	ko: `이 활동을 만들었습니다.`,
	mww: `Tsim kom muaj txoj kev ua no.`,
	ps: `دا فعالیت رامینځته کړی.`,
	pt: `Criou esta atividade.`,
	'pt-pt': `Criou esta atividade.`,
	ru: `Создано это упражнение.`,
	sm: `Na fatuina lenei gaoioiga.`,
	so: `Howshaan abuurisey.`,
	th: `สร้างกิจกรรมนี้`,
	ur: `اس سرگرمی کو تخلیق کیا.`,
	vi: `Đã tạo hoạt động này.`,
	'zh-hans': `创建了此活动。`,
	'zh-hant': `創建了此活動。`,
	en: `Created this activity.`
};

export const loc_hasAssignedMembers: Record<Locale, string> = {
	ar: `لديه أعضاء تم تعيينهم لهذا النشاط.`,
	de: `Verfügt über Mitglieder, die dieser Aktivität zugewiesen wurden.`,
	es: `Tiene miembros que fueron asignados a esta actividad.`,
	fa: `دارای اعضایی است که به این فعالیت اختصاص داده شده اند.`,
	fil: `May mga miyembrong naatasan sa aktibidad na ito.`,
	fr: `A des membres qui ont été affectés à cette activité.`,
	hi: `ऐसे सदस्य हैं जिन्हें इस गतिविधि के लिए असाइन किया गया था.`,
	ht: `Gen manm ki te asiyen nan aktivite sa a.`,
	it: `Contiene membri assegnati a questa attività.`,
	ja: `このアクティビティに割り当てられたメンバーがいます。`,
	ko: `이 활동에 배정된 구성원이 있습니다.`,
	mww: `Cov neeg uas raug txib mus ua qhov kev ua no.`,
	ps: `هغه غړی چې د دې فعالیت لپاره ګمارل شوی دی.`,
	pt: `Tem membros que foram designados para esta atividade.`,
	'pt-pt': `Tem membros que foram designados para esta atividade.`,
	ru: `Имеет участников, назначенных для выполнения этого задания.`,
	sm: `E i ai tagata o le ekalesia na tofia i lenei gaoioiga.`,
	so: `ayaa waxaa ka mid ah xubno loo magacaabay howshan.`,
	th: `มีสมาชิกที่ได้รับมอบหมายให้ทํากิจกรรมนี้`,
	ur: `ایسے ارکان ہیں جو اس سرگرمی کے لئے تفویض کیے گئے تھے۔`,
	vi: `Có các thành viên được chỉ định cho hoạt động này.`,
	'zh-hans': `具有分配给此活动的成员。`,
	'zh-hant': `具有分配給此活動的成員。`,
	en: `Has members who were assigned to this activity.`
};

export const loc_hasMentionedMembers: Record<Locale, string> = {
	ar: `لديه أعضاء تم ذكرهم في هذا النشاط.`,
	de: `Hat Mitglieder, die in dieser Aktivität erwähnt wurden.`,
	es: `Tiene miembros que fueron mencionados en esta actividad.`,
	fa: `دارای اعضایی است که در این فعالیت از آنها نام برده شده است.`,
	fil: `Ay may mga miyembro na nabanggit sa aktibidad na ito.`,
	fr: `A des membres qui ont été mentionnés dans cette activité.`,
	hi: `इस गतिविधि में जिन सदस्यों का उल्लेख किया गया था।`,
	ht: `Gen manm yo ki te mansyone nan aktivite sa a.`,
	it: `Ha membri che sono stati menzionati in questa attività.`,
	ja: `このアクティビティでメンションされたメンバーがいる。`,
	ko: `이 활동에서 언급된 회원이 있습니다.`,
	mww: `Muaj cov tswv cuab uas tau hais nyob rau hauv qhov kev ua no.`,
	ps: `هغه کسان چې په دې غونډه کې یې برخه درلوده، خبرې وکړې.`,
	pt: `Tem membros que foram mencionados nesta atividade.`,
	'pt-pt': `Tem membros que foram mencionados nesta atividade.`,
	ru: `Имеет участников, которые были упомянуты в этом упражнении.`,
	sm: `E i ai tagata o le ekalesia na ta'ua i lenei gaoioiga.`,
	so: `ayaa waxaa ka mid ah xubno ku xusan howshaan.`,
	th: `มีสมาชิกที่ถูกกล่าวถึงในกิจกรรมนี้`,
	ur: `اس سرگرمی میں جن ممبروں کا ذکر کیا گیا تھا۔`,
	vi: `Có các thành viên đã được đề cập trong hoạt động này.`,
	'zh-hans': `拥有此活动中提及的成员。`,
	'zh-hant': `擁有此活動中提及的成員。`,
	en: `Has members who were mentioned in this activity.`
};

export const loc_supervisesTheProject: Record<Locale, (project: unknown) => string> = {
	ar: (project) => `يشرف على مشروع ${project} .`,
	de: (project) => `Betreut das Projekt ${project} .`,
	es: (project) => `Supervisa el proyecto de ${project} .`,
	fa: (project) => `بر پروژه ${project} نظارت می کند.`,
	fil: (project) => `Nangangasiwa sa proyektong ${project} .`,
	fr: (project) => `Supervise le projet de ${project} .`,
	hi: (project) => `${project} परियोजना का पर्यवेक्षण करता है।`,
	ht: (project) => `Sipèvize pwojè ${project} la.`,
	it: (project) => `Supervisiona il progetto di ${project} .`,
	ja: (project) => `${project}プロジェクトを監督します。`,
	ko: (project) => `${project} 프로젝트를 감독합니다.`,
	mww: (project) => `Saib xyuas qhov ${project} peb tes num.`,
	ps: (project) => `د ${project} پروژې څارنه وکړئ.`,
	pt: (project) => `Supervisiona o projeto ${project} .`,
	'pt-pt': (project) => `Supervisiona o projeto ${project} .`,
	ru: (project) => `Курирует проект ${project} .`,
	sm: (project) => `Vaavaai le galuega faatino o ${project} .`,
	so: (project) => `Wuxuu ilaaliyo mashruuca ${project} .`,
	th: (project) => `ดูแลโครงการ${project}`,
	ur: (project) => `${project} منصوبے کی نگرانی کرتا ہے۔`,
	vi: (project) => `Giám sát dự án ${project} .`,
	'zh-hans': (project) => `监督 ${project} 项目。`,
	'zh-hant': (project) => `監督 ${project} 專案。`,
	en: (project) => `Supervises the ${project} project.`
};

export const loc_supervisesAnOrganization: Record<Locale, string> = {
	ar: `يشرف على منظمة مشاركة في هذا النشاط.`,
	de: `Beaufsichtigt eine Organisation, die an dieser Aktivität teilnimmt.`,
	es: `Supervisa una organización que participa en esta actividad.`,
	fa: `بر سازمانی که در این فعالیت شرکت می کند نظارت می کند.`,
	fil: `Nangangasiwa sa isang organisasyong nakikibahagi sa aktibidad na ito.`,
	fr: `Supervise une organisation participant à cette activité.`,
	hi: `इस गतिविधि में भाग लेने वाले संगठन का पर्यवेक्षण करता है।`,
	ht: `Sipèvize yon òganizasyon ki patisipe nan aktivite sa a.`,
	it: `Supervisiona un'organizzazione che partecipa a questa attività.`,
	ja: `この活動に参加する組織を監督します。`,
	ko: `이 활동에 참여하는 조직을 감독합니다.`,
	mww: `Saib xyuas ib lub koom haum uas koom tes nrog txoj kev ua no.`,
	ps: `د یوې داسې ادارې څارنه کوی چې په دې فعالیت کې برخه اخلی.`,
	pt: `Supervisiona uma organização que participa desta atividade.`,
	'pt-pt': `Supervisiona uma organização participante nesta atividade.`,
	ru: `Осуществляет руководство организацией, участвующей в данной деятельности.`,
	sm: `Vaavaai se faalapotopotoga o loo auai i lenei gaoioiga.`,
	so: `Waxay kormeertaa hay'ad ka qaybqaadanaysa hawlahan.`,
	th: `กํากับดูแลองค์กรที่เข้าร่วมกิจกรรมนี้`,
	ur: `اس سرگرمی میں حصہ لینے والی تنظیم کی نگرانی کرتا ہے۔`,
	vi: `Giám sát một tổ chức tham gia vào hoạt động này.`,
	'zh-hans': `监督参与此活动的组织。`,
	'zh-hant': `監督參與此活動的組織。`,
	en: `Supervises an organization participating in this activity.`
};

export const loc_contractedAnOrganization: Record<Locale, string> = {
	ar: `التعاقد مع منظمة مشاركة في هذا النشاط.`,
	de: `Eine Organisation, die an dieser Aktivität teilnimmt, unter Vertrag genommen.`,
	es: `Contrató a una organización que participe en esta actividad.`,
	fa: `با سازمانی که در این فعالیت شرکت می کند قرارداد بست.`,
	fil: `Kinontrata ang isang organisasyon na nakikibahagi sa aktibidad na ito.`,
	fr: `Contrat avec une organisation participant à cette activité.`,
	hi: `इस गतिविधि में भाग लेने वाले संगठन को अनुबंधित किया।`,
	ht: `Kontra yon òganizasyon ki patisipe nan aktivite sa a.`,
	it: `Ha stipulato un contratto con un'organizzazione che partecipa a questa attività.`,
	ja: `この活動に参加している団体と契約しました。`,
	ko: `이 활동에 참여하는 조직과 계약을 맺었습니다.`,
	mww: `Daim ntawv cog lus koom tes nrog txoj kev ua no.`,
	ps: `په دې غونډه کې د یوې ادارې د ګډون په اړه خبرې وکړې.`,
	pt: `Contratou uma organização participante desta atividade.`,
	'pt-pt': `Contratou uma organização participante nesta atividade.`,
	ru: `Заключен договор с организацией, участвующей в данной деятельности.`,
	sm: `Na faia se konekarate i se faalapotopotoga o loo auai i lenei gaoioiga.`,
	so: `Waxaa qandaraas la siiyay hayʼad ka qayb qaadata howshan.`,
	th: `ทําสัญญากับองค์กรที่เข้าร่วมกิจกรรมนี้`,
	ur: `اس سرگرمی میں حصہ لینے والی ایک تنظیم سے معاہدہ کیا۔`,
	vi: `Ký hợp đồng với một tổ chức tham gia vào hoạt động này.`,
	'zh-hans': `与参与此活动的组织签订合同。`,
	'zh-hant': `與參與此活動的組織簽訂合同。`,
	en: `Contracted an organization participating in this activity.`
};

export const loc_subscribed: Record<Locale, string> = {
	ar: `مشترك`,
	de: `Abonniert`,
	es: `Subscrito`,
	fa: `مشترک`,
	fil: `Naka-subscribe`,
	fr: `Souscrit`,
	hi: `सदस्यता`,
	ht: `Abònman`,
	it: `Sottoscritto`,
	ja: `購読`,
	ko: `가입`,
	mww: `Subscribed`,
	ps: `خبرونه`,
	pt: `Subscrito`,
	'pt-pt': `Subscrito`,
	ru: `Подписаны`,
	sm: `Lesitala`,
	so: `Subscribed`,
	th: `สมัครสมาชิก`,
	ur: `سبسکرائب کیا گیا`,
	vi: `Đăng ký`,
	'zh-hans': `订阅`,
	'zh-hant': `訂閱`,
	en: `Subscribed`
};

export const loc_notSubscribed: Record<Locale, string> = {
	ar: `غير مشترك`,
	de: `Nicht abonniert`,
	es: `No suscrito`,
	fa: `مشترک نشده است`,
	fil: `Hindi naka-subscribe`,
	fr: `Non abonné`,
	hi: `सदस्यता नहीं ली गई`,
	ht: `Pa enskri`,
	it: `Non iscritto`,
	ja: `購読していません`,
	ko: `구독하지 않음`,
	mww: `Tsis subscribed`,
	ps: `نا ګډون شوی`,
	pt: `Não inscrito`,
	'pt-pt': `Não subscrito`,
	ru: `Не подписан`,
	sm: `E le'i lesitala`,
	so: `Lama subscribed`,
	th: `ไม่ได้สมัครสมาชิก`,
	ur: `سبسکرائب نہیں کیا گیا`,
	vi: `Chưa đăng ký`,
	'zh-hans': `未订阅`,
	'zh-hant': `未訂閱`,
	en: `Not subscribed`
};

export const loc_memberOfCreateOrg: Record<Locale, string> = {
	ar: `عضو المنظمة التي أنشأت هذا النشاط.`,
	de: `Mitglied der Organisation, die diese Aktivität erstellt hat.`,
	es: `Miembro de la organización creadora de esta actividad.`,
	fa: `عضو سازمانی که این فعالیت را ایجاد کرده است.`,
	fil: `Miyembro ng organisasyon na lumikha ng aktibidad na ito.`,
	fr: `Membre de l’organisation qui a créé cette activité.`,
	hi: `इस गतिविधि को बनाने वाले संगठन का सदस्य.`,
	ht: `Manm òganizasyon ki te kreye aktivite sa a.`,
	it: `Membro dell'organizzazione che ha creato questa attività.`,
	ja: `このアクティビティを作成した組織のメンバー。`,
	ko: `이 활동을 만든 조직의 멤버입니다.`,
	mww: `Cov koom haum uas tsim lub koom haum no.`,
	ps: `د سازمان غړی چې دا فعالیت یې رامینځته کړی.`,
	pt: `Membro da organização que criou esta atividade.`,
	'pt-pt': `Membro da organização que criou esta atividade.`,
	ru: `Член организации, создавшей эту деятельность.`,
	sm: `Tagata o le faalapotopotoga na fatuina lenei gaoioiga.`,
	so: `Xubin ka mid ah ururkii abuuray howshan.`,
	th: `สมาชิกขององค์กรที่สร้างกิจกรรมนี้`,
	ur: `تنظیم کا رکن جس نے اس سرگرمی کو تخلیق کیا.`,
	vi: `Thành viên của tổ chức đã tạo ra hoạt động này.`,
	'zh-hans': `创建此活动的组织成员。`,
	'zh-hant': `創建此活動的組織成員。`,
	en: `Member of organization who created this activity.`
};

export const loc_wasAssignedToActivity: Record<Locale, string> = {
	ar: `تم تعيين هذا النشاط.`,
	de: `Wurde diese Aktivität zugewiesen.`,
	es: `Se le asignó esta actividad.`,
	fa: `این فعالیت به او محول شد.`,
	fil: `Ay inatasan ang aktibidad na ito.`,
	fr: `A été chargé de cette activité.`,
	hi: `यह गतिविधि असाइन की गई थी.`,
	ht: `Te jwenn asiyasyon pou aktivite sa a.`,
	it: `È stata assegnata questa attività.`,
	ja: `このアクティビティが割り当てられています。`,
	ko: `이 활동이 할당되었습니다.`,
	mww: `Raug xa mus rau qhov chaw no.`,
	ps: `د دې فعالیت لپاره ټاکل شوی و.`,
	pt: `Foi atribuída esta atividade.`,
	'pt-pt': `Foi atribuída esta atividade.`,
	ru: `Был назначен этот вид деятельности.`,
	sm: `Na tofia i ai le gaoioiga lenei.`,
	so: `Howshan loo xilsaaray.`,
	th: `ได้รับมอบหมายกิจกรรมนี้`,
	ur: `یہ سرگرمی تفویض کی گئی تھی۔`,
	vi: `Đã được chỉ định hoạt động này.`,
	'zh-hans': `已分配此活动。`,
	'zh-hant': `已分配此活動。`,
	en: `Was assigned this activity.`
};

export const loc_memberOfAssignedOrg: Record<Locale, string> = {
	ar: `عضو المنظمة الذي تم تكليفه بهذا النشاط.`,
	de: `Mitglied der Organisation, dem diese Aktivität zugewiesen wurde.`,
	es: `Miembro de la organización a la que se le asignó esta actividad.`,
	fa: `عضو سازمانی که این فعالیت را به او محول شده است.`,
	fil: `Miyembro ng organisasyon na inatasan ng aktibidad na ito.`,
	fr: `Membre de l’organisation à qui cette activité a été confiée.`,
	hi: `संगठन का सदस्य जिसे यह गतिविधि सौंपी गई थी।`,
	ht: `Manm òganizasyon ki te asiyen aktivite sa a.`,
	it: `Membro dell'organizzazione a cui è stata assegnata questa attività.`,
	ja: `このアクティビティが割り当てられた組織のメンバー。`,
	ko: `이 활동이 지정된 조직의 구성원입니다.`,
	mww: `Cov koom haum uas raug txib mus ua qhov no.`,
	ps: `د سازمان هغه غړی چې د دې فعالیت لپاره ګمارل شوی و.`,
	pt: `Membro da organização que recebeu esta atividade.`,
	'pt-pt': `Membro da organização a quem foi atribuída esta atividade.`,
	ru: `Член организации, на которую была возложена эта деятельность.`,
	sm: `Tagata o le faalapotopotoga na tofia i lenei gaoioiga.`,
	so: `Xubin ka mid ah hayʼadda loo magacaabay hawlahan.`,
	th: `สมาชิกขององค์กรที่ได้รับมอบหมายกิจกรรมนี้`,
	ur: `تنظیم کا رکن جسے یہ سرگرمی تفویض کی گئی تھی۔`,
	vi: `Thành viên của tổ chức được giao hoạt động này.`,
	'zh-hans': `分配了此活动的组织成员。`,
	'zh-hant': `分配了此活動的組織成員。`,
	en: `Member of organization who was assigned this activity.`
};

export const loc_wasMentionedInActivity: Record<Locale, string> = {
	ar: `تم ذكره في هذا النشاط.`,
	de: `Wurde in dieser Aktivität erwähnt.`,
	es: `Fue mencionado en esta actividad.`,
	fa: `در این فعالیت ذکر شده است.`,
	fil: `Ay nabanggit sa aktibidad na ito.`,
	fr: `A été mentionné dans cette activité.`,
	hi: `इस गतिविधि में उल्लेख किया गया था।`,
	ht: `Yo te pale de li nan aktivite sa a.`,
	it: `È stato menzionato in questa attività.`,
	ja: `この活動で言及されました。`,
	ko: `이 활동에서 언급되었습니다.`,
	mww: `Yog hais nyob rau hauv qhov kev ua no.`,
	ps: `په دې غونډه کې د فعالیت په اړه خبرې وشوې.`,
	pt: `Foi mencionado nesta atividade.`,
	'pt-pt': `Foi mencionado nesta atividade.`,
	ru: `Был упомянут в этом упражнении.`,
	sm: `Na ta'ua i lenei gaoioiga.`,
	so: `Howshaan ayaa lagu xusay.`,
	th: `ถูกกล่าวถึงในกิจกรรมนี้`,
	ur: `اس سرگرمی میں ذکر کیا گیا تھا.`,
	vi: `Đã được đề cập trong hoạt động này.`,
	'zh-hans': `在此活动中被提及。`,
	'zh-hant': `在此活動中被提及。`,
	en: `Was mentioned in this activity.`
};

export const loc_memberOfMentionedOrg: Record<Locale, string> = {
	ar: `عضو المنظمة الذي تم ذكره في هذا النشاط.`,
	de: `Mitglied der Organisation, das in dieser Aktivität erwähnt wurde.`,
	es: `Miembro de la organización que se mencionó en esta actividad.`,
	fa: `عضو سازمانی که در این فعالیت از آنها نام برده شده است.`,
	fil: `Miyembro ng organisasyon na nabanggit sa aktibidad na ito.`,
	fr: `Membre de l’organisation qui a été mentionné dans cette activité.`,
	hi: `संगठन का सदस्य जिसका इस गतिविधि में उल्लेख किया गया था।`,
	ht: `Manm òganizasyon ki te mansyone nan aktivite sa a.`,
	it: `Membro dell'organizzazione che è stato menzionato in questa attività.`,
	ja: `このアクティビティで言及された組織のメンバー。`,
	ko: `이 활동에서 언급된 조직의 구성원입니다.`,
	mww: `Cov koom haum uas tau hais nyob rau hauv qhov kev ua no.`,
	ps: `د سازمان هغه غړی چې په دې غونډه کې یې ګډون کړی و.`,
	pt: `Membro da organização que foi mencionado nesta atividade.`,
	'pt-pt': `Membro da organização que foi mencionado nesta atividade.`,
	ru: `Член организации, которая была упомянута в данной деятельности.`,
	sm: `Tagata o le faalapotopotoga o le na ta'ua i lenei gaoioiga.`,
	so: `Xubin ka tirsan hayʼadda oo lagu xusay howshan.`,
	th: `สมาชิกขององค์กรที่กล่าวถึงในกิจกรรมนี้`,
	ur: `تنظیم کا رکن جس کا اس سرگرمی میں ذکر کیا گیا تھا۔`,
	vi: `Thành viên của tổ chức đã được đề cập trong hoạt động này.`,
	'zh-hans': `此活动中提及的组织成员。`,
	'zh-hant': `此活動中提及的組織成員。`,
	en: `Member of organization who was mentioned in this activity.`
};

export const loc_wasSubscribedViaAutomation: Record<Locale, string> = {
	ar: `تم الاشتراك في هذا النشاط عبر الأتمتة.`,
	de: `Wurde für diese Aktivität über die Automatisierung abonniert.`,
	es: `Se suscribió a esta actividad a través de la automatización.`,
	fa: `از طریق اتوماسیون در این فعالیت مشترک شد.`,
	fil: `Ay naka subscribe sa aktibidad na ito sa pamamagitan ng automation.`,
	fr: `A été abonné à cette activité via l’automatisation.`,
	hi: `स्वचालन के माध्यम से इस गतिविधि की सदस्यता ली गई थी।`,
	ht: `Te enskri nan aktivite sa a atravè automatisation.`,
	it: `È stato iscritto a questa attività tramite automazione.`,
	ja: `自動化によってこのアクティビティに登録されました。`,
	ko: `자동화를 통해 이 활동에 가입되었습니다.`,
	mww: `Yog subscribed rau qhov kev ua no ntawm automation.`,
	ps: `دغه عملیات د اتومات ماشین له لارې ترسره شوی دی.`,
	pt: `Foi inscrito nesta atividade via automação.`,
	'pt-pt': `Foi inscrito nesta atividade via automação.`,
	ru: `Был подписан на эту активность через автоматизацию.`,
	sm: `Sa lesitala i lenei gaoioiga e ala i le faiga o mea e faia e le tagata lava ia.`,
	so: `Waxaa loogu subscribed howshan via qalabaynta.`,
	th: `สมัครสมาชิกกิจกรรมนี้ผ่านระบบอัตโนมัติ`,
	ur: `آٹومیشن کے ذریعے اس سرگرمی کو سبسکرائب کیا گیا تھا۔`,
	vi: `Đã đăng ký hoạt động này thông qua tự động hóa.`,
	'zh-hans': `通过自动化订阅了此活动。`,
	'zh-hant': `通過自動化訂閱了此活動。`,
	en: `Was subscribed to this activity via automation.`
};

export const loc_wasAssociatedViaAutomation: Record<Locale, string> = {
	ar: `ارتبط بهذا النشاط عبر الأتمتة.`,
	de: `Wurde über die Automatisierung mit dieser Aktivität in Verbindung gebracht.`,
	es: `Se asoció a esta actividad a través de la automatización.`,
	fa: `از طریق اتوماسیون با این فعالیت همراه بود.`,
	fil: `Ay nauugnay sa aktibidad na ito sa pamamagitan ng automation.`,
	fr: `A été associé à cette activité via l’automatisation.`,
	hi: `स्वचालन के माध्यम से इस गतिविधि से जुड़ा था।`,
	ht: `Te asosye ak aktivite sa a atravè automatisation.`,
	it: `È stato associato a questa attività tramite l'automazione.`,
	ja: `自動化によってこのアクティビティに関連付けられました。`,
	ko: `자동화를 통해 이 활동과 연결되었습니다.`,
	mww: `Yog mob nrog qhov kev ua no ntawm automation.`,
	ps: `دا د اتوماتیک له لارې د دې فعالیت سره تړاو لری.`,
	pt: `Foi associado a esta atividade via automação.`,
	'pt-pt': `Associou-se a esta atividade via automação.`,
	ru: `Был связан с этой деятельностью через автоматизацию.`,
	sm: `Sa fesootai ma lenei gaoioiga e ala i le faiga o mea e faia e le tagata lava ia.`,
	so: `Waxaa lagu xiriirinayay howshaan iyadoo loo marayo qalabaynta.`,
	th: `เกี่ยวข้องกับกิจกรรมนี้ผ่านระบบอัตโนมัติ`,
	ur: `آٹومیشن کے ذریعے اس سرگرمی سے وابستہ تھا۔`,
	vi: `Được liên kết với hoạt động này thông qua tự động hóa.`,
	'zh-hans': `通过自动化与此活动相关联。`,
	'zh-hant': `通過自動化與此活動相關聯。`,
	en: `Was associated with this activity via automation.`
};

export const loc_hasAccessToAssociatedActivity: Record<Locale, string> = {
	ar: `تم استلام الوصول من خلال نشاط مرتبط.`,
	de: `Zugriff über eine zugeordnete Aktivität erhalten.`,
	es: `Acceso recibido a través de una actividad asociada.`,
	fa: `دسترسی را از طریق یک فعالیت مرتبط دریافت کرد.`,
	fil: `Nakatanggap ng access sa pamamagitan ng isang kaugnay na aktibidad.`,
	fr: `Accès reçu par le biais d’une activité associée.`,
	hi: `एक संबद्ध गतिविधि के माध्यम से पहुँच प्राप्त की।`,
	ht: `Te resevwa aksè nan yon aktivite ki asosye.`,
	it: `Accesso ricevuto tramite un'attività associata.`,
	ja: `関連付けられたアクティビティを通じてアクセスを受け取った。`,
	ko: `연관된 활동을 통해 받은 액세스 권한입니다.`,
	mww: `Tau txais kev pab los ntawm ib qho kev ua ub no.`,
	ps: `د اړونده فعالیت له لارې لاسرسی ترلاسه کړئ.`,
	pt: `Acesso recebido por meio de uma atividade associada.`,
	'pt-pt': `Acesso recebido através de uma atividade associada.`,
	ru: `Получен доступ через связанную активность.`,
	sm: `Mauaina e ala i se gaoioiga e fesootai ai.`,
	so: `Waxay ku heshay fursado la xiriira waxqabadyada la xiriira.`,
	th: `ได้รับสิทธิ์เข้าถึงผ่านกิจกรรมที่เกี่ยวข้อง`,
	ur: `متعلقہ سرگرمی کے ذریعے رسائی حاصل کی.`,
	vi: `Nhận được quyền truy cập thông qua một hoạt động liên quan.`,
	'zh-hans': `通过关联的活动接收访问权限。`,
	'zh-hant': `通過關聯的活動接收訪問許可權。`,
	en: `Received access through an associated activity.`
};

export const loc_hasAccessToReferencingActivity: Record<Locale, string> = {
	ar: `تم استلام حق الوصول من خلال نشاط يشير إلى هذا النشاط.`,
	de: `Zugriff über eine Aktivität erhalten, die auf diese Aktivität verweist.`,
	es: `Acceso recibido a través de una actividad que hace referencia a esta actividad.`,
	fa: `از طریق فعالیتی که به این فعالیت ارجاع می دهد، دسترسی دریافت کرده است.`,
	fil: `Nakatanggap ng access sa pamamagitan ng aktibidad na tumutukoy sa aktibidad na ito.`,
	fr: `Accès reçu par le biais d’une activité qui fait référence à cette activité.`,
	hi: `इस गतिविधि का संदर्भ देने वाली गतिविधि के माध्यम से पहुँच प्राप्त की.`,
	ht: `Resevwa aksè nan yon aktivite ki refere aktivite sa a.`,
	it: `Accesso ricevuto tramite un'attività che fa riferimento a questa attività.`,
	ja: `このアクティビティを参照するアクティビティを通じてアクセスを受け取りました。`,
	ko: `이 활동을 참조하는 활동을 통해 액세스 권한을 받았습니다.`,
	mww: `Tau txais kev mus saib tau los ntawm ib qho kev ua uas siv cov kev ua no.`,
	ps: `د یو فعالیت له لارې لاسرسی ترلاسه کړئ چې دا فعالیت ته اشاره کوی.`,
	pt: `Acesso recebido por meio de uma atividade que faz referência a essa atividade.`,
	'pt-pt': `Acesso recebido através de uma atividade que faz referência a essa atividade.`,
	ru: `Получен доступ через действие, которое ссылается на это действие.`,
	sm: `Na maua le faaaogaina e ala i se gaoioiga e faasino i lenei gaoioiga.`,
	so: `Waxaa lagu helay helitaanka shaqooyin tixraacaya waxqabadkaan.`,
	th: `ได้รับการเข้าถึงผ่านกิจกรรมที่อ้างอิงกิจกรรมนี้`,
	ur: `ایک سرگرمی کے ذریعے رسائی حاصل کی جو اس سرگرمی کا حوالہ دیتی ہے۔`,
	vi: `Nhận được quyền truy cập thông qua một hoạt động tham chiếu đến hoạt động này.`,
	'zh-hans': `通过引用此活动的 Activity 接收访问权限。`,
	'zh-hant': `通過引用此活動的 Activity 接收訪問許可權。`,
	en: `Received access through an activity that references this activity.`
};

export const loc_memberOfContractingOrg: Record<Locale, string> = {
	ar: `عضو في منظمة تعاقدت مع هذا النشاط.`,
	de: `Mitglied einer Organisation, die diese Aktivität in Auftrag gegeben hat.`,
	es: `Miembro de una organización que contrató esta actividad.`,
	fa: `عضو سازمانی که با این فعالیت قرارداد بسته است.`,
	fil: `Miyembro ng isang organisasyon na kinontrata ang aktibidad na ito.`,
	fr: `Membre d’une organisation qui a contracté cette activité.`,
	hi: `इस गतिविधि को अनुबंधित करने वाले संगठन का सदस्य।`,
	ht: `Manm yon òganizasyon ki te trape aktivite sa a.`,
	it: `Membro di un'organizzazione che ha appaltato questa attività.`,
	ja: `このアクティビティを契約した組織のメンバー。`,
	ko: `이 활동을 계약한 조직의 구성원입니다.`,
	mww: `Ib lub koom haum uas cog lus no.`,
	ps: `د یوې داسې ادارې غړی چې دغه کار یې تر سره کړی دی.`,
	pt: `Membro de uma organização que contratou esta atividade.`,
	'pt-pt': `Membro de uma organização que contratou esta atividade.`,
	ru: `Член организации, которая заключила договор на эту деятельность.`,
	sm: `Tagata o se faalapotopotoga na faamaoniaina lenei gaoioiga.`,
	so: `xubin ka ah hayʼad qandaraas la yeeshay howshan.`,
	th: `สมาชิกขององค์กรที่ทําสัญญากิจกรรมนี้`,
	ur: `ایک تنظیم کا رکن جس نے اس سرگرمی کا معاہدہ کیا.`,
	vi: `Thành viên của một tổ chức ký hợp đồng với hoạt động này.`,
	'zh-hans': `承包此活动的组织的成员。`,
	'zh-hant': `承包此活動的組織的成員。`,
	en: `Member of an organization that contracted this activity.`
};

export const loc_memberOfProjectSupervisorOrg: Record<Locale, (project: unknown) => string> = {
	ar: (project) => `عضو في منظمة تشرف على مشروع ${project} .`,
	de: (project) => `Mitglied einer Organisation, die das Projekt ${project} betreut.`,
	es: (project) => `Miembro de una organización que supervisa el proyecto de ${project} .`,
	fa: (project) => `عضو سازمانی که بر پروژه ${project} نظارت می کند.`,
	fil: (project) => `Miyembro ng isang organisasyon na nangangasiwa sa proyektong ${project} .`,
	fr: (project) => `Membre d’un organisme qui supervise le projet de ${project} .`,
	hi: (project) => `एक संगठन का सदस्य जो ${project} परियोजना की देखरेख करता है।`,
	ht: (project) => `Manm yon òganizasyon ki sipèvize pwojè ${project} la.`,
	it: (project) => `Membro di un'organizzazione che supervisiona il progetto di ${project} .`,
	ja: (project) => `${project}プロジェクトを監督する組織のメンバー。`,
	ko: (project) => `${project} 프로젝트를 감독하는 조직의 구성원.`,
	mww: (project) => `Ib lub koom haum uas saib xyuas qhov ${project} .`,
	ps: (project) => `د یوې ادارې غړی چې د ${project} پروژې څارنه کوی.`,
	pt: (project) => `Membro de uma organização que supervisiona o projeto de ${project} .`,
	'pt-pt': (project) => `Membro de uma organização que supervisiona o projeto de ${project} .`,
	ru: (project) => `Член организации, которая осуществляет надзор за проектом ${project} .`,
	sm: (project) => `Tagata o se faalapotopotoga e vaavaaia le galuega faatino o ${project} .`,
	so: (project) => `Xubin ka mida hay'adda ilaaliya mashruuca ${project} .`,
	th: (project) => `สมาชิกขององค์กรที่ดูแลโครงการ${project}`,
	ur: (project) => `ایک تنظیم کے رکن جو ${project} منصوبے کی نگرانی کرتا ہے.`,
	vi: (project) => `Thành viên của một tổ chức giám sát dự án ${project} .`,
	'zh-hans': (project) => `监督 ${project} 项目的组织的成员。`,
	'zh-hant': (project) => `監督 ${project} 項目的組織的成員。`,
	en: (project) => `Member of an organization that supervises the ${project} project.`
};

export const loc_memberOfOrgSupervisor: Record<Locale, string> = {
	ar: `عضو في منظمة تشرف على منظمة أخرى مشاركة في هذا النشاط.`,
	de: `Mitglied einer Organisation, die eine andere Organisation beaufsichtigt, die an dieser Aktivität teilnimmt.`,
	es: `Miembro de una organización que supervisa a otra organización que participa en esta actividad.`,
	fa: `عضو سازمانی که بر سازمان دیگری که در این فعالیت شرکت می کند نظارت می کند.`,
	fil: `Miyembro ng isang organisasyon na nangangasiwa sa isa pang organisasyon na nakikibahagi sa aktibidad na ito.`,
	fr: `Membre d’une organisation qui supervise une autre organisation participant à cette activité.`,
	hi: `एक संगठन का सदस्य जो इस गतिविधि में भाग लेने वाले किसी अन्य संगठन का पर्यवेक्षण करता है।`,
	ht: `Manm yon òganizasyon ki sipèvize yon lòt òganizasyon ki patisipe nan aktivite sa a.`,
	it: `Membro di un'organizzazione che supervisiona un'altra organizzazione che partecipa a questa attività.`,
	ja: `この活動に参加している他の組織を監督する組織のメンバー。`,
	ko: `이 활동에 참여하는 다른 조직을 감독하는 조직의 구성원입니다.`,
	mww: `Ib lub koom haum uas saib xyuas lwm lub koom haum uas saib xyuas lwm lub koom haum uas koom tes nrog txoj kev ua no.`,
	ps: `د یو سازمان غړی چې د بل سازمان څارنه کوی چې پدې فعالیت کې برخه اخلی.`,
	pt: `Membro de uma organização que supervisiona outra organização que participa desta atividade.`,
	'pt-pt': `Membro de uma organização que supervisiona outra organização participante nesta atividade.`,
	ru: `Член организации, которая осуществляет надзор за другой организацией, участвующей в данной деятельности.`,
	sm: `Tagata o se faalapotopotoga e vaavaaia se isi faalapotopotoga o loo auai i lenei gaoioiga.`,
	so: `Xubin ka mid ah hay'ad kormeerta hayʼad kale oo howshan ka qayb qaadanaysa.`,
	th: `สมาชิกขององค์กรที่กํากับดูแลองค์กรอื่นที่เข้าร่วมกิจกรรมนี้`,
	ur: `ایک تنظیم کا رکن جو اس سرگرمی میں حصہ لینے والی کسی دوسری تنظیم کی نگرانی کرتا ہے۔`,
	vi: `Thành viên của một tổ chức giám sát một tổ chức khác tham gia vào hoạt động này.`,
	'zh-hans': `监督参与此活动的其他组织的组织的成员。`,
	'zh-hant': `監督參與此活動的其他組織的組織的成員。`,
	en: `Member of an organization that supervises another organization participating in this activity.`
};

export const loc_commentedOnActivity: Record<Locale, string> = {
	ar: `على هذا النشاط.`,
	de: `Kommentierte diese Aktivität.`,
	es: `Comentado sobre esta actividad.`,
	fa: `در مورد این فعالیت اظهار نظر کردم.`,
	fil: `Nagkomento sa aktibidad na ito.`,
	fr: `Commenté cette activité.`,
	hi: `इस गतिविधि पर टिप्पणी की।`,
	ht: `Kòmante sou aktivite sa a.`,
	it: `Ha commentato questa attività.`,
	ja: `この活動についてコメントしました。`,
	ko: `이 활동에 댓글을 달았습니다.`,
	mww: `Saib rau qhov kev ua no.`,
	ps: `د دې فعالیت په اړه خبرې وکړئ.`,
	pt: `Comentei sobre esta atividade.`,
	'pt-pt': `Comentou esta atividade.`,
	ru: `Прокомментировал эту деятельность.`,
	sm: `Faamatalaga e uiga i lenei gaoioiga.`,
	so: `Ka faallooday howshan.`,
	th: `แสดงความคิดเห็นเกี่ยวกับกิจกรรมนี้`,
	ur: `اس سرگرمی پر تبصرہ کیا.`,
	vi: `Nhận xét về hoạt động này.`,
	'zh-hans': `对此活动进行了评论。`,
	'zh-hant': `對此活動進行了評論。`,
	en: `Commented on this activity.`
};

export const loc_updatedThisActivity: Record<Locale, string> = {
	ar: `تم تحديث هذا النشاط.`,
	de: `Diese Aktivität wurde aktualisiert.`,
	es: `Se actualizó esta actividad.`,
	fa: `این فعالیت را به روز کرد.`,
	fil: `Na update ang aktibidad na ito.`,
	fr: `Cette activité a été mise à jour.`,
	hi: `इस गतिविधि को अपडेट किया गया.`,
	ht: `Mete ajou aktivite sa a.`,
	it: `Aggiornata questa attività.`,
	ja: `このアクティビティを更新しました。`,
	ko: `이 활동을 업데이트했습니다.`,
	mww: `Kho tshiab qhov kev ua ub no no.`,
	ps: `دا فعالیت تازه کړئ.`,
	pt: `Atualizada esta atividade.`,
	'pt-pt': `Atualizada esta atividade.`,
	ru: `Обновлено это упражнение.`,
	sm: `Toe faafou le gaoioiga lenei.`,
	so: `Cusbooneysiiyay waxqabadkan.`,
	th: `อัปเดตกิจกรรมนี้`,
	ur: `اس سرگرمی کو اپ ڈیٹ کیا۔`,
	vi: `Cập nhật hoạt động này.`,
	'zh-hans': `更新了此活动。`,
	'zh-hant': `更新了此活動。`,
	en: `Updated this activity.`
};

export const loc_advanced: Record<Locale, string> = {
	ar: `متقدم`,
	de: `Fortgeschritten`,
	es: `Avanzado`,
	fa: `پیشرفته`,
	fil: `Advanced na`,
	fr: `Avancé`,
	hi: `अग्रवर्ती`,
	ht: `Avanse`,
	it: `Avanzato`,
	ja: `アドバンスド`,
	ko: `고급`,
	mww: `siab heev`,
	ps: `بریونلی`,
	pt: `Avançado`,
	'pt-pt': `Avançado`,
	ru: `Продвинутый`,
	sm: `Maualuga`,
	so: `hore`,
	th: `ดึก`,
	ur: `اعلی`,
	vi: `Tiên tiến`,
	'zh-hans': `高深`,
	'zh-hant': `高深`,
	en: `Advanced`
};

export const loc_clear: Record<Locale, string> = {
	ar: `واضح`,
	de: `Klar`,
	es: `Claro`,
	fa: `روشن`,
	fil: `Malinaw na`,
	fr: `Clair`,
	hi: `स्पष्ट`,
	ht: `Klè`,
	it: `Chiaro`,
	ja: `クリア`,
	ko: `맑다`,
	mww: `Ntshiab`,
	ps: `پاکول`,
	pt: `Claro`,
	'pt-pt': `Limpar`,
	ru: `Ясный`,
	sm: `Manino`,
	so: `Clear`,
	th: `ใส`,
	ur: `صاف`,
	vi: `Trong`,
	'zh-hans': `清楚`,
	'zh-hant': `清楚`,
	en: `Clear`
};

export const loc_solutions: Record<Locale, string> = {
	ar: `محاليل`,
	de: `Lösungen`,
	es: `Soluciones`,
	fa: `راه حل`,
	fil: `Mga Solusyon`,
	fr: `Solutions`,
	hi: `समाधान`,
	ht: `Solisyon`,
	it: `Soluzioni`,
	ja: `ソリューション`,
	ko: `솔루션`,
	mww: `tov`,
	ps: `د حل`,
	pt: `Soluções`,
	'pt-pt': `Soluções`,
	ru: `Решения`,
	sm: `Vaifofo`,
	so: `Solutions`,
	th: `โซ ลู ชั่น`,
	ur: `حل`,
	vi: `Giải pháp`,
	'zh-hans': `解决 方案`,
	'zh-hant': `解決方案`,
	en: `Solutions`
};

export const loc_agreeToTermsOfUse: Record<Locale, (cumulus: unknown) => string> = {
	ar: (cumulus) => `أوافق على شروط استخدام ${cumulus}`,
	de: (cumulus) => `Ich stimme den ${cumulus} zu`,
	es: (cumulus) => `Acepto los Términos de uso de ${cumulus}`,
	fa: (cumulus) => `من با شرایط استفاده ${cumulus} موافقم`,
	fil: (cumulus) => `Sumasang ayon ako sa ${cumulus} Terms of Use`,
	fr: (cumulus) => `J’accepte les conditions d’utilisation de ${cumulus}`,
	hi: (cumulus) => `मैं ${cumulus} उपयोग की शर्तों से सहमत हूं`,
	ht: (cumulus) => `Mwen dakò ak Kondisyon ${cumulus} pou Itilize`,
	it: (cumulus) => `Accetto le Condizioni d'uso di ${cumulus}`,
	ja: (cumulus) => `${cumulus} の利用規約に同意します`,
	ko: (cumulus) => `${cumulus} 이용 약관에 동의합니다.`,
	mww: (cumulus) => `Kuv pom zoo rau lub ${cumulus} cov ntsiab lus uas siv`,
	ps: (cumulus) => `زه د ${cumulus} موافق یم`,
	pt: (cumulus) => `Eu concordo com os Termos de Uso da ${cumulus}`,
	'pt-pt': (cumulus) => `Concordo com os Termos de ${cumulus}`,
	ru: (cumulus) => `Я согласен с Условиями использования ${cumulus}`,
	sm: (cumulus) => `Ou te ioe i Tuutuuga o le Faaaogaina o le ${cumulus}`,
	so: (cumulus) => `Waan ogolaaday in ${cumulus} ee Isticmaalka`,
	th: (cumulus) => `ฉันยอมรับข้อกําหนดการใช้งาน ${cumulus}`,
	ur: (cumulus) => `میں ${cumulus} کے استعمال کی شرائط سے اتفاق کرتا ہوں`,
	vi: (cumulus) => `Tôi đồng ý với Điều khoản sử dụng ${cumulus}`,
	'zh-hans': (cumulus) => `我同意 ${cumulus} 使用条款`,
	'zh-hant': (cumulus) => `我同意 ${cumulus} 使用條款`,
	en: (cumulus) => `I agree to the ${cumulus} Terms of Use`
};

export const loc_termsOfUse: Record<Locale, string> = {
	ar: `شروط الاستخدام`,
	de: `Nutzungsbedingungen`,
	es: `Condiciones de uso`,
	fa: `شرایط استفاده`,
	fil: `Mga Tuntunin sa Paggamit`,
	fr: `Conditions d’utilisation`,
	hi: `उपयोग की शर्तें`,
	ht: `Regleman pou Itilize`,
	it: `Condizioni d'uso`,
	ja: `利用規約`,
	ko: `이용 약관`,
	mww: `Siv Cov Ntsiab Lus Uas Siv`,
	ps: `د کارولو شرایط`,
	pt: `Termos de Uso`,
	'pt-pt': `Termos de Utilização`,
	ru: `Условия использования`,
	sm: `Tuutuuga o le Faaaogaina`,
	so: `Terms of Use`,
	th: `เงื่อนไขการใช้งาน`,
	ur: `استعمال کی شرائط`,
	vi: `Điều khoản sử dụng`,
	'zh-hans': `使用条款`,
	'zh-hant': `使用條款`,
	en: `Terms of Use`
};

export const loc_privacy: Record<Locale, string> = {
	ar: `الخصوصيه`,
	de: `Privatsphäre`,
	es: `Privacidad`,
	fa: `حریم خصوصی`,
	fil: `Pagkapribado`,
	fr: `Vie privée`,
	hi: `गोपनीयता`,
	ht: `konfidansyalite`,
	it: `Privacy`,
	ja: `プライバシー`,
	ko: `사생활`,
	mww: `Tsis Pub Twg Paub`,
	ps: `د محرمیت تګلاره`,
	pt: `Privacidade`,
	'pt-pt': `Privacidade`,
	ru: `Конфиденциальность`,
	sm: `Le Faalauaiteleina`,
	so: `Privacy`,
	th: `ความเป็นส่วนตัว`,
	ur: `رازداری`,
	vi: `Sự riêng tư`,
	'zh-hans': `隐私`,
	'zh-hant': `隱私`,
	en: `Privacy`
};

export const loc_weveUpdatedTerms: Record<Locale, (href: unknown) => TemplateResult> = {
	ar: (href) => html`لقد قمنا بتحديث <a href="${href}">شروط الاستخدام</a> الخاصة بنا.`,
	de: (href) => html`Wir haben unsere <a href="${href}">Nutzungsbedingungen</a> aktualisiert.`,
	es: (href) => html`Hemos actualizado nuestros <a href="${href}">Términos de uso</a>.`,
	fa: (href) => html`شرایط <a href="${href}">استفاده</a> خود را به روزرسانی کرده ایم.`,
	fil: (href) => html`Na update namin ang aming <a href="${href}">Mga Tuntunin sa Paggamit</a>.`,
	fr: (href) => html`Nous avons mis à jour nos <a href="${href}">conditions d’utilisation</a>.`,
	hi: (href) => html`हमने अपनी <a href="${href}">उपयोग की शर्तों</a> को अपडेट कर दिया है।`,
	ht: (href) => html`Nou te mete ajou <a href="${href}">kondisyon nou yo nan Itilize</a>.`,
	it: (href) => html`Abbiamo aggiornato i nostri <a href="${href}">Termini di utilizzo</a>.`,
	ja: (href) => html`<a href="${href}">利用規約</a>を更新しました。`,
	ko: (href) => html`<a href="${href}">이용 약관</a>을 업데이트했습니다.`,
	mww: (href) => html`Peb twb muab kho peb <a href="${href}">cov ntsiab lus uas siv</a>.`,
	ps: (href) => html`موږ زموږ د <a href="${href}">کارولو شرایط</a> تازه کړل.`,
	pt: (href) => html`Atualizamos nossos <a href="${href}">Termos de Uso</a>.`,
	'pt-pt': (href) => html`Atualizámos os nossos <a href="${href}">Termos de Utilização</a>.`,
	ru: (href) => html`Мы обновили наши <a href="${href}">Условия использования</a>.`,
	sm: (href) => html`Ua faafouina a matou <a href="${href}">Tuutuuga o le Faaaogaina</a>.`,
	so: (href) => html`Waxaan cusbooneysiinnay <a href="${href}">Shuruucda isticmaalka</a>.`,
	th: (href) => html`เราได้อัปเดต <a href="${href}">ข้อกําหนดการใช้งาน</a>ของเรา`,
	ur: (href) => html`ہم نے اپنے استعمال کی <a href="${href}">شرائط</a> کو اپ ڈیٹ کیا ہے.`,
	vi: (href) => html`Chúng tôi đã cập nhật <a href="${href}">Điều khoản sử dụng của</a> mình.`,
	'zh-hans': (href) => html`我们更新了 <a href="${href}">我们的使用条款</a>。`,
	'zh-hant': (href) => html`我們更新了 <a href="${href}">我們的使用條款</a>。`,
	en: (href) => html`We've updated our <a href="${href}">Terms of Use</a>.`
};

export const loc_validateActivityChildrenClosed: Record<Locale, string> = {
	ar: `لا يمكن إكمال النشاط بينما لا تزال أنشطته الفرعية في حالة مفتوحة. انتقل إلى علامة التبويب "الأنشطة المرتبطة" وأكمل أو ألغي الأنشطة المدرجة هناك قبل إكمال هذا النشاط.`,
	de: `Eine Aktivität kann nicht abgeschlossen werden, solange sich die untergeordneten Aktivitäten noch im Status "Offen" befinden. Navigieren Sie zur Registerkarte "Zugehörige Aktivitäten" und schließen Sie die dort aufgeführten Aktivitäten ab oder brechen Sie sie ab, bevor Sie diese Aktivität abschließen.`,
	es: `Una actividad no se puede completar mientras sus actividades secundarias aún estén en estado abierto. Vaya a la pestaña "Actividades asociadas" y complete o cancele las actividades enumeradas allí antes de completar esta actividad.`,
	fa: `یک فعالیت را نمی توان در حالی که فعالیت های فرزند آن هنوز در وضعیت باز است، تکمیل کرد. به برگه «فعالیت های مرتبط» بروید و قبل از تکمیل این فعالیت، فعالیت های فهرست شده در آنجا را تکمیل یا لغو کنید.`,
	fil: `Hindi maaaring makumpleto ang isang aktibidad habang ang mga aktibidad nito sa bata ay nasa bukas na katayuan pa rin. Mag navigate sa tab na "Mga nauugnay na aktibidad" at kumpletuhin o kanselahin ang mga aktibidad na nakalista doon bago makumpleto ang aktibidad na ito.`,
	fr: `Une activité ne peut pas être terminée tant que ses activités enfants sont toujours ouvertes. Accédez à l’onglet « Activités associées » et terminez ou annulez les activités qui y sont énumérées avant de terminer cette activité.`,
	hi: `एक गतिविधि पूरी नहीं की जा सकती है जब तक कि उसकी बाल गतिविधियाँ अभी भी खुली स्थिति में हैं। "संबद्ध गतिविधियाँ" टैब पर नेविगेट करें और इस गतिविधि को पूरा करने से पहले वहाँ सूचीबद्ध गतिविधियों को पूरा या रद्द करें।`,
	ht: `Yon aktivite pa ka ranpli pandan aktivite timoun li yo toujou nan yon estati ouvè. Navige nan "aktivite ki asosye" tab ak konplè oswa anile aktivite yo ki nan lis la anvan yo ranpli aktivite sa a.`,
	it: `Impossibile completare un'attività mentre le relative attività figlio sono ancora in uno stato aperto. Vai alla scheda "Attività associate" e completa o annulla le attività elencate prima di completare questa attività.`,
	ja: `アクティビティは、その子アクティビティがまだオープンステータスにある間は完了できません。「関連付けられたアクティビティ」タブに移動し、このアクティビティを完了する前に、そこにリストされているアクティビティを完了またはキャンセルします。`,
	ko: `하위 활동이 열린 상태인 동안에는 활동을 완료할 수 없습니다. "관련 활동" 탭으로 이동하여 이 활동을 완료하기 전에 나열된 활동을 완료하거나 취소합니다.`,
	mww: `Ib qho kev ua ub no yuav ua tsis tau kom tiav thaum nws cov kev ua ub no tseem nyob rau hauv ib qho kev qhib raws li txoj cai. Navigate rau cov "Associated activities" tab sis ua tiav los yog muab cov kev ua ub no uas teev muaj ua ntej yuav ua tiav qhov kev ua no.`,
	ps: `یو فعالیت نشی بشپړ کیدی پداسې حال کې چې د هغې د ماشوم فعالیتونه لاهم په خلاص حالت کې دی. د "ایسوسی ایټ شوی فعالیتونو" ټب ته نیویګ کړئ او د دې فعالیت بشپړولو دمخه هلته لیست شوی فعالیتونه بشپړ یا لغوه کړئ.`,
	pt: `Uma atividade não pode ser concluída enquanto suas atividades secundárias ainda estiverem em um status aberto. Navegue até a guia "Atividades associadas" e conclua ou cancele as atividades listadas antes de concluir esta atividade.`,
	'pt-pt': `Uma atividade não pode ser concluída enquanto suas atividades filhas ainda estiverem em um status aberto. Navegue até a guia "Atividades associadas" e conclua ou cancele as atividades listadas antes de concluir esta atividade.`,
	ru: `Действие не может быть завершено, пока его дочерние действия все еще находятся в состоянии открытия. Перейдите на вкладку «Связанные действия» и завершите или отмените перечисленные там действия до завершения этого действия.`,
	sm: `E le mafai ona faamaeaina se gaoioiga a o i ai pea ana gaoioiga a tamaiti i se tulaga tatala. Alu i le faailoga "Gaoioiga e Fesootai Ai" ma faamaea pe faaleaogaina gaoioiga o loo lisiina ai iina a o lei faamaeaina lenei gaoioiga.`,
	so: `Howlahaas lama dhammeysiin karo, inta howsha carruurtu ay weli ku jirto si furan. In aad u sii marto tababka "Howlaha la xiriira", lana dhammeystiro ama laga joojiyo hawlaha halkaas ku qoran ka hor inta aan la dhamaystirin howlahan.`,
	th: `กิจกรรมไม่สามารถทําได้ในขณะที่กิจกรรมย่อยยังอยู่ในสถานะเปิดอยู่ ไปที่แท็บ "กิจกรรมที่เกี่ยวข้อง" และดําเนินการให้เสร็จสมบูรณ์หรือยกเลิกกิจกรรมที่ระบุไว้ก่อนที่จะทํากิจกรรมนี้ให้เสร็จสิ้น`,
	ur: `کسی سرگرمی کو اس وقت تک مکمل نہیں کیا جاسکتا جب تک کہ اس کی بچوں کی سرگرمیاں اب بھی کھلی حالت میں ہوں۔ "متعلقہ سرگرمیاں" ٹیب پر جائیں اور اس سرگرمی کو مکمل کرنے سے پہلے وہاں درج سرگرمیوں کو مکمل یا منسوخ کریں۔`,
	vi: `Một hoạt động không thể được hoàn thành trong khi các hoạt động con của nó vẫn ở trạng thái mở. Chuyển đến tab "Hoạt động liên kết" và hoàn tất hoặc hủy các hoạt động được liệt kê ở đó trước khi hoàn tất hoạt động này.`,
	'zh-hans': `当 Activity 的子 Activity 仍处于 open 状态时，无法完成该 Activity。导航到“Associated activities”选项卡，并在完成此活动之前完成或取消此处列出的活动。`,
	'zh-hant': `當 Activity 的子 Activity 仍處於 open 狀態時，無法完成該 Activity。導航到“Associated activities”選項卡，並在完成此活動之前完成或取消此處列出的活動。`,
	en: `An activity cannot be completed while its child activities are still in an open status. Navigate to the "Associated activities" tab and complete or cancel the activities listed there prior to completing this activity.`
};

export const loc_recommendation: Record<Locale, string> = {
	ar: `التوصيه`,
	de: `Empfehlung`,
	es: `Recomendación`,
	fa: `توصیه`,
	fil: `Rekomendasyon`,
	fr: `Recommandation`,
	hi: `सिफ़ारिश`,
	ht: `Rekòmandasyon`,
	it: `Raccomandazione`,
	ja: `勧告`,
	ko: `추천`,
	mww: `pom zoo`,
	ps: `توصیه`,
	pt: `Recomendação`,
	'pt-pt': `Recomendação`,
	ru: `Рекомендация`,
	sm: `Fautuaga`,
	so: `Soo jeedin`,
	th: `การแนะนํา`,
	ur: `سفارش`,
	vi: `Khuyến nghị`,
	'zh-hans': `建议`,
	'zh-hant': `建議`,
	en: `Recommendation`
};

export const loc_resources: Record<Locale, string> = {
	ar: `موارد`,
	de: `Betriebsmittel`,
	es: `Recursos`,
	fa: `منابع`,
	fil: `Mga Sanggunian`,
	fr: `Ressources`,
	hi: `संसाधन`,
	ht: `Resous`,
	it: `Risorse`,
	ja: `リソース`,
	ko: `리소스`,
	mww: `Cov Chaw Muab Kev Pab`,
	ps: `سرچینې`,
	pt: `Recursos`,
	'pt-pt': `Recursos`,
	ru: `Ресурсы`,
	sm: `Punaoa`,
	so: `Khayraadka`,
	th: `ทรัพยากร`,
	ur: `وسائل`,
	vi: `Tài nguyên`,
	'zh-hans': `资源`,
	'zh-hant': `資源`,
	en: `Resources`
};

export const loc_contactSales: Record<Locale, string> = {
	ar: `الاتصال بالمبيعات`,
	de: `Vertrieb kontaktieren`,
	es: `Póngase en contacto con el departamento de`,
	fa: `تماس با فروش`,
	fil: `Makipag ugnay sa mga benta`,
	fr: `Contacter le service commercial`,
	hi: `बिक्री से संपर्क करें`,
	ht: `Lavant kontak`,
	it: `Contatta l'ufficio vendite`,
	ja: `販売担当に連絡`,
	ko: `구매 문의 및 상담`,
	mww: `Hu rau muag khoom`,
	ps: `د اړیکو د خرڅلاو`,
	pt: `Entre em contato com a equipe de vendas`,
	'pt-pt': `Entre em contato com o departamento de vendas`,
	ru: `Связаться с отделом продаж`,
	sm: `Faafesootai faatauga`,
	so: `iibka Contact`,
	th: `ติดต่อฝ่ายขาย`,
	ur: `فروخت سے رابطہ کریں`,
	vi: `Liên hệ bộ phận bán hàng`,
	'zh-hans': `联系销售人员`,
	'zh-hant': `聯繫銷售人員`,
	en: `Contact sales`
};

export const loc_trainingEditProfileDisabled: Record<Locale, TemplateResult> = {
	ar: html`تم تعطيل تحرير ملف التعريف على موقع التدريب. قم بتحرير ملف التعريف الخاص بك على <a href="https://cumulus.care/settings/profile">موقع الإنتاج</a> وستتم مزامنة تغييراتك مع موقع التدريب طوال الليل.`,
	de: html`Die Profilbearbeitung ist auf der Schulungswebsite deaktiviert. Bearbeiten Sie Ihr Profil auf der <a href="https://cumulus.care/settings/profile">Produktionsseite</a> und Ihre Änderungen werden über Nacht mit der Schulungsseite synchronisiert.`,
	es: html`La edición de perfiles está deshabilitada en el sitio de capacitación. Edite su perfil en el <a href="https://cumulus.care/settings/profile">sitio de producción</a> y sus cambios se sincronizarán con el sitio de capacitación durante la noche.`,
	fa: html`ویرایش نمایه در سایت آموزشی غیرفعال است. پروفایل خود را در <a href="https://cumulus.care/settings/profile">سایت تولید</a> ویرایش کنید و تغییرات شما یک شبه با سایت آموزشی همگام سازی می شود.`,
	fil: html`Hindi pinagana ang pag edit ng profile sa site ng pagsasanay. I edit ang iyong profile sa <a href="https://cumulus.care/settings/profile">site ng produksyon</a> at ang iyong mga pagbabago ay mai synchronize sa site ng pagsasanay magdamag.`,
	fr: html`L’édition du profil est désactivée sur le site de formation. Modifiez votre profil sur le <a href="https://cumulus.care/settings/profile">site de production</a> et vos modifications seront synchronisées avec le site de formation pendant la nuit.`,
	hi: html`प्रशिक्षण साइट पर प्रोफ़ाइल संपादन अक्षम किया गया है. <a href="https://cumulus.care/settings/profile">उत्पादन साइट</a> पर अपनी प्रोफ़ाइल संपादित करें और आपके परिवर्तन रातोंरात प्रशिक्षण साइट पर सिंक्रनाइज़ हो जाएंगे।`,
	ht: html`Pwofil koreksyon enfim sou sit la fòmasyon. Edit pwofil ou sou <a href="https://cumulus.care/settings/profile">sit pwodiksyon</a> an ak chanjman ou yo pral senkronize nan sit la fòmasyon lannwit lan.`,
	it: html`La modifica del profilo è disabilitata sul sito di formazione. Modifica il tuo profilo sul <a href="https://cumulus.care/settings/profile">sito di produzione</a> e le tue modifiche verranno sincronizzate con il sito di formazione durante la notte.`,
	ja: html`トレーニングサイトでプロファイルの編集が無効になっています。 <a href="https://cumulus.care/settings/profile">本番サイトで</a> プロファイルを編集すると、変更内容がトレーニングサイトに一晩で同期されます。`,
	ko: html`프로필 편집은 교육 사이트에서 사용할 수 없습니다. <a href="https://cumulus.care/settings/profile">프로덕션 사이트에서</a> 프로필을 편집하면 변경 사항이 하룻밤 사이에 교육 사이트에 동기화됩니다.`,
	mww: html`Profile editing yog xiam oob khab rau txoj kev kawm. Edit koj profile rau qhov <a href="https://cumulus.care/settings/profile">chaw ntau lawm</a> thiab koj kev hloov yuav synchronized rau txoj kev kawm thaum hmo ntuj.`,
	ps: html`د روزنې په ساحه کې د پروفایل ترمیم غیر فعال دی. د <a href="https://cumulus.care/settings/profile">تولید په سایټ</a> کې خپل پروفایل ایډیټ کړئ او ستاسو بدلونونه به د شپې لخوا د روزنې سایټ ته همغږی شی.`,
	pt: html`A edição de perfil está desativada no site de treinamento. Edite seu perfil no <a href="https://cumulus.care/settings/profile">site de produção</a> e suas alterações serão sincronizadas com o site de treinamento durante a noite.`,
	'pt-pt': html`A edição de perfil está desativada no site de treinamento. Edite seu perfil no <a href="https://cumulus.care/settings/profile">local de produção</a> e suas alterações serão sincronizadas com o local de treinamento durante a noite.`,
	ru: html`Редактирование профиля на обучающем сайте отключено. Отредактируйте свой профиль на <a href="https://cumulus.care/settings/profile">рабочем сайте</a> , и ваши изменения будут синхронизированы с учебным сайтом в одночасье.`,
	sm: html`Ua taofia le faasa'oina o talaaga otooto i luga o le saite o aoaoga. Faasa'o lou talaaga otooto i luga o le <a href="https://cumulus.care/settings/profile">saite o le gaosiga</a> ma o le a faalaugatasia au suiga i le saite o aoaoga i le po.`,
	so: html`Tafatirka profileku wuxuu ku naafo ka yahay goobta tababarka. Edit your profile <a href="https://cumulus.care/settings/profile">on the production site</a> and your changes be bedelis to the training site beriight.`,
	th: html`การแก้ไขโปรไฟล์ถูกปิดใช้งานบนไซต์การฝึกอบรม แก้ไขโปรไฟล์ของคุณบนเว็บไซต์ <a href="https://cumulus.care/settings/profile">การผลิต</a> และการเปลี่ยนแปลงของคุณจะถูกซิงโครไนซ์กับไซต์การฝึกอบรมในชั่วข้ามคืน`,
	ur: html`تربیتی سائٹ پر پروفائل ایڈیٹنگ غیر فعال ہے۔ <a href="https://cumulus.care/settings/profile">پروڈکشن سائٹ</a> پر اپنے پروفائل میں ترمیم کریں اور آپ کی تبدیلیاں راتوں رات تربیتی سائٹ سے مطابقت پذیر ہوں گی۔`,
	vi: html`Chỉnh sửa hồ sơ bị vô hiệu hóa trên trang web đào tạo. Chỉnh sửa hồ sơ của bạn trên <a href="https://cumulus.care/settings/profile">trang web sản xuất</a> và các thay đổi của bạn sẽ được đồng bộ hóa với trang web đào tạo qua đêm.`,
	'zh-hans': html`配置文件编辑在培训网站上被禁用。在 <a href="https://cumulus.care/settings/profile">生产站点</a> 上编辑您的配置文件，您的更改将在夜间同步到培训站点。`,
	'zh-hant': html`配置文件編輯在培訓網站上被禁用。在 <a href="https://cumulus.care/settings/profile">生產網站</a> 上編輯您的配置檔，您的更改將在夜間同步到培訓網站。`,
	en: html`Profile editing is disabled on the training site. Edit your profile on the <a href="https://cumulus.care/settings/profile">production site</a> and your changes will be synchronized to the training site overnight.`
};

export const loc_trainingSignUpDisabled: Record<Locale, TemplateResult> = {
	ar: html`تم تعطيل تسجيل حساب جديد على موقع التدريب. قم بالتسجيل في <a href="https://cumulus.care/sign-up">موقع الإنتاج</a> وستتم مزامنة حسابك مع موقع التدريب طوال الليل.`,
	de: html`Die Registrierung eines neuen Kontos ist auf der Schulungsseite deaktiviert. Melden Sie sich auf der <a href="https://cumulus.care/sign-up">Produktionsseite</a> an und Ihr Konto wird über Nacht mit der Schulungsseite synchronisiert.`,
	es: html`El registro de una nueva cuenta está deshabilitado en el sitio de capacitación. Regístrese en el sitio de <a href="https://cumulus.care/sign-up">producción</a> y su cuenta se sincronizará con el sitio de capacitación durante la noche.`,
	fa: html`ثبت نام حساب جدید در سایت آموزشی غیرفعال است. در <a href="https://cumulus.care/sign-up">سایت تولید</a> ثبت نام کنید و حساب شما یک شبه با سایت آموزشی همگام سازی می شود.`,
	fil: html`Ang bagong pagpaparehistro ng account ay hindi pinagana sa site ng pagsasanay. Mag sign up sa <a href="https://cumulus.care/sign-up">site ng produksyon</a> at ang iyong account ay mai synchronize sa site ng pagsasanay magdamag.`,
	fr: html`L’enregistrement d’un nouveau compte est désactivé sur le site de formation. Inscrivez-vous sur le <a href="https://cumulus.care/sign-up">site de production</a> et votre compte sera synchronisé avec le site de formation pendant la nuit.`,
	hi: html`प्रशिक्षण साइट पर नया खाता पंजीकरण अक्षम है। <a href="https://cumulus.care/sign-up">उत्पादन साइट</a> पर साइन अप करें और आपका खाता रात भर प्रशिक्षण स्थल पर सिंक्रनाइज़ हो जाएगा।`,
	ht: html`Nouvo enskripsyon kont enfim sou sit la fòmasyon. Enskri sou <a href="https://cumulus.care/sign-up">sit la pwodiksyon</a> ak kont ou yo pral senkronize nan sit la fòmasyon lannwit lan.`,
	it: html`La registrazione di un nuovo account è disabilitata sul sito di formazione. Registrati sul <a href="https://cumulus.care/sign-up">sito di produzione</a> e il tuo account verrà sincronizzato con il sito di formazione durante la notte.`,
	ja: html`トレーニングサイトでの新規アカウント登録が無効になります。 <a href="https://cumulus.care/sign-up">本番サイトに</a> サインアップすると、アカウントは一晩でトレーニングサイトに同期されます。`,
	ko: html`새 계정 등록은 교육 사이트에서 사용할 수 없습니다. <a href="https://cumulus.care/sign-up">프로덕션 사이트에</a> 등록하면 계정이 하룻밤 사이에 교육 사이트에 동기화됩니다.`,
	mww: html`Tus account tshiab yog xiam oob khab rau txoj kev kawm. Kos npe rau ntawm <a href="https://cumulus.care/sign-up">qhov chaw ntau lawm</a> thiab koj tus account yuav synchronized rau txoj kev kawm thaum hmo ntuj.`,
	ps: html`د روزنې په ساحه کې د نوی حساب ثبت غیر فعال دی. د <a href="https://cumulus.care/sign-up">تولید په سایټ</a> کې لاسلیک وکړئ او ستاسو حساب به د شپې روزنې سایټ ته همغږی شی.`,
	pt: html`O registro de nova conta está desabilitado no site de treinamento. Inscreva-se no <a href="https://cumulus.care/sign-up">site de produção</a> e sua conta será sincronizada com o site de treinamento durante a noite.`,
	'pt-pt': html`O registo de uma nova conta está desativado no site de formação. Inscreva-se no <a href="https://cumulus.care/sign-up">site de produção</a> e sua conta será sincronizada com o local de treinamento durante a noite.`,
	ru: html`Регистрация нового аккаунта на обучающем сайте отключена. Зарегистрируйтесь на <a href="https://cumulus.care/sign-up">производственном сайте</a> , и ваша учетная запись будет синхронизирована с учебным сайтом в одночасье.`,
	sm: html`Ua taofia le lesitalaga fou o le faitotoa i luga o le saite o aoaoga. Saini e alu i luga o le <a href="https://cumulus.care/sign-up">saite o le gaosiga</a> ma o le a faalaugatasia lau faitotoa i le saite o aoaoga i le po.`,
	so: html`Iska diiwaangelinta akawn cusub waxay ku naafo ka tahay goobta tababarka. Isku qor <a href="https://cumulus.care/sign-up">goobta wax soo saarka</a> iyo akoonkaaga waafaqsanaan goobta tababarka habeenkii.`,
	th: html`การลงทะเบียนบัญชีใหม่ถูกปิดใช้งานบนไซต์การฝึกอบรม ลงทะเบียนบนเว็บไซต์ <a href="https://cumulus.care/sign-up">การผลิต</a> และบัญชีของคุณจะถูกซิงเกจกับไซต์การฝึกอบรมในชั่วข้ามคืน`,
	ur: html`تربیتی سائٹ پر نیا اکاؤنٹ رجسٹریشن غیر فعال ہے۔ <a href="https://cumulus.care/sign-up">پروڈکشن سائٹ</a> پر سائن اپ کریں اور آپ کا اکاؤنٹ راتوں رات تربیتی سائٹ سے مطابقت پذیر ہوجائے گا۔`,
	vi: html`Đăng ký tài khoản mới bị vô hiệu hóa trên trang web đào tạo. Đăng ký trên <a href="https://cumulus.care/sign-up">trang web sản xuất</a> và tài khoản của bạn sẽ được đồng bộ hóa với trang web đào tạo qua đêm.`,
	'zh-hans': html`新帐户注册在培训网站上被禁用。在 <a href="https://cumulus.care/sign-up">生产站点</a> 上注册，您的帐户将在一夜之间同步到培训站点。`,
	'zh-hant': html`新帳戶註冊在培訓網站上被禁用。在 <a href="https://cumulus.care/sign-up">生產網站</a> 上註冊，您的帳戶將在一夜之間同步到培訓網站。`,
	en: html`New account registration is disabled on the training site. Sign up on the <a href="https://cumulus.care/sign-up">production site</a> and your account will be synchronized to the training site overnight.`
};

export const loc_trainingFlash: Record<Locale, TemplateResult> = {
	ar: html`<span class="text-bold">هذا الموقع هو لأغراض التدريب فقط.</span> تتم إعادة تعيين محتويات الموقع كل يوم سبت. ارجع إلى <a href="/docs/training-site">الوثائق</a> للحصول على مزيد من المعلومات أو انتقل إلى <a href="https://cumulus.care/">موقع الإنتاج</a>.`,
	de: html`<span class="text-bold">Diese Website dient nur zu Schulungszwecken.</span> Der Inhalt der Website wird jeden Samstag zurückgesetzt. Weitere Informationen finden Sie in der <a href="/docs/training-site">Dokumentation</a> oder am <a href="https://cumulus.care/">Produktionsstandort</a>.`,
	es: html`<span class="text-bold">Este sitio es solo para fines de capacitación.</span> El contenido del sitio se reinicia todos los sábados. Consulte la <a href="/docs/training-site">documentación</a> para obtener más información o vaya al sitio de <a href="https://cumulus.care/">producción</a>.`,
	fa: html`<span class="text-bold">این سایت فقط برای اهداف آموزشی است.</span> محتویات سایت هر شنبه بازنشانی می شود. برای اطلاعات بیشتر به <a href="/docs/training-site">مستندات</a> مراجعه کنید یا به <a href="https://cumulus.care/">سایت تولید</a> بروید.`,
	fil: html`<span class="text-bold">Ang site na ito ay para sa mga layunin ng pagsasanay lamang.</span> Ang mga nilalaman ng site ay na reset tuwing Sabado. Sumangguni sa <a href="/docs/training-site">dokumentasyon</a> para sa karagdagang impormasyon o pumunta sa <a href="https://cumulus.care/">site ng produksyon</a>.`,
	fr: html`<span class="text-bold">Ce site est uniquement destiné à des fins de formation.</span> Le contenu du site est réinitialisé tous les samedis. Reportez-vous à la <a href="/docs/training-site">documentation</a> pour plus d’informations ou rendez-vous sur le <a href="https://cumulus.care/">site de production</a>.`,
	hi: html`<span class="text-bold">यह साइट केवल प्रशिक्षण उद्देश्यों के लिए है।</span> साइट सामग्री प्रत्येक शनिवार को रीसेट की जाती है. अधिक जानकारी के लिए <a href="/docs/training-site">दस्तावेज़ीकरण</a> देखें या <a href="https://cumulus.care/">उत्पादन साइट</a> पर जाएं।`,
	ht: html`<span class="text-bold">Sit sa a se pou rezon fòmasyon sèlman.</span> Sit sa yo reyaji chak Samdi. Gade nan <a href="/docs/training-site">dokiman</a> an pou plis enfòmasyon oswa ale nan <a href="https://cumulus.care/">sit pwodiksyon</a> an.`,
	it: html`<span class="text-bold">Questo sito è solo a scopo formativo.</span> I contenuti del sito vengono ripristinati ogni sabato. Per ulteriori informazioni, consultare la <a href="/docs/training-site">documentazione</a> o visitare il <a href="https://cumulus.care/">sito di produzione</a>.`,
	ja: html`<span class="text-bold">このサイトはトレーニングのみを目的としています。</span> サイトの内容は毎週土曜日にリセットされます。詳細については、 <a href="/docs/training-site">ドキュメント</a> を参照するか、 <a href="https://cumulus.care/">運用サイト</a>にアクセスしてください。`,
	ko: html`<span class="text-bold">이 사이트는 교육 목적으로만 사용됩니다.</span> 사이트 콘텐츠는 매주 토요일에 재설정됩니다. 자세한 내용은 <a href="/docs/training-site">설명서를</a> 참조하거나 <a href="https://cumulus.care/">프로덕션 사이트</a>로 이동하십시오.`,
	mww: html`<span class="text-bold">Qhov chaw no yog rau txoj kev kawm xwb.</span> Cov ntsiab lus no reset txhua hnub Saturday. Xa mus rau cov <a href="/docs/training-site">ntaub ntawv</a> yog xav paub ntxiv los yog mus rau qhov <a href="https://cumulus.care/">chaw ntau lawm</a>.`,
	ps: html`<span class="text-bold">دا سایټ یوازې د روزنې موخو لپاره دی.</span> د سایټ مینځپانګې هره شنبه بیا تنظیم کیږی. د نورو معلوماتو لپاره <a href="/docs/training-site">اسناد</a> ته مراجعه وکړئ یا د <a href="https://cumulus.care/">تولید سایټ</a> ته لاړشئ.`,
	pt: html`<span class="text-bold">Este site é apenas para fins de treinamento.</span> O conteúdo do site é redefinido todos os sábados. Consulte a <a href="/docs/training-site">documentação</a> para obter mais informações ou vá para o <a href="https://cumulus.care/">local de produção</a>.`,
	'pt-pt': html`<span class="text-bold">Este site é apenas para fins de formação.</span> O conteúdo do site é redefinido todos os sábados. Consulte a <a href="/docs/training-site">documentação</a> para obter mais informações ou vá para o <a href="https://cumulus.care/">local de produção</a>.`,
	ru: html`<span class="text-bold">Этот сайт предназначен только для учебных целей.</span> Содержимое сайта обнуляется каждую субботу. Обратитесь к <a href="/docs/training-site">документации</a> для получения дополнительной информации или перейдите на <a href="https://cumulus.care/">производственную площадку</a>.`,
	sm: html`O <span class="text-bold">lenei saite e mo na o faamoemoega faaleaoaoga.</span> E toe seti anotusi o le saite i Aso Toonai uma. Tagai i le <a href="/docs/training-site">pepa o faamatalaga</a> mo nisi faamatalaga pe alu i le <a href="https://cumulus.care/">saite o le gaosiga</a>.`,
	so: html`<span class="text-bold">This site is for training purposes is only.</span> Waxyaabaha ku jira bogga ayaa dib loo bilaabayaa Sabti kasta. Tixraac <a href="/docs/training-site">dukumentiyada</a> aad ka helayso macluumaad dheeraad ah ama tag <a href="https://cumulus.care/">goobta wax soo saarka</a>.`,
	th: html`<span class="text-bold">เว็บไซต์นี้มีวัตถุประสงค์เพื่อการฝึกอบรมเท่านั้น</span> เนื้อหาของเว็บไซต์จะถูกรีเซ็ตทุกวันเสาร์ โปรดดู<a href="/docs/training-site">เอกสารประกอบ</a>สําหรับข้อมูลเพิ่มเติมหรือไปที่<a href="https://cumulus.care/">ไซต์การผลิต</a>`,
	ur: html`<span class="text-bold">یہ سائٹ صرف تربیتی مقاصد کے لئے ہے.</span> سائٹ کے مندرجات کو ہر ہفتے دوبارہ ترتیب دیا جاتا ہے۔ مزید معلومات کے لئے <a href="/docs/training-site">دستاویزات</a> کا حوالہ دیں یا <a href="https://cumulus.care/">پروڈکشن سائٹ</a> پر جائیں.`,
	vi: html`<span class="text-bold">Trang web này chỉ dành cho mục đích đào tạo.</span> Nội dung trang web được đặt lại vào thứ Bảy hàng tuần. Tham khảo <a href="/docs/training-site">tài liệu</a> để biết thêm thông tin hoặc đến <a href="https://cumulus.care/">địa điểm sản xuất</a>.`,
	'zh-hans': html`<span class="text-bold">本网站仅用于培训目的。</span> 网站内容每周六重置。有关更多信息，请参阅 <a href="/docs/training-site">文档</a> 或转到 <a href="https://cumulus.care/">生产站点</a>。`,
	'zh-hant': html`<span class="text-bold">本網站僅用於培訓目的。</span> 網站內容每週六重置。有關更多資訊，請參閱 <a href="/docs/training-site">文檔</a> 或轉到 <a href="https://cumulus.care/">生產網站</a>。`,
	en: html`<span class="text-bold">This site is for training purposes only.</span> Site contents are reset every Saturday. Refer to the <a href="/docs/training-site">documentation</a> for more information or go to the <a href="https://cumulus.care/">production site</a>.`
};

export const loc_trainingWelcome: Record<Locale, (cumulus: unknown) => string> = {
	ar: (cumulus) => `مرحبا بكم في موقع تدريب ${cumulus} !`,
	de: (cumulus) => `Willkommen auf der ${cumulus} !`,
	es: (cumulus) => `¡Bienvenidos al sitio de entrenamiento de ${cumulus} !`,
	fa: (cumulus) => `به سایت آموزش ${cumulus} خوش آمدید!`,
	fil: (cumulus) => `Maligayang pagdating sa ${cumulus} training site!`,
	fr: (cumulus) => `Bienvenue sur le site de formation ${cumulus} !`,
	hi: (cumulus) => `${cumulus} प्रशिक्षण साइट में आपका स्वागत है!`,
	ht: (cumulus) => `Byenveni nan sit la Fòmasyon ${cumulus} !`,
	it: (cumulus) => `Benvenuti nel sito di formazione ${cumulus} !`,
	ja: (cumulus) => `${cumulus} トレーニングサイトへようこそ!`,
	ko: (cumulus) => `${cumulus} 교육 사이트에 오신 것을 환영합니다!`,
	mww: (cumulus) => `Txais ${cumulus} kawm site!`,
	ps: (cumulus) => `د ${cumulus} روزنې سایټ ته ښه راغلاست!`,
	pt: (cumulus) => `Bem-vindo ao site de treinamento ${cumulus} !`,
	'pt-pt': (cumulus) => `Bem-vindo ao site de formação ${cumulus} !`,
	ru: (cumulus) => `Добро пожаловать на обучающий сайт ${cumulus} !`,
	sm: (cumulus) => `Susu maia i le nofoaga o aoaoga a ${cumulus} !`,
	so: (cumulus) => `Ku soo dhawow goobta tababarka ${cumulus} ah!`,
	th: (cumulus) => `ยินดีต้อนรับสู่เว็บไซต์ฝึกอบรม ${cumulus} !`,
	ur: (cumulus) => `${cumulus} تربیتی سائٹ میں خوش آمدید!`,
	vi: (cumulus) => `Chào mừng bạn đến với trang web đào tạo ${cumulus} !`,
	'zh-hans': (cumulus) => `欢迎来到 ${cumulus} 培训网站！`,
	'zh-hant': (cumulus) => `歡迎來到 ${cumulus} 培訓網站！`,
	en: (cumulus) => `Welcome to the ${cumulus} training site!`
};

export const loc_iUnderstand: Record<Locale, string> = {
	ar: `أفهم`,
	de: `Ich verstehe`,
	es: `Entiendo`,
	fa: `می‌فهمم`,
	fil: `Naiintindihan ko`,
	fr: `Je comprends`,
	hi: `मै समझता/ती हुँ`,
	ht: `Mwen konprann`,
	it: `Capisco`,
	ja: `分かりました`,
	ko: `이해합니다`,
	mww: `Kuv nkag siab`,
	ps: `زه پوهېږم`,
	pt: `Eu entendo`,
	'pt-pt': `Eu entendo`,
	ru: `Я понимаю`,
	sm: `Oute malamalama`,
	so: `Waan ku fahmaa`,
	th: `ฉันเข้าใจ`,
	ur: `ميں سمجھ سکتی ہوں`,
	vi: `Tôi hiểu`,
	'zh-hans': `我理解`,
	'zh-hant': `我理解`,
	en: `I understand`
};

export const loc_productionSite: Record<Locale, string> = {
	ar: `موقع الإنتاج`,
	de: `Produktionsort`,
	es: `Planta de producción`,
	fa: `سایت تولید`,
	fil: `Site ng produksyon`,
	fr: `Site de production`,
	hi: `उत्पादन स्थल`,
	ht: `Sit Pwodiksyon`,
	it: `Sito produttivo`,
	ja: `生産拠点`,
	ko: `생산 현장`,
	mww: `Ntau lawm qhov chaw`,
	ps: `د تولید ځای`,
	pt: `Local de produção`,
	'pt-pt': `Local de produção`,
	ru: `Производственная площадка`,
	sm: `Nofoaga e gaosi ai`,
	so: `Goobta wax soo saarka`,
	th: `สถานที่ผลิต`,
	ur: `پیداوار کی جگہ`,
	vi: `Địa điểm sản xuất`,
	'zh-hans': `生产现场`,
	'zh-hant': `生產現場`,
	en: `Production site`
};

export const loc_explore: Record<Locale, string> = {
	ar: `استكشف`,
	de: `Erforschen`,
	es: `Explorar`,
	fa: `کشف`,
	fil: `Galugarin ang`,
	fr: `Explorer`,
	hi: `पता लगाना`,
	ht: `Eksplore`,
	it: `Esplorare`,
	ja: `探る`,
	ko: `탐험하다`,
	mww: `Tshawb xyuas`,
	ps: `سپړنه`,
	pt: `Explorar`,
	'pt-pt': `Explorar`,
	ru: `Исследовать`,
	sm: `Suesue`,
	so: `Baadh`,
	th: `สํารวจ`,
	ur: `دریافت کریں`,
	vi: `Khám phá`,
	'zh-hans': `探讨`,
	'zh-hant': `探討`,
	en: `Explore`
};

export const loc_getMoreInformation: Record<Locale, string> = {
	ar: `الحصول على مزيد من المعلومات`,
	de: `Mehr Informationen`,
	es: `Más información`,
	fa: `اطلاعات بیشتر دریافت کنید`,
	fil: `Kumuha ng karagdagang impormasyon`,
	fr: `Obtenir plus d’informations`,
	hi: `अधिक जानकारी प्राप्त करें`,
	ht: `Jwenn plis enfòmasyon`,
	it: `Richiedi maggiori informazioni`,
	ja: `詳細情報`,
	ko: `자세한 정보 보기`,
	mww: `Yog xav paub ntxiv`,
	ps: `نور معلومات ترلاسه کړئ`,
	pt: `Obtenha mais informações`,
	'pt-pt': `Obtenha mais informações`,
	ru: `Получить дополнительную информацию`,
	sm: `Ia maua nisi faamatalaga`,
	so: `Hel macluumaad dheeraad ah`,
	th: `ดูข้อมูลเพิ่มเติม`,
	ur: `مزید معلومات حاصل کریں`,
	vi: `Nhận thêm thông tin`,
	'zh-hans': `获取更多信息`,
	'zh-hant': `獲取更多資訊`,
	en: `Get more information`
};

export const loc_goToEvent: Record<Locale, string> = {
	ar: `انتقل إلى الحدث`,
	de: `Zur Veranstaltung`,
	es: `Ir al evento`,
	fa: `به رویداد بروید`,
	fil: `Pumunta sa event`,
	fr: `Aller à l’événement`,
	hi: `कार्यक्रम पर जाएँ`,
	ht: `Ale nan evènman`,
	it: `Vai all'evento`,
	ja: `イベントに移動`,
	ko: `이벤트 바로가기`,
	mww: `Mus kev tshwm sim`,
	ps: `پېښې ته ورتلل`,
	pt: `Ir para o evento`,
	'pt-pt': `Ir para o evento`,
	ru: `Перейти к событию`,
	sm: `Alu i le gaoioiga`,
	so: `Tag dhacdo`,
	th: `ไปที่กิจกรรม`,
	ur: `تقریب میں جائیں`,
	vi: `Đi đến sự kiện`,
	'zh-hans': `前往活动`,
	'zh-hant': `前往活動`,
	en: `Go to event`
};

export const loc_addAnotherActivityContent: Record<Locale, (addNextHref: unknown, activityTitle: unknown, parentHref: unknown, parentActivityTitle: unknown) => TemplateResult> = {
	ar: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">أضف ${activityTitle}</a> إلى <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>.`,
	de: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">Fügen Sie eine weitere ${activityTitle}</a> zur <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a> hinzu.`,
	es: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">Agregue otra ${activityTitle}</a> al <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>.`,
	fa: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">یک ${activityTitle} دیگر</a> را به <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a> اضافه کنید.`,
	fil: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">Magdagdag ng isa pang ${activityTitle}</a> sa <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>.`,
	fr: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">Ajoutez un autre ${activityTitle}</a> à l’événement <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>.`,
	hi: (parentHref, parentActivityTitle, addNextHref, activityTitle) => html`<a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a> में <a class="text-bold" href="${addNextHref}">एक और ${activityTitle} जोड़ें</a>।`,
	ht: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">Ajoute yon lòt ${activityTitle}</a> nan <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>.`,
	it: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">Aggiungi un altro ${activityTitle}</a> <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>.`,
	ja: (parentHref, parentActivityTitle, addNextHref, activityTitle) => html`<a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>に<a class="text-bold" href="${addNextHref}">別の${activityTitle}を追加します</a>。`,
	ko: (parentHref, parentActivityTitle, addNextHref, activityTitle) => html`<a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a> <a class="text-bold" href="${addNextHref}">또 다른 ${activityTitle}를 추가</a>하세요.`,
	mww: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">Ntxiv lwm ${activityTitle}</a> rau <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>.`,
	ps: (parentHref, parentActivityTitle, addNextHref, activityTitle) => html`د <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a> ته <a class="text-bold" href="${addNextHref}">د ${activityTitle} اضافه کړئ</a>.`,
	pt: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">Adicione outra ${activityTitle}</a> ao <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>.`,
	'pt-pt': (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">Adicione outra ${activityTitle}</a> ao <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>.`,
	ru: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">Добавьте еще один ${activityTitle}</a> в <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>.`,
	sm: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">Faaopoopo se isi ${activityTitle}</a> i le <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>.`,
	so: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">Ku dar ${activityTitle}</a> ah <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>.`,
	th: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">เพิ่ม${activityTitle}อีกครั้งใน</a><a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>`,
	ur: (parentHref, parentActivityTitle, addNextHref, activityTitle) => html`<a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a> <a class="text-bold" href="${addNextHref}">ایک اور ${activityTitle} شامل کریں</a>۔`,
	vi: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">Thêm một ${activityTitle} khác</a> vào <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>.`,
	'zh-hans': (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">将另一个${activityTitle}添加到</a><a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>中。`,
	'zh-hant': (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">將另一個${activityTitle}添加到</a><a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>中。`,
	en: (addNextHref, activityTitle, parentHref, parentActivityTitle) => html`<a class="text-bold" href="${addNextHref}">Add another ${activityTitle}</a> to <a href="${parentHref}" class="Link--primary">${parentActivityTitle}</a>.`
};

export const loc_created: Record<Locale, string> = {
	ar: `انشاء`,
	de: `Erstellt`,
	es: `Creado`,
	fa: `ایجاد`,
	fil: `Nilikha`,
	fr: `Créé`,
	hi: `बनाया`,
	ht: `Te kreye`,
	it: `Creato`,
	ja: `作成`,
	ko: `만든`,
	mww: `Tsim`,
	ps: `جوړ شوی`,
	pt: `Criado`,
	'pt-pt': `Criado`,
	ru: `Созданный`,
	sm: `Foafoaina`,
	so: `Wuxuu abuuray`,
	th: `สร้าง`,
	ur: `بنائی`,
	vi: `Tạo`,
	'zh-hans': `创建`,
	'zh-hant': `創建`,
	en: `Created`
};

export const loc_recommended: Record<Locale, string> = {
	ar: `اوصت`,
	de: `Empfohlen`,
	es: `Recomendado`,
	fa: `توصیه`,
	fil: `Inirerekomenda`,
	fr: `Recommandé`,
	hi: `अनुशंसित`,
	ht: `Rekòmande`,
	it: `Raccomandato`,
	ja: `推奨`,
	ko: `권장`,
	mww: `pom zoo`,
	ps: `سپارښتلی`,
	pt: `Recomendado`,
	'pt-pt': `Recomendado`,
	ru: `Рекомендованный`,
	sm: `Fautuaina`,
	so: `Talinayo`,
	th: `แนะ นำ`,
	ur: `تجویز`,
	vi: `Khuyến cáo`,
	'zh-hans': `推荐`,
	'zh-hant': `推薦`,
	en: `Recommended`
};

export const loc_more: Record<Locale, string> = {
	ar: `أكثر`,
	de: `Mehr`,
	es: `Más`,
	fa: `بیشتر`,
	fil: `Higit pa`,
	fr: `Plus`,
	hi: `अधिक`,
	ht: `Plis`,
	it: `Più`,
	ja: `もっとその`,
	ko: `더`,
	mww: `Ntau`,
	ps: `په اړه`,
	pt: `Mais`,
	'pt-pt': `Saber mais`,
	ru: `Больше`,
	sm: `Isi`,
	so: `Inbadan`,
	th: `อีก`,
	ur: `زیادہ`,
	vi: `Nhiều hơn`,
	'zh-hans': `更多`,
	'zh-hant': `更多`,
	en: `More`
};

export const loc_goBack: Record<Locale, string> = {
	ar: `الرجوع للخلف`,
	de: `Zurück`,
	es: `Volver`,
	fa: `برگرد`,
	fil: `Bumalik ka na`,
	fr: `Retour`,
	hi: `पस जाओ`,
	ht: `Tounen`,
	it: `Indietro`,
	ja: `戻ってください`,
	ko: `돌아 가`,
	mww: `Mus rov qab`,
	ps: `بیرته لاړ شه`,
	pt: `Voltar`,
	'pt-pt': `Volta`,
	ru: `Вернуться`,
	sm: `Toe fo'i`,
	so: `Soo laabo`,
	th: `ย้อนกลับ`,
	ur: `واپس جاؤ`,
	vi: `Quay lại`,
	'zh-hans': `回去`,
	'zh-hant': `回去`,
	en: `Go back`
};

export const loc_performance: Record<Locale, string> = {
	ar: `اداء`,
	de: `Leistung`,
	es: `Rendimiento`,
	fa: `عملکرد`,
	fil: `Pagganap`,
	fr: `Performance`,
	hi: `प्रदर्शन`,
	ht: `Pèfòmans`,
	it: `Prestazione`,
	ja: `パフォーマンス`,
	ko: `공연`,
	mww: `Kev Kawm Txog Yam Xeeb`,
	ps: `فعالیت`,
	pt: `Desempenho`,
	'pt-pt': `Desempenho`,
	ru: `Производительность`,
	sm: `Faatinoga`,
	so: `Performance`,
	th: `การแสดง`,
	ur: `کارکردگی`,
	vi: `Hiệu năng`,
	'zh-hans': `性能`,
	'zh-hant': `性能`,
	en: `Performance`
};

export const loc_status: Record<Locale, string> = {
	ar: `حالة`,
	de: `Status`,
	es: `Estado`,
	fa: `وضعیت`,
	fil: `Katayuan`,
	fr: `Statut`,
	hi: `ओहदा`,
	ht: `Estati`,
	it: `Stato`,
	ja: `地位`,
	ko: `상태`,
	mww: `txheej xwm`,
	ps: `انکړ`,
	pt: `Estado`,
	'pt-pt': `Situação`,
	ru: `Статус`,
	sm: `Tulaga`,
	so: `Status`,
	th: `สถานะ`,
	ur: `پایہ`,
	vi: `Tình trạng`,
	'zh-hans': `地位`,
	'zh-hant': `地位`,
	en: `Status`
};

export const loc_activity: Record<Locale, string> = {
	ar: `نشاط`,
	de: `Aktivität`,
	es: `Actividad`,
	fa: `فعالیت`,
	fil: `Aktibidad`,
	fr: `Activité`,
	hi: `गतिविधि`,
	ht: `Aktivite`,
	it: `Attività`,
	ja: `活動`,
	ko: `활동`,
	mww: `kev ua ub no`,
	ps: `فعالیت`,
	pt: `Atividade`,
	'pt-pt': `Atividade`,
	ru: `Активность`,
	sm: `Gaoioiga`,
	so: `Waxqabad`,
	th: `กิจกรรม`,
	ur: `سرگرمی`,
	vi: `Hoạt động`,
	'zh-hans': `活动`,
	'zh-hant': `活動`,
	en: `Activity`
};

export const loc_creator: Record<Locale, string> = {
	ar: `خالق`,
	de: `Schöpfer`,
	es: `Creador`,
	fa: `خالق`,
	fil: `Lumikha`,
	fr: `Créateur`,
	hi: `सृजनकर्ता`,
	ht: `Kreyatè`,
	it: `Creatore`,
	ja: `造物主`,
	ko: `창조자`,
	mww: `Tswv tsim`,
	ps: `جوړوونکی`,
	pt: `Criador`,
	'pt-pt': `Criador`,
	ru: `Создатель`,
	sm: `Foafoa`,
	so: `abuuraha`,
	th: `ผู้สร้าง`,
	ur: `خالق`,
	vi: `Người sáng tạo`,
	'zh-hans': `造物主`,
	'zh-hant': `造物主`,
	en: `Creator`
};

export const loc_mentions: Record<Locale, string> = {
	ar: `يذكر`,
	de: `Erwähnungen`,
	es: `Menciona`,
	fa: `اشاره`,
	fil: `Nabanggit ang mga`,
	fr: `Mentionne`,
	hi: `उल्लेख`,
	ht: `Mansyone`,
	it: `Cita`,
	ja: `言及`,
	ko: `언급`,
	mww: `Pav`,
	ps: `د`,
	pt: `Menciona`,
	'pt-pt': `Menções`,
	ru: `Упоминает`,
	sm: `Taua`,
	so: `ku xuso`,
	th: `กล่าวถึง`,
	ur: `ذکر`,
	vi: `Mentions`,
	'zh-hans': `提到`,
	'zh-hant': `提到`,
	en: `Mentions`
};

export const loc_project: Record<Locale, string> = {
	ar: `مشروع`,
	de: `Projekt`,
	es: `Proyecto`,
	fa: `پروژه`,
	fil: `Proyekto`,
	fr: `Projet`,
	hi: `परियोजना`,
	ht: `Pwojè`,
	it: `Progetto`,
	ja: `プロジェクト`,
	ko: `프로젝트`,
	mww: `peb tes num`,
	ps: `پروژه`,
	pt: `Projeto`,
	'pt-pt': `Projeto`,
	ru: `Проект`,
	sm: `Galuega Faatino`,
	so: `Mashruuca`,
	th: `โครงการ`,
	ur: `پراجیکٹ`,
	vi: `Dự án`,
	'zh-hans': `项目`,
	'zh-hant': `專案`,
	en: `Project`
};

export const loc_type: Record<Locale, string> = {
	ar: `نوع`,
	de: `Art`,
	es: `Tipo`,
	fa: `نوع`,
	fil: `Uri ng`,
	fr: `Type`,
	hi: `प्रकार`,
	ht: `Tip`,
	it: `Digitare`,
	ja: `種類`,
	ko: `형`,
	mww: `Yam`,
	ps: `ډول`,
	pt: `Tipo`,
	'pt-pt': `Tipo`,
	ru: `Тип`,
	sm: `Ituaiga`,
	so: `Nooca`,
	th: `ประเภท`,
	ur: `قسم`,
	vi: `Kiểu`,
	'zh-hans': `类型`,
	'zh-hant': `類型`,
	en: `Type`
};

export const loc_quickFilters: Record<Locale, string> = {
	ar: `مرشحات سريعة`,
	de: `Schnellfilter`,
	es: `Filtros rápidos`,
	fa: `فیلترهای سریع`,
	fil: `Mabilis na mga filter`,
	fr: `Filtres rapides`,
	hi: `त्वरित फ़िल्टर`,
	ht: `Filtè rapid`,
	it: `Filtri rapidi`,
	ja: `クイックフィルター`,
	ko: `퀵 필터`,
	mww: `lim dej`,
	ps: `چټک فلټرونه`,
	pt: `Filtros rápidos`,
	'pt-pt': `Filtros rápidos`,
	ru: `Быстрые фильтры`,
	sm: `Faamama vave`,
	so: `filtarrada Quick`,
	th: `ตัวกรองด่วน`,
	ur: `فوری فلٹرز`,
	vi: `Bộ lọc nhanh`,
	'zh-hans': `快速过滤器`,
	'zh-hant': `快速篩檢程式`,
	en: `Quick filters`
};

export const loc_chooseActivity: Record<Locale, string> = {
	ar: `اختر النشاط`,
	de: `Aktivität auswählen`,
	es: `Elegir actividad`,
	fa: `فعالیت را انتخاب کنید`,
	fil: `Pumili ng aktibidad`,
	fr: `Choisissez l’activité`,
	hi: `गतिविधि चुनें`,
	ht: `Chwazi aktivite`,
	it: `Scegli l'attività`,
	ja: `アクティビティを選択`,
	ko: `활동 선택`,
	mww: `Xaiv cov kev ua ub no`,
	ps: `فعالیت غوره کړئ`,
	pt: `Escolha a atividade`,
	'pt-pt': `Escolha a atividade`,
	ru: `Выберите занятие`,
	sm: `Filifili le gaoioiga`,
	so: `Dooro waxqabad`,
	th: `เลือกกิจกรรม`,
	ur: `سرگرمی کا انتخاب کریں`,
	vi: `Chọn hoạt động`,
	'zh-hans': `选择活动`,
	'zh-hant': `選擇活動`,
	en: `Choose activity`
};

export const loc_chooseType: Record<Locale, string> = {
	ar: `اختر النوع`,
	de: `Typ wählen`,
	es: `Elegir tipo`,
	fa: `نوع را انتخاب کنید`,
	fil: `Pumili ng uri`,
	fr: `Choisissez le type`,
	hi: `प्रकार चुनें`,
	ht: `Chwazi kalite`,
	it: `Scegli il tipo`,
	ja: `タイプを選択`,
	ko: `유형 선택`,
	mww: `Xaiv hom`,
	ps: `ډول چوڼل`,
	pt: `Escolha o tipo`,
	'pt-pt': `Escolha o tipo`,
	ru: `Выберите тип`,
	sm: `Filifili le ituaiga`,
	so: `Dooro nooca`,
	th: `เลือกประเภท`,
	ur: `قسم کا انتخاب کریں`,
	vi: `Chọn loại`,
	'zh-hans': `选择类型`,
	'zh-hant': `選擇類型`,
	en: `Choose type`
};

export const loc_chooseProject: Record<Locale, string> = {
	ar: `اختر المشروع`,
	de: `Projekt auswählen`,
	es: `Elegir proyecto`,
	fa: `پروژه را انتخاب کنید`,
	fil: `Pumili ng proyekto`,
	fr: `Choisissez un projet`,
	hi: `प्रोजेक्ट चुनें`,
	ht: `Chwazi pwojè`,
	it: `Scegli il progetto`,
	ja: `プロジェクトを選択`,
	ko: `프로젝트 선택`,
	mww: `Xaiv yaam project`,
	ps: `پروژه غوره کړئ`,
	pt: `Escolha o projeto`,
	'pt-pt': `Escolher projeto`,
	ru: `Выбрать проект`,
	sm: `Filifili le galuega faatino`,
	so: `Dooro mashruuca`,
	th: `เลือกโครงการ`,
	ur: `منصوبے کا انتخاب کریں`,
	vi: `Chọn dự án`,
	'zh-hans': `选择项目`,
	'zh-hant': `選擇專案`,
	en: `Choose project`
};

export const loc_chooseAttachments: Record<Locale, string> = {
	ar: `اختيار المرفقات`,
	de: `Anhänge auswählen`,
	es: `Elegir archivos adjuntos`,
	fa: `پیوست ها را انتخاب کنید`,
	fil: `Pumili ng mga attachment`,
	fr: `Choisir les pièces jointes`,
	hi: `अनुलग्नक चुनें`,
	ht: `Chwazi atachman`,
	it: `Scegli gli allegati`,
	ja: `添付ファイルを選択する`,
	ko: `첨부 파일 선택`,
	mww: `Xaiv cov kev rhais nrog rau`,
	ps: `نښلنونه ټاکل`,
	pt: `Escolha anexos`,
	'pt-pt': `Escolher anexos`,
	ru: `Выберите навесное оборудование`,
	sm: `Filifili ni mea e faatatau i ai`,
	so: `Dooro lifaaqyada`,
	th: `เลือกไฟล์แนบ`,
	ur: `اٹیچمنٹ منتخب کریں`,
	vi: `Chọn tệp đính kèm`,
	'zh-hans': `选择附件`,
	'zh-hant': `選擇附件`,
	en: `Choose attachments`
};

export const loc_sort: Record<Locale, string> = {
	ar: `صنف`,
	de: `Sortieren`,
	es: `Ordenar`,
	fa: `نوع`,
	fil: `Ayusin ang`,
	fr: `Trier`,
	hi: `जाति`,
	ht: `Klase`,
	it: `Sorta`,
	ja: `ソート`,
	ko: `종류`,
	mww: `tsawm tsawv thiab`,
	ps: `اڼل`,
	pt: `Ordenar`,
	'pt-pt': `Ordenar`,
	ru: `Сортировать`,
	sm: `Faavasega`,
	so: `Kala sooc`,
	th: `ประเภท`,
	ur: `قسم`,
	vi: `Sắp xếp`,
	'zh-hans': `排序`,
	'zh-hant': `排序`,
	en: `Sort`
};

export const loc_chooseSort: Record<Locale, string> = {
	ar: `اختر فرز`,
	de: `Sortierung wählen`,
	es: `Elegir ordenar`,
	fa: `مرتب سازی را انتخاب کنید`,
	fil: `Pumili ng uri uriin`,
	fr: `Choisir le tri`,
	hi: `सॉर्ट चुनें`,
	ht: `Chwazi sòt`,
	it: `Scegli l'ordinamento`,
	ja: `ソートを選択`,
	ko: `정렬 선택`,
	mww: `Xaiv tsawm tsawv thiab`,
	ps: `د اڼلو اڼل`,
	pt: `Escolha classificar`,
	'pt-pt': `Escolher ordenação`,
	ru: `Выберите сортировку`,
	sm: `Filifili le ituaiga`,
	so: `Dooro sort`,
	th: `เลือกจัดเรียง`,
	ur: `قسم کا انتخاب کریں`,
	vi: `Chọn sắp xếp`,
	'zh-hans': `选择 sort`,
	'zh-hant': `選擇 sort`,
	en: `Choose sort`
};

export const loc_earliestStartDate: Record<Locale, string> = {
	ar: `أقرب تاريخ بدء`,
	de: `Frühester Starttermin`,
	es: `Fecha de inicio más temprana`,
	fa: `اولین تاریخ شروع`,
	fil: `Pinakamaagang petsa ng pagsisimula`,
	fr: `Date de début au plus tôt`,
	hi: `जल्द से जल्द शुरू होने की तारीख`,
	ht: `Dat kòmansman pi bonè`,
	it: `Prima data di inizio`,
	ja: `最も早い開始日`,
	ko: `가장 빠른 시작 날짜`,
	mww: `Hnub pib ua ntej pib`,
	ps: `د پیل کولو لومړنۍ نېټه`,
	pt: `Data de início mais próxima`,
	'pt-pt': `Data de início mais antiga`,
	ru: `Самая ранняя дата начала`,
	sm: `Aso muamua e amata ai`,
	so: `Taariikhda biloowga hore`,
	th: `วันที่เริ่มต้นเร็วที่สุด`,
	ur: `آغاز کی ابتدائی تاریخ`,
	vi: `Ngày bắt đầu sớm nhất`,
	'zh-hans': `最早的开始日期`,
	'zh-hant': `最早的開始日期`,
	en: `Earliest start date`
};

export const loc_latestStartDate: Record<Locale, string> = {
	ar: `آخر تاريخ بدء`,
	de: `Spätester Starttermin`,
	es: `Última fecha de inicio`,
	fa: `آخرین تاریخ شروع`,
	fil: `Pinakabagong petsa ng pagsisimula`,
	fr: `Date de début au plus tard`,
	hi: `नवीनतम प्रारंभ दिनांक`,
	ht: `Dènye dat kòmanse`,
	it: `Ultima data di inizio`,
	ja: `最も遅い開始日`,
	ko: `가장 늦은 시작 날짜`,
	mww: `Tseeb pib hnub`,
	ps: `د پیل وروستۍ نېټه`,
	pt: `Data de início mais recente`,
	'pt-pt': `Data de início mais recente`,
	ru: `Последняя дата начала`,
	sm: `Aso amata aupito lata mai`,
	so: `Taariikhda cusub ee ugu dambeysa`,
	th: `วันที่เริ่มต้นล่าสุด`,
	ur: `آغاز کی تازہ ترین تاریخ`,
	vi: `Ngày bắt đầu muộn nhất`,
	'zh-hans': `最晚开始日期`,
	'zh-hant': `最晚開始日期`,
	en: `Latest start date`
};

export const loc_earliestEndDate: Record<Locale, string> = {
	ar: `أقرب تاريخ انتهاء`,
	de: `Frühestes Enddatum`,
	es: `Fecha de finalización más temprana`,
	fa: `اولین تاریخ پایان`,
	fil: `Pinakamaagang petsa ng pagtatapos`,
	fr: `Date de fin la plus proche`,
	hi: `जल्द से जल्द समाप्ति तिथि`,
	ht: `Dat fen pi bonè`,
	it: `Prima data di fine`,
	ja: `最も早い終了日`,
	ko: `가장 빠른 종료 날짜`,
	mww: `Hnub xaus rau hnub kawg`,
	ps: `د پای لومړنۍ نېټه`,
	pt: `Data de término mais próxima`,
	'pt-pt': `Data de fim mais antiga`,
	ru: `Самая ранняя дата окончания`,
	sm: `Aso muamua e faamuta ai`,
	so: `Taariikhda dhamaadka hore`,
	th: `วันที่สิ้นสุดเร็วที่สุด`,
	ur: `سب سے پہلے اختتام کی تاریخ`,
	vi: `Ngày kết thúc sớm nhất`,
	'zh-hans': `最早结束日期`,
	'zh-hant': `最早結束日期`,
	en: `Earliest end date`
};

export const loc_latestEndDate: Record<Locale, string> = {
	ar: `آخر تاريخ انتهاء`,
	de: `Spätestes Enddatum`,
	es: `Última fecha de finalización`,
	fa: `آخرین تاریخ پایان`,
	fil: `Pinakabagong petsa ng pagtatapos`,
	fr: `Date de fin au plus tard`,
	hi: `नवीनतम समाप्ति दिनांक`,
	ht: `Dènye dat fen`,
	it: `Ultima data di fine`,
	ja: `最も遅い終了日`,
	ko: `최근 종료 날짜`,
	mww: `Tseeb hnub kawg`,
	ps: `وروستۍ پای نېټه`,
	pt: `Data de término mais recente`,
	'pt-pt': `Data de fim mais recente`,
	ru: `Последняя дата окончания`,
	sm: `Aso e gata ai`,
	so: `Taariikhda dhamaadka Latest`,
	th: `วันที่สิ้นสุดล่าสุด`,
	ur: `اختتام کی تازہ ترین تاریخ`,
	vi: `Ngày kết thúc muộn nhất`,
	'zh-hans': `最晚结束日期`,
	'zh-hant': `最晚結束日期`,
	en: `Latest end date`
};

export const loc_recentlyUpdated: Record<Locale, string> = {
	ar: `تم تحديثه مؤخرا`,
	de: `Kürzlich aktualisiert`,
	es: `Actualizado recientemente`,
	fa: `اخیرا به روز شده است`,
	fil: `Kamakailan lamang na update`,
	fr: `Récemment mis à jour`,
	hi: `हाल ही में अपडेट किया गया`,
	ht: `Dènyèman mete ajou`,
	it: `Aggiornato di recente`,
	ja: `最近の更新`,
	ko: `최근에 업데이트됨`,
	mww: `Nyuam qhuav kho tshiab`,
	ps: `وروستی تازه شوی`,
	pt: `Atualizado recentemente`,
	'pt-pt': `Atualizado recentemente`,
	ru: `Недавно обновлено`,
	sm: `Faafouina talu ai nei`,
	so: `Recently updated`,
	th: `อัพเดทล่าสุด`,
	ur: `حال ہی میں اپ ڈیٹ کیا گیا`,
	vi: `Cập nhật gần đây`,
	'zh-hans': `最近更新`,
	'zh-hant': `最近更新`,
	en: `Recently updated`
};

export const loc_leastRecentlyUpdated: Record<Locale, string> = {
	ar: `الأقل تحديثا مؤخرا`,
	de: `Zuletzt aktualisiert`,
	es: `Actualización menos reciente`,
	fa: `کمترین به روز رسانی اخیر`,
	fil: `Hindi bababa sa kamakailan lamang na update`,
	fr: `Dernière mise à jour`,
	hi: `सबसे कम हाल ही में अपडेट किया गया`,
	ht: `Omwen dènyèman mete ajou`,
	it: `Aggiornato meno di recente`,
	ja: `最も最近更新されていない`,
	ko: `가장 최근에 업데이트됨`,
	mww: `Tsawg nyuam qhuav muab kho tshiab`,
	ps: `لږ تر لږه تازه شوی`,
	pt: `Atualizado menos recentemente`,
	'pt-pt': `Atualizado menos recentemente`,
	ru: `Обновлено совсем недавно`,
	sm: `E lei leva ona faafouina`,
	so: `ugu yaraan dhawaan la cusbooneysiiyay`,
	th: `อัปเดตล่าสุดน้อยที่สุด`,
	ur: `کم از کم حال ہی میں اپ ڈیٹ کیا گیا`,
	vi: `Cập nhật ít nhất gần đây nhất`,
	'zh-hans': `最近更新最少`,
	'zh-hant': `最近更新最少`,
	en: `Least recently updated`
};

export const loc_recentlyCreated: Record<Locale, string> = {
	ar: `تم إنشاؤه مؤخرا`,
	de: `Kürzlich erstellt`,
	es: `De reciente creación`,
	fa: `اخیرا ایجاد شده است`,
	fil: `Kamakailan lamang na nilikha`,
	fr: `Récemment créé`,
	hi: `हाल ही में बनाया गया`,
	ht: `dènyèman te kreye`,
	it: `Creato di recente`,
	ja: `最近作成したもの`,
	ko: `최근에 생성됨`,
	mww: `Nyuam qhuav tsim`,
	ps: `تازه جوړ شوی`,
	pt: `Criado recentemente`,
	'pt-pt': `Recentemente criado`,
	ru: `Недавно созданные`,
	sm: `Na fatuina talu ai nei`,
	so: `Recently abuuray`,
	th: `สร้างล่าสุด`,
	ur: `حال ہی میں بنایا گیا`,
	vi: `Được tạo gần đây`,
	'zh-hans': `最近创建`,
	'zh-hant': `最近創建`,
	en: `Recently created`
};

export const loc_leastRecentlyCreated: Record<Locale, string> = {
	ar: `الأقل إنشاء مؤخرا`,
	de: `Am wenigsten kürzlich erstellt`,
	es: `Menos reciente`,
	fa: `کمترین ایجاد شده اخیر`,
	fil: `Hindi bababa sa kamakailan lamang na nilikha`,
	fr: `Moins récemment créé`,
	hi: `सबसे कम हाल ही में बनाया गया`,
	ht: `Omwen dènyèman te kreye`,
	it: `Creato meno di recente`,
	ja: `最も最近作成されていない`,
	ko: `가장 최근에 생성됨`,
	mww: `Tsis ntev los no nyuam qhuav tsim`,
	ps: `لږ تر لږه په دې وروستیو کې جوړ شوی`,
	pt: `Criado menos recentemente`,
	'pt-pt': `Menos recentemente criado`,
	ru: `Создано в последнее время`,
	sm: `E lei leva ona fatuina`,
	so: `Dhawaan la abuuray`,
	th: `สร้างล่าสุดน้อยที่สุด`,
	ur: `سب سے کم حال ہی میں بنایا گیا`,
	vi: `Được tạo gần đây nhất`,
	'zh-hans': `最近创建的最少`,
	'zh-hant': `最近創建的最少`,
	en: `Least recently created`
};

export const loc_chooseAssignee: Record<Locale, string> = {
	ar: `اختر المحال إليه`,
	de: `Beauftragten auswählen`,
	es: `Elegir persona asignada`,
	fa: `انتخاب گیرنده واگذار شده`,
	fil: `Pumili ng assignee`,
	fr: `Choisir la personne assignée`,
	hi: `असाइनी चुनें`,
	ht: `Chwazi plase`,
	it: `Scegli assegnatario`,
	ja: `担当者の選択`,
	ko: `담당자 선택`,
	mww: `Xaiv cov xaiv ntsej xaiv`,
	ps: `ټاکل شوی ټاکل`,
	pt: `Escolher responsável`,
	'pt-pt': `Escolha o cessionário`,
	ru: `Выберите исполнителя`,
	sm: `Filifili le tagata ua tofia`,
	so: `Dooro xildhiban`,
	th: `เลือกผู้รับมอบหมาย`,
	ur: `تفویض کا انتخاب کریں`,
	vi: `Chọn người được chỉ định`,
	'zh-hans': `选择任务分配对象`,
	'zh-hant': `選擇任務分配物件`,
	en: `Choose assignee`
};

export const loc_chooseCreator: Record<Locale, string> = {
	ar: `اختر منشئ المحتوى`,
	de: `Ersteller auswählen`,
	es: `Elegir creador`,
	fa: `سازنده را انتخاب کنید`,
	fil: `Pumili ng tagalikha`,
	fr: `Choisir le créateur`,
	hi: `निर्माता चुनें`,
	ht: `Chwazi kreyatè`,
	it: `Scegli il creatore`,
	ja: `クリエイターを選択`,
	ko: `크리에이터 선택`,
	mww: `Xaiv tus tswv tsim`,
	ps: `جوړوونکی غوره کړئ`,
	pt: `Escolha o criador`,
	'pt-pt': `Escolha o criador`,
	ru: `Выберите создателя`,
	sm: `Filifili le Foafoa`,
	so: `Dooro abuure`,
	th: `เลือกผู้สร้าง`,
	ur: `تخلیق کار کا انتخاب کریں`,
	vi: `Chọn người sáng tạo`,
	'zh-hans': `选择 creator`,
	'zh-hant': `選擇 creator`,
	en: `Choose creator`
};

export const loc_chooseMentionedUser: Record<Locale, string> = {
	ar: `اختر المستخدم المذكور`,
	de: `Erwähnten Benutzer auswählen`,
	es: `Elija el usuario mencionado`,
	fa: `کاربر ذکر شده را انتخاب کنید`,
	fil: `Pumili ng nabanggit na gumagamit`,
	fr: `Choisissez l’utilisateur mentionné`,
	hi: `उल्लेखित उपयोगकर्ता चुनें`,
	ht: `Chwazi itilizatè mansyone`,
	it: `Scegli l'utente menzionato`,
	ja: `メンションされたユーザーを選択`,
	ko: `멘션된 사용자 선택`,
	mww: `Xaiv cov neeg siv`,
	ps: `نومی کارن وچوڼئ`,
	pt: `Escolha o usuário mencionado`,
	'pt-pt': `Escolha o usuário mencionado`,
	ru: `Выберите указанного пользователя`,
	sm: `Filifili le tagata faaaoga ua ta'ua`,
	so: `Dooro user sheegay`,
	th: `เลือกผู้ใช้ที่กล่าวถึง`,
	ur: `ذکر کردہ صارف کا انتخاب کریں`,
	vi: `Chọn người dùng được đề cập`,
	'zh-hans': `选择提及的用户`,
	'zh-hant': `選擇提及的使用者`,
	en: `Choose mentioned user`
};

export const loc_openStatus: Record<Locale, string> = {
	ar: `الحالات المفتوحة`,
	de: `Offene Status`,
	es: `Estados abiertos`,
	fa: `وضعیت های باز`,
	fil: `Buksan ang mga status`,
	fr: `Statuts ouverts`,
	hi: `खुले हुए स्टेटस`,
	ht: `Estati louvri`,
	it: `Stati aperti`,
	ja: `オープンステータス`,
	ko: `열린 상태`,
	mww: `Qhib raws li txoj cai`,
	ps: `انکړونه`,
	pt: `Status em aberto`,
	'pt-pt': `Abrir estados`,
	ru: `Открытые статусы`,
	sm: `Tulaga tatala`,
	so: `Xaaladda furan`,
	th: `สถานะที่เปิดอยู่`,
	ur: `Open statuses`,
	vi: `Trạng thái mở`,
	'zh-hans': `打开状态`,
	'zh-hant': `打開狀態`,
	en: `Open statuses`
};

export const loc_closedStatus: Record<Locale, string> = {
	ar: `الحالات المغلقة`,
	de: `Geschlossene Status`,
	es: `Estados cerrados`,
	fa: `وضعیت های بسته`,
	fil: `Mga saradong status`,
	fr: `Statuts fermés`,
	hi: `बंद स्थितियां`,
	ht: `Estati fèmen`,
	it: `Stati chiusi`,
	ja: `クローズステータス`,
	ko: `닫힌 상태`,
	mww: `Kaw raws li txoj cai`,
	ps: `تړل شوی انکړونه`,
	pt: `Status fechados`,
	'pt-pt': `Status fechado`,
	ru: `Закрытые статусы`,
	sm: `Tulaga ua tapunia`,
	so: `Xaaladaha xiran`,
	th: `สถานะการปิด`,
	ur: `Closed statuses`,
	vi: `Trạng thái đóng`,
	'zh-hans': `已关闭状态`,
	'zh-hant': `已關閉狀態`,
	en: `Closed statuses`
};

export const loc_clearAll: Record<Locale, string> = {
	ar: `مسح الكل`,
	de: `Alle löschen`,
	es: `Borrar todo`,
	fa: `پاک کردن همه`,
	fil: `I-clear ang lahat`,
	fr: `Effacer tout`,
	hi: `सभी साफ करें`,
	ht: `Klè tout`,
	it: `Cancella tutto`,
	ja: `すべてクリア`,
	ko: `모두 지우기`,
	mww: `Tseeb tag nrho`,
	ps: `ټول پاک کړه`,
	pt: `Apagar tudo`,
	'pt-pt': `Apagar tudo`,
	ru: `Очистить все`,
	sm: `Tāpē uma`,
	so: `Dhammaan tirtir`,
	th: `ล้างทั้งหมด`,
	ur: `سب صاف کریں`,
	vi: `Xóa tất cả`,
	'zh-hans': `全部清除`,
	'zh-hant': `全部清除`,
	en: `Clear all`
};

export const loc_assignedToYou: Record<Locale, string> = {
	ar: `مخصص لك`,
	de: `Ihnen zugewiesen`,
	es: `Asignados a usted`,
	fa: `به شما اختصاص داده شده است`,
	fil: `Itinalaga sa iyo`,
	fr: `Attribué à vous`,
	hi: `आपको सौंपा गया`,
	ht: `Asiyen ou`,
	it: `Assegnato a te`,
	ja: `あなたに割り当て済み`,
	ko: `사용자에게 할당됨`,
	mww: `Txib kom koj ua raws li koj tau cog tseg`,
	ps: `تاسو ته سپارل شوی`,
	pt: `Atribuído a você`,
	'pt-pt': `Atribuído a você`,
	ru: `Закреплено за вами`,
	sm: `Tofia ia te oe`,
	so: `Astaamo laguu dhiibay`,
	th: `มอบหมายให้คุณ`,
	ur: `آپ کو تفویض کیا گیا ہے`,
	vi: `Được giao cho bạn`,
	'zh-hans': `分配给您`,
	'zh-hant': `分配給您`,
	en: `Assigned to you`
};

export const loc_assignedToYourOrganization: Record<Locale, string> = {
	ar: `تم تعيينه لمؤسستك`,
	de: `Ihrer Organisation zugewiesen`,
	es: `Asignado a su organización`,
	fa: `به سازمان شما اختصاص داده شده است`,
	fil: `Itinalaga sa iyong samahan`,
	fr: `Attribué à votre organisation`,
	hi: `आपके संगठन को असाइन किया गया`,
	ht: `Asiyen nan òganizasyon w lan`,
	it: `Assegnato alla tua organizzazione`,
	ja: `組織に割り当てられている`,
	ko: `조직에 할당됨`,
	mww: `Txib koj lub koom haum mus rau lub koom haum`,
	ps: `ستاسو سازمان ته سپارل شوی`,
	pt: `Atribuído à sua organização`,
	'pt-pt': `Atribuído à sua organização`,
	ru: `Назначено вашей организации`,
	sm: `Tofia i lau faalapotopotoga`,
	so: `Laguu daray ururkaaga`,
	th: `มอบหมายให้กับองค์กรของคุณ`,
	ur: `آپ کی تنظیم کو تفویض کیا گیا ہے`,
	vi: `Được gán cho tổ chức của bạn`,
	'zh-hans': `分配给您的组织`,
	'zh-hant': `分配給您的組織`,
	en: `Assigned to your organization`
};

export const loc_mentionsYou: Record<Locale, string> = {
	ar: `يذكرك`,
	de: `Erwähnt Sie`,
	es: `Te menciona`,
	fa: `از شما نام می برد`,
	fil: `Binabanggit ka`,
	fr: `Vous mentionne`,
	hi: `आपका उल्लेख करता है`,
	ht: `Mansyone ou`,
	it: `Ti menziona`,
	ja: `あなたをメンションする`,
	ko: `나를 멘션합니다.`,
	mww: `Koj hais li no:`,
	ps: `تاسو یادونه کوی`,
	pt: `Menciona você`,
	'pt-pt': `Menciona-o`,
	ru: `Упоминает вас`,
	sm: `Ta'ua oe`,
	so: `aad ku xuso`,
	th: `กล่าวถึงคุณ`,
	ur: `آپ کا ذکر کرتا ہے`,
	vi: `Đề cập đến bạn`,
	'zh-hans': `提及您`,
	'zh-hant': `提及您`,
	en: `Mentions you`
};

export const loc_createdByYou: Record<Locale, string> = {
	ar: `تم إنشاؤها بواسطتك`,
	de: `Von Ihnen erstellt`,
	es: `Creado por ti`,
	fa: `ایجاد شده توسط شما`,
	fil: `Nilikha mo`,
	fr: `Créé par vos soins`,
	hi: `आपके द्वारा बनाया गया`,
	ht: `Kreye pa ou`,
	it: `Creato da te`,
	ja: `あなたが作成したもの`,
	ko: `내가 만든 사람`,
	mww: `Tsim los ntawm koj`,
	ps: `ستاسو لخوا جوړ شوی`,
	pt: `Criado por você`,
	'pt-pt': `Criado por você`,
	ru: `Создано вами`,
	sm: `Na foafoaina e oe`,
	so: `Sidaana waxaad tihiin addoommuhu.`,
	th: `สร้างโดยคุณ`,
	ur: `آپ کے ذریعہ پیدا کیا گیا`,
	vi: `Được tạo bởi bạn`,
	'zh-hans': `由您创建`,
	'zh-hant': `由您創建`,
	en: `Created by you`
};

export const loc_createdByYourOrganization: Record<Locale, string> = {
	ar: `تم إنشاؤها بواسطة مؤسستك`,
	de: `Von Ihrer Organisation erstellt`,
	es: `Creado por su organización`,
	fa: `ایجاد شده توسط سازمان شما`,
	fil: `Nilikha ng iyong samahan`,
	fr: `Créé par votre organisation`,
	hi: `आपके संगठन द्वारा बनाया गया`,
	ht: `Kreye pa òganizasyon ou`,
	it: `Creato dalla tua organizzazione`,
	ja: `あなたの組織によって作成されました`,
	ko: `조직에서 생성됨`,
	mww: `Tsim los ntawm koj lub koom haum`,
	ps: `ستاسو د سازمان لخوا جوړ شوی`,
	pt: `Criado por sua organização`,
	'pt-pt': `Criado pela sua organização`,
	ru: `Создано вашей организацией`,
	sm: `Na fatuina e lau faalapotopotoga`,
	so: `Waxaa abuuray ururkaaga`,
	th: `สร้างโดยองค์กรของคุณ`,
	ur: `آپ کی تنظیم کی طرف سے بنایا گیا`,
	vi: `Được tạo bởi tổ chức của bạn`,
	'zh-hans': `由您的组织创建`,
	'zh-hant': `由您的組織創建`,
	en: `Created by your organization`
};

export const loc_allEvents: Record<Locale, string> = {
	ar: `جميع الأحداث`,
	de: `Alle Veranstaltungen`,
	es: `Todos los eventos`,
	fa: `همه رویدادها`,
	fil: `Lahat ng mga kaganapan`,
	fr: `Tous les événements`,
	hi: `सभी घटनाएँ`,
	ht: `Tout evènman`,
	it: `Tutti gli eventi`,
	ja: `すべてのイベント`,
	ko: `모든 이벤트`,
	mww: `Tag nrho cov txheej xwm`,
	ps: `ټولې پیښې`,
	pt: `Todos os eventos`,
	'pt-pt': `Todos os eventos`,
	ru: `Все события`,
	sm: `O mea uma e tutupu`,
	so: `Dhammaan dhacdooyinka`,
	th: `กิจกรรมทั้งหมด`,
	ur: `تمام واقعات`,
	vi: `Tất cả sự kiện`,
	'zh-hans': `所有活动`,
	'zh-hant': `所有活動`,
	en: `All events`
};

export const loc_allActivities: Record<Locale, string> = {
	ar: `جميع الأنشطة`,
	de: `Alle Aktivitäten`,
	es: `Todas las actividades`,
	fa: `همه فعالیت ها`,
	fil: `Lahat ng mga aktibidad`,
	fr: `Toutes les activités`,
	hi: `सभी गतिविधियां`,
	ht: `Tout aktivite yo`,
	it: `Tutte le attività`,
	ja: `すべてのアクティビティ`,
	ko: `모든 활동`,
	mww: `Tag nrho cov kev ua ub no`,
	ps: `ټول فعالیتونه`,
	pt: `Todas as atividades`,
	'pt-pt': `Todas as atividades`,
	ru: `Все виды деятельности`,
	sm: `Gaoioiga uma`,
	so: `Dhammaan Hawlaha`,
	th: `กิจกรรมทั้งหมด`,
	ur: `تمام سرگرمیاں`,
	vi: `Tất cả các hoạt động`,
	'zh-hans': `所有活动`,
	'zh-hant': `所有活動`,
	en: `All activities`
};

export const loc_previous: Record<Locale, string> = {
	ar: `سابق`,
	de: `Vorhergehend`,
	es: `Anterior`,
	fa: `قبلی`,
	fil: `Previous`,
	fr: `Précédent`,
	hi: `पिछला`,
	ht: `Ansyen`,
	it: `Precedente`,
	ja: `先の`,
	ko: `이전의`,
	mww: `yav dhau los`,
	ps: `مخکنی`,
	pt: `Anterior`,
	'pt-pt': `Anterior`,
	ru: `Предыдущий`,
	sm: `Muamua`,
	so: `Hore`,
	th: `ก่อน`,
	ur: `پچھلا`,
	vi: `Trước`,
	'zh-hans': `以前`,
	'zh-hant': `以前`,
	en: `Previous`
};

export const loc_next: Record<Locale, string> = {
	ar: `مقبل`,
	de: `Nächster`,
	es: `Próximo`,
	fa: `بعدی`,
	fil: `Susunod`,
	fr: `Prochain`,
	hi: `अगला`,
	ht: `Pwochen`,
	it: `Prossimo`,
	ja: `次に`,
	ko: `다음`,
	mww: `Lwm`,
	ps: `راتلونکی`,
	pt: `Próximo`,
	'pt-pt': `Seguinte`,
	ru: `Следующий`,
	sm: `Sosoo ai`,
	so: `Xiga`,
	th: `ต่อไป`,
	ur: `اگلا`,
	vi: `Sau`,
	'zh-hans': `下一个`,
	'zh-hant': `下一個`,
	en: `Next`
};

export const loc_previousPage: Record<Locale, string> = {
	ar: `الصفحة السابقة`,
	de: `Vorherige Seite`,
	es: `Página anterior`,
	fa: `صفحه قبلی`,
	fil: `Nakaraang pahina`,
	fr: `Page précédente`,
	hi: `पिछला पृष्ठ`,
	ht: `Paj anvan`,
	it: `Pagina precedente`,
	ja: `前のページへ`,
	ko: `이전 페이지`,
	mww: `nplooj ntawv yav dhau los`,
	ps: `مخنی مخ`,
	pt: `Página anterior`,
	'pt-pt': `Página anterior`,
	ru: `Предыдущая страница`,
	sm: `Itulau na muamua atu`,
	so: `Sidan hore`,
	th: `หน้าก่อนหน้า`,
	ur: `پچھلا صفحہ`,
	vi: `Trang trước`,
	'zh-hans': `上一页`,
	'zh-hant': `上一頁`,
	en: `Previous page`
};

export const loc_nextPage: Record<Locale, string> = {
	ar: `الصفحة التالية`,
	de: `Nächste Seite`,
	es: `Página siguiente`,
	fa: `صفحه بعدی`,
	fil: `Susunod na pahina`,
	fr: `Page suivante`,
	hi: `अगला पृष्ठ`,
	ht: `Pwochen paj`,
	it: `Pagina successiva`,
	ja: `次のページ`,
	ko: `다음 페이지`,
	mww: `nplooj ntawv tom ntej no ntxiv`,
	ps: `بله پاڼه`,
	pt: `Próxima página`,
	'pt-pt': `Página seguinte`,
	ru: `Следующая страница`,
	sm: `Itulau e sosoo ai`,
	so: `Sida xigta`,
	th: `หน้าถัดไป`,
	ur: `اگلا صفحہ`,
	vi: `Trang tiếp theo`,
	'zh-hans': `下一页`,
	'zh-hant': `下一頁`,
	en: `Next page`
};

export const loc_pagination: Record<Locale, string> = {
	ar: `تقسيم`,
	de: `Paginierung`,
	es: `Paginación`,
	fa: `صفحه بندی`,
	fil: `Pagination`,
	fr: `Pagination`,
	hi: `पृष्ठांकन`,
	ht: `Peginasyon`,
	it: `Impaginazione`,
	ja: `ページネーション`,
	ko: `페이지 매김`,
	mww: `pagination`,
	ps: `صفحه بندی`,
	pt: `Paginação`,
	'pt-pt': `Paginação`,
	ru: `Нумерация страниц`,
	sm: `Itulau`,
	so: `Pagination`,
	th: `เลขหน้า`,
	ur: `Pagination`,
	vi: `Pagination`,
	'zh-hans': `分页`,
	'zh-hant': `分頁`,
	en: `Pagination`
};

export const loc_female: Record<Locale, string> = {
	ar: `أنثى`,
	de: `Weiblich`,
	es: `Femenino`,
	fa: `زن`,
	fil: `Babae`,
	fr: `Femelle`,
	hi: `मादा`,
	ht: `Femèl`,
	it: `Femmina`,
	ja: `女性`,
	ko: `여성`,
	mww: `Poj`,
	ps: `ښځینه`,
	pt: `Fêmea`,
	'pt-pt': `Feminino`,
	ru: `Женский`,
	sm: `Tamaitai`,
	so: `Haweenka`,
	th: `หญิง`,
	ur: `مادہ`,
	vi: `Cái`,
	'zh-hans': `女性`,
	'zh-hant': `女性`,
	en: `Female`
};

export const loc_makeACopy: Record<Locale, string> = {
	ar: `عمل نسخة`,
	de: `Kopie erstellen`,
	es: `Hacer una copia`,
	fa: `یک کپی تهیه کنید`,
	fil: `Gumawa ng isang kopya`,
	fr: `Faire une copie`,
	hi: `एक कॉपी बनाएं`,
	ht: `Fè yon kopi`,
	it: `Crea una copia`,
	ja: `コピーを作成`,
	ko: `사본 만들기`,
	mww: `Luam ib daim qauv ntawm daim qauv`,
	ps: `لمېسه جوړه کړئ`,
	pt: `Faça uma cópia`,
	'pt-pt': `Fazer uma cópia`,
	ru: `Сделать копию`,
	sm: `Fai se kopi`,
	so: `Samee nuqul`,
	th: `ทําสําเนา`,
	ur: `ایک کاپی بنائیں`,
	vi: `Tạo một bản sao`,
	'zh-hans': `制作副本`,
	'zh-hant': `製作副本`,
	en: `Make a copy`
};

export const loc_allowCopying: Record<Locale, string> = {
	ar: `السماح بالنسخ`,
	de: `Kopieren erlauben`,
	es: `Permitir copia`,
	fa: `اجازه کپی کردن`,
	fil: `Payagan ang pagkopya`,
	fr: `Autoriser la copie`,
	hi: `कॉपी करने की अनुमति दें`,
	ht: `Pèmèt kopye`,
	it: `Consenti copia`,
	ja: `コピーを許可する`,
	ko: `복사 허용`,
	mww: `Luam ib daim qauv ntawm daim qauv`,
	ps: `لمېسه پرېښل`,
	pt: `Permitir cópia`,
	'pt-pt': `Permitir cópia`,
	ru: `Разрешить копирование`,
	sm: `Faataga le kopiina`,
	so: `U ogolaada nuqul ka mid ah`,
	th: `อนุญาตการคัดลอก`,
	ur: `کاپی کرنے کی اجازت دیں`,
	vi: `Cho phép sao chép`,
	'zh-hans': `允许复制`,
	'zh-hant': `允許複製`,
	en: `Allow copying`
};

export const loc_allowCopyingDescription: Record<Locale, string> = {
	ar: `السماح للمستخدمين بعمل نسخة من هذا النشاط؟`,
	de: `Benutzern erlauben, eine Kopie dieser Aktivität zu erstellen?`,
	es: `¿Permitir que los usuarios hagan una copia de esta actividad?`,
	fa: `به کاربران اجازه می دهید از این فعالیت کپی کنند؟`,
	fil: `Payagan ang mga gumagamit na gumawa ng kopya ng aktibidad na ito?`,
	fr: `Autoriser les utilisateurs à faire une copie de cette activité ?`,
	hi: `उपयोगकर्ताओं को इस गतिविधि की प्रतिलिपि बनाने की अनुमति दें?`,
	ht: `Kite itilizatè yo fè yon kopi aktivite sa a?`,
	it: `Consentire agli utenti di creare una copia di questa attività?`,
	ja: `ユーザーがこのアクティビティのコピーを作成できるようにしますか?`,
	ko: `사용자가 이 활동의 복사본을 만들 수 있도록 허용하시겠습니까?`,
	mww: `Tso cai rau cov neeg siv kom tau ib daim qauv ntawm txoj kev ua no?`,
	ps: `ایا کاروونکو ته اجازه ورکړئ چې د دې فعالیت کاپی جوړ کړی؟`,
	pt: `Permitir que os usuários façam uma cópia dessa atividade?`,
	'pt-pt': `Permitir que os usuários façam uma cópia dessa atividade?`,
	ru: `Разрешить ли пользователям делать копии этого действия?`,
	sm: `Faataga tagata faaaoga e fai se kopi o lenei gaoioiga?`,
	so: `U oggolow isticmaalayaasha in ay sameeyaan nuqul ka mid ah waxqabadkan?`,
	th: `อนุญาตให้ผู้ใช้ทําสําเนากิจกรรมนี้ได้หรือไม่`,
	ur: `صارفین کو اس سرگرمی کی ایک کاپی بنانے کی اجازت دیں؟`,
	vi: `Cho phép người dùng tạo một bản sao của hoạt động này?`,
	'zh-hans': `是否允许用户复制此活动`,
	'zh-hant': `是否允許使用者複製此活動`,
	en: `Allow users to make a copy of this activity?`
};

export const loc_attachFilesBy: Record<Locale, string> = {
	ar: `قم بإرفاق الملفات عن طريق سحبها وإفلاتها أو تحديدها أو لصقها.`,
	de: `Hängen Sie Dateien an, indem Sie sie per Drag & Drop, Auswählen oder Einfügen verschieben.`,
	es: `Adjunte archivos arrastrándolos y soltándolos, seleccionándolos o pegándolos.`,
	fa: `فایل ها را با کشیدن و رها کردن، انتخاب یا چسباندن آنها پیوست کنید.`,
	fil: `Maglakip ng mga file sa pamamagitan ng pag drag & pagbaba, pagpili, o pag paste ng mga ito.`,
	fr: `Joignez des fichiers par glisser-déposer, sélectionner ou coller.`,
	hi: `फ़ाइलों को खींचकर और छोड़ कर, चयन करके या चिपकाकर अनुलग्न करें.`,
	ht: `Tache dosye pa trennen &ale jete, chwazi, oswa kole yo.`,
	it: `Allega i file trascinandoli, selezionandoli o incollandoli.`,
	ja: `ファイルをドラッグ&ドロップ、選択、または貼り付けて添付します。`,
	ko: `파일을 드래그 앤 드롭하거나, 선택하거나, 붙여넣어 첨부합니다.`,
	mww: `Txuas cov ntaub ntawv los dragging & xa me nyuam rov, xaiv, los yog yav tas los lawv.`,
	ps: `د راکښولو او پریښودو ، غوره کولو ، یا پیسټ کولو له لارې فایلونه ضمیمه کړئ.`,
	pt: `Anexe arquivos arrastando e soltando, selecionando ou colando-os.`,
	'pt-pt': `Anexe arquivos arrastando & soltando, selecionando ou colando-os.`,
	ru: `Прикрепляйте файлы, перетаскивая, выбирая или вставляя их.`,
	sm: `Faapipii faila e ala i le tosoina & faapalasi, filifili, pe faapipii.`,
	so: `Ku lifaaq files by jiidaya & hoos, xulashada, ama ku dhejinta iyaga.`,
	th: `แนบไฟล์โดยการลากและวาง เลือก หรือวาง`,
	ur: `فائلوں کو گھسیٹ کر اور چھوڑ کر ، منتخب کرکے ، یا چسپاں کرکے منسلک کریں۔`,
	vi: `Đính kèm tệp bằng cách kéo và thả, chọn hoặc dán chúng.`,
	'zh-hans': `通过拖放、选择或粘贴来附加文件。`,
	'zh-hant': `通過拖放、選擇或粘貼來附加檔。`,
	en: `Attach files by dragging & dropping, selecting, or pasting them.`
};

export const loc_invalidFilenameFormat: Record<Locale, (filename: unknown) => string> = {
	ar: (filename) => `اسم الملف غير صالح: "${filename}".`,
	de: (filename) => `Ungültiger Dateiname: "${filename}".`,
	es: (filename) => `Nombre de archivo no válido: "${filename}".`,
	fa: (filename) => `نام فایل نامعتبر: "${filename}".`,
	fil: (filename) => `Imbalidong filename: "${filename}".`,
	fr: (filename) => `Nom de fichier non valide : « ${filename} ».`,
	hi: (filename) => `अमान्य फ़ाइल नाम: "${filename}"।`,
	ht: (filename) => `Invalid dosye: "${filename}".`,
	it: (filename) => `Nome file non valido: "${filename}".`,
	ja: (filename) => `無効なファイル名: "${filename}"。`,
	ko: (filename) => `잘못된 파일 이름: "${filename}".`,
	mww: (filename) => `Invalid filename: "${filename}".`,
	ps: (filename) => `"${filename}": ناسم دوتنه نوم`,
	pt: (filename) => `Nome de arquivo inválido: "${filename}".`,
	'pt-pt': (filename) => `Nome do ficheiro inválido: "${filename}".`,
	ru: (filename) => `Недопустимое имя файла: "${filename}".`,
	sm: (filename) => `E le o aoga le igoa o le faila: "${filename}".`,
	so: (filename) => `Magaca faylka aan saxda ahayn: "${filename}".`,
	th: (filename) => `ชื่อไฟล์ไม่ถูกต้อง: "${filename}"`,
	ur: (filename) => `غیر قانونی فائل کا نام: "${filename}".`,
	vi: (filename) => `Tên tệp không hợp lệ: "${filename}".`,
	'zh-hans': (filename) => `文件名无效：“${filename}”。`,
	'zh-hant': (filename) => `檔名無效：「${filename}」。`,
	en: (filename) => `Invalid filename: "${filename}".`
};

export const loc_filenameTooLongFormat: Record<Locale, (filename: unknown, maxLength: unknown) => string> = {
	ar: (filename, maxLength) => `اسم الملف طويل جدا: "${filename}". يجب أن تتكون أسماء الملفات ${maxLength} حرف أو أقل.`,
	de: (filename, maxLength) => `Dateiname zu lang: "${filename}". Dateinamen dürfen ${maxLength} Zeichen lang sein.`,
	es: (filename, maxLength) => `Nombre de archivo demasiado largo: "${filename}". Los nombres de archivo deben tener ${maxLength} caracteres o menos.`,
	fa: (filename, maxLength) => `نام فایل خیلی طولانی: "${filename}". نام فایل ها باید ${maxLength} کاراکتر یا کمتر باشد.`,
	fil: (filename, maxLength) => `Masyadong mahaba ang filename: "${filename}". Ang mga filename ay dapat ${maxLength} character o mas mababa.`,
	fr: (filename, maxLength) => `Nom de fichier trop long : « ${filename} ». Les noms de fichiers doivent comporter ${maxLength} caractères ou moins.`,
	hi: (filename, maxLength) => `फ़ाइल नाम बहुत लंबा: "${filename}"। फ़ाइल नाम ${maxLength} वर्णों या उससे कम वर्णों के होने चाहिए.`,
	ht: (filename, maxLength) => `Filename twò lontan: "${filename}". Filenames dwe ${maxLength} karaktè oswa mwens.`,
	it: (filename, maxLength) => `Nome del file troppo lungo: "${filename}". I nomi dei file devono contenere al massimo ${maxLength} caratteri.`,
	ja: (filename, maxLength) => `ファイル名が長すぎます: "${filename}"。ファイル名は ${maxLength} 文字以下にする必要があります。`,
	ko: (filename, maxLength) => `파일 이름이 너무 깁니다: "${filename}". 파일 이름은 ${maxLength} 자 이하여야 합니다.`,
	mww: (filename, maxLength) => `Filename ntev dhau lawm: "${filename}". Cov ntaub ntawv yuav tsum ${maxLength} cim los yog tsawg dua.`,
	ps: (filename, maxLength) => `.دوتنه نوم ډېر اوږد دی: "${filename}" .دوتنه نومونه باید ${maxLength} توری یا لږ وی`,
	pt: (filename, maxLength) => `Nome do arquivo muito longo: "${filename}". Os nomes dos arquivos devem ter ${maxLength} caracteres ou menos.`,
	'pt-pt': (filename, maxLength) => `Nome do ficheiro demasiado longo: "${filename}". Os nomes dos arquivos devem ter ${maxLength} caracteres ou menos.`,
	ru: (filename, maxLength) => `Слишком длинное имя файла: "${filename}". Имена файлов должны содержать ${maxLength} символов.`,
	sm: (filename, maxLength) => `Ua umi tele le igoa o le faila: "${filename}". O igoa o faila e tatau ona ${maxLength} mataitusi pe itiiti ifo.`,
	so: (filename, maxLength) => `Magaca faylka aad u dheer: "${filename}". 100 talephere waa in ay ahaadaan ${maxLength} xaraf ama ka yar.`,
	th: (filename, maxLength) => `ชื่อไฟล์ยาวเกินไป: "${filename}" ชื่อไฟล์ต้องมีอักขระไม่เกิน ${maxLength} ตัว`,
	ur: (filename, maxLength) => `فائل کا نام بہت لمبا ہے: "${filename}". فائل کے نام ${maxLength} حروف یا اس سے کم ہونا ضروری ہے۔`,
	vi: (filename, maxLength) => `Tên tệp quá dài: "${filename}". Tên tệp phải từ ${maxLength} ký tự trở xuống.`,
	'zh-hans': (filename, maxLength) => `文件名太长：“${filename}”。文件名不得超过 ${maxLength} 个字符。`,
	'zh-hant': (filename, maxLength) => `檔名太長：「${filename}」。。檔名不得超過 ${maxLength} 個字元。`,
	en: (filename, maxLength) => `Filename too long: "${filename}". Filenames must be ${maxLength} characters or less.`
};

export const loc_missingFileExtensionFormat: Record<Locale, (filename: unknown) => string> = {
	ar: (filename) => `اسم الملف غير صالح: "${filename}". يجب أن يتضمن اسم الملف امتدادا.`,
	de: (filename) => `Ungültiger Dateiname: "${filename}". Der Dateiname muss eine Erweiterung enthalten.`,
	es: (filename) => `Nombre de archivo no válido: "${filename}". El nombre del archivo debe incluir una extensión.`,
	fa: (filename) => `نام فایل نامعتبر: "${filename} نام فایل باید شامل یک پسوند باشد.`,
	fil: (filename) => `Imbalidong filename: "${filename}". Ang filename ay dapat magsama ng isang extension.`,
	fr: (filename) => `Nom de fichier non valide : « ${filename} ». Le nom de fichier doit inclure une extension.`,
	hi: (filename) => `अमान्य फ़ाइल नाम: "${filename}"। फ़ाइल नाम में एक्सटेंशन शामिल होना चाहिए.`,
	ht: (filename) => `Invalid dosye: "${filename}". Filenam dwe gen ladan yon ekstansyon.`,
	it: (filename) => `Nome file non valido: "${filename}". Il nome del file deve includere un'estensione.`,
	ja: (filename) => `無効なファイル名: "${filename}"。ファイル名には拡張子を含める必要があります。`,
	ko: (filename) => `잘못된 파일 이름: "${filename}". 파일 이름에는 확장자가 포함되어야 합니다.`,
	mww: (filename) => `Invalid filename: "${filename}". Cov ntaub ntawv yuav tsum muaj ib qho extension.`,
	ps: (filename) => `"${filename}" ناسم دوتنه نوم د دوتنې نوم باید د تمدید شامل وی.`,
	pt: (filename) => `Nome de arquivo inválido: "${filename}". O nome do arquivo deve incluir uma extensão.`,
	'pt-pt': (filename) => `Nome do ficheiro inválido: "${filename}". Nome do arquivo deve incluir uma extensão.`,
	ru: (filename) => `Неверное имя файла: "${filename}". Имя файла должно содержать расширение.`,
	sm: (filename) => `E le o aoga le igoa o le ${filename}". O le igoa o le faila e tatau ona aofia ai se faalautelega.`,
	so: (filename) => `Magaca faylka aan saxneyn: "${filename}". Magaca faylka waa in lagu daraa muddo kordhin.`,
	th: (filename) => `ชื่อไฟล์ไม่ถูกต้อง: "${filename}" ชื่อไฟล์ต้องมีนามสกุล`,
	ur: (filename) => `غیر قانونی فائل کا نام: "${filename}". فائل نام میں ایک توسیع شامل ہونا ضروری ہے۔`,
	vi: (filename) => `Tên tệp không hợp lệ: "${filename}". Tên tệp phải bao gồm phần mở rộng.`,
	'zh-hans': (filename) => `文件名无效：“${filename}”。文件名必须包含扩展名。`,
	'zh-hant': (filename) => `檔名無效：「${filename}」。檔名必須包含擴展名。`,
	en: (filename) => `Invalid filename: "${filename}". Filename must include an extension.`
};

export const loc_invalidFileExtensionFormat: Record<Locale, (extension: unknown, filename: unknown, supportedExtensions: unknown) => string> = {
	ar: (extension, filename, supportedExtensions) => `ملحق غير صالح "${extension}" في "${filename}". الإضافات المدعومة هي ${supportedExtensions}.`,
	de: (extension, filename, supportedExtensions) => `Ungültige Erweiterung "${extension}" in "${filename}". Unterstützte Erweiterungen sind ${supportedExtensions}.`,
	es: (extension, filename, supportedExtensions) => `Extensión "${extension}" no válida en "${filename}". Las extensiones admitidas son ${supportedExtensions}.`,
	fa: (extension, filename, supportedExtensions) => `پسوند نامعتبر "${extension}" در "${filename}". برنامه های افزودنی پشتیبانی شده ${supportedExtensions} هستند.`,
	fil: (extension, filename, supportedExtensions) => `Imbalidong extension "${extension}" sa "${filename}". Ang mga suportado na extension ay ${supportedExtensions}.`,
	fr: (extension, filename, supportedExtensions) => `Extension « ${extension} » incorrecte dans « ${filename} ». Les extensions prises en charge sont ${supportedExtensions}.`,
	hi: (extension, filename, supportedExtensions) => `"${extension}" में अमान्य एक्सटेंशन "${filename}"। समर्थित एक्सटेंशन ${supportedExtensions} हैं।`,
	ht: (extension, filename, supportedExtensions) => `Ekstansyon envalid "${extension}" nan "${filename}". Sipòte ekstansyon yo ${supportedExtensions}.`,
	it: (extension, filename, supportedExtensions) => `Estensione "${extension}" non valida in "${filename}". Le estensioni supportate sono ${supportedExtensions}.`,
	ja: (filename, extension, supportedExtensions) => `"${filename}" の拡張子 "${extension}" が無効です。サポートされている拡張子は${supportedExtensions}です。`,
	ko: (filename, extension, supportedExtensions) => `"${filename}"의 "${extension}" 확장이 잘못되었습니다. 지원되는 확장자는 ${supportedExtensions}입니다.`,
	mww: (extension, filename, supportedExtensions) => `Invalid extension "${extension}" nyob rau hauv "${filename}". Txaus siab extensions yog ${supportedExtensions}.`,
	ps: (extension, filename, supportedExtensions) => `.ناسمه شاتاړی "${extension}" په "${filename}" کې ملاتړ شوی توسیعونه ${supportedExtensions} دی.`,
	pt: (extension, filename, supportedExtensions) => `Extensão inválida "${extension}" em "${filename}". As extensões suportadas são ${supportedExtensions}.`,
	'pt-pt': (extension, filename, supportedExtensions) => `Extensão inválida "${extension}" em "${filename}". As extensões suportadas são ${supportedExtensions}.`,
	ru: (extension, filename, supportedExtensions) => `Недопустимое расширение "${extension}" в "${filename}". Поддерживаемые расширения: ${supportedExtensions}.`,
	sm: (extension, filename, supportedExtensions) => `E le o se faalautelega "${extension}" i le "${filename}". O faalautelega e lagolagoina o ${supportedExtensions}.`,
	so: (extension, filename, supportedExtensions) => `Fidinta aan midka ahayn "${extension}" ee ${filename}". Extensions taageerayaan yihiin ${supportedExtensions}.`,
	th: (extension, filename, supportedExtensions) => `นามสกุล "${extension}" ใน "${filename}" ไม่ถูกต้อง นามสกุลที่รองรับ ได้แก่ ${supportedExtensions}`,
	ur: (extension, filename, supportedExtensions) => `"${extension}" میں غیر قانونی توسیع "${filename}". حمایت یافتہ توسیعیں ${supportedExtensions}۔`,
	vi: (extension, filename, supportedExtensions) => `Tiện ích mở rộng không hợp lệ "${extension}" trong "${filename}". Các tiện ích mở rộng được hỗ trợ là ${supportedExtensions}.`,
	'zh-hans': (extension, filename, supportedExtensions) => `“${extension}”中的扩展名“${filename}”无效。支持的扩展名包括 ${supportedExtensions}。`,
	'zh-hant': (extension, filename, supportedExtensions) => `“${extension}”中的擴展名“${filename}”無效。支持的擴展名包括 ${supportedExtensions}。`,
	en: (extension, filename, supportedExtensions) => `Invalid extension "${extension}" in "${filename}". Supported extensions are ${supportedExtensions}.`
};

export const loc_fileTooLargeFormat: Record<Locale, (filename: unknown, extension: unknown, maxSize: unknown) => string> = {
	ar: (filename, extension, maxSize) => `ملف كبير جدا: "${filename}". الحد الأقصى لحجم الملف لملفات "${extension}" هو ${maxSize}.`,
	de: (filename, extension, maxSize) => `Datei zu groß: "${filename}". Die maximale Dateigröße für "${extension}"-Dateien beträgt ${maxSize}.`,
	es: (filename, extension, maxSize) => `Archivo demasiado grande: "${filename}". El tamaño máximo de archivo para los archivos "${extension}" es de ${maxSize}.`,
	fa: (filename, extension, maxSize) => `فایل خیلی بزرگ: "${filename}". حداکثر اندازه فایل برای فایل های "${extension}" ${maxSize} است.`,
	fil: (filename, extension, maxSize) => `File masyadong malaki: "${filename}". Ang maximum na laki ng file para sa "${extension}" na mga file ay ${maxSize}.`,
	fr: (filename, extension, maxSize) => `Fichier trop volumineux : « ${filename} ». La taille maximale des fichiers « ${extension} » est de ${maxSize}.`,
	hi: (filename, extension, maxSize) => `फ़ाइल बहुत बड़ी है: "${filename}". "${extension}" फ़ाइलों के लिए अधिकतम फ़ाइल आकार ${maxSize} है।`,
	ht: (filename, extension, maxSize) => `Dosye twò gwo: "${filename}". Gwosè a dosye maksimòm pou "${extension}" dosye se ${maxSize}.`,
	it: (filename, extension, maxSize) => `File troppo grande: "${filename}". La dimensione massima del file "${extension}" è ${maxSize}.`,
	ja: (filename, extension, maxSize) => `ファイルが大きすぎます: "${filename}"。「${extension}」ファイルの最大ファイルサイズは ${maxSize}です。`,
	ko: (filename, extension, maxSize) => `파일이 너무 큼: "${filename}". "${extension}" 파일의 최대 파일 크기는 ${maxSize}입니다.`,
	mww: (filename, extension, maxSize) => `Ntaub ntawv loj heev: "${filename}". Qhov ntau npaum li cas rau "${extension}" ntaub ntawv yog ${maxSize}.`,
	ps: (filename, extension, maxSize) => `.دوتنه ډېره ${filename}" د "${extension}" فایلونو لپاره اعظمی فایل اندازه ${maxSize} ده.`,
	pt: (filename, extension, maxSize) => `Arquivo muito grande: "${filename}". O tamanho máximo do arquivo para arquivos "${extension}" é ${maxSize}.`,
	'pt-pt': (filename, extension, maxSize) => `Arquivo muito grande: "${filename}". O tamanho máximo do ficheiro para ficheiros "${extension}" é ${maxSize}.`,
	ru: (filename, extension, maxSize) => `Файл слишком большой: "${filename}". Максимальный размер файла "${extension}" составляет ${maxSize}.`,
	sm: (filename, extension, maxSize) => `Telē tele le faila: "${filename}". O le tele o le faila mo faila "${extension}" e ${maxSize}.`,
	so: (filename, extension, maxSize) => `Faylka aad u weyn: "${filename}". size file ugu badan ee files "${extension}" waa ${maxSize}.`,
	th: (filename, extension, maxSize) => `ไฟล์ใหญ่เกินไป: "${filename}" ขนาดไฟล์สูงสุดสําหรับไฟล์ "${extension}" คือ ${maxSize}`,
	ur: (filename, extension, maxSize) => `فائل بہت بڑی ہے: "${filename}". ".xml" فائلوں کے لئے زیادہ ${extension} زیادہ فائل سائز ${maxSize} ہے.`,
	vi: (filename, extension, maxSize) => `Tệp quá lớn: "${filename}". Kích thước tệp tối đa cho tệp "${extension}" là ${maxSize}.`,
	'zh-hans': (filename, extension, maxSize) => `文件太大：“${filename}”。“${extension}”文件的最大文件大小为 ${maxSize}。`,
	'zh-hant': (filename, extension, maxSize) => `檔太大：「${filename}」。。“${extension}”檔的最大檔大小為 ${maxSize}。`,
	en: (filename, extension, maxSize) => `File too large: "${filename}". The maximum file size for "${extension}" files is ${maxSize}.`
};

export const loc_fileIsEmptyFormat: Record<Locale, (filename: unknown) => string> = {
	ar: (filename) => `الملف فارغ: "${filename}".`,
	de: (filename) => `Datei ist leer: "${filename}".`,
	es: (filename) => `El archivo está vacío: "${filename}".`,
	fa: (filename) => `فایل خالی است: "${filename}".`,
	fil: (filename) => `File ay walang laman: "${filename}".`,
	fr: (filename) => `Le fichier est vide : « ${filename} ».`,
	hi: (filename) => `फ़ाइल खाली है: "${filename}"।`,
	ht: (filename) => `Dosye se annmp: "${filename}".`,
	it: (filename) => `Il file è vuoto: "${filename}".`,
	ja: (filename) => `ファイルが空です: "${filename}"。`,
	ko: (filename) => `파일이 비어 있습니다 : "${filename}".`,
	mww: (filename) => `Ntaub ntawv yog tas: "${filename}".`,
	ps: (filename) => `.دوتنه خالی ده: "${filename}".`,
	pt: (filename) => `O arquivo está vazio: "${filename}".`,
	'pt-pt': (filename) => `O ficheiro está vazio: "${filename}".`,
	ru: (filename) => `Файл пустой: "${filename}".`,
	sm: (filename) => `Ua avanoa le faila: "${filename}".`,
	so: (filename) => `Faylka wuu madhan yahay: "${filename}".`,
	th: (filename) => `ไฟล์ว่างเปล่า: "${filename}"`,
	ur: (filename) => `فائل خالی ہے: "${filename}".`,
	vi: (filename) => `Tệp trống: "${filename}".`,
	'zh-hans': (filename) => `文件为空：“${filename}”。`,
	'zh-hant': (filename) => `檔為空：「${filename}」。。`,
	en: (filename) => `File is empty: "${filename}".`
};

export const loc_uploadFilesProgressFormat: Record<Locale, (index: unknown, length: unknown) => string> = {
	ar: (index, length) => `جاري تحميل ملفاتك... (${index}/${length})`,
	de: (index, length) => `Hochladen Ihrer Dateien... (${index}/${length})`,
	es: (index, length) => `Subiendo tus archivos... (${index}/${length})`,
	fa: (index, length) => `آپلود فایل های شما... (${index}/${length})`,
	fil: (index, length) => `Nag-upload ng iyong mga file... (${index}/${length})`,
	fr: (index, length) => `Téléchargement de vos fichiers... (${index}/${length})`,
	hi: (index, length) => `अपनी फ़ाइलें अपलोड कर रहा है... (${index}/${length})`,
	ht: (index, length) => `Telechaje dosye ou ... (${index}/${length})`,
	it: (index, length) => `Caricamento dei file... (${index}/${length})`,
	ja: (index, length) => `ファイルをアップロードしています...(${index}月${length}日)`,
	ko: (index, length) => `파일을 업로드하는 중... (${index}/${length})`,
	mww: (index, length) => `Uploading koj cov ntaub ntawv... (${index}/${length})`,
	ps: (index, length) => `خپل فایلونه پورته کړئ ... (${index}/${length})`,
	pt: (index, length) => `Carregando seus arquivos... (${index}/${length})`,
	'pt-pt': (index, length) => `A carregar os seus ficheiros... (${index}/${length})`,
	ru: (index, length) => `Загрузка файлов... (${index}/${length})`,
	sm: (index, length) => `Siiina mai o au faila... (${index}/${length})`,
	so: (index, length) => `Galinta files aad... (${index}/${length})`,
	th: (index, length) => `กําลังอัปโหลดไฟล์ของคุณ... (${index}/${length})`,
	ur: (index, length) => `اپنی فائلیں اپ لوڈ کریں... (${index}/${length})`,
	vi: (index, length) => `Tải lên tệp của bạn... (${index}/${length})`,
	'zh-hans': (index, length) => `正在上传您的文件...（${index}/${length}）`,
	'zh-hant': (index, length) => `正在上傳您的檔案...（${index}/${length}）`,
	en: (index, length) => `Uploading your files... (${index}/${length})`
};

export const loc_uploadFileErrorFormat: Record<Locale, (filename: unknown) => string> = {
	ar: (filename) => `خطأ في تحميل "${filename}".`,
	de: (filename) => `Fehler beim Hochladen von "${filename}".`,
	es: (filename) => `Error al cargar "${filename}".`,
	fa: (filename) => `خطا در آپلود "${filename}".`,
	fil: (filename) => `May error sa pag upload ng "${filename}".`,
	fr: (filename) => `Erreur lors du téléchargement de « ${filename} ».`,
	hi: (filename) => `"${filename}" अपलोड करने में त्रुटि।`,
	ht: (filename) => `Erè uploading "${filename}".`,
	it: (filename) => `Errore durante il caricamento di "${filename}".`,
	ja: (filename) => `「${filename}」のアップロード中にエラーが発生しました。`,
	ko: (filename) => `"${filename}"을 업로드하는 중 오류가 발생했습니다.`,
	mww: (filename) => `Yuam kev uploading "${filename}".`,
	ps: (filename) => `په پورته کولو کې تېروتنه "${filename}"`,
	pt: (filename) => `Erro ao carregar "${filename}".`,
	'pt-pt': (filename) => `Erro ao carregar "${filename}".`,
	ru: (filename) => `Ошибка при загрузке "${filename}".`,
	sm: (filename) => `Mea sese i le siiina mai o le "${filename}".`,
	so: (filename) => `Soosaarista qalad "${filename}".`,
	th: (filename) => `เกิดข้อผิดพลาดในการอัปโหลด "${filename}"`,
	ur: (filename) => `"records.csv" اپ لوڈ کرنے ${filename} غلطی۔`,
	vi: (filename) => `Lỗi khi tải lên "${filename}".`,
	'zh-hans': (filename) => `上传 “${filename}” 时出错。`,
	'zh-hant': (filename) => `上傳 「${filename}」 時出錯。`,
	en: (filename) => `Error uploading "${filename}".`
};

export const loc_image: Record<Locale, string> = {
	ar: `صورة`,
	de: `Bild`,
	es: `Imagen`,
	fa: `تصویر`,
	fil: `Imahe`,
	fr: `Image`,
	hi: `प्रतिबिंब`,
	ht: `Imaj`,
	it: `Immagine`,
	ja: `画像`,
	ko: `이미지`,
	mww: `duab`,
	ps: `انځور`,
	pt: `Imagem`,
	'pt-pt': `Imagem`,
	ru: `Образ`,
	sm: `Ata`,
	so: `Sawir`,
	th: `ภาพ`,
	ur: `روپ`,
	vi: `Ảnh`,
	'zh-hans': `图像`,
	'zh-hant': `圖像`,
	en: `Image`
};

export const loc_video: Record<Locale, string> = {
	ar: `فيديو`,
	de: `Video`,
	es: `Vídeo`,
	fa: `ویدئو`,
	fil: `Video`,
	fr: `Vidéo`,
	hi: `वीडियो`,
	ht: `Videyo`,
	it: `Video`,
	ja: `ビデオ`,
	ko: `비디오`,
	mww: `Yees duab`,
	ps: `وېډیو`,
	pt: `Vídeo`,
	'pt-pt': `Vídeo`,
	ru: `Видео`,
	sm: `Vitio`,
	so: `Muuqaalo`,
	th: `วีดิทัศน์`,
	ur: `ویڈیو`,
	vi: `Video`,
	'zh-hans': `视频`,
	'zh-hant': `視頻`,
	en: `Video`
};

export const loc_text: Record<Locale, string> = {
	ar: `نص`,
	de: `Text`,
	es: `Mensaje de texto`,
	fa: `پیامک`,
	fil: `Teksto`,
	fr: `SMS`,
	hi: `टेक्स्ट`,
	ht: `Mesaj tèks`,
	it: `Testo`,
	ja: `テキスト`,
	ko: `문자 메시지`,
	mww: `Sau ntawv saum xov tooj`,
	ps: `لیکنه`,
	pt: `Texto`,
	'pt-pt': `Texto`,
	ru: `СМС`,
	sm: `Anotusi`,
	so: `Qoraalka`,
	th: `ข้อความ`,
	ur: `متن`,
	vi: `Nhắn tin`,
	'zh-hans': `发短信`,
	'zh-hant': `發簡訊`,
	en: `Text`
};

export const loc_any: Record<Locale, string> = {
	ar: `أي`,
	de: `Jegliche`,
	es: `Cualquier`,
	fa: `هر`,
	fil: `Anumang`,
	fr: `Quelconque`,
	hi: `कोई`,
	ht: `Okenn`,
	it: `Qualunque`,
	ja: `任意`,
	ko: `어떤`,
	mww: `Txhua yam`,
	ps: `هر`,
	pt: `Qualquer`,
	'pt-pt': `Qualquer`,
	ru: `Любой`,
	sm: `Soo se`,
	so: `Qof kasta`,
	th: `ใด`,
	ur: `کوئی`,
	vi: `Bất kì`,
	'zh-hans': `任何`,
	'zh-hant': `任何`,
	en: `Any`
};

export const loc_currentAndUpcomingFormat: Record<Locale, (activityTitle: unknown, org: unknown) => string> = {
	ar: (activityTitle, org) => `أنشطة ${activityTitle} الحالية والقادمة حسب ${org} ، مرتبة حسب المسافة من موقعك.`,
	de: (activityTitle, org) => `Aktuelle und kommende ${activityTitle} von ${org}, sortiert nach Entfernung von Ihrem Standort.`,
	es: (activityTitle, org) => `Actividades actuales y futuras de ${activityTitle} de ${org}, ordenadas por distancia desde su ubicación.`,
	fa: (activityTitle, org) => `فعالیت های فعلی و ${activityTitle} توسط ${org}، بر اساس فاصله از مکان شما مرتب شده است.`,
	fil: (activityTitle, org) => `Ang kasalukuyan at paparating na mga aktibidad sa ${activityTitle} sa pamamagitan ng ${org}, na inayos ayon sa distansya mula sa iyong lokasyon.`,
	fr: (activityTitle, org) => `Activités actuelles et à venir ${activityTitle} de ${org}, classées par distance de votre emplacement.`,
	hi: (org, activityTitle) => `${org} द्वारा वर्तमान और आगामी ${activityTitle} गतिविधियां, आपके स्थान से दूरी के अनुसार क्रमबद्ध।`,
	ht: (activityTitle, org) => `Aktivite ${activityTitle} pa ${org}, klase pa distans soti nan kote ou ye a.`,
	it: (activityTitle, org) => `Attività attuali e future di ${activityTitle} di ${org}, ordinate in base alla distanza dalla tua posizione.`,
	ja: (org, activityTitle) => `${org}による現在および今後の${activityTitle}の活動を、現在地からの距離で並べ替えて掲載しています。`,
	ko: (org, activityTitle) => `${org}의 현재 및 향후 ${activityTitle} 활동, 귀하의 위치로부터의 거리순으로 정렬됩니다.`,
	mww: (activityTitle, org) => `Tam sim no thiab yavtom ntej ${activityTitle} los ntawm ${org}, sorted nyob deb ntawm koj qhov chaw nyob.`,
	ps: (org, activityTitle) => `د ${org} لخوا د ${activityTitle} اوسنی او راتلونکی فعالیتونه ، ستاسو له موقعیت څخه د واټن په واسطه ترتیب شوی.`,
	pt: (activityTitle, org) => `Atividades clínicas de ${activityTitle} atuais e futuras da ${org}, classificadas por distância de sua localização.`,
	'pt-pt': (activityTitle, org) => `Atividades atuais e futuras da ${activityTitle} da ${org}, ordenadas por distância da sua localização.`,
	ru: (activityTitle, org) => `Текущие и предстоящие мероприятия ${activityTitle} от ${org}, отсортированные по удаленности от вашего местоположения.`,
	sm: (activityTitle, org) => `O gaoioiga o loo i ai nei ma le ${activityTitle} o tui o loo loma na saunia e ${org}, e faavasegaina i le mamao mai lou nofoaga.`,
	so: (activityTitle, org) => `Hawlaha ${activityTitle} ee hadda la socda ee ay qabto ${org}, oo laga soocay meel ka fog meesha aad joogto.`,
	th: (activityTitle, org) => `กิจกรรมคลินิก ${activityTitle} ในปัจจุบันและที่กําลังจะมาถึงโดย ${org} โดยเรียงตามระยะทางจากตําแหน่งของคุณ`,
	ur: (org, activityTitle) => `${org} کی طرف سے موجودہ اور آنے والی ${activityTitle} کی سرگرمیاں ، آپ کے مقام سے فاصلے کے لحاظ سے ترتیب دی جاتی ہیں۔`,
	vi: (activityTitle, org) => `Các hoạt động phòng ${activityTitle} hiện tại và sắp tới của ${org}, được sắp xếp theo khoảng cách từ vị trí của bạn.`,
	'zh-hans': (org, activityTitle) => `${org} 当前和即将进行的${activityTitle}活动，按与您所在位置的距离排序。`,
	'zh-hant': (org, activityTitle) => `${org} 當前和即將進行的${activityTitle}活動，按與您所在位置的距離排序。`,
	en: (activityTitle, org) => `Current and upcoming ${activityTitle} activities by ${org}, sorted by distance from your location.`
};

export const loc_noActiveOrUpcomingEvents: Record<Locale, string> = {
	ar: `لا توجد أحداث نشطة أو قادمة`,
	de: `Keine aktiven oder bevorstehenden Veranstaltungen`,
	es: `No hay eventos activos o próximos`,
	fa: `بدون رویداد فعال یا آینده`,
	fil: `Walang aktibo o paparating na mga kaganapan`,
	fr: `Aucun événement actif ou à venir`,
	hi: `कोई सक्रिय या आगामी ईवेंट नहीं`,
	ht: `Pa gen aktif oswa evènman kap vini yo`,
	it: `Nessun evento attivo o imminente`,
	ja: `アクティブなイベントや今後のイベントはありません`,
	ko: `진행 중이거나 예정된 이벤트가 없습니다.`,
	mww: `Tsis muaj yam los yog yavtom ntej`,
	ps: `هیڅ فعال یا راتلونکی پیښې شتون نلری`,
	pt: `Nenhum evento ativo ou futuro`,
	'pt-pt': `Sem eventos ativos ou futuros`,
	ru: `Нет активных или предстоящих событий`,
	sm: `Leai ni mea o loo tutupu pe o loo loma`,
	so: `Majiraan dhacdooyin firfircoon ama kuwo soo socda`,
	th: `ไม่มีกิจกรรมที่ใช้งานอยู่หรือกิจกรรมที่กําลังจะเกิดขึ้น`,
	ur: `کوئی فعال یا آنے والے واقعات نہیں`,
	vi: `Không có sự kiện đang hoạt động hoặc sắp tới`,
	'zh-hans': `没有活动或即将发生的事件`,
	'zh-hant': `沒有活動或即將發生的事件`,
	en: `No active or upcoming events`
};

export const loc_myLocation: Record<Locale, string> = {
	ar: `موقعي`,
	de: `Mein Standort`,
	es: `Mi ubicación`,
	fa: `موقعیت مکانی من`,
	fil: `Ang aking lokasyon`,
	fr: `Ma localisation`,
	hi: `मेरा स्थान`,
	ht: `Kote mwen ye`,
	it: `La mia posizione`,
	ja: `私の位置情報`,
	ko: `내 위치`,
	mww: `Kuv qhov chaw nyob`,
	ps: `زما ځای`,
	pt: `Minha localização`,
	'pt-pt': `A minha localização`,
	ru: `Мое местоположение`,
	sm: `O lo'u nofoaga`,
	so: `My goobta`,
	th: `ตําแหน่งของฉัน`,
	ur: `میرا محل وقوع`,
	vi: `Vị trí của tôi`,
	'zh-hans': `我的位置`,
	'zh-hant': `我的位置`,
	en: `My location`
};

export const loc_eventSchedule: Record<Locale, string> = {
	ar: `جدول الفعاليات`,
	de: `Ablauf der Veranstaltung`,
	es: `Calendario del evento`,
	fa: `برنامه رویداد`,
	fil: `Iskedyul ng kaganapan`,
	fr: `Calendrier des événements`,
	hi: `घटना अनुसूची`,
	ht: `Orè evènman`,
	it: `Programma dell'evento`,
	ja: `イベントスケジュール`,
	ko: `이벤트 일정`,
	mww: `Cov Sij Hawm Hawm Tuaj Rau Kev Tshwm`,
	ps: `د پیښې مهال ویش`,
	pt: `Programação do evento`,
	'pt-pt': `Programação do evento`,
	ru: `Расписание мероприятий`,
	sm: `Faatulagaga o mea e tutupu`,
	so: `Jadwalka event`,
	th: `ตารางกิจกรรม`,
	ur: `ایونٹ کا شیڈول`,
	vi: `Lịch trình sự kiện`,
	'zh-hans': `活动时间表`,
	'zh-hant': `啟用時程表`,
	en: `Event schedule`
};

export const loc_noCurrentAndUpcomingFormat: Record<Locale, (org: unknown, activityTitle: unknown) => string> = {
	ar: (org, activityTitle) => `لا يوجد لدى ${org} أي أنشطة ${activityTitle} لقاح مجدولة.`,
	de: (org, activityTitle) => `${org} hat keine Aktivitäten in ${activityTitle} geplant.`,
	es: (org, activityTitle) => `${org} no tiene programadas actividades en la ${activityTitle} .`,
	fa: (org, activityTitle) => `${org} هیچ فعالیت ${activityTitle} برنامه ریزی نشده است.`,
	fil: (org, activityTitle) => `Ang ${org} ay walang mga aktibidad sa ${activityTitle} na naka iskedyul.`,
	fr: (org, activityTitle) => `${org} n’a pas d’activités prévues ${activityTitle} .`,
	hi: (org, activityTitle) => `${org} में कोई ${activityTitle} गतिविधि निर्धारित नहीं है।`,
	ht: (org, activityTitle) => `${org} pa gen okenn aktivite ${activityTitle} .`,
	it: (org, activityTitle) => `${org} non ha in programma attività ${activityTitle} .`,
	ja: (org, activityTitle) => `${org} では、 ${activityTitle} の活動は予定されていません。`,
	ko: (org, activityTitle) => `${org} 에는 ${activityTitle} 활동이 예정되어 있지 않습니다.`,
	mww: (org, activityTitle) => `${org} twb tsis muaj ${activityTitle} teem tseg.`,
	ps: (org, activityTitle) => `${org} د ${activityTitle} فعالیتونه مهالویش نلری.`,
	pt: (org, activityTitle) => `${org} não tem atividades ${activityTitle} agendadas.`,
	'pt-pt': (org, activityTitle) => `${org} não tem atividades ${activityTitle} .`,
	ru: (org, activityTitle) => `${org} не запланированы мероприятия ${activityTitle} .`,
	sm: (activityTitle, org) => `E leai ni gaoioiga a le ${activityTitle} o ${org} e faatulagaina.`,
	so: (org, activityTitle) => `${org} ma laha wax ${activityTitle} ah oo loo qoondeeyay.`,
	th: (org, activityTitle) => `${org} ไม่มีกําหนดการกิจกรรม${activityTitle}`,
	ur: (org, activityTitle) => `${org} میں ${activityTitle} کی کوئی سرگرمیاں شیڈول نہیں ہیں۔`,
	vi: (org, activityTitle) => `${org} không có lịch trình ${activityTitle} .`,
	'zh-hans': (org, activityTitle) => `${org} 没有安排 ${activityTitle} 活动。`,
	'zh-hant': (org, activityTitle) => `${org} 沒有安排 ${activityTitle} 活動。`,
	en: (org, activityTitle) => `${org} has no ${activityTitle} activities scheduled.`
};

export const loc_distanceFromYourLocationFormat: Record<Locale, (distance: unknown) => string> = {
	ar: (distance) => `${distance} كلم من موقعك`,
	de: (distance) => `${distance} Meilen von Ihrem Standort entfernt`,
	es: (distance) => `${distance} millas de su ubicación`,
	fa: (distance) => `${distance} مایل از موقعیت مکانی شما`,
	fil: (distance) => `${distance} km mula sa iyong lokasyon`,
	fr: (distance) => `${distance} miles de votre emplacement`,
	hi: (distance) => `अपने स्थान से ${distance} मील दूर`,
	ht: (distance) => `${distance} kilomèt nan kote ou ye a`,
	it: (distance) => `${distance} miglia dalla tua posizione`,
	ja: (distance) => `現在地から${distance}マイル`,
	ko: (distance) => `현재 위치에서 ${distance} km`,
	mww: (distance) => `${distance} mais ntawm koj qhov chaw nyob`,
	ps: (distance) => `ستاسو د ${distance} مایل`,
	pt: (distance) => `${distance} milhas da sua localização`,
	'pt-pt': (distance) => `${distance} milhas da sua localização`,
	ru: (distance) => `${distance} миль от вашего местоположения`,
	sm: (distance) => `${distance} maila mai lou nofoaga`,
	so: (distance) => `${distance} miles u jirta meesha aad degan tahay`,
	th: (distance) => `${distance} กม. จากตําแหน่งของคุณ`,
	ur: (distance) => `آپ کے مقام سے ${distance} میل دور`,
	vi: (distance) => `${distance} dặm từ vị trí của bạn`,
	'zh-hans': (distance) => `距离您的位置 ${distance} 英里`,
	'zh-hant': (distance) => `距離您的位置 ${distance} 英里`,
	en: (distance) => `${distance} miles from your location`
};

export const loc_yourLocation: Record<Locale, string> = {
	ar: `موقعك`,
	de: `Ihr Standort`,
	es: `Su ubicación`,
	fa: `مکان شما`,
	fil: `Ang iyong lokasyon`,
	fr: `Votre emplacement`,
	hi: `आपका स्थान`,
	ht: `Kote ou ye a`,
	it: `La tua posizione`,
	ja: `あなたの場所`,
	ko: `현재 위치`,
	mww: `Koj qhov chaw nyob`,
	ps: `ستاسو ځای`,
	pt: `Sua localização`,
	'pt-pt': `A sua localização`,
	ru: `Ваше местоположение`,
	sm: `O lou nofoaga`,
	so: `Goobtaada`,
	th: `ตําแหน่งของคุณ`,
	ur: `آپ کا محل وقوع`,
	vi: `Vị trí của bạn`,
	'zh-hans': `您的位置`,
	'zh-hant': `您的位置`,
	en: `Your location`
};

export const loc_upcoming: Record<Locale, string> = {
	ar: `القادمه`,
	de: `Kommend`,
	es: `Próximo`,
	fa: `آینده`,
	fil: `Mga paparating na`,
	fr: `Prochain`,
	hi: `घटित`,
	ht: `vini`,
	it: `Prossimo`,
	ja: `今後`,
	ko: `다가오는`,
	mww: `yavtom ntej`,
	ps: `راتلونکی`,
	pt: `Próximo`,
	'pt-pt': `Próximos passos`,
	ru: `Развивающийся`,
	sm: `O Loo Loma`,
	so: `Soo socda`,
	th: `เกิด ขึ้น`,
	ur: `آئندہ`,
	vi: `Sắp tới`,
	'zh-hans': `即将 到来`,
	'zh-hant': `即將到來`,
	en: `Upcoming`
};

export const loc_active: Record<Locale, string> = {
	ar: `نشط`,
	de: `Aktiv`,
	es: `Activo`,
	fa: `فعال`,
	fil: `Aktibo`,
	fr: `Actif`,
	hi: `सक्रिय`,
	ht: `Aktif`,
	it: `Attivo`,
	ja: `能動`,
	ko: `활동적인`,
	mww: `Active`,
	ps: `چارند`,
	pt: `Ativo`,
	'pt-pt': `Ativo`,
	ru: `Активный`,
	sm: `Toaga`,
	so: `Firfircoon`,
	th: `กระปรี้กระเปร่า`,
	ur: `سرگرم`,
	vi: `Hoạt động`,
	'zh-hans': `积极`,
	'zh-hant': `積極`,
	en: `Active`
};

export const loc_searchByTitle: Record<Locale, string> = {
	ar: `البحث حسب العنوان`,
	de: `Suche nach Titel`,
	es: `Búsqueda por título`,
	fa: `جستجو بر اساس عنوان`,
	fil: `Maghanap ayon sa pamagat`,
	fr: `Recherche par titre`,
	hi: `शीर्षक द्वारा खोजें`,
	ht: `Rechèch pa`,
	it: `Ricerca per titolo`,
	ja: `タイトルから探す`,
	ko: `제목으로 찾기`,
	mww: `Nrhiav lub npe`,
	ps: `د سرلیک له مخې لټون`,
	pt: `Pesquisar por título`,
	'pt-pt': `Pesquisa por título`,
	ru: `Поиск по названию`,
	sm: `Saili i le ulutala`,
	so: `Raadi adigoo title ah`,
	th: `ค้นหาตามชื่อเรื่อง`,
	ur: `عنوان کے لحاظ سے تلاش کریں`,
	vi: `Tìm kiếm theo tiêu đề`,
	'zh-hans': `按标题搜索`,
	'zh-hant': `按標題搜索`,
	en: `Search by title`
};

export const loc_upcomingVaccineClinics: Record<Locale, string> = {
	ar: `عيادات اللقاح القادمة`,
	de: `Kommende Impfkliniken`,
	es: `Próximas clínicas de vacunación`,
	fa: `کلینیک های واکسن آینده`,
	fil: `Mga paparating na klinika ng bakuna`,
	fr: `Prochaines cliniques de vaccination`,
	hi: `आगामी वैक्सीन क्लीनिक`,
	ht: `Klinik vaksen k ap vini yo`,
	it: `Prossime cliniche per i vaccini`,
	ja: `今後のワクチンクリニック`,
	ko: `다가오는 백신 클리닉`,
	mww: `Yavtom ntej cov chaw txhaj tshuaj yavtom`,
	ps: `د واکسین راتلونکی کلینیکونه`,
	pt: `Próximas clínicas de vacinas`,
	'pt-pt': `Próximas clínicas de vacinação`,
	ru: `Предстоящие вакцинационные клиники`,
	sm: `Falemai o tui o loo loma`,
	so: `Xarumo caafimaad oo talaal oo soo socda`,
	th: `คลินิกวัคซีนที่กําลังจะมาถึง`,
	ur: `آنے والے ویکسین کلینک`,
	vi: `Các phòng khám vắc xin sắp tới`,
	'zh-hans': `即将成立的疫苗诊所`,
	'zh-hant': `即將成立的疫苗診所`,
	en: `Upcoming vaccine clinics`
};

export const loc_location: Record<Locale, string> = {
	ar: `مكان`,
	de: `Ort`,
	es: `Ubicación`,
	fa: `محل`,
	fil: `Lokasyon`,
	fr: `Emplacement`,
	hi: `स्थान`,
	ht: `Kote`,
	it: `Ubicazione`,
	ja: `場所`,
	ko: `위치`,
	mww: `qhov chaw`,
	ps: `ځای`,
	pt: `Localização`,
	'pt-pt': `Localização`,
	ru: `Местоположение`,
	sm: `Nofoaga`,
	so: `Goobta`,
	th: `สถานที่`,
	ur: `جگہ`,
	vi: `Vị trí`,
	'zh-hans': `位置`,
	'zh-hant': `位置`,
	en: `Location`
};

export const loc_locationSearchDescription: Record<Locale, string> = {
	ar: `ابحث عن عنوان أو مدينة أو ولاية أو رمز بريدي أو استخدم موقعك الحالي.`,
	de: `Suchen Sie nach einer Adresse, einer Stadt, einem Bundesland, einer Postleitzahl oder verwenden Sie Ihren aktuellen Standort.`,
	es: `Busque una dirección, ciudad, estado, código postal o use su ubicación actual.`,
	fa: `آدرس، شهر، ایالت، فایل فشرده را جستجو کنید یا از مکان فعلی خود استفاده کنید.`,
	fil: `Maghanap para sa isang address, lungsod, estado, zip o gamitin ang iyong kasalukuyang lokasyon.`,
	fr: `Recherchez une adresse, une ville, un état, un code postal ou utilisez votre emplacement actuel.`,
	hi: `एक पता, शहर, राज्य, ज़िप खोजें या अपने वर्तमान स्थान का उपयोग करें।`,
	ht: `Chèche yon adrès, vil, eta, zip oswa itilize kote ou ye kounye a.`,
	it: `Cerca un indirizzo, una città, uno stato, un codice postale o usa la tua posizione attuale.`,
	ja: `住所、都市、州、郵便番号を検索するか、現在地を使用します。`,
	ko: `주소, 도시, 주, 우편 번호를 검색하거나 현재 위치를 사용하십시오.`,
	mww: `Nrhiav ib qhov chaw nyob, nroog, xeev, zip los yog siv koj qhov chaw nyob tam sim no.`,
	ps: `د پته ، ښار ، ایالت ، زپ لټون وکړئ یا خپل اوسنی موقعیت وکاروئ.`,
	pt: `Pesquise um endereço, cidade, estado, CEP ou use sua localização atual.`,
	'pt-pt': `Procure um endereço, cidade, estado, zip ou use sua localização atual.`,
	ru: `Выполните поиск по адресу, городу, штату, почтовому индексу или используйте свое текущее местоположение.`,
	sm: `Saili mo se tuatusi, aai, setete, zip pe faaaoga lou nofoaga o i ai nei.`,
	so: `Raadi cinwaan, magaalo, gobol, zip ama isticmaal meesha aad hadda joogto.`,
	th: `ค้นหาที่อยู่ เมือง รัฐ zip หรือใช้ตําแหน่งปัจจุบันของคุณ`,
	ur: `پتہ ، شہر ، ریاست ، زپ تلاش کریں یا اپنے موجودہ مقام کا استعمال کریں۔`,
	vi: `Tìm kiếm địa chỉ, thành phố, tiểu bang, zip hoặc sử dụng vị trí hiện tại của bạn.`,
	'zh-hans': `搜索地址、城市、州、邮政编码或使用您当前的位置。`,
	'zh-hant': `搜索位址、城市、州、郵遞區號或使用您當前的位置。`,
	en: `Search for an address, city, state, zip or use your current location.`
};

export const loc_range: Record<Locale, string> = {
	ar: `نطاق`,
	de: `Bereich`,
	es: `Gama`,
	fa: `محدوده`,
	fil: `Saklaw`,
	fr: `Gamme`,
	hi: `श्रेणी`,
	ht: `Pòte`,
	it: `Gamma`,
	ja: `範囲`,
	ko: `레인지`,
	mww: `Ntau yam`,
	ps: `سیمه`,
	pt: `Gama`,
	'pt-pt': `Gama`,
	ru: `Диапазон`,
	sm: `Vaega`,
	so: `Range`,
	th: `เทือก`,
	ur: `رینج`,
	vi: `Phạm vi`,
	'zh-hans': `范围`,
	'zh-hant': `範圍`,
	en: `Range`
};

export const loc_fiftyMiles: Record<Locale, string> = {
	ar: `50 أميال`,
	de: `50 Meilen`,
	es: `50 millas`,
	fa: `50 مایل`,
	fil: `50 milya`,
	fr: `50 milles`,
	hi: `50 मील की दूरी`,
	ht: `50 kilomèt`,
	it: `50 miglia`,
	ja: `50マイル`,
	ko: `50마일`,
	mww: `50 mais`,
	ps: `50 مایل`,
	pt: `50 milhas`,
	'pt-pt': `50 milhas`,
	ru: `50 миль`,
	sm: `50 maila`,
	so: `50 miles`,
	th: `80.1 กม.`,
	ur: `50 میل`,
	vi: `50 dặm`,
	'zh-hans': `50 英里`,
	'zh-hant': `50 英里`,
	en: `50 miles`
};

export const loc_oneHundredMiles: Record<Locale, string> = {
	ar: `100 ميل`,
	de: `100 Meilen`,
	es: `100 millas`,
	fa: `100 مایل`,
	fil: `100 milya`,
	fr: `100 milles`,
	hi: `100 मील`,
	ht: `100 kilomèt`,
	it: `100 miglia`,
	ja: `100マイル`,
	ko: `100마일`,
	mww: `100 mais`,
	ps: `100 مایل`,
	pt: `100 milhas`,
	'pt-pt': `100 milhas`,
	ru: `100 миль`,
	sm: `100 maila`,
	so: `100 miles`,
	th: `160 กม.`,
	ur: `100 میل`,
	vi: `100 dặm`,
	'zh-hans': `100 英里`,
	'zh-hant': `100 英里`,
	en: `100 miles`
};

export const loc_unspecifiedLocation: Record<Locale, string> = {
	ar: `موقع غير محدد`,
	de: `Nicht näher bezeichneter Ort`,
	es: `Ubicación no especificada`,
	fa: `مکان نامشخص`,
	fil: `Hindi tinukoy na lokasyon`,
	fr: `Emplacement non spécifié`,
	hi: `अनिर्दिष्ट स्थान`,
	ht: `Kote ki pa espesifye`,
	it: `Località non specificata`,
	ja: `場所不明`,
	ko: `지정되지 않은 위치`,
	mww: `Unspecified qhov chaw`,
	ps: `ناپېژندل شوی ځای`,
	pt: `Localização não especificada`,
	'pt-pt': `Localização não especificada`,
	ru: `Местоположение не указано`,
	sm: `Nofoaga e le'i faailoaina`,
	so: `Goob aan la cayimin`,
	th: `ตําแหน่งที่ไม่ระบุ`,
	ur: `غیر متعین مقام`,
	vi: `Vị trí không xác định`,
	'zh-hans': `未指定位置`,
	'zh-hant': `未指定位置`,
	en: `Unspecified location`
};

export const loc_milesAwayFormat: Record<Locale, (distance: unknown) => string> = {
	ar: (distance) => `على بعد ${distance} كلم`,
	de: (distance) => `${distance} Meilen entfernt`,
	es: (distance) => `${distance} millas de distancia`,
	fa: (distance) => `${distance} مایل دورتر`,
	fil: (distance) => `${distance} km ang layo`,
	fr: (distance) => `${distance} milles`,
	hi: (distance) => `${distance} मील दूर`,
	ht: (distance) => `${distance} kilomèt lwen`,
	it: (distance) => `${distance} miglia di distanza`,
	ja: (distance) => `${distance} マイル離れて`,
	ko: (distance) => `${distance} 마일 거리`,
	mww: (distance) => `${distance} mais tam sim ntawd`,
	ps: (distance) => `${distance} مایل لرې`,
	pt: (distance) => `${distance} milhas de distância`,
	'pt-pt': (distance) => `${distance} milhas de distância`,
	ru: (distance) => `${distance} км`,
	sm: (distance) => `${distance} maila le mamao`,
	so: (distance) => `${distance} miles aways`,
	th: (distance) => `ห่างออกไป ${distance} กม.`,
	ur: (distance) => `${distance} میل دور`,
	vi: (distance) => `${distance} dặm`,
	'zh-hans': (distance) => `${distance} 英里`,
	'zh-hant': (distance) => `${distance} 英里`,
	en: (distance) => `${distance} miles away`
};

export const loc_noUpcomingVaccineClinics: Record<Locale, string> = {
	ar: `لا توجد عيادات لقاح قادمة`,
	de: `Keine bevorstehenden Impfkliniken`,
	es: `No hay próximas clínicas de vacunación`,
	fa: `هیچ کلینیک واکسن آینده وجود ندارد`,
	fil: `Walang mga paparating na klinika ng bakuna`,
	fr: `Pas de cliniques de vaccination à venir`,
	hi: `कोई आगामी वैक्सीन क्लीनिक नहीं`,
	ht: `Pa gen klinik vaksen k ap vini`,
	it: `Nessuna clinica per i vaccini in arrivo`,
	ja: `今後のワクチン接種クリニックはありません`,
	ko: `예정된 백신 클리닉이 없습니다.`,
	mww: `Tsis muaj tshuaj yavtom ntej`,
	ps: `د واکسین راتلونکی کلینیکونه نشته`,
	pt: `Nenhuma clínica de vacinas futura`,
	'pt-pt': `Não há próximas clínicas de vacinação`,
	ru: `Предстоящих вакцинационных клиник нет`,
	sm: `Leai ni falemai o tui o loo loma`,
	so: `Majiraan xarumo talaal oo soo socda`,
	th: `ไม่มีคลินิกวัคซีนที่กําลังจะมาถึง`,
	ur: `آئندہ کوئی ویکسین کلینک نہیں`,
	vi: `Không có phòng khám vắc xin sắp tới`,
	'zh-hans': `没有即将到来的疫苗诊所`,
	'zh-hant': `沒有即將到來的疫苗診所`,
	en: `No upcoming vaccine clinics`
};

export const loc_noUpcomingVaccineClinicsDescription: Record<Locale, string> = {
	ar: `سيتم سرد عيادات اللقاح القادمة ضمن النطاق المحدد هنا.`,
	de: `Kommende Impfkliniken innerhalb des ausgewählten Sortiments werden hier aufgelistet.`,
	es: `Las próximas clínicas de vacunación dentro del rango seleccionado se enumerarán aquí.`,
	fa: `کلینیک های واکسن آینده در محدوده انتخاب شده در اینجا فهرست می شوند.`,
	fil: `Ang mga paparating na klinika ng bakuna sa loob ng napiling saklaw ay ilista dito.`,
	fr: `Les prochaines cliniques de vaccination dans la plage sélectionnée seront répertoriées ici.`,
	hi: `चयनित सीमा के भीतर आगामी वैक्सीन क्लीनिक यहां सूचीबद्ध किए जाएंगे।`,
	ht: `Klinik vaksen k ap vini nan seri a chwazi yo pral ki nan lis isit la.`,
	it: `Le prossime cliniche per i vaccini all'interno dell'intervallo selezionato saranno elencate qui.`,
	ja: `選択した範囲内で今後開催されるワクチンクリニックは、ここにリストされます。`,
	ko: `선택한 범위 내에서 예정된 백신 클리닉이 여기에 나열됩니다.`,
	mww: `Yavtom ntej cov chaw kho mob nyob rau hauv cov kev xaiv ntau yam yuav muaj nyob ntawm no.`,
	ps: `په ټاکل شوی حد کې د واکسین راتلونکی کلینیکونه به دلته لیست شی.`,
	pt: `As próximas clínicas de vacinas dentro da faixa selecionada serão listadas aqui.`,
	'pt-pt': `As próximas clínicas de vacinação dentro da faixa selecionada serão listadas aqui.`,
	ru: `Ближайшие клиники вакцинации в пределах выбранного диапазона будут перечислены здесь.`,
	sm: `O falemai o tui o loo loma i totonu o le vaega ua filifilia o le a lisiina iinei.`,
	so: `Xarumaha caafimaad ee soo socda ee talaalka ee soo socda ee ku guda jiro qaybta laga soo xulay ayaa lagu soo dari doonaa halkan.`,
	th: `คลินิกวัคซีนที่กําลังจะมาถึงภายในช่วงที่เลือกจะแสดงรายการที่นี่`,
	ur: `منتخب کردہ رینج کے اندر آنے والے ویکسین کلینک یہاں درج کیے جائیں گے۔`,
	vi: `Các phòng khám vắc xin sắp tới trong phạm vi đã chọn sẽ được liệt kê nhấn vào đây.`,
	'zh-hans': `此处将列出所选范围内即将成立的疫苗诊所。`,
	'zh-hant': `此處將列出所選範圍內即將成立的疫苗診所。`,
	en: `Upcoming vaccine clinics within the selected range will be listed here.`
};

export const loc_oneClinic: Record<Locale, string> = {
	ar: `عيادة واحدة`,
	de: `Eine Klinik`,
	es: `Una clínica`,
	fa: `یک کلینیک`,
	fil: `Isang klinika`,
	fr: `Une clinique`,
	hi: `एक क्लिनिक`,
	ht: `Yon klinik`,
	it: `Una clinica`,
	ja: `1つのクリニック`,
	ko: `하나의 클리닉`,
	mww: `ib lub tsev kho mob`,
	ps: `یو کلینیک`,
	pt: `Uma clínica`,
	'pt-pt': `Uma clínica`,
	ru: `Одна клиника`,
	sm: `Tasi le falemai`,
	so: `Hal taliinik`,
	th: `คลินิกเดียว`,
	ur: `ایک کلینک`,
	vi: `Một phòng khám`,
	'zh-hans': `一家诊所`,
	'zh-hant': `一家診所`,
	en: `One clinic`
};

export const loc_multipleClinicsFormat: Record<Locale, (count: unknown) => string> = {
	ar: (count) => `${count} عيادات`,
	de: (count) => `${count} Kliniken`,
	es: (count) => `${count} clínicas`,
	fa: (count) => `${count} کلینیک`,
	fil: (count) => `${count} mga klinika`,
	fr: (count) => `${count} cliniques`,
	hi: (count) => `${count} क्लीनिक`,
	ht: (count) => `${count} klinik`,
	it: (count) => `${count} cliniche`,
	ja: (count) => `${count} つのクリニック`,
	ko: (count) => `${count} 개 클리닉`,
	mww: (count) => `${count} lub tsev kho mob`,
	ps: (count) => `${count} کلینیکونه`,
	pt: (count) => `${count} clínicas`,
	'pt-pt': (count) => `${count} clínicas`,
	ru: (count) => `${count} клиник`,
	sm: (count) => `${count} falemai`,
	so: (count) => `${count} kiliinikada`,
	th: (count) => `${count} คลินิก`,
	ur: (count) => `${count} کلینکس`,
	vi: (count) => `${count} phòng khám`,
	'zh-hans': (count) => `${count} 家诊所`,
	'zh-hant': (count) => `${count} 家診所`,
	en: (count) => `${count} clinics`
};

export const loc_anyDistance: Record<Locale, string> = {
	ar: `أي مسافة`,
	de: `Beliebige Entfernung`,
	es: `Cualquier distancia`,
	fa: `هر فاصله ای`,
	fil: `Anumang distansya`,
	fr: `N’importe quelle distance`,
	hi: `कोई भी दूरी`,
	ht: `Nenpòt distans`,
	it: `Qualsiasi distanza`,
	ja: `任意の距離`,
	ko: `모든 거리`,
	mww: `nyob deb li cas`,
	ps: `هر واټن`,
	pt: `Qualquer distância`,
	'pt-pt': `Qualquer distância`,
	ru: `Любое расстояние`,
	sm: `Soo se mamao`,
	so: `Meel kasta oo u fog`,
	th: `ระยะทางใดก็ได้`,
	ur: `کوئی بھی فاصلہ`,
	vi: `Bất kỳ khoảng cách nào`,
	'zh-hans': `任何距离`,
	'zh-hant': `任何距離`,
	en: `Any distance`
};

export const loc_distanceFormat: Record<Locale, (distance: unknown, location: unknown) => string> = {
	ar: (distance, location) => `${distance} من ${location}`,
	de: (distance, location) => `${distance} von ${location}`,
	es: (distance, location) => `${distance} de ${location}`,
	fa: (distance, location) => `${distance} از ${location}`,
	fil: (distance, location) => `${distance} mula sa ${location}`,
	fr: (distance, location) => `${distance} de ${location}`,
	hi: (location, distance) => `${location} से ${distance}`,
	ht: (distance, location) => `${distance} soti nan ${location}`,
	it: (distance, location) => `${distance} da ${location}`,
	ja: (location, distance) => `${location}から${distance}`,
	ko: (location, distance) => `${location} ${distance}`,
	mww: (distance, location) => `${distance} ntawm ${location}`,
	ps: (location, distance) => `د ${location} څخه ${distance}`,
	pt: (distance, location) => `${distance} de ${location}`,
	'pt-pt': (distance, location) => `${distance} de ${location}`,
	ru: (distance, location) => `${distance} от ${location}`,
	sm: (distance, location) => `${distance} mai ${location}`,
	so: (distance, location) => `${distance} u ${location}`,
	th: (distance, location) => `${distance} จาก ${location}`,
	ur: (location, distance) => `${location} سے ${distance}`,
	vi: (distance, location) => `${distance} từ ${location}`,
	'zh-hans': (location, distance) => `距离 ${location} ${distance}`,
	'zh-hant': (location, distance) => `距離 ${location} ${distance}`,
	en: (distance, location) => `${distance} from ${location}`
};

export const loc_tenMiles: Record<Locale, string> = {
	ar: `10 أميال`,
	de: `10 Meilen`,
	es: `10 millas`,
	fa: `10 مایل`,
	fil: `10 milya`,
	fr: `10 milles`,
	hi: `10 मील`,
	ht: `10 kilomèt`,
	it: `10 miglia`,
	ja: `10マイル`,
	ko: `10마일`,
	mww: `10 mais`,
	ps: `10 مایل`,
	pt: `10 milhas`,
	'pt-pt': `10 milhas`,
	ru: `10 миль`,
	sm: `10 maila`,
	so: `10 miles`,
	th: `16.1 กม.`,
	ur: `10 میل`,
	vi: `10 dặm`,
	'zh-hans': `10 英里`,
	'zh-hant': `10 英里`,
	en: `10 miles`
};

export const loc_date: Record<Locale, string> = {
	ar: `تاريخ`,
	de: `Datum`,
	es: `Fecha`,
	fa: `تاریخ`,
	fil: `Petsa`,
	fr: `Date`,
	hi: `खजूर`,
	ht: `Dat`,
	it: `Dattero`,
	ja: `日付`,
	ko: `날짜`,
	mww: `Hnub`,
	ps: `نېټه`,
	pt: `Data`,
	'pt-pt': `Data`,
	ru: `Дата`,
	sm: `Aso`,
	so: `Date`,
	th: `วันที่`,
	ur: `تاریخ`,
	vi: `Ngày`,
	'zh-hans': `日期`,
	'zh-hant': `日期`,
	en: `Date`
};

export const loc_distance: Record<Locale, string> = {
	ar: `بون`,
	de: `Abstand`,
	es: `Distancia`,
	fa: `فاصله`,
	fil: `Distansya`,
	fr: `Distance`,
	hi: `दूरी`,
	ht: `Distans`,
	it: `Distanza`,
	ja: `距離`,
	ko: `거리`,
	mww: `nyob deb li cas`,
	ps: `فاصله`,
	pt: `Distância`,
	'pt-pt': `Distância`,
	ru: `Расстояние`,
	sm: `Mamao`,
	so: `Distance`,
	th: `ระยะทาง`,
	ur: `فاصلہ`,
	vi: `Khoảng cách`,
	'zh-hans': `距离`,
	'zh-hant': `距離`,
	en: `Distance`
};

export const loc_inactive: Record<Locale, string> = {
	ar: `كسلان`,
	de: `Inaktiv`,
	es: `Inactivo`,
	fa: `غیر فعال`,
	fil: `Hindi aktibo`,
	fr: `Inactif`,
	hi: `निष्क्रिय`,
	ht: `Inaktif`,
	it: `Inattivo`,
	ja: `不活発な`,
	ko: `게으른`,
	mww: `ua tsis taus pa`,
	ps: `ناچارند`,
	pt: `Inativo`,
	'pt-pt': `Inativo`,
	ru: `Неактивный`,
	sm: `Le Toaga`,
	so: `Inactive`,
	th: `เกียจคร้าน`,
	ur: `غیر فعال`,
	vi: `Không hoạt động`,
	'zh-hans': `无效`,
	'zh-hant': `無效`,
	en: `Inactive`
};

export const loc_contract: Record<Locale, string> = {
	ar: `عقد`,
	de: `Vertrag`,
	es: `Contrato`,
	fa: `قرارداد`,
	fil: `Kontrata`,
	fr: `Contrat`,
	hi: `इक़रारनामा करना`,
	ht: `Kontra`,
	it: `Contratto`,
	ja: `契約`,
	ko: `계약`,
	mww: `daim ntawv cog lus`,
	ps: `تړون`,
	pt: `Contrato`,
	'pt-pt': `Contrato`,
	ru: `Контракт`,
	sm: `Feagaiga`,
	so: `Heshiiska`,
	th: `สัญญา`,
	ur: `معاہدہ`,
	vi: `Hợp đồng`,
	'zh-hans': `合同`,
	'zh-hant': `合同`,
	en: `Contract`
};

export const loc_clearSelection: Record<Locale, string> = {
	ar: `مسح التحديد`,
	de: `Auswahl aufheben`,
	es: `Borrar selección`,
	fa: `پاک کردن انتخاب`,
	fil: `I-clear ang pagpili`,
	fr: `Effacer la sélection`,
	hi: `चयन साफ़ करें`,
	ht: `Seleksyon klè`,
	it: `Cancella selezione`,
	ja: `選択をクリア`,
	ko: `선택 해제`,
	mww: `xaiv tsa`,
	ps: `ټاکنه پاکول`,
	pt: `Limpar seleção`,
	'pt-pt': `Seleção clara`,
	ru: `Очистить выбор`,
	sm: `Manino le filifiliga`,
	so: `Xulashada clear`,
	th: `ล้างการเลือก`,
	ur: `واضح انتخاب`,
	vi: `Lựa chọn rõ ràng`,
	'zh-hans': `清除选择`,
	'zh-hant': `清除選擇`,
	en: `Clear selection`
};

export const loc_notFound: Record<Locale, string> = {
	ar: `لم يتم العثور على`,
	de: `Zur Zeit nicht gefunden`,
	es: `No encontrado`,
	fa: `یافت نشد`,
	fil: `Hindi natagpuan`,
	fr: `Non trouvé`,
	hi: `नहीं मिला`,
	ht: `Pa jwenn`,
	it: `Non trovato`,
	ja: `見つかりませんでした`,
	ko: `찾을 수 없음`,
	mww: `nrhiav tsis tau`,
	ps: `ونه موندل شو`,
	pt: `Não encontrado`,
	'pt-pt': `Não encontrado`,
	ru: `Не найдено`,
	sm: `E le'i maua`,
	so: `Lama helin`,
	th: `ไม่พบ`,
	ur: `نہیں ملا`,
	vi: `Không tìm thấy`,
	'zh-hans': `未找到`,
	'zh-hant': `未找到`,
	en: `Not found`
};

export const loc_notFoundMessage: Record<Locale, string> = {
	ar: `لا يمكن العثور على الصفحة التي طلبتها.`,
	de: `Die von Ihnen angeforderte Seite kann nicht gefunden werden.`,
	es: `No se puede encontrar la página que solicitó.`,
	fa: `صفحه ای که درخواست کرده اید پیدا نمی شود.`,
	fil: `Hindi mahanap ang pahinang hiniling mo.`,
	fr: `La page que vous avez demandée est introuvable.`,
	hi: `आपके द्वारा अनुरोधित पृष्ठ नहीं मिल सका.`,
	ht: `Paj ou te mande a pa ka jwenn.`,
	it: `Impossibile trovare la pagina richiesta.`,
	ja: `リクエストしたページが見つかりません。`,
	ko: `요청하신 페이지를 찾을 수 없습니다.`,
	mww: `Nplooj ntawv koj thov tsis tau.`,
	ps: `هغه پاڼه چې تاسو یې غوښتنه کړې وه ونه موندل شوه.`,
	pt: `A página que você solicitou não pode ser encontrada.`,
	'pt-pt': `A página solicitada não pode ser encontrada.`,
	ru: `Запрошенная вами страница не найдена.`,
	sm: `E le o maua le itulau na e talosagaina.`,
	so: `Bogga aad codsatay lama heli karo.`,
	th: `ไม่พบหน้าที่คุณร้องขอ`,
	ur: `آپ کی درخواست کردہ صفحہ تلاش نہیں کیا جا سکتا۔`,
	vi: `Không thể tìm thấy trang bạn yêu cầu.`,
	'zh-hans': `找不到您请求的页面。`,
	'zh-hant': `找不到您請求的頁面。`,
	en: `The page you requested cannot be found.`
};

export const loc_notFoundDescription: Record<Locale, string> = {
	ar: `قد يكون عنوان URL به خطأ إملائي أو أن الصفحة التي تبحث عنها لم تعد متوفرة.`,
	de: `Möglicherweise ist die URL falsch geschrieben oder die gesuchte Seite ist nicht mehr verfügbar.`,
	es: `Es posible que la URL esté mal escrita o que la página que estás buscando ya no esté disponible.`,
	fa: `ممکن است نشانی وب اشتباه املایی باشد یا صفحه مورد نظر شما دیگر دردسترس نباشد.`,
	fil: `Maaaring mali ang spelling ng URL o wala na ang page na hinahanap mo.`,
	fr: `Il se peut que l’URL soit mal orthographiée ou que la page que vous recherchez ne soit plus disponible.`,
	hi: `हो सकता है कि URL की वर्तनी गलत हो या आप जिस पृष्ठ को खोज रहे हैं वह अब उपलब्ध न हो.`,
	ht: `URL la ka disparèt oswa paj la w ap chèche pou pa disponib ankò.`,
	it: `L'URL potrebbe essere stato digitato in modo errato o la pagina che stai cercando non è più disponibile.`,
	ja: `URLのスペルが間違っているか、探しているページが利用できなくなっている可能性があります。`,
	ko: `URL의 철자가 잘못되었거나 찾고 있는 페이지를 더 이상 사용할 수 없습니다.`,
	mww: `Lub URL tej zaum yuav misspelled los yog nplooj ntawv uas koj nyob nraum nrhiav tsis muaj.`,
	ps: `URL ممکن غلط لیکل شوی وی یا هغه پا pageه چې تاسو یې په لټه کې یاست نور شتون نلری.`,
	pt: `O URL pode estar incorreto ou a página que você está procurando não está mais disponível.`,
	'pt-pt': `O URL pode estar escrito incorretamente ou a página que você está procurando não está mais disponível.`,
	ru: `URL-адрес может быть написан с ошибкой или страница, которую вы ищете, больше не доступна.`,
	sm: `Atonu e sese le sipelaina o le URL pe ua le o toe maua le itulau o loo e sailia.`,
	so: `Waxaa laga yaabaa in si qaldan loo iibiyo URL ama bogga aad raadineysid aan la heli karin.`,
	th: `URL อาจสะกดผิดหรือหน้าเว็บที่ต้องการไม่พร้อมใช้งานอีกต่อไป`,
	ur: `ہوسکتا ہے کہ یو آر ایل کا غلط املا کیا گیا ہو یا جس صفحے کی آپ تلاش کر رہے ہیں وہ اب دستیاب نہیں ہے۔`,
	vi: `URL có thể bị viết sai chính tả hoặc trang bạn đang tìm kiếm không còn khả dụng nữa.`,
	'zh-hans': `URL 可能拼写错误，或者您要查找的页面不再可用。`,
	'zh-hant': `URL 可能拼寫錯誤，或者您要查找的頁面不再可用。`,
	en: `The URL may be misspelled or the page you're looking for is no longer available.`
};

export const loc_internalServerError: Record<Locale, string> = {
	ar: `خطأ داخلي في الخادم`,
	de: `Interner Serverfehler`,
	es: `Error interno del servidor`,
	fa: `خطای سرور داخلی`,
	fil: `Error sa panloob na server`,
	fr: `Erreur de serveur interne`,
	hi: `आंतरिक सर्वर त्रुटि`,
	ht: `Internal sèvè erè`,
	it: `Errore interno del server`,
	ja: `内部サーバーエラー`,
	ko: `내부 서버 오류`,
	mww: `Internal server yuam kev`,
	ps: `د پالنګر دنننۍ تېروتنه`,
	pt: `Erro interno do servidor`,
	'pt-pt': `Erro interno do servidor`,
	ru: `Внутренняя ошибка сервера`,
	sm: `Mea sese i totonu o le tautua autu`,
	so: `Qalad server-ga gudaha ah`,
	th: `ข้อผิดพลาดของเซิร์ฟเวอร์ภายใน`,
	ur: `اندرونی سرور کی غلطی`,
	vi: `Lỗi máy chủ nội bộ`,
	'zh-hans': `内部服务器错误`,
	'zh-hant': `內部伺服器錯誤`,
	en: `Internal server error`
};

export const loc_internalServerErrorMessage: Record<Locale, string> = {
	ar: `حدث خطأ ما من جانبنا.`,
	de: `Irgendetwas ist auf unserer Seite schief gelaufen.`,
	es: `Algo salió mal por nuestra parte.`,
	fa: `مشکلی از طرف ما پیش آمد.`,
	fil: `May nangyaring mali sa aming dulo.`,
	fr: `Quelque chose s’est mal passé de notre côté.`,
	hi: `हमारी ओर से कुछ गलत हुआ।`,
	ht: `Gen yon bagay ki te mal nan fen nou.`,
	it: `Qualcosa è andato storto da parte nostra.`,
	ja: `私たちの側で何かがうまくいかなかった。`,
	ko: `우리 쪽에서 뭔가 잘못되었습니다.`,
	mww: `Tej yam mus tsis ncaj ncees lawm rau peb kawg.`,
	ps: `زموږ په پای کې یو څه غلط شو.`,
	pt: `Algo deu errado do nosso lado.`,
	'pt-pt': `Algo correu mal do nosso lado.`,
	ru: `Что-то пошло не так с нашей стороны.`,
	sm: `Sa i ai se mea na faaletonu i lo matou itu.`,
	so: `Wax baa khaldamay dhamaadkeennii.`,
	th: `มีบางอย่างผิดพลาดในตอนท้ายของเรา`,
	ur: `ہماری طرف سے کچھ غلط ہو گیا.`,
	vi: `Đã xảy ra sự cố ở phía chúng tôi.`,
	'zh-hans': `我们这边出了点问题。`,
	'zh-hant': `我們這邊出了點問題。`,
	en: `Something went wrong on our end.`
};

export const loc_internalServerErrorDescription: Record<Locale, string> = {
	ar: `حدث خطأ أثناء معالجة طلبك. تم تنبيه فريق الركام. إذا استمرت المشكلة أو كانت لديك أي أسئلة، فيرجى الاتصال بالدعم.`,
	de: `Bei der Bearbeitung Ihrer Anfrage ist ein Fehler aufgetreten. Das Cumulus-Team wurde alarmiert. Wenn das Problem weiterhin besteht oder Sie Fragen haben, wenden Sie sich bitte an den Support.`,
	es: `Se ha producido un error al procesar su solicitud. El equipo de Cumulus ha sido alertado. Si el problema persiste o tiene alguna pregunta, póngase en contacto con el servicio de asistencia.`,
	fa: `هنگام پردازش درخواست شما خطایی رخ داد. به تیم کومولوس هشدار داده شده است. اگر مشکل همچنان ادامه داشت یا سوالی دارید، لطفا با پشتیبانی تماس بگیرید.`,
	fil: `May nangyaring error habang pinoproseso ang iyong kahilingan. Naalerto na ang Cumulus team. Kung ang problema ay patuloy o mayroon kang anumang mga katanungan, mangyaring makipag ugnay sa suporta.`,
	fr: `Une erreur s’est produite lors du traitement de votre demande. L’équipe Cumulus a été alertée. Si le problème persiste ou si vous avez des questions, veuillez contacter le support.`,
	hi: `आपके अनुरोध को संसाधित करते समय कोई त्रुटि उत्पन्न हुई. क्यूम्यलस टीम को अलर्ट कर दिया गया है। यदि समस्या बनी रहती है या आपके कोई प्रश्न हैं, तो कृपया समर्थन से संपर्क करें।`,
	ht: `Yon erè ki te fèt pandan y ap trete demann ou an. Ekip Culus la te avèti. Si pwoblèm nan pèsiste oswa ou gen nenpòt kesyon, tanpri kontakte sipò.`,
	it: `Si è verificato un errore durante l'elaborazione della richiesta. Il team di Cumulus è stato allertato. Se il problema persiste o hai domande, contatta l'assistenza.`,
	ja: `リクエストの処理中にエラーが発生しました。Cumulus チームにアラートが送信されました。問題が解決しない場合、またはご不明な点がございましたら、サポートまでお問い合わせください。`,
	ko: `요청을 처리하는 동안 오류가 발생했습니다. Cumulus 팀에게 경고가 전달되었습니다. 문제가 지속되거나 질문이 있는 경우 지원팀에 문의하세요.`,
	mww: `Ib qho yuam kev tshwm sim thaum uas koj thov. Pab neeg Cumulus muaj tau alerted. Yog hais tias qhov teeb meem persists los yog koj muaj lus nug, thov hu rau kev them nyiaj yug.`,
	ps: `ستاسو د غوښتنې په پروسس کولو کې ستونزه رامنځته شوه. د ولسی جرګې غړی په دې اړه خبرداری ورکړل شوی دی. که ستونزه دوام ولری یا تاسو کومه پوښتنه لرئ ، مهربانی وکړئ د ملاتړ سره اړیکه ونیسئ.`,
	pt: `Ocorreu um erro durante o processamento de sua solicitação. A equipe do Cumulus foi alertada. Se o problema persistir ou você tiver alguma dúvida, entre em contato com o suporte.`,
	'pt-pt': `Ocorreu um erro durante o processamento do seu pedido. A equipa da Cumulus foi alertada. Se o problema persistir ou se tiver alguma dúvida, contacte o suporte.`,
	ru: `Произошла ошибка при обработке вашего запроса. Команда Cumulus была предупреждена. Если проблема не устранена или у вас возникли вопросы, обратитесь в службу поддержки.`,
	sm: `Na tupu se mea sese a o faagaoioia lau talosaga. Ua lapataia le au Cumulus. Afai e tumau pea le faafitauli pe i ai foi ni au fesili, faamolemole faafesootai le lagolago.`,
	so: `Qalad ayaa dhacay iyada oo laga baaraandegayey codsigaaga. Kooxda Cumulus ayaa loo soo gudbiyay feejignayn. Haddii dhibaatadu ay socoto ama aad wax suʼaalo ah qabtid, fadlan la soo xiriir taageero.`,
	th: `เกิดข้อผิดพลาดขณะประมวลผลคําขอของคุณ ทีม Cumulus ได้รับการแจ้งเตือนแล้ว หากปัญหายังคงอยู่หรือคุณมีคําถามใดๆ โปรดติดต่อฝ่ายสนับสนุน`,
	ur: `آپ کی درخواست پر کارروائی کرتے وقت ایک نقص واقع ہوا۔ کمولس ٹیم کو الرٹ کر دیا گیا ہے۔ اگر مسئلہ برقرار رہتا ہے یا آپ کے کوئی سوالات ہیں تو، براہ مہربانی مدد سے رابطہ کریں.`,
	vi: `Đã xảy ra lỗi trong khi xử lý yêu cầu của bạn. Nhóm Cumulus đã được cảnh báo. Nếu sự cố vẫn tiếp diễn hoặc bạn có bất kỳ câu hỏi nào, vui lòng liên hệ với bộ phận hỗ trợ.`,
	'zh-hans': `处理您的请求时出错。Cumulus 团队已收到警报。如果问题仍然存在或您有任何疑问，请联系支持人员。`,
	'zh-hant': `處理您的請求時發生錯誤。Cumulus 團隊已收到警報。如果問題仍然存在或您有任何疑問，請聯繫支持人員。`,
	en: `An error occurred while processing your request. The Cumulus team has been alerted. If the problem persists or you have any questions, please contact support.`
};

export const loc_forbidden: Record<Locale, string> = {
	ar: `ممنوع`,
	de: `Verboten`,
	es: `Prohibido`,
	fa: `ممنوع`,
	fil: `Bawal na`,
	fr: `Interdit`,
	hi: `वर्जित`,
	ht: `Entèdi`,
	it: `Vietato`,
	ja: `禁じられた`,
	ko: `금지된`,
	mww: `Forbidden`,
	ps: `منع`,
	pt: `Proibido`,
	'pt-pt': `Proibido`,
	ru: `Запретный`,
	sm: `Faasāina`,
	so: `Reebay`,
	th: `ต้องห้าม`,
	ur: `منع`,
	vi: `Bị cấm`,
	'zh-hans': `禁止`,
	'zh-hant': `禁止`,
	en: `Forbidden`
};

export const loc_forbiddenMessage: Record<Locale, string> = {
	ar: `ليس لديك حق الوصول إلى هذه الصفحة.`,
	de: `Sie haben keinen Zugriff auf diese Seite.`,
	es: `Usted no tiene acceso a esta página.`,
	fa: `شما به این صفحه دسترسی ندارید.`,
	fil: `Wala kang access sa pahinang ito.`,
	fr: `Vous n’avez pas accès à cette page.`,
	hi: `आपके पास इस पृष्ठ तक पहुँच नहीं है.`,
	ht: `Ou pa gen aksè a paj sa a.`,
	it: `Non hai accesso a questa pagina.`,
	ja: `このページにアクセスできません。`,
	ko: `이 페이지에 액세스할 수 없습니다.`,
	mww: `Koj tsis tas yuav mus saib tau nplooj ntawv no.`,
	ps: `تاسو دې ویب پا accessې ته لاسرسی نلرئ.`,
	pt: `Você não tem acesso a esta página.`,
	'pt-pt': `Você não tem acesso a esta página.`,
	ru: `У вас нет доступа к этой странице.`,
	sm: `E le mafai ona e faaaogaina lenei itulau.`,
	so: `Ma lihid galitaanka boggan.`,
	th: `คุณไม่มีสิทธิ์เข้าถึงหน้านี้`,
	ur: `آپ کو اس صفحے تک رسائی حاصل نہیں ہے.`,
	vi: `Bạn không có quyền truy cập vào trang này.`,
	'zh-hans': `您无权访问此页面。`,
	'zh-hant': `您無權訪問此頁面。`,
	en: `You do not have access to this page.`
};

export const loc_forbiddenDescription: Record<Locale, string> = {
	ar: `هذه الصفحة موجودة ولكن دورك أو عضوية مؤسستك لا تمنحك الإذن بالوصول إليها.`,
	de: `Diese Seite ist vorhanden, aber Ihre Rolle oder Organisationsmitgliedschaft gewährt Ihnen keine Zugriffsberechtigung darauf.`,
	es: `Esta página existe, pero su rol o pertenencia a la organización no le concede permisos de acceso a ella.`,
	fa: `این صفحه وجود دارد اما نقش یا عضویت سازمان شما به شما اجازه دسترسی به آن را نمی دهد.`,
	fil: `Ang pahinang ito ay umiiral ngunit ang iyong papel o pagiging miyembro ng organisasyon ay hindi nagbibigay sa iyo ng pahintulot na ma access ito.`,
	fr: `Cette page existe, mais votre rôle ou votre appartenance à l’organisation ne vous autorise pas à y accéder.`,
	hi: `यह पृष्ठ मौजूद है लेकिन आपकी भूमिका या संगठन सदस्यता आपको इस पर अनुमति पहुँच प्रदान नहीं करती है.`,
	ht: `Paj sa egziste men wòl ou jwe oswa manm òganizasyon an pa ba ou pèmisyon aksè a li.`,
	it: `Questa pagina esiste, ma l'appartenenza al ruolo o all'organizzazione non concede l'autorizzazione di accesso.`,
	ja: `このページは存在しますが、ロールまたは組織のメンバーシップによって、このページへのアクセス権が付与されていません。`,
	ko: `이 페이지는 존재하지만 역할 또는 조직 멤버십이 해당 페이지에 대한 액세스 권한을 부여하지 않습니다.`,
	mww: `Nplooj ntawv no tshwm sim tab sis koj lub luag hauj lwm los yog koom haum ua tswv cuab tsis tso cai rau koj tso cai rau nws.`,
	ps: `دا پاڼه شتون لری مګر ستاسو رول یا د سازمان غړیتوب تاسو ته اجازه نه درکوی چې دې ته لاسرسی ومومئ.`,
	pt: `Esta página existe, mas sua função ou associação à organização não concede a você permissão de acesso a ela.`,
	'pt-pt': `Esta página existe, mas a sua função ou associação à organização não lhe concede permissão de acesso à mesma.`,
	ru: `Эта страница существует, но ваша роль или членство в организации не дает вам права доступа к ней.`,
	sm: `O loo i ai lenei itulau ae o lau matafaioi po o le pepa faamaumau o le faalapotopotoga e le tuuina atu ai ia te oe se faatagaga e faaaoga ai.`,
	so: `Boggan waa mid ka jira laakiin kaalintaada ama xubinnimada ururku kuma siinayo ogolaanshaha gelitaankaaga.`,
	th: `หน้านี้มีอยู่ แต่บทบาทหรือการเป็นสมาชิกขององค์กรของคุณไม่ได้ให้สิทธิ์การเข้าถึงหน้านั้น`,
	ur: `یہ صفحہ موجود ہے لیکن آپ کا کردار یا تنظیم کی رکنیت آپ کو اس تک رسائی کی اجازت نہیں دیتی ہے۔`,
	vi: `Trang này tồn tại nhưng vai trò hoặc tư cách thành viên tổ chức của bạn không cấp cho bạn quyền truy cập vào trang đó.`,
	'zh-hans': `此页面存在，但您的角色或组织成员身份未授予您访问该页面的权限。`,
	'zh-hant': `此頁面存在，但您的角色或組織成員身份未授予您訪問該頁面的許可權。`,
	en: `This page exists but your role or organization membership does not grant you permission access to it.`
};

export const loc_notAuthorized: Record<Locale, string> = {
	ar: `غير مصرح به`,
	de: `Nicht autorisiert`,
	es: `No autorizado`,
	fa: `مجاز نیست`,
	fil: `Hindi awtorisado`,
	fr: `Non autorisé`,
	hi: `अधिकृत नहीं`,
	ht: `Pa otorize`,
	it: `Non autorizzato`,
	ja: `認証されていません`,
	ko: `승인되지 않음`,
	mww: `Tsis tso cai`,
	ps: `نه دی منل شوی`,
	pt: `Não autorizado`,
	'pt-pt': `Não autorizado`,
	ru: `Не авторизовано`,
	sm: `E le faatagaina`,
	so: `Lama oggola`,
	th: `ไม่ได้รับอนุญาต`,
	ur: `مجاز نہیں`,
	vi: `Không được ủy quyền`,
	'zh-hans': `未授权`,
	'zh-hant': `未授權`,
	en: `Not authorized`
};

export const loc_notAuthorizedMessage: Record<Locale, string> = {
	ar: `سجل الدخول للوصول إلى هذه الصفحة.`,
	de: `Melden Sie sich an, um auf diese Seite zuzugreifen.`,
	es: `Inicie sesión para acceder a esta página.`,
	fa: `برای دسترسی به این صفحه وارد شوید.`,
	fil: `Mag-sign in para ma-access ang pahinang ito.`,
	fr: `Connectez-vous pour accéder à cette page.`,
	hi: `इस पृष्ठ तक पहुँचने के लिए साइन इन करें.`,
	ht: `Siyen pou jwenn aksè nan paj sa a.`,
	it: `Effettua l'accesso per accedere a questa pagina.`,
	ja: `このページにアクセスするには、サインインしてください。`,
	ko: `이 페이지에 액세스하려면 로그인하십시오.`,
	mww: `Kos npe rau ntawm nplooj ntawv no.`,
	ps: `دې پاڼې ته د لاسرسی لپاره لاسلیک وکړئ.`,
	pt: `Faça login para acessar esta página.`,
	'pt-pt': `Inicie sessão para aceder a esta página.`,
	ru: `Войдите в систему, чтобы получить доступ к этой странице.`,
	sm: `Saini e alu i totonu e faaaoga ai le itulau lenei.`,
	so: `Gali si aad u hesho boggan.`,
	th: `ลงชื่อเข้าใช้เพื่อเข้าถึงหน้านี้`,
	ur: `اس صفحے تک رسائی حاصل کرنے کے لئے سائن ان کریں۔`,
	vi: `Đăng nhập để truy cập trang này.`,
	'zh-hans': `登录以访问此页面。`,
	'zh-hant': `登錄以訪問此頁面。`,
	en: `Sign in to access this page.`
};

export const loc_notAuthorizedDescription: Record<Locale, string> = {
	ar: `يجب عليك تسجيل الدخول للوصول إلى هذه الصفحة.`,
	de: `Sie müssen angemeldet sein, um auf diese Seite zugreifen zu können.`,
	es: `Debe iniciar sesión para acceder a esta página.`,
	fa: `برای دسترسی به این صفحه باید وارد سیستم شوید.`,
	fil: `Kailangan kang i-sign in para ma-access ang pahinang ito.`,
	fr: `Vous devez être connecté pour accéder à cette page.`,
	hi: `इस पृष्ठ तक पहुँचने के लिए आपको साइन-इन होना आवश्यक है.`,
	ht: `Ou dwe siyen pou jwenn aksè nan paj sa a.`,
	it: `Per accedere a questa pagina è necessario aver effettuato l'accesso.`,
	ja: `このページにアクセスするには、サインインする必要があります。`,
	ko: `이 페이지에 액세스하려면 로그인해야 합니다.`,
	mww: `Koj yuav tsum kos npe rau daim ntawv no kom muaj nyob hauv phab ntawv no.`,
	ps: `تاسو باید دې پا accessې ته د لاسرسی لپاره لاسلیک وکړئ.`,
	pt: `Você deve estar conectado para acessar esta página.`,
	'pt-pt': `Tem de ter sessão iniciada para aceder a esta página.`,
	ru: `Для доступа к этой странице необходимо войти в систему.`,
	sm: `E tatau ona e saini e alu i totonu ina ia mafai ai ona e faaaogaina lenei itulau.`,
	so: `Waa in aad saxiixdaa-in si aad u hesho boggan.`,
	th: `คุณต้องลงชื่อเข้าใช้เพื่อเข้าถึงหน้านี้`,
	ur: `اس صفحے تک رسائی حاصل کرنے کے لئے آپ کا سائن ان ہونا ضروری ہے۔`,
	vi: `Bạn phải đăng nhập để truy cập trang này.`,
	'zh-hans': `您必须登录才能访问此页面。`,
	'zh-hant': `您必須登錄才能存取此頁面。`,
	en: `You must be signed-in to access this page.`
};

export const loc_errorPageLinksInstructions: Record<Locale, (cumulus: unknown) => string> = {
	ar: (cumulus) => `حدد أحد الروابط أدناه للوصول إلى منطقة أخرى من ${cumulus}.`,
	de: (cumulus) => `Wählen Sie einen der untenstehenden Links, um auf einen anderen Bereich von ${cumulus} zuzugreifen.`,
	es: (cumulus) => `Seleccione uno de los enlaces a continuación para acceder a otra área de ${cumulus}.`,
	fa: (cumulus) => `یکی از پیوندهای زیر را برای دسترسی به منطقه دیگری از ${cumulus} انتخاب کنید.`,
	fil: (cumulus) => `Pumili ng isa sa mga link sa ibaba upang ma access ang isa pang lugar ng ${cumulus}.`,
	fr: (cumulus) => `Sélectionnez l’un des liens ci-dessous pour accéder à une autre zone de ${cumulus}.`,
	hi: (cumulus) => `${cumulus} के किसी अन्य क्षेत्र तक पहुँचने के लिए नीचे दिए गए लिंक में से एक का चयन करें।`,
	ht: (cumulus) => `Chwazi youn nan lyen ki anba yo jwenn aksè nan yon lòt zòn nan ${cumulus}.`,
	it: (cumulus) => `Seleziona uno dei link sottostanti per accedere a un'altra area di ${cumulus}.`,
	ja: (cumulus) => `以下のリンクのいずれかを選択して、 ${cumulus} の別の領域にアクセスします。`,
	ko: (cumulus) => `아래 링크 중 하나를 선택하여 ${cumulus}의 다른 영역에 액세스하십시오.`,
	mww: (cumulus) => `Xaiv ib qhov txuas hauv qab no mus saib tau lwm cheeb tsam ntawm ${cumulus}.`,
	ps: (cumulus) => `د لاندې لینکونو څخه یو غوره کړئ ترڅو د ${cumulus} ساحې ته لاسرسی ومومئ.`,
	pt: (cumulus) => `Selecione um dos links abaixo para acessar outra área do ${cumulus}.`,
	'pt-pt': (cumulus) => `Selecione um dos links abaixo para acessar outra área do ${cumulus}.`,
	ru: (cumulus) => `Выберите одну из ссылок ниже, чтобы получить доступ к другой области ${cumulus}.`,
	sm: (cumulus) => `Filifili se tasi o sootaga o loo i lalo e faaaoga ai se isi eria o ${cumulus}.`,
	so: (cumulus) => `Xulo mid ka mid ah isku xirayaasha hoose si aad u hesho meel kale oo ${cumulus} ah.`,
	th: (cumulus) => `เลือกหนึ่งในลิงค์ด้านล่างเพื่อเข้าถึงพื้นที่อื่นของ ${cumulus}`,
	ur: (cumulus) => `کمولس کے دوسرے علاقے تک رسائی حاصل کرنے کے لئے نیچے دیئے گئے لنکس میں سے ایک کو منتخب ${cumulus}۔`,
	vi: (cumulus) => `Chọn một trong các liên kết bên dưới để truy cập một khu vực khác của ${cumulus}.`,
	'zh-hans': (cumulus) => `选择下面的链接之一以访问 ${cumulus} 的另一个区域。`,
	'zh-hant': (cumulus) => `選擇下面的連結之一以訪問 ${cumulus} 的另一個區域。`,
	en: (cumulus) => `Select one of the links below to access another area of ${cumulus}.`
};

export const loc_noActiveContractWithAssignee: Record<Locale, string> = {
	ar: `لا توجد منظمات لديها عقد نشط مع المحال إليه المختار.`,
	de: `Keine Organisation hat einen aktiven Vertrag mit dem ausgewählten Beauftragten.`,
	es: `Ninguna organización tiene un contrato activo con la persona asignada seleccionada.`,
	fa: `هیچ سازمانی قرارداد فعالی با واگذار کننده انتخاب شده ندارد.`,
	fil: `Walang mga organisasyon ang may aktibong kontrata sa napiling assignee.`,
	fr: `Aucune organisation n’a de contrat actif avec le cessionnaire sélectionné.`,
	hi: `चयनित समनुदेशिती के साथ किसी भी संगठन का सक्रिय अनुबंध नहीं है.`,
	ht: `Pa gen okenn òganizasyon ki gen yon kontra aktif ak asiyen yo chwazi.`,
	it: `Nessuna organizzazione ha un contratto attivo con l'assegnatario selezionato.`,
	ja: `選択した担当者と有効な契約を結んでいる組織はありません。`,
	ko: `선택한 담당자와 활성 계약을 맺고 있는 조직이 없습니다.`,
	mww: `Tsis muaj koom haum muaj ib daim ntawv cog lus nrog xaiv cov neeg xaiv.`,
	ps: `هیڅ سازمان د ټاکل شوی ټاکل شوی سره فعال قرارداد نلری.`,
	pt: `Nenhuma organização tem um contrato ativo com o destinatário selecionado.`,
	'pt-pt': `Nenhuma organização tem um contrato ativo com o cessionário selecionado.`,
	ru: `Ни одна организация не имеет действующего договора с выбранным цессионарием.`,
	sm: `E leai ni faalapotopotoga e i ai se konekarate o loo faagaoioia ma le tagata ua filifilia ua tofia.`,
	so: `Majiraan ururo heshiis firfircoon oo lala galay shaqsiyihi loo xushay.`,
	th: `ไม่มีองค์กรใดมีสัญญาที่ใช้งานอยู่กับผู้รับมอบหมายที่เลือก`,
	ur: `منتخب کردہ تفویض کنندہ کے ساتھ کسی بھی تنظیم کا فعال معاہدہ نہیں ہے۔`,
	vi: `Không có tổ chức nào có hợp đồng hoạt động với người được chuyển nhượng được chọn.`,
	'zh-hans': `没有组织与所选受理人签订有效合同。`,
	'zh-hant': `沒有組織與所選受理人簽訂有效合同。`,
	en: `No organizations have an active contract with the selected assignee.`
};

export const loc_unspecifiedSchedule: Record<Locale, string> = {
	ar: `جدول غير محدد`,
	de: `Nicht spezifizierter Zeitplan`,
	es: `Horario no especificado`,
	fa: `برنامه نامشخص`,
	fil: `Hindi tinukoy na iskedyul`,
	fr: `Horaire non précisé`,
	hi: `अनिर्दिष्ट अनुसूची`,
	ht: `Orè ki pa espesifye`,
	it: `Orario non specificato`,
	ja: `スケジュール不詳`,
	ko: `지정되지 않은 일정`,
	mww: `Unspecified sij hawm`,
	ps: `ناځانګړ شوی مهال وېش`,
	pt: `Horário não especificado`,
	'pt-pt': `Horário não especificado`,
	ru: `Неуказанное расписание`,
	sm: `Faatulagaga e le'i faailoaina`,
	so: `Jadwal aan la cayimin`,
	th: `กําหนดการที่ไม่ระบุ`,
	ur: `غیر متعین شیڈول`,
	vi: `Lịch trình không xác định`,
	'zh-hans': `未指定的时间表`,
	'zh-hant': `未指定的時程表`,
	en: `Unspecified schedule`
};

export const loc_unspecifiedVaccinations: Record<Locale, string> = {
	ar: `لقاحات غير محددة`,
	de: `Nicht näher bezeichnete Impfungen`,
	es: `Vacunas no especificadas`,
	fa: `واکسیناسیون های نامشخص`,
	fil: `Mga hindi tinukoy na bakuna`,
	fr: `Vaccinations non spécifiées`,
	hi: `अनिर्दिष्ट टीकाकरण`,
	ht: `Vaksen ki pa espesifye yo`,
	it: `Vaccinazioni non specificate`,
	ja: `不特定の予防接種`,
	ko: `지정되지 않은 백신 접종`,
	mww: `Txhaj tshuaj tiv thaiv kab mob`,
	ps: `نامعلوم واکسینونه`,
	pt: `Vacinas não especificadas`,
	'pt-pt': `Vacinas não especificadas`,
	ru: `Неуточненные прививки`,
	sm: `Tui puipui e le'i faailoaina`,
	so: `Talaalo aan la cayimin`,
	th: `การฉีดวัคซีนที่ไม่ระบุ`,
	ur: `غیر واضح ویکسینیشن`,
	vi: `Tiêm chủng không xác định`,
	'zh-hans': `未指定的疫苗接种`,
	'zh-hant': `未指定的疫苗接種`,
	en: `Unspecified vaccinations`
};

export const loc_vaccine: Record<Locale, string> = {
	ar: `لقاح`,
	de: `Impfstoff`,
	es: `Vacuna`,
	fa: `واکسن`,
	fil: `Bakuna`,
	fr: `Vaccin`,
	hi: `टीका`,
	ht: `Vaksen`,
	it: `Vaccino`,
	ja: `ワクチン`,
	ko: `백신`,
	mww: `txhaj tshuaj tiv thaiv`,
	ps: `واکسين`,
	pt: `Vacina`,
	'pt-pt': `Vacina`,
	ru: `Вакцина`,
	sm: `Tui`,
	so: `Talaalka`,
	th: `วัคซีน`,
	ur: `ویکسین`,
	vi: `Vaccine`,
	'zh-hans': `疫苗`,
	'zh-hant': `疫苗`,
	en: `Vaccine`
};

export const loc_yourSessionExpired: Record<Locale, string> = {
	ar: `انتهت صلاحية جلستك. سجل الدخول لمتابعة جلستك.`,
	de: `Ihre Sitzung ist abgelaufen. Melden Sie sich an, um Ihre Sitzung fortzusetzen.`,
	es: `Su sesión ha caducado. Inicie sesión para continuar la sesión.`,
	fa: `جلسه شما منقضی شده است. برای ادامه جلسه به سیستم وارد شوید.`,
	fil: `Nag expire ang session mo. Mag sign in para ipagpatuloy ang iyong session.`,
	fr: `Votre session a expiré. Connectez-vous pour poursuivre votre session.`,
	hi: `आपका सत्र समाप्त हो गया। अपना सत्र जारी रखने के लिए साइन इन करें।`,
	ht: `Sesyon ou ekspirasyon. Siyen pou kontinye sesyon ou an.`,
	it: `La sessione è scaduta. Accedi per continuare la sessione.`,
	ja: `セッションの有効期限が切れました。サインインしてセッションを続行します。`,
	ko: `세션이 만료되었습니다. 세션을 계속하려면 로그인하십시오.`,
	mww: `Koj qhib qhov kev sib kho. Kos npe rau qhov chaw qhib kev sib kho kom haum.`,
	ps: `ستاسو غونډه پای ته ورسیده. د خپلې غونډې د دوام لپاره لاسلیک وکړئ.`,
	pt: `Sua sessão expirou. Faça login para continuar sua sessão.`,
	'pt-pt': `A sua sessão expirou. Inicie sessão para continuar a sua sessão.`,
	ru: `Срок действия сеанса истек. Войдите в систему, чтобы продолжить сеанс.`,
	sm: `Ua uma le aoga o lau sauniga. Saini e alu i totonu e faaauau ai lau sauniga.`,
	so: `Kulankaaguna waa uu dhacay. Gali si aad u sii socoto fadhigaaga.`,
	th: `เซสชันของคุณหมดอายุ ลงชื่อเข้าใช้เพื่อดําเนินการเซสชันต่อ`,
	ur: `آپ کا سیشن ختم ہو گیا۔ اپنا سیشن جاری رکھنے کے لئے سائن ان کریں۔`,
	vi: `Phiên của bạn đã hết hạn. Đăng nhập để tiếp tục phiên của bạn.`,
	'zh-hans': `您的会话已过期。登录以继续您的会话。`,
	'zh-hant': `您的工作階段已過期。登錄以繼續您的會話。`,
	en: `Your session expired. Sign in to continue your session.`
};

export const loc_sessionExpired: Record<Locale, string> = {
	ar: `انتهت صلاحية الجلسة`,
	de: `Sitzung abgelaufen`,
	es: `Sesión caducada`,
	fa: `جلسه منقضی شده است`,
	fil: `Nag-expire na ang sesyon`,
	fr: `Session expirée`,
	hi: `सत्र समाप्त हो गया`,
	ht: `Sesyon ekspirasyon`,
	it: `Sessione scaduta`,
	ja: `セッションの有効期限が切れました`,
	ko: `세션이 만료되었습니다.`,
	mww: `Qhib expired`,
	ps: `ناستې تېر مهاله`,
	pt: `Sessão expirada`,
	'pt-pt': `Sessão expirada`,
	ru: `Сеанс истек`,
	sm: `Ua uma le aoga o le sauniga`,
	so: `Fadhigii oo dhacay`,
	th: `เซสชันหมดอายุ`,
	ur: `اجلاس ختم ہو گیا`,
	vi: `Phiên đã hết hạn`,
	'zh-hans': `会话已过期`,
	'zh-hant': `會話已過期`,
	en: `Session expired`
};

export const loc_viewAll: Record<Locale, string> = {
	ar: `عرض الكل`,
	de: `Alle anzeigen`,
	es: `Ver todo`,
	fa: `مشاهده همه`,
	fil: `Tingnan ang lahat`,
	fr: `Voir tout`,
	hi: `सभी देखें`,
	ht: `Gade tout`,
	it: `Vedi tutto`,
	ja: `すべて表示`,
	ko: `전체보기`,
	mww: `Saib tag nrho`,
	ps: `ټول وګورئ`,
	pt: `Ver tudo`,
	'pt-pt': `Ver tudo`,
	ru: `Посмотреть все`,
	sm: `Maimoa i mea uma`,
	so: `View alle`,
	th: `ดูทั้งหมด`,
	ur: `سب دیکھیں`,
	vi: `Xem tất cả`,
	'zh-hans': `查看全部`,
	'zh-hant': `查看全部`,
	en: `View all`
};

export const loc_person: Record<Locale, string> = {
	ar: `شخص`,
	de: `Person`,
	es: `Persona`,
	fa: `فرد`,
	fil: `Tao na tao`,
	fr: `Personne`,
	hi: `व्यक्ति`,
	ht: `Moun`,
	it: `Persona`,
	ja: `人`,
	ko: `사람`,
	mww: `Neeg`,
	ps: `وګړی`,
	pt: `Pessoa`,
	'pt-pt': `Pessoa`,
	ru: `Человек`,
	sm: `Tagata`,
	so: `Qofka`,
	th: `คน`,
	ur: `شخص`,
	vi: `Người`,
	'zh-hans': `人`,
	'zh-hant': `人`,
	en: `Person`
};

export const loc_createANewPerson: Record<Locale, string> = {
	ar: `إنشاء عميل / مريض / عضو جديد`,
	de: `Erstellen eines neuen Kunden / Patienten / Mitglieds`,
	es: `Crear un nuevo cliente / paciente / miembro`,
	fa: `یک مشتری / بیمار / عضو جدید ایجاد کنید`,
	fil: `Lumikha ng isang bagong kliyente / pasyente / miyembro`,
	fr: `Créer un nouveau client / patient / membre`,
	hi: `एक नया ग्राहक / रोगी / सदस्य बनाएं`,
	ht: `Kreye yon nouvo kliyan / pasyan / manm`,
	it: `Crea un nuovo cliente/paziente/membro`,
	ja: `新しいクライアント/患者/メンバーを作成する`,
	ko: `새 클라이언트/환자/구성원 만들기`,
	mww: `Tsim ib tug neeg muaj mob / tus neeg mob / tus neeg mob`,
	ps: `یو نوی مشتری / ناروغ / غړی جوړول`,
	pt: `Criar um novo cliente / paciente / membro`,
	'pt-pt': `Criar um novo cliente / paciente / membro`,
	ru: `Создание нового клиента / пациента / участника`,
	sm: `Fatu se tagata fou / gasegase / tagata`,
	so: `Abuuri macmiil cusub / bukaan / xubin`,
	th: `สร้างลูกค้าใหม่ / ผู้ป่วย / สมาชิก`,
	ur: `ایک نیا کلائنٹ / مریض / ممبر بنائیں`,
	vi: `Tạo khách hàng / bệnh nhân / thành viên mới`,
	'zh-hans': `创建新客户 / 患者 / 会员`,
	'zh-hant': `創建新客戶 / 患者 / 會員`,
	en: `Create a new client / patient / member`
};

export const loc_filterEllipses: Record<Locale, string> = {
	ar: `راووق...`,
	de: `Filter...`,
	es: `Filtro...`,
	fa: `فیلتر...`,
	fil: `Filter...`,
	fr: `Filtre...`,
	hi: `छानना।।।`,
	ht: `Filtè ...`,
	it: `Filtro...`,
	ja: `フィルター。。。`,
	ko: `필터...`,
	mww: `Lim...`,
	ps: `...چاڼ`,
	pt: `Filtro...`,
	'pt-pt': `Filtrar...`,
	ru: `Фильтр...`,
	sm: `Faamama...`,
	so: `Filter...`,
	th: `กรอง   `,
	ur: `فلٹر...`,
	vi: `Bộ lọc...`,
	'zh-hans': `滤波器。。。`,
	'zh-hant': `濾波器。。。`,
	en: `Filter...`
};

export const loc_filterActivities: Record<Locale, string> = {
	ar: `تصفية الأنشطة`,
	de: `Filtern von Aktivitäten`,
	es: `Filtrar actividades`,
	fa: `فعالیت های فیلتر`,
	fil: `Mga aktibidad sa filter`,
	fr: `Filtrer les activités`,
	hi: `गतिविधियों को फ़िल्टर करें`,
	ht: `Aktivite filtre`,
	it: `Filtrare le attività`,
	ja: `アクティビティのフィルタリング`,
	ko: `활동 필터링`,
	mww: `Lim dej ua si`,
	ps: `د فلټر فعالیتونه`,
	pt: `Filtrar atividades`,
	'pt-pt': `Filtrar atividades`,
	ru: `Фильтрация действий`,
	sm: `Gaoioiga faamama`,
	so: `Shaqooyin filter`,
	th: `กรองกิจกรรม`,
	ur: `فلٹر کی سرگرمیاں`,
	vi: `Lọc hoạt động`,
	'zh-hans': `Filter 活动`,
	'zh-hant': `Filter 活動`,
	en: `Filter activities`
};

export const loc_newActivityPersonSearchFormat: Record<Locale, (activityTitle: unknown) => TemplateResult> = {
	ar: (activityTitle) => html`<strong>${activityTitle} جديدة</strong>: البحث عن شخص`,
	de: (activityTitle) => html`<strong>Neue ${activityTitle}</strong>: Suche nach einer Person`,
	es: (activityTitle) => html`<strong>Nueva ${activityTitle}</strong>: búsqueda de una persona`,
	fa: (activityTitle) => html`<strong>${activityTitle} جدید</strong>: یک شخص را جستجو کنید`,
	fil: (activityTitle) => html`<strong>Bagong ${activityTitle}</strong>: maghanap para sa isang tao`,
	fr: (activityTitle) => html`<strong>Nouvelle ${activityTitle}</strong> : recherche d’une personne`,
	hi: (activityTitle) => html`<strong>नया ${activityTitle}</strong>: किसी व्यक्ति की खोज करें`,
	ht: (activityTitle) => html`<strong>Nouvo ${activityTitle}</strong>: rechèch pou yon moun`,
	it: (activityTitle) => html`<strong>Nuovo ${activityTitle}</strong>: cerca una persona`,
	ja: (activityTitle) => html`<strong>新規 ${activityTitle}</strong>:人を探す`,
	ko: (activityTitle) => html`<strong>새 ${activityTitle}</strong>: 사람 검색`,
	mww: (activityTitle) => html`<strong>${activityTitle}</strong> tshiab: nrhiav ib tug neeg`,
	ps: (activityTitle) => html`نوی <strong>${activityTitle}</strong>: د یو کس لټون`,
	pt: (activityTitle) => html`<strong>Nova ${activityTitle}</strong>: procurar uma pessoa`,
	'pt-pt': (activityTitle) => html`<strong>Nova ${activityTitle}</strong>: procurar uma pessoa`,
	ru: (activityTitle) => html`<strong>Новый ${activityTitle}</strong>: поиск человека`,
	sm: (activityTitle) => html`Faailoa <strong>fou${activityTitle}</strong>: saili mo se tagata`,
	so: (activityTitle) => html`<strong>${activityTitle} cusub</strong>: Raadinta qofka`,
	th: (activityTitle) => html`<strong>${activityTitle}ใหม่</strong>: ค้นหาบุคคล`,
	ur: (activityTitle) => html`<strong>نیا ${activityTitle}</strong>: کسی شخص کی تلاش`,
	vi: (activityTitle) => html`<strong>${activityTitle} mới</strong>: tìm kiếm một người`,
	'zh-hans': (activityTitle) => html`<strong>新 ${activityTitle}</strong>人：搜索人员`,
	'zh-hant': (activityTitle) => html`<strong>新 ${activityTitle}</strong>人：搜索人員`,
	en: (activityTitle) => html`<strong>New ${activityTitle}</strong>: search for a person`
};

export const loc_newActivityPersonCreateFormat: Record<Locale, (activityTitle: unknown) => TemplateResult> = {
	ar: (activityTitle) => html`<strong>${activityTitle} جديدة</strong>: إنشاء شخص جديد`,
	de: (activityTitle) => html`<strong>Neue ${activityTitle}</strong>: Erstellen Sie eine neue Person`,
	es: (activityTitle) => html`<strong>Nueva ${activityTitle}</strong>: crear una nueva persona`,
	fa: (activityTitle) => html`<strong>${activityTitle} جدید</strong>: یک شخص جدید ایجاد کنید`,
	fil: (activityTitle) => html`<strong>Bagong ${activityTitle}</strong>: lumikha ng isang bagong tao`,
	fr: (activityTitle) => html`<strong>Nouvelle ${activityTitle}</strong> : créer une nouvelle personne`,
	hi: (activityTitle) => html`<strong>नया ${activityTitle}</strong>: एक नया व्यक्ति बनाएं`,
	ht: (activityTitle) => html`<strong>Nouvo ${activityTitle}</strong>: kreye yon nouvo moun`,
	it: (activityTitle) => html`<strong>Nuovo ${activityTitle}</strong>: crea una nuova persona`,
	ja: (activityTitle) => html`<strong>新しい ${activityTitle}</strong>:新しい人を作成します`,
	ko: (activityTitle) => html`<strong>새 ${activityTitle}</strong>: 새 사람 만들기`,
	mww: (activityTitle) => html`<strong>${activityTitle}</strong> tsim ib tug neeg tshiab`,
	ps: (activityTitle) => html`نوی <strong>${activityTitle}</strong>: نوی شخص رامینځته کړئ`,
	pt: (activityTitle) => html`<strong>Nova ${activityTitle}</strong>: criar uma nova pessoa`,
	'pt-pt': (activityTitle) => html`<strong>Nova ${activityTitle}</strong>: crie uma nova pessoa`,
	ru: (activityTitle) => html`<strong>Новый ${activityTitle}</strong>: создать нового человека`,
	sm: (activityTitle) => html`Faailoa <strong>fou${activityTitle}</strong>: fatu se tagata fou`,
	so: (activityTitle) => html`<strong>${activityTitle} cusub</strong>: In la abuuro qof cusub`,
	th: (activityTitle) => html`<strong>${activityTitle}ใหม่</strong>: สร้างบุคคลใหม่`,
	ur: (activityTitle) => html`<strong>نیا ${activityTitle}</strong>: ایک نیا شخص بنائیں`,
	vi: (activityTitle) => html`<strong>${activityTitle} mới</strong>: tạo một người mới`,
	'zh-hans': (activityTitle) => html`<strong>新 ${activityTitle}</strong>人：创建新人员`,
	'zh-hant': (activityTitle) => html`<strong>新 ${activityTitle}</strong>人：創建新人員`,
	en: (activityTitle) => html`<strong>New ${activityTitle}</strong>: create a new person`
};

export const loc_newActivitySelectParentActivityFormat: Record<Locale, (activityTitle: unknown) => TemplateResult> = {
	ar: (activityTitle) => html`<strong>${activityTitle} جديدة</strong>: حدد النشاط الذي سترتبط به ${activityTitle} الجديدة`,
	de: (activityTitle) => html`<strong>Neue ${activityTitle}</strong>: Wählen Sie die Aktivität aus, mit der die neue ${activityTitle} verknüpft werden soll`,
	es: (activityTitle) => html`<strong>Nueva ${activityTitle}</strong>: seleccione la actividad a la que se asociará la nueva ${activityTitle}`,
	fa: (activityTitle) => html`<strong>${activityTitle} جدید</strong>: فعالیتی را که ${activityTitle} جدید با آن مرتبط است انتخاب کنید`,
	fil: (activityTitle) => html`<strong>Bagong ${activityTitle}</strong>: piliin ang aktibidad na maiuugnay sa bagong ${activityTitle}`,
	fr: (activityTitle) => html`<strong>Nouveau ${activityTitle}</strong> : sélectionnez l’activité à laquelle le nouveau ${activityTitle} sera associé`,
	hi: (activityTitle) => html`<strong>नया ${activityTitle}</strong>: उस गतिविधि का चयन करें जिससे नया ${activityTitle} संबद्ध होगा`,
	ht: (activityTitle) => html`<strong>Nouvo ${activityTitle}</strong>: chwazi aktivite a nouvo ${activityTitle} lan pral asosye ak`,
	it: (activityTitle) => html`<strong>Nuovo ${activityTitle}</strong>: seleziona l'attività a cui sarà associato il nuovo ${activityTitle}`,
	ja: (activityTitle) => html`<strong>新しい ${activityTitle}</strong>: 新しい ${activityTitle} が関連付けられるアクティビティを選択します`,
	ko: (activityTitle) => html`<strong>새 ${activityTitle}</strong>: 새 ${activityTitle} 과 연결될 활동을 선택합니다.`,
	mww: (activityTitle) => html`<strong>${activityTitle}</strong> xaiv qhov kev ${activityTitle} yuav raug mob nrog`,
	ps: (activityTitle) => html`<strong>نوی ${activityTitle}</strong>: هغه فعالیت غوره کړئ چې نوی ${activityTitle} ورسره تړاو ولری`,
	pt: (activityTitle) => html`<strong>Nova ${activityTitle}</strong>: selecione a atividade à qual a nova ${activityTitle} será associada`,
	'pt-pt': (activityTitle) => html`<strong>Nova ${activityTitle}</strong>: selecione a atividade à qual a nova ${activityTitle} será associada`,
	ru: (activityTitle) => html`<strong>Новый ${activityTitle}</strong>: выберите активность, с которой будет связан новый ${activityTitle}`,
	sm: (activityTitle) => html`Faailoa <strong>fou${activityTitle}</strong>: filifili le gaoioiga o le a fesootai ai le ${activityTitle} fou`,
	so: (activityTitle) => html`<strong>${activityTitle} cusub</strong>: dooro dhaqdhaqaaqa ${activityTitle} cusub ee lala xiriirin doono`,
	th: (activityTitle) => html`<strong>${activityTitle}ใหม่</strong>: เลือกกิจกรรมที่จะเชื่อมโยงกับ${activityTitle}ใหม่`,
	ur: (activityTitle) => html`<strong>نیا ${activityTitle}</strong>: اس سرگرمی کا انتخاب کریں جس سے نیا ${activityTitle} وابستہ ہوگا`,
	vi: (activityTitle) => html`<strong>${activityTitle} mới</strong>: chọn hoạt động mà ${activityTitle} mới sẽ được liên kết`,
	'zh-hans': (activityTitle) => html`<strong>New ${activityTitle}</strong>：选择新 ${activityTitle} 将与之关联的活动`,
	'zh-hant': (activityTitle) => html`<strong>New ${activityTitle}</strong>：選擇新 ${activityTitle} 將與之關聯的活動`,
	en: (activityTitle) => html`<strong>New ${activityTitle}</strong>: select the activity the new ${activityTitle} will be associated with`
};

export const loc_refer: Record<Locale, string> = {
	ar: `تشير`,
	de: `Verweisen`,
	es: `Recomienda`,
	fa: `مراجعه کنید`,
	fil: `Mag-refer`,
	fr: `Parrainer`,
	hi: `सौंपना`,
	ht: `Refere`,
	it: `Segnalare`,
	ja: `参照`,
	ko: `언급하다`,
	mww: `Xa mus rau`,
	ps: `مراجعه وکړئ`,
	pt: `Referir`,
	'pt-pt': `Consultar`,
	ru: `Отсылать`,
	sm: `Tagai`,
	so: `Tixraac`,
	th: `อ้างถึง`,
	ur: `حوالہ دیں`,
	vi: `Tham khảo`,
	'zh-hans': `指`,
	'zh-hant': `指`,
	en: `Refer`
};

export const loc_createANewReferral: Record<Locale, string> = {
	ar: `إنشاء إحالة جديدة`,
	de: `Erstellen einer neuen Empfehlung`,
	es: `Crear una nueva referencia`,
	fa: `یک ارجاع جدید ایجاد کنید`,
	fil: `Gumawa ng bagong referral`,
	fr: `Créer une nouvelle référence`,
	hi: `एक नया रेफ़रल बनाएँ`,
	ht: `Kreye yon nouvo rekòmandasyon`,
	it: `Crea un nuovo referral`,
	ja: `新しい紹介を作成する`,
	ko: `새 추천 만들기`,
	mww: `Tsim ib tug xa mus cuag tshiab`,
	ps: `نوې حواله جوړه کړئ`,
	pt: `Criar uma nova indicação`,
	'pt-pt': `Criar uma nova referência`,
	ru: `Создайте нового реферала`,
	sm: `Fatu se tagata fou e faasino atu i ai`,
	so: `Loo abuuro gudbin cusub`,
	th: `สร้างการอ้างอิงใหม่`,
	ur: `ایک نیا حوالہ بنائیں`,
	vi: `Tạo giới thiệu mới`,
	'zh-hans': `创建新的引荐`,
	'zh-hant': `創建新的引薦`,
	en: `Create a new referral`
};

export const loc_upload: Record<Locale, string> = {
	ar: `رفع`,
	de: `Hochladen`,
	es: `Subir`,
	fa: `آپلود`,
	fil: `Mag-upload`,
	fr: `Télécharger`,
	hi: `अपलोड`,
	ht: `Upload`,
	it: `Caricare`,
	ja: `アップロード`,
	ko: `업로드`,
	mww: `upload`,
	ps: `ورلېښل`,
	pt: `Carregar`,
	'pt-pt': `Carregar`,
	ru: `Закачать`,
	sm: `Sii mai`,
	so: `Hore`,
	th: `อัปโหลด`,
	ur: `اپ لوڈ`,
	vi: `Tải lên`,
	'zh-hans': `上传`,
	'zh-hant': `上傳`,
	en: `Upload`
};

export const loc_chooseFile: Record<Locale, string> = {
	ar: `اختر الملف`,
	de: `Datei auswählen`,
	es: `Elegir archivo`,
	fa: `فایل را انتخاب کنید`,
	fil: `Pumili ng file`,
	fr: `Choisissez un fichier`,
	hi: `फ़ाइल चुनें`,
	ht: `Chwazi dosye`,
	it: `Scegli il file`,
	ja: `ファイルを選択`,
	ko: `파일 선택`,
	mww: `Xaiv cov ntaub ntawv xaiv`,
	ps: `دوتنه وچوڼئ`,
	pt: `Escolher arquivo`,
	'pt-pt': `Escolher ficheiro`,
	ru: `Выберите файл`,
	sm: `Filifili le faila`,
	so: `Dooro file`,
	th: `เลือกไฟล์`,
	ur: `فائل منتخب کریں`,
	vi: `Chọn tệp`,
	'zh-hans': `选择文件`,
	'zh-hant': `選擇檔案`,
	en: `Choose file`
};

export const loc_oneFile: Record<Locale, string> = {
	ar: `1 ملف`,
	de: `1 Datei`,
	es: `1 fichero`,
	fa: `1 فایل`,
	fil: `1 file`,
	fr: `1 fichier`,
	hi: `1 फ़ाइल`,
	ht: `1 dosye`,
	it: `1 fascicolo`,
	ja: `1ファイル`,
	ko: `1 파일`,
	mww: `1 ntaub ntawv`,
	ps: `1 دوتنه`,
	pt: `1 arquivo`,
	'pt-pt': `1 ficheiro`,
	ru: `1 файл`,
	sm: `1 faila`,
	so: `1 loox file`,
	th: `1 ไฟล์`,
	ur: `1 فائل`,
	vi: `1 tệp`,
	'zh-hans': `1 个文件`,
	'zh-hant': `1 個檔`,
	en: `1 file`
};

export const loc_multipleFilesFormat: Record<Locale, (count: unknown) => string> = {
	ar: (count) => `${count} ملفات`,
	de: (count) => `${count} Dateien`,
	es: (count) => `${count} archivos`,
	fa: (count) => `${count} فایل`,
	fil: (count) => `${count} mga file`,
	fr: (count) => `${count} fichiers`,
	hi: (count) => `${count} फ़ाइलें`,
	ht: (count) => `${count} dosye`,
	it: (count) => `${count} file`,
	ja: (count) => `${count} 個のファイル`,
	ko: (count) => `${count} 파일`,
	mww: (count) => `${count} cov ntaub ntawv`,
	ps: (count) => `${count} دوتنې`,
	pt: (count) => `${count} arquivos`,
	'pt-pt': (count) => `${count} ficheiros`,
	ru: (count) => `${count} файла`,
	sm: (count) => `${count} faila`,
	so: (count) => `${count} files`,
	th: (count) => `${count} ไฟล์`,
	ur: (count) => `${count} فائلیں`,
	vi: (count) => `${count} tập tin`,
	'zh-hans': (count) => `${count} 个文件`,
	'zh-hant': (count) => `${count} 個檔`,
	en: (count) => `${count} files`
};

export const loc_sign: Record<Locale, string> = {
	ar: `وقع`,
	de: `Zeichen`,
	es: `Firmar`,
	fa: `نشانه`,
	fil: `Mag-sign`,
	fr: `Signe`,
	hi: `संकेत`,
	ht: `Siyen`,
	it: `Segno`,
	ja: `看板`,
	ko: `기호`,
	mww: `Kos`,
	ps: `نښه`,
	pt: `Sinal`,
	'pt-pt': `Assinar`,
	ru: `Знак`,
	sm: `Saini`,
	so: `Saxiix`,
	th: `สัญลักษณ์`,
	ur: `علامت`,
	vi: `Dấu`,
	'zh-hans': `标志`,
	'zh-hant': `標誌`,
	en: `Sign`
};

export const loc_acceptSignature: Record<Locale, string> = {
	ar: `قبول التوقيع`,
	de: `Unterschrift akzeptieren`,
	es: `Aceptar firma`,
	fa: `امضا را بپذیرید`,
	fil: `Tanggapin ang lagda`,
	fr: `Accepter la signature`,
	hi: `हस्ताक्षर स्वीकार करें`,
	ht: `Siyati aksepte`,
	it: `Accetta firma`,
	ja: `署名の受理`,
	ko: `서명 수락`,
	mww: `Kos npe`,
	ps: `لاسلیک منل`,
	pt: `Aceitar assinatura`,
	'pt-pt': `Aceitar assinatura`,
	ru: `Принять подпись`,
	sm: `Talia le saini`,
	so: `Aqbasho saxiixa`,
	th: `ยอมรับลายเซ็น`,
	ur: `دستخط قبول کریں`,
	vi: `Chấp nhận chữ ký`,
	'zh-hans': `接受签名`,
	'zh-hant': `接受簽名`,
	en: `Accept signature`
};

export const loc_undo: Record<Locale, string> = {
	ar: `تراجع`,
	de: `Aufmachen`,
	es: `Deshacer`,
	fa: `خنثیسازی`,
	fil: `Mag-undo`,
	fr: `Défaire`,
	hi: `खोलना`,
	ht: `Defèt`,
	it: `Disfare`,
	ja: `外す`,
	ko: `취소`,
	mww: `undo`,
	ps: `ناکړ`,
	pt: `Desfazer`,
	'pt-pt': `Desfazer`,
	ru: `Отменить`,
	sm: `Toe Tatala`,
	so: `Undo`,
	th: `แก้`,
	ur: `کالعدم`,
	vi: `Hoàn tác`,
	'zh-hans': `撤消`,
	'zh-hant': `恢復`,
	en: `Undo`
};

export const loc_consentToDoBusinessElectronically: Record<Locale, string> = {
	ar: `بقبول هذا التوقيع ، فإنك توافق على أنه يمكن توقيع هذا النشاط إلكترونيا وأن التوقيعات الإلكترونية التي تظهر على هذا النشاط هي نفس التوقيعات المكتوبة بخط اليد لأغراض الصلاحية وقابلية التنفيذ والمقبولية.`,
	de: `Indem Sie diese Signatur akzeptieren, erklären Sie sich damit einverstanden, dass diese Aktivität elektronisch signiert werden kann und dass die elektronischen Signaturen, die auf dieser Aktivität erscheinen, zum Zwecke der Gültigkeit, Durchsetzbarkeit und Zulässigkeit mit handschriftlichen Unterschriften identisch sind.`,
	es: `Al aceptar esta firma, usted acepta que esta actividad puede estar firmada electrónicamente y que las firmas electrónicas que aparecen en esta actividad son las mismas que las firmas manuscritas a efectos de validez, aplicabilidad y admisibilidad.`,
	fa: `با پذیرش این امضا، شما موافقت می کنید که این فعالیت ممکن است به صورت الکترونیکی امضا شود و امضاهای الکترونیکی ظاهر شده در این فعالیت مانند امضاهای دست نویس به منظور اعتبار، قابلیت اجرا و قابل قبول بودن هستند.`,
	fil: `Sa pagtanggap ng lagda na ito sumasang-ayon ka na ang aktibidad na ito ay maaaring lagdaan sa elektronikong paraan at ang mga elektronikong lagda na makikita sa aktibidad na ito ay katulad ng mga lagda na nakasulat sa kamay para sa mga layunin ng bisa, kakayahang magpatupad, at pagkatanggap.`,
	fr: `En acceptant cette signature, vous acceptez que cette activité puisse être signée électroniquement et que les signatures électroniques apparaissant sur cette activité soient les mêmes que les signatures manuscrites aux fins de validité, d’applicabilité et d’admissibilité.`,
	hi: `इस हस्ताक्षर को स्वीकार करके आप सहमत हैं कि यह गतिविधि इलेक्ट्रॉनिक रूप से हस्ताक्षरित हो सकती है और इस गतिविधि पर प्रदर्शित होने वाले इलेक्ट्रॉनिक हस्ताक्षर वैधता, प्रवर्तनीयता और स्वीकार्यता के उद्देश्यों के लिए हस्तलिखित हस्ताक्षर के समान हैं।`,
	ht: `Pa aksepte siyati sa a ou dakò ke aktivite sa a ka elektwonikman siyen e ke siyati elektwonik yo parèt sou aktivite sa a se menm bagay la kòm siyati ekri alamen pou rezon validite, ranfòse, ak admisibilite.`,
	it: `Accettando questa firma, l'utente accetta che questa attività possa essere firmata elettronicamente e che le firme elettroniche che appaiono su questa attività siano le stesse delle firme autografe ai fini della validità, dell'esecutività e dell'ammissibilità.`,
	ja: `この署名を受理することにより、この活動が電子署名されること、およびこの活動に表示される電子署名が有効性、執行可能性、および許容性の目的で手書きの署名と同じであることに同意するものとします。`,
	ko: `이 서명을 수락하면 이 활동에 전자 서명이 될 수 있으며 이 활동에 표시되는 전자 서명이 유효성, 집행 가능성 및 허용 가능성을 위해 자필 서명과 동일하다는 데 동의하는 것입니다.`,
	mww: `Yog txais qhov kos npe no koj pom zoo tias qhov kev ua no tej zaum yuav tau electronically kos npe thiab tias cov kos npe kos npe hnyuj hnyo rau qhov kev ua no yog tib yam li handwritten kos npe rau lub hom phiaj ntawm validity, enforceability, thiab admissibility.`,
	ps: `د دې لاسلیک په منلو سره تاسو موافق یاست چې دا فعالیت ممکن په بریښنایی ډول لاسلیک شی او دا چې پدې فعالیت کې څرګند شوی بریښنایی لاسلیکونه د اعتبار ، پلی کیدو ، او منلو وړ اهدافو لپاره د لاس لیکل شوی لاسلیکونو سره ورته دی.`,
	pt: `Ao aceitar esta assinatura, você concorda que esta atividade pode ser assinada eletronicamente e que as assinaturas eletrônicas que aparecem nesta atividade são as mesmas que as assinaturas manuscritas para fins de validade, aplicabilidade e admissibilidade.`,
	'pt-pt': `Ao aceitar esta assinatura, você concorda que esta atividade pode ser assinada eletronicamente e que as assinaturas eletrônicas que aparecem nesta atividade são as mesmas que as assinaturas manuscritas para fins de validade, executoriedade e admissibilidade.`,
	ru: `Принимая эту подпись, вы соглашаетесь с тем, что это действие может быть подписано электронным способом и что электронные подписи, отображаемые на этом мероприятии, являются такими же, как собственноручные подписи в целях действительности, исполнимости и допустимости.`,
	sm: `O le taliaina o lenei saini ua e ioe ai e mafai ona sainia faaeletonika lenei gaoioiga ma o saini faaeletonika o loo faaali mai i luga o lenei gaoioiga e tutusa lava ma saini tusilima mo faamoemoega o le aoga, faamalosia, ma le taliaina.`,
	so: `Markaad aqbasho saxiixan waxaad ogolaataa in howshan laga yaabo in si elektaroonik ah loo saxiixo iyo in saxiixyada elektaroonigga ah ee ka soo muuqanaya howshan ay la mid yihiin saxiixyada farta lagu qoray oo loogu talo galay ujeeddooyinka ansaxnimada, fulinta, iyo is-aqbalka.`,
	th: `การยอมรับลายเซ็นนี้แสดงว่าคุณยอมรับว่ากิจกรรมนี้อาจได้รับการลงนามทางอิเล็กทรอนิกส์ และลายเซ็นอิเล็กทรอนิกส์ที่ปรากฏในกิจกรรมนี้เหมือนกับลายเซ็นที่เขียนด้วยลายมือเพื่อวัตถุประสงค์ของความถูกต้อง`,
	ur: `اس دستخط کو قبول کرتے ہوئے آپ اس بات سے اتفاق کرتے ہیں کہ اس سرگرمی پر الیکٹرانک طور پر دستخط کیے جاسکتے ہیں اور اس سرگرمی پر ظاہر ہونے والے الیکٹرانک دستخط وہی ہیں جو درستگی ، نفاذ اور قبولیت کے مقاصد کے لئے ہاتھ سے لکھے گئے دستخط ہیں۔`,
	vi: `Bằng cách chấp nhận chữ ký này, bạn đồng ý rằng hoạt động này có thể được ký điện tử và chữ ký điện tử xuất hiện trên hoạt động này giống như chữ ký viết tay cho các mục đích hợp lệ, thực thi và chấp nhận.`,
	'zh-hans': `接受此签名，即表示您同意此活动可以进行电子签名，并且此活动上显示的电子签名在有效性、可执行性和可接受性方面与手写签名相同。`,
	'zh-hant': `接受此簽名，即表示您同意此活動可以進行電子簽名，並且此活動上顯示的電子簽名在有效性、可執行性和可接受性方面與手寫簽名相同。`,
	en: `By accepting this signature you agree that this activity may be electronically signed and that the electronic signatures appearing on this activity are the same as handwritten signatures for the purposes of validity, enforceability, and admissibility.`
};

export const loc_signature: Record<Locale, string> = {
	ar: `توقيع`,
	de: `Unterschrift`,
	es: `Firma`,
	fa: `امضا`,
	fil: `Lagda`,
	fr: `Signature`,
	hi: `दस्‍तख़त देखिए।`,
	ht: `Siyati`,
	it: `Firma`,
	ja: `署名`,
	ko: `서명`,
	mww: `kos npe`,
	ps: `لاسلیک`,
	pt: `Assinatura`,
	'pt-pt': `Assinatura`,
	ru: `Подпись`,
	sm: `Saini`,
	so: `saxiixa`,
	th: `ลายเซ็น`,
	ur: `دستخط`,
	vi: `Chữ ký`,
	'zh-hans': `签名`,
	'zh-hant': `簽名`,
	en: `Signature`
};

export const loc_name: Record<Locale, string> = {
	ar: `اسم`,
	de: `Name`,
	es: `Nombre`,
	fa: `نام`,
	fil: `Pangalan`,
	fr: `Nom`,
	hi: `नाम`,
	ht: `Fe-apel`,
	it: `Nome`,
	ja: `名前`,
	ko: `이름`,
	mww: `Npe`,
	ps: `نوم`,
	pt: `Nome`,
	'pt-pt': `Designação`,
	ru: `Имя`,
	sm: `Igoa`,
	so: `magaca`,
	th: `ชื่อ`,
	ur: `نام`,
	vi: `Tên`,
	'zh-hans': `名字`,
	'zh-hant': `名字`,
	en: `Name`
};

export const loc_deleteSignature: Record<Locale, string> = {
	ar: `حذف التوقيع`,
	de: `Signatur löschen`,
	es: `Eliminar firma`,
	fa: `حذف امضا`,
	fil: `Tanggalin ang lagda`,
	fr: `Supprimer la signature`,
	hi: `हस्ताक्षर हटाएं`,
	ht: `Efase siyati`,
	it: `Elimina firma`,
	ja: `署名の削除`,
	ko: `서명 삭제`,
	mww: `Delete kos npe`,
	ps: `لاسلیک ړنګول`,
	pt: `Excluir assinatura`,
	'pt-pt': `Excluir assinatura`,
	ru: `Удалить подпись`,
	sm: `Tapeese le saini`,
	so: `Saxeexa Delete`,
	th: `ลบลายเซ็น`,
	ur: `دستخط حذف کریں`,
	vi: `Xóa chữ ký`,
	'zh-hans': `删除签名`,
	'zh-hant': `刪除簽名`,
	en: `Delete signature`
};

export const loc_confirmDeleteSignature: Record<Locale, string> = {
	ar: `هل أنت متأكد من أنك تريد حذف هذا التوقيع؟`,
	de: `Sind Sie sicher, dass Sie diese Signatur löschen möchten?`,
	es: `¿Está seguro de que desea eliminar esta firma?`,
	fa: `آیا مطمئن هستید که می خواهید این امضا را حذف کنید؟`,
	fil: `Sigurado ka bang gusto mong tanggalin ang lagdang ito?`,
	fr: `Êtes-vous sûr de vouloir supprimer cette signature ?`,
	hi: `क्या आप वाकई इस हस्ताक्षर को हटाना चाहते हैं?`,
	ht: `Èske ou sèten ou vle efase siyati sa a?`,
	it: `Sei sicuro di voler eliminare questa firma?`,
	ja: `この署名を削除してもよろしいですか?`,
	ko: `이 서명을 삭제하시겠습니까?`,
	mww: `Koj puas xav delete kos npe no?`,
	ps: `ایا تاسو ډاډه یاست چې تاسو غواړئ دا لاسلیک حذف کړئ؟`,
	pt: `Tem certeza de que deseja excluir esta assinatura?`,
	'pt-pt': `Tem a certeza de que pretende eliminar esta assinatura?`,
	ru: `Вы уверены, что хотите удалить эту подпись?`,
	sm: `E te mautinoa e te manao e tape le saini lenei?`,
	so: `Ma hubtaa in aad rabto in aad tirtirto saxiixan?`,
	th: `คุณแน่ใจหรือไม่ว่าต้องการลบลายเซ็นนี้?`,
	ur: `کیا آپ واقعی اس دستخط کو حذف کرنا چاہتے ہیں؟`,
	vi: `Bạn có chắc chắn muốn xóa chữ ký này không?`,
	'zh-hans': `是否确实要删除此签名？`,
	'zh-hant': `是否確實要刪除此簽名？`,
	en: `Are you sure you want to delete this signature?`
};

export const loc_oneSignature: Record<Locale, string> = {
	ar: `1 توقيع`,
	de: `1 Unterschrift`,
	es: `1 firma`,
	fa: `1 امضا`,
	fil: `1 lagda`,
	fr: `1 signature`,
	hi: `1 हस्ताक्षर`,
	ht: `1 siyati`,
	it: `1 firma`,
	ja: `1つの署名`,
	ko: `서명 1개`,
	mww: `kos npe`,
	ps: `1 لاسلیک`,
	pt: `1 assinatura`,
	'pt-pt': `1 assinatura`,
	ru: `1 подпись`,
	sm: `1 saini`,
	so: `1 saxiixa`,
	th: `1 ลายเซ็น`,
	ur: `1 دستخط`,
	vi: `1 chữ ký`,
	'zh-hans': `1 个签名`,
	'zh-hant': `1 個簽名`,
	en: `1 signature`
};

export const loc_multipleSignaturesFormat: Record<Locale, (count: unknown) => string> = {
	ar: (count) => `${count} توقيعات`,
	de: (count) => `${count} Unterschriften`,
	es: (count) => `${count} firmas`,
	fa: (count) => `${count} امضا`,
	fil: (count) => `${count} lagda`,
	fr: (count) => `${count} signatures`,
	hi: (count) => `${count} हस्ताक्षर`,
	ht: (count) => `${count} siyati`,
	it: (count) => `${count} firme`,
	ja: (count) => `${count} つの署名`,
	ko: (count) => `${count} 개`,
	mww: (count) => `${count} kos npe`,
	ps: (count) => `${count} لاسلیکونه`,
	pt: (count) => `${count} assinaturas`,
	'pt-pt': (count) => `${count} assinaturas`,
	ru: (count) => `${count} подписи`,
	sm: (count) => `${count} saini`,
	so: (count) => `${count}`,
	th: (count) => `${count} ลายเซ็น`,
	ur: (count) => `${count} دستخط`,
	vi: (count) => `${count} chữ ký`,
	'zh-hans': (count) => `${count} 个签名`,
	'zh-hant': (count) => `${count} 個簽名`,
	en: (count) => `${count} signatures`
};

export const loc_makeDefaultSignature: Record<Locale, string> = {
	ar: `اجعل هذا توقيعي الافتراضي`,
	de: `Dies als Standardsignatur festlegen`,
	es: `Hacer de esta mi firma predeterminada`,
	fa: `این امضای پیش فرض من را قرار دهید`,
	fil: `Gawin itong default na lagda ko`,
	fr: `Faites-en ma signature par défaut`,
	hi: `इसे मेरा डिफ़ॉल्ट हस्ताक्षर बनाओ`,
	ht: `Fè siyati default mwen`,
	it: `Imposta questa come firma predefinita`,
	ja: `これをデフォルトの署名にする`,
	ko: `이 서명을 기본 서명으로 설정`,
	mww: `Ua qhov no kuv default kos npe`,
	ps: `دا زما تلواله لاسلیک جوړ کړئ`,
	pt: `Tornar esta minha assinatura padrão`,
	'pt-pt': `Tornar esta a minha assinatura predefinida`,
	ru: `Сделайте эту подпись моей подписью по умолчанию`,
	sm: `Fai la'u saini Tiumasani lenei`,
	so: `Samee this saxiixa default`,
	th: `ทําให้ลายเซ็นเริ่มต้นของฉัน`,
	ur: `اسے میرا پہلے سے طے شدہ دستخط بنائیں`,
	vi: `Đặt chữ ký này làm chữ ký mặc định của tôi`,
	'zh-hans': `将此签名设为我的默认签名`,
	'zh-hant': `將此簽名設為我的預設簽名`,
	en: `Make this my default signature`
};

export const loc_style: Record<Locale, string> = {
	ar: `نمط`,
	de: `Stil`,
	es: `Estilo`,
	fa: `سبک`,
	fil: `Estilo`,
	fr: `Style`,
	hi: `वर्तिका`,
	ht: `Style`,
	it: `Stile`,
	ja: `スタイル`,
	ko: `스타일`,
	mww: `Style`,
	ps: `ډول`,
	pt: `Estilo`,
	'pt-pt': `Estilo`,
	ru: `Стиль`,
	sm: `Sitaili`,
	so: `Marka`,
	th: `ลีลา`,
	ur: `طرز`,
	vi: `Phong cách`,
	'zh-hans': `风格`,
	'zh-hant': `風格`,
	en: `Style`
};

export const loc_noDefaultSignature: Record<Locale, string> = {
	ar: `لا يوجد توقيع افتراضي`,
	de: `Keine Standardsignatur`,
	es: `Sin firma predeterminada`,
	fa: `بدون امضای پیش فرض`,
	fil: `Walang default na lagda`,
	fr: `Pas de signature par défaut`,
	hi: `कोई डिफ़ॉल्ट हस्ताक्षर नहीं`,
	ht: `Pa gen siyati default`,
	it: `Nessuna firma predefinita`,
	ja: `デフォルトの署名はありません`,
	ko: `기본 서명 없음`,
	mww: `Tsis muaj default kos npe`,
	ps: `تلواله لاسلیک نشته`,
	pt: `Sem assinatura padrão`,
	'pt-pt': `Sem assinatura padrão`,
	ru: `Нет подписи по умолчанию`,
	sm: `Leai se saini Tiumasani`,
	so: `No saxiixa default`,
	th: `ไม่มีลายเซ็นเริ่มต้น`,
	ur: `کوئی طے شدہ دستخط نہیں`,
	vi: `Không có chữ ký mặc định`,
	'zh-hans': `无默认签名`,
	'zh-hant': `無預設簽名`,
	en: `No default signature`
};

export const loc_noDefaultSignatureDescription: Record<Locale, TemplateResult> = {
	ar: html`ليس لديك توقيع افتراضي حتى الآن. استخدم زر التوقيع لتوقيع النشاط وحدد مربع <em>جعل هذا توقيعي الافتراضي</em> لحفظ التوقيع للاستخدام في المستقبل.`,
	de: html`Sie haben noch keine Standardsignatur. Verwenden Sie die Schaltfläche "Signieren", um die Aktivität zu signieren, und aktivieren Sie das Kontrollkästchen <em>"Diese als Standardsignatur festlegen</em> ", um die Signatur für die zukünftige Verwendung zu speichern.`,
	es: html`Todavía no tiene una firma predeterminada. Use el botón de firmar para firmar la actividad y marque la casilla <em>hacer que esta sea mi firma predeterminada</em> para guardar la firma para usarla en el futuro.`,
	fa: html`شما هنوز امضای پیش فرض ندارید. از دکمه امضا برای امضای فعالیت استفاده کنید و کادر <em>Make this my default signature</em> را علامت بزنید تا امضا برای استفاده در آینده ذخیره شود.`,
	fil: html`Wala ka pang default signature. Gamitin ang sign button para lagdaan ang aktibidad at suriin ang <em>make this my default signature</em> box para i-save ang lagda para magamit sa hinaharap.`,
	fr: html`Vous n’avez pas encore de signature par défaut. Utilisez le bouton Signer pour signer l’activité et cochez la case <em>Définir comme signature par défaut</em> pour enregistrer la signature pour une utilisation ultérieure.`,
	hi: html`आपके पास अभी तक कोई डिफ़ॉल्ट हस्ताक्षर नहीं है. गतिविधि पर हस्ताक्षर करने के लिए साइन बटन का उपयोग करें और भविष्य में उपयोग के लिए हस्ताक्षर सहेजने के लिए <em>इसे मेरा डिफ़ॉल्ट हस्ताक्षर बनाएं</em> बॉक्स चेक करें।`,
	ht: html`Ou pa gen yon siyati default ankò. Sèvi ak bouton an siyen aktivite a epi tcheke <em>fè sa a bwat siyati default mwen</em> pou konsève pou siyati a pou itilize nan lavni.`,
	it: html`Non hai ancora una firma predefinita. Usa il pulsante Firma per firmare l'attività e seleziona la casella <em>Imposta come firma predefinita</em> per salvare la firma per un uso futuro.`,
	ja: html`デフォルトの署名がまだありません。署名ボタンを使用してアクティビティに署名し、[ <em>これをデフォルトの署名にする</em> ]チェックボックスをオンにして、後で使用するために署名を保存します。`,
	ko: html`아직 기본 서명이 없습니다. 서명 버튼을 사용하여 활동에 서명하고 <em>이 서명을 기본 서명으로 만들기</em> 상자를 선택하여 나중에 사용할 수 있도록 서명을 저장합니다.`,
	mww: html`Koj tsis muaj ib tug default kos npe tsis tau. Siv lub pob khawm kos npe rau daim ntawv thiab kos npe <em>rau qhov no kuv default kos npe</em> rau lub thawv txuag lub kos npe rau yav tom ntej.`,
	ps: html`تاسو تراوسه د ناکامۍ لاسلیک نه لرئ. د فعالیت لاسلیک کولو لپاره د نښې ت buttonۍ وکاروئ او <em>دا زما د ډیفالټ لاسلیک بکس جوړ کړئ</em> ترڅو د راتلونکی کارونې لپاره لاسلیک خوندی کړئ.`,
	pt: html`Você ainda não tem uma assinatura padrão. Use o botão de assinatura para assinar a atividade e marque a caixa <em>tornar esta minha assinatura padrão</em> para salvar a assinatura para uso futuro.`,
	'pt-pt': html`Você ainda não tem uma assinatura padrão. Use o botão de assinatura para assinar a atividade e marque a caixa <em>Tornar esta minha assinatura padrão</em> para salvar a assinatura para uso futuro.`,
	ru: html`У вас еще нет подписи по умолчанию. Используйте кнопку «Подписать», чтобы подписать действие, и установите флажок « <em>Сделать это моей подписью по умолчанию</em> », чтобы сохранить подпись для использования в будущем.`,
	sm: html`E le'i i ai sau saini Tiumasani. Faaaoga le faamau saini e saini ai le gaoioiga ma faailoga le pusa <em>o la'u saini Tiumasani lenei</em> e sefe ai le saini mo le faaaogaina i le lumanai.`,
	so: html`Weli ma lihid saxiix default ah. Isticmaal badhanka calaamadaha si aad u saxiixdo dhaqdhaqaaqa oo aad u hubiso <em>in ay ka dhigto sanduuqaan saxiixa saxiixa</em> si aad u badbaadiso saxiixa isticmaalka mustaqbalka.`,
	th: html`คุณยังไม่มีลายเซ็นเริ่มต้น ใช้ปุ่มลงนามเพื่อลงนามในกิจกรรมและทําเครื่องหมายที่ช่อง <em>กําหนดให้เป็นลายเซ็นเริ่มต้นของฉัน</em> เพื่อบันทึกลายเซ็นเพื่อใช้ในอนาคต`,
	ur: html`آپ کے پاس ابھی تک پہلے سے طے شدہ دستخط نہیں ہیں۔ سرگرمی پر دستخط کرنے کے لئے سائن بٹن کا استعمال کریں اور مستقبل کے استعمال کے لئے دستخط کو محفوظ کرنے کے لئے <em>اس کو میرا ڈیفالٹ دستخط باکس بنائیں چیک کریں</em> ۔`,
	vi: html`Bạn chưa có chữ ký mặc định. Sử dụng nút ký để ký hoạt động và chọn hộp <em>đặt chữ ký này thành chữ ký mặc định của tôi</em> để lưu chữ ký để sử dụng trong tương lai.`,
	'zh-hans': html`您还没有默认签名。使用签名按钮对活动进行签名，并选中 <em>将此设为我的默认签名</em> 框以保存签名以供将来使用。`,
	'zh-hant': html`您還沒有預設簽名。使用簽名按鈕對活動進行簽名，並選中 <em>將此設為我的預設簽名</em> 框以保存簽名以供將來使用。`,
	en: html`You don't have a default signature yet. Use the sign button to sign the activity and check the <em>make this my default signature</em> box to save the signature for future use.`
};

export const loc_draw: Record<Locale, string> = {
	ar: `رسم`,
	de: `Zeichnen`,
	es: `Dibujar`,
	fa: `رسم`,
	fil: `Gumuhit`,
	fr: `Dessiner`,
	hi: `आकर्षित करना`,
	ht: `Trase`,
	it: `Disegnare`,
	ja: `描く`,
	ko: `그리다`,
	mww: `kos`,
	ps: `انځورول`,
	pt: `Desenhar`,
	'pt-pt': `Sorteio`,
	ru: `Рисовать`,
	sm: `Tusi se Ata`,
	so: `Sawiro`,
	th: `วาด`,
	ur: `کھینچنا`,
	vi: `Vẽ`,
	'zh-hans': `画`,
	'zh-hant': `畫`,
	en: `Draw`
};

export const loc_resetDefaultSignature: Record<Locale, string> = {
	ar: `إعادة تعيين التوقيع الافتراضي`,
	de: `Standardsignatur zurücksetzen`,
	es: `Restablecer firma predeterminada`,
	fa: `بازنشانی امضای پیش فرض`,
	fil: `I-reset ang default na lagda`,
	fr: `Réinitialiser la signature par défaut`,
	hi: `डिफ़ॉल्ट हस्ताक्षर रीसेट करें`,
	ht: `Reset default siyati`,
	it: `Reimposta la firma predefinita`,
	ja: `デフォルトの署名をリセットする`,
	ko: `기본 서명 재설정`,
	mww: `Reset default kos npe`,
	ps: `تلواله لاسلیک بیاټاکل`,
	pt: `Redefinir assinatura padrão`,
	'pt-pt': `Redefinir assinatura padrão`,
	ru: `Сбросить подпись по умолчанию`,
	sm: `Toe seti le saini Tiumasani`,
	so: `Dib-u-celin saxiixa default`,
	th: `รีเซ็ตลายเซ็นเริ่มต้น`,
	ur: `پہلے سے طے شدہ دستخط کو ری سیٹ`,
	vi: `Đặt lại chữ ký mặc định`,
	'zh-hans': `重置默认签名`,
	'zh-hant': `重置預設簽名`,
	en: `Reset default signature`
};

export const loc_fields: Record<Locale, string> = {
	ar: `حقول`,
	de: `Felder`,
	es: `Campos`,
	fa: `زمینه`,
	fil: `Mga Patlang`,
	fr: `Champs`,
	hi: `फ़ील्ड`,
	ht: `Tèren yo`,
	it: `Campi`,
	ja: `田畑`,
	ko: `필드`,
	mww: `teb`,
	ps: `ډګرونه`,
	pt: `Campos`,
	'pt-pt': `Campos`,
	ru: `Поля`,
	sm: `Fanua`,
	so: `fields`,
	th: `ฟิลด์`,
	ur: `قطعات`,
	vi: `Lĩnh vực`,
	'zh-hans': `领域`,
	'zh-hant': `領域`,
	en: `Fields`
};

export const loc_consent: Record<Locale, string> = {
	ar: `موافقه`,
	de: `Einwilligen`,
	es: `Consentimiento`,
	fa: `رضایت`,
	fil: `Pahintulot`,
	fr: `Consentement`,
	hi: `अनुमति`,
	ht: `Konsantman`,
	it: `Consenso`,
	ja: `同意`,
	ko: `승낙`,
	mww: `Kev Tso Cai`,
	ps: `رضایت`,
	pt: `Consentimento`,
	'pt-pt': `Consentimento`,
	ru: `Согласие`,
	sm: `Maliega`,
	so: `Ogolaanshaha`,
	th: `ยินยอม`,
	ur: `اجازت`,
	vi: `Đồng ý`,
	'zh-hans': `同意`,
	'zh-hant': `同意`,
	en: `Consent`
};

export const loc_oneMedication: Record<Locale, string> = {
	ar: `دواء واحد`,
	de: `Ein Medikament`,
	es: `Un medicamento`,
	fa: `یک دارو`,
	fil: `Isang gamot`,
	fr: `Un médicament`,
	hi: `एक दवा`,
	ht: `Yon renmèd`,
	it: `Un farmaco`,
	ja: `1つの薬`,
	ko: `약물 1개`,
	mww: `ib cov tshuaj noj`,
	ps: `یو درمل`,
	pt: `Um medicamento`,
	'pt-pt': `Um medicamento`,
	ru: `Один препарат`,
	sm: `Tasi le fualaau`,
	so: `Hal daawo`,
	th: `ยาหนึ่งตัว`,
	ur: `ایک دوا`,
	vi: `Một loại thuốc`,
	'zh-hans': `一种药物`,
	'zh-hant': `一種藥物`,
	en: `One medication`
};

export const loc_multipleMedicationsFormat: Record<Locale, (count: unknown) => string> = {
	ar: (count) => `${count} أدوية`,
	de: (count) => `${count} Medikamente`,
	es: (count) => `${count} medicamentos`,
	fa: (count) => `${count} دارو`,
	fil: (count) => `${count} mga gamot`,
	fr: (count) => `${count} médicaments`,
	hi: (count) => `${count} दवाएं`,
	ht: (count) => `${count} redisyon`,
	it: (count) => `${count} farmaci`,
	ja: (count) => `${count} つの薬`,
	ko: (count) => `${count} 가지 약물`,
	mww: (count) => `${count} tshuaj noj`,
	ps: (count) => `${count} درمل`,
	pt: (count) => `${count} medicamentos`,
	'pt-pt': (count) => `${count} medicamentos`,
	ru: (count) => `${count} лекарства`,
	sm: (count) => `${count} fualaau`,
	so: (count) => `${count} daawooyin ah`,
	th: (count) => `${count} ชนิด`,
	ur: (count) => `${count} دوائیں`,
	vi: (count) => `${count} loại thuốc`,
	'zh-hans': (count) => `${count} 种药物`,
	'zh-hant': (count) => `${count} 種藥物`,
	en: (count) => `${count} medications`
};

export const loc_actionRequired: Record<Locale, string> = {
	ar: `الإجراء المطلوب`,
	de: `Handlungsbedarf`,
	es: `Acción requerida`,
	fa: `اقدام لازم است`,
	fil: `Kailangan ang pagkilos`,
	fr: `Action requise`,
	hi: `कार्रवाई करना ज़रूरी है`,
	ht: `Aksyon obligatwa`,
	it: `Azione richiesta`,
	ja: `必要なアクション`,
	ko: `조치 필요`,
	mww: `yuav tsum tau ua`,
	ps: `چار اړینه ده`,
	pt: `Ação necessária`,
	'pt-pt': `Ação necessária`,
	ru: `Необходимые действия`,
	sm: `E manaomia le faatinoga`,
	so: `Action baahan yahay`,
	th: `ต้องดําเนินการ`,
	ur: `کارروائی کی ضرورت ہے`,
	vi: `Hành động cần thiết`,
	'zh-hans': `需要采取的措施`,
	'zh-hant': `需要採取的措施`,
	en: `Action required`
};

export const loc_scrollToTop: Record<Locale, string> = {
	ar: `انتقل إلى أعلى`,
	de: `Nach oben scrollen`,
	es: `Desplázate hacia arriba`,
	fa: `رفتن به بالا`,
	fil: `Mag scroll sa itaas`,
	fr: `Faire défiler vers le haut`,
	hi: `शीर्ष तक स्क्रॉल करें`,
	ht: `Woulo tèt`,
	it: `Scorrere verso l'alto`,
	ja: `トップにスクロール`,
	ko: `맨 위로 스크롤`,
	mww: `Scroll saum toj kawg nkaus`,
	ps: `پښتو`,
	pt: `Rolar para cima`,
	'pt-pt': `Voltar ao topo`,
	ru: `Прокрутить наверх`,
	sm: `Faasolo i luga`,
	so: `Hore`,
	th: `เลื่อนไปด้านบน`,
	ur: `اوپر سکرول کریں`,
	vi: `Di chuyển lên đầu trang`,
	'zh-hans': `滚动到顶部`,
	'zh-hant': `滾動到頂部`,
	en: `Scroll to top`
};

export const loc_unspecified: Record<Locale, string> = {
	ar: `محدد`,
	de: `Unspezifiziert`,
	es: `No especificado`,
	fa: `نامشخص`,
	fil: `Hindi tinukoy`,
	fr: `Quelconque`,
	hi: `अनिर्दिष्ट`,
	ht: `Pa espesifye`,
	it: `Specificato`,
	ja: `未指定`,
	ko: `불특정`,
	mww: `Unspecified`,
	ps: `ناپېژندلی`,
	pt: `Unspecified`,
	'pt-pt': `Não especificado`,
	ru: `Неопределенное`,
	sm: `E Le'i Faailoaina`,
	so: `Aan la cayimin`,
	th: `ระบุ`,
	ur: `غیر واضح`,
	vi: `Không xác định`,
	'zh-hans': `未指定`,
	'zh-hant': `未指定`,
	en: `Unspecified`
};

export const loc_phoneNumber: Record<Locale, string> = {
	ar: `رقم الهاتف`,
	de: `Telefonnummer`,
	es: `Número de teléfono`,
	fa: `شماره تلفن`,
	fil: `Numero ng telepono`,
	fr: `Numéro de téléphone`,
	hi: `फोन संख्या`,
	ht: `Nimewo telefòn`,
	it: `Numero di telefono`,
	ja: `電話番号`,
	ko: `전화번호`,
	mww: `xov tooj`,
	ps: `د ټېلیفون شمېره`,
	pt: `Telefone`,
	'pt-pt': `Número de telefone`,
	ru: `Номер телефона`,
	sm: `Numera o le telefoni`,
	so: `lambarka telefoonka`,
	th: `หมายเลขโทรศัพท์`,
	ur: `فون نمبر`,
	vi: `Số điện thoại`,
	'zh-hans': `电话号码`,
	'zh-hant': `電話號碼`,
	en: `Phone number`
};

export const loc_extension: Record<Locale, TemplateResult> = {
	ar: html`تحويلة<span class="sr-only">الفرقة</span>`,
	de: html`Erweiterung<span class="sr-only"></span>`,
	es: html`Ext<span class="sr-only">ension</span>`,
	fa: html`گسترش<span class="sr-only"></span>`,
	fil: html`<span class="sr-only">Ekst ension</span>`,
	fr: html`Extension<span class="sr-only"></span>`,
	hi: html`एक्सटेंशन<span class="sr-only">एंशन</span>`,
	ht: html`<span class="sr-only">èkspas</span>`,
	it: html`Estensione<span class="sr-only"></span>`,
	ja: html`エクス<span class="sr-only">テント</span>`,
	ko: html`Ext<span class="sr-only">ension</span>`,
	mww: html`Ext<span class="sr-only">ension</span>`,
	ps: html`Ext<span class="sr-only">ension</span>`,
	pt: html`Ext<span class="sr-only">ension</span>`,
	'pt-pt': html`Ext<span class="sr-only">ension</span>`,
	ru: html`Расширение<span class="sr-only"></span>`,
	sm: html`<span class="sr-only">Ension</span> faaopoopo`,
	so: html`Ext<span class="sr-only">ension</span>`,
	th: html`ขยายความ<span class="sr-only"></span>`,
	ur: html`Ext<span class="sr-only">ension</span>`,
	vi: html`<span class="sr-only">Ext ension</span>`,
	'zh-hans': html`Ext<span class="sr-only">ension</span>`,
	'zh-hant': html`Ext<span class="sr-only">ension</span>`,
	en: html`Ext<span class="sr-only">ension</span>`
};

export const loc_primary: Record<Locale, string> = {
	ar: `ابتدائي`,
	de: `Primär`,
	es: `Primario`,
	fa: `اولیه`,
	fil: `Pangunahing`,
	fr: `Primaire`,
	hi: `प्राथमिक`,
	ht: `Pwensipal`,
	it: `Primario`,
	ja: `原発`,
	ko: `본래의`,
	mww: `thawj`,
	ps: `لومړنی`,
	pt: `Primário`,
	'pt-pt': `Primário`,
	ru: `Первичный`,
	sm: `Peraimeri`,
	so: `Primary`,
	th: `ประถม`,
	ur: `اصلی`,
	vi: `Chính`,
	'zh-hans': `主要`,
	'zh-hant': `主要`,
	en: `Primary`
};

export const loc_thereAreNoPhones: Record<Locale, string> = {
	ar: `لا توجد هواتف`,
	de: `Es gibt keine Telefone`,
	es: `No hay teléfonos`,
	fa: `هیچ تلفنی وجود ندارد`,
	fil: `Walang mga telepono`,
	fr: `Il n’y a pas de téléphone`,
	hi: `कोई फोन नहीं हैं`,
	ht: `Pa gen telefòn`,
	it: `Non ci sono telefoni`,
	ja: `電話はありません`,
	ko: `전화가 없습니다`,
	mww: `Yog tsis muaj tes`,
	ps: `هیڅ تلیفون شتون نلری`,
	pt: `Não há telefones`,
	'pt-pt': `Não há telefones`,
	ru: `Телефонов нет`,
	sm: `E leai ni telefoni`,
	so: `Ma jiraan telefoono`,
	th: `ไม่มีโทรศัพท์`,
	ur: `کوئی فون نہیں ہے`,
	vi: `Không có điện thoại`,
	'zh-hans': `没有电话`,
	'zh-hant': `沒有電話`,
	en: `There are no phones`
};

export const loc_addPhone: Record<Locale, string> = {
	ar: `إضافة رقم هاتف`,
	de: `Telefon hinzufügen`,
	es: `Agregar teléfono`,
	fa: `اضافه کردن تلفن`,
	fil: `Magdagdag ng telepono`,
	fr: `Ajouter un téléphone`,
	hi: `फ़ोन जोड़ें`,
	ht: `Ajoute telefòn`,
	it: `Aggiungi telefono`,
	ja: `電話を追加`,
	ko: `휴대전화 추가`,
	mww: `Ntxiv xov tooj`,
	ps: `ټېلېفون ورزیات کړئ`,
	pt: `Adicionar telefone`,
	'pt-pt': `Adicionar telefone`,
	ru: `Добавить телефон`,
	sm: `Faaopoopo le telefoni`,
	so: `Add telefoonka`,
	th: `เพิ่มโทรศัพท์`,
	ur: `فون شامل کریں`,
	vi: `Thêm điện thoại`,
	'zh-hans': `添加电话`,
	'zh-hant': `添加電話`,
	en: `Add phone`
};

export const loc_removePhone: Record<Locale, string> = {
	ar: `إزالة الهاتف`,
	de: `Telefon entfernen`,
	es: `Quitar el teléfono`,
	fa: `برداشتن تلفن`,
	fil: `Tanggalin ang telepono`,
	fr: `Retirer le téléphone`,
	hi: `फ़ोन निकालें`,
	ht: `Retire telefòn`,
	it: `Rimuovi telefono`,
	ja: `電話を取り外す`,
	ko: `휴대전화 삭제`,
	mww: `Tshem tawm xov tooj`,
	ps: `ټېلېفون لرې کول`,
	pt: `Remover telefone`,
	'pt-pt': `Remover telefone`,
	ru: `Удалить телефон`,
	sm: `Aveese le telefoni`,
	so: `Ka saar telefoonka`,
	th: `ถอดโทรศัพท์ออก`,
	ur: `فون ہٹا دیں`,
	vi: `Xóa điện thoại`,
	'zh-hans': `删除手机`,
	'zh-hant': `刪除手機`,
	en: `Remove phone`
};

export const loc_phone: Record<Locale, string> = {
	ar: `الهاتف`,
	de: `Telefon`,
	es: `Teléfono`,
	fa: `تلفن`,
	fil: `Telepono`,
	fr: `Téléphone`,
	hi: `फ़ोन`,
	ht: `Telefòn`,
	it: `Telefono`,
	ja: `電話`,
	ko: `전화`,
	mww: `Xov tooj`,
	ps: `ټېلېفون`,
	pt: `Telefone`,
	'pt-pt': `Telefone`,
	ru: `Телефон`,
	sm: `Telefoni`,
	so: `Telefoon`,
	th: `โทรศัพท์`,
	ur: `فون`,
	vi: `Điện thoại`,
	'zh-hans': `电话`,
	'zh-hant': `電話`,
	en: `Phone`
};

export const loc_phones: Record<Locale, string> = {
	ar: `الهواتف`,
	de: `Telefone`,
	es: `Teléfonos`,
	fa: `تلفن`,
	fil: `Mga Telepono`,
	fr: `Téléphones`,
	hi: `फोन`,
	ht: `Telefòn yo`,
	it: `Telefoni`,
	ja: `携帯 電話`,
	ko: `전화`,
	mww: `tes`,
	ps: `تلیفون`,
	pt: `Telefones`,
	'pt-pt': `Telefones`,
	ru: `Телефоны`,
	sm: `Telefoni`,
	so: `Telefoonada`,
	th: `โทรศัพท์ มือ ถือ`,
	ur: `فونز`,
	vi: `Điện thoại`,
	'zh-hans': `手机`,
	'zh-hant': `手機`,
	en: `Phones`
};

export const loc_email: Record<Locale, string> = {
	ar: `البريد الإلكتروني`,
	de: `E-Mail`,
	es: `Correo electrónico`,
	fa: `ایمیل`,
	fil: `Mag-email`,
	fr: `Messagerie électronique`,
	hi: `ईमेल`,
	ht: `Imel`,
	it: `E-mail`,
	ja: `電子メール`,
	ko: `메일 주소`,
	mww: `Tus email`,
	ps: `برېښلیک`,
	pt: `Email`,
	'pt-pt': `Email`,
	ru: `Отправить по электронной почте`,
	sm: `Imeli`,
	so: `Email`,
	th: `อีเมล`,
	ur: `ای میل`,
	vi: `Email`,
	'zh-hans': `电子邮件`,
	'zh-hant': `電子郵件`,
	en: `Email`
};

export const loc_emails: Record<Locale, string> = {
	ar: `البريد الإلكتروني`,
	de: `E-Mails`,
	es: `Correos electrónicos`,
	fa: `ایمیل`,
	fil: `Mga Email`,
	fr: `Courriels`,
	hi: `ईमेल`,
	ht: `Imèl`,
	it: `E-mail`,
	ja: `メール`,
	ko: `이메일`,
	mww: `Emails`,
	ps: `بریښنالیکونه`,
	pt: `E-mails`,
	'pt-pt': `E-mails`,
	ru: `Электронная почта`,
	sm: `Imeli`,
	so: `Emails`,
	th: `อีเมล`,
	ur: `ای میلز`,
	vi: `Thư điện tử`,
	'zh-hans': `电子邮件`,
	'zh-hant': `電子郵件`,
	en: `Emails`
};

export const loc_addEmail: Record<Locale, string> = {
	ar: `إضافة بريد إلكتروني`,
	de: `E-Mail hinzufügen`,
	es: `Agregar correo electrónico`,
	fa: `افزودن ایمیل`,
	fil: `Magdagdag ng email`,
	fr: `Ajouter une adresse e-mail`,
	hi: `ईमेल जोड़ें`,
	ht: `Ajoute imèl`,
	it: `Aggiungi email`,
	ja: `メールを追加`,
	ko: `이메일 추가`,
	mww: `Ntxiv email`,
	ps: `برېښلیک زیاتول`,
	pt: `Adicionar e-mail`,
	'pt-pt': `Adicionar e-mail`,
	ru: `Добавить электронную почту`,
	sm: `Faaopoopo le imeli`,
	so: `Ku dar email`,
	th: `เพิ่มอีเมล`,
	ur: `ای میل شامل کریں`,
	vi: `Thêm email`,
	'zh-hans': `添加电子邮件`,
	'zh-hant': `添加電子郵件`,
	en: `Add email`
};

export const loc_removeEmail: Record<Locale, string> = {
	ar: `إزالة البريد الإلكتروني`,
	de: `E-Mail entfernen`,
	es: `Eliminar correo electrónico`,
	fa: `برداشتن ایمیل`,
	fil: `Tanggalin ang email`,
	fr: `Supprimer l’e-mail`,
	hi: `ईमेल निकालें`,
	ht: `Retire imèl`,
	it: `Rimuovi email`,
	ja: `メールを削除`,
	ko: `이메일 삭제`,
	mww: `Tshem tawm email`,
	ps: `برېښلیک لر کول`,
	pt: `Remover e-mail`,
	'pt-pt': `Remover e-mail`,
	ru: `Удалить электронную почту`,
	sm: `Aveese le imeli`,
	so: `Ka saar email`,
	th: `ลบอีเมล`,
	ur: `ای میل حذف کریں`,
	vi: `Xóa email`,
	'zh-hans': `移除电子邮件`,
	'zh-hant': `拿掉電子郵件`,
	en: `Remove email`
};

export const loc_thereAreNoEmails: Record<Locale, string> = {
	ar: `لا توجد رسائل بريد إلكتروني`,
	de: `Es gibt keine E-Mails`,
	es: `No hay correos electrónicos`,
	fa: `هیچ ایمیلی وجود ندارد`,
	fil: `Walang mga email`,
	fr: `Il n’y a pas d’e-mails`,
	hi: `कोई ईमेल नहीं हैं`,
	ht: `Pa gen okenn Imèl`,
	it: `Non ci sono e-mail`,
	ja: `メールはありません`,
	ko: `이메일이 없습니다`,
	mww: `Yog tsis muaj emails`,
	ps: `هیڅ بریښنالیکونه شتون نلری`,
	pt: `Não há e-mails`,
	'pt-pt': `Não há e-mails`,
	ru: `Нет электронных писем`,
	sm: `E leai ni imeli`,
	so: `Waxaa jira emails lahayn`,
	th: `ไม่มีอีเมล`,
	ur: `کوئی ای میلز نہیں ہیں`,
	vi: `Không có email`,
	'zh-hans': `没有电子邮件`,
	'zh-hant': `沒有電子郵件`,
	en: `There are no emails`
};

export const loc_addAddress: Record<Locale, string> = {
	ar: `إضافة عنوان`,
	de: `Adresse hinzufügen`,
	es: `Agregar dirección`,
	fa: `افزودن آدرس`,
	fil: `Magdagdag ng address`,
	fr: `Ajouter une adresse`,
	hi: `पता जोड़ें`,
	ht: `Adrès`,
	it: `Aggiungi indirizzo`,
	ja: `住所を追加`,
	ko: `주소 추가`,
	mww: `Chaw nyob`,
	ps: `پته زیاتول`,
	pt: `Adicionar endereço`,
	'pt-pt': `Adicionar endereço`,
	ru: `Добавить адрес`,
	sm: `Faaopoopo le tuatusi`,
	so: `Ku dar cinwaanka`,
	th: `เพิ่มที่อยู่`,
	ur: `ایڈریس شامل کریں`,
	vi: `Thêm địa chỉ`,
	'zh-hans': `添加地址`,
	'zh-hant': `添加位址`,
	en: `Add address`
};

export const loc_removeAddress: Record<Locale, string> = {
	ar: `إزالة العنوان`,
	de: `Adresse entfernen`,
	es: `Eliminar dirección`,
	fa: `برداشتن آدرس`,
	fil: `Tanggalin ang address`,
	fr: `Supprimer l’adresse`,
	hi: `पता निकालें`,
	ht: `Retire adrès`,
	it: `Rimuovi indirizzo`,
	ja: `アドレスの削除`,
	ko: `주소 삭제`,
	mww: `Tshem tawm qhov chaw nyob`,
	ps: `پته لرې کړئ`,
	pt: `Remover endereço`,
	'pt-pt': `Remover endereço`,
	ru: `Удалить адрес`,
	sm: `Aveese le tuatusi`,
	so: `Ka saar cinwaanka`,
	th: `ลบที่อยู่`,
	ur: `ایڈریس حذف کریں`,
	vi: `Xóa địa chỉ`,
	'zh-hans': `删除地址`,
	'zh-hant': `刪除位址`,
	en: `Remove address`
};

export const loc_thereAreNoAddresses: Record<Locale, string> = {
	ar: `لا توجد عناوين`,
	de: `Es gibt keine Adressen`,
	es: `No hay direcciones`,
	fa: `هیچ آدرسی وجود ندارد`,
	fil: `Walang mga address`,
	fr: `Il n’y a pas d’adresses`,
	hi: `कोई पता नहीं है`,
	ht: `Pa gen okenn adrès`,
	it: `Non ci sono indirizzi`,
	ja: `住所はありません`,
	ko: `주소가 없습니다`,
	mww: `Tsis muaj chaw nyob`,
	ps: `هیڅ ادرس نه لری`,
	pt: `Não há endereços`,
	'pt-pt': `Não há endereços`,
	ru: `Нет адресов`,
	sm: `E leai ni tuatusi`,
	so: `Cinwaano ma leh.`,
	th: `ไม่มีที่อยู่`,
	ur: `کوئی پتہ نہیں ہے`,
	vi: `Không có địa chỉ`,
	'zh-hans': `没有地址`,
	'zh-hant': `沒有位址`,
	en: `There are no addresses`
};

export const loc_streetAddress: Record<Locale, string> = {
	ar: `عنوان الشارع`,
	de: `Straße`,
	es: `Dirección`,
	fa: `آدرس خیابان`,
	fil: `Address ng kalye`,
	fr: `Adresse postale`,
	hi: `सड़क का पता`,
	ht: `Adrès lari`,
	it: `Indirizzo`,
	ja: `住所`,
	ko: `주소`,
	mww: `Txoj kev chaw nyob`,
	ps: `سړک پته`,
	pt: `Endereço`,
	'pt-pt': `Endereço`,
	ru: `Почтовый адрес`,
	sm: `Tuatusi o le auala`,
	so: `cinwaanka Street`,
	th: `ที่ตั้ง`,
	ur: `سڑک کا پتہ`,
	vi: `Địa chỉ đường phố`,
	'zh-hans': `街道地址`,
	'zh-hant': `街道位址`,
	en: `Street address`
};

export const loc_subAddress: Record<Locale, string> = {
	ar: `شقة / جناح / وحدة`,
	de: `Appartement / Suite / Einheit`,
	es: `Apartamento / suite / unidad`,
	fa: `آپارتمان / سوئیت / واحد`,
	fil: `Apartment / suite / unit`,
	fr: `Appartement / suite / unité`,
	hi: `अपार्टमेंट/सुइट/यूनिट`,
	ht: `Apatman / admin fò / inite`,
	it: `Appartamento / suite / unità`,
	ja: `アパートメント/スイート/ユニット`,
	ko: `아파트 / 스위트 / 유닛`,
	mww: `Chav Tsev / Suite / Chav Tsev`,
	ps: `اپارتمان / اپارتمان / واحد`,
	pt: `Apartamento / suíte / unidade`,
	'pt-pt': `Apartamento / suite / unidade`,
	ru: `Апартаменты / люксы / апартаменты`,
	sm: `Fale mautotogi / seti / iunite`,
	so: `Apartment / suite / unit`,
	th: `อพาร์ทเม้นท์ / ห้องสวีท / ยูนิต`,
	ur: `اپارٹمنٹ / سویٹ / یونٹ`,
	vi: `Căn hộ / suite / căn hộ`,
	'zh-hans': `公寓 / 套房 / 单元`,
	'zh-hant': `公寓 / 套房 / 單元`,
	en: `Apartment / suite / unit`
};

export const loc_city: Record<Locale, string> = {
	ar: `مدينة`,
	de: `Stadt`,
	es: `Ciudad`,
	fa: `شهر`,
	fil: `Lungsod`,
	fr: `Ville`,
	hi: `शहर`,
	ht: `Vil`,
	it: `Città`,
	ja: `都市`,
	ko: `도시`,
	mww: `Zos`,
	ps: `ښار`,
	pt: `Cidade`,
	'pt-pt': `Cidade`,
	ru: `Город`,
	sm: `Aai`,
	so: `Magaalada`,
	th: `เมือง`,
	ur: `شہر`,
	vi: `Thành phố`,
	'zh-hans': `城市`,
	'zh-hant': `城市`,
	en: `City`
};

export const loc_state: Record<Locale, string> = {
	ar: `حالة`,
	de: `Zustand`,
	es: `Estado`,
	fa: `دولت`,
	fil: `Estado`,
	fr: `État`,
	hi: `राज्य`,
	ht: `Leta`,
	it: `Stato`,
	ja: `状態`,
	ko: `상태`,
	mww: `Xeev`,
	ps: `ایالت`,
	pt: `Estado`,
	'pt-pt': `Estado`,
	ru: `Государство`,
	sm: `Setete`,
	so: `Dowladda`,
	th: `สภาพ`,
	ur: `حالت`,
	vi: `Tiểu bang`,
	'zh-hans': `州`,
	'zh-hant': `州`,
	en: `State`
};

export const loc_zipCode: Record<Locale, string> = {
	ar: `الرمز البريدي`,
	de: `PLZ`,
	es: `Código postal`,
	fa: `کد پستی`,
	fil: `Zip code`,
	fr: `Code postal`,
	hi: `ज़िप कोड`,
	ht: `Kòd postal`,
	it: `CAP`,
	ja: `郵便番号`,
	ko: `우편번호`,
	mww: `Tsib tug leb qhia thaj tsam yus nyob`,
	ps: `زیپ شفر`,
	pt: `Código Postal`,
	'pt-pt': `Código Postal`,
	ru: `Почтовый индекс`,
	sm: `Numera o le pusa meli`,
	so: `Lanbarka furaha`,
	th: `รหัสไปรษณีย์`,
	ur: `علاقائ نمبر`,
	vi: `Mã bưu điện`,
	'zh-hans': `邮政编码`,
	'zh-hant': `郵遞區號`,
	en: `Zip code`
};

export const loc_zipCodeExt: Record<Locale, TemplateResult> = {
	ar: html`<span class="sr-only">الرمز البريدي </span>تحويلة<span class="sr-only">الفرقة</span>`,
	de: html`<span class="sr-only">PLZ </span>Erweiterung<span class="sr-only"></span>`,
	es: html`<span class="sr-only">Código postal </span>Ext<span class="sr-only">ension</span>`,
	fa: html`<span class="sr-only">کد پستی </span>گسترش<span class="sr-only"></span>`,
	fil: html`<span class="sr-only">Zip code </span><span class="sr-only">Ekst ension</span>`,
	fr: html`<span class="sr-only">Code postal </span>Extension<span class="sr-only"></span>`,
	hi: html`<span class="sr-only">ज़िप कोड </span>एक्सटेंशन<span class="sr-only">एंशन</span>`,
	ht: html`<span class="sr-only">Kòd postal </span><span class="sr-only">èkspas</span>`,
	it: html`<span class="sr-only">CAP </span>Estensione<span class="sr-only"></span>`,
	ja: html`<span class="sr-only">郵便番号 </span>エクス<span class="sr-only">テント</span>`,
	ko: html`<span class="sr-only">우편번호 </span>Ext<span class="sr-only">ension</span>`,
	mww: html`<span class="sr-only">Tsib tug leb qhia thaj tsam yus nyob </span>Ext<span class="sr-only">ension</span>`,
	ps: html`<span class="sr-only">زیپ شفر </span>Ext<span class="sr-only">ension</span>`,
	pt: html`<span class="sr-only">Código Postal </span>Ext<span class="sr-only">ension</span>`,
	'pt-pt': html`<span class="sr-only">Código Postal </span>Ext<span class="sr-only">ension</span>`,
	ru: html`<span class="sr-only">Почтовый индекс </span>Расширение<span class="sr-only"></span>`,
	sm: html`<span class="sr-only">Numera o le pusa meli </span><span class="sr-only">Ension</span> faaopoopo`,
	so: html`<span class="sr-only">Lanbarka furaha </span>Ext<span class="sr-only">ension</span>`,
	th: html`<span class="sr-only">รหัสไปรษณีย์ </span>ขยายความ<span class="sr-only"></span>`,
	ur: html`<span class="sr-only">علاقائ نمبر </span>Ext<span class="sr-only">ension</span>`,
	vi: html`<span class="sr-only">Mã bưu điện </span><span class="sr-only">Ext ension</span>`,
	'zh-hans': html`<span class="sr-only">邮政编码 </span>Ext<span class="sr-only">ension</span>`,
	'zh-hant': html`<span class="sr-only">郵遞區號 </span>Ext<span class="sr-only">ension</span>`,
	en: html`<span class="sr-only">Zip code </span>Ext<span class="sr-only">ension</span>`
};

export const loc_county: Record<Locale, string> = {
	ar: `مقاطعه`,
	de: `Bezirk`,
	es: `Condado`,
	fa: `شهرستان`,
	fil: `Condado han`,
	fr: `Comté`,
	hi: `काउंटी`,
	ht: `Konte`,
	it: `Contea`,
	ja: `郡`,
	ko: `군`,
	mww: `County`,
	ps: `ولسوالی`,
	pt: `Condado`,
	'pt-pt': `Concelho`,
	ru: `Графство`,
	sm: `Itumalo`,
	so: `Gobolka`,
	th: `เขต`,
	ur: `کاؤنٹی`,
	vi: `Quận`,
	'zh-hans': `县`,
	'zh-hant': `縣`,
	en: `County`
};

export const loc_primaryPhysicalAddress: Record<Locale, string> = {
	ar: `العنوان الفعلي الأساسي`,
	de: `Primäre physische Adresse`,
	es: `Dirección física principal`,
	fa: `آدرس فیزیکی اولیه`,
	fil: `Pangunahing pisikal na address`,
	fr: `Adresse physique principale`,
	hi: `प्राथमिक भौतिक पता`,
	ht: `Adrès fizik prensipal`,
	it: `Indirizzo fisico principale`,
	ja: `プライマリ物理アドレス`,
	ko: `기본 물리적 주소`,
	mww: `Lub cev qhov chaw nyob`,
	ps: `لومړنۍ فزیکی پته`,
	pt: `Endereço físico principal`,
	'pt-pt': `Endereço físico principal`,
	ru: `Основной физический адрес`,
	sm: `Tuatusi faaletino o le Peraimeri`,
	so: `Ciwaanka aasaasiga ah ee jirka`,
	th: `ที่อยู่ทางกายภาพหลัก`,
	ur: `بنیادی جسمانی پتہ`,
	vi: `Địa chỉ thực chính`,
	'zh-hans': `主要物理地址`,
	'zh-hant': `主要物理位址`,
	en: `Primary physical address`
};

export const loc_primaryMailingAddress: Record<Locale, string> = {
	ar: `العنوان البريدي الأساسي`,
	de: `Primäre Postanschrift`,
	es: `Dirección postal principal`,
	fa: `آدرس پستی اصلی`,
	fil: `Primary mailing address`,
	fr: `Adresse postale principale`,
	hi: `प्राथमिक डाक पता`,
	ht: `Adrès poste prensipal`,
	it: `Indirizzo postale principale`,
	ja: `主な郵送先住所`,
	ko: `기본 우편 주소`,
	mww: `Primary chaw nyob`,
	ps: `نوی لیک پته چاپول`,
	pt: `Endereço de correspondência principal`,
	'pt-pt': `Endereço postal principal`,
	ru: `Основной почтовый адрес`,
	sm: `Tuatusi o le meli autu`,
	so: `Cinwaanka boostada aasaasiga ah`,
	th: `ที่อยู่ทางไปรษณีย์หลัก`,
	ur: `بنیادی میلنگ ایڈریس`,
	vi: `Địa chỉ gửi thư chính`,
	'zh-hans': `主要邮寄地址`,
	'zh-hant': `主要郵寄位址`,
	en: `Primary mailing address`
};

export const loc_rooftopX: Record<Locale, string> = {
	ar: `روفتوب إكس`,
	de: `Dachterrasse X`,
	es: `Azotea X`,
	fa: `پشت بام X`,
	fil: `bubong X`,
	fr: `Toit X`,
	hi: `रूफटॉप X`,
	ht: `Twati X`,
	it: `Tetto X`,
	ja: `ルーフトップX`,
	ko: `루프탑 X`,
	mww: `Rooftop X`,
	ps: `د چت X`,
	pt: `Telhado X`,
	'pt-pt': `Telhado X`,
	ru: `Крыша X`,
	sm: `Taualuga X`,
	so: `Rooftop X`,
	th: `รูฟท็อป X`,
	ur: `چھت X`,
	vi: `Tầng thượng X`,
	'zh-hans': `屋顶 X`,
	'zh-hant': `屋頂 X`,
	en: `Rooftop X`
};

export const loc_rooftopY: Record<Locale, string> = {
	ar: `روفتوب Y`,
	de: `Dachterrasse Y`,
	es: `Azotea Y`,
	fa: `پشت بام Y`,
	fil: `Rooftop Y`,
	fr: `Toit Y`,
	hi: `रूफटॉप Y`,
	ht: `Twati Y`,
	it: `Tetto Y`,
	ja: `屋上Y`,
	ko: `루프탑 Y`,
	mww: `Rooftop Y`,
	ps: `د چت Y`,
	pt: `Telhado Y`,
	'pt-pt': `Telhado Y`,
	ru: `Крыша Y`,
	sm: `Taualuga Y`,
	so: `Rooftop Y`,
	th: `รูฟท็อป Y`,
	ur: `چھت Y`,
	vi: `Tầng thượng Y`,
	'zh-hans': `屋顶 Y`,
	'zh-hant': `屋頂 Y`,
	en: `Rooftop Y`
};

export const loc_entryX: Record<Locale, string> = {
	ar: `الإدخال X`,
	de: `Eintrag X`,
	es: `Entrada X`,
	fa: `ورودی X`,
	fil: `Entry X`,
	fr: `Entrée X`,
	hi: `प्रवेश X`,
	ht: `Antre X`,
	it: `Voce X`,
	ja: `エントリーX`,
	ko: `엔트리 X`,
	mww: `Nkag teb chaws X`,
	ps: `ننوت X`,
	pt: `Entrada X`,
	'pt-pt': `Entrada X`,
	ru: `Запись X`,
	sm: `Ta i totonu X`,
	so: `Galitaanka X`,
	th: `รายการ X`,
	ur: `Entry X`,
	vi: `Mục X`,
	'zh-hans': `条目 X`,
	'zh-hant': `條目 X`,
	en: `Entry X`
};

export const loc_entryY: Record<Locale, string> = {
	ar: `الإدخال Y`,
	de: `Eintrag Y`,
	es: `Entrada Y`,
	fa: `ورودی Y`,
	fil: `Entry Y`,
	fr: `Entrée Y`,
	hi: `प्रविष्टि Y`,
	ht: `Antre y`,
	it: `Voce Y`,
	ja: `エントリーY`,
	ko: `엔트리 Y`,
	mww: `Nkag Teb Chaws Y`,
	ps: `ننوت Y`,
	pt: `Entrada Y`,
	'pt-pt': `Entrada Y`,
	ru: `Запись Y`,
	sm: `Ta i totonu Y`,
	so: `Entry Y`,
	th: `รายการ Y`,
	ur: `Entry Y`,
	vi: `Mục Y`,
	'zh-hans': `条目 Y`,
	'zh-hant': `條目 Y`,
	en: `Entry Y`
};

export const loc_district: Record<Locale, string> = {
	ar: `قطر`,
	de: `Bezirk`,
	es: `Distrito`,
	fa: `منطقه`,
	fil: `Distrito`,
	fr: `District`,
	hi: `जिला`,
	ht: `Distri`,
	it: `Quartiere`,
	ja: `区`,
	ko: `지구`,
	mww: `Cheeb Tsam Tsev Kawm Ntawv`,
	ps: `ولسوالۍ`,
	pt: `Distrito`,
	'pt-pt': `Distrito`,
	ru: `Район`,
	sm: `Itu`,
	so: `Degmada`,
	th: `เขต`,
	ur: `علاقہ`,
	vi: `Huyện`,
	'zh-hans': `区`,
	'zh-hant': `區`,
	en: `District`
};

export const loc_metroArea: Record<Locale, string> = {
	ar: `منطقة المترو`,
	de: `Metropolregion`,
	es: `Área metropolitana`,
	fa: `منطقه مترو`,
	fil: `Lugar ng Metro`,
	fr: `Région métropolitaine`,
	hi: `मेट्रो क्षेत्र`,
	ht: `Zòn metro`,
	it: `Area metropolitana`,
	ja: `メトロエリア`,
	ko: `메트로 지역`,
	mww: `Xov cheeb tsam`,
	ps: `میټرو سیمه`,
	pt: `Área metropolitana`,
	'pt-pt': `Área metropolitana`,
	ru: `Район метро`,
	sm: `Eria o le metro`,
	so: `Agagaarka Metrooga`,
	th: `เขตเมืองใหญ่`,
	ur: `میٹرو کا علاقہ`,
	vi: `Khu vực tàu điện ngầm`,
	'zh-hans': `都会区`,
	'zh-hant': `都會區`,
	en: `Metro area`
};

export const loc_neighborhood: Record<Locale, string> = {
	ar: `حي`,
	de: `Nachbarschaft`,
	es: `Vecindario`,
	fa: `محله`,
	fil: `Kapitbahayan`,
	fr: `Quartier`,
	hi: `पड़ोस`,
	ht: `Katye`,
	it: `Quartiere`,
	ja: `近所`,
	ko: `이웃`,
	mww: `neeg zej zog`,
	ps: `ګاونډیتوب`,
	pt: `Vizinhança`,
	'pt-pt': `Bairro`,
	ru: `Соседство`,
	sm: `Tuaoi`,
	so: `xaafadda`,
	th: `ย่าน`,
	ur: `پڑوس`,
	vi: `Neighborhood`,
	'zh-hans': `附近`,
	'zh-hant': `附近`,
	en: `Neighborhood`
};

export const loc_region: Record<Locale, string> = {
	ar: `قطر`,
	de: `Region`,
	es: `Región`,
	fa: `منطقه`,
	fil: `Rehiyon`,
	fr: `Région`,
	hi: `क्षेत्र`,
	ht: `Rejyon`,
	it: `Regione`,
	ja: `地域`,
	ko: `부위`,
	mww: `thaj av ntawd`,
	ps: `سیمه`,
	pt: `Região`,
	'pt-pt': `Região`,
	ru: `Область`,
	sm: `Itulagi`,
	so: `Gobolka`,
	th: `บริเวณ`,
	ur: `علاقہ`,
	vi: `Vùng`,
	'zh-hans': `地区`,
	'zh-hant': `地區`,
	en: `Region`
};

export const loc_precision: Record<Locale, string> = {
	ar: `دقة`,
	de: `Präzision`,
	es: `Precisión`,
	fa: `دقت`,
	fil: `Katumpakan`,
	fr: `Précision`,
	hi: `यथार्थता`,
	ht: `Presizyon`,
	it: `Precisione`,
	ja: `精度`,
	ko: `정밀`,
	mww: `precision`,
	ps: `دقت`,
	pt: `Precisão`,
	'pt-pt': `Precisão`,
	ru: `Точность`,
	sm: `Sa'o`,
	so: `Sax`,
	th: `ความถูกต้อง`,
	ur: `Precision`,
	vi: `Chính xác`,
	'zh-hans': `精度`,
	'zh-hant': `精度`,
	en: `Precision`
};

export const loc_country: Record<Locale, string> = {
	ar: `بلد`,
	de: `Land`,
	es: `País`,
	fa: `کشور`,
	fil: `Bansa`,
	fr: `Pays`,
	hi: `भूक्षेत्र`,
	ht: `Peyi`,
	it: `Paese`,
	ja: `国`,
	ko: `나라`,
	mww: `Teb`,
	ps: `افغانستان`,
	pt: `País`,
	'pt-pt': `País`,
	ru: `Страна`,
	sm: `Atunuu`,
	so: `Maad`,
	th: `ประเทศ`,
	ur: `ملک`,
	vi: `Quốc gia`,
	'zh-hans': `国家`,
	'zh-hant': `國家`,
	en: `Country`
};

export const loc_verifiedAddress: Record<Locale, string> = {
	ar: `عنوان موثق`,
	de: `Verifizierte Adresse`,
	es: `Dirección verificada`,
	fa: `آدرس تأییدشده`,
	fil: `Verified na address`,
	fr: `Adresse vérifiée`,
	hi: `सत्यापित पता`,
	ht: `Adrès verifye`,
	it: `Indirizzo verificato`,
	ja: `確認済みの住所`,
	ko: `확인된 주소`,
	mww: `Qhov chaw nyob muaj tseeb`,
	ps: `تایید شوې پته`,
	pt: `Endereço verificado`,
	'pt-pt': `Endereço verificado`,
	ru: `Подтвержденный адрес`,
	sm: `Tuatusi ua faamaonia`,
	so: `Cinwaanka la xaqiijiyey`,
	th: `ที่อยู่ที่ยืนยันแล้ว`,
	ur: `تصدیق شدہ پتہ`,
	vi: `Địa chỉ đã xác minh`,
	'zh-hans': `已验证地址`,
	'zh-hant': `已驗證位址`,
	en: `Verified address`
};

export const loc_unverifiedAddress: Record<Locale, string> = {
	ar: `عنوان لم يتم التحقق منه`,
	de: `Nicht verifizierte Adresse`,
	es: `Dirección no verificada`,
	fa: `آدرس تأییدنشده`,
	fil: `Hindi na verify na address`,
	fr: `Adresse non vérifiée`,
	hi: `असत्यापित पता`,
	ht: `Adrès ki pa verifye`,
	it: `Indirizzo non verificato`,
	ja: `未確認のアドレス`,
	ko: `확인되지 않은 주소`,
	mww: `Chaw nyob unverified chaw nyob`,
	ps: `نا تایید شوې پته`,
	pt: `Endereço não verificado`,
	'pt-pt': `Endereço não verificado`,
	ru: `Неподтвержденный адрес`,
	sm: `Tuatusi e le'i faamaoniaina`,
	so: `Ciwaanka oo aan la xaqiijin`,
	th: `ที่อยู่ที่ไม่ได้รับการยืนยัน`,
	ur: `غیر مصدقہ پتہ`,
	vi: `Địa chỉ chưa xác minh`,
	'zh-hans': `未验证的地址`,
	'zh-hant': `未驗證的位址`,
	en: `Unverified address`
};

export const loc_physical: Record<Locale, string> = {
	ar: `بدني`,
	de: `Physisch`,
	es: `Físico`,
	fa: `فیزیکی`,
	fil: `Pisikal na`,
	fr: `Physique`,
	hi: `शारीरिक`,
	ht: `Fizik`,
	it: `Fisico`,
	ja: `形而下`,
	ko: `육체의`,
	mww: `lub cev`,
	ps: `فزیکی`,
	pt: `Físico`,
	'pt-pt': `Física`,
	ru: `Физический`,
	sm: `Faaletino`,
	so: `Jirka`,
	th: `ทางกายภาพ`,
	ur: `جسمانی`,
	vi: `Vật lý`,
	'zh-hans': `物理的`,
	'zh-hant': `物理的`,
	en: `Physical`
};

export const loc_mailing: Record<Locale, string> = {
	ar: `البريديه`,
	de: `Abschickend`,
	es: `Mailing`,
	fa: `پستی`,
	fil: `Pag-mail`,
	fr: `Publipostage`,
	hi: `डाक`,
	ht: `Poste`,
	it: `Mailing list`,
	ja: `郵送`,
	ko: `우편물`,
	mww: `xa ntawv`,
	ps: `لیک`,
	pt: `Mailing`,
	'pt-pt': `Envio por correspondência`,
	ru: `Рассылка`,
	sm: `Meli`,
	so: `Boostada`,
	th: `เมล`,
	ur: `ترسیلی`,
	vi: `Gửi thư`,
	'zh-hans': `邮件`,
	'zh-hant': `郵件`,
	en: `Mailing`
};

export const loc_streetAddressPrompt: Record<Locale, (cumulus: unknown) => string> = {
	ar: (cumulus) => `ابدأ في كتابة عنوان الشارع وسيقوم ${cumulus} بملء الباقي`,
	de: (cumulus) => `Beginnen Sie mit der Eingabe einer Adresse und ${cumulus} füllt den Rest aus`,
	es: (cumulus) => `Comience a escribir una dirección y ${cumulus} completará el resto`,
	fa: (cumulus) => `شروع به تایپ آدرس خیابان کنید و ${cumulus} بقیه را پر می کند`,
	fil: (cumulus) => `Simulan ang pag type ng isang address sa kalye at si ${cumulus} ay punan ang natitira`,
	fr: (cumulus) => `Commencez à taper une adresse postale et ${cumulus} remplira le reste`,
	hi: (cumulus) => `एक सड़क का पता टाइप करना शुरू करें और ${cumulus} बाकी को भर देगा`,
	ht: (cumulus) => `Kòmanse tape yon adrès lari ak ${cumulus} pral ranpli nan rès la`,
	it: (cumulus) => `Inizia a digitare un indirizzo e ${cumulus} compilerà il resto`,
	ja: (cumulus) => `住所の入力を開始すると、 ${cumulus} が残りを埋めます`,
	ko: (cumulus) => `상세 주소를 입력하기 시작하면 ${cumulus} 가 나머지를 채웁니다`,
	mww: (cumulus) => `Pib typing ib txoj kev chaw nyob thiab ${cumulus} yuav sau nyob rau hauv tas`,
	ps: (cumulus) => `د سړک پته ټایپ کول پیل کړئ او ${cumulus} به په پاتې کې ډک شی`,
	pt: (cumulus) => `Comece a digitar um endereço e o ${cumulus} preencherá o resto`,
	'pt-pt': (cumulus) => `Comece a digitar um endereço e ${cumulus} preencherá o restante`,
	ru: (cumulus) => `Начните вводить адрес, а ${cumulus} заполнит остальное`,
	sm: (cumulus) => `Amata ona taina se tuatusi o le auala ${cumulus} le vaega o totoe`,
	so: (cumulus) => `Bilow makiinada cinwaan wadada ah iyo ${cumulus} wuxuu buuxin doonaa inta kale`,
	th: (cumulus) => `เริ่มพิมพ์ที่อยู่และ ${cumulus} จะกรอกส่วนที่เหลือ`,
	ur: (cumulus) => `گلی کا پتہ ٹائپ کرنا شروع ${cumulus} باقی کو بھر دے گا`,
	vi: (cumulus) => `Bắt đầu nhập địa chỉ đường phố và ${cumulus} sẽ điền vào phần còn lại`,
	'zh-hans': (cumulus) => `开始输入街道地址， ${cumulus} 将填写其余部分`,
	'zh-hant': (cumulus) => `開始輸入街道位址， ${cumulus} 將填寫其餘部分`,
	en: (cumulus) => `Start typing a street address and ${cumulus} will fill in the rest`
};

export const loc_enterAddressManually: Record<Locale, string> = {
	ar: `لا يمكنني العثور على العنوان أو أبحث عنه. أحتاج إلى إدخال العنوان يدويا.`,
	de: `Ich kann die Adresse nicht finden oder suche sie. Ich muss die Adresse manuell eingeben.`,
	es: `No encuentro la dirección que busco. Necesito ingresar la dirección manualmente.`,
	fa: `من نمی توانم آدرس را پیدا کنم یا به دنبال آن هستم. باید آدرس را به صورت دستی وارد کنم.`,
	fil: `Hindi ko makita ang address o hinahanap ko. Kailangan kong ipasok ang address nang manu mano.`,
	fr: `Je ne trouve pas l’adresse ou je la cherche. Je dois saisir l’adresse manuellement.`,
	hi: `मुझे वह पता नहीं मिल रहा है या मैं ढूंढ रहा हूँ. मुझे मैन्युअल रूप से पता दर्ज करने की आवश्यकता है।`,
	ht: `Mwen pa ka jwenn adrès la oswa mwen ap chèche pou. Mwen bezwen antre nan adrès la manyèlman.`,
	it: `Non riesco a trovare l'indirizzo o lo sto cercando. Devo inserire l'indirizzo manualmente.`,
	ja: `住所が見つからない、または探しています。住所を手動で入力する必要があります。`,
	ko: `주소를 찾을 수 없거나 찾고 있습니다. 주소를 수동으로 입력해야 합니다.`,
	mww: `Kuv nrhiav tsis tau qhov chaw nyob los yog kuv tab tom nrhiav. Kuv yuav tsum tau sau qhov chaw nyob manually.`,
	ps: `زه پته نشم موندلی یا زه په لټه کې یم. زه اړتیا لرم چې په لاسی ډول پته ته ننوځم.`,
	pt: `Não consigo encontrar o endereço ou estou procurando. Preciso inserir o endereço manualmente.`,
	'pt-pt': `Não consigo encontrar o endereço ou estou procurando. Preciso inserir o endereço manualmente.`,
	ru: `Я не могу найти адрес или ищу. Мне нужно ввести адрес вручную.`,
	sm: `E le mafai ona ou maua le tuatusi pe o ou sailia foi. E tatau ona ou ta i totonu le tuatusi.`,
	so: `Cinwaanka ma heli karo ama waan raadinayaa. Waxaan u baahanahay in aan cinwaanka ku soo galo gacanta.`,
	th: `ฉันไม่พบที่อยู่หรือฉันกําลังมองหา ฉันต้องป้อนที่อยู่ด้วยตนเอง`,
	ur: `مجھے پتہ نہیں مل رہا ہے یا میں تلاش کر رہا ہوں. مجھے دستی طور پر پتہ درج کرنے کی ضرورت ہے۔`,
	vi: `Tôi không thể tìm thấy địa chỉ hoặc tôi đang tìm kiếm. Tôi cần nhập địa chỉ theo cách thủ công.`,
	'zh-hans': `我找不到地址或我正在寻找。我需要手动输入地址。`,
	'zh-hant': `我找不到位址或我正在尋找。我需要手動輸入位址。`,
	en: `I can't find the address or I'm looking for. I need to enter the address manually.`
};

export const loc_addressDetails: Record<Locale, string> = {
	ar: `تفاصيل العنوان`,
	de: `Adressdaten`,
	es: `Detalles de la dirección`,
	fa: `جزئیات آدرس`,
	fil: `Mga detalye ng address`,
	fr: `Coordonnées`,
	hi: `पते का विवरण`,
	ht: `Detay adrès`,
	it: `Dettagli dell'indirizzo`,
	ja: `住所の詳細`,
	ko: `주소 세부 정보`,
	mww: `Hais kom paub meej`,
	ps: `د پتې سپړنې`,
	pt: `Detalhes do endereço`,
	'pt-pt': `Detalhes do endereço`,
	ru: `Адресная информация`,
	sm: `Faamatalaga auiliili o le tuatusi`,
	so: `Faahfaahinta cinwaanka`,
	th: `รายละเอียดที่อยู่`,
	ur: `ایڈریس کی تفصیلات`,
	vi: `Chi tiết địa chỉ`,
	'zh-hans': `地址详情`,
	'zh-hant': `地址詳情`,
	en: `Address details`
};

export const loc_contacts: Record<Locale, string> = {
	ar: `جهات الاتصال`,
	de: `Kontakte`,
	es: `Contactos`,
	fa: `مخاطبین`,
	fil: `Mga Contact`,
	fr: `Contacts`,
	hi: `संपर्क`,
	ht: `Kontak`,
	it: `Contatti`,
	ja: `連絡先`,
	ko: `연락처`,
	mww: `Kev sib cuag`,
	ps: `اړیکلوری`,
	pt: `Contatos`,
	'pt-pt': `Contatos`,
	ru: `Контакты`,
	sm: `Tagata Faafesootai`,
	so: `Xiriirada`,
	th: `ติดต่อ`,
	ur: `روابط`,
	vi: `Liên hệ`,
	'zh-hans': `接触`,
	'zh-hant': `接觸`,
	en: `Contacts`
};

export const loc_addContact: Record<Locale, string> = {
	ar: `إضافة جهة اتصال`,
	de: `Kontakt hinzufügen`,
	es: `Agregar contacto`,
	fa: `افزودن مخاطب`,
	fil: `Magdagdag ng contact`,
	fr: `Ajouter un contact`,
	hi: `संपर्क जोड़ें`,
	ht: `Ajoute kontak`,
	it: `Aggiungi contatto`,
	ja: `連絡先を追加`,
	ko: `연락처 추가`,
	mww: `Sib cuag tau`,
	ps: `اړیکلوری زیاتول`,
	pt: `Adicionar contato`,
	'pt-pt': `Adicionar contacto`,
	ru: `Добавить контакт`,
	sm: `Faaopoopo le tagata faafesootai`,
	so: `Ku dar xiriir`,
	th: `เพิ่มผู้ติดต่อ`,
	ur: `رابطہ شامل کریں`,
	vi: `Thêm liên hệ`,
	'zh-hans': `添加联系人`,
	'zh-hant': `添加連絡人`,
	en: `Add contact`
};

export const loc_removeContact: Record<Locale, string> = {
	ar: `إزالة جهة اتصال`,
	de: `Kontakt entfernen`,
	es: `Eliminar el contacto`,
	fa: `برداشتن مخاطب`,
	fil: `Alisin ang contact`,
	fr: `Supprimer le contact`,
	hi: `संपर्क हटाएं`,
	ht: `Retire kontak`,
	it: `Rimuovi contatto`,
	ja: `連絡先の削除`,
	ko: `연락처 제거`,
	mww: `Tshem tawm cov kev sib cuag`,
	ps: `اړیکلوری ړنګول`,
	pt: `Remover contato`,
	'pt-pt': `Remover contacto`,
	ru: `Удалить контакт`,
	sm: `Aveese le tagata faafesootai`,
	so: `Ka saar xiriir`,
	th: `ลบผู้ติดต่อ`,
	ur: `رابطہ ختم کریں`,
	vi: `Xóa liên hệ`,
	'zh-hans': `删除联系人`,
	'zh-hant': `刪除連絡人`,
	en: `Remove contact`
};

export const loc_thereAreNoContacts: Record<Locale, string> = {
	ar: `لا توجد جهات اتصال`,
	de: `Es gibt keine Kontakte`,
	es: `No hay contactos`,
	fa: `هیچ مخاطبی وجود ندارد`,
	fil: `Walang mga contact`,
	fr: `Il n’y a pas de contacts`,
	hi: `कोई संपर्क नहीं हैं`,
	ht: `Pa gen okenn kontak`,
	it: `Non ci sono contatti`,
	ja: `連絡先がありません`,
	ko: `연락처가 없습니다`,
	mww: `Tsis muaj kev sib cuag`,
	ps: `هیڅ اړیکه نشته`,
	pt: `Não há contatos`,
	'pt-pt': `Não existem contactos`,
	ru: `Нет контактов`,
	sm: `E leai ni tagata faafesootai`,
	so: `Ma jiraan xiriiro`,
	th: `ไม่มีผู้ติดต่อ`,
	ur: `کوئی رابطے نہیں ہیں`,
	vi: `Không có liên hệ`,
	'zh-hans': `没有联系人`,
	'zh-hant': `沒有聯繫人`,
	en: `There are no contacts`
};

export const loc_unknown: Record<Locale, string> = {
	ar: `مجهول`,
	de: `Unbekannt`,
	es: `Desconocido`,
	fa: `ناشناخته`,
	fil: `Hindi kilala`,
	fr: `Inconnu`,
	hi: `अप्रसिद्घ व्यक्ति`,
	ht: `Enkoni`,
	it: `Sconosciuto`,
	ja: `不明`,
	ko: `알려지지 않은`,
	mww: `tsis paub`,
	ps: `ناپېژندلی`,
	pt: `Desconhecido`,
	'pt-pt': `Desconhecido`,
	ru: `Неизвестный`,
	sm: `E le mailoa`,
	so: `Aan la garaneynin`,
	th: `ไม่คุ้นเคย`,
	ur: `نامعلوم`,
	vi: `Không biết`,
	'zh-hans': `未知`,
	'zh-hant': `未知`,
	en: `Unknown`
};

export const loc_primaryEmergencyContact: Record<Locale, string> = {
	ar: `جهة الاتصال الأساسية في حالات الطوارئ`,
	de: `Primärer Notfallkontakt`,
	es: `Contacto Primario de Emergencia`,
	fa: `تماس اضطراری اولیه`,
	fil: `Pangunahing Pang emergency na Kontak`,
	fr: `Personne-ressource principale en cas d’urgence`,
	hi: `प्राथमिक आपातकालीन संपर्क`,
	ht: `Kontak Ijans Prensipal`,
	it: `Contatto di emergenza primario`,
	ja: `主な緊急連絡先`,
	ko: `기본 비상 연락처`,
	mww: `Thawj Cov Kev Kub Ntxhov Hu Rau Tus Kheej`,
	ps: `لومړنۍ بیړنۍ اړیکه`,
	pt: `Contato de emergência primário`,
	'pt-pt': `Contacto de Emergência Principal`,
	ru: `Основное контактное лицо для связи в чрезвычайных ситуациях`,
	sm: `Fesootaiga Autu mo Faalavelave Faafuasei`,
	so: `Primary Emergency Contact`,
	th: `ผู้ติดต่อฉุกเฉินหลัก`,
	ur: `بنیادی ہنگامی رابطہ`,
	vi: `Liên hệ khẩn cấp chính`,
	'zh-hans': `主要紧急联系人`,
	'zh-hant': `主要緊急聯繫人`,
	en: `Primary Emergency Contact`
};

export const loc_emergencyContact: Record<Locale, string> = {
	ar: `الاتصال في حالات الطوارئ`,
	de: `Notfall-Kontakt`,
	es: `Contacto de emergencia`,
	fa: `تماس اضطراری`,
	fil: `Pang emergency na Kontak`,
	fr: `Contact d’urgence`,
	hi: `आपातकालीन संपर्क`,
	ht: `Kontak Ijans`,
	it: `Contatto di emergenza`,
	ja: `緊急連絡先`,
	ko: `비상 연락처`,
	mww: `Kev Kub Ntxhov Ceev`,
	ps: `عاجل تماس`,
	pt: `Contato de emergência`,
	'pt-pt': `Contacto de Emergência`,
	ru: `Контактная информация для экстренных случаев`,
	sm: `Fesootaiga mo Faalavelave Faafuasei`,
	so: `Xiriirka Gargaarka Degdega ah`,
	th: `ติดต่อฉุกเฉิน`,
	ur: `ہنگامی رابطہ`,
	vi: `Liên hệ khẩn cấp`,
	'zh-hans': `紧急联系人`,
	'zh-hant': `緊急聯繫人`,
	en: `Emergency Contact`
};

export const loc_employer: Record<Locale, string> = {
	ar: `مستخدم`,
	de: `Arbeitgeber`,
	es: `Empleador`,
	fa: `کارفرما`,
	fil: `Employer`,
	fr: `Employeur`,
	hi: `मालिक`,
	ht: `Anplwayè`,
	it: `Datore di lavoro`,
	ja: `雇い主`,
	ko: `고용주`,
	mww: `tswv num`,
	ps: `کارفرما`,
	pt: `Empregador`,
	'pt-pt': `Empregador`,
	ru: `Работодатель`,
	sm: `Pule`,
	so: `Shaqo-bixiyaha`,
	th: `นายจ้าง`,
	ur: `آجر`,
	vi: `Tuyển dụng`,
	'zh-hans': `雇主`,
	'zh-hant': `雇主`,
	en: `Employer`
};

export const loc_federalAgency: Record<Locale, string> = {
	ar: `الوكالة الاتحادية`,
	de: `Bundesagentur`,
	es: `Agencia Federal`,
	fa: `آژانس فدرال`,
	fil: `Pederal na Ahensya`,
	fr: `Agence fédérale`,
	hi: `संघीय एजेंसी`,
	ht: `Ajans federal`,
	it: `Agenzia federale`,
	ja: `連邦政府機関`,
	ko: `연방 기관`,
	mww: `Tsoom fwv teb chaws cov chaw ua hauj`,
	ps: `فدرالی اداره`,
	pt: `Agência Federal`,
	'pt-pt': `Agência Federal`,
	ru: `Федеральное агентство`,
	sm: `Faitalia a le Malo`,
	so: `Hay'adda Federaalka`,
	th: `หน่วยงานของรัฐบาลกลาง`,
	ur: `وفاقی ایجنسی`,
	vi: `Cơ quan liên bang`,
	'zh-hans': `联邦机构`,
	'zh-hant': `聯邦機構`,
	en: `Federal Agency`
};

export const loc_insuranceCompany: Record<Locale, string> = {
	ar: `شركة تأمين`,
	de: `Versicherungsgesellschaft`,
	es: `Compañía de seguros`,
	fa: `شرکت بیمه`,
	fil: `Kompanya ng Seguro`,
	fr: `Compagnie d’assurances`,
	hi: `बीमा कंपनी`,
	ht: `Konpayi asirans`,
	it: `Compagnia di assicurazioni`,
	ja: `保険会社`,
	ko: `보험 회사`,
	mww: `Tuam txhab tuav pov hwm`,
	ps: `د بیمې شرکت`,
	pt: `Seguradora`,
	'pt-pt': `Companhia de Seguros`,
	ru: `Страховая компания`,
	sm: `Kamupani Inisiua`,
	so: `Shirkadda caymiska`,
	th: `บริษัทประกันภัย`,
	ur: `انشورنس کمپنی`,
	vi: `Công ty bảo hiểm`,
	'zh-hans': `保险公司`,
	'zh-hant': `保險公司`,
	en: `Insurance Company`
};

export const loc_nextOfKin: Record<Locale, string> = {
	ar: `أقرب الأقارب`,
	de: `Angehörige`,
	es: `Pariente más cercano`,
	fa: `خویشاوندان بعدی`,
	fil: `Susunod na Kamag anak`,
	fr: `Proches parents`,
	hi: `नेक्स्ट-ऑफ-किन`,
	ht: `pwochen-of-Kin`,
	it: `Parente prossimo`,
	ja: `近親者`,
	ko: `가까운 친척`,
	mww: `Tom ntej-ntawm-Kin`,
	ps: `د Kin`,
	pt: `Parentes próximos`,
	'pt-pt': `Parentes mais próximos`,
	ru: `Ближайшие родственники`,
	sm: `Tagata o le Aiga e Sosoo Ai`,
	so: `Next-of-Kin`,
	th: `ญาติคนใกล้ชิด`,
	ur: `رشتہ داروں کے اگلے افراد`,
	vi: `Thân nhân tiếp theo`,
	'zh-hans': `近亲`,
	'zh-hant': `近親`,
	en: `Next-of-Kin`
};

export const loc_stateAgency: Record<Locale, string> = {
	ar: `وكالة الدولة`,
	de: `Staatliche Agentur`,
	es: `Agencia Estatal`,
	fa: `آژانس دولتی`,
	fil: `Ahensya ng Estado`,
	fr: `Agence de l’État`,
	hi: `राज्य एजेंसी`,
	ht: `Ajans leta`,
	it: `Agenzia statale`,
	ja: `国家機関`,
	ko: `국가 기관`,
	mww: `Xeev Lub Xeev Qhov Chaw Ua Hauj Lwm`,
	ps: `دولتی اداره`,
	pt: `Agência Estadual`,
	'pt-pt': `Agência do Estado`,
	ru: `Государственное агентство`,
	sm: `Faitalia a le Setete`,
	so: `Hay'adda Gobolka`,
	th: `หน่วยงานของรัฐ`,
	ur: `ریاستی ایجنسی`,
	vi: `Cơ quan nhà nước`,
	'zh-hans': `国家机构`,
	'zh-hant': `國家機構`,
	en: `State Agency`
};

export const loc_description: Record<Locale, string> = {
	ar: `وصف`,
	de: `Beschreibung`,
	es: `Descripción`,
	fa: `توضیحات`,
	fil: `Paglalarawan`,
	fr: `Description`,
	hi: `या क़िस्‍म`,
	ht: `Deskripsyon`,
	it: `Descrizione`,
	ja: `形容`,
	ko: `묘사`,
	mww: `piav qhia`,
	ps: `سپړاوی`,
	pt: `Descrição`,
	'pt-pt': `Descrição`,
	ru: `Описание`,
	sm: `Faamatalaga`,
	so: `Tilmaan`,
	th: `คำอธิบาย`,
	ur: `بیان`,
	vi: `Sự miêu tả`,
	'zh-hans': `描述`,
	'zh-hant': `描述`,
	en: `Description`
};

export const loc_moreORGProgramsFormat: Record<Locale, (org: unknown) => TemplateResult> = {
	ar: (org) => html`<span>المزيد من برامج ${org}</span>`,
	de: (org) => html`<span>Weitere ${org}</span>`,
	es: (org) => html`<span>Más programas ${org}</span>`,
	fa: (org) => html`<span>برنامه های ${org} بیشتر</span>`,
	fil: (org) => html`<span>Higit pang mga programa ng ${org}</span>`,
	fr: (org) => html`<span>Plus ${org}</span>`,
	hi: (org) => html`<span>अधिक ${org} कार्यक्रम</span>`,
	ht: (org) => html`<span>Plis pwogram ${org}</span>`,
	it: (org) => html`<span>Altri programmi ${org}</span>`,
	ja: (org) => html`<span>その他の ${org} プログラム</span>`,
	ko: (org) => html`<span>더 많은 ${org} 프로그램</span>`,
	mww: (org) => html`<span>NcoA cov kev pab cuam ${org}</span>`,
	ps: (org) => html`<span>د ${org} نور پروګرامونه</span>`,
	pt: (org) => html`<span>Mais programas ${org}</span>`,
	'pt-pt': (org) => html`<span>Mais programas ${org}</span>`,
	ru: (org) => html`<span>Другие программы ${org}</span>`,
	sm: (org) => html`<span>Isi polokalama ${org}</span>`,
	so: (org) => html`<span>Barnaamijyada ${org}</span>`,
	th: (org) => html`<span>โปรแกรม ${org} เพิ่มเติม</span>`,
	ur: (org) => html`<span>${org} پروگرام</span>`,
	vi: (org) => html`<span>Các chương trình ${org} khác</span>`,
	'zh-hans': (org) => html`<span>更多 ${org} 计划</span>`,
	'zh-hant': (org) => html`<span>更多 ${org} 計劃</span>`,
	en: (org) => html`<span>More ${org} programs</span>`
};

export const loc_releaseNotes: Record<Locale, string> = {
	ar: `ملاحظات الإصدار`,
	de: `Versionshinweise`,
	es: `Notas`,
	fa: `یادداشت های انتشار`,
	fil: `Mga tala ng paglabas`,
	fr: `Notes de mise à jour`,
	hi: `रिलीज नोट्स`,
	ht: `Liberasyon nòt yo`,
	it: `Note di rilascio`,
	ja: `リリースノート`,
	ko: `릴리즈 노트`,
	mww: `Tso tawm cov ntawv ceeb toom`,
	ps: `د خوشې کولو یادښتونه`,
	pt: `Notas de versão`,
	'pt-pt': `Notas de versão`,
	ru: `Примечания к выпуску`,
	sm: `Faamatalaga o le faalauiloaga`,
	so: `Qoraalada Release`,
	th: `บันทึกประจํารุ่น`,
	ur: `نوٹ جاری کریں`,
	vi: `Ghi chú phát hành`,
	'zh-hans': `发行说明`,
	'zh-hant': `發行說明`,
	en: `Release notes`
};

export const loc_interoperability: Record<Locale, string> = {
	ar: `قابلية التشغيل البيني`,
	de: `Interoperabilität`,
	es: `Interoperabilidad`,
	fa: `قابلیت همکاری`,
	fil: `Interoperability`,
	fr: `Interopérabilité`,
	hi: `इंटरोऑपरेबिलिटी`,
	ht: `Ki pa operasyon`,
	it: `Interoperabilità`,
	ja: `相互運用性`,
	ko: `상호 운용성`,
	mww: `interoperability`,
	ps: `Interoperability`,
	pt: `Interoperabilidade`,
	'pt-pt': `Interoperabilidade`,
	ru: `Функциональная совместимость`,
	sm: `Fesootaiga`,
	so: `Interoperability`,
	th: `การทํางานร่วมกัน`,
	ur: `Interoperability`,
	vi: `Khả năng tương tác`,
	'zh-hans': `互操作性`,
	'zh-hant': `互操作性`,
	en: `Interoperability`
};

export const loc_product: Record<Locale, string> = {
	ar: `حاصل الضرب`,
	de: `Produkt`,
	es: `Producto`,
	fa: `محصول`,
	fil: `Produkto`,
	fr: `Produit`,
	hi: `गुणनफल`,
	ht: `Pwodwi`,
	it: `Prodotto`,
	ja: `積`,
	ko: `제품`,
	mww: `Khoom`,
	ps: `د محصول د`,
	pt: `Produto`,
	'pt-pt': `Produto`,
	ru: `Продукт`,
	sm: `Oloa`,
	so: `Product`,
	th: `ผลิตภัณฑ์`,
	ur: `حاصل ضرب`,
	vi: `Sản phẩm`,
	'zh-hans': `产品`,
	'zh-hant': `產品`,
	en: `Product`
};

export const loc_company: Record<Locale, string> = {
	ar: `شركة`,
	de: `Firma`,
	es: `Compañía`,
	fa: `شرکت`,
	fil: `Kumpanya`,
	fr: `Compagnie`,
	hi: `अतिथि`,
	ht: `Konpayi`,
	it: `Società`,
	ja: `会社`,
	ko: `회사`,
	mww: `tuam txhab`,
	ps: `شرکت`,
	pt: `Companhia`,
	'pt-pt': `Companhia`,
	ru: `Компания`,
	sm: `Kamupani`,
	so: `Company`,
	th: `บริษัท`,
	ur: `کمپنی`,
	vi: `Công ty`,
	'zh-hans': `公司`,
	'zh-hant': `公司`,
	en: `Company`
};

export const loc_siteWideLinks: Record<Locale, string> = {
	ar: `روابط على مستوى الموقع`,
	de: `Links auf der gesamten Website`,
	es: `Enlaces a todo el sitio`,
	fa: `لینک های گسترده سایت`,
	fil: `Mga link sa buong site`,
	fr: `Liens à l’échelle du site`,
	hi: `साइट-व्यापी लिंक`,
	ht: `lyen site-lajè`,
	it: `Collegamenti a livello di sito`,
	ja: `サイト全体のリンク`,
	ko: `사이트 전체 링크`,
	mww: `Site-wide links`,
	ps: `د سایټ پراخه لینکونه`,
	pt: `Links para todo o site`,
	'pt-pt': `Links para todo o site`,
	ru: `Ссылки на весь сайт`,
	sm: `Sootaga i le saite atoa`,
	so: `Site-ballaaran links`,
	th: `ลิงก์ทั่วทั้งเว็บไซต์`,
	ur: `سائٹ-وسیع لنکس`,
	vi: `Liên kết trên toàn trang web`,
	'zh-hans': `站点范围的链接`,
	'zh-hant': `網站範圍的連結`,
	en: `Site-wide links`
};

export const loc_learnMore: Record<Locale, string> = {
	ar: `التعرف على المزيد`,
	de: `Weitere Informationen`,
	es: `Aprende más`,
	fa: `بیشتر بدانید`,
	fil: `Alamin ang higit pa`,
	fr: `Pour en savoir plus`,
	hi: `और जानो`,
	ht: `Aprann plis`,
	it: `Ulteriori informazioni`,
	ja: `詳細情報`,
	ko: `더 알아보세요`,
	mww: `Kawm ntxiv`,
	ps: `نور زده کړئ`,
	pt: `Saiba Mais`,
	'pt-pt': `Saiba mais`,
	ru: `Подробнее`,
	sm: `Su'esu'e atili`,
	so: `Waxbadan baro`,
	th: `ศึกษาเพิ่มเติม`,
	ur: `اور سيکھو`,
	vi: `Tìm hiểu thêm`,
	'zh-hans': `了解更多信息`,
	'zh-hant': `瞭解更多資訊`,
	en: `Learn more`
};

export const loc_sockMarketingHeading: Record<Locale, (cumulus: unknown) => string> = {
	ar: (cumulus) => `ما الذي يمكنك استخدام ${cumulus} من أجله أيضا؟`,
	de: (cumulus) => `Wofür können Sie ${cumulus} sonst noch verwenden?`,
	es: (cumulus) => `¿Para qué más podrías usar ${cumulus} ?`,
	fa: (cumulus) => `برای چه چیز دیگری می توانید از ${cumulus} استفاده کنید؟`,
	fil: (cumulus) => `Para saan mo pa magagamit ang ${cumulus}`,
	fr: (cumulus) => `À quoi d’autre pourriez-vous utiliser ${cumulus} ?`,
	hi: (cumulus) => `आप ${cumulus} के लिए उपयोग कर सकते हैं?`,
	ht: (cumulus) => `Ki lòt bagay ou ta ka itilize ${cumulus} pou?`,
	it: (cumulus) => `Per cos'altro potresti usare ${cumulus} ?`,
	ja: (cumulus) => `${cumulus} は他に何に使用できますか?`,
	ko: (cumulus) => `${cumulus}를 다른 어떤 용도로 사용할 수 있습니까?`,
	mww: (cumulus) => `Dab tsi ntxiv koj yuav siv ${cumulus} rau?`,
	ps: (cumulus) => `تاسو د کوم لپاره کولی شئ ${cumulus} وکاروئ؟`,
	pt: (cumulus) => `Para que mais você poderia usar o ${cumulus} ?`,
	'pt-pt': (cumulus) => `Para que mais você poderia usar ${cumulus} ?`,
	ru: (cumulus) => `Для чего еще можно использовать ${cumulus} ?`,
	sm: (cumulus) => `O le a se isi mea e mafai ona e faaaogaina ${cumulus} ?`,
	so: (cumulus) => `Maxaa kale oo aad u isticmaali kartaa ${cumulus} ?`,
	th: (cumulus) => `คุณสามารถใช้ ${cumulus} ทําอะไรได้อีกบ้าง?`,
	ur: (cumulus) => `آپ کمولس کو اور کس چیز کے لئے استعمال ${cumulus} ؟`,
	vi: (cumulus) => `Bạn có thể sử dụng ${cumulus} để làm gì khác?`,
	'zh-hans': (cumulus) => `您还可以使用 ${cumulus} 做什么？`,
	'zh-hant': (cumulus) => `您還可以使用 ${cumulus} 做什麼？`,
	en: (cumulus) => `What else could you use ${cumulus} for?`
};

export const loc_sockMarketingBody: Record<Locale, (cumulus: unknown) => string> = {
	ar: (cumulus) => `قم بتوصيل الشركاء والفرق ومشاركة المعلومات وإدارة العملاء وإجراء التقييمات وإجراء الإحالات وتتبع الأحداث والمزيد. استبدل جداول البيانات والورق ورسائل البريد الإلكتروني بمهام سير عمل ${cumulus} والنماذج الآلية والاستطلاعات والمهام.`,
	de: (cumulus) => `Verbinden Sie Partner und Teams, tauschen Sie Informationen aus, verwalten Sie Kunden, führen Sie Bewertungen durch, geben Sie Empfehlungen ab, verfolgen Sie Veranstaltungen und vieles mehr. Ersetzen Sie Tabellenkalkulationen, Papier und E-Mails durch ${cumulus} , automatisierte Formulare, Umfragen und Aufgaben.`,
	es: (cumulus) => `Conecte socios y equipos, comparta información, administre clientes, realice evaluaciones, haga referencias, realice un seguimiento de eventos y más. Reemplace las hojas de cálculo, el papel y los correos electrónicos con flujos de trabajo, formularios automatizados, encuestas y tareas de ${cumulus} .`,
	fa: (cumulus) => `شرکا و تیم ها را به هم متصل کنید، اطلاعات را به اشتراک بگذارید، مشتریان را مدیریت کنید، ارزیابی ها را انجام دهید، ارجاع دهید، رویدادها را پیگیری کنید و موارد دیگر. صفحات گسترده، کاغذ و ایمیل ها را با گردش کار ${cumulus} ، فرم های خودکار، نظرسنجی ها و وظایف جایگزین کنید.`,
	fil: (cumulus) => `Ikonekta ang mga kasosyo at koponan, magbahagi ng impormasyon, pamahalaan ang mga kliyente, magsagawa ng mga pagtatasa, gumawa ng mga referral, subaybayan ang mga kaganapan, at marami pa. Palitan ang mga spreadsheet, papel, at email sa mga daloy ng trabaho ${cumulus} , awtomatikong mga form, survey, at gawain.`,
	fr: (cumulus) => `Connectez les partenaires et les équipes, partagez des informations, gérez des clients, effectuez des évaluations, effectuez des références, suivez des événements, etc. Remplacez les feuilles de calcul, le papier et les e-mails par des flux de travail ${cumulus} , des formulaires automatisés, des enquêtes et des tâches.`,
	hi: (cumulus) => `भागीदारों और टीमों को कनेक्ट करें, जानकारी साझा करें, ग्राहकों का प्रबंधन करें, आकलन करें, रेफरल बनाएं, घटनाओं को ट्रैक करें, और बहुत कुछ। स्प्रेडशीट, काग़ज़ और ईमेल को ${cumulus} वर्कफ़्लोज़, स्वचालित प्रपत्र, सर्वेक्षण और कार्यों से बदलें.`,
	ht: (cumulus) => `Patnè konekte ak ekip, pataje enfòmasyon, jere kliyan, evalyasyon kondwit, fè referans, evènman swiv, ak plis ankò. Ranplase pwopagasyon, papye, ak Imèl ak ${cumulus} , fòm otomatik, sondaj, ak travay.`,
	it: (cumulus) => `Connetti partner e team, condividi informazioni, gestisci i clienti, conduci valutazioni, fai referenze, monitora gli eventi e altro ancora. Sostituisci fogli di calcolo, carta ed e-mail con i flussi di lavoro, i moduli automatizzati, i sondaggi e le attività ${cumulus} .`,
	ja: (cumulus) => `パートナーとチームの接続、情報の共有、クライアントの管理、評価の実施、紹介、イベントの追跡などを行います。スプレッドシート、紙、メールを ${cumulus} ワークフロー、自動フォーム、アンケート、タスクに置き換えます。`,
	ko: (cumulus) => `파트너와 팀을 연결하고, 정보를 공유하고, 고객을 관리하고, 평가를 수행하고, 추천을 하고, 이벤트를 추적하는 등의 작업을 수행할 수 있습니다. 스프레드시트, 종이, 이메일을 ${cumulus} 워크플로, 자동화된 양식, 설문조사 및 작업으로 대체하세요.`,
	mww: (cumulus) => `Txuas nrog ib tug neeg thiab paab, muab cov lus qhia, tswj cov neeg tau, kev soj ntsuam, ua referrals, khiav txheej xwm, thiab ntau dua. Hloov kis tau, ntawv, thiab emails nrog ${cumulus} workflows, automated ntaub ntawv, kev sojntsuam, thiab cov hauj lwm.`,
	ps: (cumulus) => `شریکان او ټیمونه وصل کړئ ، معلومات شریک کړئ ، پیرودونکی اداره کړئ ، ارزونې ترسره کړئ ، راجع کول ، پیښې تعقیب کړئ ، او نور ډیر څه. سپریډ شیټونه ، کاغذونه ، او بریښنالیکونه د ${cumulus} کاری فلو ، اتومات فارمونو ، سروې ، او دندو سره ځای په ځای کړئ.`,
	pt: (cumulus) => `Conecte parceiros e equipes, compartilhe informações, gerencie clientes, conduza avaliações, faça referências, rastreie eventos e muito mais. Substitua planilhas, papéis e e-mails por fluxos de trabalho, formulários automatizados, pesquisas e tarefas do ${cumulus} .`,
	'pt-pt': (cumulus) => `Conecte parceiros e equipes, compartilhe informações, gerencie clientes, realize avaliações, faça indicações, acompanhe eventos e muito mais. Substitua planilhas, papel e e-mails por fluxos de trabalho ${cumulus} , formulários automatizados, pesquisas e tarefas.`,
	ru: (cumulus) => `Подключайте партнеров и команды, обменивайтесь информацией, управляйте клиентами, проводите оценки, делайте рекомендации, отслеживайте события и многое другое. Замените электронные таблицы, бумагу и электронную почту рабочими процессами ${cumulus} , автоматизированными формами, опросами и задачами.`,
	sm: (cumulus) => `Faafesootai paaga ma au, faasoa atu faamatalaga, pulea tagata e faaaogaina, faatautaia faavasegaga, faia o faailoaga, siaki mea e tutupu, ma isi mea. Sui pepa o faamatalaga, pepa, ma imeli i faiga o galuega ${cumulus} , fomu otometi, sailiiliga, ma galuega.`,
	so: (cumulus) => `Isku xirka la-hawlgalayaasha iyo kooxaha, macluumaadka wadaagaan, maareeyaan macaamiisha, qiimaynta la sameeyo, samee gudbinta, dhacdooyinka track, iyo in ka badan. Badal waraaqaha waraaqaha, warqad, iyo emails socodka shaqada ${cumulus} , foomamka automated, sahan, iyo hawlaha.`,
	th: (cumulus) => `เชื่อมต่อพันธมิตรและทีม แบ่งปันข้อมูล จัดการลูกค้า ทําการประเมิน การอ้างอิง ติดตามเหตุการณ์ และอื่นๆ แทนที่สเปรดชีต กระดาษ และอีเมลด้วยเวิร์กโฟลว์ ${cumulus} แบบฟอร์มอัตโนมัติ แบบสํารวจ และงาน`,
	ur: (cumulus) => `شراکت داروں اور ٹیموں کو مربوط کریں ، معلومات کا اشتراک کریں ، گاہکوں کا انتظام کریں ، تشخیص کریں ، حوالہ جات بنائیں ، واقعات کو ٹریک کریں ، اور بہت کچھ۔ اسپریڈ شیٹس ، کاغذ ، اور ای میلز ${cumulus} ورک فلوز ، خودکار فارم ، سروے اور کاموں کے ساتھ تبدیل کریں۔`,
	vi: (cumulus) => `Kết nối các đối tác và nhóm, chia sẻ thông tin, quản lý khách hàng, tiến hành đánh giá, giới thiệu, theo dõi sự kiện và hơn thế nữa. Thay thế bảng tính, giấy và email bằng quy trình làm việc ${cumulus} , biểu mẫu, khảo sát và nhiệm vụ tự động.`,
	'zh-hans': (cumulus) => `连接合作伙伴和团队、共享信息、管理客户、进行评估、进行推荐、跟踪事件等。将电子表格、纸张和电子邮件替换为 ${cumulus} 工作流、自动化表单、调查和任务。`,
	'zh-hant': (cumulus) => `連接合作夥伴和團隊、共用資訊、管理客戶、進行評估、進行推薦、跟蹤事件等。將電子錶格、紙張和電子郵件替換為 ${cumulus} 工作流、自動化表單、調查和任務。`,
	en: (cumulus) => `Connect partners and teams, share information, manage clients, conduct assessments, make referrals, track events, and more. Replace spreadsheets, paper, and emails with ${cumulus} workflows, automated forms, surveys, and tasks.`
};

export const loc_sidebarMarketingFormat: Record<Locale, (cumulus: unknown, org: unknown) => TemplateResult> = {
	ar: (cumulus, org) => html`<span>هل تريد استخدام ${cumulus} لبرامج ${org} الأخرى؟</span>`,
	de: (cumulus, org) => html`<span>Möchten Sie ${cumulus} auch für andere ${org} nutzen?</span>`,
	es: (cumulus, org) => html`<span>¿Desea usar ${cumulus} para otros programas ${org} ?</span>`,
	fa: (cumulus, org) => html`<span>آیا می خواهید از ${cumulus} برای سایر برنامه ${org} استفاده کنید؟</span>`,
	fil: (cumulus, org) => html`<span>Gusto mo bang gamitin ang ${cumulus} para sa iba pang mga programa ng ${org} ?</span>`,
	fr: (cumulus, org) => html`<span>Vous souhaitez utiliser ${cumulus} pour d’autres programmes ${org} ?</span>`,
	hi: (org, cumulus) => html`<span>अन्य ${org} कार्यक्रमों के लिए ${cumulus} का उपयोग करना चाहते हैं?</span>`,
	ht: (cumulus, org) => html`<span>Vle itilize ${cumulus} pou lòt pwogram ${org} ?</span>`,
	it: (cumulus, org) => html`<span>Vuoi utilizzare ${cumulus} per altri programmi ${org} ?</span>`,
	ja: (cumulus, org) => html`<span>${cumulus} を他の ${org} プログラムに使用したいですか?</span>`,
	ko: (org, cumulus) => html`<span>다른 ${org} 프로그램에 ${cumulus}를 사용하고 싶으신가요?</span>`,
	mww: (cumulus, org) => html`<span>Xav siv ${cumulus} rau lwm ${org} cov kev pab cuam?</span>`,
	ps: (org, cumulus) => html`<span>ایا تاسو غواړئ د ${org} نورو برنامو لپاره ${cumulus} وکاروئ؟</span>`,
	pt: (cumulus, org) => html`<span>Quer usar o ${cumulus} para outros programas ${org} ?</span>`,
	'pt-pt': (cumulus, org) => html`<span>Quer usar o ${cumulus} para outros programas ${org} ?</span>`,
	ru: (cumulus, org) => html`<span>Хотите использовать ${cumulus} для других программ ${org} ?</span>`,
	sm: (cumulus, org) => html`<span>E te manao e faaaoga ${cumulus} mo isi polokalama ${org} ?</span>`,
	so: (cumulus, org) => html`<span>Ma dooneysaa inaad ${cumulus} u isticmaasho barnaamijyada kale ${org} ?</span>`,
	th: (cumulus, org) => html`<span>ต้องการใช้ ${cumulus} สําหรับโปรแกรม ${org} อื่น ๆ หรือไม่?</span>`,
	ur: (org, cumulus) => html`<span>دوسرے ${org} ${cumulus} استعمال کرنا چاہتے ہیں؟</span>`,
	vi: (cumulus, org) => html`<span>Bạn muốn sử dụng ${cumulus} cho các chương trình ${org} khác?</span>`,
	'zh-hans': (cumulus, org) => html`<span>想要将 ${cumulus} 用于其他 ${org} 计划？</span>`,
	'zh-hant': (cumulus, org) => html`<span>想要將 ${cumulus} 用於其他 ${org} 計劃？</span>`,
	en: (cumulus, org) => html`<span>Want to use ${cumulus} for other ${org} programs?</span>`
};

export const loc_editDemographics: Record<Locale, string> = {
	ar: `تعديل الخصائص الديمغرافية`,
	de: `Demografische Daten bearbeiten`,
	es: `Editar datos demográficos`,
	fa: `ویرایش جمعیت شناسی`,
	fil: `I-edit ang mga demograpiko`,
	fr: `Modifier les données démographiques`,
	hi: `जनसांख्यिकी संपादित करें`,
	ht: `Edit demografik`,
	it: `Modifica i dati demografici`,
	ja: `人口統計の編集`,
	ko: `인구 통계 편집`,
	mww: `Edit demographics`,
	ps: `ډیموګرافیک سمول`,
	pt: `Editar dados demográficos`,
	'pt-pt': `Editar dados demográficos`,
	ru: `Редактирование демографических данных`,
	sm: `Faasa'o le faitau aofai o tagata`,
	so: `Edit dadka`,
	th: `แก้ไขข้อมูลประชากร`,
	ur: `ڈیموگرافکس میں ترمیم کریں`,
	vi: `Chỉnh sửa nhân khẩu học`,
	'zh-hans': `编辑受众特征`,
	'zh-hant': `編輯受眾特徵`,
	en: `Edit demographics`
};

export const loc_gender: Record<Locale, string> = {
	ar: `جنس`,
	de: `Geschlecht`,
	es: `Género`,
	fa: `جنسیت`,
	fil: `Kasarian`,
	fr: `Genre`,
	hi: `लिंग`,
	ht: `Sèks`,
	it: `Genere`,
	ja: `ジェンダー`,
	ko: `성별`,
	mww: `tub los ntxhais`,
	ps: `جنسیت`,
	pt: `Gênero`,
	'pt-pt': `Género`,
	ru: `Род`,
	sm: `Itupa`,
	so: `Jinsiga`,
	th: `เพศ`,
	ur: `جنس`,
	vi: `Giống`,
	'zh-hans': `性`,
	'zh-hant': `性`,
	en: `Gender`
};

export const loc_dob: Record<Locale, string> = {
	ar: `دوب`,
	de: `ROHDIAMANT`,
	es: `DOB`,
	fa: `تاریخ نگارش`,
	fil: `DOB`,
	fr: `DOB`,
	hi: `जन्म तिथि`,
	ht: `DOB`,
	it: `DOB`,
	ja: `生年月日`,
	ko: `생년월일`,
	mww: `DOB`,
	ps: `DOB`,
	pt: `DOB`,
	'pt-pt': `Data de nascimento`,
	ru: `Дата рождения`,
	sm: `DOB`,
	so: `DOB`,
	th: `วันเกิด`,
	ur: `DOB`,
	vi: `DOB`,
	'zh-hans': `出生日期`,
	'zh-hant': `出生日期`,
	en: `DOB`
};

export const loc_ethnicity: Record<Locale, string> = {
	ar: `العرق`,
	de: `Ethnizität`,
	es: `Origen étnico`,
	fa: `قومیت`,
	fil: `Lahing Etniko`,
	fr: `Ethnicité`,
	hi: `जातीयता`,
	ht: `Etnisite`,
	it: `Etnicità`,
	ja: `エスニシティ`,
	ko: `민족성`,
	mww: `Haiv neeg tsawg`,
	ps: `قومیت`,
	pt: `Etnia`,
	'pt-pt': `Etnicidade`,
	ru: `Этнос`,
	sm: `Ituaiga`,
	so: `Ethnicity`,
	th: `เชื้อชาติ`,
	ur: `نسلی تفریق`,
	vi: `Sắc tộc`,
	'zh-hans': `种族`,
	'zh-hant': `種族`,
	en: `Ethnicity`
};

export const loc_race: Record<Locale, string> = {
	ar: `سباق`,
	de: `Rennen`,
	es: `Raza`,
	fa: `مسابقه`,
	fil: `lahi`,
	fr: `Course`,
	hi: `जाति`,
	ht: `kous`,
	it: `Razza`,
	ja: `競走`,
	ko: `인종`,
	mww: `haiv neeg`,
	ps: `نسل`,
	pt: `Raça`,
	'pt-pt': `Raça`,
	ru: `Раса`,
	sm: `Ituaiga`,
	so: `Race`,
	th: `แข่ง`,
	ur: `نسل`,
	vi: `Chủng tộc`,
	'zh-hans': `比赛`,
	'zh-hant': `比賽`,
	en: `Race`
};

export const loc_sexualOrientation: Record<Locale, string> = {
	ar: `التوجه الجنسي`,
	de: `Sexuelle Orientierung`,
	es: `Orientación sexual`,
	fa: `گرایش جنسی`,
	fil: `Sekswal na oryentasyon`,
	fr: `Orientation sexuelle`,
	hi: `सेक्शुअल ओरिएंटेशन`,
	ht: `Annaryè seksyèl`,
	it: `Orientamento sessuale`,
	ja: `性的指向`,
	ko: `성적 지향`,
	mww: `kev ua niam txiv orientation`,
	ps: `جنسی لېوالتیا`,
	pt: `Orientação sexual`,
	'pt-pt': `Orientação sexual`,
	ru: `Сексуальная ориентация`,
	sm: `Aoaoga faamasani faalefeusuaiga`,
	so: `Jihada galmada`,
	th: `รสนิยมทางเพศ`,
	ur: `جنسی رجحان`,
	vi: `Xu hướng tình dục`,
	'zh-hans': `性取向`,
	'zh-hant': `性取向`,
	en: `Sexual orientation`
};

export const loc_primaryLanguage: Record<Locale, string> = {
	ar: `اللغة الأساسية`,
	de: `Primäre Sprache`,
	es: `Lengua materna`,
	fa: `زبان اصلی`,
	fil: `Pangunahing wika`,
	fr: `Langue principale`,
	hi: `प्राथमिक भाषा`,
	ht: `Lang prensipal`,
	it: `Lingua principale`,
	ja: `主要言語`,
	ko: `기본 언어`,
	mww: `Hom lus ua ntej`,
	ps: `زبان اصلی`,
	pt: `Língua principal`,
	'pt-pt': `Língua principal`,
	ru: `Основной язык`,
	sm: `Gagana autu`,
	so: `Luqada aasaasiga ah`,
	th: `ภาษาหลัก`,
	ur: `بنیادی زبان`,
	vi: `Ngôn ngữ chính`,
	'zh-hans': `主要语言`,
	'zh-hant': `主要語言`,
	en: `Primary language`
};

export const loc_maritalStatus: Record<Locale, string> = {
	ar: `الحالة الاجتماعية`,
	de: `Familienstand`,
	es: `Estado civil`,
	fa: `وضعیت تأهل`,
	fil: `Katayuan sa pag aasawa`,
	fr: `Situation de famille`,
	hi: `वैवाहिक स्थिति`,
	ht: `Eta sivil, kondisyon matris`,
	it: `Stato civile`,
	ja: `婚姻状況`,
	ko: `결혼 여부`,
	mww: `Cuab yeej ob leeg tsim muaj`,
	ps: `مدنی حالت`,
	pt: `Estado civil`,
	'pt-pt': `Estado civil`,
	ru: `Семейное положение`,
	sm: `Tulaga faale-faaipoipoga`,
	so: `Xaaladda guurka`,
	th: `สถานภาพการสมรส`,
	ur: `ازدواجی حیثیت`,
	vi: `Tình trạng hôn nhân`,
	'zh-hans': `婚姻状况`,
	'zh-hant': `婚姻狀況`,
	en: `Marital status`
};

export const loc_ssn: Record<Locale, string> = {
	ar: `إس إن`,
	de: `SSN`,
	es: `SSN`,
	fa: `Ssn`,
	fil: `SSN`,
	fr: `SSN`,
	hi: `एसएसएन`,
	ht: `oblije`,
	it: `SSN`,
	ja: `SSNの`,
	ko: `증권 시세 표시기`,
	mww: `SSN`,
	ps: `SSN`,
	pt: `SSN`,
	'pt-pt': `SSN`,
	ru: `ССН`,
	sm: `NPF`,
	so: `SSN`,
	th: `เอสเอสเอ็น`,
	ur: `SSN`,
	vi: `SSN`,
	'zh-hans': `SSN`,
	'zh-hant': `SSN`,
	en: `SSN`
};

export const loc_medicaidID: Record<Locale, string> = {
	ar: `معرف ميديكيد`,
	de: `Medicaid-Kennung`,
	es: `Identificación de Medicaid`,
	fa: `شناسه Medicaid`,
	fil: `Medicaid ID`,
	fr: `Identifiant Medicaid`,
	hi: `मेडिकेड आईडी`,
	ht: `Medicaid ID`,
	it: `Medicaid ID`,
	ja: `メディケイドID`,
	ko: `메디케이드 ID`,
	mww: `Medicaid ID`,
	ps: `د درملو ID`,
	pt: `Medicaid ID`,
	'pt-pt': `Medicaid ID`,
	ru: `Идентификатор Medicaid`,
	sm: `Medicaid ID`,
	so: `Medicaid ID`,
	th: `รหัสเมดิเคด`,
	ur: `Medicaid ID`,
	vi: `Medicaid ID`,
	'zh-hans': `医疗补助 ID`,
	'zh-hant': `醫療補助ID`,
	en: `Medicaid ID`
};

export const loc_veteranID: Record<Locale, string> = {
	ar: `معرف المخضرم`,
	de: `Veteranen-ID`,
	es: `Identificación de veterano`,
	fa: `شناسه جانباز`,
	fil: `ID ng mga beterano`,
	fr: `Carte d’identité d’ancien combattant`,
	hi: `वयोवृद्ध आईडी`,
	ht: `Veteran ID`,
	it: `Tessera da veterano`,
	ja: `退役軍人ID`,
	ko: `재향 군인 ID`,
	mww: `Veteran ID`,
	ps: `ویټرن ID`,
	pt: `Identificação de veterano`,
	'pt-pt': `ID de veterano`,
	ru: `Удостоверение ветерана`,
	sm: `ID o le o`,
	so: `Veteran ID`,
	th: `บัตรประจําตัวทหารผ่านศึก`,
	ur: `تجربہ کار ID`,
	vi: `ID cựu chiến binh`,
	'zh-hans': `退伍军人 ID`,
	'zh-hant': `退伍軍人ID`,
	en: `Veteran ID`
};

export const loc_allEpisodesCases: Record<Locale, string> = {
	ar: `جميع الحلقات / الحالات`,
	de: `Alle Episoden / Fälle`,
	es: `Todos los episodios / casos`,
	fa: `همه قسمت ها / موارد`,
	fil: `Lahat ng mga episode / kaso`,
	fr: `Tous les épisodes / cas`,
	hi: `सभी एपिसोड/मामले`,
	ht: `Tout epizòd / ka`,
	it: `Tutti gli episodi / casi`,
	ja: `すべてのエピソード/ケース`,
	ko: `모든 에피소드/사례`,
	mww: `Txhua lub sijhawm uas nws rov / tej zaum`,
	ps: `ټولې برخې / قضیې`,
	pt: `Todos os episódios / casos`,
	'pt-pt': `Todos os episódios / casos`,
	ru: `Все эпизоды / кейсы`,
	sm: `Vaega uma / mataupu`,
	so: `Dhammaan dhacdooyinka / kiisaska`,
	th: `ทุกตอน / คดี`,
	ur: `تمام اقساط / معاملات`,
	vi: `Tất cả các đợt / trường hợp`,
	'zh-hans': `所有剧集/案例`,
	'zh-hant': `所有劇集/案例`,
	en: `All episodes / cases`
};

export const loc_telecom: Record<Locale, string> = {
	ar: `الإتصالات`,
	de: `Telekommunikation`,
	es: `Telecomunicaciones`,
	fa: `مخابرات`,
	fil: `Telecom`,
	fr: `Télécommunications`,
	hi: `दूरसंचार`,
	ht: `Telekominikasyon`,
	it: `Telecomunicazioni`,
	ja: `テレコム`,
	ko: `텔레콤`,
	mww: `Telecom`,
	ps: `مخابراتی`,
	pt: `Telecomunicações`,
	'pt-pt': `Telecomunicações`,
	ru: `Телеком`,
	sm: `Faatelekome`,
	so: `Telecom`,
	th: `โทรคมนาคม`,
	ur: `Telecom`,
	vi: `Viễn thông`,
	'zh-hans': `电信`,
	'zh-hant': `電信`,
	en: `Telecom`
};

export const loc_summary: Record<Locale, string> = {
	ar: `ملخص`,
	de: `Zusammenfassung`,
	es: `Resumen`,
	fa: `خلاصه`,
	fil: `Buod`,
	fr: `Résumé`,
	hi: `सारांश`,
	ht: `Rezime`,
	it: `Sommario`,
	ja: `概要`,
	ko: `요약`,
	mww: `Kev Xaus Lub Neej`,
	ps: `لنډیز`,
	pt: `Resumo`,
	'pt-pt': `Resumo`,
	ru: `Сводка`,
	sm: `Aotelega`,
	so: `Kooban`,
	th: `สรุป`,
	ur: `خلاصہ`,
	vi: `Tóm tắt`,
	'zh-hans': `总结`,
	'zh-hant': `總結`,
	en: `Summary`
};

export const loc_overview: Record<Locale, string> = {
	ar: `نظره عامه`,
	de: `Überblick`,
	es: `Visión general`,
	fa: `مروری`,
	fil: `Pangkalahatang ideya`,
	fr: `Aperçu`,
	hi: `विहंगावलोकन`,
	ht: `Rezime`,
	it: `Panoramica`,
	ja: `概要`,
	ko: `개요`,
	mww: `Overview`,
	ps: `عمومی کتنه`,
	pt: `Visão geral`,
	'pt-pt': `Visão geral`,
	ru: `Обзор`,
	sm: `Vaaiga Aoao`,
	so: `Tilmaam`,
	th: `ภาพรวม`,
	ur: `جائزہ`,
	vi: `Tổng quan`,
	'zh-hans': `概述`,
	'zh-hant': `概述`,
	en: `Overview`
};

export const loc_editDetails: Record<Locale, string> = {
	ar: `تعديل التفاصيل`,
	de: `Details bearbeiten`,
	es: `Editar detalles`,
	fa: `ویرایش جزئیات`,
	fil: `I-edit ang mga detalye`,
	fr: `Modifier les détails`,
	hi: `विवरण संपादित करें`,
	ht: `Detay edit`,
	it: `Modifica dettagli`,
	ja: `詳細の編集`,
	ko: `세부 정보 편집`,
	mww: `Edit paub meej`,
	ps: `سپړنې سمول`,
	pt: `Editar detalhes`,
	'pt-pt': `Editar detalhes`,
	ru: `Редактировать детали`,
	sm: `Faasa'o faamatalaga auiliili`,
	so: `faahfaahinta edit`,
	th: `แก้ไขรายละเอียด`,
	ur: `تفصیلات میں ترمیم کریں`,
	vi: `Chỉnh sửa chi tiết`,
	'zh-hans': `编辑详细信息`,
	'zh-hant': `編輯詳細資訊`,
	en: `Edit details`
};

export const loc_associated: Record<Locale, string> = {
	ar: `اسوشيتد`,
	de: `Assoziiert`,
	es: `Asociado`,
	fa: `مرتبط`,
	fil: `Nauugnay`,
	fr: `Associé`,
	hi: `संबंधित`,
	ht: `Asosye`,
	it: `Associato`,
	ja: `関連付け られた`,
	ko: `연결`,
	mww: `Associated`,
	ps: `اسوشیتید`,
	pt: `Associado`,
	'pt-pt': `Associados`,
	ru: `Связанный`,
	sm: `Fesootai`,
	so: `associated`,
	th: `เชื่อม โยง`,
	ur: `وابستہ`,
	vi: `Liên kết`,
	'zh-hans': `相关`,
	'zh-hant': `相關`,
	en: `Associated`
};

export const loc_printSummary: Record<Locale, string> = {
	ar: `طباعة الملخص`,
	de: `Zusammenfassung drucken`,
	es: `Imprimir resumen`,
	fa: `خلاصه چاپ`,
	fil: `I-print ang buod`,
	fr: `Imprimer le résumé`,
	hi: `प्रिंट सारांश`,
	ht: `Rezime enprime`,
	it: `Stampa sommario`,
	ja: `概要を印刷`,
	ko: `요약 인쇄`,
	mww: `Sau kom meej`,
	ps: `چاپ لنډیز`,
	pt: `Resumo da impressão`,
	'pt-pt': `Imprimir resumo`,
	ru: `Распечатать резюме`,
	sm: `Lolomi le aotelega`,
	so: `Kooban Daabac`,
	th: `พิมพ์สรุป`,
	ur: `خلاصہ پرنٹ کریں`,
	vi: `In tóm tắt`,
	'zh-hans': `打印摘要`,
	'zh-hant': `列印摘要`,
	en: `Print summary`
};

export const loc_printDetails: Record<Locale, string> = {
	ar: `تفاصيل الطباعة`,
	de: `Details drucken`,
	es: `Detalles de impresión`,
	fa: `جزئیات چاپ`,
	fil: `Mga detalye ng pag print`,
	fr: `Imprimer les détails`,
	hi: `प्रिंट विवरण`,
	ht: `Detay enprime`,
	it: `Stampa dettagli`,
	ja: `印刷内容`,
	ko: `세부 정보 인쇄`,
	mww: `Sau kom paub meej`,
	ps: `چاپ خبرتیاوې`,
	pt: `Detalhes da impressão`,
	'pt-pt': `Imprimir detalhes`,
	ru: `Распечатать детали`,
	sm: `Lolomi faamatalaga auiliili`,
	so: `Daabac faahfaahinta`,
	th: `รายละเอียดการพิมพ์`,
	ur: `تفصیلات پرنٹ کریں`,
	vi: `In chi tiết`,
	'zh-hans': `打印详细信息`,
	'zh-hant': `列印詳細資訊`,
	en: `Print details`
};

export const loc_stateRequiredValidationMessage: Record<Locale, (label: unknown) => string> = {
	ar: (label) => `${label} مطلوبة. أدخل عنوان الشارع وسيتم ملء الباقي تلقائيا.`,
	de: (label) => `${label} ist erforderlich. Geben Sie eine Adresse ein und der Rest wird automatisch ausgefüllt.`,
	es: (label) => `${label} . Ingrese una dirección y el resto se completará automáticamente.`,
	fa: (label) => `${label} الزامی است. آدرس خیابان را وارد کنید و بقیه به طور خودکار پر می شوند.`,
	fil: (label) => `${label} ay kinakailangan. Magpasok ng isang address ng kalye at ang natitira ay awtomatikong magpupuno.`,
	fr: (label) => `${label} est requis. Entrez une adresse postale et le reste se remplira automatiquement.`,
	hi: (label) => `${label} की आवश्यकता है। एक सड़क का पता दर्ज करें और बाकी स्वचालित रूप से भर जाएगा।`,
	ht: (label) => `${label} obligatwa. Antre nan yon adrès lari ak rès la ap ranpli nan otomatikman.`,
	it: (label) => `${label} è obbligatorio. Inserisci un indirizzo e il resto verrà compilato automaticamente.`,
	ja: (label) => `${label} は必須です。住所を入力すると、残りは自動的に入力されます。`,
	ko: (label) => `${label} 는 필수입니다. 도로명 주소를 입력하면 나머지는 자동으로 채워집니다.`,
	mww: (label) => `${label} yuav tsum tau muaj. Sau ib txoj kev chaw nyob thiab tas yuav sau nyob rau hauv.`,
	ps: (label) => `${label} ته اړتیا ده. د سړک پته دننه کړئ او پاتې به په اتوماتیک ډول ډک شی.`,
	pt: (label) => `${label} é necessário. Digite um endereço e o restante será preenchido automaticamente.`,
	'pt-pt': (label) => `${label} é obrigatório. Digite um endereço e o restante será preenchido automaticamente.`,
	ru: (label) => `${label} . Введите почтовый адрес, а остальное заполнится автоматически.`,
	sm: (label) => `E manaomia le ${label}. Ta i totonu se tuatusi o le auala ma o le a otometi lava ona faatumuina le vaega o totoe.`,
	so: (label) => `${label} ayaa loo baahan yahay. Ku qor cinwaanka wadada inta kalena si toos ah ayey u buuxinayaan.`,
	th: (label) => `${label} ป้อนที่อยู่และส่วนที่เหลือจะกรอกโดยอัตโนมัติ`,
	ur: (label) => `${label} ضرورت ہے. گلی کا پتہ درج کریں اور باقی خود بخود بھر جائے گا۔`,
	vi: (label) => `${label} là bắt buộc. Nhập địa chỉ đường phố và phần còn lại sẽ tự động điền vào.`,
	'zh-hans': (label) => `${label} 是必需的。输入街道地址，其余地址将自动填写。`,
	'zh-hant': (label) => `${label} 是必需的。輸入街道地址，其餘位址將自動填寫。`,
	en: (label) => `${label} is required. Enter a street address and the rest will fill in automatically.`
};

export const loc_page: Record<Locale, string> = {
	ar: `صفحة`,
	de: `Seite`,
	es: `Página`,
	fa: `صفحه`,
	fil: `Pahina`,
	fr: `Page`,
	hi: `पृष्ठ`,
	ht: `Paj`,
	it: `Pagina`,
	ja: `ページ`,
	ko: `페이지`,
	mww: `Phab`,
	ps: `مخ`,
	pt: `Página`,
	'pt-pt': `Página`,
	ru: `Страница`,
	sm: `Itulau`,
	so: `sida`,
	th: `หน้า`,
	ur: `صفحہ`,
	vi: `Trang`,
	'zh-hans': `页`,
	'zh-hant': `頁`,
	en: `Page`
};

export const loc_requiredRecommendationValidationMessage: Record<Locale, (label: unknown) => string> = {
	ar: (label) => `"${label}" مطلوبة ويجب إنشاؤها قبل تحديث حالة هذا السجل. التراجع عن التغيير إلى الحالة، وحفظ، ثم إنشاء "${label}" قبل محاولة تحديث حالة هذا السجل.`,
	de: (label) => `Eine ${label}" ist erforderlich und muss erstellt werden, bevor der Status dieses Datensatzes aktualisiert werden kann. Machen Sie die Statusänderung rückgängig, speichern Sie sie und erstellen Sie dann "${label}", bevor Sie versuchen, den Status dieses Datensatzes zu aktualisieren.`,
	es: (label) => `Se requiere una "${label}" y debe crearse antes de que se pueda actualizar el estado de este registro. Deshaga el cambio de estado, guarde y, a continuación, cree "${label}" antes de intentar actualizar el estado de este registro.`,
	fa: (label) => `"${label} بودن" مورد نیاز است و باید قبل از به روز رسانی وضعیت این رکورد ایجاد شود. قبل از تلاش برای به روزرسانی وضعیت این رکورد، تغییر وضعیت را لغو کنید، ذخیره کنید، سپس «${label} بودن» را ایجاد کنید.`,
	fil: (label) => `Ang "${label}" ay kinakailangan at dapat na nilikha bago ma update ang katayuan ng talaang ito. I-undo ang pagbabago sa katayuan, i-save, pagkatapos ay lumikha ${label}" bago tangkaing i-update ang katayuan ng talaang ito.`,
	fr: (label) => `${label} » est requise et doit être créée avant que le statut de ce dossier puisse être mis à jour. Annulez la modification du statut, enregistrez, puis créez « ${label} » avant de tenter de mettre à jour le statut de cet enregistrement.`,
	hi: (label) => `"${label}" आवश्यक है और इस रिकॉर्ड की स्थिति को अद्यतन किए जाने से पहले बनाया जाना चाहिए। स्थिति में परिवर्तन पूर्ववत करें, सहेजें, फिर इस रिकॉर्ड की स्थिति को अपडेट करने का प्रयास करने से पहले "${label}" बनाएं।`,
	ht: (label) => `"${label} epi yo dwe kreye anvan estati dosye sa a ka mete ajou. Defèt chanjman nan estati, sove, Lè sa a, kreye "${label}" anvan ou eseye mete ajou estati dosye sa a.`,
	it: (label) => `La "${label}" è richiesta e deve essere creata prima che lo stato di questo record possa essere aggiornato. Annulla la modifica dello stato, salva, quindi crea "${label}" prima di tentare di aggiornare lo stato di questo record.`,
	ja: (label) => `「${label}」が必要であり、このレコードのステータスを更新する前に作成する必要があります。このレコードのステータスを更新する前に、ステータスへの変更を元に戻して保存し、「${label}」を作成します。`,
	ko: (label) => `"${label}"가 필요하며 이 기록의 상태를 업데이트하기 전에 만들어야 합니다. 상태 변경을 실행 취소하고 저장한 다음 이 레코드의 상태를 업데이트하기 전에 "${label}"를 만듭니다.`,
	mww: (label) => `"${label}" yuav tsum muaj thiab yuav tsum tau tsim ua ntej raws li txoj cai ntawm daim ntawv no yuav muab kho dua. Undo hloov raws li txoj cai, txuag, ces tsim "${label}" ua ntej yuav sim hloov cov ntaub ntawv no raws li txoj cai.`,
	ps: (label) => `"د ${label}" اړین دی او باید د دې ریکارډ وضعیت تازه کولو دمخه رامینځته شی. وضعیت ته بدلون لغوه کړئ ، خوندی کړئ ، بیا د دې ریکارډ وضعیت تازه کولو هڅه کولو دمخه "د ${label}" رامینځته کړئ.`,
	pt: (label) => `${label}" é necessária e deve ser criada antes que o status desse registro possa ser atualizado. Desfaça a alteração no status, salve e crie "${label}" antes de tentar atualizar o status desse registro.`,
	'pt-pt': (label) => `A "${label}" é necessária e deve ser criada antes que o status deste registro possa ser atualizado. Desfaça a alteração de status, salve e crie "${label}" antes de tentar atualizar o status deste registro.`,
	ru: (label) => `${label}» является обязательной и должна быть создана до того, как статус этой записи может быть обновлен. Отмените изменение статуса, сохраните, а затем создайте «${label}», прежде чем пытаться обновить статус этой записи.`,
	sm: (label) => `"${label}" e manaomia ma e tatau ona fatuina a o lei mafai ona faafouina le tulaga o lenei faamaumauga. Toe tatala le suiga i le tulaga, sefe, ona fatu lea ${label}" a o lei taumafai e faafou le tulaga o lenei faamaumauga.`,
	so: (label) => `"${label}" ayaa loo baahan yahay waana in la abuuraa ka hor inta aan xaaladda diiwaankaan la cusbooneysiin karin. Undo change to status, save, then create "${label}" ka hor inta aan la isku dayin in la cusboonaysiiyo xaaladda diiwaanka this ee.`,
	th: (label) => `จําเป็นต้องมี "${label}" และต้องสร้างขึ้นก่อนจึงจะสามารถอัปเดตสถานะของบันทึกนี้ได้ เลิกทําการเปลี่ยนแปลงสถานะ บันทึก จากนั้นสร้าง "${label}" ก่อนที่จะพยายามอัปเดตสถานะของเรกคอร์ดนี้`,
	ur: (label) => `"${label}" ضروری ہے اور اس ریکارڈ کی حیثیت کو اپ ڈیٹ کرنے سے پہلے اسے بنانا ضروری ہے۔ اس ریکارڈ کی حیثیت کو اپ ڈیٹ کرنے کی کوشش کرنے سے پہلے اسٹیٹس میں تبدیلی کو ختم کریں ، محفوظ کریں ، پھر "${label}" بنائیں۔`,
	vi: (label) => `"${label}" là bắt buộc và phải được tạo trước khi trạng thái của hồ sơ này có thể được cập nhật. Hoàn tác thay đổi trạng thái, lưu, sau đó tạo "${label}" trước khi cố gắng cập nhật trạng thái của bản ghi này.`,
	'zh-hans': (label) => `“${label}”是必需的，并且必须在更新此记录的状态之前创建。撤消对状态的更改，保存，然后创建“${label}”，然后再尝试更新此记录的状态。`,
	'zh-hant': (label) => `“${label}”是必需的，並且必須在更新此記錄的狀態之前創建。撤銷對狀態的更改，保存，然後創建“${label}”，然後再嘗試更新此記錄的狀態。`,
	en: (label) => `"${label}" is required and must be created before the status of this record can be updated. Undo change to status, save, then create "${label}" before attempting to update this record's status.`
};

export const loc_organizationsWithConsentFormat: Record<Locale, (label: unknown) => string> = {
	ar: (label) => `${label} منظمات أخرى لديها حق الوصول عن طريق الموافقة`,
	de: (label) => `${label} weitere Organisationen haben Zugriff per Einwilligung`,
	es: (label) => `${label} organizaciones más tienen acceso a través del consentimiento`,
	fa: (label) => `${label} سازمان دیگر از طریق رضایت دسترسی دارند`,
	fil: (label) => `${label} pang mga organisasyon ang may access sa pamamagitan ng pahintulot`,
	fr: (label) => `${label} autres organisations ont accès via le consentement`,
	hi: (label) => `${label} और संगठनों की सहमति से पहुंच है`,
	ht: (label) => `${label} plis òganizasyon gen aksè atravè konsantman`,
	it: (label) => `Altre ${label} organizzazioni hanno accesso tramite consenso`,
	ja: (label) => `さらに${label}の組織が同意によってアクセスできます`,
	ko: (label) => `${label} 개 이상의 조직이 동의를 통해 액세스할 수 있습니다.`,
	mww: (label) => `${label} ntau lub koom haum tau saib ntawm kev tso cai`,
	ps: (label) => `${label} نور سازمانونه د رضایت له لارې لاسرسی لری`,
	pt: (label) => `${label} organizações têm acesso via consentimento`,
	'pt-pt': (label) => `${label} organizações têm acesso via consentimento`,
	ru: (label) => `${label} организаций имеют доступ через согласие`,
	sm: (label) => `${label} isi faalapotopotoga e mafai ona faaaogaina e ala i le maliega`,
	so: (label) => `${label} urur oo dheeraad ah ayaa ku hela helitaanka ogolaansho`,
	th: (label) => `อีก ${label} องค์กรมีสิทธิ์เข้าถึงผ่านความยินยอม`,
	ur: (label) => `${label} تنظیموں کو رضامندی کے ذریعے رسائی حاصل ہے`,
	vi: (label) => `${label} tổ chức có quyền truy cập thông qua sự đồng ý`,
	'zh-hans': (label) => `另有 ${label} 个组织可以通过同意进行访问`,
	'zh-hant': (label) => `另有${label}個組織可以通過同意進行訪問`,
	en: (label) => `${label} more organizations have access via consent`
};

export const loc_organizationWithConsent: Record<Locale, string> = {
	ar: `1 مؤسسة أخرى لديها حق الوصول عبر الموافقة`,
	de: `1 weitere Organisation hat Zugriff per Einwilligung`,
	es: `1 organización más tiene acceso a través del consentimiento`,
	fa: `1 سازمان دیگر از طریق رضایت دسترسی دارد`,
	fil: `1 higit pang mga organisasyon ay may access sa pamamagitan ng pahintulot`,
	fr: `1 organisation de plus a accès via le consentement`,
	hi: `1 और संगठन के पास सहमति के माध्यम से पहुंच है`,
	ht: `1 plis òganizasyon gen aksè atravè konsantman`,
	it: `1 altra organizzazione ha accesso tramite consenso`,
	ja: `さらに 1 つの組織が同意によってアクセスできます`,
	ko: `동의를 통해 액세스할 수 있는 조직 1개 이상`,
	mww: `1 ntau lub koom haum tau saib ntawm kev tso cai`,
	ps: `1 نور سازمان د رضایت له لارې لاسرسی لری`,
	pt: `Mais 1 organização tem acesso via consentimento`,
	'pt-pt': `Mais 1 organização tem acesso via consentimento`,
	ru: `1 Еще одна организация имеет доступ через согласие`,
	sm: `1 isi faalapotopotoga e mafai ona maua e ala i le maliega`,
	so: `1 urur dheeraad ah ayaa helitaanka via ogolaansho`,
	th: `อีก 1 องค์กรมีสิทธิ์เข้าถึงผ่านความยินยอม`,
	ur: `1 مزید تنظیم کو رضامندی کے ذریعے رسائی حاصل ہے`,
	vi: `Thêm 1 tổ chức có quyền truy cập thông qua sự đồng ý`,
	'zh-hans': `另外 1 个组织可以通过同意进行访问`,
	'zh-hant': `另外1個組織可以通過同意進行訪問`,
	en: `1 more organization has access via consent`
};

export const loc_site: Record<Locale, string> = {
	ar: `موقع`,
	de: `Platz`,
	es: `Sitio`,
	fa: `سایت`,
	fil: `Site`,
	fr: `Site`,
	hi: `साइट`,
	ht: `Sit konplèks`,
	it: `Sito`,
	ja: `敷地`,
	ko: `사이트`,
	mww: `Qhov Chaw Kawm Ntawv`,
	ps: `ګورت پاڼه`,
	pt: `Local`,
	'pt-pt': `Sítio`,
	ru: `Место`,
	sm: `Saite`,
	so: `Site`,
	th: `สถานที่`,
	ur: `سائٹ`,
	vi: `Site`,
	'zh-hans': `网站`,
	'zh-hant': `網站`,
	en: `Site`
};

export const loc_sites: Record<Locale, string> = {
	ar: `المواقع`,
	de: `Lagen`,
	es: `Sitios`,
	fa: `سایت`,
	fil: `Mga Site`,
	fr: `Sites`,
	hi: `साइटों`,
	ht: `Sit`,
	it: `Siti`,
	ja: `サイト`,
	ko: `사이트`,
	mww: `Tej Chaw`,
	ps: `سایټونه`,
	pt: `Sites`,
	'pt-pt': `Sítios`,
	ru: `Сайтов`,
	sm: `Saite`,
	so: `Goobaha`,
	th: `ไซต์`,
	ur: `سائٹس`,
	vi: `Trang web`,
	'zh-hans': `网站`,
	'zh-hant': `網站`,
	en: `Sites`
};

export const loc_addSite: Record<Locale, string> = {
	ar: `إضافة موقع`,
	de: `Website hinzufügen`,
	es: `Agregar sitio`,
	fa: `اضافه کردن سایت`,
	fil: `Magdagdag ng site`,
	fr: `Ajouter un site`,
	hi: `साइट जोड़ें`,
	ht: `Ajoute sit`,
	it: `Aggiungi sito`,
	ja: `サイトを追加`,
	ko: `사이트 추가`,
	mww: `Ntxiv qhov chaw`,
	ps: `ځای زیاتول`,
	pt: `Adicionar site`,
	'pt-pt': `Adicionar site`,
	ru: `Добавить сайт`,
	sm: `Faaopoopo le saite`,
	so: `Add site`,
	th: `เพิ่มเว็บไซต์`,
	ur: `سائٹ شامل کریں`,
	vi: `Thêm trang web`,
	'zh-hans': `添加站点`,
	'zh-hant': `添加網站`,
	en: `Add site`
};

export const loc_removeSite: Record<Locale, string> = {
	ar: `إزالة الموقع`,
	de: `Website entfernen`,
	es: `Eliminar sitio`,
	fa: `برداشتن سایت`,
	fil: `Alisin ang site`,
	fr: `Supprimer le site`,
	hi: `साइट निकालें`,
	ht: `Retire sit`,
	it: `Rimuovi sito`,
	ja: `サイトを削除`,
	ko: `사이트 제거`,
	mww: `Tshem tawm qhov chaw`,
	ps: `ځای ړنګول`,
	pt: `Remover site`,
	'pt-pt': `Remover site`,
	ru: `Удалить сайт`,
	sm: `Aveese le saite`,
	so: `saar site`,
	th: `ลบเว็บไซต์`,
	ur: `سائٹ کو حذف کریں`,
	vi: `Xóa trang web`,
	'zh-hans': `删除站点`,
	'zh-hant': `刪除網站`,
	en: `Remove site`
};

export const loc_thereAreNoSites: Record<Locale, string> = {
	ar: `لا توجد مواقع`,
	de: `Es gibt keine Seiten`,
	es: `No hay sitios`,
	fa: `هیچ سایتی وجود ندارد`,
	fil: `Walang mga site`,
	fr: `Il n’y a pas de sites`,
	hi: `कोई साइट नहीं हैं`,
	ht: `Pa gen okenn sit`,
	it: `Non ci sono siti`,
	ja: `サイトはありません`,
	ko: `사이트가 없습니다`,
	mww: `Tsis muaj chaw`,
	ps: `هیڅ سایټونه شتون نلری`,
	pt: `Não há sites`,
	'pt-pt': `Não existem sites`,
	ru: `Сайтов нет`,
	sm: `E leai ni saite`,
	so: `Ma jiraan goobo`,
	th: `ไม่มีเว็บไซต์`,
	ur: `کوئی سائٹس نہیں ہیں`,
	vi: `Không có trang web`,
	'zh-hans': `没有网站`,
	'zh-hant': `沒有網站`,
	en: `There are no sites`
};

export const loc_genderIdentity: Record<Locale, string> = {
	ar: `الهوية الجنسية`,
	de: `Geschlechtsidentität`,
	es: `Identidad de género`,
	fa: `هویت جنسیتی`,
	fil: `Pagkakakilanlan ng kasarian`,
	fr: `Identité sexuelle`,
	hi: `लिंग पहचान`,
	ht: `Idantite seksyèl`,
	it: `Identità di genere`,
	ja: `自認`,
	ko: `성 정체성`,
	mww: `Yog leejtwg tiag`,
	ps: `د جندر هویت`,
	pt: `Identidade de gênero`,
	'pt-pt': `Identidade de género`,
	ru: `Гендерная идентичность`,
	sm: `Faasinomaga o le itupa`,
	so: `Aqoonsiga jinsiga`,
	th: `อัตลักษณ์ทางเพศ`,
	ur: `صنفی شناخت`,
	vi: `Bản dạng giới`,
	'zh-hans': `性别认同`,
	'zh-hant': `性別認同`,
	en: `Gender identity`
};

export const loc_billing: Record<Locale, string> = {
	ar: `الفواتير`,
	de: `Abrechnung`,
	es: `Facturación`,
	fa: `صدور صورت حساب`,
	fil: `Pagsingil`,
	fr: `Facturation`,
	hi: `बिलिंग`,
	ht: `Fakti`,
	it: `Fatturazione`,
	ja: `請求`,
	ko: `과금`,
	mww: `Billing`,
	ps: `بلینګ`,
	pt: `Faturamento`,
	'pt-pt': `Faturação`,
	ru: `Выставления счетов`,
	sm: `Pili`,
	so: `Billing`,
	th: `เรียก เก็บ เงิน`,
	ur: `Billing`,
	vi: `Thanh toán`,
	'zh-hans': `计费`,
	'zh-hant': `計費`,
	en: `Billing`
};

export const loc_administrative: Record<Locale, string> = {
	ar: `اداري`,
	de: `Administrativ`,
	es: `Administrativo`,
	fa: `اداری`,
	fil: `Administratibo`,
	fr: `Administratif`,
	hi: `संचालन-संबंधी`,
	ht: `Administratif`,
	it: `Amministrativo`,
	ja: `行政`,
	ko: `행정상의`,
	mww: `Administrative Administrative`,
	ps: `اداری`,
	pt: `Administrativo`,
	'pt-pt': `Administrativo`,
	ru: `Административный`,
	sm: `Pulega`,
	so: `administrative`,
	th: `ดูแล`,
	ur: `انتظامی`,
	vi: `Quản trị`,
	'zh-hans': `行政`,
	'zh-hant': `行政`,
	en: `Administrative`
};

export const loc_humanResources: Record<Locale, string> = {
	ar: `الموارد البشرية`,
	de: `Personalabteilung`,
	es: `Recursos humanos`,
	fa: `منابع انسانی`,
	fil: `Mga yamang tao`,
	fr: `Ressources humaines`,
	hi: `मानव संसाधन`,
	ht: `Resous imen`,
	it: `Risorse umane`,
	ja: `人的資源`,
	ko: `인사`,
	mww: `Tib neeg cov chaw muab kev pab`,
	ps: `د بشری منابعو`,
	pt: `Recursos humanos`,
	'pt-pt': `Recursos humanos`,
	ru: `Управление персоналом`,
	sm: `Punaoa mo tagata`,
	so: `Human resources`,
	th: `ทรัพยากร`,
	ur: `انسانی وسائل`,
	vi: `Nguồn nhân lực`,
	'zh-hans': `人力资源`,
	'zh-hant': `人力資源`,
	en: `Human resources`
};

export const loc_payor: Record<Locale, string> = {
	ar: `الدافع`,
	de: `Kostenträger`,
	es: `Pagador`,
	fa: `Payor`,
	fil: `Payor`,
	fr: `Payeur`,
	hi: `भुगतानकर्ता`,
	ht: `Peyè`,
	it: `Pagatore`,
	ja: `支払人`,
	ko: `지불자`,
	mww: `Them`,
	ps: `Payor`,
	pt: `Pagador`,
	'pt-pt': `Pagador`,
	ru: `Плательщик`,
	sm: `Tagata e Totogiina`,
	so: `Payor`,
	th: `ผู้จ่ายเงิน`,
	ur: `Payor`,
	vi: `Người thanh toán`,
	'zh-hans': `付款人`,
	'zh-hant': `付款人`,
	en: `Payor`
};

export const loc_clients: Record<Locale, string> = {
	ar: `العملاء`,
	de: `Klienten`,
	es: `Clientes`,
	fa: `مشتریان`,
	fil: `Mga Kliyente`,
	fr: `Clientèle`,
	hi: `ग्राहकों`,
	ht: `Kliyan yo`,
	it: `Clienti`,
	ja: `クライアント`,
	ko: `클라이언트`,
	mww: `Cov neeg tau txais kev`,
	ps: `د مراجعینو`,
	pt: `Clientes`,
	'pt-pt': `Clientes`,
	ru: `Клиентов`,
	sm: `Tagata e Faaaogaina`,
	so: `Macaamiisha`,
	th: `ไคล เอนต์`,
	ur: `کلائنٹس`,
	vi: `Khách hàng`,
	'zh-hans': `客户`,
	'zh-hant': `客戶`,
	en: `Clients`
};

export const loc_press: Record<Locale, string> = {
	ar: `كبس`,
	de: `Presse`,
	es: `Prensa`,
	fa: `مطبوعات`,
	fil: `Pindutin ang`,
	fr: `Presser`,
	hi: `दबाना`,
	ht: `Près`,
	it: `Passeggiata`,
	ja: `押す`,
	ko: `누르다`,
	mww: `xovxwm`,
	ps: `مطبوعاتی`,
	pt: `Imprensa`,
	'pt-pt': `Imprensa`,
	ru: `Давить`,
	sm: `Oomi le`,
	so: `Press`,
	th: `กด`,
	ur: `دبانا`,
	vi: `Báo chí`,
	'zh-hans': `压`,
	'zh-hant': `壓`,
	en: `Press`
};

export const loc_organizationContactsDescription: Record<Locale, (membersHref: unknown) => TemplateResult> = {
	ar: (membersHref) => html`سجل جهات الاتصال الإدارية والفوترة وأنواع جهات الاتصال الأخرى. استخدم <a class="Link--secondary text-bold" href="${membersHref}">قسم الأعضاء</a> لإضافة مستخدمين إلى مؤسستك.`,
	de: (membersHref) => html`Erfassen Sie Verwaltungs-, Abrechnungs- und andere Arten von Kontakten. Verwenden Sie den <a class="Link--secondary text-bold" href="${membersHref}">Abschnitt Mitglieder,</a> um Benutzer zu Ihrer Organisation hinzuzufügen.`,
	es: (membersHref) => html`Registre los contactos administrativos, de facturación y de otro tipo. Use la <a class="Link--secondary text-bold" href="${membersHref}">sección de miembros</a> para agregar usuarios a su organización.`,
	fa: (membersHref) => html`مخاطبین اداری، صورتحساب و سایر مخاطبین را ضبط کنید. از <a class="Link--secondary text-bold" href="${membersHref}">بخش اعضا</a> برای افزودن کاربران به سازمان خود استفاده کنید.`,
	fil: (membersHref) => html`Itala ang administratibo, pagsingil, at iba pang uri ng mga contact. Gamitin ang <a class="Link--secondary text-bold" href="${membersHref}">seksyon ng mga miyembro</a> upang magdagdag ng mga gumagamit sa iyong samahan.`,
	fr: (membersHref) => html`Enregistrez les contacts administratifs, de facturation et autres. Utilisez la <a class="Link--secondary text-bold" href="${membersHref}">section des membres</a> pour ajouter des utilisateurs à votre organisation.`,
	hi: (membersHref) => html`व्यवस्थापक, बिलिंग और अन्य प्रकार के संपर्कों को रिकॉर्ड करें. अपने संगठन में उपयोगकर्ताओं को जोड़ने के लिए <a class="Link--secondary text-bold" href="${membersHref}">सदस्य अनुभाग</a> का उपयोग करें.`,
	ht: (membersHref) => html`Dosye administratif, bòdwo, ak lòt kalite kontak. Sèvi ak <a class="Link--secondary text-bold" href="${membersHref}">seksyon manm yo</a> pou ajoute itilizatè yo nan òganizasyon w lan.`,
	it: (membersHref) => html`Registra contatti amministrativi, di fatturazione e di altro tipo. Utilizza la <a class="Link--secondary text-bold" href="${membersHref}">sezione membri</a> per aggiungere utenti alla tua organizzazione.`,
	ja: (membersHref) => html`管理、請求、およびその他の種類の連絡先を記録します。 <a class="Link--secondary text-bold" href="${membersHref}">メンバー セクション</a> を使用して、組織にユーザーを追加します。`,
	ko: (membersHref) => html`관리, 청구 및 기타 유형의 연락처를 기록합니다. <a class="Link--secondary text-bold" href="${membersHref}">구성원 섹션을</a> 사용하여 기관에 사용자를 추가합니다.`,
	mww: (membersHref) => html`Cov ntaub ntawv administrative, billing, thiab lwm hom kev sib cuag. Siv cov <a class="Link--secondary text-bold" href="${membersHref}">tswv cuab</a> ntxiv rau koj lub koom haum.`,
	ps: (membersHref) => html`اداری ، بلینګ ، او د اړیکو نور ډولونه ثبت کړئ. خپل سازمان ته د کاروونکو اضافه کولو لپاره <a class="Link--secondary text-bold" href="${membersHref}">د غړو برخې</a> څخه کار واخلئ.`,
	pt: (membersHref) => html`Registre contatos administrativos, de cobrança e outros tipos de contatos. Use a <a class="Link--secondary text-bold" href="${membersHref}">seção de membros</a> para adicionar usuários à sua organização.`,
	'pt-pt': (membersHref) => html`Registre contatos administrativos, de cobrança e outros tipos de contatos. Use a <a class="Link--secondary text-bold" href="${membersHref}">seção de membros</a> para adicionar usuários à sua organização.`,
	ru: (membersHref) => html`Записывайте административные, биллинговые и другие типы контактов. Используйте <a class="Link--secondary text-bold" href="${membersHref}">раздел участники</a> для добавления пользователей в свою организацию.`,
	sm: (membersHref) => html`Faamaumau le pulega, pili, ma isi ituaiga o tagata faafesootai. Faaaoga le <a class="Link--secondary text-bold" href="${membersHref}">vaega o tagata</a> o le ekalesia e faaopoopo ai tagata faaaoga i lau faalapotopotoga.`,
	so: (membersHref) => html`Record maamulka, biilasha, iyo noocyada kale ee xiriirada. Isticmaal <a class="Link--secondary text-bold" href="${membersHref}">qaybta xubnaha</a> si aad ugu darto dadka isticmaala ururkaaga.`,
	th: (membersHref) => html`บันทึกผู้ติดต่อด้านการดูแลระบบ การเรียกเก็บเงิน และประเภทอื่นๆ ใช้ <a class="Link--secondary text-bold" href="${membersHref}">ส่วนสมาชิก</a> เพื่อเพิ่มผู้ใช้ในองค์กรของคุณ`,
	ur: (membersHref) => html`انتظامی ، بلنگ ، اور دیگر قسم کے رابطوں کو ریکارڈ کریں۔ صارفین کو اپنی تنظیم میں شامل کرنے کے لئے <a class="Link--secondary text-bold" href="${membersHref}">ممبرز سیکشن</a> کا استعمال کریں۔`,
	vi: (membersHref) => html`Ghi lại hành chính, thanh toán và các loại liên hệ khác. Sử dụng <a class="Link--secondary text-bold" href="${membersHref}">phần thành viên</a> để thêm người dùng vào tổ chức của bạn.`,
	'zh-hans': (membersHref) => html`记录管理、计费和其他类型的联系人。使用 <a class="Link--secondary text-bold" href="${membersHref}">members 部分</a> 将用户添加到您的组织。`,
	'zh-hant': (membersHref) => html`記錄管理、計費和其他類型的聯繫人。使用 <a class="Link--secondary text-bold" href="${membersHref}">members 部分</a> 將使用者添加到您的組織。`,
	en: (membersHref) => html`Record administrative, billing, and other types of contacts. Use the <a class="Link--secondary text-bold" href="${membersHref}">members section</a> to add users to your organization.`
};

export const loc_organizationSitesDescription: Record<Locale, string> = {
	ar: `سجل اسم المواقع التي تعمل فيها مؤسستك ورقم هاتفها وعنوانها.`,
	de: `Notieren Sie sich den Namen, die Telefonnummer und die Adresse der Websites, an denen Ihre Organisation tätig ist.`,
	es: `Registre el nombre, el número de teléfono y la dirección de los sitios en los que opera su organización.`,
	fa: `نام، شماره تلفن و آدرس سایت هایی را که سازمان شما در آن فعالیت می کند ثبت کنید.`,
	fil: `Itala ang pangalan, numero ng telepono, at address ng mga site kung saan nagpapatakbo ang iyong samahan.`,
	fr: `Notez le nom, le numéro de téléphone et l’adresse des sites où votre organisation exerce ses activités.`,
	hi: `उन साइट्स का नाम, फ़ोन नंबर और पता रिकॉर्ड करें जहाँ आपका संगठन संचालित होता है.`,
	ht: `Ekri non, nimewo telefòn, ak adrès nan sit kote òganizasyon ou fonksyone.`,
	it: `Registra il nome, il numero di telefono e l'indirizzo dei siti in cui opera la tua organizzazione.`,
	ja: `組織が運営するサイトの名前、電話番号、住所を記録します。`,
	ko: `조직이 운영되는 사이트의 이름, 전화 번호 및 주소를 기록합니다.`,
	mww: `Sau lub npe, xov tooj, thiab chaw nyob ntawm qhov chaw uas koj lub koom haum koomtxoos.`,
	ps: `د هغه سایټونو نوم ، د تلیفون شمیره او پته ثبت کړئ چیرې چې ستاسو سازمان فعالیت کوی.`,
	pt: `Registre o nome, o número de telefone e o endereço dos sites em que sua organização opera.`,
	'pt-pt': `Registe o nome, número de telefone e endereço dos sites onde a sua organização opera.`,
	ru: `Запишите название, номер телефона и адрес сайтов, на которых работает ваша организация.`,
	sm: `Faamaumau le igoa, numera o le telefoni, ma le tuatusi o saite o loo faagaoioia ai lau faalapotopotoga.`,
	so: `Ku qor magaca, lambarka telefoonka, iyo cinwaanka goobaha uu ururku ka shaqeeyo.`,
	th: `บันทึกชื่อ หมายเลขโทรศัพท์ และที่อยู่ของไซต์ที่องค์กรของคุณดําเนินการอยู่`,
	ur: `ان سائٹوں کا نام ، فون نمبر اور پتہ ریکارڈ کریں جہاں آپ کی تنظیم کام کرتی ہے۔`,
	vi: `Ghi lại tên, số điện thoại và địa chỉ của các site nơi tổ chức của bạn hoạt động.`,
	'zh-hans': `记录您的组织运营的站点的名称、电话号码和地址。`,
	'zh-hant': `記錄您的組織運營的網站的名稱、電話號碼和位址。`,
	en: `Record the name, phone number, and address of sites where your organization operates.`
};

export const loc_noChildActivitiesTitleFormat: Record<Locale, (type: unknown) => string> = {
	ar: (type) => `${type} بأنشطة الأطفال`,
	de: (type) => `${type} erlaubt keine Aktivitäten für Kinder`,
	es: (type) => `La ${type} no permite actividades infantiles`,
	fa: (type) => `${type} اجازه فعالیت کودک را نمی دهد`,
	fil: (type) => `Hindi pinapayagan ng ${type} ang mga aktibidad ng bata`,
	fr: (type) => `${type} ne permet pas les activités des enfants`,
	hi: (type) => `${type} बाल गतिविधियों की अनुमति नहीं देता है`,
	ht: (type) => `${type} pèmèt aktivite timoun yo`,
	it: (type) => `${type} non consente attività per bambini`,
	ja: (type) => `${type} では、子供の活動は許可されていません`,
	ko: (type) => `${type} 은 아동 활동을 허용하지 않습니다.`,
	mww: (type) => `${type} tsis pub cov kev ua ub no`,
	ps: (type) => `د ${type} ماشومانو فعالیتونو ته اجازه نه ورکوی`,
	pt: (type) => `${type} não permite atividades infantis`,
	'pt-pt': (type) => `${type} não permite atividades infantis`,
	ru: (type) => `${type} не разрешает детям заниматься своими делами`,
	sm: (type) => `O meaai ${type} e le faatagaina ai gaoioiga a tamaiti`,
	so: (type) => `${type} ma ogola waxqabadka caruurta`,
	th: (type) => `${type} ไม่อนุญาตให้มีกิจกรรมสําหรับเด็ก`,
	ur: (type) => `${type} سرگرمیوں کی اجازت نہیں دیتا ہے`,
	vi: (type) => `${type} không cho phép các hoạt động của trẻ em`,
	'zh-hans': (type) => `${type} 不允许儿童活动`,
	'zh-hant': (type) => `${type} 不允許兒童活動`,
	en: (type) => `${type} does not permit child activities`
};

export const loc_noChildActivitiesDescriptionFormat: Record<Locale, (parentHref: unknown, parentType: unknown) => TemplateResult> = {
	ar: (parentHref, parentType) => html`ربما كنت تقصد إنشاء سجل طفل من <a href="${parentHref}">${parentType}</a>؟`,
	de: (parentHref, parentType) => html`Vielleicht wollten Sie einen untergeordneten Datensatz aus der <a href="${parentHref}">${parentType}</a> erstellen?`,
	es: (parentHref, parentType) => html`¿Quizás tenía la intención de crear un registro de niño a partir de la <a href="${parentHref}">${parentType}</a>?`,
	fa: (parentHref, parentType) => html`شاید قصد داشتید از <a href="${parentHref}">${parentType}</a> یک رکورد کودک ایجاد کنید؟`,
	fil: (parentHref, parentType) => html`Marahil ay sinadya mong gumawa ng record ng bata mula sa <a href="${parentHref}">${parentType}</a>?`,
	fr: (parentHref, parentType) => html`Peut-être vouliez-vous créer un dossier d’enfant à partir de la <a href="${parentHref}">${parentType}</a> ?`,
	hi: (parentHref, parentType) => html`शायद आप <a href="${parentHref}">${parentType}</a> से एक बच्चे का रिकॉर्ड बनाना चाहते थे?`,
	ht: (parentHref, parentType) => html`Petèt ou te vle di kreye yon dosye timoun nan <a href="${parentHref}">${parentType}</a> la?`,
	it: (parentHref, parentType) => html`Forse intendevi creare un record figlio dal <a href="${parentHref}">${parentType}</a>?`,
	ja: (parentHref, parentType) => html`もしかしたら、 <a href="${parentHref}">${parentType}</a>から子レコードを作成するつもりだったのでしょうか?`,
	ko: (parentHref, parentType) => html`아마도 <a href="${parentHref}">${parentType}</a>에서 하위 레코드를 만들려고 했습니까?`,
	mww: (parentHref, parentType) => html`Kab tias koj meant los tsim ib tug me nyuam ntaub ntawv los ntawm cov <a href="${parentHref}">${parentType}</a>`,
	ps: (parentHref, parentType) => html`ایا تاسو غواړئ د <a href="${parentHref}">${parentType}</a> څخه د ماشوم ریکارډ رامینځته کړئ؟`,
	pt: (parentHref, parentType) => html`Talvez você quisesse criar um registro filho a partir da <a href="${parentHref}">${parentType}</a>?`,
	'pt-pt': (parentHref, parentType) => html`Talvez você pretendesse criar um registro de criança a partir da <a href="${parentHref}">${parentType}</a>?`,
	ru: (parentHref, parentType) => html`Возможно, вы имели в виду создать дочернюю запись из <a href="${parentHref}">${parentType}</a>?`,
	sm: (parentHref, parentType) => html`Atonu o le uiga o lau fatuina o se faamaumauga o le tamaitiiti mai le <a href="${parentHref}">${parentType}</a>?`,
	so: (parentHref, parentType) => html`Waxaa laga yaabaa in aad loogu tala galay in lagu abuuro diiwaanka ilmaha ee <a href="${parentHref}">${parentType}</a>?`,
	th: (parentHref, parentType) => html`บางทีคุณอาจตั้งใจจะสร้างบันทึกเด็กจาก <a href="${parentHref}">${parentType}</a>?`,
	ur: (parentHref, parentType) => html`شاید آپ <a href="${parentHref}">${parentType}</a> سے بچے کا ریکارڈ بنانا چاہتے تھے؟`,
	vi: (parentHref, parentType) => html`Có lẽ bạn có ý định tạo một bản ghi con từ <a href="${parentHref}">${parentType}</a>?`,
	'zh-hans': (parentHref, parentType) => html`也许您打算从 <a href="${parentHref}">${parentType}</a>创建子记录？`,
	'zh-hant': (parentHref, parentType) => html`也許您打算從 <a href="${parentHref}">${parentType}</a>創建子記錄？`,
	en: (parentHref, parentType) => html`Perhaps you meant to create a child record from the <a href="${parentHref}">${parentType}</a>?`
};

export const loc_cannotCreateChildActivities: Record<Locale, string> = {
	ar: `لا يمكنك إنشاء سجلات تابعة من هذا النشاط`,
	de: `Sie können keine untergeordneten Datensätze aus dieser Aktivität erstellen`,
	es: `No se pueden crear registros secundarios a partir de esta actividad`,
	fa: `شما نمی توانید رکوردهای فرزند را از این فعالیت ایجاد کنید`,
	fil: `Hindi ka maaaring lumikha ng mga talaan ng bata mula sa aktibidad na ito`,
	fr: `Vous ne pouvez pas créer d’enregistrements enfants à partir de cette activité`,
	hi: `आप इस गतिविधि से चाइल्ड रिकॉर्ड नहीं बना सकते`,
	ht: `Ou pa ka kreye dosye timoun nan aktivite sa a`,
	it: `Non è possibile creare record figlio da questa attività`,
	ja: `この活動から子レコードを作成することはできません`,
	ko: `이 활동에서는 자식 레코드를 만들 수 없습니다`,
	mww: `Koj yuav tsim tau cov ntaub ntawv los ntawm txoj kev ua no`,
	ps: `له دې چارې څخه د ماشومانو د ثبتولو توان نه لرئ`,
	pt: `Não é possível criar registros filhos a partir dessa atividade`,
	'pt-pt': `Não é possível criar registos filho a partir desta atividade`,
	ru: `Вы не можете создать дочерние записи на основе этого действия`,
	sm: `E le mafai ona e fatuina ni faamaumauga o tamaiti mai le gaoioiga lenei`,
	so: `Adigu ma abuuri kartid qoraalka ilmaha ee howshan`,
	th: `คุณไม่สามารถสร้างเรกคอร์ดรองจากกิจกรรมนี้ได้`,
	ur: `آپ اس سرگرمی سے بچوں کا ریکارڈ نہیں بنا سکتے`,
	vi: `Bạn không thể tạo bản ghi con từ hoạt động này`,
	'zh-hans': `您无法从此活动创建子记录`,
	'zh-hant': `您無法從此活動建立子記錄`,
	en: `You cannot create child records from this activity`
};

export const loc_conditionallyReadOnly: Record<Locale, string> = {
	ar: `للقراءة المشروطة فقط`,
	de: `Bedingt schreibgeschützt`,
	es: `Condicionalmente de solo lectura`,
	fa: `فقط خواندنی مشروط`,
	fil: `May kondisyon na nababasa lamang`,
	fr: `Lecture seule conditionnelle`,
	hi: `सशर्त रूप से केवल पढ़ने के लिए`,
	ht: `Kondisyonèlman li-sèlman`,
	it: `Sola lettura condizionale`,
	ja: `条件付き読み取り専用`,
	ko: `조건부 읽기 전용`,
	mww: `Conditionally nyeem-xwb`,
	ps: `شرطیه لوستنی`,
	pt: `Somente leitura condicional`,
	'pt-pt': `Somente leitura condicional`,
	ru: `Условно только для чтения`,
	sm: `Na o le faitauina i tulaga e tatau ai`,
	so: `Shuruudaha akhriska kaliya`,
	th: `อ่านอย่างเดียวตามเงื่อนไข`,
	ur: `مشروط طور پر صرف پڑھیں`,
	vi: `Chỉ đọc có điều kiện`,
	'zh-hans': `有条件只读`,
	'zh-hant': `有條件唯讀`,
	en: `Conditionally read-only`
};

export const loc_conditionallyReadOnlyMessage: Record<Locale, string> = {
	ar: `هذا السجل للقراءة فقط بسبب الشروط التي تم تعيينها من قبل مسؤول المشروع.`,
	de: `Dieser Datensatz ist aufgrund von Bedingungen, die vom Projektadministrator festgelegt wurden, schreibgeschützt.`,
	es: `Este registro es de solo lectura debido a las condiciones establecidas por el administrador del proyecto.`,
	fa: `این رکورد به دلیل شرایط تعیین شده توسط مدیر پروژه فقط خواندنی است.`,
	fil: `Ang talaang ito ay read-only dahil sa mga kondisyong itinakda ng project administrator.`,
	fr: `Cet enregistrement est en lecture seule en raison des conditions définies par l’administrateur du projet.`,
	hi: `यह रिकॉर्ड प्रोजेक्ट व्यवस्थापक द्वारा सेट की गई शर्तों के कारण केवल पढ़ने के लिए है.`,
	ht: `Dosye sa a li-sèlman akòz kondisyon mete pa administratè pwojè a.`,
	it: `Questo record è di sola lettura a causa delle condizioni impostate dall'amministratore del progetto.`,
	ja: `このレコードは、プロジェクト管理者によって設定された条件により、読み取り専用になります。`,
	ko: `이 레코드는 프로젝트 관리자가 설정한 조건으로 인해 읽기 전용입니다.`,
	mww: `Cov ntaub ntawv no yog nyeem tsuas yog vim muaj tej yam mob uas teev cia los ntawm tus thawj tswj tes num.`,
	ps: `دا ریکارډ یوازې د پروژې مدیر لخوا ټاکل شوی شرایطو له امله لوستل کیږی.`,
	pt: `Esse registro é somente leitura devido às condições definidas pelo administrador do projeto.`,
	'pt-pt': `Esse registro é somente leitura devido às condições definidas pelo administrador do projeto.`,
	ru: `Эта запись доступна только для чтения в соответствии с условиями, установленными администратором проекта.`,
	sm: `O lenei faamaumauga e na o le faitauina lava ona o tulaga ua faatulagaina e le taitai o le galuega faatino.`,
	so: `Diiwaankaan waxaa loo aqrinayaa oo keliya sababtoo ah shuruudaha uu dejiyay maamulaha mashruuca.`,
	th: `เรกคอร์ดนี้เป็นแบบอ่านอย่างเดียวเนื่องจากเงื่อนไขที่กําหนดโดยผู้ดูแลระบบโครงการ`,
	ur: `یہ ریکارڈ صرف پروجیکٹ ایڈمنسٹریٹر کی طرف سے مقرر کردہ شرائط کی وجہ سے پڑھا جاتا ہے۔`,
	vi: `Bản ghi này ở chế độ chỉ đọc do các điều kiện do quản trị viên dự án đặt.`,
	'zh-hans': `由于项目管理员设置的条件，此记录是只读的。`,
	'zh-hant': `由於專案管理員設置的條件，此記錄是唯讀的。`,
	en: `This record is read-only due to conditions set by the project administrator.`
};

export const loc_roleReadOnlyMessage: Record<Locale, string> = {
	ar: `لا يسمح لك دورك بتحرير هذا السجل.`,
	de: `Ihre Rolle erlaubt es Ihnen nicht, diesen Datensatz zu bearbeiten.`,
	es: `Su rol no le permite editar este registro.`,
	fa: `نقش شما به شما اجازه ویرایش این رکورد را نمی دهد.`,
	fil: `Hindi ka pinapayagan ng iyong papel na i-edit ang talaang ito.`,
	fr: `Votre rôle ne vous permet pas de modifier cet enregistrement.`,
	hi: `आपकी भूमिका आपको इस रिकॉर्ड को संपादित करने की अनुमति नहीं देती.`,
	ht: `Wòl ou pa pèmèt ou edite dosye sa a.`,
	it: `Il ruolo non consente di modificare questo record.`,
	ja: `あなたのロールでは、このレコードの編集は許可されていません。`,
	ko: `사용자의 역할은 이 레코드를 편집하도록 허용하지 않습니다.`,
	mww: `Koj lub luag hauj lwm tsis pub koj edit cov ntaub ntawv no.`,
	ps: `ستاسو رول تاسو ته اجازه نه ورکوی چې دا ریکارډ ترمیم کړی.`,
	pt: `Sua função não permite que você edite esse registro.`,
	'pt-pt': `A sua função não lhe permite editar este registo.`,
	ru: `Ваша роль не позволяет вам редактировать эту запись.`,
	sm: `E le faatagaina oe e lau matafaioi e faasa'o lenei faamaumauga.`,
	so: `Doorkaagu kuuma ogola in aad tafatirto rikoorkan.`,
	th: `บทบาทของคุณไม่อนุญาตให้คุณแก้ไขเรกคอร์ดนี้`,
	ur: `آپ کا کردار آپ کو اس ریکارڈ میں ترمیم کرنے کی اجازت نہیں دیتا۔`,
	vi: `Vai trò của bạn không cho phép bạn chỉnh sửa bản ghi này.`,
	'zh-hans': `您的角色不允许您编辑此记录。`,
	'zh-hant': `您的角色不允許您編輯此記錄。`,
	en: `Your role does not permit you to edit this record.`
};

export const loc_all: Record<Locale, string> = {
	ar: `كل`,
	de: `Alle`,
	es: `Todo`,
	fa: `همه`,
	fil: `Lahat ng`,
	fr: `Tout`,
	hi: `सब`,
	ht: `Tout`,
	it: `Tutto`,
	ja: `すべての`,
	ko: `모두`,
	mww: `Tag nrho`,
	ps: `ټول`,
	pt: `Todo`,
	'pt-pt': `Tudo`,
	ru: `Все`,
	sm: `Uma`,
	so: `Alle`,
	th: `ทั้งหมด`,
	ur: `تمام`,
	vi: `Tất cả`,
	'zh-hans': `都`,
	'zh-hant': `都`,
	en: `All`
};

export const loc_agency: Record<Locale, string> = {
	ar: `وكالة`,
	de: `Agentur`,
	es: `Agencia`,
	fa: `آژانس`,
	fil: `Kalayaan sa Pagpili`,
	fr: `Agence`,
	hi: `सरकारी एजेंसी`,
	ht: `Ajans`,
	it: `Agenzia`,
	ja: `エージェンシー`,
	ko: `기관`,
	mww: `Chaw ua hauj lwm`,
	ps: `اداره`,
	pt: `Agência`,
	'pt-pt': `Agência`,
	ru: `Агентство`,
	sm: `Faitalia`,
	so: `Hay'adda`,
	th: `บริษัทตัวแทน`,
	ur: `ایجنسی`,
	vi: `Cơ quan`,
	'zh-hans': `机构`,
	'zh-hant': `機構`,
	en: `Agency`
};

export const loc_organizationAgencyDesc: Record<Locale, string> = {
	ar: `مؤسسة مشاركة في المشروع لا يمتلك مستخدموها حسابات Cumulus.`,
	de: `Eine Organisation, die an dem Projekt beteiligt ist und deren Benutzer kein Cumulus-Konto haben.`,
	es: `Una organización que participa en el proyecto cuyos usuarios no tienen cuentas de Cumulus.`,
	fa: `سازمانی که در پروژه شرکت می کند و کاربران آن حساب کومولوس ندارند.`,
	fil: `Isang organisasyon na nakikibahagi sa proyekto na ang mga gumagamit ay walang mga account sa Cumulus.`,
	fr: `Une organisation participant au projet dont les utilisateurs n’ont pas de compte Cumulus.`,
	hi: `परियोजना में भाग लेने वाला एक संगठन जिसके उपयोगकर्ताओं के पास Cumulus खाते नहीं हैं.`,
	ht: `Yon òganizasyon k ap patisipe nan pwojè a ki gen itilizatè yo pa gen kont Culus.`,
	it: `Un'organizzazione che partecipa al progetto i cui utenti non dispongono di account Cumulus.`,
	ja: `プロジェクトに参加している組織で、ユーザが Cumulus アカウントを持っていない人。`,
	ko: `프로젝트에 참여하고 있으며 사용자에게 Cumulus 계정이 없는 조직입니다.`,
	mww: `Ib lub koom haum koom tes hauv peb tes num uas nws cov neeg siv tsis muaj Cumulus nyiaj.`,
	ps: `یو سازمان چې په پروژه کې برخه اخلی چې کاروونکی یې د کمولس حسابونه نلری.`,
	pt: `Uma organização participante do projeto cujos usuários não têm contas Cumulus.`,
	'pt-pt': `Uma organização participante do projeto cujos usuários não têm contas Cumulus.`,
	ru: `Организация, участвующая в проекте, у пользователей которой нет учетных записей Cumulus.`,
	sm: `O se faalapotopotoga o loo auai i le galuega faatino e le o i ai ni teugatupe a tagata faaaoga o le Cumulus.`,
	so: `Hay'ad ka qaybqaadanaysa mashruuca oo dadka isticmaala aysan laheyn xisaabaadka Cumulus.`,
	th: `องค์กรที่เข้าร่วมในโครงการที่ผู้ใช้ไม่มีบัญชี Cumulus`,
	ur: `اس منصوبے میں حصہ لینے والی ایک تنظیم جس کے صارفین کے پاس کمولس اکاؤنٹ نہیں ہیں۔`,
	vi: `Một tổ chức tham gia dự án có người dùng không có tài khoản Cumulus.`,
	'zh-hans': `参与项目的组织，其用户没有 Cumulus 账户。`,
	'zh-hant': `參與項目的組織，其用戶沒有 Cumulus 帳戶。`,
	en: `An organization participating in the project whose users do not have Cumulus accounts.`
};

export const loc_organizationSiteDesc: Record<Locale, string> = {
	ar: `موقع تجري فيه المنظمة عملياتها.`,
	de: `Ein Ort, an dem eine Organisation Operationen durchführt.`,
	es: `Ubicación en la que una organización lleva a cabo operaciones.`,
	fa: `مکانی که یک سازمان در آن عملیات انجام می دهد.`,
	fil: `Isang lokasyon kung saan ang isang organisasyon ay nagsasagawa ng mga operasyon.`,
	fr: `Lieu où une organisation exerce ses activités.`,
	hi: `वह स्थान जहाँ एक संगठन संचालन करता है।`,
	ht: `Yon kote yon òganizasyon ap fè operasyon.`,
	it: `Luogo in cui un'organizzazione esegue operazioni.`,
	ja: `組織が業務を行う場所。`,
	ko: `조직이 작업을 수행하는 위치입니다.`,
	mww: `Ib qhov chaw uas ib lub koom haum ua haujlwm.`,
	ps: `دا هغه ځای دی چیرې چې یو سازمان فعالیت کوی.`,
	pt: `Um local onde uma organização realiza operações.`,
	'pt-pt': `Um local onde uma organização realiza operações.`,
	ru: `Местоположение, в котором организация осуществляет свою деятельность.`,
	sm: `O se nofoaga e faatautaia ai e se faalapotopotoga le faagaoioiga.`,
	so: `Goob ay hayʼad howlgalo ka fuliso.`,
	th: `สถานที่ที่องค์กรดําเนินงาน`,
	ur: `ایک ایسی جگہ جہاں کوئی تنظیم آپریشن کرتی ہے۔`,
	vi: `Một địa điểm nơi một tổ chức tiến hành các hoạt động.`,
	'zh-hans': `组织执行操作的位置。`,
	'zh-hant': `組織執行操作的位置。`,
	en: `A location where an organization conducts operations.`
};

export const loc_organizationPeriodDesc: Record<Locale, string> = {
	ar: `إطار زمني يستخدم لمواءمة تواريخ بدء النشاط وانتهائه مع نطاق زمني محدد.`,
	de: `Ein Zeitrahmen, der verwendet wird, um das Start- und Enddatum der Aktivität an einem bestimmten Zeitraum auszurichten.`,
	es: `Un período de tiempo que se utiliza para alinear las fechas de inicio y finalización de la actividad con un intervalo de fechas específico.`,
	fa: `یک بازه زمانی که برای تراز کردن تاریخ شروع و پایان فعالیت با یک محدوده تاریخ خاص استفاده می شود.`,
	fil: `Isang time frame na ginagamit upang ihanay ang mga petsa ng pagsisimula at pagtatapos ng aktibidad sa isang tiyak na hanay ng petsa.`,
	fr: `Plage horaire utilisée pour aligner les dates de début et de fin d’activité sur une plage de dates spécifique.`,
	hi: `गतिविधि प्रारंभ और समाप्ति दिनांकों को एक विशिष्ट दिनांक सीमा में संरेखित करने के लिए उपयोग की जाने वाली समय-सीमा.`,
	ht: `Yon ankram alè itilize nan aliyen aktivite kòmanse ak fen dat nan yon seri dat espesifik.`,
	it: `Un intervallo di tempo utilizzato per allineare le date di inizio e fine dell'attività a un intervallo di date specifico.`,
	ja: `アクティビティの開始日と終了日を特定の日付範囲に合わせるために使用される時間枠。`,
	ko: `활동 시작 및 종료 날짜를 특정 날짜 범위에 맞추는 데 사용되는 시간 프레임입니다.`,
	mww: `Ib lub sij hawm uas siv los align kev ua ub no pib thiab xaus rau ib hnub twg.`,
	ps: `یو وخت چوکاټ د فعالیت پیل او پای نیټې مشخص نیټې ته د تنظیم کولو لپاره کارول کیږی.`,
	pt: `Um período de tempo usado para alinhar as datas de início e término da atividade a um intervalo de datas específico.`,
	'pt-pt': `Um período de tempo usado para alinhar as datas de início e término da atividade a um intervalo de datas específico.`,
	ru: `Временной интервал, используемый для привязки дат начала и окончания действия к определенному диапазону дат.`,
	sm: `O se taimi e faaaoga e faaogatusa ai aso amata ma aso faaiu o gaoioiga i se vaega patino o aso.`,
	so: `Waqti-go'an oo loo isticmaalo in lays waafajiyo bilawga dhaqdhaqaaqa iyo gabagabeyn taariikheed ilaa taariikh gaar ah.`,
	th: `กรอบเวลาที่ใช้ในการจัดตําแหน่งวันที่เริ่มต้นและสิ้นสุดของกิจกรรมให้ตรงกับช่วงวันที่ที่ระบุ`,
	ur: `ایک ٹائم فریم جو سرگرمی کے آغاز اور اختتام کی تاریخوں کو ایک مخصوص تاریخ کی حد سے ہم آہنگ کرنے کے لئے استعمال ہوتا ہے۔`,
	vi: `Khung thời gian được sử dụng để căn chỉnh ngày bắt đầu và ngày kết thúc hoạt động theo một phạm vi ngày cụ thể.`,
	'zh-hans': `用于将活动开始日期和结束日期与特定日期范围对齐的时间范围。`,
	'zh-hant': `用於將活動開始日期和結束日期與特定日期範圍對齊的時間範圍。`,
	en: `A time-frame used to align activity start and end dates to a specific date range.`
};

export const loc_allAgencies: Record<Locale, string> = {
	ar: `جميع الوكالات`,
	de: `Alle Agenturen`,
	es: `Todas las agencias`,
	fa: `همه آژانس ها`,
	fil: `Lahat ng ahensya`,
	fr: `Toutes les agences`,
	hi: `सभी एजेंसियां`,
	ht: `Tout ajans yo`,
	it: `Tutte le agenzie`,
	ja: `すべての代理店`,
	ko: `모든 에이전시`,
	mww: `Tag nrho cov koom haum`,
	ps: `ټولې ادارې`,
	pt: `Todas as agências`,
	'pt-pt': `Todas as agências`,
	ru: `Все агентства`,
	sm: `Ofisa uma`,
	so: `Dhamaan hay'adaha`,
	th: `ทุกหน่วยงาน`,
	ur: `تمام ایجنسیاں`,
	vi: `Tất cả các cơ quan`,
	'zh-hans': `所有机构`,
	'zh-hant': `所有機構`,
	en: `All agencies`
};

export const loc_oneActivity: Record<Locale, string> = {
	ar: `1 نشاط`,
	de: `1 Aktivität`,
	es: `1 actividad`,
	fa: `1 فعالیت`,
	fil: `1 aktibidad`,
	fr: `1 activité`,
	hi: `1 गतिविधि`,
	ht: `1 aktivite`,
	it: `1 attività`,
	ja: `アクティビティ1`,
	ko: `액티비티 1개`,
	mww: `1 yam kev ua ub no`,
	ps: `1 فعالیت`,
	pt: `1 atividade`,
	'pt-pt': `1 atividade`,
	ru: `1 задание`,
	sm: `1 gaoioiga`,
	so: `1 shaqo`,
	th: `1 กิจกรรม`,
	ur: `1 سرگرمی`,
	vi: `1 Hoạt động`,
	'zh-hans': `1 次活动`,
	'zh-hant': `1 次活動`,
	en: `1 activity`
};

export const loc_activitiesFormat: Record<Locale, (count: unknown) => string> = {
	ar: (count) => `${count} أنشطة`,
	de: (count) => `${count} Aktivitäten`,
	es: (count) => `${count} actividades`,
	fa: (count) => `${count} فعالیت`,
	fil: (count) => `${count} Mga gawain`,
	fr: (count) => `${count} activités`,
	hi: (count) => `${count} गतिविधियां`,
	ht: (count) => `${count} aktivite`,
	it: (count) => `${count} attività`,
	ja: (count) => `${count} 件`,
	ko: (count) => `${count} 활동`,
	mww: (count) => `${count} kev ua ub no`,
	ps: (count) => `${count} فعالیتونه`,
	pt: (count) => `${count} atividades`,
	'pt-pt': (count) => `${count} atividades`,
	ru: (count) => `${count}`,
	sm: (count) => `${count} gaoioiga`,
	so: (count) => `${count}`,
	th: (count) => `${count} กิจกรรม`,
	ur: (count) => `${count}`,
	vi: (count) => `${count} Hoạt động`,
	'zh-hans': (count) => `${count} 活动`,
	'zh-hant': (count) => `${count} 活動`,
	en: (count) => `${count} activities`
};

export const loc_noActivities: Record<Locale, string> = {
	ar: `لا توجد أنشطة`,
	de: `Keine Aktivitäten`,
	es: `Ninguna actividad`,
	fa: `بدون فعالیت`,
	fil: `Walang mga aktibidad`,
	fr: `Aucune activité`,
	hi: `कोई गतिविधि नहीं`,
	ht: `Pa gen aktivite`,
	it: `Nessuna attività`,
	ja: `活動なし`,
	ko: `활동 없음`,
	mww: `Tsis muaj kev ua ub no`,
	ps: `هیڅ فعالیت نشته`,
	pt: `Sem atividades`,
	'pt-pt': `Sem atividades`,
	ru: `Нет активностей`,
	sm: `Leai ni gaoioiga`,
	so: `Wax shaqo ahna ma qabanqaab`,
	th: `ไม่มีกิจกรรม`,
	ur: `کوئی سرگرمی نہیں`,
	vi: `Không có hoạt động`,
	'zh-hans': `无活动`,
	'zh-hant': `無活動`,
	en: `No activities`
};

export const loc_period: Record<Locale, string> = {
	ar: `مرحلة زمنية`,
	de: `Periode`,
	es: `Periodo`,
	fa: `دوره`,
	fil: `Panahon ng`,
	fr: `Période`,
	hi: `काल`,
	ht: `Peryòd`,
	it: `Periodo`,
	ja: `時代`,
	ko: `마침표`,
	mww: `caij`,
	ps: `دوره`,
	pt: `Período`,
	'pt-pt': `Ponto final`,
	ru: `Период`,
	sm: `Vaitaimi`,
	so: `Mudada`,
	th: `ช่วง`,
	ur: `دور`,
	vi: `Thì`,
	'zh-hans': `时期`,
	'zh-hant': `時期`,
	en: `Period`
};

export const loc_allPeriods: Record<Locale, string> = {
	ar: `جميع الفترات`,
	de: `Alle Zeiträume`,
	es: `Todos los períodos`,
	fa: `همه دوره ها`,
	fil: `Lahat ng panahon`,
	fr: `Toutes les périodes`,
	hi: `सभी अवधि`,
	ht: `Tout peryòd`,
	it: `Tutti i periodi`,
	ja: `すべての期間`,
	ko: `모든 기간`,
	mww: `Txhua lub sij hawm`,
	ps: `ټولې دورې`,
	pt: `Todos os períodos`,
	'pt-pt': `Todos os períodos`,
	ru: `Все периоды`,
	sm: `Vaitaimi uma`,
	so: `Dhammaan muddooyinka`,
	th: `ทุกช่วงเวลา`,
	ur: `تمام ادوار`,
	vi: `Tất cả các thời kỳ`,
	'zh-hans': `所有期间`,
	'zh-hant': `所有期間`,
	en: `All periods`
};

export const loc_printSummaryWithHistory: Record<Locale, string> = {
	ar: `طباعة ملخص مع التاريخ`,
	de: `Zusammenfassung mit Historie drucken`,
	es: `Imprimir resumen con historial`,
	fa: `خلاصه را با تاریخچه چاپ کنید`,
	fil: `I-print ang buod na may kasaysayan`,
	fr: `Imprimer le résumé avec l’historique`,
	hi: `इतिहास के साथ सारांश प्रिंट करें`,
	ht: `Rezime enprime ak istwa`,
	it: `Stampa il riepilogo con la cronologia`,
	ja: `履歴付きの要約の印刷`,
	ko: `기록이 있는 요약 인쇄(Print summary with history)`,
	mww: `Sau ntawv nrog keeb kwm`,
	ps: `لنډیز له مخینې سره چاپ کړئ`,
	pt: `Imprimir resumo com histórico`,
	'pt-pt': `Imprimir resumo com histórico`,
	ru: `Распечатать сводку с историей`,
	sm: `Lolomi le aotelega ma le talafaasolopito`,
	so: `Kooban kooban oo leh taariikh`,
	th: `พิมพ์สรุปพร้อมประวัติ`,
	ur: `تاریخ کے ساتھ خلاصہ پرنٹ کریں`,
	vi: `In tóm tắt với lịch sử`,
	'zh-hans': `打印带历史记录的摘要`,
	'zh-hant': `列印帶歷史記錄的摘要`,
	en: `Print summary with history`
};

export const loc_sendForSignature: Record<Locale, string> = {
	ar: `إرسال للتوقيع`,
	de: `Zur Signatur senden`,
	es: `Enviar para firmar`,
	fa: `ارسال برای امضا`,
	fil: `Ipadala para sa lagda`,
	fr: `Envoyer pour signature`,
	hi: `हस्ताक्षर के लिए भेजें`,
	ht: `Voye pou siyati`,
	it: `Invia per firma`,
	ja: `署名用に送信`,
	ko: `서명을 위해 전송`,
	mww: `Xa npe rau kos npe`,
	ps: `د لاسلیک لپاره استول`,
	pt: `Enviar para assinatura`,
	'pt-pt': `Enviar para assinatura`,
	ru: `Отправить на подпись`,
	sm: `Auina atu mo le saini`,
	so: `U dir saxiix`,
	th: `ส่งเพื่อขอลายเซ็น`,
	ur: `دستخط کے لئے بھیجیں`,
	vi: `Gửi để có chữ ký`,
	'zh-hans': `发送以供签名`,
	'zh-hant': `發送以供簽名`,
	en: `Send for signature`
};

export const loc_sendForSignatureDesc: Record<Locale, string> = {
	ar: `أرسل إلى شخص ما رابطا لتوقيع هذا السجل إلكترونيا.`,
	de: `Senden Sie jemandem einen Link, um diesen Datensatz elektronisch zu signieren.`,
	es: `Envíe a alguien un enlace para firmar este registro electrónicamente.`,
	fa: `برای امضای این رکورد به صورت الکترونیکی برای کسی پیوند ارسال کنید.`,
	fil: `Magpadala ng link sa isang tao para lagdaan ang talaang ito sa elektronikong paraan.`,
	fr: `Envoyez à quelqu’un un lien pour signer ce document électroniquement.`,
	hi: `इलेक्ट्रॉनिक रूप से इस रिकॉर्ड पर हस्ताक्षर करने के लिए किसी को लिंक भेजें.`,
	ht: `Voye yon moun yon lyen pou siyen dosye sa a elektwonikman.`,
	it: `Invia a qualcuno un link per firmare questo record elettronicamente.`,
	ja: `このレコードに電子署名するためのリンクを他のユーザーに送信します。`,
	ko: `이 기록에 전자적으로 서명할 수 있는 링크를 다른 사람에게 보냅니다.`,
	mww: `Xa ib qhov txuas mus kos npe rau daim ntawv no electronically.`,
	ps: `یو چا ته لینک واستوئ چې دا ریکارډ په بریښنایی ډول لاسلیک کړی.`,
	pt: `Envie a alguém um link para assinar este registro eletronicamente.`,
	'pt-pt': `Envie a alguém um link para assinar este registro eletronicamente.`,
	ru: `Отправьте кому-нибудь ссылку для подписания этой записи в электронном виде.`,
	sm: `Auina atu i se tasi se sootaga e saini ai lenei faamaumauga faaeletonika.`,
	so: `U dir qof isku xir si aad u saxiixdo rikoodhkan elektaroonik ahaan.`,
	th: `ส่งลิงก์ให้ผู้อื่นเพื่อลงนามในบันทึกนี้ทางอิเล็กทรอนิกส์`,
	ur: `اس ریکارڈ پر الیکٹرانک طور پر دستخط کرنے کے لئے کسی کو لنک بھیجیں۔`,
	vi: `Gửi cho ai đó một liên kết để ký bản ghi này bằng điện tử.`,
	'zh-hans': `向某人发送链接以电子方式签署此记录。`,
	'zh-hant': `向某人發送連結以電子方式簽署此記錄。`,
	en: `Send someone a link to sign this record electronically.`
};

export const loc_signWithDefaultSignature: Record<Locale, string> = {
	ar: `التوقيع باستخدام توقيعك الافتراضي`,
	de: `Signieren Sie mit Ihrer Standardsignatur`,
	es: `Firmar con su firma predeterminada`,
	fa: `با امضای پیش فرض خود امضا کنید`,
	fil: `Mag sign gamit ang iyong default na lagda`,
	fr: `Signez avec votre signature par défaut`,
	hi: `अपने डिफ़ॉल्ट हस्ताक्षर के साथ हस्ताक्षर करें`,
	ht: `Siyen ak siyati default ou`,
	it: `Firma con la tua firma predefinita`,
	ja: `デフォルトの署名で署名する`,
	ko: `기본 서명으로 서명합니다.`,
	mww: `Kos npe rau koj cov default kos npe`,
	ps: `د خپل تلواله لاسلیک سره لاسلیک کړئ`,
	pt: `Assinar com sua assinatura padrão`,
	'pt-pt': `Assine com sua assinatura padrão`,
	ru: `Подписание с помощью подписи по умолчанию`,
	sm: `Saini i lau saini Tiumasani`,
	so: `Saxiix la saxiixa default aad`,
	th: `ลงนามด้วยลายเซ็นเริ่มต้น`,
	ur: `اپنے طے شدہ دستخط کے ساتھ دستخط کریں`,
	vi: `Ký bằng chữ ký mặc định của bạn`,
	'zh-hans': `使用您的默认签名签名`,
	'zh-hant': `使用您的預設簽名簽名`,
	en: `Sign with your default signature`
};

export const loc_linkExpired: Record<Locale, string> = {
	ar: `انتهت صلاحية الرابط`,
	de: `Link abgelaufen`,
	es: `Enlace caducado`,
	fa: `لینک منقضی شده است`,
	fil: `Nag expire na ang link`,
	fr: `Lien expiré`,
	hi: `लिंक की समय सीमा समाप्त हो गई`,
	ht: `Lyen ekspirasyon`,
	it: `Link scaduto`,
	ja: `リンクの有効期限が切れています`,
	ko: `링크가 만료되었습니다.`,
	mww: `Txuas ntxiv mus`,
	ps: `تړنه تېر مهاله`,
	pt: `Link expirado`,
	'pt-pt': `Link expirado`,
	ru: `Срок действия ссылки истек`,
	sm: `Ua uma le aoga o le sootaga`,
	so: `Link-ka dhacay`,
	th: `ลิงก์หมดอายุ`,
	ur: `لنک ختم ہو گیا`,
	vi: `Liên kết đã hết hạn`,
	'zh-hans': `链接已过期`,
	'zh-hant': `連結已過期`,
	en: `Link expired`
};

export const loc_linkExpiredFormat: Record<Locale, (when: unknown) => TemplateResult> = {
	ar: (when) => html`<span>انتهت صلاحية الرابط ${when}</span>`,
	de: (when) => html`<span>Link ${when}</span>`,
	es: (when) => html`<span>El enlace caducó ${when}</span>`,
	fa: (when) => html`<span>لینک منقضی ${when}</span>`,
	fil: (when) => html`<span>Link expired ${when}</span>`,
	fr: (when) => html`<span>Lien expiré ${when}</span>`,
	hi: (when) => html`<span>लिंक की समय सीमा समाप्त ${when}</span>`,
	ht: (when) => html`<span>Lyen ${when}</span>`,
	it: (when) => html`<span>Link scaduto ${when}</span>`,
	ja: (when) => html`<span>リンクの有効期限が切れました ${when}</span>`,
	ko: (when) => html`<span>링크가 ${when} 만료되었습니다.</span>`,
	mww: (when) => html`<span>Txuas expired ${when}</span>`,
	ps: (when) => html`<span>لینک ${when} پای ته ورسید</span>`,
	pt: (when) => html`<span>Link expirou ${when}</span>`,
	'pt-pt': (when) => html`<span>Link expirou ${when}</span>`,
	ru: (when) => html`<span>Срок действия ссылки истек ${when}</span>`,
	sm: (when) => html`<span>Ua uma le aoga o le sootaga i le ${when}</span>`,
	so: (when) => html`<span>Link-ku wuxuu dhacay ${when}</span>`,
	th: (when) => html`<span>ลิงก์หมดอายุเมื่อ ${when}</span>`,
	ur: (when) => html`<span>لنک ${when} ختم ہو گیا</span>`,
	vi: (when) => html`<span>Liên kết đã hết hạn ${when}</span>`,
	'zh-hans': (when) => html`<span>链接在 ${when}过期</span>`,
	'zh-hant': (when) => html`<span>連結在 ${when}過期</span>`,
	en: (when) => html`<span>Link expired ${when}</span>`
};

export const loc_copyLink: Record<Locale, string> = {
	ar: `نسخ الرابط`,
	de: `Link kopieren`,
	es: `Copiar enlace`,
	fa: `کپی لینک`,
	fil: `Kopyahin ang link`,
	fr: `Copier le lien`,
	hi: `लिंक कॉपी करें`,
	ht: `Kopi lyen`,
	it: `Copia link`,
	ja: `リンクをコピー`,
	ko: `링크 복사`,
	mww: `Luam ib daim qauv ntawm daim qauv`,
	ps: `پیوند لمېسل`,
	pt: `Copiar link`,
	'pt-pt': `Copiar link`,
	ru: `Скопировать ссылку`,
	sm: `Kopi le sootaga`,
	so: `Koobi link`,
	th: `คัดลอกลิงค์`,
	ur: `کاپی لنک`,
	vi: `Sao chép liên kết`,
	'zh-hans': `复制链接`,
	'zh-hant': `複製連結`,
	en: `Copy link`
};

export const loc_error: Record<Locale, string> = {
	ar: `خطأ`,
	de: `Fehler`,
	es: `Error`,
	fa: `خطا`,
	fil: `Mali`,
	fr: `Erreur`,
	hi: `चूक`,
	ht: `Erè`,
	it: `Errore`,
	ja: `エラー`,
	ko: `오류`,
	mww: `Yuam kev`,
	ps: `تېروتنه`,
	pt: `Erro`,
	'pt-pt': `Erro`,
	ru: `Ошибка`,
	sm: `Mea Sese`,
	so: `Khalad`,
	th: `ความผิดพลาด`,
	ur: `غلطی`,
	vi: `Lỗi`,
	'zh-hans': `错误`,
	'zh-hant': `錯誤`,
	en: `Error`
};

export const loc_important: Record<Locale, string> = {
	ar: `مهم`,
	de: `Wichtig`,
	es: `Importante`,
	fa: `مهم`,
	fil: `Mahalaga`,
	fr: `Important`,
	hi: `महत्वपूर्ण`,
	ht: `Enpòtan`,
	it: `Importante`,
	ja: `大事な`,
	ko: `중요하다`,
	mww: `Tseem ceeb`,
	ps: `اړین`,
	pt: `Importante`,
	'pt-pt': `Importante`,
	ru: `Важный`,
	sm: `Taua`,
	so: `Muhiim`,
	th: `สําคัญ`,
	ur: `اہم`,
	vi: `Quan trọng`,
	'zh-hans': `重要`,
	'zh-hant': `重要`,
	en: `Important`
};

export const loc_signaturePageInstructions: Record<Locale, (senderName: unknown, senderGivenName: unknown, senderEmail: unknown, expires: unknown) => TemplateResult> = {
	ar: (senderName, senderGivenName, senderEmail, expires) => html`أرسل ${senderName} هذا النموذج للتوقيع. راجع المعلومات أدناه وقم <a href="#sign-button">بالتوقيع في الأسفل</a>. إذا كانت لديك أسئلة ، فاتصل ${senderGivenName} عبر البريد الإلكتروني على <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. ستنتهي صلاحية هذا النموذج ${expires}.`,
	de: (senderName, senderGivenName, senderEmail, expires) => html`${senderName} hat Ihnen dieses Formular zur Unterschrift zugesandt. Überprüfen Sie die Informationen unten und <a href="#sign-button">unterschreiben Sie unten</a>. Wenn Sie Fragen haben, wenden Sie sich bitte per E-Mail an ${senderGivenName} <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. Dieses Formular läuft ${expires} ab.`,
	es: (senderName, senderGivenName, senderEmail, expires) => html`${senderName} le envió este formulario para que lo firmara. Revise la información a continuación y <a href="#sign-button">firme en la parte inferior</a>. Si tiene preguntas, comuníquese con ${senderGivenName} por correo electrónico a <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. Este formulario caducará ${expires}`,
	fa: (senderName, senderGivenName, senderEmail, expires) => html`${senderName} این فرم را برای شما ارسال کرد تا امضا کنید. اطلاعات زیر را مرور کنید و <a href="#sign-button">در پایین امضا</a> کنید. اگر سوالی دارید ${senderGivenName} ایمیل با <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. این فرم ${expires} منقضی می شود.`,
	fil: (senderName, senderGivenName, senderEmail, expires) => html`Ipinadala sa inyo ni ${senderName} ang form na ito para lagdaan. Repasuhin ang impormasyon sa ibaba at <a href="#sign-button">lagdaan sa ibaba</a>. Kung mayroon kang mga katanungan, makipag ugnay kay ${senderGivenName} sa pamamagitan ng email sa <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. Ang form na ito ay mag eexpire ${expires}.`,
	fr: (senderName, senderGivenName, senderEmail, expires) => html`${senderName} vous a envoyé ce formulaire à signer. Vérifiez les informations ci-dessous et <a href="#sign-button">signez en bas</a>. Si vous avez des questions, contactez ${senderGivenName} par e-mail à <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. Ce formulaire expirera ${expires}`,
	hi: (senderName, senderEmail, senderGivenName, expires) => html`${senderName} ने आपको हस्ताक्षर करने के लिए यह फॉर्म भेजा था। नीचे दी गई जानकारी की समीक्षा करें और <a href="#sign-button">नीचे हस्ताक्षर करें</a>। यदि आपके कोई प्रश्न हैं, तो <a class="Link--primary" href="${senderEmail}">${senderEmail}</a> पर ईमेल के माध्यम से ${senderGivenName} से संपर्क करें। यह फॉर्म ${expires} समाप्त हो जाएगा।`,
	ht: (senderName, senderGivenName, senderEmail, expires) => html`${senderName} te voye ou fòm sa a pou siyen. Revize enfòmasyon ki anba a epi <a href="#sign-button">siyen nan pati anba a</a>. Si ou gen kesyon, kontakte ${senderGivenName} atravè imèl nan <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. Fòm sa a pral ekspilse ${expires}.`,
	it: (senderName, senderGivenName, senderEmail, expires) => html`${senderName} ti ha inviato questo modulo da firmare. Rivedi le informazioni di seguito e <a href="#sign-button">firma in fondo</a>. In caso di domande, contattare ${senderGivenName} via e-mail <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. Questo modulo scadrà ${expires}`,
	ja: (senderName, senderEmail, senderGivenName, expires) => html`${senderName}、署名のためにこのフォームを送った。以下の情報を確認し、<a href="#sign-button">下部に署名してください</a>。ご不明な点がございましたら、<a class="Link--primary" href="${senderEmail}">${senderEmail}</a> までメールで${senderGivenName}。このフォームの有効期限は${expires} です。`,
	ko: (senderName, senderEmail, senderGivenName, expires) => html`${senderName} 서명하기 위해 이 양식을 보냈습니다. 아래 정보를 검토하고 <a href="#sign-button">하단에 서명하십시오</a>. 질문이 있는 경우 <a class="Link--primary" href="${senderEmail}">${senderEmail}</a> 로 이메일을 통해 ${senderGivenName}에게 문의하십시오. 이 양식은 ${expires} 만료됩니다.`,
	mww: (senderName, senderGivenName, senderEmail, expires) => html`${senderName} xa koj daim ntawv no kos npe. Saib cov lus hauv qab no thiab <a href="#sign-button">kos npe rau hauv qab</a> no. Yog koj muaj lus nug, hu ${senderGivenName} ntawm email ntawm <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. Daim ntawv no yuav tshwm sim ${expires}.`,
	ps: (senderName, senderEmail, senderGivenName, expires) => html`${senderName} تاسو ته د لاسلیک کولو لپاره دا فورمه واستوله. لاندې معلومات وګورئ او <a href="#sign-button">په لاندې برخه کې لاسلیک وکړئ</a>. که تاسو کومه پوښتنه لرئ ، په <a class="Link--primary" href="${senderEmail}">${senderEmail}</a> بریښنالیک له لارې ${senderGivenName} سره اړیکه ونیسئ. دا فورمه به سبا پای ته ورسیږی ${expires}`,
	pt: (senderName, senderGivenName, senderEmail, expires) => html`${senderName} enviou-lhe este formulário para assinar. Revise as informações abaixo e <a href="#sign-button">assine na parte inferior</a>. Se você tiver dúvidas, entre em contato com ${senderGivenName} por e-mail em <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. Este formulário expirará ${expires}`,
	'pt-pt': (senderName, senderGivenName, senderEmail, expires) => html`${senderName} enviou-lhe este formulário para assinar. Reveja as informações abaixo e <a href="#sign-button">assine na parte inferior</a>. Em caso de dúvidas, entre em contato com ${senderGivenName} por e-mail no <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. Este formulário expira ${expires}`,
	ru: (senderName, senderGivenName, senderEmail, expires) => html`${senderName} отправил вам эту форму для подписи. Ознакомьтесь с приведенной ниже информацией и <a href="#sign-button">подпишитесь внизу</a>. Если у вас есть вопросы, свяжитесь с ${senderGivenName} по электронной почте <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. Срок действия этой формы истекает ${expires}`,
	sm: (senderName, senderGivenName, senderEmail, expires) => html`Na auina atu e ${senderName} le fomu lenei e saini ai. Toe iloilo faamatalaga o loo i lalo ma <a href="#sign-button">saini i le pito i lalo</a>. Afai e i ai ni au fesili, faafesootai ${senderGivenName} e ala i le imeli i le <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. O le a faamutaina le aoga o le fomu lenei ${expires}.`,
	so: (senderName, senderGivenName, senderEmail, expires) => html`${senderName} ayaa qaabkan kuu soo diray inaad saxiixdo. Dib u eegis macluumaadka hoose iyo <a href="#sign-button">calaamad ugu hooseysa</a>. Haddii aad qabto su'aalo, la xiriir ${senderGivenName} via email <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. Foomkani wuxuu dhacayaa ${expires}.`,
	th: (senderName, senderGivenName, senderEmail, expires) => html`${senderName}แบบฟอร์มนี้ให้คุณลงนาม ตรวจสอบข้อมูลด้านล่างและ<a href="#sign-button">ลงชื่อที่ด้านล่าง</a> หากคุณมีคําถาม โปรดติดต่อ${senderGivenName}ทางอีเมลที่ <a class="Link--primary" href="${senderEmail}">${senderEmail}</a> แบบฟอร์มนี้จะหมดอายุในวัน${expires}`,
	ur: (senderName, senderGivenName, senderEmail, expires) => html`${senderName} نے آپ کو دستخط کرنے کے لئے یہ فارم بھیجا تھا۔ نیچے دی گئی معلومات کا جائزہ لیں اور <a href="#sign-button">نیچے دستخط کریں</a>۔ اگر آپ کے سوالات ہیں تو، john.smith@example.com پر ای میل کے ذریعہ ${senderGivenName} سے رابطہ <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. یہ فارم ${expires} ختم ہو جائے گا۔`,
	vi: (senderName, senderGivenName, senderEmail, expires) => html`${senderName} đã gửi cho bạn mẫu đơn này để ký. Xem lại thông tin bên dưới và <a href="#sign-button">ký ở dưới cùng</a>. Nếu bạn có thắc mắc, hãy liên hệ với ${senderGivenName} qua email tại <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. Mẫu đơn này sẽ hết hạn ${expires}.`,
	'zh-hans': (senderName, senderEmail, senderGivenName, expires) => html`${senderName} 将这份表格发送给您签名。查看以下信息并在<a href="#sign-button">底部签名</a>。如果您有任何疑问，请发送电子邮件至 <a class="Link--primary" href="${senderEmail}">${senderEmail}</a> 联系 ${senderGivenName}。此表格将于${expires}）到期。`,
	'zh-hant': (senderName, senderEmail, senderGivenName, expires) => html`${senderName} 將這份表格發送給您簽名。查看以下資訊並在<a href="#sign-button">底部簽名</a>。如果您有任何疑問，請發送電子郵件至 <a class="Link--primary" href="${senderEmail}">${senderEmail}</a> 聯繫 ${senderGivenName}。此表格將於${expires}）到期。`,
	en: (senderName, senderGivenName, senderEmail, expires) => html`${senderName} sent you this form to sign. Review the information below and <a href="#sign-button">sign at the bottom</a>. If you have questions, contact ${senderGivenName} via email at <a class="Link--primary" href="${senderEmail}">${senderEmail}</a>. This form will expire ${expires}.`
};

export const loc_helpWithThisPage: Record<Locale, string> = {
	ar: `مساعدة في هذه الصفحة`,
	de: `Hilfe zu dieser Seite`,
	es: `Ayuda con esta página`,
	fa: `در مورد این صفحه کمک کنید`,
	fil: `Tulong sa pahinang ito`,
	fr: `Aide pour cette page`,
	hi: `इस पृष्ठ के साथ मदद`,
	ht: `Ede avèk paj sa a`,
	it: `Aiuto con questa pagina`,
	ja: `このページのヘルプ`,
	ko: `이 페이지에 대한 도움말`,
	mww: `Pab nrog rau phab ntawv no`,
	ps: `د دې پاڼې سره مرسته`,
	pt: `Ajuda com esta página`,
	'pt-pt': `Ajuda com esta página`,
	ru: `Помощь с этой страницей`,
	sm: `Fesoasoani i le itulau lenei`,
	so: `Caawimada boggan`,
	th: `ความช่วยเหลือเกี่ยวกับหน้านี้`,
	ur: `اس صفحے کے ساتھ مدد کریں`,
	vi: `Trợ giúp với trang này`,
	'zh-hans': `此页面的帮助`,
	'zh-hant': `此頁面的説明`,
	en: `Help with this page`
};

export const loc_thankYouForSubmitting: Record<Locale, string> = {
	ar: `شكرا لك على الرد!`,
	de: `Vielen Dank für Ihre Antwort!`,
	es: `¡Gracias por responder!`,
	fa: `ممنون که پاسخ دادید!`,
	fil: `Salamat sa pagtugon!`,
	fr: `Merci d’avoir répondu !`,
	hi: `जवाब देने के लिए धन्यवाद!`,
	ht: `Mèsi pou reponn!`,
	it: `Grazie per aver risposto!`,
	ja: `ご回答いただきありがとうございます!`,
	ko: `응답해 주셔서 감사합니다!`,
	mww: `Ua tsaug rau koj teb!`,
	ps: `د ځواب ویلو لپاره مننه!`,
	pt: `Obrigado por responder!`,
	'pt-pt': `Obrigado por responder!`,
	ru: `Спасибо, что ответили!`,
	sm: `Faafetai mo le tali mai!`,
	so: `Waad ku mahadsan tahay jawaabtaada!`,
	th: `ขอบคุณสําหรับการตอบกลับ!`,
	ur: `جواب دینے کے لئے آپ کا شکریہ!`,
	vi: `Cảm ơn bạn đã trả lời!`,
	'zh-hans': `感谢您的回复！`,
	'zh-hant': `感謝您的回復！`,
	en: `Thank you for responding!`
};

export const loc_signatureAlreadyProvidedDesc: Record<Locale, string> = {
	ar: `وقد تم بالفعل التوقيع على هذا السؤال. لطلب توقيع جديد، احذف أولا أي توقيعات موجودة في هذا `,
	de: `Für diese Frage wurde bereits eine Unterschrift bereitgestellt. Um eine neue Signatur anzufordern, löschen Sie zunächst alle vorhandenen Signaturen in diesem `,
	es: `Ya se ha proporcionado una firma para esta pregunta. Para solicitar una nueva firma, primero elimine las firmas existentes en este `,
	fa: `امضایی برای این سوال قبلا ارائه شده است. برای درخواست یک امضای جدید، ابتدا امضاهای موجود در این را حذف کنید `,
	fil: `May lagda na para sa tanong na ito. Upang humiling ng bagong lagda, tanggalin muna ang anumang umiiral na lagda dito `,
	fr: `Une signature a déjà été fournie pour cette question. Pour demander une nouvelle signature, supprimez d’abord toutes les signatures existantes dans ce `,
	hi: `इस प्रश्न के लिए एक हस्ताक्षर पहले ही प्रदान किया जा चुका है। नए हस्ताक्षर का अनुरोध करने के लिए, पहले इसमें किसी भी मौजूदा हस्ताक्षर को हटा दें `,
	ht: `Yon siyati te deja bay pou kesyon sa a. Pou mande yon nouvo siyati, premye efase nenpòt siyati ki deja egziste nan sa a `,
	it: `Per questa domanda è già stata fornita una firma. Per richiedere una nuova firma, eliminare prima tutte le firme esistenti in questo `,
	ja: `この質問には、すでに署名が提供されています。新しい署名を要求するには、まずこの署名内の既存の署名をすべて削除します `,
	ko: `이 질문에 대한 서명이 이미 제공되었습니다. 새 서명을 요청하려면 먼저 이 서명에서 기존 서명을 삭제하십시오. `,
	mww: `Ib kos npe muaj twb tau muab rau lo lus nug no. Xav thov ib daim ntawv kos npe tshiab, ua ntej delete tej kos npe rau hauv no `,
	ps: `د دې پوښتنې لپاره لا دمخه یو لاسلیک چمتو شوی. د نوی لاسلیک غوښتنه کولو لپاره ، لومړی پدې کې موجود لاسلیکونه حذف کړئ `,
	pt: `Uma assinatura já foi fornecida para esta pergunta. Para solicitar uma nova assinatura, primeiro exclua todas as assinaturas existentes neste `,
	'pt-pt': `Já foi fornecida uma assinatura para esta pergunta. Para solicitar uma nova assinatura, primeiro exclua todas as assinaturas existentes neste `,
	ru: `На этот вопрос уже поставлена подпись. Чтобы запросить новую подпись, сначала удалите все существующие подписи в этом `,
	sm: `Ua uma ona tuuina atu se saini mo lenei fesili. Ina ia talosagaina se saini fou, tapeese muamua soo se saini o loo i ai nei i lenei `,
	so: `Su'aashan horey ayaa loo bixiyay saxiix. Si aad u codsato saxiix cusub, marka hore tirtirto wixii saxiixyo ah ee jira `,
	th: `มีการจัดเตรียมลายเซ็นสําหรับคําถามนี้แล้ว หากต้องการขอลายเซ็นใหม่ ให้ลบลายเซ็นที่มีอยู่ในรายการนี้ก่อน `,
	ur: `اس سوال کے لئے ایک دستخط پہلے ہی فراہم کیا جا چکا ہے. نئے دستخط کی درخواست کرنے کے لئے، پہلے اس میں موجود کسی بھی دستخط کو حذف کریں `,
	vi: `Một chữ ký đã được cung cấp cho câu hỏi này. Để yêu cầu chữ ký mới, trước tiên hãy xóa mọi chữ ký hiện có trong chữ ký này `,
	'zh-hans': `这个问题已经提供了签名。要请求新签名，请先删除此 `,
	'zh-hant': `這個問題已經提供了簽名。要請求新簽名，請先刪除此 `,
	en: `A signature has already been provided for this question. To request a new signature, first delete any existing signatures in this `
};

export const loc_signatureQRCodeInstructions: Record<Locale, string> = {
	ar: `للإرسال باستخدام هاتفك ، امسح رمز الاستجابة السريعة ضوئيا باستخدام تطبيق الكاميرا.`,
	de: `Um mit Ihrem Telefon zu senden, scannen Sie den QR-Code mit Ihrer Kamera-App.`,
	es: `Para enviar usando tu teléfono, escanea el código QR con la aplicación de tu cámara.`,
	fa: `برای ارسال با استفاده از تلفن، کد QR را با برنامه دوربین اسکن کنید.`,
	fil: `Upang ipadala gamit ang iyong telepono, i scan ang QR code gamit ang iyong camera app.`,
	fr: `Pour envoyer des messages à l’aide de votre téléphone, scannez le code QR avec votre application appareil photo.`,
	hi: `अपने फ़ोन का उपयोग करके भेजने के लिए, अपने कैमरा ऐप से QR कोड स्कैन करें।`,
	ht: `Pou voye lè l sèvi avèk telefòn ou, analysis kòd la QR ak app kamera ou.`,
	it: `Per inviare utilizzando il telefono, scansiona il codice QR con l'app della fotocamera.`,
	ja: `スマートフォンを使用して送信するには、カメラアプリでQRコードをスキャンします。`,
	ko: `휴대전화를 사용하여 전송하려면 카메라 앱으로 QR 코드를 스캔하세요.`,
	mww: `Xa koj lub xov tooj, scan lub QR code nrog koj lub koob yees duab app.`,
	ps: `د خپل تلیفون په کارولو سره لیږلو لپاره ، د خپل کیمرې اپلیکیشن سره د QR کوډ سکین کړئ.`,
	pt: `Para enviar usando seu telefone, escaneie o código QR com o aplicativo da câmera.`,
	'pt-pt': `Para enviar usando seu telefone, digitalize o código QR com seu aplicativo de câmera.`,
	ru: `Чтобы отправить с помощью телефона, отсканируйте QR-код с помощью приложения камеры.`,
	sm: `Ina ia auina atu e faaaoga ai lau telefoni, vaai faata le numera faailo QR i lau polokalama o le mea pueata.`,
	so: `Si aad u dirto adiga oo isticmaalaya telefoonkaaga, iskaan QR code-ka app-kaaga camera.`,
	th: `หากต้องการส่งโดยใช้โทรศัพท์ ให้สแกนรหัส QR ด้วยแอปกล้องถ่ายรูป`,
	ur: `اپنے فون کا استعمال کرتے ہوئے بھیجنے کے لئے ، اپنے کیمرہ ایپ کے ساتھ کیو آر کوڈ اسکین کریں۔`,
	vi: `Để gửi bằng điện thoại của bạn, hãy quét mã QR bằng ứng dụng máy ảnh của bạn.`,
	'zh-hans': `要使用手机发送，请使用相机应用程序扫描 QR 码。`,
	'zh-hant': `要使用手機發送，請使用相機應用程式掃描 QR 碼。`,
	en: `To send using your phone, scan the QR code with your camera app.`
};

export const loc_sendViaEmail: Record<Locale, string> = {
	ar: `إرسال عبر البريد الإلكتروني`,
	de: `Per E-Mail versenden`,
	es: `Enviar por correo electrónico`,
	fa: `ارسال از طریق ایمیل`,
	fil: `Ipadala sa pamamagitan ng Email`,
	fr: `Envoyer par e-mail`,
	hi: `ईमेल द्वारा भेजें`,
	ht: `Voye atravè Imèl`,
	it: `Invia via e-mail`,
	ja: `メールで送信`,
	ko: `이메일로 보내기`,
	mww: `Xa ntawm Email`,
	ps: `د برېښنالیک له لارې ولیږئ`,
	pt: `Enviar por e-mail`,
	'pt-pt': `Enviar por e-mail`,
	ru: `Отправить по электронной почте`,
	sm: `Auina atu e ala i le Imeli`,
	so: `U dir email`,
	th: `ส่งทางอีเมล`,
	ur: `ای میل کے ذریعے بھیجیں`,
	vi: `Gửi qua Email`,
	'zh-hans': `通过电子邮件发送`,
	'zh-hant': `通過電子郵件發送`,
	en: `Send via Email`
};

export const loc_sendViaSMS: Record<Locale, string> = {
	ar: `إرسال عبر رسالة نصية`,
	de: `Per SMS senden`,
	es: `Enviar por mensaje de texto`,
	fa: `ارسال از طریق پیام متنی`,
	fil: `Ipadala sa pamamagitan ng Text Message`,
	fr: `Envoyer par SMS`,
	hi: `पाठ संदेश के माध्यम से भेजें`,
	ht: `Voye atravè Mesaj tèks`,
	it: `Invia tramite messaggio di testo`,
	ja: `テキストメッセージで送信`,
	ko: `문자 메시지로 보내기`,
	mww: `Xa ntawm Text Lus`,
	ps: `د متن پیغام له لارې واستوئ`,
	pt: `Enviar por mensagem de texto`,
	'pt-pt': `Enviar via mensagem de texto`,
	ru: `Отправить с помощью текстового сообщения`,
	sm: `Auina atu e ala i le Feau Tusitusia`,
	so: `U dir via Fariin Qoraalka`,
	th: `ส่งทางข้อความ`,
	ur: `ٹیکسٹ میسج کے ذریعے بھیجیں`,
	vi: `Gửi qua tin nhắn văn bản`,
	'zh-hans': `通过短信发送`,
	'zh-hant': `通過簡訊發送`,
	en: `Send via Text Message`
};

export const loc_customPhoneNumber: Record<Locale, string> = {
	ar: `رقم هاتف مخصص`,
	de: `Benutzerdefinierte Telefonnummer`,
	es: `Número de teléfono personalizado`,
	fa: `شماره تلفن سفارشی`,
	fil: `Pasadyang numero ng telepono`,
	fr: `Numéro de téléphone personnalisé`,
	hi: `कस्टम फ़ोन नंबर`,
	ht: `Nimewo telefòn koutim`,
	it: `Numero di telefono personalizzato`,
	ja: `カスタム電話番号`,
	ko: `사용자 지정 전화 번호`,
	mww: `Xov Tooj`,
	ps: `دودیز ټېلیفون شمېره`,
	pt: `Número de telefone personalizado`,
	'pt-pt': `Número de telefone personalizado`,
	ru: `Пользовательский номер телефона`,
	sm: `Numera o le telefoni masani`,
	so: `lambarka telefoonka Custom`,
	th: `หมายเลขโทรศัพท์ที่กําหนดเอง`,
	ur: `اپنی مرضی کے مطابق فون نمبر`,
	vi: `Số điện thoại tùy chỉnh`,
	'zh-hans': `自定义电话号码`,
	'zh-hant': `自訂電話號碼`,
	en: `Custom phone number`
};

export const loc_customEmailAddress: Record<Locale, string> = {
	ar: `عنوان بريد إلكتروني مخصص`,
	de: `Benutzerdefinierte E-Mail-Adresse`,
	es: `Dirección de correo electrónico personalizada`,
	fa: `آدرس ایمیل سفارشی`,
	fil: `Pasadyang email address`,
	fr: `Adresse e-mail personnalisée`,
	hi: `कस्टम ईमेल पता`,
	ht: `Adrès imèl koutim`,
	it: `Indirizzo e-mail personalizzato`,
	ja: `カスタムメールアドレス`,
	ko: `사용자 지정 이메일 주소`,
	mww: `Kev lis kev cai email chaw nyob`,
	ps: `دودیز برېښلیک پته`,
	pt: `Endereço de e-mail personalizado`,
	'pt-pt': `Endereço de e-mail personalizado`,
	ru: `Пользовательский адрес электронной почты`,
	sm: `Tuatusi imeli masani`,
	so: `cinwaanka email Custom`,
	th: `ที่อยู่อีเมลที่กําหนดเอง`,
	ur: `اپنی مرضی کے مطابق ای میل ایڈریس`,
	vi: `Địa chỉ email tùy chỉnh`,
	'zh-hans': `自定义电子邮件地址`,
	'zh-hant': `自訂電子郵件位址`,
	en: `Custom email address`
};

export const loc_chooseHowToSend: Record<Locale, string> = {
	ar: `اختيار كيفية الإرسال`,
	de: `Wählen Sie aus, wie Sie senden möchten`,
	es: `Elige cómo enviar`,
	fa: `نحوه ارسال را انتخاب کنید`,
	fil: `Piliin kung paano magpadala`,
	fr: `Choisissez comment envoyer`,
	hi: `भेजने का तरीका चुनें`,
	ht: `Chwazi kijan pou voye`,
	it: `Scegli la modalità di invio`,
	ja: `送信方法を選択する`,
	ko: `전송 방법 선택`,
	mww: `Xaiv seb yuav xa li cas`,
	ps: `د لیږلو څرنګوالی غوره کړئ`,
	pt: `Escolha como enviar`,
	'pt-pt': `Escolha como enviar`,
	ru: `Выберите способ отправки`,
	sm: `Filifili le auala e auina atu ai`,
	so: `Dooro sida loo diro`,
	th: `เลือกวิธีส่ง`,
	ur: `بھیجنے کا طریقہ منتخب کریں`,
	vi: `Chọn cách gửi`,
	'zh-hans': `选择发送方式`,
	'zh-hant': `選擇發送方式`,
	en: `Choose how to send`
};

export const loc_send: Record<Locale, string> = {
	ar: `ارسل`,
	de: `Senden`,
	es: `Enviar`,
	fa: `ارسال`,
	fil: `Ipadala ang`,
	fr: `Envoyer`,
	hi: `भेजना`,
	ht: `Voye`,
	it: `Invia`,
	ja: `送信`,
	ko: `보내기`,
	mww: `Xa`,
	ps: `د`,
	pt: `Enviar`,
	'pt-pt': `Enviar`,
	ru: `Отправить`,
	sm: `Auina Atu`,
	so: `U dir`,
	th: `ส่ง`,
	ur: `بھیجنا`,
	vi: `Gửi`,
	'zh-hans': `发送`,
	'zh-hant': `發送`,
	en: `Send`
};

export const loc_authorizationMissing: Record<Locale, string> = {
	ar: `التفويض مفقود.`,
	de: `Berechtigung fehlt.`,
	es: `Falta autorización.`,
	fa: `مجوز وجود ندارد.`,
	fil: `Awtorisasyon nawawala.`,
	fr: `Autorisation manquante.`,
	hi: `प्राधिकरण अनुपलब्ध।`,
	ht: `Otorizasyon ki disparèt.`,
	it: `Autorizzazione mancante.`,
	ja: `認証がありません。`,
	ko: `인증이 누락되었습니다.`,
	mww: `Kev tso cai uas ploj lawm.`,
	ps: `د ورک شوی واک.`,
	pt: `Autorização ausente.`,
	'pt-pt': `Autorização em falta.`,
	ru: `Авторизация отсутствует.`,
	sm: `O loo misi le faatagaga.`,
	so: `Oggolaansho la'yahay.`,
	th: `ไม่มีการอนุญาต`,
	ur: `اجازت نامہ غائب ہے۔`,
	vi: `Thiếu ủy quyền.`,
	'zh-hans': `缺少授权。`,
	'zh-hant': `缺少授權。`,
	en: `Authorization missing.`
};

export const loc_signatureRequestExpired: Record<Locale, string> = {
	ar: `انتهت صلاحية طلب التوقيع`,
	de: `Signaturanforderung abgelaufen`,
	es: `Solicitud de firma caducada`,
	fa: `درخواست امضا منقضی شده است`,
	fil: `Nag expire ang kahilingan sa lagda`,
	fr: `Demande de signature expirée`,
	hi: `हस्ताक्षर अनुरोध की समय सीमा समाप्त हो गई है`,
	ht: `Demann siyati ekspire`,
	it: `Richiesta di firma scaduta`,
	ja: `署名リクエストの有効期限が切れました`,
	ko: `서명 요청이 만료되었습니다.`,
	mww: `Kos npe thov expired`,
	ps: `د لاسلیک غوښتنه له وخته تېره شوه`,
	pt: `Solicitação de assinatura expirada`,
	'pt-pt': `O pedido de assinatura expirou`,
	ru: `Срок действия запроса на подпись истек`,
	sm: `Ua uma le aoga o le talosaga mo saini`,
	so: `Codsiga saxiixa ahi waa dhacay`,
	th: `คําขอลายเซ็นหมดอายุ`,
	ur: `دستخط کی درخواست ختم ہو گئی`,
	vi: `Yêu cầu chữ ký đã hết hạn`,
	'zh-hans': `签名请求已过期`,
	'zh-hant': `簽名請求已過期`,
	en: `Signature request expired`
};

export const loc_signatureRequestExpiredDescFormat: Record<Locale, (when: unknown) => TemplateResult> = {
	ar: (when) => html`<p>انتهت صلاحية طلب التوقيع هذا ${when}.</p>`,
	de: (when) => html`<p>Diese Anforderung zur Unterschrift ist ${when} abgelaufen.</p>`,
	es: (when) => html`<p>Esta solicitud de firma caducó ${when}.</p>`,
	fa: (when) => html`<p>این درخواست امضا ${when} منقضی شده است.</p>`,
	fil: (when) => html`<p>Nag expire ang request na ito para sa signature ${when}.</p>`,
	fr: (when) => html`<p>Cette demande de signature a expiré ${when}.</p>`,
	hi: (when) => html`<p>हस्ताक्षर के लिए यह अनुरोध ${when}।</p>`,
	ht: (when) => html`<p>Demann sa a pou siyati ${when} sa.</p>`,
	it: (when) => html`<p>Questa richiesta di firma è scaduta ${when}.</p>`,
	ja: (when) => html`<p>この署名リクエストは ${when}期限切れになりました。</p>`,
	ko: (when) => html`<p>이 서명 요청은 ${when} 만료되었습니다.</p>`,
	mww: (when) => html`<p>Qhov kev thov kos npe rau ${when}.</p>`,
	ps: (when) => html`<p>د لاسلیک لپاره دا غوښتنه ${when} پای ته ورسیده.</p>`,
	pt: (when) => html`<p>Este pedido de assinatura expirou ${when}.</p>`,
	'pt-pt': (when) => html`<p>Este pedido de assinatura expirou ${when}.</p>`,
	ru: (when) => html`<p>Срок действия этого запроса на подпись истек ${when}.</p>`,
	sm: (when) => html`<p>O lenei talosaga mo le saini na faamutaina i le ${when}.</p>`,
	so: (when) => html`<p>Codsigaan saxiixa saxiixa ah wuxuu dhacay ${when}.</p>`,
	th: (when) => html`<p>คําขอลายเซ็นนี้หมดอายุเมื่อ ${when}</p>`,
	ur: (when) => html`<p>دستخط کی اس درخواست کی ${when} ختم ہو گئی تھی۔</p>`,
	vi: (when) => html`<p>Yêu cầu chữ ký này đã hết hạn ${when}.</p>`,
	'zh-hans': (when) => html`<p>此签名请求 ${when}过期。</p>`,
	'zh-hant': (when) => html`<p>此簽名請求 ${when}過期。</p>`,
	en: (when) => html`<p>This request for signature expired ${when}.</p>`
};

export const loc_signatureDocNotFound: Record<Locale, string> = {
	ar: `لم يتم العثور على مستند التوقيع.`,
	de: `Signaturdokument nicht gefunden.`,
	es: `No se ha encontrado el documento de firma.`,
	fa: `سند امضا یافت نشد.`,
	fil: `Hindi natagpuan ang dokumento ng lagda.`,
	fr: `Document de signature introuvable.`,
	hi: `हस्ताक्षर दस्तावेज़ नहीं मिला.`,
	ht: `Dokiman siyati pa jwenn.`,
	it: `Documento di firma non trovato.`,
	ja: `署名ドキュメントが見つかりません。`,
	ko: `서명 문서를 찾을 수 없습니다.`,
	mww: `Kos npe tsis pom muaj.`,
	ps: `.لاسلیک لاسوند ونه موندل شو`,
	pt: `Documento de assinatura não encontrado.`,
	'pt-pt': `Documento de assinatura não encontrado.`,
	ru: `Документ с подписью не найден.`,
	sm: `E le'i maua le pepa o faamatalaga o saini.`,
	so: `Dokumentiga saxeexa lama helin.`,
	th: `ไม่พบเอกสารลายเซ็น`,
	ur: `دستخط کی دستاویز نہیں ملی۔`,
	vi: `Không tìm thấy tài liệu chữ ký.`,
	'zh-hans': `找不到签名文档。`,
	'zh-hant': `找不到簽名文件。`,
	en: `Signature document not found.`
};

export const loc_authorizationInvalid: Record<Locale, string> = {
	ar: `التفويض غير صالح.`,
	de: `Autorisierung ungültig.`,
	es: `La autorización no es válida.`,
	fa: `مجوز نامعتبر است.`,
	fil: `Hindi wasto ang awtorisasyon.`,
	fr: `Autorisation non valide.`,
	hi: `प्राधिकरण अमान्य।`,
	ht: `Otorizasyon envalid.`,
	it: `Autorizzazione non valida.`,
	ja: `認証が無効です。`,
	ko: `권한 부여가 잘못되었습니다.`,
	mww: `Kev tso cai.`,
	ps: `واک ورکول باطل دی.`,
	pt: `Autorização inválida.`,
	'pt-pt': `Autorização inválida.`,
	ru: `Авторизация недействительна.`,
	sm: `E le o se faatagaga.`,
	so: `Ogolaanshaha sharci darro.`,
	th: `การอนุญาตไม่ถูกต้อง`,
	ur: `اجازت نامہ غیر قانونی ہے۔`,
	vi: `Ủy quyền không hợp lệ.`,
	'zh-hans': `授权无效。`,
	'zh-hant': `授權無效。`,
	en: `Authorization invalid.`
};

export const loc_alreadySigned: Record<Locale, string> = {
	ar: `تم التوقيع على الوثيقة بالفعل.`,
	de: `Das Dokument wurde bereits signiert.`,
	es: `El documento ya ha sido firmado.`,
	fa: `سند قبلا امضا شده است.`,
	fil: `Napirmahan na ang dokumento.`,
	fr: `Le document a déjà été signé.`,
	hi: `दस्तावेज़ पर पहले ही हस्ताक्षर किए जा चुके हैं.`,
	ht: `Dokiman an deja siyen.`,
	it: `Il documento è già stato firmato.`,
	ja: `ドキュメントは既に署名されています。`,
	ko: `문서에 이미 서명이 완료되었습니다.`,
	mww: `Cov ntaub ntawv muaj twb tau kos npe.`,
	ps: `دغه سند له وړاندې لاسلیک شوی دی.`,
	pt: `O documento já foi assinado.`,
	'pt-pt': `O documento já foi assinado.`,
	ru: `Документ уже подписан.`,
	sm: `Ua uma ona sainia le pepa o faamatalaga.`,
	so: `Qoraalka horey ayaa loo saxiixay.`,
	th: `เอกสารได้รับการลงนามแล้ว`,
	ur: `دستاویز پر پہلے ہی دستخط ہو چکے ہیں۔`,
	vi: `Văn bản đã được ký.`,
	'zh-hans': `文件已签名。`,
	'zh-hant': `檔已簽名。`,
	en: `The document has already been signed.`
};

export const loc_senderNoLongerActive: Record<Locale, string> = {
	ar: `المرسل لم يعد نشطا في المشروع.`,
	de: `Der Absender ist im Projekt nicht mehr aktiv.`,
	es: `El remitente ya no está activo en el proyecto.`,
	fa: `فرستنده دیگر در پروژه فعال نیست.`,
	fil: `Sender hindi na aktibo sa proyekto.`,
	fr: `L’expéditeur n’est plus actif dans le projet.`,
	hi: `प्रेषक अब प्रोजेक्ट में सक्रिय नहीं है.`,
	ht: `Sansib pa aktif ankò nan pwojè a ankò.`,
	it: `Il mittente non è più attivo nel progetto.`,
	ja: `プロジェクトでセンダがアクティブでなくなりました。`,
	ko: `보낸 사람이 프로젝트에서 더 이상 활성화되지 않습니다.`,
	mww: `Xa tsis txawj dhia hauv peb tes num.`,
	ps: `د پروژې په اړه نور جزیات نه دی په لاس کې.`,
	pt: `O remetente não está mais ativo no projeto.`,
	'pt-pt': `O remetente não está mais ativo no projeto.`,
	ru: `Отправитель больше не активен в проекте.`,
	sm: `Ua le toe malosi le tagata na auina atu i le galuega faatino.`,
	so: `Sender mar dambe firfircoon mashruuca.`,
	th: `ผู้ส่งไม่ได้ใช้งานในโครงการอีกต่อไป`,
	ur: `مرسل اب اس منصوبے میں فعال نہیں ہے۔`,
	vi: `Người gửi không còn hoạt động trong dự án.`,
	'zh-hans': `发件人在项目中不再处于活动状态。`,
	'zh-hant': `寄件人在專案中不再處於活動狀態。`,
	en: `Sender no longer active in the project.`
};

export const loc_documentNoLongerExists: Record<Locale, string> = {
	ar: `المستند لم يعد موجودا.`,
	de: `Das Dokument existiert nicht mehr.`,
	es: `El documento ya no existe.`,
	fa: `سند دیگر وجود ندارد.`,
	fil: `Ang dokumento ay hindi na umiiral.`,
	fr: `Le document n’existe plus.`,
	hi: `दस्तावेज़ अब मौजूद नहीं है.`,
	ht: `Dokiman an pa egziste ankò.`,
	it: `Il documento non esiste più.`,
	ja: `ドキュメントは存在しなくなりました。`,
	ko: `문서가 더 이상 존재하지 않습니다.`,
	mww: `Cov ntaub ntawv lawm tshwm sim.`,
	ps: `دا سند نور شتون نلری.`,
	pt: `O documento não existe mais.`,
	'pt-pt': `O documento já não existe.`,
	ru: `Документ больше не существует.`,
	sm: `E le o toe i ai le pepa o faamatalaga.`,
	so: `Dukumentiga mar dambe ma jiro.`,
	th: `เอกสารไม่มีอยู่อีกต่อไป`,
	ur: `دستاویز اب موجود نہیں ہے۔`,
	vi: `Tài liệu không còn tồn tại.`,
	'zh-hans': `该文档不再存在。`,
	'zh-hant': `該文件不再存在。`,
	en: `The document no longer exists.`
};

export const loc_signatureLinkWarning: Record<Locale, string> = {
	ar: `ستنتهي صلاحية رابط صفحة التوقيع بعد 12 ساعة. سيتمكن أي شخص يتلقى الرابط من الوصول إلى صفحة التوقيع حتى تنتهي صلاحيتها أو يتم توقيعها.`,
	de: `Der Link zur Signaturseite läuft nach 12 Stunden ab. Jeder, der den Link erhält, kann auf die Signaturseite zugreifen, bis sie abläuft oder signiert ist.`,
	es: `El enlace de la página de firma caducará después de 12 horas. Cualquier persona que reciba el enlace podrá acceder a la página de firma hasta que caduque o se firme.`,
	fa: `پیوند صفحه امضا پس از 12 ساعت منقضی می شود. هر کسی که پیوند را دریافت کند، می تواند تا زمان انقضای یا امضای صفحه امضا به آن دسترسی داشته باشد.`,
	fil: `Ang link ng pahina ng lagda ay mag e expire pagkatapos ng 12 oras. Ang sinumang tumatanggap ng link ay magagawang ma access ang pahina ng lagda hanggang sa ito ay mag expire o lagdaan.`,
	fr: `Le lien de la page de signature expirera après 12 heures. Toute personne recevant le lien pourra accéder à la page de signature jusqu’à ce qu’elle expire ou soit signée.`,
	hi: `हस्ताक्षर पृष्ठ लिंक 12 घंटे के बाद समाप्त हो जाएगा। लिंक प्राप्त करने वाला कोई भी व्यक्ति हस्ताक्षर पृष्ठ को तब तक एक्सेस कर सकेगा जब तक कि उसकी समय सीमा समाप्त नहीं हो जाती या उस पर हस्ताक्षर नहीं हो जाते।`,
	ht: `Lyen paj siyati a ap ekspire apre 12 èdtan. Nenpòt moun k ap resevwa lyen an yo pral kapab jwenn aksè nan paj la siyati jiskaske li ekspire oswa siyen.`,
	it: `Il link alla pagina della firma scadrà dopo 12 ore. Chiunque riceva il link potrà accedere alla pagina della firma fino alla scadenza o alla firma.`,
	ja: `署名ページのリンクは 12 時間後に期限切れになります。リンクを受け取った人は誰でも、署名ページの有効期限が切れるか署名されるまで、署名ページにアクセスできます。`,
	ko: `서명 페이지 링크는 12시간 후에 만료됩니다. 링크를 받는 사람은 누구나 서명 페이지가 만료되거나 서명될 때까지 서명 페이지에 액세스할 수 있습니다.`,
	mww: `Qhov kos npe yuav tshwm sim tom qab 12 teev. Leej twg los tau qhov txuas yuav tau mus saib tau cov nplooj ntawv kos npe mus txog rau thaum nws expires los yog kos npe.`,
	ps: `د لاسلیک پاڼې لینک به د 12 ساعتونو وروسته پای ته ورسیږی. هرڅوک چې لینک ترلاسه کوی کولی شی د لاسلیک پا pageې ته لاسرسی ومومی تر هغه چې پای ته ورسیږی یا لاسلیک شی.`,
	pt: `O link da página de assinatura expirará após 12 horas. Qualquer pessoa que receber o link poderá acessar a página de assinatura até que ela expire ou seja assinada.`,
	'pt-pt': `O link da página de assinatura expirará após 12 horas. Qualquer pessoa que receba o link poderá acessar a página de assinatura até que ela expire ou seja assinada.`,
	ru: `Срок действия ссылки на страницу подписи истечет через 12 часов. Любой, кто получит ссылку, сможет получить доступ к странице подписи до тех пор, пока срок ее действия не истечет или она не будет подписана.`,
	sm: `O le a faamutaina le aoga o le sootaga o le itulau saini pe a mavae le 12 itula. Soo se tasi lava e mauaina le sootaga o le a mafai ona faaaoga le itulau o saini seia oo ina uma lona aoga pe ua sainia foi.`,
	so: `Bogga saxiixa linkiga wuxuu dhici doonaa 12 saacadood ka dib. Qof kasta oo helaya link-ka wuxuu awoodi doonaa inuu galo bogga saxiixa ilaa uu ka dhaco ama la saxiixayo.`,
	th: `ลิงก์หน้าลายเซ็นจะหมดอายุหลังจากผ่านไป 12 ชั่วโมง ใครก็ตามที่ได้รับลิงก์จะสามารถเข้าถึงหน้าลายเซ็นได้จนกว่าจะหมดอายุหรือลงนาม`,
	ur: `دستخط کے صفحے کا لنک 12 گھنٹوں کے بعد ختم ہوجائے گا۔ لنک حاصل کرنے والا کوئی بھی شخص دستخط کے صفحے تک اس وقت تک رسائی حاصل کرسکتا ہے جب تک کہ اس کی میعاد ختم نہ ہوجائے یا اس پر دستخط نہ ہوجائیں۔`,
	vi: `Liên kết trang chữ ký sẽ hết hạn sau 12 giờ. Bất kỳ ai nhận được liên kết sẽ có thể truy cập trang chữ ký cho đến khi hết hạn hoặc được ký.`,
	'zh-hans': `签名页链接将在 12 小时后过期。收到该链接的任何人都可以访问该签名页，直到该页过期或被签名为止。`,
	'zh-hant': `簽名頁連結將在12小時後過期。收到該連結的任何人都可以訪問該簽名頁，直到該頁過期或被簽名為止。`,
	en: `The signature page link will expire after 12 hours. Anyone receiving the link will be able to access the signature page until it expires or is signed.`
};

export const loc_merge: Record<Locale, string> = {
	ar: `دمج`,
	de: `Verschmelzen`,
	es: `Fusionar`,
	fa: `ادغام`,
	fil: `Mag-merge`,
	fr: `Fusionner`,
	hi: `डुबो`,
	ht: `Fonn`,
	it: `Fondersi`,
	ja: `マージ`,
	ko: `병합`,
	mww: `merge`,
	ps: `سره ننوېستل`,
	pt: `Fundir`,
	'pt-pt': `Mesclar`,
	ru: `Сливать`,
	sm: `Tuufaatasi`,
	so: `Merge`,
	th: `ควบ`,
	ur: `ضم`,
	vi: `Merge`,
	'zh-hans': `合并`,
	'zh-hant': `合併`,
	en: `Merge`
};

export const loc_mergeConfirmation: Record<Locale, (title: unknown) => string> = {
	ar: (title) => `هل أنت متأكد من أنك تريد دمج "${title}"؟ سيتم حذف هذا السجل وسيتم نقل أنشطته إلى السجل الهدف.`,
	de: (title) => `Sind Sie sicher, dass Sie "${title}" zusammenführen möchten? Dieser Datensatz wird gelöscht und seine Aktivitäten werden in den Zieldatensatz verschoben.`,
	es: (title) => `¿Estás seguro de que quieres fusionar "${title}"? Este registro se eliminará y sus actividades se moverán al registro de destino.`,
	fa: (title) => `آیا مطمئن هستید که می خواهید "${title} را ادغام کنید؟ این رکورد حذف می شود و فعالیت های آن به رکورد هدف منتقل می شود.`,
	fil: (title) => `Sigurado ka bang gusto mong i merge ang "${title}"? Ang talaang ito ay tatanggalin at ang mga aktibidad nito ay ililipat sa target na talaan.`,
	fr: (title) => `Êtes-vous sûr de vouloir fusionner « ${title} » ? Cet enregistrement sera supprimé et ses activités seront déplacées vers l’enregistrement cible.`,
	hi: (title) => `क्या आप वाकई "${title}" मर्ज करना चाहते हैं? यह रिकॉर्ड हटा दिया जाएगा और इसकी गतिविधियों को लक्ष्य रिकॉर्ड पर ले जाया जाएगा.`,
	ht: (title) => `Èske ou sèten ou vle rantre nan "${title}"? Dosye sa a pral efase ak aktivite li yo pral deplase nan dosye a sib.`,
	it: (title) => `Sei sicuro di voler unire "${title}"? Questo record verrà eliminato e le relative attività verranno spostate nel record di destinazione.`,
	ja: (title) => `「${title}」を統合してもよろしいですか?このレコードは削除され、その活動はターゲット レコードに移動されます。`,
	ko: (title) => `"${title}"를 병합하시겠습니까? 이 레코드는 삭제되고 해당 활동이 대상 레코드로 이동됩니다.`,
	mww: (title) => `Koj puas paub tseeb tias koj xav merge "${title}"? Cov ntaub ntawv no yuav tau deleted thiab nws cov kev ua ub no yuav tau tsiv mus nyob rau lub hom phaj.`,
	ps: (title) => `ایا تاسو ډاډه یاست چې تاسو غواړئ د "${title}" سره یوځای شئ؟ دا ریکارډ به حذف شی او د هغې فعالیتونه به د هدف ریکارډ ته لیږدول کیږی.`,
	pt: (title) => `Tem certeza de que deseja mesclar "${title}"? Esse registro será excluído e suas atividades serão movidas para o registro de destino.`,
	'pt-pt': (title) => `Tem certeza de que deseja mesclar "${title}"? Esse registro será excluído e suas atividades serão movidas para o registro de destino.`,
	ru: (title) => `Вы уверены, что хотите объединить "${title}"? Эта запись будет удалена, а ее действия будут перемещены в целевую запись.`,
	sm: (title) => `E te mautinoa e te manao e tuufaatasia le "${title}"? O le a tapeese lenei faamaumauga ma o le a siitia atu ana gaoioiga i le faamaumauga o le taulaiga.`,
	so: (title) => `Ma hubtaa inaad rabto inaad isku darsato "${title}"? Diiwaankaas ayaa la tirtiri doonaa, waxaana howlaheedii loo gudbinayaa diiwaankii lagu bartilmaameedsaday.`,
	th: (title) => `คุณแน่ใจหรือว่าต้องการรวม "${title}" เรกคอร์ดนี้จะถูกลบและกิจกรรมจะถูกย้ายไปยังเรกคอร์ดเป้าหมาย`,
	ur: (title) => `کیا آپ واقعی "جان ڈو" کو ضم کرنا ${title}؟ اس ریکارڈ کو حذف کردیا جائے گا اور اس کی سرگرمیوں کو ہدف ریکارڈ میں منتقل کردیا جائے گا۔`,
	vi: (title) => `Bạn có chắc chắn muốn hợp nhất "${title}" không? Bản ghi này sẽ bị xóa và các hoạt động của nó sẽ được chuyển đến bản ghi đích.`,
	'zh-hans': (title) => `您确定要合并 “${title}” 吗？此记录将被删除，其活动将移动到目标记录。`,
	'zh-hant': (title) => `您確定要合併 「${title}」 嗎？此記錄將被刪除，其活動將移動到目標記錄。`,
	en: (title) => `Are you sure you want to merge "${title}"? This record will be deleted and its activities will be moved to the target record.`
};

export const loc_confirmMerge: Record<Locale, string> = {
	ar: `تأكيد الدمج`,
	de: `Zusammenführung bestätigen`,
	es: `Confirmar combinación`,
	fa: `ادغام را تأیید کنید`,
	fil: `Kumpirmahin ang merge`,
	fr: `Confirmer la fusion`,
	hi: `मर्ज की पुष्टि करें`,
	ht: `Konfime ke`,
	it: `Conferma l'unione`,
	ja: `マージの確認`,
	ko: `병합 확인`,
	mww: `Paub tseeb hais tias merge`,
	ps: `ادغام باوری کړئ`,
	pt: `Confirmar mesclagem`,
	'pt-pt': `Confirmar mesclagem`,
	ru: `Подтвердите слияние`,
	sm: `Faamautu le tuufaatasia`,
	so: `Xaqiiji inay ku midoobaan`,
	th: `ยืนยันการผสาน`,
	ur: `ضم ہونے کی تصدیق کریں`,
	vi: `Xác nhận hợp nhất`,
	'zh-hans': `确认合并`,
	'zh-hant': `確認合併`,
	en: `Confirm merge`
};

export const loc_searchHistoryEllipses: Record<Locale, string> = {
	ar: `سجل البحث...`,
	de: `Verlauf der Suche...`,
	es: `Historial de búsqueda...`,
	fa: `تاریخچه جستجو...`,
	fil: `Maghanap ng kasaysayan...`,
	fr: `Historique de recherche...`,
	hi: `खोज इतिहास...`,
	ht: `Istwa rechèch ...`,
	it: `Cronologia delle ricerche...`,
	ja: `検索履歴...`,
	ko: `검색 기록...`,
	mww: `Nrhiav keeb kwm...`,
	ps: `مخینه لټول...`,
	pt: `Pesquisar no histórico...`,
	'pt-pt': `Histórico de pesquisas...`,
	ru: `История поиска...`,
	sm: `Talafaasolopito o sailiga...`,
	so: `Raadi taariikhda ...`,
	th: `ประวัติการค้นหา...`,
	ur: `تلاش کی تاریخ...`,
	vi: `Lịch sử tìm kiếm...`,
	'zh-hans': `搜索历史...`,
	'zh-hant': `搜尋歷史...`,
	en: `Search history...`
};

export const loc_remove: Record<Locale, string> = {
	ar: `أبعد`,
	de: `Entfernen`,
	es: `Eliminar`,
	fa: `حذف`,
	fil: `Tanggalin ang`,
	fr: `Enlever`,
	hi: `हटाना`,
	ht: `Deplase`,
	it: `Togliere`,
	ja: `取り去る`,
	ko: `제거하다`,
	mww: `Tshem`,
	ps: `ړنګول`,
	pt: `Retirar`,
	'pt-pt': `Remover`,
	ru: `Убирать`,
	sm: `Aveese`,
	so: `ka saar`,
	th: `ถอด`,
	ur: `ہٹانا`,
	vi: `Triệt`,
	'zh-hans': `删除`,
	'zh-hant': `刪除`,
	en: `Remove`
};

export const loc_removeConfirmation: Record<Locale, (title: unknown) => string> = {
	ar: (title) => `هل أنت متأكد من أنك تريد إزالة ${title}؟`,
	de: (title) => `Sind Sie sicher, dass Sie ${title} entfernen möchten?`,
	es: (title) => `¿Estás seguro de que quieres eliminar ${title}?`,
	fa: (title) => `آیا مطمئن هستید که می خواهید ${title} را حذف کنید؟`,
	fil: (title) => `Sigurado ka bang gusto mong alisin ang ${title}?`,
	fr: (title) => `Êtes-vous sûr de vouloir supprimer ${title} ?`,
	hi: (title) => `क्या आप वाकई ${title} निकालना चाहते हैं?`,
	ht: (title) => `Èske ou sèten ou vle retire ${title}?`,
	it: (title) => `Sei sicuro di voler rimuovere ${title}?`,
	ja: (title) => `${title}もよろしいですか?`,
	ko: (title) => `${title} 제거하시겠습니까?`,
	mww: (title) => `Koj puas paub tseeb tias koj xav tshem ${title}?`,
	ps: (title) => `ایا تاسو ډاډه یاست چې تاسو غواړئ د ${title}`,
	pt: (title) => `Tem certeza de que deseja remover o ${title}?`,
	'pt-pt': (title) => `Tem certeza de que deseja remover ${title}?`,
	ru: (title) => `Вы уверены, что хотите удалить ${title}?`,
	sm: (title) => `E te mautinoa e te manao e aveese ${title}?`,
	so: (title) => `Ma hubtaa inaad rabtid inaad ka saarto ${title}?`,
	th: (title) => `คุณแน่ใจหรือไม่ว่าต้องการลบ${title}`,
	ur: (title) => `کیا آپ واقعی ${title} کو ہٹانا چاہتے ہیں؟`,
	vi: (title) => `Bạn có chắc chắn muốn xóa ${title} không?`,
	'zh-hans': (title) => `您确定要删除 ${title}吗？`,
	'zh-hant': (title) => `您確定要刪除 ${title}嗎？`,
	en: (title) => `Are you sure you want to remove ${title}?`
};

export const loc_regenerate: Record<Locale, string> = {
	ar: `تجديد`,
	de: `Erneuern`,
	es: `Regenerar`,
	fa: `بازسازی`,
	fil: `Magbagong-buhay`,
	fr: `Régénérer`,
	hi: `पुनर्जन्म`,
	ht: `Rejenerasyon`,
	it: `Rigenerare`,
	ja: `再生`,
	ko: `재생성`,
	mww: `Regenerate`,
	ps: `بیا تولیدول`,
	pt: `Regenerar`,
	'pt-pt': `Regenerar`,
	ru: `Регенерировать`,
	sm: `Toe Faafou`,
	so: `Dib u soo noolaada`,
	th: `สร้างใหม่`,
	ur: `اصلاح کرنا`,
	vi: `Tái tạo`,
	'zh-hans': `再生`,
	'zh-hant': `再生`,
	en: `Regenerate`
};

export const loc_regenerateRecoveryCode: Record<Locale, string> = {
	ar: `إعادة إنشاء رمز الاسترداد`,
	de: `Wiederherstellungscode neu generieren`,
	es: `Volver a generar el código de recuperación`,
	fa: `کد بازیابی را بازسازی کنید`,
	fil: `Muling bumuo ng recovery code`,
	fr: `Régénérer le code de récupération`,
	hi: `पुनर्प्राप्ति कोड पुन: उत्पन्न करें`,
	ht: `Rejenerasyon kòd rekiperasyon`,
	it: `Rigenera il codice di ripristino`,
	ja: `リカバリコードの再生成`,
	ko: `복구 코드 다시 생성`,
	mww: `Regenerate rov qab code`,
	ps: `د بیا رغونې کوډ بیا راژوندی کول`,
	pt: `Regenerar código de recuperação`,
	'pt-pt': `Regenerar o código de recuperação`,
	ru: `Повторное создание кода восстановления`,
	sm: `Toe faafou le numera faalilolilo o le toe faaleleia`,
	so: `Dib u soo kabashada code`,
	th: `สร้างรหัสการกู้คืนใหม่`,
	ur: `Regenerate بازیابی کوڈ`,
	vi: `Tạo lại mã khôi phục`,
	'zh-hans': `重新生成恢复代码`,
	'zh-hant': `重新生成恢復代碼`,
	en: `Regenerate recovery code`
};

export const loc_regenerateRecoveryCodeDescription: Record<Locale, string> = {
	ar: `أعد إنشاء الرمز الذي يمكنك استخدامه لتسجيل الدخول عندما لا يكون لديك حق الوصول إلى جهاز (أجهزة) المصادقة الخاص بك.`,
	de: `Generieren Sie den Code neu, mit dem Sie sich anmelden können, wenn Sie keinen Zugriff auf Ihr(e) Authentifizierungsgerät(e) haben.`,
	es: `Vuelva a generar el código que puede usar para iniciar sesión cuando no tenga acceso a su(s) dispositivo(s) de autenticación.`,
	fa: `کدی را که می توانید برای ورود به سیستم استفاده کنید، زمانی که به دستگاه(های) احراز هویت خود دسترسی ندارید، بازسازی کنید.`,
	fil: `Muling bumuo ng code na maaari mong gamitin upang mag login kapag wala kang access sa iyong (mga) aparato ng authenticator.`,
	fr: `Régénérez le code que vous pouvez utiliser pour vous connecter lorsque vous n’avez pas accès à votre ou vos appareils d’authentification.`,
	hi: `उस कोड को पुन: उत्पन्न करें जिसका उपयोग आप लॉगिन करने के लिए कर सकते हैं जब आपके पास अपने प्रमाणक डिवाइस (उपकरणों) तक पहुंच नहीं है।`,
	ht: `Rejenerasyon kòd la ou ka itilize yo konekte lè ou pa gen aksè a aparèy natif natal ou (yo).`,
	it: `Rigenera il codice che puoi utilizzare per accedere quando non hai accesso ai tuoi dispositivi di autenticazione.`,
	ja: `認証デバイスにアクセスできない場合にログインするために使用できるコードを再生成します。`,
	ko: `인증 장치에 액세스할 수 없는 경우 로그인하는 데 사용할 수 있는 코드를 다시 생성합니다.`,
	mww: `Regenerate qhov chaws uas koj yuav siv tau login thaum koj tsis tau mus saib rau koj authenticator ntaus(s).`,
	ps: `هغه کوډ بیا راژوندی کړئ چې تاسو یې د ننوتلو لپاره کارولی شئ کله چې تاسو خپل تصدیق کونکی وسیلې ته لاسرسی نلرئ.`,
	pt: `Gere novamente o código que você pode usar para fazer login quando não tiver acesso ao(s) seu(s) dispositivo(s) autenticador(es).`,
	'pt-pt': `Regenere o código que pode utilizar para iniciar sessão quando não tiver acesso ao(s) seu(s) dispositivo(s) autenticador(es).`,
	ru: `Повторно сгенерируйте код, который можно использовать для входа в систему, если у вас нет доступа к устройствам для аутентификации.`,
	sm: `Toe faatulaga le numera faalilolilo e mafai ona e faaaogaina e alu ai i totonu pe a e le mauaina lau masini e faaaoga ai le faamaumauga.`,
	so: `Dib u soo noolee koodhka aad isticmaali karto si aad u gasho marka aadan u helin aaladdaada authenticator(s).`,
	th: `สร้างรหัสใหม่ที่คุณสามารถใช้เพื่อเข้าสู่ระบบเมื่อคุณไม่สามารถเข้าถึงอุปกรณ์ตรวจสอบสิทธิ์ของคุณ`,
	ur: `جب آپ کے پاس اپنے تصدیق کنندہ آلہ تک رسائی نہ ہو تو لاگ ان کرنے کے لئے آپ جس کوڈ کا استعمال کرسکتے ہیں اسے دوبارہ تیار کریں۔`,
	vi: `Tạo lại mã bạn có thể sử dụng để đăng nhập khi bạn không có quyền truy cập vào (các) thiết bị xác thực của mình.`,
	'zh-hans': `重新生成您在无法访问身份验证器设备时可用于登录的代码。`,
	'zh-hant': `重新生成您在無法存取身份驗證器設備時可用於登錄的代碼。`,
	en: `Regenerate the code you can use to login when you do not have access to your authenticator device(s).`
};

export const loc_copyRecoveryCodeInstructions: Record<Locale, string> = {
	ar: `انسخ رمز الاسترداد هذا واحتفظ به في مكان آمن. ستحتاج إليها إذا احتجت في أي وقت إلى تسجيل الدخول بدون جهازك.`,
	de: `Kopieren Sie diesen Wiederherstellungscode, und bewahren Sie ihn an einem sicheren Ort auf. Sie werden es brauchen, wenn Sie sich jemals ohne Ihr Gerät anmelden müssen.`,
	es: `Copie este código de recuperación y guárdelo en un lugar seguro. Lo necesitarás si alguna vez necesitas iniciar sesión sin tu dispositivo.`,
	fa: `این کد بازیابی را کپی کنید و آن را در جایی امن نگه دارید. اگر نیاز به ورود بدون دستگاه خود داشته باشید، به آن نیاز خواهید داشت.`,
	fil: `Kopyahin ang recovery code na ito at panatilihin ito sa isang lugar na ligtas. Kakailanganin mo ito kung sakaling kailangan mong mag log in nang walang iyong aparato.`,
	fr: `Copiez ce code de récupération et conservez-le dans un endroit sûr. Vous en aurez besoin si vous devez vous connecter sans votre appareil.`,
	hi: `इस पुनर्प्राप्ति कोड को कॉपी करें और इसे कहीं सुरक्षित रखें। यदि आपको कभी भी अपने डिवाइस के बिना लॉग इन करने की आवश्यकता है तो आपको इसकी आवश्यकता होगी।`,
	ht: `Kopi kòd rekiperasyon sa a epi kenbe li yon kote ki an sekirite. Ou pral bezwen li si ou janm bezwen ouvri sesyon an san aparèy ou an.`,
	it: `Copia questo codice di ripristino e conservalo in un luogo sicuro. Ne avrai bisogno se hai bisogno di accedere senza il tuo dispositivo.`,
	ja: `このリカバリコードをコピーして、安全な場所に保管してください。デバイスなしでログインする必要がある場合に必要になります。`,
	ko: `이 복구 코드를 복사하여 안전한 곳에 보관하십시오. 장치 없이 로그인해야 하는 경우 필요합니다.`,
	mww: `Luam daim ntawv rov qab no thiab khaws nws qhov chaw zoo. Koj yuav tau nws yog hais tias koj puas yuav tsum tau log nyob rau hauv tsis tau koj ntaus ntawv.`,
	ps: `دا د بیا رغونې کوډ کاپی کړئ او په خوندی ځای کې یې وساتئ. تاسو به دې ته اړتیا ولرئ که تاسو کله هم د خپل وسیلې پرته ننوتل ته اړتیا لرئ.`,
	pt: `Copie este código de recuperação e mantenha-o em algum lugar seguro. Você precisará dele se precisar fazer login sem o seu dispositivo.`,
	'pt-pt': `Copie este código de recuperação e mantenha-o em algum lugar seguro. Você precisará dele se precisar fazer login sem seu dispositivo.`,
	ru: `Скопируйте этот код восстановления и храните его в безопасном месте. Он вам понадобится, если вам когда-нибудь понадобится войти в систему без вашего устройства.`,
	sm: `Kopi le numera faalilolilo lenei o le toe faaleleia ma teu i se mea saogalemu. O le a e manaomia pe afai e te manaomia le alu i totonu e aunoa ma lau masini.`,
	so: `Nuqul ka dhig lambarkan ka soo kabashada oo meel ammaan ah ka ilaali. Waad u baahan doontaa haddii aad marnaba u baahan tahay inaad gasho qalab la'aanteed.`,
	th: `คัดลอกรหัสการกู้คืนนี้และเก็บไว้ในที่ปลอดภัย คุณจะต้องใช้มันหากคุณต้องการเข้าสู่ระบบโดยไม่ต้องใช้อุปกรณ์ของคุณ`,
	ur: `اس بازیابی کوڈ کو کاپی کریں اور اسے کہیں محفوظ رکھیں۔ اگر آپ کو کبھی بھی اپنے آلے کے بغیر لاگ ان کرنے کی ضرورت ہو تو آپ کو اس کی ضرورت ہوگی۔`,
	vi: `Sao chép mã khôi phục này và giữ nó ở nơi an toàn. Bạn sẽ cần nó nếu bạn cần đăng nhập mà không cần thiết bị của mình.`,
	'zh-hans': `复制此恢复代码并将其保存在安全的地方。如果您需要在没有设备的情况下登录，您将需要它。`,
	'zh-hant': `複製此恢復代碼並將其保存在安全的地方。如果您需要在沒有設備的情況下登錄，您將需要它。`,
	en: `Copy this recovery code and keep it somewhere safe. You’ll need it if you ever need to log in without your device.`
};

export const loc_recoveryCode: Record<Locale, string> = {
	ar: `رمز الاسترداد`,
	de: `Wiederherstellungscode`,
	es: `Código de recuperación`,
	fa: `کد بازیابی`,
	fil: `Recovery code`,
	fr: `Code de récupération`,
	hi: `रिकवरी कोड`,
	ht: `Kòd rekiperasyon`,
	it: `Codice di ripristino`,
	ja: `リカバリーコード`,
	ko: `복구 코드`,
	mww: `Rov ua hauj lwm dua`,
	ps: `د بیاموندنه کوډ`,
	pt: `Código de recuperação`,
	'pt-pt': `Código de recuperação`,
	ru: `Код восстановления`,
	sm: `Numera o le toe faaleleia`,
	so: `Recovery code`,
	th: `รหัสการกู้คืน`,
	ur: `بازیابی کا کوڈ`,
	vi: `Mã khôi phục`,
	'zh-hans': `恢复代码`,
	'zh-hant': `恢復代碼`,
	en: `Recovery code`
};

export const loc_enroll: Record<Locale, string> = {
	ar: `تسجيل`,
	de: `Einschreiben`,
	es: `Inscribirse`,
	fa: `ثبت نام`,
	fil: `Mag-enrol`,
	fr: `Inscrire`,
	hi: `भर्ती करना`,
	ht: `Anwole`,
	it: `Iscriversi`,
	ja: `入る`,
	ko: `등록`,
	mww: `Muaj Npe Muaj Npe`,
	ps: `شاملول`,
	pt: `Inscrever`,
	'pt-pt': `Inscrever-se`,
	ru: `Зачислять`,
	sm: `Lesitala`,
	so: `isdiiwaangalin`,
	th: `ลงทะเบียน`,
	ur: `اندراج`,
	vi: `Đăng ký`,
	'zh-hans': `注册`,
	'zh-hant': `註冊`,
	en: `Enroll`
};

export const loc_phoneSMS: Record<Locale, string> = {
	ar: `الهاتف (الرسائل القصيرة)`,
	de: `Telefon (SMS)`,
	es: `Teléfono (SMS)`,
	fa: `تلفن (پیامک)`,
	fil: `Telepono (SMS)`,
	fr: `Téléphone (SMS)`,
	hi: `फोन (एसएमएस)`,
	ht: `Telefòn (SMS)`,
	it: `Telefono (SMS)`,
	ja: `電話(SMS)`,
	ko: `전화(SMS)`,
	mww: `Xov Tooj (SMS)`,
	ps: `تلیفون (SMS)`,
	pt: `Telefone (SMS)`,
	'pt-pt': `Telefone (SMS)`,
	ru: `Телефон (SMS)`,
	sm: `Telefoni (SMS)`,
	so: `Telefoonka (SMS)`,
	th: `โทรศัพท์ (SMS)`,
	ur: `فون (ایس ایم ایس)`,
	vi: `Điện thoại (SMS)`,
	'zh-hans': `电话 （SMS）`,
	'zh-hant': `電話 （SMS）`,
	en: `Phone (SMS)`
};

export const loc_notEnrolled: Record<Locale, string> = {
	ar: `غير مسجل`,
	de: `Nicht eingeschrieben`,
	es: `No inscritos`,
	fa: `ثبت نام نشده است`,
	fil: `Hindi naka-enroll`,
	fr: `Non inscrit`,
	hi: `नामांकित नहीं`,
	ht: `Pa enskri`,
	it: `Non iscritto`,
	ja: `未登録`,
	ko: `등록되지 않음`,
	mww: `Tsis muaj npe tau txais kev pab`,
	ps: `نه دی شامل شوی`,
	pt: `Não inscrito`,
	'pt-pt': `Não inscrito`,
	ru: `Не зачислен`,
	sm: `E le'i lesitalaina`,
	so: `Lama qorin`,
	th: `ไม่ได้ลงทะเบียน`,
	ur: `اندراج نہیں ہوا`,
	vi: `Chưa đăng ký`,
	'zh-hans': `未注册`,
	'zh-hant': `未註冊`,
	en: `Not enrolled`
};

export const loc_multiFactorAuthentication: Record<Locale, string> = {
	ar: `المصادقة متعددة العوامل (MFA)`,
	de: `Multi-Faktor-Authentifizierung (MFA)`,
	es: `Autenticación multifactor (MFA)`,
	fa: `احراز هویت چند عاملی (MFA)`,
	fil: `Multi-factor authentication (MFA)`,
	fr: `Authentification multifacteur (MFA)`,
	hi: `बहु-कारक प्रमाणीकरण (एमएफए)`,
	ht: `Milti-faktè otantifikasyon (MFA)`,
	it: `Autenticazione a più fattori (MFA)`,
	ja: `多要素認証(MFA)`,
	ko: `다단계 인증(MFA)`,
	mww: `Tej yam authentication (MFA)`,
	ps: `د کثیر فکتور تصدیق (MFA)`,
	pt: `Autenticação multifator (MFA)`,
	'pt-pt': `Autenticação multifator (MFA)`,
	ru: `Многофакторная аутентификация (MFA)`,
	sm: `Faamaoniaina o vaega e tele (MFA)`,
	so: `Aqoonsiga Multi-factor (MFA)`,
	th: `การรับรองความถูกต้องแบบหลายปัจจัย (MFA)`,
	ur: `ملٹی فیکٹر تصدیق (ایم ایف اے)`,
	vi: `Xác thực đa yếu tố (MFA)`,
	'zh-hans': `多重身份验证 （MFA）`,
	'zh-hant': `多重身份驗證 （MFA）`,
	en: `Multi-factor authentication (MFA)`
};

export const loc_totp: Record<Locale, string> = {
	ar: `كلمة المرور لمرة واحدة المستندة إلى الوقت (TOTP)`,
	de: `Zeitbasiertes Einmalpasswort (TOTP)`,
	es: `Contraseña de un solo uso basada en el tiempo (TOTP)`,
	fa: `رمز عبور یکبار مصرف مبتنی بر زمان (TOTP)`,
	fil: `Isang Password na Nakabatay sa Oras (TOTP)`,
	fr: `Mot de passe à usage unique basé sur le temps (TOTP)`,
	hi: `समय-आधारित वन-टाइम पासवर्ड (TOTP)`,
	ht: `Modpas Yon sèl-fwa (TOTP)`,
	it: `Password monouso basata sul tempo (TOTP)`,
	ja: `時間ベースのワンタイムパスワード(TOTP)`,
	ko: `시간 기반 일회용 암호(TOTP)`,
	mww: `Sij hawm kuas ib-lub sij hawm Password (TOTP)`,
	ps: `د وخت پر بنسټ د یو وخت پاسورډ (TOTP)`,
	pt: `Senha de uso único baseada em tempo (TOTP)`,
	'pt-pt': `Senha única baseada no tempo (TOTP)`,
	ru: `Одноразовый пароль на основе времени (TOTP)`,
	sm: `Uputatala e Faavae i le Taimi e Tasi (TOTP)`,
	so: `Waqtiga ku salaysan Hal-Time Password (TOTP)`,
	th: `รหัสผ่านแบบใช้ครั้งเดียวตามเวลา (TOTP)`,
	ur: `وقت پر مبنی ون ٹائم پاس ورڈ (ٹی او ٹی پی)`,
	vi: `Mật khẩu dùng một lần dựa trên thời gian (TOTP)`,
	'zh-hans': `基于时间的一次性密码 （TOTP）`,
	'zh-hant': `基於時間的一次性密碼 （TOTP）`,
	en: `Time-based One-Time Password (TOTP)`
};

export const loc_enrolled: Record<Locale, string> = {
	ar: `المسجلين`,
	de: `Eingeschrieben`,
	es: `Inscrito`,
	fa: `ثبت نام`,
	fil: `Nag-enrol`,
	fr: `Inscrits`,
	hi: `नामांकित`,
	ht: `Anwole`,
	it: `Iscritto`,
	ja: `登録`,
	ko: `등록`,
	mww: `Muaj Npe Muaj Npe Kawm`,
	ps: `نوم لیکنه`,
	pt: `Matriculado`,
	'pt-pt': `Inscritos`,
	ru: `Зачислены`,
	sm: `Lesitala`,
	so: `Dadka la isku qoray`,
	th: `ลง ทะเบียน`,
	ur: `اندراج`,
	vi: `Đăng ký`,
	'zh-hans': `登记`,
	'zh-hant': `登記`,
	en: `Enrolled`
};

export const loc_enrolledWith: Record<Locale, (name: unknown) => string> = {
	ar: (name) => `مسجل مع ${name}`,
	de: (name) => `Registriert bei ${name}`,
	es: (name) => `Inscrito con ${name}`,
	fa: (name) => `ثبت نام با ${name}`,
	fil: (name) => `Nakarehistro sa ${name}`,
	fr: (name) => `Inscrit au ${name}`,
	hi: (name) => `${name} के साथ नामांकित`,
	ht: (name) => `Enskri ak ${name}`,
	it: (name) => `Iscritto con ${name}`,
	ja: (name) => `${name}に登録済み`,
	ko: (name) => `${name}에 등록됨`,
	mww: (name) => `Muaj npe zwm rau ${name}`,
	ps: (name) => `د نوم لیکنې سره ${name}`,
	pt: (name) => `Inscrito com ${name}`,
	'pt-pt': (name) => `Inscrito com ${name}`,
	ru: (name) => `Зарегистрирован по телефону ${name}`,
	sm: (name) => `Lesitala i le ${name}`,
	so: (name) => `Ku qoran ${name}`,
	th: (name) => `ลงทะเบียนกับ ${name}`,
	ur: (name) => `${name} ساتھ اندراج کیا گیا`,
	vi: (name) => `Đã ghi danh với ${name}`,
	'zh-hans': (name) => `注册电话 ${name}`,
	'zh-hant': (name) => `註冊電話 ${name}`,
	en: (name) => `Enrolled with ${name}`
};

export const loc_continue: Record<Locale, string> = {
	ar: `استمر`,
	de: `Fortsetzen`,
	es: `Continuar`,
	fa: `ادامه`,
	fil: `Magpatuloy`,
	fr: `Continuer`,
	hi: `जारी रखना`,
	ht: `Kontinye`,
	it: `Continuare`,
	ja: `続ける`,
	ko: `계속하다`,
	mww: `Mus`,
	ps: `ادامه`,
	pt: `Continuar`,
	'pt-pt': `Continuar`,
	ru: `Продолжать`,
	sm: `Faaauau`,
	so: `Sii wad`,
	th: `ต่อเนื่อง`,
	ur: `جاری رکھنا`,
	vi: `Tiếp tục`,
	'zh-hans': `继续`,
	'zh-hant': `繼續`,
	en: `Continue`
};

export const loc_secureYourAcountSMS: Record<Locale, string> = {
	ar: `تأمين حسابك باستخدام الهاتف (SMS)`,
	de: `Sichern Sie Ihr Konto per Telefon (SMS)`,
	es: `Asegure su cuenta mediante el teléfono (SMS)`,
	fa: `ایمن سازی حساب خود با استفاده از تلفن (پیامک)`,
	fil: `Secure ang iyong account gamit ang Phone (SMS)`,
	fr: `Sécurisez votre compte à l’aide du téléphone (SMS)`,
	hi: `फ़ोन (SMS) का उपयोग करके अपना खाता सुरक्षित करें`,
	ht: `Sekirite kont ou lè l sèvi avèk Telefòn (SMS)`,
	it: `Proteggi il tuo account tramite telefono (SMS)`,
	ja: `電話(SMS)を使用してアカウントを保護する`,
	ko: `전화(SMS)를 사용하여 계정 보호`,
	mww: `Ruaj ntseg koj tus account siv Xov Tooj (SMS)`,
	ps: `خپل حساب د تلیفون له لارې خوندی کړئ (SMS)`,
	pt: `Proteja sua conta usando Telefone (SMS)`,
	'pt-pt': `Proteja sua conta usando Telefone (SMS)`,
	ru: `Защитите свою учетную запись с помощью телефона (SMS)`,
	sm: `Faamautu lau faitotoa e faaaoga ai le Telefoni (SMS)`,
	so: `Xaji akoonkaaga adoo isticmaalaya Phone (SMS)`,
	th: `รักษาความปลอดภัยให้กับบัญชีของคุณโดยใช้โทรศัพท์ (SMS)`,
	ur: `فون (SMS) کا استعمال کرتے ہوئے اپنے اکاؤنٹ کو محفوظ بنائیں`,
	vi: `Bảo mật tài khoản của bạn bằng Điện thoại (SMS)`,
	'zh-hans': `使用电话 （SMS） 保护您的帐户`,
	'zh-hant': `使用電話 （SMS） 保護您的帳戶`,
	en: `Secure your account using Phone (SMS)`
};

export const loc_associateSMSInstruction: Record<Locale, string> = {
	ar: `أدخل رقم هاتفك أدناه. سيتم إرسال رسالة نصية قصيرة إلى هذا الرقم مع رمز لإدخاله في الشاشة التالية.`,
	de: `Geben Sie unten Ihre Telefonnummer ein. An diese Nummer wird eine SMS mit einem Code gesendet, den Sie auf dem nächsten Bildschirm eingeben müssen.`,
	es: `Ingrese su número de teléfono a continuación. Se enviará un SMS a ese número con un código para ingresar en la siguiente pantalla.`,
	fa: `شماره تلفنتان را در زیر وارد کنید. پیامکی به آن شماره با یک کد برای وارد کردن در صفحه بعدی ارسال می شود.`,
	fil: `Ipasok ang iyong numero ng telepono sa ibaba. Ang SMS ay ipapadala sa numerong iyon na may code na ipasok sa susunod na screen.`,
	fr: `Entrez votre numéro de téléphone ci-dessous. Un SMS sera envoyé à ce numéro avec un code à saisir sur l’écran suivant.`,
	hi: `नीचे अपना फ़ोन नंबर दर्ज करें। अगली स्क्रीन पर दर्ज करने के लिए एक कोड के साथ उस नंबर पर एक एसएमएस भेजा जाएगा।`,
	ht: `Antre nimewo telefòn ou anba a. Yo pral voye yon SMS bay nimewo sa a ak yon kòd antre nan ekran kap vini an.`,
	it: `Inserisci il tuo numero di telefono qui sotto. A quel numero verrà inviato un SMS con un codice da inserire nella schermata successiva.`,
	ja: `以下に電話番号を入力してください。その番号にSMSが送信され、次の画面で入力するコードが送信されます。`,
	ko: `아래에 전화번호를 입력하세요. 다음 화면에서 입력할 코드와 함께 SMS가 해당 번호로 전송됩니다.`,
	mww: `Sau koj tus xov tooj hauv qab no. Tus SMS yuav muab xa mus rau ntawd tus xov tooj uas muaj ib tug code nkag mus rau ntawm qhov screen tom ntej no.`,
	ps: `لاندې خپل د تلیفون شمیره دننه کړئ. یو پیغام به دې شمیرې ته د کوډ سره لیږل کیږی ترڅو په راتلونکی سکرین کې دننه شی.`,
	pt: `Digite seu número de telefone abaixo. Um SMS será enviado para esse número com um código para inserir na próxima tela.`,
	'pt-pt': `Introduza o seu número de telefone abaixo. Um SMS será enviado para esse número com um código para inserir na próxima tela.`,
	ru: `Введите свой номер телефона ниже. На этот номер будет отправлено SMS-сообщение с кодом для ввода на следующем экране.`,
	sm: `Ta i totonu lau numera o le telefoni o loo i lalo. O le a auina atu se SMS i lena numera ma se numera faalilolilo e ta i totonu i le lau e sosoo ai.`,
	so: `Nambarka telefoonkaaga hoose ku qor. SMS ayaa loo diri doonaa lambarkaas oo leh code si ay ugu galaan shaashadda xigta.`,
	th: `ป้อนหมายเลขโทรศัพท์ของคุณด้านล่าง SMS จะถูกส่งไปยังหมายเลขนั้นพร้อมรหัสเพื่อป้อนในหน้าจอถัดไป`,
	ur: `نیچے اپنا فون نمبر درج کریں۔ اگلی اسکرین پر داخل ہونے کے لئے کوڈ کے ساتھ اس نمبر پر ایک ایس ایم ایس بھیجا جائے گا۔`,
	vi: `Nhập số điện thoại của bạn bên dưới. Một tin nhắn SMS sẽ được gửi đến số đó với một mã để nhập vào màn hình tiếp theo.`,
	'zh-hans': `在下面输入您的电话号码。将向该号码发送一条短信，其中包含可在下一个屏幕上输入的代码。`,
	'zh-hant': `在下面輸入您的電話號碼。將向該號碼發送一條短信，其中包含可在下一個螢幕上輸入的代碼。`,
	en: `Enter your phone number below. An SMS will be sent to that number with a code to enter on the next screen.`
};

export const loc_confirmSMSFormat: Record<Locale, (phone_number: unknown) => string> = {
	ar: (phone_number) => `لقد أرسلنا رسالة نصية إلى +1 ${phone_number}. أدخل الرمز المكون من 6 أرقام من الرسالة النصية التي تلقيتها.`,
	de: (phone_number) => `Wir haben eine SMS an +1 ${phone_number} gesendet. Geben Sie den 6-stelligen Code aus der SMS ein, die Sie erhalten haben.`,
	es: (phone_number) => `Hemos enviado un mensaje de texto al +1 ${phone_number}. Ingresa el código de 6 dígitos del mensaje de texto que recibiste.`,
	fa: (phone_number) => `ما یک پیام متنی به + ${phone_number} ارسال کرده ایم. کد ۶ رقمی را از پیامکی که دریافت کرده اید وارد کنید.`,
	fil: (phone_number) => `Nagpadala na kami ng text message sa +1 ${phone_number}. Ipasok ang 6-digit code mula sa text message na natanggap mo.`,
	fr: (phone_number) => `Nous avons envoyé un SMS au +1 ${phone_number}. Entrez le code à 6 chiffres du SMS que vous avez reçu.`,
	hi: (phone_number) => `हमने +1 ${phone_number} पर एक टेक्स्ट संदेश भेजा है। आपको प्राप्त पाठ संदेश से 6-अंकीय कोड दर्ज करें।`,
	ht: (phone_number) => `Nou te voye yon mesaj tèks bay + 1 ${phone_number}. Antre kòd la 6-chif nan mesaj tèks ou te resevwa a.`,
	it: (phone_number) => `Abbiamo inviato un messaggio di testo al numero +1 ${phone_number}. Inserisci il codice a 6 cifre del messaggio di testo che hai ricevuto.`,
	ja: (phone_number) => `+1 ${phone_number} にテキスト メッセージを送信しました。受信したテキストメッセージに記載されている6桁のコードを入力します。`,
	ko: (phone_number) => `+1 ${phone_number}로 문자 메시지를 보냈습니다. 받은 문자 메시지의 6자리 코드를 입력합니다.`,
	mww: (phone_number) => `Peb twb xa ib phau ntawv lus rau +1 ${phone_number}. Sau tus 6-digit code ntawm cov ntawv nyeem uas koj tau txais.`,
	ps: (phone_number) => `موږ د +1 ${phone_number} ته د متن پیغام لیږو. د 6 عددی کوډ دننه کړئ د متن پیغام څخه چې تاسو ترلاسه کړی.`,
	pt: (phone_number) => `Enviamos uma mensagem de texto para +1 ${phone_number}. Digite o código de 6 dígitos da mensagem de texto que você recebeu.`,
	'pt-pt': (phone_number) => `Enviamos uma mensagem de texto para +1 ${phone_number}. Introduza o código de 6 dígitos da mensagem de texto que recebeu.`,
	ru: (phone_number) => `Мы отправили текстовое сообщение на номер +1 ${phone_number}. Введите 6-значный код из полученного вами текстового сообщения.`,
	sm: (phone_number) => `Ua matou auina atu se feau tusitusia i le +1 ${phone_number}. Ta i totonu le numera faailo e 6-fuainumera mai le feau tusitusia na e mauaina.`,
	so: (phone_number) => `Waxaan fariin qoraal ah u dirnay +1 ${phone_number}. Ku qor nambarka 6-lambar ee fariintii qoraalka ahaa ee aad heshay ku qor.`,
	th: (phone_number) => `เราได้ส่งข้อความไปที่ +1 ${phone_number} ป้อนรหัส 6 หลักจากข้อความที่คุณได้รับ`,
	ur: (phone_number) => `ہم نے +1 ${phone_number} پر ٹیکسٹ میسج بھیجا ہے۔ آپ کو موصول ہونے والے ٹیکسٹ میسج سے 6 ہندسوں کا کوڈ درج کریں۔`,
	vi: (phone_number) => `Chúng tôi đã gửi tin nhắn văn bản đến +1 ${phone_number}. Nhập mã gồm 6 chữ số từ tin nhắn văn bản bạn nhận được.`,
	'zh-hans': (phone_number) => `我们已发送短信至 +1 ${phone_number}。输入您收到的短信中的 6 位代码。`,
	'zh-hant': (phone_number) => `我們已發送簡訊至 +1 ${phone_number}。輸入您收到的簡訊中的6位代碼。`,
	en: (phone_number) => `We've sent a text message to +1 ${phone_number}. Enter the 6-digit code from the text message you received.`
};

export const loc_oneTimeCode: Record<Locale, string> = {
	ar: `رمز لمرة واحدة`,
	de: `Einmaliger Code`,
	es: `Código de un solo uso`,
	fa: `کد یکبار مصرف`,
	fil: `Isang beses na code`,
	fr: `Code à usage unique`,
	hi: `एक बार का कोड`,
	ht: `Kòd yon sèl fwa`,
	it: `Codice monouso`,
	ja: `ワンタイムコード`,
	ko: `일회성 코드`,
	mww: `Ib-lub sij hawm code`,
	ps: `د یو وخت کوډ`,
	pt: `Código único`,
	'pt-pt': `Código de uso único`,
	ru: `Одноразовый код`,
	sm: `Numera faailo e tasi le taimi`,
	so: `Hal-mar code`,
	th: `รหัสแบบใช้ครั้งเดียว`,
	ur: `ون ٹائم کوڈ`,
	vi: `Mã một lần`,
	'zh-hans': `一次性代码`,
	'zh-hant': `一次性代碼`,
	en: `One-time code`
};

export const loc_secureYourAcountTOTP: Record<Locale, string> = {
	ar: `قم بتأمين حسابك باستخدام كلمة المرور لمرة واحدة المستندة إلى الوقت (TOTP)`,
	de: `Sichern Sie Ihr Konto mit dem zeitbasierten Einmalpasswort (TOTP)`,
	es: `Proteja su cuenta con una contraseña de un solo uso basada en el tiempo (TOTP)`,
	fa: `حساب خود را با استفاده از رمز عبور یکبار مصرف مبتنی بر زمان (TOTP) ایمن کنید`,
	fil: `Secure ang iyong account gamit ang Time-based One-Time Password (TOTP)`,
	fr: `Sécurisez votre compte à l’aide d’un mot de passe à usage unique basé sur le temps (TOTP)`,
	hi: `समय-आधारित वन-टाइम पासवर्ड (TOTP) का उपयोग करके अपना खाता सुरक्षित करें`,
	ht: `Sekirite kont ou lè l sèvi avèk Modpas One-Time (TOTP)`,
	it: `Proteggi il tuo account utilizzando la password monouso a tempo (TOTP)`,
	ja: `時間ベースのワンタイムパスワード(TOTP)を使用してアカウントを保護します`,
	ko: `TOTP(Time-based One-Time Password)를 사용하여 계정 보호`,
	mww: `Ruaj ntseg koj tus account siv sij hawm ib-lub sij hawm Password (TOTP)`,
	ps: `د وخت پر بنسټ د یو وخت رمز (TOTP) په کارولو سره خپل حساب خوندی کړئ`,
	pt: `Proteja sua conta usando a senha de uso único baseada em tempo (TOTP)`,
	'pt-pt': `Proteja sua conta usando a senha única baseada no tempo (TOTP)`,
	ru: `Защитите свою учетную запись с помощью одноразового пароля на основе времени (TOTP)`,
	sm: `Faamautu lau faitotoa e faaaoga ai le Uputatala e Faavae i le Taimi e Tasi (TOTP)`,
	so: `Xafido aad account isticmaalaya Time-ku salaysan One-Time Password (TOTP)`,
	th: `รักษาความปลอดภัยบัญชีของคุณโดยใช้รหัสผ่านแบบใช้ครั้งเดียวตามเวลา (TOTP)`,
	ur: `وقت پر مبنی ون ٹائم پاس ورڈ (ٹی او ٹی پی) کا استعمال کرتے ہوئے اپنے اکاؤنٹ کو محفوظ بنائیں`,
	vi: `Bảo mật tài khoản của bạn bằng Mật khẩu dùng một lần dựa trên thời gian (TOTP)`,
	'zh-hans': `使用基于时间的一次性密码 （TOTP） 保护您的帐户`,
	'zh-hant': `使用基於時間的一次性密碼 （TOTP） 保護您的帳戶`,
	en: `Secure your account using Time-based One-Time Password (TOTP)`
};

export const loc_associateTOTPInstruction: Record<Locale, string> = {
	ar: `امسح رمز الاستجابة السريعة أعلاه باستخدام تطبيق المصادقة المفضل لديك ثم أدخل الرمز المقدم لمرة واحدة أدناه.`,
	de: `Scannen Sie den obigen QR-Code mit Ihrer bevorzugten Authentifizierungs-App und geben Sie dann den unten angegebenen Einmalcode ein.`,
	es: `Escanee el código QR de arriba con su aplicación de autenticación preferida y luego ingrese el código de un solo uso que se proporciona a continuación.`,
	fa: `کد QR بالا را با استفاده از برنامه احراز هویت دلخواه خود اسکن کنید و سپس کد یکبار مصرف ارائه شده را در زیر وارد کنید.`,
	fil: `I scan ang QR Code sa itaas gamit ang iyong ginustong authenticator app at pagkatapos ay ipasok ang ibinigay na isang beses na code sa ibaba.`,
	fr: `Scannez le code QR ci-dessus à l’aide de votre application d’authentification préférée, puis entrez le code à usage unique fourni ci-dessous.`,
	hi: `अपने पसंदीदा प्रमाणक ऐप का उपयोग करके ऊपर दिए गए क्यूआर कोड को स्कैन करें और फिर नीचे दिया गया वन-टाइम कोड दर्ज करें।`,
	ht: `Analysis kòd la QR pi wo a lè l sèvi avèk app otantifikatè pi pito ou ak Lè sa a, antre nan kòd la bay yon sèl-fwa anba a.`,
	it: `Scansiona il codice QR qui sopra utilizzando la tua app di autenticazione preferita, quindi inserisci il codice monouso fornito di seguito.`,
	ja: `お好みの認証アプリを使用して上記のQRコードをスキャンし、以下に提供されたワンタイムコードを入力します。`,
	ko: `선호하는 인증 앱을 사용하여 위의 QR 코드를 스캔한 다음 아래에 제공된 일회성 코드를 입력합니다.`,
	mww: `Scan lub QR Code saum toj no siv koj najnpawb authenticator app thiab ces nkag mus rau hauv qab no.`,
	ps: `د خپل غوره شوی تصدیق کونکی اپلیکیشن په کارولو سره پورته د QR کوډ سکین کړئ او بیا لاندې چمتو شوی یو ځل کوډ دننه کړئ.`,
	pt: `Digitalize o QR Code acima usando seu aplicativo autenticador preferido e insira o código único fornecido abaixo.`,
	'pt-pt': `Digitalize o QR Code acima usando seu aplicativo autenticador preferido e, em seguida, insira o código único fornecido abaixo.`,
	ru: `Отсканируйте QR-код выше с помощью предпочитаемого приложения для аутентификации, а затем введите предоставленный одноразовый код ниже.`,
	sm: `Faata le QR Code o loo i luga e faaaoga ai le polokalama o le authenticator e te fiafia i ai ona ta lea i totonu o le numera faailo e tasi le taimi o loo tuuina mai i lalo.`,
	so: `Iskaanka QR Code-ka ee kor ku xusan adigoo isticmaalaya app-ka authenticator aad doorbidayso ka dibna ku qor lambarka hal mar ee la bixiyey ee hoose.`,
	th: `สแกน QR Code ด้านบนโดยใช้แอปยืนยันตัวตนที่คุณต้องการ จากนั้นป้อนรหัสแบบใช้ครั้งเดียวที่ให้ไว้ด้านล่าง`,
	ur: `اپنی پسندیدہ تصدیق کار ایپ کا استعمال کرتے ہوئے اوپر کیو آر کوڈ اسکین کریں اور پھر نیچے فراہم کردہ ون ٹائم کوڈ درج کریں۔`,
	vi: `Quét mã QR ở trên bằng ứng dụng xác thực ưa thích của bạn và sau đó nhập mã một lần được cung cấp bên dưới.`,
	'zh-hans': `使用您首选的身份验证器应用程序扫描上面的二维码，然后在下面输入提供的一次性代码。`,
	'zh-hant': `使用您首選的身份驗證器應用程序掃描上面的二維碼，然後在下面輸入提供的一次性代碼。`,
	en: `Scan the QR Code above using your preferred authenticator app and then enter the provided one-time code below.`
};

export const loc_troubleScanningQRCode: Record<Locale, string> = {
	ar: `هل تواجه مشكلة في مسح رمز الاستجابة السريعة ضوئيا؟`,
	de: `Probleme beim Scannen des QR-Codes?`,
	es: `¿Tienes problemas para escanear el código QR?`,
	fa: `در اسکن کد QR مشکل دارید؟`,
	fil: `Problema sa pag-scan ng QR code?`,
	fr: `Vous avez du mal à scanner le code QR ?`,
	hi: `क्यूआर कोड स्कैन करने में परेशानी हो रही है?`,
	ht: `Pwoblèm pou optik kòd la QR?`,
	it: `Problemi con la scansione del codice QR?`,
	ja: `QRコードのスキャンに問題がありますか?`,
	ko: `QR 코드를 스캔하는 데 문제가 있습니까?`,
	mww: `Teeb meem scanning lub QR code?`,
	ps: `د QR کوډ سکین کولو کې ستونزه؟`,
	pt: `Problemas para escanear o código QR?`,
	'pt-pt': `Problemas para escanear o código QR?`,
	ru: `Проблемы со сканированием QR-кода?`,
	sm: `Faafitauli i le faata o le numera faailo QR?`,
	so: `Dhib inaad iska baarto lambarka QR?`,
	th: `ปัญหาในการสแกนรหัส QR ใช่ไหม`,
	ur: `کیو آر کوڈ اسکین کرنے میں دشواری؟`,
	vi: `Bạn gặp sự cố khi quét mã QR?`,
	'zh-hans': `扫描 QR 码时遇到问题？`,
	'zh-hant': `掃描 QR 碼時遇到問題？`,
	en: `Trouble scanning the QR code?`
};

export const loc_enterOneTimeCode: Record<Locale, string> = {
	ar: `أدخل الرمز لمرة واحدة من تطبيق المصادقة الخاص بك.`,
	de: `Geben Sie den Einmalcode aus Ihrer Authentifizierungs-App ein.`,
	es: `Ingresa el código de un solo uso de tu aplicación de autenticación.`,
	fa: `کد یکبار مصرف را از برنامه احراز هویت خود وارد کنید.`,
	fil: `Ipasok ang isang beses na code mula sa iyong authenticator app.`,
	fr: `Entrez le code à usage unique de votre application d’authentification.`,
	hi: `अपने प्रमाणक ऐप्लिकेशन से एक बार का कोड डालें.`,
	ht: `Antre kòd la yon sèl-fwa soti nan app natif natal ou.`,
	it: `Inserisci il codice monouso dall'app di autenticazione.`,
	ja: `認証アプリからワンタイムコードを入力します。`,
	ko: `인증 앱에서 일회성 코드를 입력합니다.`,
	mww: `Sau ib-time code ntawm koj authenticator app.`,
	ps: `د خپل اصلی غوښتنلیک څخه د یو وخت کوډ دننه کړئ.`,
	pt: `Insira o código único do seu aplicativo autenticador.`,
	'pt-pt': `Insira o código único do seu aplicativo autenticador.`,
	ru: `Введите одноразовый код из приложения для аутентификации.`,
	sm: `Ta i totonu le numera faailo mo le taimi e tasi mai lau polokalama o le faamaonia.`,
	so: `Ka qor hal mar lambarkii aad ka heshay app-ka Authenticator.ka.`,
	th: `ป้อนรหัสแบบใช้ครั้งเดียวจากแอปตัวยืนยันตัวตนของคุณ`,
	ur: `اپنے تصدیق کنندہ ایپ سے ون ٹائم کوڈ درج کریں۔`,
	vi: `Nhập mã một lần từ ứng dụng xác thực của bạn.`,
	'zh-hans': `输入验证器应用程序中的一次性代码。`,
	'zh-hant': `輸入驗證器應用程式中的一次性代碼。`,
	en: `Enter the one-time code from your authenticator app.`
};

export const loc_totpComplete: Record<Locale, string> = {
	ar: `اكتمل التسجيل في كلمة المرور لمرة واحدة (TOTP) المستندة إلى الوقت!`,
	de: `Die Registrierung für Time-based One-Time Password (TOTP) abgeschlossen!`,
	es: `¡Se completó la inscripción de contraseña de un solo uso (TOTP) basada en el tiempo!`,
	fa: `ثبت نام رمز عبور یکبار مصرف (TOTP) مبتنی بر زمان تکمیل شد!`,
	fil: `Kumpleto na ang pag-enroll ng One-Time Password (TOTP) na batay sa Time-based One-Time Password!`,
	fr: `L’inscription au mot de passe à usage unique (TOTP) basé sur le temps est terminée !`,
	hi: `समय-आधारित वन-टाइम पासवर्ड (TOTP) नामांकन पूर्ण!`,
	ht: `Modpas Yon sèl-fwa (TOTP) enskripsyon konplè!`,
	it: `L'iscrizione alla Time-Based One-Time Password (TOTP) è stata completata!`,
	ja: `TOTP(Time-based One-Time Password)の登録が完了しました。`,
	ko: `TOTP(Time-based One-Time Password) 등록 완료!`,
	mww: `Sij hawm raws li ib-lub sij hawm Password (TOTP) sau npe ua tiav!`,
	ps: `د وخت پر بنسټ د یو وخت پاسورډ (TOTP) نوم لیکنه بشپړه کړئ!`,
	pt: `Inscrição de senha de uso único baseada em tempo (TOTP) concluída!`,
	'pt-pt': `Inscrição One-Time Password (TOTP) baseada no tempo concluída!`,
	ru: `Регистрация одноразового пароля (TOTP) на основе времени завершена!`,
	sm: `Ua maea le lesitalaga o le Uputatala e Faavae i le Taimi e Tasi (TOTP)!`,
	so: `Time-ku salaysan Hal-Time Password (TOTP) diiwaangelin dhameystiran!`,
	th: `การลงทะเบียนรหัสผ่านแบบใช้ครั้งเดียว (TOTP) ตามเวลาเสร็จสมบูรณ์!`,
	ur: `وقت پر مبنی ون ٹائم پاس ورڈ (ٹی او ٹی پی) اندراج مکمل!`,
	vi: `Đăng ký Mật khẩu dùng một lần (TOTP) dựa trên thời gian hoàn tất!`,
	'zh-hans': `基于时间的一次性密码 （TOTP） 注册完成！`,
	'zh-hant': `基於時間的一次性密碼 （TOTP） 註冊完成！`,
	en: `Time-based One-Time Password (TOTP) enrollment complete!`
};

export const loc_smsComplete: Record<Locale, string> = {
	ar: `اكتمل التسجيل عبر الهاتف (SMS)`,
	de: `Registrierung per Telefon (SMS) abgeschlossen`,
	es: `Inscripción por teléfono (SMS) completada`,
	fa: `ثبت نام تلفن (پیامک) به پایان رسید`,
	fil: `Natapos na ang enrollment sa telepono (SMS)`,
	fr: `Inscription par téléphone (SMS) terminée`,
	hi: `फोन (एसएमएस) नामांकन पूरा हुआ`,
	ht: `Telefòn (SMS) enskripsyon ranpli`,
	it: `Registrazione telefonica (SMS) completata`,
	ja: `電話(SMS)の登録が完了しました`,
	ko: `전화(SMS) 등록 완료`,
	mww: `Xov tooj (SMS) sau npe ua tiav`,
	ps: `د تلیفون (SMS) نوم لیکنه بشپړه شوه`,
	pt: `Inscrição por telefone (SMS) concluída`,
	'pt-pt': `Inscrição por telefone (SMS) concluída`,
	ru: `Регистрация по телефону (SMS) завершена`,
	sm: `Ua maea le lesitalaga i le telefoni (SMS)`,
	so: `Phone (SMS) diiwaangelin la dhammeystiray`,
	th: `การลงทะเบียนทางโทรศัพท์ (SMS) เสร็จสมบูรณ์`,
	ur: `فون (ایس ایم ایس) کا اندراج مکمل`,
	vi: `Đã hoàn tất đăng ký qua điện thoại (SMS)`,
	'zh-hans': `电话 （SMS） 注册已完成`,
	'zh-hant': `電話 （SMS） 註冊已完成`,
	en: `Phone (SMS) enrollment completed`
};

export const loc_manualOtpEnrollInstructions: Record<Locale, string> = {
	ar: `أدخل الكود التالي يدويا في تطبيق المصادقة المفضل لديك ثم أدخل الرمز المقدم لمرة واحدة أدناه.`,
	de: `Geben Sie den folgenden Code manuell in Ihre bevorzugte Authentifizierungs-App ein und geben Sie dann den unten angegebenen einmaligen Code ein.`,
	es: `Ingrese manualmente el siguiente código en su aplicación de autenticación preferida y luego ingrese el código de un solo uso proporcionado a continuación.`,
	fa: `کد زیر را به صورت دستی در برنامه احراز هویت دلخواه خود وارد کنید و سپس کد یکبار مصرف ارائه شده را در زیر وارد کنید.`,
	fil: `Manu manong ipasok ang sumusunod na code sa iyong ginustong authenticator app at pagkatapos ay ipasok ang ibinigay na isang beses na code sa ibaba.`,
	fr: `Entrez manuellement le code suivant dans votre application d’authentification préférée, puis entrez le code à usage unique fourni ci-dessous.`,
	hi: `मैन्युअल रूप से अपने पसंदीदा प्रमाणक ऐप में निम्न कोड दर्ज करें और फिर नीचे दिया गया वन-टाइम कोड दर्ज करें।`,
	ht: `Manyèlman antre nan kòd sa a nan app otantifikatè pi pito ou ak Lè sa a, antre nan kòd la bay yon sèl-fwa anba a.`,
	it: `Inserisci manualmente il seguente codice nella tua app di autenticazione preferita, quindi inserisci il codice monouso fornito di seguito.`,
	ja: `優先する認証アプリに次のコードを手動で入力し、以下に提供されているワンタイムコードを入力します。`,
	ko: `선호하는 인증 앱에 다음 코드를 수동으로 입력한 다음, 아래에 제공된 일회성 코드를 입력합니다.`,
	mww: `Manually nkag mus rau hauv qab no code rau koj najnpawb authenticator app thiab ces nkag mus rau hauv qab no.`,
	ps: `لاندې کوډ په خپل غوره شوی تصدیق کونکی اپلیکیشن کې دننه کړئ او بیا لاندې ورکړل شوی یو ځل کوډ دننه کړئ.`,
	pt: `Insira manualmente o código a seguir em seu aplicativo autenticador preferido e, em seguida, insira o código único fornecido abaixo.`,
	'pt-pt': `Introduza manualmente o seguinte código na sua aplicação autenticadora preferida e, em seguida, introduza o código único fornecido abaixo.`,
	ru: `Вручную введите следующий код в предпочитаемое приложение для аутентификации, а затем введите предоставленный ниже одноразовый код.`,
	sm: `Ta i totonu le numera faailo lenei i totonu o le polokalama o le faamaonia e te fiafia i ai ona ta lea i totonu o le numera faailo e tasi le taimi o loo tuuina mai i lalo.`,
	so: `Joogu gali code soo socda galay app authenticator aad doorbidayso ka dibna gali la siiyay code hal mar hoos.`,
	th: `ป้อนรหัสต่อไปนี้ลงในแอปตัวยืนยันตัวตนที่คุณต้องการด้วยตนเอง จากนั้นป้อนรหัสแบบใช้ครั้งเดียวที่ให้ไว้ด้านล่าง`,
	ur: `دستی طور پر مندرجہ ذیل کوڈ کو اپنے پسندیدہ تصدیق کار ایپ میں درج کریں اور پھر نیچے فراہم کردہ ون ٹائم کوڈ درج کریں۔`,
	vi: `Nhập thủ công mã sau vào ứng dụng xác thực ưa thích của bạn, sau đó nhập mã một lần được cung cấp bên dưới.`,
	'zh-hans': `在您首选的身份验证器应用程序中手动输入以下代码，然后在下面输入提供的一次性代码。`,
	'zh-hant': `在您首選的身份驗證器應用程式中手動輸入以下代碼，然後在下面輸入提供的一次性代碼。`,
	en: `Manually enter the following code into your preferred authenticator app and then enter the provided one-time code below.`
};

export const loc_mfaRequiredSidebarNotice: Record<Locale, string> = {
	ar: `🔒 ستكون MFA مطلوبة في 11/13.`,
	de: `🔒 MFA wird am 13.11. erforderlich sein.`,
	es: `🔒 Se requerirá MFA el 13/11.`,
	fa: `🔒 MFA در تاریخ 11/13 مورد نیاز خواهد بود.`,
	fil: `🔒 Kakailanganin ang MFA sa 11/13.`,
	fr: `🔒 L’authentification multifacteur sera exigée le 13/11.`,
	hi: `🔒 11/13 को एमएफए की आवश्यकता होगी।`,
	ht: `🔒 MFA ap obligatwa sou 11/13.`,
	it: `🔒 L'MFA sarà richiesto il 13/11.`,
	ja: `🔒 MFAは11/13に必要になります。`,
	ko: `🔒 MFA는 11월 13일에 필요합니다.`,
	mww: `🔒 MFA yuav tsum tau rau 11/13.`,
	ps: `🔒 د 11 / 13 په اړه د MFA اړتیا ده.`,
	pt: `🔒 O MFA será exigido em 13/11.`,
	'pt-pt': `🔒 O MFA será requerido em 13/11.`,
	ru: `🔒 MFA потребуется 13/11.`,
	sm: `🔒 O le a manaomia le MFA i le aso 11/13.`,
	so: `🔒 MFA waxaa laga doonayaa inay ku 11/13.`,
	th: `🔒 จําเป็นต้องมี MFA ในวันที่ 11/13`,
	ur: `🔒 11/13 کو ایم ایف اے کی ضرورت ہوگی۔`,
	vi: `🔒 MFA sẽ được yêu cầu vào ngày 13/11.`,
	'zh-hans': `🔒 11 月 13 日将需要 MFA。`,
	'zh-hant': `🔒 11 月 13 日將需要 MFA。`,
	en: `🔒 MFA will be required on 11/13.`
};

export const loc_thinking: Record<Locale, string> = {
	ar: `فكر`,
	de: `Denken`,
	es: `Pensamiento`,
	fa: `تفکر`,
	fil: `Pag-iisip`,
	fr: `Pensée`,
	hi: `सोच रहे`,
	ht: `Panse`,
	it: `Pensante`,
	ja: `思索`,
	ko: `생각`,
	mww: `xav`,
	ps: `فکر`,
	pt: `Pensante`,
	'pt-pt': `Pensamento`,
	ru: `Мыслящий`,
	sm: `Mafaufau`,
	so: `Fikirka`,
	th: `ความคิด`,
	ur: `سوچ`,
	vi: `Suy nghĩ`,
	'zh-hans': `思维`,
	'zh-hant': `思維`,
	en: `Thinking`
};

export const loc_you: Record<Locale, string> = {
	ar: `أنت`,
	de: `Du`,
	es: `Tú`,
	fa: `شما`,
	fil: `Ikaw`,
	fr: `Vous`,
	hi: `तुम`,
	ht: `Ou`,
	it: `Tu`,
	ja: `あなたが`,
	ko: `너`,
	mww: `Koj`,
	ps: `ته یی`,
	pt: `Você`,
	'pt-pt': `Você`,
	ru: `Ты`,
	sm: `O Oe`,
	so: `waxaad`,
	th: `ท่าน`,
	ur: `تم`,
	vi: `Mày`,
	'zh-hans': `你`,
	'zh-hant': `你`,
	en: `You`
};

export const loc_copy: Record<Locale, string> = {
	ar: `نسخ`,
	de: `Kopieren`,
	es: `Copiar`,
	fa: `کپی`,
	fil: `Kopyahin`,
	fr: `Copier`,
	hi: `प्रतिलिपि`,
	ht: `Kopi`,
	it: `Copiare`,
	ja: `写し`,
	ko: `복사`,
	mww: `Luam ib daim qauv`,
	ps: `لمېسه`,
	pt: `Copiar`,
	'pt-pt': `Copiar`,
	ru: `Копировать`,
	sm: `Kopi`,
	so: `Nuqul`,
	th: `ลอก`,
	ur: `نقل`,
	vi: `Bản sao`,
	'zh-hans': `复制`,
	'zh-hant': `複製`,
	en: `Copy`
};

export const loc_chatPrompt1: Record<Locale, string> = {
	ar: `تلخيص هذا السجل`,
	de: `Fassen Sie diesen Datensatz zusammen`,
	es: `Resumir este registro`,
	fa: `این رکورد را خلاصه کنید`,
	fil: `Ibuod ang talaang ito`,
	fr: `Résumer ce record`,
	hi: `इस रिकॉर्ड को सारांशित करें`,
	ht: `Rezime dosye sa a`,
	it: `Riassumi questo record`,
	ja: `このレコードを要約する`,
	ko: `이 기록을 요약합니다.`,
	mww: `Sau tsab ntawv no`,
	ps: `دغه خوندېیښت لنډیز کړئ`,
	pt: `Resumir este registro`,
	'pt-pt': `Resumir este registo`,
	ru: `Подведите итоги этой летописи`,
	sm: `Otooto le faamaumauga lenei`,
	so: `Soo koobi rikoorkan`,
	th: `สรุปบันทึกนี้`,
	ur: `اس ریکارڈ کا خلاصہ کریں`,
	vi: `Tóm tắt hồ sơ này`,
	'zh-hans': `总结此记录`,
	'zh-hant': `總結此記錄`,
	en: `Summarize this record`
};

export const loc_chatPrompt2: Record<Locale, string> = {
	ar: `تحديد الخطوات التالية`,
	de: `Identifizieren Sie die nächsten Schritte`,
	es: `Identificar los próximos pasos`,
	fa: `مراحل بعدی را شناسایی کنید`,
	fil: `Tukuyin ang mga susunod na hakbang`,
	fr: `Identifier les prochaines étapes`,
	hi: `अगले चरणों की पहचान करें`,
	ht: `Idantifye pwochen etap yo`,
	it: `Identificare i passaggi successivi`,
	ja: `次のステップを特定する`,
	ko: `다음 단계 파악`,
	mww: `Taw cov kauj ruam tom ntej`,
	ps: `راتلونکی ګامونه وپېژنئ`,
	pt: `Identifique as próximas etapas`,
	'pt-pt': `Identificar as próximas etapas`,
	ru: `Определите дальнейшие действия`,
	sm: `Faailoa mai isi laasaga`,
	so: `Aqoonso tallaabooyinka xiga`,
	th: `ระบุขั้นตอนถัดไป`,
	ur: `اگلے اقدامات کی نشاندہی کریں`,
	vi: `Xác định các bước tiếp theo`,
	'zh-hans': `确定后续步骤`,
	'zh-hant': `確定後續步驟`,
	en: `Identify next steps`
};

export const loc_chatPrompt3: Record<Locale, string> = {
	ar: `البحث عن العلامات الحمراء أو المشكلات العاجلة`,
	de: `Finden Sie Warnsignale oder dringende Probleme`,
	es: `Encuentre señales de alerta o problemas urgentes`,
	fa: `پیدا کردن پرچم های قرمز یا مسائل فوری`,
	fil: `Maghanap ng mga pulang bandila o mga kagyat na isyu`,
	fr: `Trouvez des signaux d’alarme ou des problèmes urgents`,
	hi: `लाल झंडे या अत्यावश्यक समस्याएं खोजें`,
	ht: `Jwenn drafig wouj oswa pwoblèm ijan`,
	it: `Trova bandiere rosse o problemi urgenti`,
	ja: `危険信号や緊急の問題を見つける`,
	ko: `위험 신호 또는 긴급한 문제 찾기`,
	mww: `Nrhiav chij liab los yog ceev tej teeb meem`,
	ps: `سور بیرغونه یا عاجل مسلې ومومئ`,
	pt: `Encontre sinais de alerta ou problemas urgentes`,
	'pt-pt': `Encontre sinais de alerta ou problemas urgentes`,
	ru: `Находите тревожные сигналы или срочные проблемы`,
	sm: `Saili ni fu'a mumu po o ni faafitauli faanatinati`,
	so: `Raadi calaamado gaduudan ama arimo deg deg ah`,
	th: `ค้นหาธงสีแดงหรือปัญหาเร่งด่วน`,
	ur: `سرخ جھنڈے یا فوری مسائل تلاش کریں`,
	vi: `Tìm cờ đỏ hoặc các vấn đề khẩn cấp`,
	'zh-hans': `查找危险信号或紧急问题`,
	'zh-hant': `查找危險信號或緊急問題`,
	en: `Find red flags or urgent issues`
};

export const loc_chatPrompt4: Record<Locale, string> = {
	ar: `سرد التدخلات الرئيسية وآثارها`,
	de: `Liste der wichtigsten Interventionen und ihrer Auswirkungen`,
	es: `Enumerar las intervenciones clave y sus efectos`,
	fa: `مداخلات کلیدی و تاثیرات آنها را فهرست کنید`,
	fil: `Ilista ang mga pangunahing interbensyon at ang kanilang mga epekto`,
	fr: `Énumérer les principales interventions et leurs effets`,
	hi: `प्रमुख हस्तक्षेपों और उनके प्रभावों की सूची बनाएं`,
	ht: `Lis entèvansyon kle ak efè yo`,
	it: `Elencare gli interventi chiave e i loro effetti`,
	ja: `主な介入策とその効果を挙げる`,
	ko: `주요 개입 및 그 효과 나열`,
	mww: `Daim ntawv teev cov kev pab tseem ceeb thiab lawv cov teebmeem`,
	ps: `د کلیدی مداخلو لیست او د هغې اغیزې`,
	pt: `Liste as principais intervenções e seus efeitos`,
	'pt-pt': `Listar as principais intervenções e seus efeitos`,
	ru: `Перечислите ключевые вмешательства и их эффекты`,
	sm: `Lisi ia faalavefau autu ma o latou aafiaga`,
	so: `Liiska waxqabadyada muhiimka ah iyo saamaynta ay saameynta`,
	th: `แสดงรายการการแทรกแซงที่สําคัญและผลกระทบ`,
	ur: `اہم مداخلتوں اور ان کے اثرات کی فہرست`,
	vi: `Liệt kê các can thiệp chính và tác dụng của chúng`,
	'zh-hans': `列出关键干预措施及其效果`,
	'zh-hant': `列出關鍵干預措施及其效果`,
	en: `List key interventions and their effects`
};

export const loc_chatPrompt5: Record<Locale, string> = {
	ar: `تحليل استخدام الموارد`,
	de: `Analysieren der Ressourcenauslastung`,
	es: `Analice la utilización de recursos`,
	fa: `تجزیه و تحلیل استفاده از منابع`,
	fil: `Suriin ang paggamit ng mapagkukunan`,
	fr: `Analyser l’utilisation des ressources`,
	hi: `संसाधन उपयोग का विश्लेषण करें`,
	ht: `Analyze resous itilize`,
	it: `Analizzare l'utilizzo delle risorse`,
	ja: `リソース使用率の分析`,
	ko: `리소스 사용률 분석`,
	mww: `Tsom xam cov chaw muab kev pab utilization`,
	ps: `د سرچینو کارول تحلیل کړئ`,
	pt: `Analisar a utilização de recursos`,
	'pt-pt': `Analise a utilização de recursos`,
	ru: `Анализ использования ресурсов`,
	sm: `Iloilo le faaaogaina o punaoa`,
	so: `Falanqaynta isticmaalka ilaha`,
	th: `วิเคราะห์การใช้ทรัพยากร`,
	ur: `وسائل کے استعمال کا تجزیہ کریں`,
	vi: `Phân tích việc sử dụng tài nguyên`,
	'zh-hans': `分析资源利用率`,
	'zh-hant': `分析資源利用率`,
	en: `Analyze resource utilization`
};

export const loc_chatPrompt6: Record<Locale, string> = {
	ar: `البحث عن الثغرات أو المعلومات المفقودة`,
	de: `Lücken oder fehlende Informationen finden`,
	es: `Encontrar lagunas o información faltante`,
	fa: `شکاف ها یا اطلاعات گمشده را پیدا کنید`,
	fil: `Maghanap ng mga gaps o nawawalang impormasyon`,
	fr: `Trouver des lacunes ou des informations manquantes`,
	hi: `अंतराल या अनुपलब्ध जानकारी खोजें`,
	ht: `Jwenn espas oswa enfòmasyon ki manke`,
	it: `Trova lacune o informazioni mancanti`,
	ja: `ギャップや不足している情報を見つける`,
	ko: `갭 또는 누락된 정보 찾기`,
	mww: `Nrhiav gaps los yog uas ploj lawm`,
	ps: `تشې یا ورک شوی معلومات ومومئ`,
	pt: `Encontrar lacunas ou informações ausentes`,
	'pt-pt': `Encontrar lacunas ou informações em falta`,
	ru: `Найдите пробелы или недостающую информацию`,
	sm: `Saili ni avanoa po o ni faamatalaga o loo misi`,
	so: `Raadi labada meelood oo ay faraha ka maqan yihiin ama macluumaad maqan`,
	th: `ค้นหาช่องว่างหรือข้อมูลที่ขาดหายไป`,
	ur: `خلا یا گم شدہ معلومات تلاش کریں`,
	vi: `Tìm khoảng trống hoặc thiếu thông tin`,
	'zh-hans': `查找差距或缺失信息`,
	'zh-hant': `查找差距或缺失資訊`,
	en: `Find gaps or missing information`
};

export const loc_chatPrompt7: Record<Locale, string> = {
	ar: `تحقق من المشاعر / الرضا`,
	de: `Überprüfen Sie die Stimmung/Zufriedenheit`,
	es: `Comprobar el sentimiento/satisfacción`,
	fa: `احساسات/رضایت را بررسی کنید`,
	fil: `Suriin ang damdamin/kasiyahan`,
	fr: `Vérifier le sentiment/la satisfaction`,
	hi: `भावना/संतुष्टि की जाँच करें`,
	ht: `Tcheke santiman/satisfaksyon`,
	it: `Controlla il sentiment/la soddisfazione`,
	ja: `センチメント/満足度を確認する`,
	ko: `감정/만족도 확인`,
	mww: `Ntsuam xyuas sentiment/siab`,
	ps: `احساس / رضایت چیک کړئ`,
	pt: `Verifique o sentimento/satisfação`,
	'pt-pt': `Verificar sentimento/satisfação`,
	ru: `Проверьте настроения/удовлетворенность`,
	sm: `Siaki le lagona/faamalieina`,
	so: `Hubi caadifad / qanacsanaan`,
	th: `ตรวจสอบความเชื่อมั่น/ความพึงพอใจ`,
	ur: `جذبات / اطمینان کی جانچ کریں`,
	vi: `Kiểm tra tình cảm/sự hài lòng`,
	'zh-hans': `检查情绪/满意度`,
	'zh-hant': `檢查情緒/滿意度`,
	en: `Check sentiment/satisfaction`
};

export const loc_chatPrompt8: Record<Locale, string> = {
	ar: `تقييم تقدم العميل`,
	de: `Bewerten Sie den Fortschritt des Kunden`,
	es: `Evaluar el progreso del cliente`,
	fa: `پیشرفت مشتری را ارزیابی کنید`,
	fil: `Suriin ang progreso ng kliyente`,
	fr: `Évaluer les progrès du client`,
	hi: `ग्राहक की प्रगति का मूल्यांकन करें`,
	ht: `Evalye pwogrè kliyan an`,
	it: `Valutare l'andamento del cliente`,
	ja: `クライアントの進捗状況を評価する`,
	ko: `클라이언트의 진행 상황 평가`,
	mww: `Soj ntsuam tus neeg tau txais kev pab`,
	ps: `د مراجعینو د پرمختګ ارزونه`,
	pt: `Avalie o progresso do cliente`,
	'pt-pt': `Avaliar o progresso do cliente`,
	ru: `Оценивайте прогресс клиента`,
	sm: `Iloilo le alualu i luma o le tagata`,
	so: `Qiime horumarka macmiilka`,
	th: `ประเมินความคืบหน้าของลูกค้า`,
	ur: `کلائنٹ کی پیشرفت کا جائزہ لیں`,
	vi: `Đánh giá tiến độ của khách hàng`,
	'zh-hans': `评估客户的进展`,
	'zh-hant': `評估客戶的進展`,
	en: `Evaluate the client's progress`
};

export const loc_chatPrompt9: Record<Locale, string> = {
	ar: `تسليط الضوء على قصص النجاح`,
	de: `Heben Sie Erfolgsgeschichten hervor`,
	es: `Destacar casos de éxito`,
	fa: `داستان های موفقیت را برجسته کنید`,
	fil: `I-highlight ang mga kuwento ng tagumpay`,
	fr: `Mettez en évidence les réussites`,
	hi: `सफलता की कहानियों को हाइलाइट करें`,
	ht: `Mete aksan sou istwa siksè`,
	it: `Metti in evidenza le storie di successo`,
	ja: `成功事例を強調する`,
	ko: `성공 사례 하이라이트`,
	mww: `Highlight zoo dab neeg`,
	ps: `د بریالیتوب کیسې روښانه کړئ`,
	pt: `Destaque histórias de sucesso`,
	'pt-pt': `Destaque histórias de sucesso`,
	ru: `Освещайте истории успеха`,
	sm: `Faailoga tala faamanuiaina`,
	so: `Muujiya sheekooyinka guul`,
	th: `เน้นเรื่องราวความสําเร็จ`,
	ur: `کامیابی کی کہانیوں کو نمایاں کریں`,
	vi: `Làm nổi bật những câu chuyện thành công`,
	'zh-hans': `突出成功案例`,
	'zh-hant': `突出成功案例`,
	en: `Highlight success stories`
};

export const loc_whatCanIHelpWith: Record<Locale, string> = {
	ar: `ما الذي يمكنني المساعدة به؟`,
	de: `Womit kann ich helfen?`,
	es: `¿En qué puedo ayudar?`,
	fa: `در چه چیزی می توانم کمک کنم؟`,
	fil: `Ano po ba ang pwede kong itulong`,
	fr: `En quoi puis-je vous aider ?`,
	hi: `मैं क्या मदद कर सकता हूँ?`,
	ht: `Kisa mwen ka ede avèk?`,
	it: `In cosa posso aiutare?`,
	ja: `何かお手伝いできることはありますか?`,
	ko: `무엇을 도와드릴까요?`,
	mww: `Kuv yuav pab kuv li cas?`,
	ps: `زه له څه سره مرسته کولی شم؟`,
	pt: `Em que posso ajudar?`,
	'pt-pt': `Em que posso ajudar?`,
	ru: `В чем я могу помочь?`,
	sm: `O le a se mea e mafai ona ou fesoasoani i ai?`,
	so: `Maxaan ka caawin karaa?`,
	th: `ฉันจะช่วยอะไรได้บ้าง?`,
	ur: `میں کس کے ساتھ مدد کر سکتا ہوں؟`,
	vi: `Tôi có thể giúp gì?`,
	'zh-hans': `我能帮忙做什么？`,
	'zh-hant': `我能幫忙做什麼？`,
	en: `What can I help with?`
};

export const loc_askCumulusAssistant: Record<Locale, (cumulusAssistant: unknown) => string> = {
	ar: (cumulusAssistant) => `اسأل ${cumulusAssistant}`,
	de: (cumulusAssistant) => `Fragen Sie ${cumulusAssistant}`,
	es: (cumulusAssistant) => `Pregúntale ${cumulusAssistant}`,
	fa: (cumulusAssistant) => `از ${cumulusAssistant} بپرسید`,
	fil: (cumulusAssistant) => `Itanong sa ${cumulusAssistant}`,
	fr: (cumulusAssistant) => `Demandez à ${cumulusAssistant}`,
	hi: (cumulusAssistant) => `${cumulusAssistant} से पूछें`,
	ht: (cumulusAssistant) => `${cumulusAssistant}`,
	it: (cumulusAssistant) => `Chiedi ${cumulusAssistant}`,
	ja: (cumulusAssistant) => `${cumulusAssistant}に問い合わせる`,
	ko: (cumulusAssistant) => `${cumulusAssistant}에게 물어보기`,
	mww: (cumulusAssistant) => `Nug ${cumulusAssistant}`,
	ps: (cumulusAssistant) => `د ${cumulusAssistant} څخه پوښتنه وکړئ`,
	pt: (cumulusAssistant) => `Pergunte ao ${cumulusAssistant}`,
	'pt-pt': (cumulusAssistant) => `Pergunte ao ${cumulusAssistant}`,
	ru: (cumulusAssistant) => `Задать ${cumulusAssistant}`,
	sm: (cumulusAssistant) => `Fesili i le ${cumulusAssistant}`,
	so: (cumulusAssistant) => `Weydii ${cumulusAssistant}`,
	th: (cumulusAssistant) => `ถาม ${cumulusAssistant}`,
	ur: (cumulusAssistant) => `${cumulusAssistant}`,
	vi: (cumulusAssistant) => `Hỏi ${cumulusAssistant}`,
	'zh-hans': (cumulusAssistant) => `询问 ${cumulusAssistant}`,
	'zh-hant': (cumulusAssistant) => `詢問 ${cumulusAssistant}`,
	en: (cumulusAssistant) => `Ask ${cumulusAssistant}`
};

export const loc_youCanAskThingsFormat: Record<Locale, (chatPrompt1: unknown, chatPrompt2: unknown, chatPrompt3: unknown, cumulusAssistant: unknown) => TemplateResult> = {
	ar: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`يمكنك طرح أشياء مثل "${chatPrompt1}" و "${chatPrompt2}" و "${chatPrompt3}" و <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">أكثر</button>.<br>يتم تشغيل <a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> بواسطة الذكاء الاصطناعي ، لذا فإن الأخطاء ممكنة. راجع الإخراج بعناية قبل الاستخدام.`,
	de: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`Sie können Fragen stellen wie "${chatPrompt1}", "${chatPrompt2}", "${chatPrompt3}" und <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">mehr</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> wird von KI unterstützt, so dass Fehler möglich sind. Überprüfen Sie die Ausgabe vor der Verwendung sorgfältig.`,
	es: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`Puede preguntar cosas como "${chatPrompt1}", "${chatPrompt2}", "${chatPrompt3}" y <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">más</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> funciona con IA, por lo que es posible que se cometan errores. Revise la salida cuidadosamente antes de usar.`,
	fa: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`می توانید مواردی مانند «${chatPrompt1}»، «${chatPrompt2}»، «${chatPrompt3}» و <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">بیشتر</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> از هوش مصنوعی پشتیبانی می کند، بنابراین اشتباهات ممکن است. قبل از استفاده، خروجی را به دقت بررسی کنید.`,
	fil: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`Maaari kang magtanong ng mga bagay tulad ng "${chatPrompt1}", "${chatPrompt2}", "${chatPrompt3}", at <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">mas marami pa</button>.<br>Ang <a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> ay pinalakas ng AI, kaya posible ang mga pagkakamali. Repasuhin nang mabuti ang output bago gamitin.`,
	fr: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`Vous pouvez demander des choses comme « ${chatPrompt1} », « ${chatPrompt2} », « ${chatPrompt3} », et <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">plus</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> est alimenté par l’IA, il est donc possible que des erreurs soient commises. Examinez attentivement la sortie avant utilisation.`,
	hi: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`आप "${chatPrompt1}", "${chatPrompt2}", "${chatPrompt3}" जैसी चीज़ें पूछ सकते हैं, और <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">अधिक</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> AI द्वारा संचालित है, इसलिए गलतियाँ संभव हैं। पुन:view उपयोग करने से पहले आउटपुट काजीपूर्वक करें।`,
	ht: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`Ou ka mande bagay tankou "${chatPrompt1}", "${chatPrompt2}, "${chatPrompt3}", ak <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">Plis</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> patwone pa AI, se konsa erè yo posib. Revize pwodiksyon ak anpil atansyon anvan ou itilize.`,
	it: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`Puoi chiedere cose come "${chatPrompt1}", "${chatPrompt2}", "${chatPrompt3}" e <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">più</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> è alimentato dall'intelligenza artificiale, quindi gli errori sono possibili. Review l'output attentamente prima dell'uso.`,
	ja: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`「${chatPrompt1}する」、「${chatPrompt2}」、「${chatPrompt3}」などを尋ねることができます。 <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">もっとその</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> は AI を利用しているため、ミスを犯す可能性があります。よくview 使用する前に出力をよく。`,
	ko: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`"${chatPrompt1}", "${chatPrompt2}", "${chatPrompt3}"와 같은 질문을 할 수 있습니다. <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">더</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> 는 AI로 구동되므로 실수가 발생할 수 있습니다. Review 사용하기 전에 출력을 주의 깊게 확인하십시오.`,
	mww: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`Koj muaj peev xwm nug tau tej yam xws li "${chatPrompt1}", "${chatPrompt2}", "${chatPrompt3}", thiab <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">Ntau</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> yog powered los AI, ces yuam kev yog tau. Muab tso zis tso zis ua ntej yuav siv.`,
	ps: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`تاسو کولی شئ د شیانو څخه وپوښتئ لکه "${chatPrompt1}"، "${chatPrompt2}"، "${chatPrompt3}"، او <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">زیات</button>.<br>د <a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> AI لخوا ځواکمن کیږی ، نو غلطی ممکنه ده. د کارولو دمخه محصول په دقت سره وګورئ.`,
	pt: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`Você pode perguntar coisas como "${chatPrompt1}", "${chatPrompt2}", "${chatPrompt3}" e <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">mais</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> é alimentado por IA, portanto, erros são possíveis. Review a saída cuidadosamente antes de usar.`,
	'pt-pt': (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`Você pode perguntar coisas como "${chatPrompt1}", "${chatPrompt2}", "${chatPrompt3}" e <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">Saber mais</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> é alimentado por IA, por isso erros são possíveis. Revise cuidadosamente a saída antes de usar.`,
	ru: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`Вы можете задать такие вопросы, как «${chatPrompt1}», «${chatPrompt2}», «${chatPrompt3}» и <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">больше</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> работает на основе искусственного интеллекта, поэтому возможны ошибки. Внимательно просмотрите выходные данные перед использованием.`,
	sm: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`E mafai ona e fesili atu i mea e pei o le "${chatPrompt1}", "${chatPrompt2}", "${chatPrompt3}", ma <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">sili atu</button>.<br>O le <a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> faapaoaina e le AI, o lea e mafai ai ona faia ni mea sese. Iloilo ma le faaeteete ia mea e maua a o lei faaaogaina.`,
	so: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`Waxaad weydiin kartaa waxyaabaha ay ka midka yihiin "${chatPrompt1}", "${chatPrompt2}", "${chatPrompt3}", iyo <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">hore</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> waxaa ku shaqeeya AI, sidaas darteed qaladaadku waa suurtogal. Dib u eegista saarka si taxadar leh ka hor inta aan la isticmaalin.`,
	th: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`คุณสามารถถามสิ่งต่างๆ เช่น "${chatPrompt1}" "${chatPrompt2}" "${chatPrompt3}" และ <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">อีก</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> ขับเคลื่อนโดย AI ดังนั้นจึงอาจเกิดข้อผิดพลาดได้ Review ผลลัพธ์อย่างระมัดระวังก่อนใช้งาน`,
	ur: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`آپ "${chatPrompt1} کریں"، "${chatPrompt2}"، "${chatPrompt3}" جیسی چیزیں پوچھ سکتے ہیں، اور <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">زیادہ</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> اے آئی کے ذریعہ چلایا جاتا ہے ، لہذا غلطیاں ممکن ہیں۔ استعمال سے پہلے آؤٹ پٹ کا احتیاط سے جائزہ لیں۔`,
	vi: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`Bạn có thể hỏi những câu như "${chatPrompt1}", "${chatPrompt2}", "${chatPrompt3}" và <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">nhiều hơn</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> được hỗ trợ bởi AI, vì vậy sai lầm là có thể. Xem lại đầu ra cẩn thận trước khi sử dụng.`,
	'zh-hans': (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`您可以询问诸如“${chatPrompt1}”、“${chatPrompt2}”、“${chatPrompt3}”等问题，以及 <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">更多</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> 由 AI 提供支持，因此可能会出现错误。使用前请仔细查看输出。`,
	'zh-hant': (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`您可以詢問諸如「${chatPrompt1}」、「${chatPrompt2}」、「${chatPrompt3}」等問題，以及 <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">更多</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> 由 AI 提供支援，因此可能會出現錯誤。使用前請仔細查看輸出。`,
	en: (chatPrompt1, chatPrompt2, chatPrompt3, cumulusAssistant) => html`You can ask things like "${chatPrompt1}", "${chatPrompt2}", "${chatPrompt3}", and <button class="btn-link Link--secondary text-bold" type="button" data-toggle-for="assistant-chat-prompt-menu">more</button>.<br><a class="Link--secondary text-bold" href="/docs/assistant">${cumulusAssistant}</a> is powered by AI, so mistakes are possible. Review output carefully before use.`
};

export const loc_createDate: Record<Locale, string> = {
	ar: `إنشاء تاريخ`,
	de: `Erstellungsdatum`,
	es: `Fecha de creación`,
	fa: `ایجاد تاریخ`,
	fil: `Petsa ng paglikha`,
	fr: `Date de création`,
	hi: `तारीख बनाना`,
	ht: `Dat kreye`,
	it: `Crea data`,
	ja: `作成日`,
	ko: `날짜 만들기`,
	mww: `Tsim hnub`,
	ps: `تاریخ پنځول`,
	pt: `Criar data`,
	'pt-pt': `Data de criação`,
	ru: `Создать дату`,
	sm: `Fatu le aso`,
	so: `In la abuuro taariikh`,
	th: `สร้างวันที่`,
	ur: `تاریخ بنائیں`,
	vi: `Tạo ngày`,
	'zh-hans': `创建日期`,
	'zh-hant': `創建日期`,
	en: `Create date`
};

export const loc_imports: Record<Locale, string> = {
	ar: `واردات`,
	de: `Einfuhren`,
	es: `Importaciones`,
	fa: `واردات`,
	fil: `Mga import`,
	fr: `Importations`,
	hi: `आयात`,
	ht: `Enpòtasyon`,
	it: `Importazioni`,
	ja: `インポート`,
	ko: `가져옵니다`,
	mww: `Imports`,
	ps: `دننول`,
	pt: `Importações`,
	'pt-pt': `Importações`,
	ru: `Импорт`,
	sm: `Auina Mai`,
	so: `Soo-dejinta`,
	th: `การนําเข้า`,
	ur: `درآمد`,
	vi: `Nhập khẩu`,
	'zh-hans': `进口`,
	'zh-hant': `進口`,
	en: `Imports`
};

