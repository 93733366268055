import {
  Property,
  Report,
  ReportDefinition,
  identifierRegex,
  parseAndValidateReportExpression,
  parseForm,
  printExpression,
  reportColumnIndexToIdentifierName,
  tryParseAndTranslateReportExpressionColumnReferences
} from '@cumu/shared';
import { CoreExpression } from 'jsep';
import { ExpressionReturnType } from 'shared/src/expressions/common';
import { HTMLValueElement, allFormData } from '../forms/utilities';
import { addCustomValidator, setValidationMessage } from '../forms/validation';
import { dispatchChange } from './util';

export class ReportExpressionInputElement extends HTMLElement {
  private editableList: Element | null = null;
  private timeout = 0;

  connectedCallback() {
    this.addEventListener('change', this);
    this.addEventListener('blur', this);
    this.addEventListener('keydown', this);
    this.editableList = this.closest('editable-list');
    this.editableList?.addEventListener('change', this);
    this.editableList?.addEventListener('details-menu-selected', this, {
      capture: true
    });
    this.editableList?.addEventListener('editable-list-change', this);
  }

  disconnectedCallback() {
    this.removeEventListener('change', this);
    this.removeEventListener('blur', this);
    this.removeEventListener('keydown', this);
    this.editableList?.removeEventListener('change', this);
    this.editableList?.removeEventListener('details-menu-selected', this, {
      capture: true
    });
    this.editableList?.removeEventListener('editable-list-change', this);
  }

  get input() {
    return this.querySelector('input')!;
  }

  // get note() {
  //   return this.closest('.form-group')!.querySelector('.note:not(.error)')!;
  // }

  get expressionType() {
    return this.getAttribute('expression-type') as ExpressionReturnType | null;
  }
  set expressionType(value: ExpressionReturnType | null) {
    if (value === null) {
      this.removeAttribute('expression-type');
    } else {
      this.setAttribute('expression-type', value);
    }
    this.dispatchEvent(new CustomEvent('report-expression-changed'));
  }

  handleEvent(event: Event) {
    if (event.type === 'editable-list-change') {
      if (event.target !== this.editableList) {
        return;
      }
      const indexes = (
        event as CustomEvent<{ indexes: Record<number, number | null> }>
      ).detail.indexes;
      const node = tryParseAndTranslateReportExpressionColumnReferences(
        this.input.value,
        indexes
      );
      if (node) {
        clearTimeout(this.timeout);
        this.input.value = printExpression(node);
        this.processChange();
        dispatchChange(this.input);
      }
    } else if (event.target === this.input) {
      if (event instanceof KeyboardEvent) {
        if (event.key === 'Enter') {
          event.preventDefault();
        } else {
          return;
        }
      }
      if (event.isTrusted) {
        clearTimeout(this.timeout);
        this.processChange();
      }
    } else {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(this.processChange, 300);
    }
  }

  processChange = () => {
    if (this.input.value.trim() === '') {
      // this.note.innerHTML = render(expressionHelpText);
      this.expressionType = null;
      return;
    }

    const result = parseAndValidate(this.input);
    if (!result.valid) {
      setTimeout(() => {
        setValidationMessage(this.input, result.reason);
        this.closest('.form-group')?.classList.add('errored');
      });
      this.expressionType = null;
      return;
    }

    const formatted = printExpression(result.originalNode);
    if (this.input.value !== formatted) {
      this.input.value = formatted;
      dispatchChange(this.input);
    }
    this.expressionType = result.type;
  };
}

declare global {
  interface Window {
    ReportExpressionInputElement: typeof ReportExpressionInputElement;
  }
  interface HTMLElementTagNameMap {
    'report-expression-input': ReportExpressionInputElement;
  }
}

if (!window.customElements.get('report-expression-input')) {
  window.ReportExpressionInputElement = ReportExpressionInputElement;
  window.customElements.define(
    'report-expression-input',
    ReportExpressionInputElement
  );
}

function parseAndValidate(input: HTMLInputElement):
  | {
      valid: true;
      type: ExpressionReturnType;
      node: CoreExpression;
      originalNode: CoreExpression;
    }
  | { valid: false; reason: string } {
  let name: string;
  if (input.name.includes('/parameters/')) {
    const raw = input
      .closest('li')
      ?.querySelector<HTMLInputElement>('input[name$="/name"]')
      ?.value?.toUpperCase();
    if (raw && identifierRegex.test(raw)) {
      name = raw;
    } else {
      name = `$PARAMETER_${parseInt(input.name.split('/')[2])}`;
    }
  } else if (input.name.includes('/columns/')) {
    name = reportColumnIndexToIdentifierName(
      parseInt(input.name.split('/')[2])
    );
  } else {
    return {
      valid: false,
      reason: 'Unable to locate name associated with formula.'
    };
  }
  return parseAndValidateReportExpression({
    name,
    expression: input.value,
    getDefinitionInfo: () => {
      const {
        definition: { columns, parameters },
        properties
      } = parseReportDefinition(input.form!);
      return {
        columns,
        parameters,
        properties
      };
    }
  });
}

addCustomValidator((input: HTMLValueElement) => {
  if (
    input instanceof HTMLInputElement &&
    input.type === 'text' &&
    input.value.length &&
    input.parentElement instanceof ReportExpressionInputElement
  ) {
    const result = parseAndValidate(input);
    if (!result.valid) {
      return result.reason;
    }
  }
  return null;
});

const definitionsMap = new Map<HTMLFormElement, ReportDefinition>();
const propertiesMap = new Map<HTMLFormElement, Property[]>();

export function parseReportDefinition(form: HTMLFormElement): {
  definition: ReportDefinition;
  properties: Property[];
} {
  let definition = definitionsMap.get(form);
  let properties = propertiesMap.get(form);
  if (definition && properties) {
    // console.log('cached');
  } else {
    // console.log('not cached');
    definition = parseForm<Report>(allFormData(form)).definition;
    definition.parameters = (definition.parameters ?? []).filter(p => {
      p.name = p.name.toUpperCase();
      return identifierRegex.test(p.name);
    });
    properties = Array.from(
      form.querySelectorAll<HTMLInputElement>(
        '[data-property][name^="definition/columns/"]'
      )
    ).map(el => JSON.parse(el.dataset.property!));
    definitionsMap.set(form, definition);
    propertiesMap.set(form, properties);
    Promise.resolve().then(() => {
      definitionsMap.delete(form);
      propertiesMap.delete(form);
    });
  }
  return { definition, properties };
}
