import { icon, render, sortAscIcon, sortDescIcon } from '@cumu/shared';
import { target } from '@github/catalyst';
import type RemoteInputElement from '@github/remote-input-element';
import { controller } from './controller';
import { focusSomething } from './util';

@controller('timeline-controller')
export default class TimelineControllerElement extends HTMLElement {
  @target declare searchInput: HTMLInputElement;
  @target declare assistant: HTMLElement;

  changeMode(event: Event) {
    const button = event.currentTarget as HTMLButtonElement;
    this.querySelectorAll('[data-timeline-mode][aria-selected]').forEach(el => {
      el.setAttribute('aria-selected', String(el === button));
    });
    this.reload();
  }

  changeSort(event: Event) {
    const button = event.currentTarget as HTMLButtonElement;
    const sort = button.dataset.timelineSort!;
    button.setAttribute(
      'aria-label',
      sort === 'asc' ? 'Sort ascending' : 'Sort descending'
    );
    button.dataset.timelineSort = sort === 'asc' ? 'desc' : 'asc';
    button.setAttribute('cookie-value', sort === 'asc' ? '' : 'asc');
    button.innerHTML = render(
      icon(sort === 'asc' ? sortDescIcon : sortAscIcon)
    );
    this.reload();
  }

  toggleAssistant() {
    this.assistant.hidden = !this.assistant.hidden;
    if (!this.assistant.hidden) {
      focusSomething(this.assistant);
    }
  }

  reload() {
    this.searchInput.value = '';
    const remote = this.searchInput.closest(
      'remote-input'
    ) as RemoteInputElement;
    // Setting src, even to the same value, will cause the remote input to reload because it will invoke
    // fetchResults with checkCurrentQuery=false.
    // https://github.com/github/remote-input-element/blob/dcb027c993790dbfd4ac50e0c65c29a0c73f5780/src/index.ts#L20
    remote.src = remote.src;
  }
}

declare global {
  interface Window {
    TimelineControllerElement: typeof TimelineControllerElement;
  }
  interface HTMLElementTagNameMap {
    'timeline-controller': TimelineControllerElement;
  }
}
