import { HTMLValueElement } from '../forms/utilities';
import { addCustomValidator } from '../forms/validation';
import { dispatchChange } from './util';

class UppercaseTextInput extends HTMLElement {
  connectedCallback() {
    this.addEventListener('change', this);
  }

  disconnectedCallback() {
    this.removeEventListener('change', this);
  }

  handleEvent(event: Event) {
    if (
      event.target instanceof HTMLInputElement &&
      event.target.type === 'text' &&
      event.target.value !== event.target.value.toUpperCase()
    ) {
      event.preventDefault();
      event.stopImmediatePropagation();
      event.target.value = event.target.value.toUpperCase();
      dispatchChange(event.target);
    }
  }
}

declare global {
  interface Window {
    UppercaseTextInput: typeof UppercaseTextInput;
  }
  interface HTMLElementTagNameMap {
    'uppercase-text-input': UppercaseTextInput;
  }
}

addCustomValidator((input: HTMLValueElement, label: string) => {
  if (
    input instanceof HTMLInputElement &&
    input.type === 'text' &&
    input.value.length &&
    input.parentElement instanceof UppercaseTextInput &&
    input.value !== input.value.toUpperCase()
  ) {
    return `${label} must be all uppercase letters.`;
  }
  return null;
});

if (!window.customElements.get('uppercase-text-input')) {
  window.UppercaseTextInput = UppercaseTextInput;
  window.customElements.define('uppercase-text-input', UppercaseTextInput);
}
