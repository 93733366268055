export enum ValidationErrorCode {
  EmailUndeliverable = 'email-undeliverable',
  ReCaptchaFailed = 'recaptcha-failed',
  PasswordTooWeak = 'password-too-weak',
  PasswordTooCommon = 'password-too-common',
  LoginUnavailable = 'login-unavailable',
  EmailAlreadyRegistered = 'email-already-registered',
  EmailAlreadyVerified = 'email-already-verified',
  EmailNotRegistered = 'email-not-registered',
  UserAlreadyOrganizationMember = 'user-already-organization-member',
  CannotInviteYourself = 'cannot-invite-yourself',
  UserAlreadyInvited = 'user-already-invited',
  InvitationEmailSentTooRecently = 'invitation-email-sent-too-recently',
  DuplicateName = 'duplicate-name',
  ReferencesPreventDeletion = 'references-prevent-deletion',
  OrganizationAlreadyInvited = 'organization-already-invited',
  OrganizationAlreadyProjectMember = 'organization-already-project-member'
}

export const messagesByCode: Record<ValidationErrorCode, string> = {
  [ValidationErrorCode.EmailUndeliverable]:
    'Email address is undeliverable. Contact support for assistance if you believe this to be an error.',
  [ValidationErrorCode.PasswordTooWeak]: 'Password is too weak.',
  [ValidationErrorCode.PasswordTooCommon]: 'Password is too common.',
  [ValidationErrorCode.ReCaptchaFailed]:
    'Activity flagged by bot detection. Contact support for assistance if you believe this to be an error.',
  [ValidationErrorCode.LoginUnavailable]:
    '"{value}" is unavailable. {label} must be unique.',
  [ValidationErrorCode.EmailAlreadyRegistered]: 'Email already registered.',
  [ValidationErrorCode.EmailAlreadyVerified]:
    'Email already verified. Go ahead and sign-in.',
  [ValidationErrorCode.EmailNotRegistered]:
    'No account matching specified email.',
  [ValidationErrorCode.UserAlreadyOrganizationMember]:
    'User is already a member of the organization.',
  [ValidationErrorCode.CannotInviteYourself]: 'You cannot invite yourself.',
  [ValidationErrorCode.UserAlreadyInvited]: 'User already invited.',
  [ValidationErrorCode.InvitationEmailSentTooRecently]:
    'Invitation emails cannot be sent more than once per hour.',
  [ValidationErrorCode.DuplicateName]: '{label} must be unique.',
  [ValidationErrorCode.ReferencesPreventDeletion]:
    'Unable to delete. Record is in use.',
  [ValidationErrorCode.OrganizationAlreadyInvited]:
    '"{value}" has already been invited to the project.',
  [ValidationErrorCode.OrganizationAlreadyProjectMember]:
    'The organization is already a member of the project.'
};

export const validationErrorBodyType = 'validation-error';

export interface ValidationError {
  /** Error code. */
  code: ValidationErrorCode;
  /** User-facing message */
  message: string;
  /** Property associated with the error */
  property?: string;
}

export interface ValidationErrorBody extends ValidationError {
  /** Body type discriminator. */
  type: typeof validationErrorBodyType;
  /** Detailed contextual information. */
  details?: object;
}

export function isValidationErrorBody(
  obj: unknown
): obj is ValidationErrorBody {
  return (
    !!obj &&
    typeof obj === 'object' &&
    'type' in obj &&
    obj.type === validationErrorBodyType &&
    'message' in obj &&
    typeof obj.message === 'string'
  );
}
