export const numberFormat = new Intl.NumberFormat('en-us', {});

export const currencyFormat = new Intl.NumberFormat('en-us', {
  style: 'currency',
  currency: 'USD'
});

export function roundNumber(value: number, precision: number) {
  const factor = Math.pow(10, precision);
  const tempNumber = value * factor;
  const roundedTempNumber = Math.round(tempNumber);
  return roundedTempNumber / factor;
}
