import {
  attachmentTypesByExtension,
  DependencyError,
  getFileExtension,
  validateAttachmentFilename,
  validateAttachmentFilesize
} from '@cumu/shared';
import { loc_uploadFileErrorFormat, Locale } from '@cumu/strings';
import { Attachment } from '@github/file-attachment-element';
import { getLocale } from './locale';
import { isSessionExpiredResponse } from './session';

addEventListener('file-attachment-accept', event => {
  const locale = getLocale();
  for (const attachment of event.detail.attachments) {
    const result = validateAttachmentFilename(locale, attachment.file.name);
    if (!result.valid) {
      event.preventDefault();
      alert(result.reason);
      return;
    }
    // https://developer.mozilla.org/en-US/docs/Web/API/Blob/type
    // Note: Based on the current implementation, browsers won't actually read
    // the bytestream of a file to determine its media type. It is assumed based
    // on the file extension; a PNG image file renamed to .txt would give
    // "text/plain" and not "image/png". Moreover, blob.type is generally reliable
    // only for common file types like images, HTML documents, audio and video.
    // Uncommon file extensions would return an empty string. Client configuration
    // (for instance, the Windows Registry) may result in unexpected values even
    // for common types. Developers are advised not to rely on this property as a
    // sole validation scheme.
    // if (result.type.contentType !== attachment.file.type) {
    //   event.preventDefault();
    //   alert(
    //     `Invalid file content type ${JSON.stringify(
    //       attachment.file.type
    //     )}. Expected: "${result.type.contentType}".`
    //   );
    //   return;
    // }
    const sizeResult = validateAttachmentFilesize(
      locale,
      attachment.file.name,
      result.type,
      attachment.file.size
    );
    if (!sizeResult.valid) {
      event.preventDefault();
      alert(sizeResult.reason);
      return;
    }
    attachment.name = result.filename;
  }
});

export async function uploadAttachments({
  locale,
  attachments,
  uploadUrl,
  attachmentSaved,
  jwt
}: {
  locale: Locale;
  attachments: Attachment[];
  uploadUrl: URL;
  attachmentSaved: (
    attachment: Attachment,
    download_href: string | null
  ) => void;
  jwt?: string;
}) {
  for (const attachment of attachments) {
    attachment.saving(0);
    uploadUrl.searchParams.set('filename', attachment.name!);
    const headers = new Headers();
    const uploadRequestInit: RequestInit = {
      method: 'POST',
      body: attachment.file,
      headers
    };
    if (jwt) {
      headers.set('Authorization', `Bearer ${jwt}`);
    }
    // ensure content-type header is provided in scenarios where browser doesn't compute it automatically.
    if (attachment.file.type === '') {
      const extension = getFileExtension(attachment.file.name);
      const contentType = attachmentTypesByExtension[extension].contentType;
      headers.set('content-type', contentType);
    }
    let response = await fetch(uploadUrl, uploadRequestInit);
    if (response.status >= 500 && response.status < 600) {
      response.text().then(text => {
        throw new DependencyError(response, text);
      });
      await new Promise(resolve => setTimeout(resolve, 3000));
      uploadUrl.searchParams.set('retry', '1');
      response = await fetch(uploadUrl, uploadRequestInit);
    }
    if (isSessionExpiredResponse(response)) {
      throw new DependencyError(response, await response.text());
    }
    if (!response.ok) {
      alert(loc_uploadFileErrorFormat[locale](attachment.name!));
      throw new DependencyError(response, await response.text());
    }
    const { id, href, download_href, name } = await response.json();
    attachment.saved({ id, href, name });
    attachmentSaved(attachment, download_href);
  }
}

/*
async function uploadAttachment(attachment: Attachment, href: string) {
  const xhr = await new Promise<XMLHttpRequest>((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    // xhr.withCredentials = true;
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.open('POST', href, true);
    xhr.onloadstart = () => attachment.saving(0);
    xhr.upload.onprogress = e => {
      if (e.lengthComputable) {
        attachment.saving(Math.round((e.loaded / e.total) * 100));
      }
    };
    xhr.onload = () => resolve(xhr);
    xhr.onerror = reject;
    xhr.send(attachment.file);
  });
  if (xhr.status === 201) {
    const { id, href, name } = JSON.parse(xhr.responseText);
    attachment.saved({ id, href, name });
  } else {
    throw new XhrDependencyError(xhr);
  }
}

class XhrDependencyError extends DependencyError {
  constructor(xhr: XMLHttpRequest) {
    super(
      {
        url: xhr.responseURL,
        status: xhr.status,
        statusText: xhr.statusText,
        headers: XhrDependencyError.parseXhrHeaders(xhr)
      },
      xhr.responseText
    );
    this.name = this.constructor.name;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    }
  }

  static parseXhrHeaders(xhr: XMLHttpRequest) {
    const headers = new Headers();
    // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest/getAllResponseHeaders#examples
    const lines = xhr
      .getAllResponseHeaders()
      .trim()
      .split(/[\r\n]+/);
    for (const line of lines) {
      const [name, ...value] = line.split(': ');
      headers.append(name, value.join(': '));
    }
    return headers;
  }
}
*/
