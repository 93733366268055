import {
  Locale,
  loc_assignedToLogin,
  loc_commentCountPlural,
  loc_commentCountSingular,
  loc_create,
  loc_createdByLogin,
  loc_dueOnDate,
  loc_edit,
  loc_fields,
  loc_multipleAttachments,
  loc_searchEllipses,
  loc_setStatus,
  loc_singularAttachment,
  loc_unassigned,
  loc_unspecified,
  loc_view
} from '@cumu/strings';
import { addDays, userDateFormat } from '../date';
import { progressRingIconContent } from '../donut';
import { icon, workflowIcon } from '../icons';
import {
  Activity,
  ActivityDefinitionFlags,
  ActivityDefinitionLookupItem,
  ActivityListItem,
  ActivityPropertyValue,
  ParentActivitySummary,
  activityStatusColors,
  activityStatusIcons,
  activityStatusStateClass,
  activityStatusTitles,
  activityTypeDisplayName,
  activityTypeHasTitle,
  getNewActivityHref,
  isDueDateActivityType
} from '../model/activity';
import { assigneeUserOrganization } from '../model/organization';
import { uuidToBase58 } from '../new-id';
import { TemplateResult, attr, booleanAttr, html } from '../template';
import { userLinkTemplate } from './user';

export function activityStatusIcon(status: string, classes = '') {
  return icon(
    activityStatusIcons[status],
    `color-fg-${activityStatusColors[status]} ${classes}`
  );
}

export function activityStatusButtonContents(locale: Locale, status: string) {
  return html`${activityStatusIcon(status)}
    <span
      >${loc_setStatus[locale](activityStatusTitles[status][locale])}</span
    >`;
}

export function pageActivityStatusLabelTemplate(
  status: string,
  locale: Locale
) {
  return html`<a
    class="State State--small ${activityStatusStateClass[
      status
    ]} d-flex flex-items-center gap-1"
    href="#activity-status-field"
    id="activity-status-label"
    >${icon(activityStatusIcons[status], 'f5')}
    ${activityStatusTitles[status][locale]}</a
  >`;
}

export function activityLabelsTemplate({
  locale,
  organization_login,
  status,
  visibleProperties,
  answeredProperties,
  parentActivity
}: {
  locale: Locale;
  organization_login: string;
  status: string;
  visibleProperties: number;
  answeredProperties: number;
  parentActivity: ParentActivitySummary | null;
}) {
  const labelClass = 'Label Label--large d-flex flex-items-center gap-1';

  const labels = [pageActivityStatusLabelTemplate(status, locale)];

  if (visibleProperties > 0) {
    labels.push(
      html`<span
        class="${labelClass} text-lowercase"
        id="activity-progress-label"
        >${icon(
          progressRingIconContent(answeredProperties / visibleProperties),
          'f5 color-fg-accent'
        )}
        ${answeredProperties} of ${visibleProperties}
        ${loc_fields[locale]}</span
      >`
    );
  }
  if (parentActivity) {
    labels.push(
      html`<a
        class="${labelClass} Link--primary"
        id="activity-parent-label"
        href="${activityHref(parentActivity, organization_login)}"
        >${icon(workflowIcon, 'color-fg-accent')}<span
          class="Truncate"
          style="max-width: 12rem"
          ><span class="Truncate-text"
            >${parentActivity.title || parentActivity.definition_title}</span
          ></span
        ></a
      >`
    );
  }
  return labels;
}

export function activityListItemTemplate(
  locale: Locale,
  organization_login: string,
  today: Date,
  activity: ActivityListItem,
  isReadOnly: boolean,
  modalButton = true,
  displayPerson = false,
  compact = true,
  href: string | undefined = undefined
) {
  const button = modalButton
    ? editActivityModal(
        locale,
        organization_login,
        activity.subject_person_id!,
        activity.activity_id,
        isReadOnly
      )
    : '';
  return html`
    <li class="Box-row d-flex gap-2 flex-items-center">
      ${activityListItemBodyTemplate(
        locale,
        organization_login,
        today,
        activity,
        compact,
        displayPerson,
        undefined,
        href
      )}
      ${button}
    </li>
  `;
}

export function activityListItemBodyTemplate(
  locale: Locale,
  organization_login: string,
  today: Date,
  activity: ActivityListItem,
  compact: boolean,
  displayPerson = false, // needs redesign for activity list https://github.com/cumulus-care/cumulus/issues/605
  propertyValues: ActivityPropertyValue[] | undefined = undefined,
  href = activityHref(activity, organization_login)
) {
  const readyInProgressOnHold = ['ready', 'in-progress', 'on-hold'].includes(
    activity.status
  );
  const statusTitle = html`<span class="text-bold color-fg-default"
    >${activityStatusTitles[activity.status][locale]}</span
  >`;

  const title = activity.title.length
    ? `${activity.title} - ${activity.definition_title}`
    : activity.definition_title;

  let dateInfo: TemplateResult | string;
  if (
    isDueDateActivityType(activity.type) &&
    (activity.flags & ActivityDefinitionFlags.HasDueDate) > 0 &&
    readyInProgressOnHold
  ) {
    const due_date = new Date(activity.due_date);
    let dueDateStatusColor = 'color-fg-muted';
    if (readyInProgressOnHold && due_date <= today) {
      dueDateStatusColor = 'color-fg-severe';
    } else if (readyInProgressOnHold && due_date <= addDays(today, 2)) {
      dueDateStatusColor = 'color-fg-attention';
    }
    dateInfo = html`<span class="${dueDateStatusColor}"
      >${loc_dueOnDate[locale](
        userDateFormat.format(new Date(activity.due_date))
      )}</span
    >`;
  } else {
    dateInfo =
      activity.start_date === activity.end_date
        ? userDateFormat.format(new Date(activity.start_date))
        : html`${userDateFormat.format(new Date(activity.start_date))} -
          ${activity.end_date
            ? userDateFormat.format(new Date(activity.end_date))
            : loc_unspecified[locale]}`;
  }

  let assignee: TemplateResult;
  if (activity.assignee_user_id) {
    assignee = loc_assignedToLogin[locale](
      compact
        ? html`<a
            href="/users/${activity.assignee_user_login}"
            class="Link--secondary text-bold"
            >${activity.assignee_user_login}
            (${activity.assignee_organization_login})</a
          >`
        : userLinkTemplate(assigneeUserOrganization(activity), {
            organization: true,
            project_id: activity.project_id,
            popoverDirection: 'left'
          })
    );
  } else if (activity.assignee_organization_id) {
    assignee = loc_assignedToLogin[locale](
      html`<span class="text-bold"
        >${activity.assignee_organization_login}</span
      >`
    );
  } else {
    assignee = loc_createdByLogin[locale](
      html`<a
        href="/users/${activity.create_user_login}"
        class="Link--secondary text-bold"
      >
        ${activity.create_user_login} (${activity.create_organization_login})
      </a>`
    );
  }
  const unassigned =
    activity.assignee_organization_id === null && readyInProgressOnHold
      ? html`<span class="Label Label--danger">${loc_unassigned[locale]}</span>`
      : null;

  const person =
    displayPerson && activity.subject_person_id
      ? html`<span class="${compact ? 'f6' : ''}"
          ><a
            class="Link--secondary text-bold"
            href="/${organization_login}/people/${uuidToBase58(
              activity.subject_person_id
            )}"
            >${activity.subject_person_name}</a
          >
          ${activity.subject_person_primary_physical
            ?.replace(/^(, )+/g, '')
            .replaceAll(', , ', ', ')
            .replaceAll('  ', ' ')
            .trim()}</span
        >`
      : null;
  const address =
    activityTypeHasTitle(activity.type) && activity.primary_physical
      ? html`<span class="${compact ? 'f6' : ''}"
          >${activity.primary_physical
            .replace(/^(, )+/g, '')
            .replaceAll(', , ', ', ')
            .replaceAll('  ', ' ')
            .trim()}</span
        >`
      : null;
  return html`
    ${activityStatusIcon(
      activity.status,
      `f4 align-self-start${compact ? '' : ' mt-1'}`
    )}
    <span class="flex-1 d-flex flex-column gap-1 color-fg-muted">
      <span class="${compact ? 'h5' : 'h4'}">
        <a class="Link--primary" href="${href}"
          >${title} | ${activity.project_login}</a
        >
        <span class="Label color-fg-default"
          >${activityTypeDisplayName(locale, activity.type)}</span
        >
        ${unassigned}
      </span>
      ${person}${address}
      <span class="${compact ? 'f6' : ''}">
        ${statusTitle} · ${dateInfo} · ${assignee}
      </span>
      ${!compact && (activity.comment_count || activity.attachment_count)
        ? html`<span class="f6">
            ${activity.comment_count
              ? html`${activity.comment_count === 1
                  ? loc_commentCountSingular[locale]
                  : loc_commentCountPlural[locale](activity.comment_count)}`
              : null}
            ${activity.comment_count && activity.attachment_count ? '-' : null}
            ${activity.attachment_count
              ? html`${activity.attachment_count === 1
                  ? loc_singularAttachment[locale]
                  : loc_multipleAttachments[locale](activity.attachment_count)}`
              : null}
          </span>`
        : null}
      ${propertyValues
        ? activityListItemBodyPreviewPropertiesTemplate(propertyValues)
        : null}
    </span>
  `;
}

function activityListItemBodyPreviewPropertiesTemplate(
  propertyValues: ActivityPropertyValue[]
) {
  return propertyValues.length
    ? html`<table class="PropertyTable color-fg-default">
        <tbody>
          ${propertyValues.map(
            x =>
              html`<tr
                class="DefinitionList-row ${x.value === 'Unanswered'
                  ? 'color-fg-muted'
                  : ''}"
              >
                <th scope="row" class="DefinitionList-term">${x.label}</th>
                <td class="DefinitionList-definition">${x.value}</td>
              </tr>`
          )}
        </tbody>
      </table>`
    : null;
}

export function activityHref(
  activity: Pick<Activity, 'activity_id' | 'subject_person_id'>,
  organization_login: string
) {
  return activity.subject_person_id
    ? `/${organization_login}/people/${uuidToBase58(
        activity.subject_person_id
      )}/activities/${uuidToBase58(activity.activity_id)}`
    : `/${organization_login}/activities/${uuidToBase58(activity.activity_id)}`;
}

export function createActivityModal({
  locale,
  organization_login,
  person_id,
  associated_activity_id,
  activityDefinition,
  recommendation = false,
  elementIndex,
  requiresSubmit
}: {
  locale: Locale;
  organization_login: string;
  person_id?: string | null;
  associated_activity_id?: string;
  activityDefinition: ActivityDefinitionLookupItem;
  recommendation?: boolean;
  elementIndex: number;
  requiresSubmit: boolean;
}) {
  const src = getNewActivityHref({
    organization_login,
    activityDefinition,
    person_id,
    associated_activity_id,
    recommendation,
    params: new URLSearchParams({
      modal: 'true',
      'element-index': elementIndex.toString()
    })
  });
  return html`<modal-controller
    src="${src}"
    ${attr(
      'data-targets',
      associated_activity_id === '__ACTIVITY_ID__'
        ? 'activity-controller.needsActivityId'
        : null
    )}
  >
    <button
      class="btn btn-outline"
      type="button"
      ${booleanAttr('data-requires-submit', requiresSubmit)}
    >
      <span>${loc_create[locale]}</span>
    </button>
    <template slot="template"><include-fragment></include-fragment></template>
  </modal-controller>`;
}

export function editActivityModal(
  locale: Locale,
  organization_login: string,
  person_id: string,
  activity_id: string,
  isReadOnly: boolean,
  classes = ''
) {
  return html`<modal-controller
    src="/${organization_login}/people/${uuidToBase58(
      person_id
    )}/activities/${uuidToBase58(activity_id)}?modal=true"
  >
    <button class="btn btn-sm ${classes}" type="button">
      ${isReadOnly ? loc_view[locale] : loc_edit[locale]}
    </button>
    <template slot="template">
      <include-fragment></include-fragment>
    </template>
  </modal-controller>`;
}

export function activitySelectMenuButtonContent(
  locale: Locale,
  name: string,
  required: boolean,
  label: string,
  value?: Pick<
    Activity,
    'activity_id' | 'title' | 'status' | 'start_date' | 'end_date'
  > | null
) {
  return html`<span
      ${attr('class', value ? null : 'text-italic color-fg-muted')}
      >${value?.title ?? loc_searchEllipses[locale]}</span
    ><input
      id="${name}"
      class="sr-only"
      type="text"
      name="${name}"
      ${booleanAttr('required', required)}
      aria-label="${label}"
      value="${value?.activity_id}"
      activity_start_date="${value?.start_date}"
      activity_end_date="${value?.end_date}"
      data-target="activity-select.input"
      data-action="focus:activity-select#inputFocused"
    />`;
}
