import { ValidationErrorBody } from '@cumu/shared';
import { target } from '@github/catalyst';
import { Attachment } from '@github/file-attachment-element';
import {
  clearValidationErrors,
  displayServerValidationError
} from '../forms/validation';
import { controller } from './controller';
import { dispatchChange } from './util';

@controller('excel-attachment')
class ExcelAttachmentElement extends HTMLElement {
  @target declare fileInput: HTMLInputElement;
  @target declare valueInput: HTMLInputElement;
  @target declare filenameInput: HTMLInputElement;

  async attachmentAccepted(event: CustomEvent<{ attachments: Attachment[] }>) {
    const attachments = event.detail.attachments;
    clearValidationErrors(this.valueInput.form!);
    try {
      this.fileInput.disabled = true;
      this.fileInput.labels?.[0].classList.add('btn-busy');
      const file = attachments[0].file;
      const worker = new Worker(
        new URL('../excel-worker.ts', import.meta.url),
        {
          type: 'module'
        }
      );
      await new Promise<MessageEvent>(resolve =>
        worker.addEventListener('message', resolve, { once: true })
      );
      worker.postMessage(await file.arrayBuffer());
      const event = await new Promise<
        MessageEvent<
          { type: 'xlsx-error' } | { type: 'xlsx-data'; data: unknown[] }
        >
      >(resolve => worker.addEventListener('message', resolve, { once: true }));
      worker.terminate();
      if (event.data.type === 'xlsx-error') {
        const error: Omit<ValidationErrorBody, 'code'> = {
          type: 'validation-error',
          message: `Error opening "${file.name}". Ensure the file is a valid Excel file.`
        };
        displayServerValidationError(this.valueInput.form!, error);
        return;
      }
      this.valueInput.value = JSON.stringify(event.data.data);
      this.filenameInput.value = file.name;
      await this.querySelector('form-behavior')?.submit();
    } finally {
      this.fileInput.disabled = false;
      this.fileInput.labels?.[0].classList.remove('btn-busy');
      this.fileInput.focus();
      dispatchChange(this.valueInput);
    }
  }

  valueInputFocused() {
    this.fileInput.focus();
  }
}

declare global {
  interface Window {
    ExcelAttachmentElement: typeof ExcelAttachmentElement;
  }

  interface HTMLElementTagNameMap {
    'excel-attachment': ExcelAttachmentElement;
  }
}
