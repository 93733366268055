import { target } from '@github/catalyst';
import { controller } from './controller';

export const SIDEBAR_COLLAPSED_EVENT_TYPE = 'sidebar-collapsed';

const collapsedClass = 'Sidebar--collapsed';

@controller('side-bar')
export class SideBarElement extends HTMLElement {
  @target declare searchInput: HTMLInputElement;
  #prefersCollapsed = false;

  get prefersCollapsed() {
    return this.#prefersCollapsed;
  }
  set prefersCollapsed(value) {
    this.#prefersCollapsed = value;
    document.cookie = `sidebar-collapsed=${value};path=/;${
      location.protocol === 'https:' ? 'secure;' : ''
    }samesite=lax;max-age=31536000`;
  }

  connectedCallback() {
    this.#prefersCollapsed = this.classList.contains(collapsedClass);
  }

  disconnectedCallback() {
    this.toggleWindowSubscriptions(false);
  }

  toggleWindowSubscriptions(value: boolean) {
    const method = value ? 'addEventListener' : 'removeEventListener';
    window[method]('focus', this.restoreCollapsedState, true);
    window[method]('click', this.restoreCollapsedState);
  }

  toggleExpanded() {
    this.prefersCollapsed = this.toggleCollapsedClass();
  }

  toggleCollapsedClass(add = !this.classList.contains(collapsedClass)) {
    const current = this.classList.contains(collapsedClass);
    if (add !== current) {
      this.classList.toggle(collapsedClass, add);
      this.dispatchEvent(
        new CustomEvent(SIDEBAR_COLLAPSED_EVENT_TYPE, {
          detail: add,
          bubbles: true
        })
      );
    }
    return add;
  }

  activateSearch() {
    this.toggleCollapsedClass(false);
    this.searchInput.focus();
    this.toggleWindowSubscriptions(true);
  }

  restoreCollapsedState = ({ target }: Event) => {
    if (target instanceof Node && !this.contains(target)) {
      this.toggleCollapsedClass(this.prefersCollapsed);
      this.toggleWindowSubscriptions(false);
    }
  };
}

declare global {
  interface Window {
    SideBarElement: typeof SideBarElement;
  }
  interface HTMLElementTagNameMap {
    'side-bar': SideBarElement;
  }

  interface ElementEventMap {
    [SIDEBAR_COLLAPSED_EVENT_TYPE]: CustomEvent<boolean>;
  }

  interface WindowEventMap {
    [SIDEBAR_COLLAPSED_EVENT_TYPE]: CustomEvent<boolean>;
  }
}
