import {
  Locale,
  loc_deleteSignature,
  loc_noDefaultSignature,
  loc_noDefaultSignatureDescription,
  loc_resetDefaultSignature,
  loc_signWithDefaultSignature
} from '@cumu/strings';
import { icon, signatureIcon, trashIcon } from '../icons';
import { html } from '../template';

export function signatureItemTemplate({
  locale,
  src,
  attachment_id,
  inserted_property_id,
  readonly = false
}: {
  locale: Locale;
  src: string;
  attachment_id: string;
  inserted_property_id?: number;
  readonly?: boolean;
}) {
  return html`<span
    class="d-flex flex-items-start gap-2"
    attachment-id="${attachment_id}"
  >
    <img src="${src}" alt="Signature" style="height: 40px" />
    ${inserted_property_id
      ? html`<input
          type="hidden"
          name="attachments/-:json"
          value=${JSON.stringify({
            attachment_id,
            property_id: inserted_property_id
          })}
        />`
      : null}
    ${readonly
      ? null
      : html`<button
          type="button"
          class="btn-octicon btn-octicon-danger mt-n1 mb-n1 ml-n1 mr-n1"
          aria-label="${loc_deleteSignature[locale]}"
          data-action="click:signature-question#deleteSignature"
        >
          ${icon(trashIcon)}
        </button>`}
  </span>`;
}

export function signWithDefaultSignatureTemplate(
  locale: Locale,
  default_signature_href: string | null
) {
  return default_signature_href
    ? html`<button
          class="SelectMenu-item d-block"
          type="button"
          role="menuitem"
          data-action="click:signature-question#signWithDefaultSignature"
        >
          <span class="text-bold d-block"
            >${icon(signatureIcon, 'SelectMenu-icon color-fg-accent')}
            <span class="f5 text-bold"
              >${loc_signWithDefaultSignature[locale]}</span
            ></span
          >
          <img
            class="signature-image d-block width-full my-3 px-3"
            src="${default_signature_href}"
            alt
          />
        </button>
        <button
          class="SelectMenu-item d-block btn-octicon-danger"
          type="button"
          role="menuitem"
          data-action="click:signature-question#resetDefaultSignature"
        >
          ${icon(trashIcon, 'SelectMenu-icon')}
          <span>${loc_resetDefaultSignature[locale]}</span>
        </button>`
    : html`<div class="SelectMenu-blankslate">
        ${icon(signatureIcon, { classes: 'color-fg-muted f2' })}
        <h4 class="my-2">${loc_noDefaultSignature[locale]}</h4>
        <p class="mb-3 color-fg-muted">
          ${loc_noDefaultSignatureDescription[locale]}
        </p>
      </div>`;
}
