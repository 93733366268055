import { parseForm } from '@cumu/shared';
import { target } from '@github/catalyst';
import { TabContainerChangeEvent } from '@github/tab-container-element';
import SignaturePad from 'signature_pad';
import { displayServerValidationError } from '../forms/validation';
import { controller } from './controller';
import { dispatchChange } from './util';

export interface SignatureAcceptedEventDetail {
  blob: Blob;
  set_default_signature: boolean;
}

@controller('signature-pad')
class SignaturePadElement extends HTMLElement {
  declare signaturePad: SignaturePad;
  @target declare canvas: HTMLCanvasElement;
  @target declare typeSignatureInput: HTMLInputElement;
  @target declare drawSignatureInput: HTMLInputElement;
  @target declare submitButton: HTMLButtonElement;
  @target declare undoButton: HTMLButtonElement;

  connectedCallback() {
    this.signaturePad = new SignaturePad(this.canvas);
    this.signaturePad.addEventListener('endStroke', () => this.endStroke());
    addEventListener('resize', this.resizeCanvas);
    this.resizeCanvas();
  }

  disconnectedCallback() {
    this.signaturePad.removeEventListener('endStroke', () => this.endStroke());
    removeEventListener('resize', this.resizeCanvas);
  }

  setRequired(active: 'type-signature' | 'draw-signature') {
    this.typeSignatureInput.required = active === 'type-signature';
    this.drawSignatureInput.required = active === 'draw-signature';
    // clear validation
    dispatchChange(this.typeSignatureInput);
    dispatchChange(this.drawSignatureInput);
  }

  tabContainerChanged(event: TabContainerChangeEvent) {
    const typeSignatureTabActive = event.tab?.id === 'tab-type-signature';
    this.setRequired(
      typeSignatureTabActive ? 'type-signature' : 'draw-signature'
    );

    this.resizeCanvas();
  }

  updateDrawSignatureInputValue() {
    const value = this.signaturePad.isEmpty()
      ? ''
      : this.signaturePad.toDataURL('image/png');
    if (this.drawSignatureInput.value !== value) {
      this.drawSignatureInput.value = value;
      dispatchChange(this.drawSignatureInput);
    }
  }

  endStroke() {
    this.updateDrawSignatureInputValue();
  }

  undo() {
    const data = this.signaturePad.toData();
    if (data) {
      data.pop();
      this.signaturePad.fromData(data);
      this.updateDrawSignatureInputValue();
    }
  }

  resizeCanvas = () => {
    const canvas: HTMLCanvasElement & { ratio?: number } = this.canvas;
    if (canvas.closest('[hidden]')) {
      return;
    }
    const { width: oldWidth, height: oldHeight, ratio: oldRatio } = canvas;
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    const width = canvas.offsetWidth * ratio;
    const height = canvas.offsetHeight * ratio;
    if (
      canvas.width !== oldWidth ||
      canvas.height !== oldHeight ||
      ratio !== oldRatio
    ) {
      canvas.width = width;
      canvas.height = height;
      canvas.ratio = ratio;
      canvas.getContext('2d')?.scale(ratio, ratio);
      this.signaturePad.clear();
    }
  };

  handleTypeSignatureInput() {
    const value = this.typeSignatureInput.value;
    if (value.length === 0) {
      return;
    }
    const firstLetter = value.charAt(0);
    if (firstLetter === firstLetter.toUpperCase()) {
      return;
    }
    const start = this.typeSignatureInput.selectionStart;
    const end = this.typeSignatureInput.selectionEnd;
    this.typeSignatureInput.value = firstLetter.toUpperCase() + value.slice(1);
    this.typeSignatureInput.setSelectionRange(start, end);
    dispatchChange(this.typeSignatureInput);
  }

  handleDrawSignatureInputFocus() {
    this.undoButton.focus();
  }

  handleFontButtonClick(event: Event) {
    const button =
      event.target instanceof Element && event.target.closest('button');
    if (!button) {
      return;
    }
    const { font_family, size } = JSON.parse(button.value);
    this.typeSignatureInput.style.fontFamily = font_family;
    this.typeSignatureInput.style.fontSize = `${size}px`;
    button
      .closest('details-menu')
      ?.querySelectorAll('[aria-checked]')
      .forEach(b => b.setAttribute('aria-checked', 'false'));
    button.setAttribute('aria-checked', 'true');
  }

  async submit(event: CustomEvent<FormData>) {
    event.preventDefault();
    const { set_default_signature } = parseForm<{
      set_default_signature: boolean;
    }>(event.detail);

    // wait for form to finish clearing btn-busy before we re-add it
    await new Promise(resolve => setTimeout(resolve));

    try {
      this.submitButton.classList.add('btn-busy');
      const canvas = document.createElement('canvas');
      if (
        this.querySelector('.tabnav-tab[aria-selected="true"]')?.id ===
        'tab-type-signature'
      ) {
        const ctx = canvas.getContext('2d')!;
        const { fontSize, fontFamily } = this.typeSignatureInput.style;
        const font = `${+fontSize.substr(0, 2) * 2}px ${fontFamily}`;
        ctx.font = font;
        ctx.textBaseline = 'top';
        const {
          actualBoundingBoxLeft,
          actualBoundingBoxRight,
          actualBoundingBoxAscent,
          actualBoundingBoxDescent
        } = ctx.measureText(this.typeSignatureInput.value);
        canvas.width = actualBoundingBoxRight - actualBoundingBoxLeft;
        canvas.height = actualBoundingBoxAscent + actualBoundingBoxDescent;
        ctx.font = font;
        ctx.textBaseline = 'top';
        ctx.fillText(
          this.typeSignatureInput.value,
          actualBoundingBoxLeft,
          actualBoundingBoxAscent
        );
      } else {
        const groups = this.signaturePad.toData();
        const points = groups.map(g => g.points).flat();
        const minX = Math.max(-4, Math.min(...points.map(p => p.x - 4)));
        const minY = Math.max(-4, Math.min(...points.map(p => p.y - 4)));
        const maxX = Math.min(
          this.canvas.width / 2 + 4,
          Math.max(...points.map(p => p.x + 4))
        );
        const maxY = Math.min(
          this.canvas.height / 2 + 4,
          Math.max(...points.map(p => p.y + 4))
        );
        console.log(
          minX,
          maxX,
          this.canvas.width,
          minY,
          maxY,
          this.canvas.height
        );
        for (const point of points) {
          point.x -= minX;
          point.y -= minY;
        }
        canvas.width = (maxX - minX) * 2;
        canvas.height = (maxY - minY) * 2;
        canvas.getContext('2d')?.scale(2, 2);
        const pad = new SignaturePad(canvas);
        pad.fromData(groups);
      }
      const blob = await new Promise<Blob | null>(resolve =>
        canvas.toBlob(resolve, 'image/png')
      );
      const modal = this.closest('modal-content');
      modal?.controller?.dispatchEvent(
        new CustomEvent<SignatureAcceptedEventDetail>('signature-accepted', {
          bubbles: true,
          detail: { blob: blob!, set_default_signature }
        })
      );
      modal?.remove();
    } catch (err) {
      displayServerValidationError(this.typeSignatureInput.form!);
      throw err;
    } finally {
      this.submitButton.classList.remove('btn-busy');
    }
  }
}

declare global {
  interface Window {
    SignaturePadElement: typeof SignaturePadElement;
  }
  interface HTMLElementTagNameMap {
    'signature-pad': SignaturePadElement;
  }
}
