import {
  AddressFields,
  AddressPrecision,
  AddressSuggestion,
  DependencyError,
  addressPrecisionButtonContent,
  render
} from '@cumu/shared';
import type AutoCompleteElement from '@github/auto-complete-element';
import { target, targets } from '@github/catalyst';
import { getLocale } from '../locale';
import { isSessionExpiredResponse } from '../session';
import { controller } from './controller';
import { animate, dispatchChange } from './util';

const locale = getLocale();

@controller('address-scope')
class AddressScopeElement extends HTMLElement {
  @target declare autocomplete: AutoCompleteElement;
  @target declare fieldset: HTMLFieldSetElement;
  @target declare sub_address: HTMLInputElement;
  @target declare precision_summary: HTMLElement;
  @target declare rooftop_map_link: HTMLAnchorElement;
  @target declare entry_map_link: HTMLAnchorElement;
  @targets declare manual_inputs: HTMLInputElement[];

  connectedCallback() {
    this.addEventListener('combobox-commit', this.onCommit);
    this.addEventListener('input', this.onInput);
    this.addEventListener('change', this.onChange);
    this.autocomplete.fetchOnEmpty = false;
  }

  disconnectedCallback() {
    this.removeEventListener('combobox-commit', this.onCommit);
    this.removeEventListener('input', this.onInput);
    this.removeEventListener('change', this.onChange);
  }

  onAutocompleteInputBlur() {
    // Close the dropdown on blur.
    // Timeout ensures this doesn't interfere with blurs that occur when selecting a suggestion.
    setTimeout(() => {
      if (this.autocomplete.open) {
        this.autocomplete.open = false;
      }
    }, 300); // do not reduce without testing quickly toggling between autocomplete suggestions with mouse.
  }

  onAutocompleteLoadEnd() {
    // Don't open the dropdown when the input is not focused.
    if (document.activeElement !== this.autocomplete.inputElement) {
      setTimeout(() => {
        if (this.autocomplete.open) {
          this.autocomplete.open = false;
        }
      }, 0);
    }
  }

  onChange = () => {};

  onCommit = async ({ target }: Event) => {
    if (!(target instanceof HTMLElement)) {
      return;
    }
    try {
      this.fieldset.disabled = true;

      const suggestion = JSON.parse(
        target.getAttribute('data-autocomplete-data')!
      ) as AddressSuggestion;
      const postOfficeBox = target.getAttribute(
        'data-autocomplete-post-office-box'
      );
      const manual = suggestion.magicKey === 'MANUAL';

      let candidate: AddressFields;

      if (manual) {
        candidate = {
          street_address: suggestion.text,
          sub_address: '',
          city: '',
          region: '',
          region_abbr: '',
          postal_code: '',
          postal_code_ext: '',
          country: '',
          neighborhood: '',
          district: '',
          metro_area: '',
          entry_x: '' as any,
          entry_y: '' as any,
          rooftop_x: '' as any,
          rooftop_y: '' as any,
          precision: '',
          subregion: ''
        };
        for (const input of this.manual_inputs) {
          input.disabled = false;
        }
      } else {
        const params = new URLSearchParams({
          q: suggestion.text,
          'magic-key': suggestion.magicKey,
          'for-storage': 'true'
        });
        const response = await fetch(`/api/addresses/candidates?${params}`);
        if (isSessionExpiredResponse(response)) {
          return;
        }
        if (!response.ok) {
          throw new DependencyError(response);
        }
        const candidates = await response.json();
        candidate = candidates[0];
        if (postOfficeBox) {
          candidate.street_address = postOfficeBox;
        }
      }

      for (let [key, value] of Object.entries(candidate)) {
        if (key === 'postal_code' && candidate.postal_code_ext) {
          value = `${value}-${candidate.postal_code_ext}`;
        }
        const input = this.getInput(key);
        if (input instanceof HTMLInputElement) {
          const newValue = String(value ?? '');
          if (input.value !== newValue) {
            input.value = newValue;
            dispatchChange(input);
            animate(input, 'element-changed-animation--fast');
          }
        }
      }
      this.rooftop_map_link.href = `https://www.google.com/maps/search/?api=1&query=${candidate.rooftop_y},${candidate.rooftop_x}`;
      this.rooftop_map_link.hidden =
        !candidate.rooftop_x || !candidate.rooftop_y;
      this.entry_map_link.href = `https://www.google.com/maps/search/?api=1&query=${candidate.entry_y},${candidate.entry_x}`;
      this.entry_map_link.hidden = !candidate.entry_x || !candidate.entry_y;
      this.updatePrecision(candidate.precision);
    } finally {
      this.fieldset.disabled = false;
      this.sub_address.focus();
    }
  };

  getInput(key: string) {
    return this.querySelector<HTMLInputElement>(
      `[name$="/${key}"],[name*="/${key}:"]`
    );
  }

  onInput = ({ target }: Event) => {
    if (
      !(target instanceof HTMLInputElement) ||
      target.type === 'checkbox' ||
      target.name.includes('/sub_address')
    ) {
      return;
    }
    this.updatePrecision('');
    this.rooftop_map_link.hidden = true;
    this.entry_map_link.hidden = true;
    const toClear = [
      'precision',
      'neighborhood',
      'district',
      'metro_area',
      'entry_x',
      'entry_y',
      'rooftop_x',
      'rooftop_y'
    ];
    if (target.name.includes('/region_abbr')) {
      toClear.push('region');
      toClear.push('country');
    }
    for (const key of toClear) {
      const input = this.getInput(key);
      if (input instanceof HTMLInputElement) {
        input.value = '';
        animate(input, 'element-changed-animation--fast');
      }
    }
  };

  updatePrecision(precision: AddressPrecision) {
    this.precision_summary.innerHTML = render(
      addressPrecisionButtonContent(locale, precision)
    );
  }
}

declare global {
  interface Window {
    AddressScopeElement: typeof AddressScopeElement;
  }
  interface HTMLElementTagNameMap {
    'address-scope': AddressScopeElement;
  }
}
