import { target, targets } from '@github/catalyst';
import { controller } from './controller';
import { instantiateTemplate, synchronizePointers } from './editable-list';

@controller('swap-controller')
class SwapControllerElement extends HTMLElement {
  @target declare target: HTMLFormElement;
  @targets declare templates: HTMLTemplateElement[];

  swap({ target }: Event) {
    const value =
      target && 'value' in target && typeof target.value === 'string'
        ? target.value
        : null;
    const template = this.templates.find(
      t => t.getAttribute('data-value') === value
    );
    if (!template) {
      return;
    }
    const fragment = instantiateTemplate(template);
    const nodes = Array.from(fragment.children);
    const current = this.target;
    current.after(fragment);
    current.remove();
    nodes.forEach(synchronizePointers);
  }
}

declare global {
  interface Window {
    SwapControllerElement: typeof SwapControllerElement;
  }
  interface HTMLElementTagNameMap {
    'swap-controller': SwapControllerElement;
  }
}
