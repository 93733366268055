import {
  errorAlertTemplate,
  html,
  isValidationErrorBody,
  loginRegex,
  newElementId,
  parseUserDate,
  render
} from '@cumu/shared';
import {
  loc_actionRequired,
  loc_invalidFormat,
  loc_invalidGeneral,
  loc_invalidInteger,
  loc_invalidLessOrEqual,
  loc_invalidLogin,
  loc_invalidMaxCount,
  loc_invalidMaxLength,
  loc_invalidMaxNumber,
  loc_invalidMinLength,
  loc_invalidMinNumber,
  loc_invalidMultiple,
  loc_invalidRequired,
  loc_invalidUnique,
  loc_invalidUniqueCount,
  loc_somethingWentWrong,
  loc_theresAProblem
} from '@cumu/strings';
import { getLocale } from '../locale';
import {
  HTMLValueElement,
  getPointerSiblingElements,
  isValueElement
} from './utilities';

export const FORM_VALIDATION_ERROR_EVENT_TYPE = 'form-validation-error';

export interface FormValidationErrorEventDetails {
  form: HTMLFormElement;
  validator: Validator;
  input: HTMLValueElement;
  group: HTMLElement;
  label: string;
  message: string;
}

declare global {
  interface ElementEventMap {
    [FORM_VALIDATION_ERROR_EVENT_TYPE]: CustomEvent<FormValidationErrorEventDetails>;
  }

  interface WindowEventMap {
    [FORM_VALIDATION_ERROR_EVENT_TYPE]: CustomEvent<FormValidationErrorEventDetails>;
  }
}

const locale = getLocale();

export function getLabel(input: HTMLValueElement): string {
  const labelElement =
    (input.type === 'checkbox' || input.type === 'radio'
      ? input.closest('fieldset')?.querySelector('legend')
      : null) ?? input.labels?.[0];
  const textElement =
    labelElement?.querySelector('[data-label-text]') ?? labelElement;
  const label = textElement?.textContent ?? input.getAttribute('aria-label');
  if (!label) {
    throw new Error(
      `${input.nodeName} name="${input.name}" id="${input.id}" has no associated label.`
    );
  }
  return label.trim();
}

function getGroup(input: HTMLValueElement) {
  const group = input.closest<HTMLElement>('.form-group');
  if (!group) {
    throw new Error(
      `${input.nodeName} type="${input.type}" name="${input.name}" id="${input.id}" is not within a .form-group. Parent #${input.parentElement?.id} ${input.parentElement?.className}`
    );
  }
  return group;
}

function getBody(input: HTMLValueElement) {
  const body = input.closest('.form-group-body');
  if (!body) {
    throw new Error(
      `${input.nodeName} name="${input.name}" id="${input.id}" is not within a .form-group-body`
    );
  }
  return body;
}

function createErrorAlert(form: HTMLFormElement) {
  const formLayout = form.classList.contains('row')
    ? form
    : (form.querySelector('.row') ?? form);
  formLayout.insertAdjacentHTML(
    'afterbegin',
    render(errorAlertTemplate(locale))
  );
  return formLayout.firstElementChild as HTMLElement;
}

function getErrorAlert(form: HTMLFormElement, warn = false) {
  const id = form.getAttribute('error-alert');
  const errorAlert = id
    ? document.getElementById(id)
    : (form.querySelector<HTMLElement>(
        '.flash.flash-error, .flash.flash-warn'
      ) ?? createErrorAlert(form));
  if (!errorAlert) {
    throw new Error(`getErrorAlert: No element with id="${id}" found.`);
  }
  errorAlert.classList.toggle('flash-warn', warn);
  errorAlert.classList.toggle('flash-error', !warn);
  const title = errorAlert.firstElementChild as HTMLElement;
  title.textContent = warn
    ? loc_actionRequired[locale]
    : loc_theresAProblem[locale];
  return {
    errorAlert,
    errorList: errorAlert.lastElementChild as HTMLElement
  };
}

function createErrorNote(input: HTMLValueElement) {
  const note = document.createElement('p');
  note.id = newElementId();
  input.setAttribute(
    'aria-describedby',
    `${note.id} ${input.getAttribute('aria-describedby') || ''}`
  );
  note.classList.add('note', 'error');
  getBody(input).after(note);
  return note;
}

export function setValidationMessage(
  element: HTMLValueElement,
  message: string
) {
  const group = getGroup(element);
  const note = group.querySelector('.note.error') || createErrorNote(element);
  note.textContent = message;
}

export interface FormValidationError {
  message: string;
  input: HTMLValueElement;
}

export type FormValidationResult =
  | { valid: true }
  | { valid: false; errors: FormValidationError[] };

export type Validator = (
  input: HTMLValueElement,
  label: string
) => string | null;

export function validateRequired(
  input: HTMLValueElement,
  label: string
): string | null {
  if (
    input.closest('[validate-required]')?.getAttribute('validate-required') ===
    'false'
  ) {
    return null;
  }
  const customMessage = input.getAttribute('validate-required-message');
  if (
    input.required &&
    input.value.trim() === '' &&
    !(
      input instanceof HTMLSelectElement &&
      (input.options.length === 0 ||
        (input.options.length === 1 && input.options[0].value === ''))
    ) &&
    input.type !== 'checkbox' &&
    input.type !== 'radio'
  ) {
    return customMessage
      ? customMessage.replaceAll('{label}', label)
      : loc_invalidRequired[locale](label);
  }
  if (input.type === 'checkbox') {
    const items = input.form?.elements.namedItem(input.name);
    if (
      !items ||
      !(items instanceof RadioNodeList) ||
      items.item(items.length - 1) !== input ||
      Array.from(items).find(x => (x as HTMLInputElement).checked)
    ) {
      return null;
    }
    return customMessage
      ? customMessage.replaceAll('{label}', label)
      : loc_invalidRequired[locale](label);
  }
  if (input.type === 'radio') {
    const items = input.form?.elements.namedItem(input.name);
    if (
      !items ||
      !(items instanceof RadioNodeList) ||
      items.item(items.length - 1) !== input ||
      Array.from(items).find(
        x =>
          (x as HTMLInputElement).checked &&
          (x as HTMLInputElement).value.length
      )
    ) {
      return null;
    }
    return customMessage
      ? customMessage.replaceAll('{label}', label)
      : loc_invalidRequired[locale](label);
  }
  return null;
}

function validatePattern(
  input: HTMLValueElement,
  label: string
): string | null {
  if (input.validity.patternMismatch) {
    return (
      input.getAttribute('pattern-message') ?? loc_invalidFormat[locale](label)
    );
  }
  return null;
}

function validateStep(input: HTMLValueElement, label: string): string | null {
  if (input.validity.stepMismatch) {
    const step = input.getAttribute('step');
    if (step === '1') {
      return loc_invalidInteger[locale](label);
    }
    return loc_invalidMultiple[locale](label, step);
  }
  return null;
}

function validateLoginPattern(
  input: HTMLValueElement,
  label: string
): string | null {
  if (
    input.validity.valid &&
    input.hasAttribute('validate-login') &&
    input.value &&
    !loginRegex.test(input.value)
  ) {
    return loc_invalidLogin[locale](label);
  }
  return null;
}

function validateUrl(input: HTMLValueElement, label: string): string | null {
  if (
    input.type === 'url' &&
    input.value &&
    (input.validity.typeMismatch || input.validity.patternMismatch)
  ) {
    return loc_invalidGeneral[locale](label);
  }
  return null;
}

function validateEmail(input: HTMLValueElement, label: string): string | null {
  if (input.type === 'email' && input.value && input.validity.typeMismatch) {
    return loc_invalidGeneral[locale](label);
  }
  return null;
}

function validateMinLength(
  input: HTMLValueElement,
  label: string
): string | null {
  if (
    (input instanceof HTMLTextAreaElement ||
      input instanceof HTMLInputElement) &&
    // input.validity.tooShort
    input.minLength !== -1 &&
    input.value.length !== 0 &&
    input.value.length < input.minLength
  ) {
    return loc_invalidMinLength[locale](label, input.minLength);
  }
  return null;
}

function validateMaxLength(
  input: HTMLValueElement,
  label: string
): string | null {
  if (
    (input instanceof HTMLTextAreaElement ||
      input instanceof HTMLInputElement) &&
    // input.validity.tooLong
    input.maxLength !== -1 &&
    input.value.length !== 0 &&
    input.value.length > input.maxLength
  ) {
    return loc_invalidMaxLength[locale](label, input.maxLength);
  }
  return null;
}

function validateMin(input: HTMLValueElement, label: string): string | null {
  if (
    input instanceof HTMLInputElement &&
    input.value.length !== 0 &&
    input.validity.rangeUnderflow
  ) {
    return loc_invalidMinNumber[locale](label, input.getAttribute('min'));
  }
  return null;
}

function validateMax(input: HTMLValueElement, label: string): string | null {
  if (input instanceof HTMLInputElement && input.validity.rangeOverflow) {
    return loc_invalidMaxNumber[locale](label, input.getAttribute('max'));
  }
  return null;
}

function validateLessOrEqual(
  input: HTMLValueElement,
  label: string
): string | null {
  let otherName: string | null;
  let other: Element | RadioNodeList | null | undefined;
  let dateValue: Date | undefined;
  let otherDateValue: Date | undefined;
  if (
    input instanceof HTMLInputElement &&
    input.value.length !== 0 &&
    (otherName = input.getAttribute('validate-less-or-equal')) &&
    (other = input.form?.elements.namedItem(otherName)) &&
    other instanceof HTMLInputElement &&
    other.value.length !== 0 &&
    other.type === input.type &&
    ((input.type === 'number' && input.valueAsNumber > other.valueAsNumber) ||
      (input.type === 'text' &&
        input.closest('date-input') &&
        other.closest('date-input') &&
        (dateValue = parseUserDate(input.value)) &&
        dateValue &&
        (otherDateValue = parseUserDate(other.value)) &&
        otherDateValue &&
        dateValue > otherDateValue))
  ) {
    const otherLabel = getLabel(other);
    return loc_invalidLessOrEqual[locale](label, otherLabel);
  }
  return null;
}

function validateMaxSiblings(
  input: HTMLValueElement,
  _label: string
): string | null {
  if (!input.validity.valid || !input.hasAttribute('validate-max-items')) {
    return null;
  }
  const max = +input.getAttribute('validate-max-items')!;
  const items = getPointerSiblingElements(input).filter(
    x => !(x instanceof HTMLInputElement && x.type === 'radio' && !x.checked)
  );
  if (items.length > max && items.slice(max).includes(input)) {
    return loc_invalidMaxCount[locale](max);
  }
  return null;
}

function validateUniqueSiblings(input: HTMLValueElement, label: string) {
  if (
    !input.validity.valid ||
    !input.hasAttribute('validate-unique') ||
    !input.value ||
    (input instanceof HTMLInputElement &&
      input.type === 'radio' &&
      !input.checked)
  ) {
    return null;
  }
  const items = getPointerSiblingElements(input);
  const rawMax = input.getAttribute('validate-unique') || '1';
  const max = parseInt(rawMax);
  if (isNaN(max)) {
    return null;
  }
  if (
    items
      .filter(
        x =>
          x.value === input.value &&
          !(x instanceof HTMLInputElement && x.type === 'radio' && !x.checked)
      )
      .indexOf(input) >= max
  ) {
    return max === 1
      ? loc_invalidUnique[locale](label)
      : loc_invalidUniqueCount[locale](label, max);
  }
  return null;
}

const validators: Validator[] = [
  validateRequired,
  validateStep,
  validateMinLength,
  validateMaxLength,
  validateMin,
  validateMax,
  validateUrl,
  validateLoginPattern,
  validatePattern,
  validateEmail,
  validateLessOrEqual,
  validateMaxSiblings,
  validateUniqueSiblings
];

export function addCustomValidator(validator: Validator) {
  validators.push(validator);
}

function canValidate(target: EventTarget | null): target is HTMLValueElement {
  return (
    isValueElement(target) &&
    target.type !== 'hidden' &&
    !(target.type === 'radio' && !target.required) &&
    !(target.type === 'checkbox' && !target.required)
  );
}

export async function validateForm(
  form: HTMLFormElement,
  displayValidity: true | false | 'info' = true,
  scope: Element = form
): Promise<FormValidationResult> {
  const errors: FormValidationError[] = [];

  const { errorAlert, errorList } = getErrorAlert(
    form,
    displayValidity === 'info'
  );

  if (displayValidity) {
    errorAlert.hidden = true;
    errorList.innerHTML = '';
  }

  for (const input of form.elements) {
    if ((scope !== form && !scope.contains(input)) || !canValidate(input)) {
      continue;
    }

    const label = getLabel(input);
    const group = getGroup(input);

    if (displayValidity) {
      setValidationMessage(input, '');
      group.classList.remove('errored');
    }

    for (const validator of validators) {
      const message = validator(input, label);

      if (!message) {
        continue;
      }

      if (
        !form.dispatchEvent(
          new CustomEvent<FormValidationErrorEventDetails>(
            FORM_VALIDATION_ERROR_EVENT_TYPE,
            {
              bubbles: true,
              cancelable: true,
              detail: {
                validator,
                form,
                group,
                input,
                label,
                message
              }
            }
          )
        )
      ) {
        continue;
      }

      errors.push({ input, message });

      if (displayValidity) {
        setValidationMessage(input, message);
        group.classList.toggle('errored', displayValidity === true);
        const linkTarget =
          getFirstInputWithName(input) ??
          input.closest('.form-group-body')?.querySelector('[id]');
        if (linkTarget) {
          linkTarget.style.scrollMarginTop = `${Math.max(linkTarget.offsetTop - group.offsetTop, 60)}px`;
        }
        errorList.insertAdjacentHTML(
          'beforeend',
          render(
            html`<li>
              <a class="Link--primary" href="#${linkTarget?.id}">${message}</a>
            </li>`
          )
        );
      }

      break;
    }
  }

  if (errors.length === 0) {
    return { valid: true };
  }

  if (displayValidity) {
    errorAlert.hidden = false;
    errorAlert.focus();
  }

  return { valid: false, errors };
}

function getFirstInputWithName(input: HTMLValueElement) {
  if (
    input instanceof HTMLInputElement &&
    (input.type === 'checkbox' || input.type === 'radio')
  ) {
    input =
      input.form?.querySelector(
        `[name="${input.name}"][type="${input.type}"]`
      ) ?? input;
    return input;
  } else {
    return input;
  }
}

export function clearValidationErrors(target: EventTarget | null) {
  if (
    !canValidate(target) &&
    // scenario where a server validation error was added for something that is not normally client-side validated
    !(
      target instanceof HTMLInputElement &&
      target.closest('.form-group.errored')
    )
  ) {
    return;
  }
  setValidationMessage(target, '');
  getGroup(target).classList.remove('errored');
  const { errorAlert, errorList } = getErrorAlert(target.form!);
  errorList
    .querySelectorAll(
      `a[href="#${target.id}"],a[href="#${
        getFirstInputWithName(target).id
      }"],a[href="#${
        target.closest('.form-group-body')?.querySelector('[id]')?.id
      }"]`
    )
    .forEach(a => a.parentElement!.remove());
  if (!errorList.firstElementChild) {
    errorAlert.hidden = true;
  }
}

export function displayServerValidationError(
  form: HTMLFormElement,
  body?: unknown
) {
  if (!isValidationErrorBody(body)) {
    displayInternalServerError(form);
    return;
  }
  let { property, message } = body;
  const { errorAlert, errorList } = getErrorAlert(form);
  let input: Element | null = null;
  if (property) {
    for (let i = 0; i < form.elements.length; i++) {
      let el = form.elements.item(i)!;
      if (el instanceof RadioNodeList) {
        el = el.item(0) as Element;
      }
      if (
        el.matches(
          `:is([name="${property}"],[name^="${property}:"]):not([type="hidden"])`
        )
      ) {
        input = el;
        break;
      }
    }
  }
  if (isValueElement(input)) {
    const label = getLabel(input);
    message = message
      .replaceAll('{label}', label)
      .replaceAll('{value}', input.value);
    setValidationMessage(input, message);
    const group = getGroup(input);
    group.classList.add('errored');
    input.style.scrollMarginTop = `${Math.max(input.offsetTop - group.offsetTop, 60)}px`;
    errorList.insertAdjacentHTML(
      'beforeend',
      render(
        html`<li>
          <a
            class="Link--primary"
            href="#${input.id ||
            input.closest('.form-group-body')?.querySelector('[id]')?.id}"
            >${message}</a
          >
        </li>`
      )
    );
  } else {
    errorList.insertAdjacentHTML(
      `beforeend`,
      render(html`<li>${message}</li>`)
    );
  }
  errorAlert.hidden = false;
  errorAlert.focus();
}

export function displayInternalServerError(form: HTMLFormElement) {
  const { errorAlert, errorList } = getErrorAlert(form);
  errorList.insertAdjacentHTML(
    `beforeend`,
    render(html`<li>${loc_somethingWentWrong[locale]}</li>`)
  );
  errorAlert.hidden = false;
  errorAlert.focus();
}
