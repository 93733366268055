import { newElementId } from '@cumu/shared';

export class ModalControllerElement extends HTMLElement {
  templateSlot: HTMLSlotElement;

  constructor() {
    super();
    const shadow = this.attachShadow({ mode: 'open' });
    this.templateSlot = document.createElement('slot');
    this.templateSlot.name = 'template';
    shadow.append(document.createElement('slot'), this.templateSlot);
  }

  connectedCallback() {
    this.querySelector('button')?.addEventListener('click', this);
  }

  disconnectedCallback() {
    this.querySelector('button')?.removeEventListener('click', this);
  }

  handleEvent(event: Event) {
    if (event.target instanceof Element && event.target.closest(':disabled')) {
      return;
    }
    const document = this.ownerDocument;
    const template = this.templateSlot.assignedElements()[0];
    const src = this.getAttribute('src');
    let content: DocumentFragment | Element;
    if (!template) {
      if (src) {
        const frag = document.createElement('include-fragment');
        frag.src = src;
        content = frag;
      } else {
        throw new Error('Part "template" or attribute "src" is required.');
      }
    } else if (!(template instanceof HTMLTemplateElement)) {
      throw new Error('Part "template" is not an HTMLTemplateElement');
    } else {
      content = template.content.cloneNode(true) as DocumentFragment;
      if (src) {
        content.querySelector('include-fragment')!.src = src;
      }
    }
    if (!this.id) {
      this.id = newElementId();
    }
    const modal = document.createElement('modal-content');
    modal.setAttribute('controller-id', this.id);
    modal.append(content);
    document.body.append(modal);
  }
}

declare global {
  interface Window {
    ModalControllerElement: typeof ModalControllerElement;
  }
  interface HTMLElementTagNameMap {
    'modal-controller': ModalControllerElement;
  }
}

if (!window.customElements.get('modal-controller')) {
  window.ModalControllerElement = ModalControllerElement;
  window.customElements.define('modal-controller', ModalControllerElement);
}
