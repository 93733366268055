import {
  DependencyError,
  html,
  possiblyMarkdownRegex,
  render
} from '@cumu/shared';
import {
  loc_nothingToPreview,
  loc_uploadFilesProgressFormat
} from '@cumu/strings';
import { Attachment } from '@github/file-attachment-element';
import { uploadAttachments } from '../attachments';
import { getLocale } from '../locale';
import { isSessionExpiredResponse } from '../session';
import { dispatchChange } from './util';

interface TextExpanderChangeEventDetail {
  key: string;
  provide: TextExpanderProvideFn;
  text: string;
}

type TextExpanderProvideFn = (
  value: Promise<{ matched: boolean; fragment: HTMLElement }>
) => void;

interface TextExpanderValueEventDetail {
  key: string;
  item: HTMLElement;
  value: string | null;
}
interface Emoji {
  id: string;
  text: string[];
  value: string;
}

let emojiPromise: Promise<Emoji[]> | null = null;

async function findEmoji(text: string, src: string): Promise<Emoji[]> {
  if (!emojiPromise) {
    emojiPromise = fetch(src).then(r => {
      if (isSessionExpiredResponse(r)) {
        emojiPromise = null;
        return [];
      }
      return r.json();
    });
  }

  if (text.length === 0) {
    return [
      { id: '+1', text: [], value: '👍' },
      { id: '-1', text: [], value: '👎' },
      { id: '100', text: [], value: '💯' },
      { id: 'cry', text: [], value: '😢' },
      { id: 'heart', text: [], value: '❤️' }
    ];
  }
  const emoji = await emojiPromise;
  text = text.toLowerCase();
  const suggestions = [];
  let i = 0;
  while (i < emoji.length && suggestions.length < 5) {
    if (emoji[i].text.find(t => t.startsWith(text))) {
      suggestions.push(emoji[i]);
    }
    i++;
  }
  return suggestions;
}

const fragment = document.createElement('ul');
fragment.setAttribute('aria-label', 'Results');
fragment.className = 'suggester suggester-container';
fragment.setAttribute('role', 'listbox');

let mentionFetchController: AbortController | undefined;

async function provideMention(
  mentionsSrc: string,
  text: string
): Promise<string> {
  mentionFetchController?.abort('superseded');
  mentionFetchController = new AbortController();
  const url = new URL(mentionsSrc, location.origin);
  url.searchParams.set('q', text);
  const response = await fetch(url, {
    signal: mentionFetchController.signal,
    headers: { accept: 'text/fragment+html' }
  });
  if (isSessionExpiredResponse(response)) {
    return '';
  }
  if (!response.ok) {
    throw new DependencyError(response);
  }
  return response.text();
}

let previewFetchController: AbortController | undefined;

async function previewMarkdown(
  previewSrc: string,
  markdown: string
): Promise<string> {
  previewFetchController?.abort('superseded');
  previewFetchController = undefined;
  if (markdown.trim().length === 0) {
    return loc_nothingToPreview[getLocale()];
  }
  if (!possiblyMarkdownRegex.test(markdown)) {
    return markdown;
  }
  previewFetchController = new AbortController();
  const url = new URL(previewSrc, location.origin);
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
      accept: 'text/fragment+html'
    },
    body: JSON.stringify(markdown),
    signal: previewFetchController.signal
  });
  if (isSessionExpiredResponse(response)) {
    return `<p class="text-italic">Unable to render markdown preview: session is expired</p>`;
  }
  if (!response.ok) {
    throw new DependencyError(response);
  }
  return response.text();
}

export class MarkdownEditorElement extends HTMLElement {
  private markdownToPreview: string | undefined = undefined;
  private selectionStart: number | undefined = undefined;

  connectedCallback() {
    this.addEventListener('change', this);
    this.addEventListener('input', this);
    this.addEventListener('tab-container-changed', this);
    this.addEventListener('text-expander-change', this, { capture: true });
    this.addEventListener('text-expander-value', this, { capture: true });
    this.addEventListener('text-expander-committed', this, { capture: true });
    this.addEventListener('file-attachment-accepted', this);
    this.addEventListener('blur', this, { capture: true });
    this.markdownToPreview =
      this.querySelector<HTMLTextAreaElement>('text-expander textarea')
        ?.value ?? undefined;
  }

  disconnectedCallback() {
    this.removeEventListener('change', this);
    this.removeEventListener('input', this);
    this.removeEventListener('tab-container-changed', this);
    this.removeEventListener('text-expander-change', this, { capture: true });
    this.removeEventListener('text-expander-value', this, { capture: true });
    this.removeEventListener('text-expander-committed', this, {
      capture: true
    });
    this.removeEventListener('file-attachment-accepted', this);
    this.removeEventListener('blur', this, { capture: true });
  }

  handleEvent(event: Event) {
    const { type, target } = event;
    switch (type) {
      case 'text-expander-committed':
      case 'change':
      case 'input':
        if (!(target instanceof HTMLTextAreaElement)) {
          return;
        }
        this.invalidatePreview(target.value);
        this.selectionStart = target.selectionStart;
        return;
      case 'tab-container-changed':
        this.preview();
        return;
      case 'text-expander-value': {
        const { detail } = event as CustomEvent<TextExpanderValueEventDetail>;
        const value = detail.item.getAttribute('data-value')!;
        detail.value = value;
        return;
      }
      case 'text-expander-change':
        const {
          detail: { key, provide, text }
        } = event as CustomEvent<TextExpanderChangeEventDetail>;

        if (key === ':') {
          provide(this.provideEmoji(text));
        }

        if (key === '@') {
          const mentionsSrc = this.getAttribute('mentions-src');
          if (mentionsSrc) {
            provide(this.provideMention(mentionsSrc, text));
          }
        }
        return;
      case 'file-attachment-accepted':
        this.fileAttachmentAccepted(
          event as CustomEvent<{ attachments: Attachment[] }>
        );
        return;
      case 'blur':
        if (event.target instanceof HTMLTextAreaElement) {
          this.selectionStart = event.target.selectionStart;
        }
        return;
      default:
        throw new Error(`Unexpected event type "${type}".`);
    }
  }

  provideEmoji(text: string) {
    return findEmoji(text, this.getAttribute('emoji-src')!).then(emoji => {
      fragment.innerHTML = render(
        emoji.map(
          x =>
            html`<li role="option" data-value="${x.value}">
              ${x.value} ${x.id}
            </li>`
        )
      );
      return {
        matched: emoji.length > 0,
        fragment
      };
    });
  }

  provideMention(mentionsSrc: string, text: string) {
    return provideMention(mentionsSrc, text).then(html => {
      fragment.innerHTML = html;
      return {
        matched: fragment.childElementCount > 0,
        fragment
      };
    });
  }

  get markdownBody() {
    const el = this.querySelector('.markdown-body');
    if (!el) {
      throw new Error('Missing .markdown-body element.');
    }
    return el;
  }

  async preview() {
    if (this.markdownToPreview === undefined) {
      return;
    }
    const md = this.markdownToPreview;
    this.markdownToPreview = undefined;
    const src = this.getAttribute('preview-src')!;
    const html = await previewMarkdown(src, md);
    this.markdownBody.innerHTML = html;
  }

  invalidatePreview(value: string) {
    const markdownBody = this.markdownBody;
    this.markdownToPreview = value;
    markdownBody.setAttribute('source-markdown', value);
    markdownBody.innerHTML = render(
      html`<div class="py-3 d-flex flex-justify-center">
        <div class="spinner-border f1 color-fg-muted"></div>
      </div>`
    );
  }

  async fileAttachmentAccepted(
    event: CustomEvent<{ attachments: Attachment[] }>
  ) {
    const locale = getLocale();
    const fileAttachmentElement =
      event.target instanceof HTMLElement && event.target;
    if (!fileAttachmentElement) {
      throw new Error('Missing file attachment element');
    }
    const uploadHref = fileAttachmentElement.getAttribute('upload-href');
    if (!uploadHref) {
      throw new Error('Missing upload href');
    }
    const uploadUrl = new URL(uploadHref, location.origin);
    const attachments = event.detail.attachments;
    const fileLabelContentSpan =
      fileAttachmentElement.hasAttribute('data-file-label-content-id') &&
      (document.getElementById(
        fileAttachmentElement.getAttribute('data-file-label-content-id')!
      ) as HTMLElement);
    if (!fileLabelContentSpan) {
      throw new Error('Missing file label content span');
    }
    const textarea = fileAttachmentElement.querySelector('textarea');
    if (!textarea) {
      throw new Error('Missing textarea');
    }
    if (document.activeElement !== textarea) {
      textarea.focus();
    }
    let interval = 0;
    const progressSpan = document.createElement('span');
    progressSpan.append(
      document.createElement('span'),
      document.createTextNode(' '),
      document.createElement('span')
    );
    progressSpan.firstElementChild!.classList.add(
      'spinner-border',
      'color-fg-muted'
    );
    const setProgress = () => {
      const index = attachments.filter(
        attachment => attachment.isSaved() || attachment.isSaving()
      ).length;
      progressSpan.lastElementChild!.textContent =
        loc_uploadFilesProgressFormat[locale](index, attachments.length);
    };
    setProgress();

    const attachmentSaved = (attachment: Attachment) => {
      const { id, name, href } = attachment;
      const start = this.selectionStart ?? textarea.selectionStart;
      const end = this.selectionStart ?? textarea.selectionEnd;
      const prefix = start > 0 ? '  \n' : '';
      textarea.setRangeText(
        `${prefix}${attachment.isImage() ? '!' : ''}[${name}](${href})  `,
        start,
        end,
        'end'
      );
      dispatchChange(textarea);
      const input = document.createElement('input');
      input.type = 'hidden';
      input.name = 'attachments/-/attachment_id';
      input.value = id!;
      textarea.form?.insertAdjacentElement('beforeend', input);
    };

    try {
      fileLabelContentSpan.hidden = true;
      fileLabelContentSpan.after(progressSpan);
      interval = setInterval(setProgress, 50);
      await uploadAttachments({
        locale,
        attachments,
        uploadUrl,
        attachmentSaved
      });
    } finally {
      progressSpan.remove();
      fileLabelContentSpan.hidden = false;
      clearInterval(interval);
    }
  }
}

declare global {
  interface Window {
    MarkdownEditorElement: typeof MarkdownEditorElement;
  }
  interface HTMLElementTagNameMap {
    'markdown-editor': MarkdownEditorElement;
  }
}

if (!window.customElements.get('markdown-editor')) {
  window.MarkdownEditorElement = MarkdownEditorElement;
  window.customElements.define('markdown-editor', MarkdownEditorElement);
}
