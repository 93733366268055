import {
  ReportColumn,
  aggregateFunctionNames,
  getGroupingOptions,
  groupedSelectOptionsTemplate,
  render
} from '@cumu/shared';
import { target } from '@github/catalyst';
import { controller } from './controller';
import { ReportExpressionInputElement } from './report-expression-input';

@controller('report-column')
class ReportColumnElement extends HTMLElement {
  @target declare typeInput: HTMLInputElement;
  @target declare propertyFormGroup: HTMLElement;
  @target declare groupingSelect: HTMLSelectElement;
  @target declare aggregateSelect: HTMLSelectElement | null | undefined;
  @target declare expressionInput:
    | ReportExpressionInputElement
    | null
    | undefined;

  // When the property, aggregate function, or expression type changed, update the grouping/summarization select options
  updateSummarizationOptions() {
    const propertyInput =
      this.propertyFormGroup?.querySelector('[data-property]');
    const property = propertyInput
      ? JSON.parse(propertyInput.getAttribute('data-property')!)
      : null;
    const column = {
      type: this.typeInput.value,
      aggregate: this.aggregateSelect?.value
    } as ReportColumn;
    const groupingOptions = getGroupingOptions(
      column,
      property,
      this.expressionInput?.expressionType ?? null
    );
    const options = groupedSelectOptionsTemplate(
      groupingOptions.map(g => ({
        text:
          g === 'none'
            ? 'None'
            : g === 'group'
              ? 'Group'
              : aggregateFunctionNames[g],
        value: g,
        group: ['none', 'group'].includes(g)
          ? undefined
          : 'Summarization functions'
      })),
      groupingOptions.includes(this.groupingSelect.value as any)
        ? this.groupingSelect.value
        : 'none'
    );
    this.groupingSelect.innerHTML = render(options);
  }
}

declare global {
  interface Window {
    ReportColumnElement: typeof ReportColumnElement;
  }
  interface HTMLElementTagNameMap {
    'report-column': ReportColumnElement;
  }
}
