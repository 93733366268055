import { html, render, ReportEntityType } from '@cumu/shared';
import { target } from '@github/catalyst';
import { allFormData } from '../forms/utilities';
import { validateForm } from '../forms/validation';
import { isSessionExpiredResponse } from '../session';
import { controller } from './controller';

@controller('report-configuration')
class ReportConfigurationElement extends HTMLElement {
  @target declare form: HTMLFormElement;
  @target declare previewFields: HTMLElement;
  @target declare previewContainer: HTMLElement;
  previewTimeout = 0;
  previewController: AbortController | undefined = undefined;

  connectedCallback() {
    if (this.previewContainer) {
      this.preview();
    }

    this.addEventListener('details-menu-selected', this, true);
  }

  disconnectedCallback() {
    this.removeEventListener('details-menu-selected', this, true);
  }

  handleEvent(event: Event) {
    switch (event.type) {
      case 'details-menu-selected':
        this.enqueuePreview(event);
        break;
    }
  }

  get type() {
    return this.getAttribute('entity-type') as ReportEntityType;
  }

  get organization_id() {
    return this.getAttribute('organization-id')!;
  }

  get previewStatusContainer() {
    return document.getElementById(this.getAttribute('preview-status')!)!;
  }

  validateConfiguration() {
    return validateForm(this.form, true, this);
  }

  setLoadingPreview() {
    this.previewContainer.innerHTML = `<h2 class="p-3"><span>Loading</span><span class="AnimatedEllipsis"></span></h2>`;
    this.previewStatusContainer.innerHTML = `<span class="AnimatedEllipsis"></span>`;
  }

  setPreviewValidationError() {
    this.previewContainer.innerHTML = render(html`
      <h2 class="p-3">Validation error</h2>
    `);
    this.previewStatusContainer.textContent = '0';
  }

  setPreviewServerError(sessionExpired: boolean) {
    this.previewContainer.innerHTML = render(html`
      <h2 class="p-3">
        ${sessionExpired ? 'Session expired' : 'Error loading report preview.'}
      </h2>
    `);
    this.previewStatusContainer.textContent = '0';
  }

  setPreviewResults(fragment: string) {
    this.previewContainer.innerHTML = fragment;
    const attr = 'data-result-count';
    const count = parseInt(
      this.previewContainer.querySelector(`[${attr}]`)!.getAttribute(attr)!
    );
    this.previewStatusContainer.textContent = count.toString();
  }

  enqueuePreview(event: Event) {
    if (
      event.target instanceof Element &&
      event.target.closest('form') !== this.form
    ) {
      return;
    }
    clearTimeout(this.previewTimeout);
    this.previewTimeout = setTimeout(() => this.preview(), 300);
  }

  async preview() {
    clearTimeout(this.previewTimeout);
    const { valid } = await validateForm(this.form, false, this.previewFields);
    if (!valid) {
      return;
    }
    if (this.previewController) {
      this.previewController.abort('superseded');
    }
    this.setLoadingPreview();
    const data = allFormData(this.form);
    data.set('title', 'Preview');
    const body = new URLSearchParams(data as any);
    this.previewController = new AbortController();
    const response = await fetch(
      `/api/organizations/${this.organization_id}/reports/preview`,
      {
        method: 'POST',
        body,
        signal: this.previewController.signal
      }
    );
    this.previewController = undefined;
    if (response.ok) {
      const fragment = await response.text();
      this.setPreviewResults(fragment);
    } else if (response.status === 400) {
      this.setPreviewValidationError();
    } else {
      this.setPreviewServerError(isSessionExpiredResponse(response));
    }
  }
}

declare global {
  interface Window {
    ReportConfigurationElement: typeof ReportConfigurationElement;
  }
  interface HTMLElementTagNameMap {
    'report-configuration': ReportConfigurationElement;
  }
}
