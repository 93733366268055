import type AutoCompleteElement from '@github/auto-complete-element';
import { target } from '@github/catalyst';
import { controller } from './controller';

@controller('medication-input')
class MedicationInputElement extends HTMLElement {
  @target declare autocomplete: AutoCompleteElement; // https://github.com/github/auto-complete-element
}

declare global {
  interface Window {
    MedicationInputElement: typeof MedicationInputElement;
  }
  interface HTMLElementTagNameMap {
    'medication-input': MedicationInputElement;
  }
}
