import {
  Locale,
  loc_theresAProblem,
  loc_validationErrors
} from '@cumu/strings';
import { html } from '../template';
import { newElementId } from './element-id';

export function errorAlertTemplate(locale: Locale, alertId = newElementId()) {
  const id = newElementId();
  return html`<div
    class="flash flash-error py-2 span-12"
    id="${alertId}"
    role="group"
    tabindex="-1"
    aria-labelledby="${id}"
    hidden
  >
    <strong id="${id}">${loc_theresAProblem[locale]}</strong>
    <ul class="pl-3" aria-label="${loc_validationErrors[locale]}"></ul>
  </div>`;
}
