import base from 'base-x';

// Why base 58?
// - url safe
// - shortens guids as much as base 62 most of the time
// - no ambiguity with regards to 0 and O
const base58Alphabet =
  '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';
const base58 = base(base58Alphabet);

export function uuidToBase58(s: string) {
  return base58.encode(parseUuid(s));
}

export function base58ToUuid(s: string) {
  return uuid(base58.decode(s));
}

/*
const BASE58_ALPHABET = '123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz';

export const encode = (uuid: string): string => {
  let b = BigInt('0x' + uuid.replace(/[^a-f0-9]/gi, ''));
  let u58 = '';
  do {
    const index = parseInt((b % BigInt(BASE58_ALPHABET.length)).toString(), 10);
    u58 = BASE58_ALPHABET[index] + u58;
    b = b / BigInt(BASE58_ALPHABET.length);
  } while (b > 0);
  return u58;
};

export const decode = (uuidBase58: string): string => {
  const parts = Array.from(uuidBase58).map((x: string) => BASE58_ALPHABET.indexOf(x));
  if (parts.some((inc) => inc < 0) || uuidBase58.trim() === '') {
    return uuidBase58;
  }
  const max = uuidBase58.length - 1;
  const b = parts.reduce(
    (total, val, index) => (total + BigInt(val)) * (index < max ? BigInt(BASE58_ALPHABET.length) : BigInt(1)),
    BigInt(0),
  );
  const hex = b.toString(16).padStart(32, '0');
  return [0, 8, 12, 16, 20].map((p, i, a) => hex.substring(p, a[i + 1])).join('-');
};

export const valid = (uuidBase58: string): boolean => {
  return decode(uuidBase58).length === 36;
}
*/

const supportsRandomUUID = typeof crypto.randomUUID === 'function';

const randomArr = new Uint8Array(16);

const reg =
  /^(?:[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}|00000000-0000-0000-0000-000000000000)$/i;

const separator = '-';

/**
 * Generate a V4 compliant uuid.
 */
export function uuid(arr?: Uint8Array): string {
  if (!arr) {
    if (supportsRandomUUID) {
      return crypto.randomUUID();
    }
    crypto.getRandomValues(randomArr);
    arr = randomArr;
  }
  // // Per 4.4, set bits for version and `clock_seq_hi_and_reserved`
  // arr[6] = (arr[6] & 0x0f) | 0x40;
  // arr[8] = (arr[8] & 0x3f) | 0x80;
  const s = (
    bth[arr[0]] +
    bth[arr[1]] +
    bth[arr[2]] +
    bth[arr[3]] +
    separator +
    bth[arr[4]] +
    bth[arr[5]] +
    separator +
    bth[arr[6]] +
    bth[arr[7]] +
    separator +
    bth[arr[8]] +
    bth[arr[9]] +
    separator +
    bth[arr[10]] +
    bth[arr[11]] +
    bth[arr[12]] +
    bth[arr[13]] +
    bth[arr[14]] +
    bth[arr[15]]
  ).toLowerCase(); // https://bugs.chromium.org/p/v8/issues/detail?id=3175#c4 https://github.com/uuidjs/uuid/pull/434
  if (!reg.test(s)) {
    throw new TypeError('Invalid uuid');
  }
  return s;
}

export function parseUuid(s: string): Uint8Array {
  if (!reg.test(s)) {
    throw new TypeError('Invalid uuid');
  }
  let v;
  const arr = new Uint8Array(16);

  // Parse ########-....-....-....-............
  arr[0] = (v = parseInt(s.slice(0, 8), 16)) >>> 24;
  arr[1] = (v >>> 16) & 0xff;
  arr[2] = (v >>> 8) & 0xff;
  arr[3] = v & 0xff;

  // Parse ........-####-....-....-............
  arr[4] = (v = parseInt(s.slice(9, 13), 16)) >>> 8;
  arr[5] = v & 0xff;

  // Parse ........-....-####-....-............
  arr[6] = (v = parseInt(s.slice(14, 18), 16)) >>> 8;
  arr[7] = v & 0xff;

  // Parse ........-....-....-####-............
  arr[8] = (v = parseInt(s.slice(19, 23), 16)) >>> 8;
  arr[9] = v & 0xff;

  // Parse ........-....-....-....-############
  // (Use "/" to avoid 32-bit truncation when bit-shifting high-order bytes)
  arr[10] = ((v = parseInt(s.slice(24, 36), 16)) / 0x10000000000) & 0xff;
  arr[11] = (v / 0x100000000) & 0xff;
  arr[12] = (v >>> 24) & 0xff;
  arr[13] = (v >>> 16) & 0xff;
  arr[14] = (v >>> 8) & 0xff;
  arr[15] = v & 0xff;

  return arr;
}

/**
 * Byte to hex mapping.
 * @type {string[]}
 */
const bth: string[] = [];

for (let i = 0; i < 256; i++) {
  bth[i] = (i + 0x100).toString(16).substr(1);
}
