import { loc_save } from '@cumu/strings';
import { getLocale } from '../locale';

export type HTMLValueElement =
  | HTMLInputElement
  | HTMLSelectElement
  | HTMLTextAreaElement;

export function isValueElement(
  target: EventTarget | Node | null
): target is HTMLValueElement {
  return (
    target instanceof HTMLSelectElement ||
    target instanceof HTMLInputElement ||
    target instanceof HTMLTextAreaElement
  );
}

export function normalizeInputValue(target: EventTarget | null) {
  if (target instanceof HTMLTextAreaElement) {
    target.value = target.value.trim();
  } else if (
    target instanceof HTMLInputElement &&
    (target.type === 'text' || target.type === 'email')
  ) {
    target.value = target.value.trim();
  }
}

function escapeRegex(s: string) {
  return s.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
}

function getPointerSiblingRegex(pointer: string) {
  const [, base, name] = /(^.*\/)(?:\d+)(\/[^:]*)/.exec(pointer) ?? [];
  if (base === undefined || name === undefined) {
    throw new Error(`Unexpected pointer "${pointer}" for sibling expression.`);
  }
  const source = `^${escapeRegex(base)}\\d+${escapeRegex(name)}(?::|$)`;
  return new RegExp(source);
}

export function getPointerSiblingElements(
  input: HTMLValueElement
): HTMLValueElement[] {
  const r = getPointerSiblingRegex(input.name);
  return Array.from(input.form!.elements)
    .filter(isValueElement)
    .filter(el => r.test(el.name));
}

export function disableSubmitButtons(form: HTMLFormElement, disabled: boolean) {
  for (const element of form.elements) {
    if (
      element instanceof HTMLButtonElement &&
      element.type === 'submit' &&
      !element.hasAttribute('name')
    ) {
      element.toggleAttribute('disabled', disabled);
    }
  }
}

export function busySubmitButtons(form: HTMLFormElement, busy: boolean) {
  for (const element of form.elements) {
    if (element instanceof HTMLButtonElement && element.type === 'submit') {
      element.classList.toggle('btn-busy', busy);
    }
  }
}

export function updateNewSubmitButtonText(form: HTMLFormElement) {
  for (const element of form.elements) {
    if (
      element instanceof HTMLButtonElement &&
      element.type === 'submit' &&
      element.hasAttribute('new') &&
      element.lastElementChild instanceof HTMLSpanElement
    ) {
      element.lastElementChild.textContent = loc_save[getLocale()];
    }
  }
}

export function allFormData(form: HTMLFormElement): FormData {
  const disabled = Array.from(form.elements).filter(
    el => isValueElement(el) && el.disabled
  ) as HTMLValueElement[];
  for (const el of disabled) {
    el.disabled = false;
  }
  const data = new FormData(form);
  for (const el of disabled) {
    el.disabled = true;
  }
  return data;
}

export function isEmptyIterable(iterable: Iterable<unknown>) {
  for (const _ of iterable) {
    return false;
  }
  return true;
}
