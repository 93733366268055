export function maskToArray(value: number): number[] {
  const array: number[] = [];
  for (let shift = 0; shift < 32; shift++) {
    if ((value & (2 ** shift)) !== 0) {
      array.push(shift);
    }
  }
  return array;
}

export function arrayToMask(array: number[]): number {
  let value = 0;
  for (const shift of array) {
    value += 2 ** shift;
  }
  return value;
}
