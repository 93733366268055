declare const RECAPTCHA_SITE_KEY: string;

let loaded: Promise<void> | undefined = undefined;

export function loadRecaptcha() {
  if (loaded === undefined) {
    loaded = new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_SITE_KEY}`;
      script.onload = () => resolve();
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }
  return loaded;
}

async function recaptchaReady() {
  await loadRecaptcha();
  return new Promise(resolve => grecaptcha.ready(resolve));
}

export async function getRecaptchaToken(action = 'submit'): Promise<string> {
  await recaptchaReady;
  return grecaptcha.execute(RECAPTCHA_SITE_KEY, { action });
}
