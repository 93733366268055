import { escape as escape_html } from 'html-escaper';

const TEMPLATE_RESULT = Symbol.for('cumulus.templates.result');
const UNSAFE_HTML = Symbol.for('cumulus.templates.unsafe-html');

export interface TemplateResult {
  readonly strings: TemplateStringsArray;
  readonly values: unknown[];
}

export interface UnsafeHTML {
  readonly value: string;
}

export function isTemplateResult(value: unknown): value is TemplateResult {
  return (value as any)?.[TEMPLATE_RESULT] === true;
}

export function isUnsafeHTML(value: unknown): value is UnsafeHTML {
  return (value as any)?.[UNSAFE_HTML] === true;
}

export /*#__NO_SIDE_EFFECTS__*/ function html(
  strings: TemplateStringsArray,
  ...values: any[]
): TemplateResult {
  return {
    [TEMPLATE_RESULT]: true,
    strings,
    values
  } as TemplateResult;
}

export function render(value: unknown) {
  if (value === null || value === undefined || value === '') {
    return '';
  }
  if (Array.isArray(value)) {
    let result = '';
    for (const item of value) {
      result += render(item);
    }
    return result;
  }
  if (isTemplateResult(value)) {
    const { strings, values } = value;
    let result = strings[0];
    for (let i = 1; i < strings.length; i++) {
      const value = values[i - 1];
      result += render(value);
      result += strings[i];
    }
    return result;
  }
  if (isUnsafeHTML(value)) {
    return value.value;
  }
  if (typeof value === 'function') {
    throw new Error(`Cannot render function "${value.name}"`);
  }
  return escape_html(typeof value === 'string' ? value : String(value));
}

export function unsafeHTML(value: string): UnsafeHTML {
  return {
    [UNSAFE_HTML]: true,
    value: typeof value === 'string' ? value : String(value)
  } as UnsafeHTML;
}

export function attr(
  name: string,
  value: string | undefined | null
): '' | UnsafeHTML {
  return value === null || value === undefined || value === ''
    ? ''
    : ({
        [UNSAFE_HTML]: true,
        value: `${escape_html(name)}="${escape_html(value)}"`
      } as UnsafeHTML);
}

export function booleanAttr(name: string, value: any): '' | UnsafeHTML {
  return Boolean(value)
    ? ({ [UNSAFE_HTML]: true, value: escape_html(name) } as UnsafeHTML)
    : '';
}

/*
export function renderStream(value: unknown): ReadableStream<Uint8Array> {
  const abortController = new AbortController();
  return new ReadableStream<Uint8Array>({
    async start(controller) {
      const encoder = new TextEncoder();
      await pushValue(value, controller, encoder, abortController.signal);
      controller.close();
    },
    cancel() {
      abortController.abort();
    }
  });
}

async function pushValue(
  value: unknown,
  controller: ReadableStreamController<Uint8Array>,
  encoder: TextEncoder,
  signal: AbortSignal
): Promise<void> {
  if (value === null || value === undefined || value === '' || signal.aborted) {
    return;
  } else if (Array.isArray(value)) {
    for (let i = 0; i < value.length && !signal.aborted; i++) {
      await pushValue(value[i], controller, encoder, signal);
    }
  } else if (isTemplateResult(value)) {
    const { strings, values } = value;
    controller.enqueue(encoder.encode(strings[0]));
    for (let i = 1; i < strings.length; i++) {
      const value = values[i - 1];
      await pushValue(value, controller, encoder, signal);
      controller.enqueue(encoder.encode(strings[i]));
    }
  } else if (value instanceof UnsafeHTML) {
    controller.enqueue(encoder.encode(value.value));
  } else {
    controller.enqueue(
      encoder.encode(
        escape_html(typeof value === 'string' ? value : String(value))
      )
    );
  }
}

// async function pushStream(stream: ReadableStream<Uint8Array>, controller: StreamController, cancellationToken: CancellationToken) {
//   const reader = stream.getReader();
//   do {
//     const { value, done } = await reader.read();
//     if (done || cancellationToken.cancelled) {
//       reader.releaseLock();
//       return;
//     }
//     controller.enqueue(value);
//   } while (true);
// }
*/
