import { AssigneeListItem } from '@cumu/shared';
import { target } from '@github/catalyst';
import RemoteInputElement from '@github/remote-input-element';
import { controller } from './controller';
import { dispatchChange } from './util';

@controller('assignee-picker')
export class AssigneePickerElement extends HTMLElement {
  @target declare userIdInput: HTMLInputElement;
  @target declare userLoginInput: HTMLInputElement | null;
  @target declare organizationIdInput: HTMLInputElement;
  @target declare organizationLoginInput: HTMLInputElement | null;
  @target declare summary: HTMLElement;
  @target declare remoteInput: RemoteInputElement;
  @target declare suggestions: HTMLElement;
  @target declare contractFormGroupContainer: HTMLElement;

  get value(): AssigneeListItem | null {
    const raw = this.summary
      .querySelector('[data-value]')!
      .getAttribute('data-value')!;
    return JSON.parse(raw) as AssigneeListItem;
  }

  focusMenu() {
    this.remoteInput.input!.focus();
  }

  select() {
    const value = this.value;
    const organization_id = value?.organization_id ?? '';
    const organization_login = value?.organization_login ?? '';
    const user_id = value?.user_id ?? '';
    const user_login = value?.user_login ?? '';
    let change = false;
    if (this.organizationIdInput.value !== organization_id) {
      change = true;
      this.organizationIdInput.value = organization_id;
      this.organizationLoginInput?.setAttribute('value', organization_login);
      dispatchChange(this.organizationIdInput);

      if (this.contractFormGroupContainer) {
        const template = organization_id
          ? this.suggestions.querySelector(
              `[data-contracts-for="${organization_id}"]`
            )
          : this.suggestions.querySelector(
              '[data-create-organization-contracts]'
            );
        if (template instanceof HTMLTemplateElement) {
          this.contractFormGroupContainer.innerHTML = '';
          this.contractFormGroupContainer.append(template.content);
        }
      }
    }
    if (this.userIdInput.value !== user_id) {
      change = true;
      this.userIdInput.value = user_id;
      this.userLoginInput?.setAttribute('value', user_login);
      dispatchChange(this.userIdInput);
    }
    if (this.remoteInput.input?.value) {
      this.remoteInput.input.value = '';
      dispatchChange(this.remoteInput.input);
    }
    if (change) {
      this.dispatchEvent(
        new CustomEvent('assignee-change', { bubbles: true, detail: value })
      );
    }
  }

  remoteSuccess() {
    // formerly used to insert a clear selection button
  }
}

declare global {
  interface Window {
    AssigneePickerElement: typeof AssigneePickerElement;
  }
  interface HTMLElementTagNameMap {
    'assignee-picker': AssigneePickerElement;
  }
}
