import {
  Locale,
  defaultLocale,
  loc_email,
  loc_fileIsEmptyFormat,
  loc_fileTooLargeFormat,
  loc_image,
  loc_invalidFileExtensionFormat,
  loc_invalidFilenameFormat,
  loc_missingFileExtensionFormat,
  loc_text,
  loc_video
} from '@cumu/strings';
import filenamify from '../../../../node_modules/filenamify/filenamify.js';
import { groupBy, keyBy } from '../array';
import {
  iconEmail48,
  iconFileArchive48,
  iconFileExcel48,
  iconFilePdf48,
  iconFilePhoto48,
  iconFilePowerpoint48,
  iconFileText48,
  iconFileVideo48,
  iconFileWord48
} from '../icons';

export type AttachmentOwnerType = 'activity' | 'activity_definition' | 'user';

export const attachmentOwnerTypes: AttachmentOwnerType[] = [
  'activity',
  'activity_definition',
  'user'
];

export type AttachmentOwnerSlug =
  | 'activities'
  | 'activity-definitions'
  | 'users';

export const attachmentOwnerSlugs: AttachmentOwnerSlug[] = [
  'activities',
  'activity-definitions',
  'users'
];

export const attachmentTypeToSlug: Record<
  AttachmentOwnerType,
  AttachmentOwnerSlug
> = {
  activity: 'activities',
  activity_definition: 'activity-definitions',
  user: 'users'
};

export const attachmentSlugToType: Record<
  AttachmentOwnerSlug,
  AttachmentOwnerType
> = {
  activities: 'activity',
  'activity-definitions': 'activity_definition',
  users: 'user'
};

export interface AttachmentDocument {
  attachment_id: string;
  owner_id: string;
  owner_type: AttachmentOwnerType;
  content_type: string;
  size: number;
  filename: string;
  update_user_id: string;
  update_datetime: string;
}

export interface AttachmentAssociation
  extends Omit<AttachmentDocument, 'owner_id' | 'owner_type'> {
  property_id: number | null;
  update_organization_id: string;
}

const oneMb = 1000000;

export interface AttachmentType {
  category: (locale: Locale) => string;
  extensions: string[];
  contentType: string;
  maxBytes: number;
  icon48: () => string;
  disposition: 'inline' | 'attachment';
}

export const attachmentTypes: AttachmentType[] = [
  {
    category: locale => loc_image[locale],
    extensions: ['gif'],
    contentType: 'image/gif',
    maxBytes: 10 * oneMb,
    icon48: iconFilePhoto48,
    disposition: 'inline'
  },
  {
    category: locale => loc_image[locale],
    extensions: ['jpeg', 'jpg'],
    contentType: 'image/jpeg',
    maxBytes: 10 * oneMb,
    icon48: iconFilePhoto48,
    disposition: 'inline'
  },
  {
    category: locale => loc_image[locale],
    extensions: ['png'],
    contentType: 'image/png',
    maxBytes: 10 * oneMb,
    icon48: iconFilePhoto48,
    disposition: 'inline'
  },
  {
    category: locale => loc_image[locale],
    extensions: ['svg'],
    contentType: 'image/svg+xml',
    maxBytes: oneMb,
    icon48: iconFilePhoto48,
    disposition: 'inline'
  },
  {
    category: locale => loc_image[locale],
    extensions: ['tif', 'tiff'],
    contentType: 'image/tiff',
    maxBytes: 10 * oneMb,
    icon48: iconFilePhoto48,
    disposition: 'inline'
  },
  {
    category: locale => loc_video[locale],
    extensions: ['mov'],
    contentType: 'video/quicktime',
    maxBytes: 100 * oneMb,
    icon48: iconFileVideo48,
    disposition: 'inline'
  },
  {
    category: locale => loc_video[locale],
    extensions: ['mp4'],
    contentType: 'video/mp4',
    maxBytes: 100 * oneMb,
    icon48: iconFileVideo48,
    disposition: 'inline'
  },
  {
    category: locale => loc_video[locale],
    extensions: ['webm'],
    contentType: 'video/webm',
    maxBytes: 100 * oneMb,
    icon48: iconFileVideo48,
    disposition: 'inline'
  },
  {
    category: () => 'Excel',
    extensions: ['csv'],
    contentType: 'text/csv',
    maxBytes: 25 * oneMb,
    icon48: iconFileExcel48,
    disposition: 'attachment'
  },
  {
    category: () => 'Word',
    extensions: ['doc'],
    contentType: 'application/msword',
    maxBytes: 25 * oneMb,
    icon48: iconFileWord48,
    disposition: 'attachment'
  },
  {
    category: () => 'Word',
    extensions: ['docx'],
    contentType:
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    maxBytes: 25 * oneMb,
    icon48: iconFileWord48,
    disposition: 'attachment'
  },
  {
    category: () => 'PDF',
    extensions: ['pdf'],
    contentType: 'application/pdf',
    maxBytes: 32 * oneMb,
    icon48: iconFilePdf48,
    disposition: 'inline'
  },
  {
    category: () => 'PowerPoint',
    extensions: ['pptx'],
    contentType:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    maxBytes: 25 * oneMb,
    icon48: iconFilePowerpoint48,
    disposition: 'attachment'
  },
  {
    category: locale => loc_text[locale],
    extensions: ['txt'],
    contentType: 'text/plain',
    maxBytes: 25 * oneMb,
    icon48: iconFileText48,
    disposition: 'inline'
  },
  {
    category: () => 'Excel',
    extensions: ['xls'],
    contentType: 'application/vnd.ms-excel',
    maxBytes: 25 * oneMb,
    icon48: iconFileExcel48,
    disposition: 'attachment'
  },
  {
    category: () => 'Excel',
    extensions: ['xlsx'],
    contentType:
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    maxBytes: 25 * oneMb,
    icon48: iconFileExcel48,
    disposition: 'attachment'
  },
  {
    category: () => 'Zip',
    extensions: ['zip'],
    contentType: 'application/zip',
    maxBytes: 25 * oneMb,
    icon48: iconFileArchive48,
    disposition: 'attachment'
  },
  {
    category: locale => loc_email[locale],
    extensions: ['eml'],
    contentType: 'message/rfc822',
    maxBytes: 25 * oneMb,
    icon48: iconEmail48,
    disposition: 'attachment'
  },
  {
    category: locale => loc_email[locale],
    extensions: ['msg'],
    contentType: 'application/vnd.ms-outlook',
    maxBytes: 25 * oneMb,
    icon48: iconEmail48,
    disposition: 'attachment'
  }
];

export const unregisteredContentTypeMap: Record<string, string> = {
  'application/x-zip-compressed': 'application/zip',
  'application/zip-compressed': 'application/zip'
};

export const categorizedAttachmentTypes = Object.values(
  groupBy(attachmentTypes, x => x.category(defaultLocale))
).sort((a, b) =>
  a[0].category(defaultLocale).localeCompare(b[0].category(defaultLocale))
);

export const attachmentTypesByContentType = keyBy(
  attachmentTypes,
  'contentType'
);

export const attachmentTypesByExtension = attachmentTypes.reduce(
  (acc, type) => {
    for (const extension of type.extensions) {
      acc[extension] = type;
    }
    return acc;
  },
  {} as Record<string, AttachmentType>
);

export const attachmentExtensions = attachmentTypes
  .reduce((acc, type) => acc.concat(type.extensions), [] as string[])
  .sort();

export const attachmentHrefRegex = new RegExp(
  `^/attachments/(?:activities|activity-definitions)/([0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12})/([0-9a-f]{8}-(?:[0-9a-f]{4}-){3}[0-9a-f]{12})\.(?:${attachmentExtensions.join(
    '|'
  )})$`
);

export function filterAttachmentsByBody(
  owner_slug: AttachmentOwnerSlug,
  owner_id: string,
  attachments: { attachment_id: string; property_id?: number }[],
  body: string
) {
  return attachments.filter(
    a =>
      a.property_id ||
      body.indexOf(
        `(/attachments/${owner_slug}/${owner_id}/${a.attachment_id}.`
      ) !== -1
  );
}

export function getFileExtension(basename: string) {
  const index = basename.lastIndexOf('.');
  if (index === -1) {
    return '';
  }
  return basename.substr(index + 1).toLowerCase();
}

export function sanitizeFilename(filename: string) {
  return filenamify(filename, { replacement: '-', maxLength: 100 });
}

export function validateAttachmentFilename(
  locale: Locale,
  filename: string
):
  | {
      valid: true;
      filename: string;
      extension: string;
      originalFilename: string;
      type: AttachmentType;
    }
  | { valid: false; reason: string } {
  const sanitized = sanitizeFilename(filename);

  if (sanitized.length === 0) {
    return {
      valid: false,
      reason: loc_invalidFilenameFormat[locale](filename)
    };
  }

  if (sanitized[0] === '.') {
    return {
      valid: false,
      reason: loc_invalidFilenameFormat[locale](filename)
    };
  }

  const extension = getFileExtension(sanitized);
  if (extension.length === 0) {
    return {
      valid: false,
      reason: loc_missingFileExtensionFormat[locale](filename)
    };
  }

  const type = attachmentTypes.find(type =>
    type.extensions.includes(extension.toLowerCase())
  );

  if (!type) {
    return {
      valid: false,
      reason: loc_invalidFileExtensionFormat[locale](
        extension,
        filename,
        attachmentExtensions.join(', ')
      )
    };
  }

  return {
    valid: true,
    filename: sanitized,
    extension: extension.toLowerCase(),
    originalFilename: filename,
    type
  };
}

export function validateAttachmentFilesize(
  locale: Locale,
  filename: string,
  type: AttachmentType,
  sizeBytes: number
): { valid: true } | { valid: false; reason: string } {
  if (sizeBytes === 0) {
    return { valid: false, reason: loc_fileIsEmptyFormat[locale](filename) };
  }
  if (sizeBytes > type.maxBytes) {
    return {
      valid: false,
      reason: loc_fileTooLargeFormat[locale](
        filename,
        type.extensions[0],
        `${type.maxBytes / oneMb}MB`
      )
    };
  }
  return { valid: true };
}
