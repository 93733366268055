import { MaskInput } from 'maska';

export class MaskInputElement extends HTMLElement {
  mask?: MaskInput;

  connectedCallback() {
    const pattern = this.getAttribute('pattern');
    if (pattern && this.firstElementChild instanceof HTMLInputElement) {
      this.mask = new MaskInput(this.firstElementChild, {
        mask: pattern
      });
    }
  }

  disconnectedCallback() {
    this.mask?.destroy();
  }
}

declare global {
  interface Window {
    MaskInputElement: typeof MaskInputElement;
  }
  interface HTMLElementTagNameMap {
    'mask-input': MaskInputElement;
  }
}

if (!window.customElements.get('mask-input')) {
  window.MaskInputElement = MaskInputElement;
  window.customElements.define('mask-input', MaskInputElement);
}
