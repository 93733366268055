import { userDateFormat } from '../date';
import { calendarIcon, icon } from '../icons';
import { attr, booleanAttr, html } from '../template';
import { newElementId } from './element-id';

export function dateInputTemplate(
  attrs: {
    id?: string;
    name?: string;
    value?: Date | string | null;
    required?: boolean;
    disabled?: boolean;
    ariaLabel?: string;
    small?: boolean;
    autofocus?: boolean;
    form?: string;
    target?: string;
    action?: string;
    inputAttrs?: unknown[];
  } = {}
) {
  let value = '';
  if (attrs.value instanceof Date) {
    value = userDateFormat.format(attrs.value);
  } else if (attrs.value) {
    value = attrs.value;
  }
  const inputId = attrs.id ?? newElementId();
  const listId = newElementId();
  return html`
    <input
      type="date"
      form="undefined-form"
      ${attr(
        'value',
        attrs.value instanceof Date
          ? attrs.value.toISOString().substr(0, 10)
          : null
      )}
      tabindex="-1"
      aria-labelledby="${inputId}"
      data-target="date-input.pickerInput"
      data-action="change:date-input#handlePickerInputChange"
      style="
        width: 0;
        max-width: 0;
        max-height: 1px;
        margin-bottom: -4px;
        align-self: end;
        overflow: hidden;
        -webkit-appearance: none;
        padding: 0;
        border: none;
        clip: rect(0 0 0 0);
        clip-path: inset(50%);
      "
    />
    <input
      class="form-control ${attrs.small ? 'input-sm' : ''} mr-1"
      id="${inputId}"
      type="text"
      autocomplete="none"
      autocorrect="off"
      autocapitalize="off"
      spellcheck="false"
      list="${listId}"
      ${attr('name', attrs.name)}
      ${attr('value', value)}
      ${booleanAttr('required', attrs.required)}
      ${booleanAttr('disabled', attrs.disabled)}
      ${attr('form', attrs.form)}
      ${attr('aria-label', attrs.ariaLabel)}
      ${booleanAttr('autofocus', attrs.autofocus)}
      ${attrs.inputAttrs?.map((x, i) => [i == 0 ? null : ' ', x])}
      data-target="date-input.input ${attrs.target}"
      data-action="
        change:date-input#handleInputChange
        blur:date-input#handleInputChange
        input:date-input#updateSuggestions
        focus:date-input#updateSuggestions
        ${attrs.action}
      "
    />
    <datalist id="${listId}"></datalist>
    <button
      class="btn ${attrs.small ? 'btn-sm' : ''} d-print-none"
      type="button"
      aria-label="Show calendar"
      data-action="click:date-input#showPicker"
      ${booleanAttr('hidden', attrs.disabled)}
    >
      ${icon(calendarIcon)}
    </button>
  `;
}
