import {
  booleanAttr,
  dateOperatorNames,
  getReportFilterOperators,
  html,
  operatorNames,
  Property,
  render,
  ReportAggregateFunction,
  ReportEntityType,
  ReportFilter,
  ReportFilterOperator,
  reportFilterValueTemplate
} from '@cumu/shared';
import { target } from '@github/catalyst';
import { controller } from './controller';

@controller('report-filter')
class ReportFilterElement extends HTMLElement {
  @target declare typeInput: HTMLInputElement;
  @target declare propertySelect: HTMLDetailsElement;
  @target declare aggregateSelect: HTMLSelectElement | null | undefined;
  @target declare operatorSelect: HTMLSelectElement;
  @target declare valueContainer: HTMLElement;

  get organization_id() {
    return this.getAttribute('organization-id')!;
  }

  get entity_type() {
    return this.getAttribute('entity-type') as ReportEntityType;
  }

  get valueElementID() {
    return this.getAttribute('value-element-id') as string;
  }

  get valueFormGroup() {
    return this.valueContainer.closest<HTMLElement>('.form-group');
  }

  get type() {
    return this.typeInput.value as ReportFilter['type'];
  }

  get property() {
    const input =
      this.propertySelect.querySelector<HTMLInputElement>('summary input');
    const value = input?.value;
    if (!value) {
      return null;
    }
    return JSON.parse(input.getAttribute('data-property')!) as Property;
  }

  get operator() {
    return this.operatorSelect.value as ReportFilterOperator;
  }
  set operator(value) {
    this.operatorSelect.value = value;
  }

  get aggregate() {
    return this.aggregateSelect?.value as ReportAggregateFunction;
  }

  get operatorFormGroup() {
    return this.operatorSelect.closest<HTMLElement>('.form-group');
  }

  handleLeftSideChange() {
    const filter = {
      type: this.type,
      aggregate: this.aggregate
    } as any as ReportFilter;
    const property = this.property;
    const ops = getReportFilterOperators(filter, property);
    // coerce operator
    let current = this.operator;
    this.operator = current = ops.includes(current) ? current : ops[0];
    // show/hide operator select
    this.operatorFormGroup!.hidden = ops.length === 0;
    this.operatorSelect.innerHTML = render(
      ops.map(
        op =>
          html`<option value="${op}" ${booleanAttr('selected', op === current)}>
            ${property?.type === 'date'
              ? dateOperatorNames[op]
              : operatorNames[op]}
          </option>`
      )
    );
    this.setValueInput();
  }

  handleOperatorChange() {
    if (
      this.operator === 'blank' ||
      this.operator === 'notblank' ||
      this.valueFormGroup?.hidden
    ) {
      this.setValueInput();
    }
  }

  setValueInput() {
    const nameBase = this.getAttribute('name')!;
    const wildcards = this.getAttribute('wildcards') === 'true';
    const property = this.property;
    const property_id = property?.property_id;
    const operator = this.operator;
    const filter =
      this.type === 'standard'
        ? {
            type: 'standard',
            property_id,
            operator,
            value: null
          }
        : {
            type: 'subquery',
            aggregate: this.aggregate,
            operator,
            subquery_property_id: property_id,
            value: null
          };
    const valueTemplate = reportFilterValueTemplate(
      this.organization_id,
      this.entity_type,
      property,
      filter as ReportFilter,
      `${nameBase}/value`,
      this.valueElementID,
      false,
      wildcards
    );

    this.valueContainer.closest<HTMLElement>('.form-group')!.hidden =
      !valueTemplate ||
      this.operator === 'blank' ||
      this.operator === 'notblank';
    this.valueContainer.innerHTML = render(valueTemplate);
  }
}

declare global {
  interface Window {
    ReportFilterElement: typeof ReportFilterElement;
  }
  interface HTMLElementTagNameMap {
    'report-filter': ReportFilterElement;
  }
}
