import { install } from '@github/hotkey';

const attrName = 'data-hotkey';

function installSubtree(element = document.documentElement) {
  if (element.hasAttribute(attrName)) {
    install(element);
  }
  for (const el of element.querySelectorAll<HTMLElement>(`[${attrName}]`)) {
    install(el);
  }
}

const observer = new MutationObserver(mutations => {
  for (const mutation of mutations) {
    if (
      mutation.type === 'attributes' &&
      mutation.target instanceof HTMLElement
    ) {
      install(mutation.target);
    } else if (mutation.type === 'childList' && mutation.addedNodes.length) {
      for (const node of mutation.addedNodes) {
        if (node instanceof HTMLElement) {
          installSubtree(node);
        }
      }
    }
  }
});

observer.observe(document, {
  childList: true,
  subtree: true,
  attributeFilter: [attrName]
});

installSubtree();
