import { DependencyError, html, render } from '@cumu/shared';
import {
  loc_sessionExpired,
  loc_signIn,
  loc_yourSessionExpired
} from '@cumu/strings';
import { getLocale } from './locale';

declare const REQUIRES_AUTHENTICATION: 'standard' | 'mfa_token' | null;

const sessionExpiredModalId = 'session-expired-modal';

function displaySessionExpiredModal() {
  if (document.getElementById(sessionExpiredModalId)) {
    return;
  }
  const locale = getLocale();
  document.body.insertAdjacentHTML(
    'beforeend',
    render(
      html`<modal-content id="${sessionExpiredModalId}" force-submit>
        <div class="Box Box--width-md anim-fade-in fast">
          <div class="Box-header">
            <h3 class="Box-title">${loc_sessionExpired[locale]}</h3>
          </div>
          <div class="Box-body">
            <p>${loc_yourSessionExpired[locale]}</p>
            ${REQUIRES_AUTHENTICATION === 'standard'
              ? html`<a
                  class="btn btn-primary target-subtle"
                  href="/auth/sign-in?redirect_uri=${location.origin}/auth/signed-in"
                  target="_blank"
                  modal-dismiss
                >
                  ${loc_signIn[locale]}
                </a>`
              : html`<a
                  class="btn btn-primary"
                  href="${location.pathname}${location.search}"
                >
                  ${loc_signIn[locale]}
                </a>`}
          </div>
        </div>
      </modal-content>`
    )
  );
}

export function isSessionExpiredResponse({ status, url }: Response) {
  if (
    status === 401 &&
    new URL(url, location.origin).origin === location.origin
  ) {
    displaySessionExpiredModal();
    return true;
  }
  return false;
}

let checkSessionDebounce = 0;
function scheduleCheckSession() {
  clearTimeout(checkSessionDebounce);
  checkSessionDebounce = setTimeout(checkSession, 100);
}

let checkingSession = false;

async function checkSession() {
  clearTimeout(checkSessionDebounce);
  if (checkingSession) {
    return;
  }
  checkingSession = true;
  try {
    const response = await fetch(
      new URL('/auth/check-session', location.origin),
      { method: 'GET' }
    );
    if (isSessionExpiredResponse(response)) {
      return;
    }
    if (!response.ok) {
      throw new DependencyError(response);
    }
    document.getElementById(sessionExpiredModalId)?.remove();
  } finally {
    checkingSession = false;
  }
}

if (REQUIRES_AUTHENTICATION) {
  // https://developer.mozilla.org/en-US/docs/Web/API/Document/visibilitychange_event
  document.addEventListener('visibilitychange', () => {
    if (document.visibilityState === 'visible') {
      checkSession();
    }
  });

  // https://github.com/github/remote-input-element/blob/main/src/index.ts#L144
  addEventListener('remote-input-error', event => {
    if (
      event.target instanceof Element &&
      event.target.tagName === 'REMOTE-INPUT'
    ) {
      scheduleCheckSession();
    }
  });

  // https://github.com/github/include-fragment-element/blob/main/src/include-fragment-element.ts#L261
  // https://github.com/github/auto-complete-element/blob/main/src/autocomplete.ts#L232
  addEventListener(
    'error',
    event => {
      if (
        event.target instanceof Element &&
        ['INCLUDE-FRAGMENT', 'AUTO-COMPLETE'].includes(event.target.tagName)
      ) {
        scheduleCheckSession();
      }
    },
    { capture: true }
  );
}
