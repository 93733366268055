import {
  html,
  identifierRegex,
  parseAndValidateReportExpression,
  render
} from '@cumu/shared';
import { HTMLValueElement } from '../forms/utilities';
import { addCustomValidator } from '../forms/validation';
import { parseReportDefinition } from './report-expression-input';
import { dispatchChange } from './util';

export class NumberInputElement extends HTMLElement {
  get parametric() {
    return this.hasAttribute('parametric');
  }

  connectedCallback() {
    this.addEventListener('change', this);
    this.addEventListener('blur', this, { capture: true });
    if (this.hasAttribute('parametric')) {
      this.addEventListener('focus', this, { capture: true });
    }
  }

  disconnectedCallback() {
    this.removeEventListener('change', this);
    this.removeEventListener('blur', this, { capture: true });
    this.removeEventListener('focus', this, { capture: true });
  }

  handleEvent(event: Event) {
    const input =
      event.target instanceof HTMLInputElement &&
      event.target === this.firstElementChild &&
      event.target;
    if (!input) {
      return;
    }
    if (event.type === 'change' || event.type === 'blur') {
      const value = parseFloat(input.value);
      if (!isFinite(value)) {
        if (
          this.parametric &&
          identifierRegex.test(input.value.toUpperCase())
        ) {
          input.value = input.value.toUpperCase();
        }
        return;
      }
      // format the value to the specified number of digits
      const minimumFractionDigits = parseInt(
        this.getAttribute('minimum-fraction-digits') ?? '0'
      );
      const maximumFractionDigits = parseInt(
        this.getAttribute('maximum-fraction-digits') ??
          minimumFractionDigits.toString()
      );
      const formatted = new Intl.NumberFormat('en-us', {
        useGrouping: false,
        minimumFractionDigits,
        maximumFractionDigits,
        roundingMode: 'floor'
      }).format(value);
      if (input.value !== formatted) {
        input.value = formatted;
        dispatchChange(input);
      }
    } else if (event.type === 'focus') {
      if (!this.parametric || !input.list || !input.form) {
        return;
      }
      const parameters = parseReportDefinition(input.form).definition
        ?.parameters;
      if (!parameters?.length) {
        return;
      }
      input.list.innerHTML = render(
        parameters.map(x => html`<option value="${x.name}">${x.name}</option>`)
      );
    }
  }
}

declare global {
  interface Window {
    NumberInputElement: typeof NumberInputElement;
  }
  interface HTMLElementTagNameMap {
    'number-input': NumberInputElement;
  }
}

if (!window.customElements.get('number-input')) {
  window.NumberInputElement = NumberInputElement;
  window.customElements.define('number-input', NumberInputElement);
}

addCustomValidator((input: HTMLValueElement, label: string) => {
  if (
    input instanceof HTMLInputElement &&
    input.type === 'text' &&
    input.value.length &&
    input.parentElement instanceof NumberInputElement
  ) {
    const value = input.value;
    if (input.parentElement.parametric && identifierRegex.test(value)) {
      const {
        definition: { columns, parameters },
        properties
      } = parseReportDefinition(input.form!);
      const parameter = parameters.find(p => p.name === value);
      if (!parameter) {
        return `Invalid parameter reference. Unknown parameter "${value}".`;
      }
      if (['literal-number', 'select-number'].includes(parameter.type)) {
        return null;
      }
      if (parameter.type === 'expression') {
        const result = parseAndValidateReportExpression({
          name: parameter.name,
          expression: parameter.expression,
          getDefinitionInfo: () => ({ columns, parameters, properties })
        });
        if (result.valid && ['int', 'decimal'].includes(result.type)) {
          return null;
        }
      }
      return `Invalid parameter reference. Parameter "${value}" is not of type number.`;
    }
    const num = parseFloat(value);
    if (isFinite(num)) {
      return null;
    }
    return `${label} is invalid.`;
  }
  return null;
});
