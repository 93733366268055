import {
  copyIcon,
  DependencyError,
  html,
  icon,
  newElementId,
  render
} from '@cumu/shared';
import {
  loc_copy,
  loc_somethingWentWrong,
  loc_thinking,
  loc_you
} from '@cumu/strings';
import { target } from '@github/catalyst';
import {
  default_renderer,
  parser,
  parser_end,
  parser_write
} from 'streaming-markdown';
import { busySubmitButtons } from '../forms/utilities';
import { getLocale } from '../locale';
import { controller } from './controller';
import { scrollIntoViewIfNeeded } from './util';

const locale = getLocale();

@controller('assistant-chat-controller')
export default class AssistantChatControllerElement extends HTMLElement {
  @target declare form: HTMLFormElement;
  @target declare field: HTMLTextAreaElement | HTMLInputElement;
  @target declare horizontalRule: HTMLHRElement;
  initialized = false;

  get src() {
    return this.getAttribute('src')!;
  }

  async init() {
    if (this.initialized) {
      return;
    }
    this.initialized = true;
    const url = new URL(this.src, location.origin);
    url.searchParams.set('type', 'init');
    const response = await fetch(url.origin + url.pathname, {
      method: 'POST',
      body: url.searchParams
    });
    if (!response.ok) {
      throw new DependencyError(response);
    }
  }

  async submit(event: CustomEvent<FormData>) {
    event.preventDefault();

    const message = (
      (event.detail.get('suggested-message') ??
        event.detail.get('message')) as string
    ).trim();
    if (!message) {
      return;
    }
    const restart_conversation = event.detail.has('suggested-message');

    this.initialized = true;

    const assistantMarkdownBodyId = newElementId();
    this.horizontalRule.hidden = false;
    this.form.insertAdjacentHTML(
      'afterend',
      render(
        html`<div class="align-self-start mr-6">
            <span class="f6">Cumulus Assistant</span>
            <div
              class="border rounded color-bg-default p-3 pr-5 markdown-body f5 position-relative"
              id="${assistantMarkdownBodyId}"
            >
              <span>${loc_thinking[locale]}</span
              ><span class="AnimatedEllipsis"></span>
            </div>
          </div>
          <div class="align-self-end ml-6">
            <span class="f6 color-fg-accent">${loc_you[locale]}</span>
            <div
              class="border rounded color-bg-accent color-border-accent element-changed-animation p-3 markdown-body f5"
            >
              <p>${message}</p>
            </div>
          </div>`
      )
    );
    const markdownBody = document.getElementById(assistantMarkdownBodyId)!;
    const renderer = default_renderer(markdownBody);
    const markdownParser = parser(renderer);

    // pause before busying the submit buttons
    await new Promise<void>(resolve => setTimeout(resolve));

    let received = '';
    try {
      busySubmitButtons(this.form, true);
      this.field.disabled = true;
      this.field.value = message;

      const url = new URL(this.src, location.origin);
      url.searchParams.set('type', 'message');
      url.searchParams.set('role', 'user');
      url.searchParams.set('content', message);
      if (restart_conversation) {
        url.searchParams.set('restart_conversation', 'true');
      }

      if (message.trim() === '/system') {
        const response = await fetch(url);
        if (!response.ok) {
          throw new DependencyError(response);
        }
        received = await response.text();
        markdownBody.innerHTML = '';
        parser_write(markdownParser, received);
        return;
      }

      await new Promise<void>((resolve, reject) => {
        const source = new EventSource(url.href, {
          withCredentials: true
        });
        source.addEventListener('error', () => {
          source.close();
          reject(new Error('EventSource error'));
        });
        source.addEventListener('message', event => {
          if (event.data == '[DONE]') {
            source.close();
            resolve();
            return;
          }
          if (received.length === 0) {
            markdownBody.innerHTML = '';
          }
          const data = JSON.parse(event.data);
          received += data.response;
          parser_write(markdownParser, data.response);
        });
      });

      if (received.length === 0) {
        throw new Error('Empty response');
      }

      url.searchParams.set('role', 'assistant');
      url.searchParams.set('content', received);
      url.searchParams.delete('restart_conversation');
      const response = await fetch(url.origin + url.pathname, {
        method: 'POST',
        body: url.searchParams
      });
      if (!response.ok) {
        throw new DependencyError(response);
      }
    } catch (err) {
      if (received.length === 0) {
        markdownBody.innerHTML = '';
      }
      markdownBody.insertAdjacentHTML(
        'beforeend',
        render(
          html`<p class="text-italic">${loc_somethingWentWrong[locale]}</p>`
        )
      );
      throw err;
    } finally {
      this.field.disabled = false;
      this.field.focus();
      parser_end(markdownParser);
      scrollIntoViewIfNeeded(markdownBody.parentElement!);
      busySubmitButtons(this.form, false);
      if (received.length) {
        this.field.value = '';
        markdownBody.insertAdjacentHTML(
          'afterbegin',
          render(
            html`<clipboard-copy
              class="btn-octicon position-absolute top-0 right-0 p-2 m-0"
              value="${received}"
              aria-label="${loc_copy[locale]}"
              >${icon(copyIcon)}</clipboard-copy
            >`
          )
        );
      }
    }
  }
}

declare global {
  interface Window {
    AssistantChatControllerElement: typeof AssistantChatControllerElement;
  }
  interface HTMLElementTagNameMap {
    'assistant-chat-controller': AssistantChatControllerElement;
  }
}
