import { html, render } from '@cumu/shared';
import { loc_recaptchaLegal } from '@cumu/strings';
import { getLocale } from '../locale';
import { isSessionExpiredResponse } from '../session';
import { getRecaptchaToken, loadRecaptcha } from './recaptcha';
import {
  allFormData,
  busySubmitButtons,
  disableSubmitButtons,
  isEmptyIterable,
  isValueElement,
  normalizeInputValue,
  updateNewSubmitButtonText
} from './utilities';
import {
  clearValidationErrors,
  displayInternalServerError,
  displayServerValidationError,
  validateForm
} from './validation';

export const FORM_BEFORE_SUBMIT_EVENT_TYPE = 'form-before-submit';
export const FORM_AFTER_SUBMIT_EVENT_TYPE = 'form-after-submit';
export const FORM_AFTER_SUBMIT_DATA_EVENT_TYPE = 'form-after-submit-data';
export const FORM_SERVER_VALIDATION_ERROR_EVENT_TYPE =
  'form-server-validation-error';

export class FormBehaviorElement extends HTMLElement {
  private submitting: Promise<void> | null = null;
  private initialData = new FormData();
  private toDispose: (() => void)[] = [];
  private isDirty = false;
  private commitTimeout = 0;
  private mutationTimeout = 0;
  private observer?: MutationObserver;
  private busyCount = 0;
  private busyPromise: Promise<void> | undefined = undefined;
  private resolveBusyPromise = () => {};

  constructor() {
    super();
  }

  public get isNew() {
    return this.hasAttribute('new');
  }

  public get canSubmit() {
    return this.isDirty || this.isNew;
  }

  private get unloadPromptRequired() {
    return this.isDirty && this.getAttribute('unload-prompt') !== 'false';
  }

  public setBusy(work: Promise<unknown>) {
    if (this.busyCount === 0) {
      this.busyPromise = new Promise(resolve => {
        this.resolveBusyPromise = resolve;
      });
    }
    this.busyCount++;
    work.finally(() => {
      this.busyCount--;
      if (this.busyCount === 0) {
        this.busyPromise = undefined;
        this.resolveBusyPromise();
      }
    });
  }

  public connectedCallback() {
    const form = this.parentElement;
    if (!(form instanceof HTMLFormElement)) {
      return;
    }

    form.setAttribute('novalidate', '');

    this.initialData = allFormData(form);

    this.subscribe(window, 'input', this);
    this.subscribe(window, 'change', this);
    this.subscribe(form, 'submit', this);
    this.subscribe(window, 'keydown', this);
    this.subscribe(form, 'details-menu-selected', this);
    this.observer = new MutationObserver(() => {
      clearTimeout(this.mutationTimeout);
      this.mutationTimeout = setTimeout(() => this.setDirty(), 300);
    });

    this.observer.observe(form, { subtree: true, childList: true });

    disableSubmitButtons(form, !this.isNew);

    if (this.hasAttribute('recaptcha')) {
      loadRecaptcha();
      form.insertAdjacentHTML(
        'beforeend',
        render(
          html`<p class="note m-0 lh-default">
            ${loc_recaptchaLegal[getLocale()]}
          </p>`
        )
      );
    }
  }

  public disconnectedCallback() {
    clearTimeout(this.commitTimeout);
    clearTimeout(this.mutationTimeout);
    for (const dispose of this.toDispose) {
      dispose();
    }
    this.observer?.disconnect();
    window.removeEventListener('beforeunload', this);
  }

  private subscribe(
    target: EventTarget,
    type: string,
    listener: EventListenerObject
  ) {
    const options: AddEventListenerOptions = {
      capture: type === 'details-menu-selected'
    };
    target.addEventListener(type, listener, options);
    this.toDispose.push(() =>
      target.removeEventListener(type, listener, options)
    );
  }

  private setDirty() {
    const form = this.parentElement as HTMLFormElement;
    const initial = new URLSearchParams(this.initialData as any).toString();
    const current = new URLSearchParams(allFormData(form) as any).toString();
    this.isDirty = current !== initial;
    disableSubmitButtons(form, !this.canSubmit);
    // https://web.dev/articles/bfcache
    window.removeEventListener('beforeunload', this);
    if (this.unloadPromptRequired) {
      window.addEventListener('beforeunload', this);
    }
  }

  public acceptChanges() {
    const form = this.parentElement as HTMLFormElement;
    this.initialData = allFormData(form);
    this.setDirty();
  }

  public handleEvent(event: Event) {
    switch (event.type) {
      case 'submit':
        this.handleSubmitEvent(event as SubmitEvent);
        break;
      case 'beforeunload':
        this.handleUnloadEvent(event);
        break;
      case 'input':
        this.scheduleCommit(event);
        break;
      case 'change':
        this.commit(event);
        break;
      case 'keydown':
        this.handleKeyDown(event as KeyboardEvent);
        break;
      case 'details-menu-selected':
        this.setDirty();
        break;
      default:
        throw new Error(`Unexpected event "${event.type}".`);
    }
  }

  private isFormValueElementEvent({ target }: Event) {
    return isValueElement(target) && target.form === this.parentElement;
  }

  private scheduleCommit(event: Event) {
    if (!this.isFormValueElementEvent(event)) {
      return;
    }
    clearValidationErrors(event.target);
    clearTimeout(this.commitTimeout);
    this.commitTimeout = setTimeout(this.commit, 300, event);
  }

  private commit = (event: Event) => {
    if (!this.isFormValueElementEvent(event)) {
      return;
    }
    clearValidationErrors(event.target);
    clearTimeout(this.commitTimeout);
    if (event.type === 'change') {
      normalizeInputValue(event.target);
    }
    this.setDirty();
  };

  private async handleUnloadEvent(event: BeforeUnloadEvent) {
    if (!this.unloadPromptRequired) {
      return;
    }
    event.preventDefault();
    event.returnValue = 'You have unsaved work.'; // browser overrides this text
  }

  private handleKeyDown(event: KeyboardEvent) {
    if (
      !(event.ctrlKey || event.metaKey) ||
      event.shiftKey ||
      event.altKey ||
      event.key !== 's' ||
      event.defaultPrevented ||
      this.getAttribute('submit-shortcut') === 'false' ||
      this.getAttribute('unload-prompt') === 'false'
    ) {
      return;
    }

    const closestBehavior =
      event.target instanceof Element &&
      event.target.closest('form')?.querySelector('form-behavior');
    if (closestBehavior && closestBehavior !== this) {
      return;
    }

    event.preventDefault();

    if (isValueElement(event.target)) {
      event.target.blur();
      event.target.focus();
    }

    this.setDirty();
    if (!this.canSubmit) {
      return;
    }

    const form = this.parentElement as HTMLFormElement;
    form.requestSubmit();
  }

  private async handleSubmitEvent(event: SubmitEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.submit(event.submitter);
  }

  public submit(submitter?: HTMLElement | null) {
    if (!this.submitting) {
      this.submitting = this.submitInternal(submitter);
    }
    return this.submitting;
  }

  private async submitInternal(submitter?: HTMLElement | null) {
    await this.busyPromise;
    const form = this.parentElement as HTMLFormElement;
    const recaptcha = this.hasAttribute('recaptcha');
    try {
      busySubmitButtons(form, true);
      const body = allFormData(form);
      if (submitter?.hasAttribute('name') && submitter.hasAttribute('value')) {
        body.set(
          submitter.getAttribute('name')!,
          submitter.getAttribute('value')!
        );
      }
      const [{ valid }, recaptchaToken] = await Promise.all([
        validateForm(form),
        recaptcha ? getRecaptchaToken() : Promise.resolve(null)
      ]);

      if (!valid) {
        return;
      }

      const beforeSubmitEvent = new CustomEvent<FormData>(
        FORM_BEFORE_SUBMIT_EVENT_TYPE,
        {
          detail: body,
          bubbles: true,
          cancelable: true
        }
      );
      if (!this.dispatchEvent(beforeSubmitEvent)) {
        return;
      }

      const afterSubmitEvent = new CustomEvent<FormData>(
        FORM_AFTER_SUBMIT_EVENT_TYPE,
        {
          detail: body,
          bubbles: true
        }
      );

      if (!form.hasAttribute('action')) {
        this.dispatchEvent(afterSubmitEvent);
        return;
      }

      if (this.hasAttribute('standard-submit')) {
        window.removeEventListener('beforeunload', this);
        form.submit(); // unable to make form.requestSubmit(submitter) work?
        this.dispatchEvent(afterSubmitEvent);
        return;
      }

      const method = submitter?.getAttribute('formmethod') ?? form.method;
      const action = submitter?.getAttribute('formaction') ?? form.action;

      if (method === 'get' && this.hasAttribute('navigation')) {
        const params = new URLSearchParams(body as any);
        this.navigate(`${form.action}?${params}`);
        return;
      }

      const url = new URL(action || location.href, location.origin);
      const init: RequestInit = { method, body };
      if (url.searchParams.has('_method')) {
        init.method = url.searchParams.get('_method')!;
        url.searchParams.delete('_method');
      }
      if (init.method === 'get') {
        delete init.body;
        for (const [key, value] of body) {
          if (typeof value !== 'string') {
            throw new Error(`Cannot use non-string value in GET request.`);
          }
          url.searchParams.set(key, value);
        }
      }
      if (init.method === 'delete' && isEmptyIterable(body.keys())) {
        delete init.body; // cloudflare throws 400 when DELETE is used with form data body.
      }
      const request = new Request(url.href, init);
      if (recaptchaToken) {
        request.headers.set('recaptcha-token', recaptchaToken);
      }
      if (this.hasAttribute('accept')) {
        request.headers.set('accept', this.getAttribute('accept')!);
      }
      const response = await fetch(request);
      busySubmitButtons(form, false);
      if (response.ok) {
        this.initialData = body;
        this.removeAttribute('new');
        form.action =
          response.headers.get('x-cumulus-form-action') ?? form.action;
        this.setDirty();
        updateNewSubmitButtonText(form);
        this.dispatchEvent(afterSubmitEvent);
        this.dispatchEvent(
          new CustomEvent<Response>(FORM_AFTER_SUBMIT_DATA_EVENT_TYPE, {
            detail: response,
            bubbles: true
          })
        );
        this.navigate(response.headers.get('location'));
      } else if (response.status === 400) {
        const body =
          response.headers.get('content-type') === 'application/json'
            ? await response.json()
            : null;
        displayServerValidationError(form, body);
        this.dispatchEvent(
          new CustomEvent(FORM_SERVER_VALIDATION_ERROR_EVENT_TYPE, {
            bubbles: true
          })
        );
      } else if (!isSessionExpiredResponse(response)) {
        displayInternalServerError(form);
      }
    } finally {
      this.submitting = null;
      busySubmitButtons(form, false);
    }
  }

  private navigate(href: string | null) {
    switch (this.getAttribute('navigation')) {
      case null:
        // do nothing.
        break;
      case 'follow':
        if (href) {
          location.href = href;
        }
        break;
      case 'replace':
        if (href) {
          location.replace(href);
        }
        break;
      case 'replace-state':
        if (href) {
          history.replaceState(undefined, '', href);
        }
        break;
      case 'modal':
        if (href) {
          const frag = document.createElement('include-fragment');
          frag.src = href;
          const modal = document.createElement('modal-content');
          modal.append(frag);
          document.body.append(modal);
        }
        break;
      case 'reload':
        location.reload();
        break;
      default:
        throw new Error(`Unexpected submit attribute value.`);
    }
  }
}

declare global {
  interface Window {
    FormBehaviorElement: typeof FormBehaviorElement;
  }
  interface HTMLElementTagNameMap {
    'form-behavior': FormBehaviorElement;
  }

  interface ElementEventMap {
    [FORM_BEFORE_SUBMIT_EVENT_TYPE]: CustomEvent<FormData>;
    [FORM_AFTER_SUBMIT_EVENT_TYPE]: CustomEvent<FormData>;
    [FORM_AFTER_SUBMIT_DATA_EVENT_TYPE]: CustomEvent<Response>;
  }

  interface WindowEventMap {
    [FORM_BEFORE_SUBMIT_EVENT_TYPE]: CustomEvent<FormData>;
    [FORM_AFTER_SUBMIT_EVENT_TYPE]: CustomEvent<FormData>;
    [FORM_AFTER_SUBMIT_DATA_EVENT_TYPE]: CustomEvent<Response>;
  }
}

if (!window.customElements.get('form-behavior')) {
  window.FormBehaviorElement = FormBehaviorElement;
  window.customElements.define('form-behavior', FormBehaviorElement);
}
