import { set } from 'jsonpointer';
import { isRelativeDate, maxDate, minDate } from './date';
import { identifierRegex } from './model/report';

const PARSED = Symbol('PARSED');

export function parseForm<T extends object>(
  data: FormData | URLSearchParams
): T {
  if ((data as any)[PARSED]) {
    return (data as any)[PARSED];
  }
  const obj = Object.create(null) as T;
  const accruedMasks = Object.create(null) as any;
  for (const [key, value] of data) {
    if (typeof value !== 'string') {
      throw new Error(`Unexpected value type: ${value}`);
    }
    let parsedValue: string | boolean | number | null = value
      // https://www.w3.org/MarkUp/html-spec/html-spec_8.html#SEC8.2.1
      // The form field names and values are escaped: space characters are replaced by `+', and then
      // reserved characters are escaped as per [URL]; that is, non-alphanumeric characters are
      // replaced by `%HH', a percent sign and two hexadecimal digits representing the ASCII code of
      // the character. Line breaks, as in multi-line text field values, are represented as CR LF
      // pairs, i.e. `%0D%0A'.
      .replaceAll('\r\n', '\n');
    let [pointer, type, ...args] = key.split(':');
    // temporary, remove after updates have been live for a while
    if (type === 'default') {
      type = '';
    }
    type ||= pointer.endsWith('_id') ? 'nullstring' : 'string';
    if (args.includes('parametric') && identifierRegex.test(value)) {
      parsedValue = value;
      set(obj, '/' + pointer, parsedValue);
      continue;
    }
    switch (type) {
      case 'string':
        parsedValue = value;
        break;
      case 'nullstring':
        parsedValue = value === '' ? null : value;
        break;
      case 'boolean':
        parsedValue = value === 'true';
        break;
      case 'number':
        parsedValue = value === '' ? null : parseFloat(value);
        break;
      case 'integer':
        parsedValue = value === '' ? null : parseInt(value);
        break;
      case 'mask-shift': {
        const current = accruedMasks[pointer] ?? 0;
        parsedValue = current + 2 ** parseInt(value);
        accruedMasks[pointer] = parsedValue;
        break;
      }
      case 'mask-sum': {
        const current = accruedMasks[pointer] ?? 0;
        parsedValue = current + parseInt(value);
        accruedMasks[pointer] = parsedValue;
        break;
      }
      case 'date': {
        if (value === '') {
          parsedValue = null;
          break;
        }
        if (args.includes('relative') && isRelativeDate(value)) {
          parsedValue = value;
          break;
        }
        const d = new Date(value);
        if (
          isFinite(d.getTime()) &&
          d.getTime() >= minDate &&
          d.getTime() <= maxDate
        ) {
          parsedValue = d.toISOString().substr(0, 10);
          break;
        }
        parsedValue = null;
        break;
      }
      case 'json':
        parsedValue = JSON.parse(value);
        break;
      default:
        throw new Error(`Unexpected type directive "${type}" in "${key}".`);
    }
    set(obj, '/' + pointer, parsedValue);
  }
  (data as any)[PARSED] = obj;
  return obj;
}
