import {
  AttachmentDisplayVersion,
  attachmentGroupTemplate,
  html,
  render
} from '@cumu/shared';
import {
  loc_confirmDeleteSignature,
  loc_deleteConfirmation,
  loc_multipleAttachments,
  loc_singularAttachment
} from '@cumu/strings';
import { target } from '@github/catalyst';
import FileAttachmentElement, {
  Attachment
} from '@github/file-attachment-element';
import { uploadAttachments } from '../attachments';
import { getLocale } from '../locale';
import { controller } from './controller';
import { dispatchChange } from './util';

const locale = getLocale();

@controller('attachment-question')
class AttachmentQuestionElement extends HTMLElement {
  @target declare fileInput: HTMLInputElement;
  @target declare valueInput: HTMLInputElement;
  @target declare fileAttachmentElement: FileAttachmentElement;
  @target declare content: HTMLElement;
  @target declare emptyContent: HTMLElement;

  get property_id() {
    return parseInt(this.getAttribute('property-id')!);
  }

  async attachmentAccepted(event: CustomEvent<{ attachments: Attachment[] }>) {
    const attachments = event.detail.attachments;
    const uploadUrl = new URL(
      this.fileAttachmentElement.getAttribute('upload-href')!,
      location.origin
    );
    let count = this.valueInput.value ? this.valueInput.valueAsNumber : 0;
    try {
      this.fileInput.disabled = true;
      this.fileInput.labels?.[0].classList.add('btn-busy');
      await uploadAttachments({
        locale: locale,
        attachments,
        uploadUrl,
        attachmentSaved: attachment => {
          count++;
          this.emptyContent.hidden = true;
          this.content.insertAdjacentHTML(
            'afterbegin',
            render(
              attachmentGroupTemplate({
                locale,
                readonly: false,
                display: 'card',
                inserted_property_id: this.property_id,
                versions: [
                  {
                    id: attachment.id!,
                    name: attachment.name!,
                    href: attachment.href!,
                    date_iso: new Date().toISOString(),
                    user_unsafe_html: 'you'
                  }
                ]
              })
            )
          );
        }
      });
    } finally {
      this.fileInput.disabled = false;
      this.fileInput.labels?.[0].classList.remove('btn-busy');
      this.fileInput.focus();
      this.valueInput.value = count.toString();
      dispatchChange(this.valueInput);
    }
  }

  valueInputFocused() {
    this.fileInput.focus();
  }

  decrementCount() {
    if (this.valueInput.value === '1') {
      this.valueInput.value = '';
      this.emptyContent.hidden = false;
    } else {
      this.valueInput.value = (parseInt(this.valueInput.value) - 1).toString();
    }
    dispatchChange(this.valueInput);
  }
}

interface AttachmentDeleteEventDetail {
  attachment_id: string;
  logged: boolean;
  display: 'link' | 'card' | 'signature';
}

type AttachmentDeleteEvent = CustomEvent<AttachmentDeleteEventDetail>;

@controller('attachment-group')
class AttachmentGroupElement extends HTMLElement {
  connectedCallback() {
    addEventListener('attachment-delete', this);
  }

  disconnectedCallback() {
    removeEventListener('attachment-delete', this);
  }

  get display() {
    return this.getAttribute('display') as 'link' | 'card' | 'signature';
  }

  versions() {
    return JSON.parse(
      this.getAttribute('versions')!
    ) as AttachmentDisplayVersion[];
  }

  deleteClick(event: Event) {
    const attachment_id =
      event.target instanceof Element &&
      event.target.closest('button')?.getAttribute('attachment-id');
    if (!attachment_id) {
      throw new Error('Delete attachment: expected attachment id.');
    }
    const name = this.versions().find(x => x.id === attachment_id)?.name;
    if (!name) {
      throw new Error(
        'Delete attachment: versions did not contain attachment id.'
      );
    }
    if (
      confirm(
        this.display === 'signature'
          ? loc_confirmDeleteSignature[locale]
          : loc_deleteConfirmation[locale](name)
      )
    ) {
      this.dispatchEvent(
        new CustomEvent<AttachmentDeleteEventDetail>('attachment-delete', {
          bubbles: true,
          detail: {
            attachment_id,
            logged: false,
            display: this.display
          }
        })
      );
    }
  }

  handleEvent(event: AttachmentDeleteEvent) {
    if (!event.detail.logged) {
      event.detail.logged = true;
      const controller = document.querySelector('activity-controller');
      if (!controller) {
        throw new Error('Expected activity-controller.');
      }
      controller.form.insertAdjacentHTML(
        'beforeend',
        render(
          html`<input
            type="hidden"
            name="delete_attachments/-:json"
            value=${JSON.stringify({
              attachment_id: event.detail.attachment_id
            })}
          />`
        )
      );

      if (event.detail.display !== 'signature') {
        const heading = controller.attachmentsSection.querySelector('h2');
        if (heading) {
          const count = +heading.getAttribute('attachment-count')! - 1;
          heading.setAttribute('attachment-count', count.toString());
          if (count === 0) {
            controller.attachmentsSection.innerHTML = '';
          }
          heading.textContent =
            count === 1
              ? loc_singularAttachment[locale]
              : loc_multipleAttachments[locale](count);
        }
      }
    }

    const versions = this.versions();
    const index = versions.findIndex(v => v.id === event.detail.attachment_id);
    if (index === -1) {
      return;
    }
    versions.splice(index, 1);

    (
      this.closest('attachment-question') ?? this.closest('signature-question')
    )?.decrementCount();

    // re-render the attachment group

    this.insertAdjacentHTML(
      'afterend',
      render(
        attachmentGroupTemplate({
          locale,
          readonly: false,
          display: this.display,
          versions
        })
      )
    );
    this.remove();
  }
}

declare global {
  interface Window {
    AttachmentQuestionElement: typeof AttachmentQuestionElement;
    AttachmentGroupElement: typeof AttachmentGroupElement;
  }

  interface HTMLElementTagNameMap {
    'attachment-question': AttachmentQuestionElement;
    'attachment-group': AttachmentGroupElement;
  }

  interface ElementEventMap {
    'attachment-delete': AttachmentDeleteEvent;
  }

  interface WindowEventMap {
    'attachment-delete': AttachmentDeleteEvent;
  }
}
