import {
  AddressCategory,
  addressCategoryToField,
  AddressFields,
  AddressSuggestion,
  autocompleteListBoxItemTemplate,
  DependencyError,
  html,
  LookupItem,
  render
} from '@cumu/shared';
import AutocompleteElement from '@github/auto-complete-element';
import { target } from '@github/catalyst';
import { isSessionExpiredResponse } from '../session';
import { controller } from './controller';
import { dispatchChange } from './util';

@controller('autocomplete-list-box')
class AutocompleteListBoxElement extends HTMLElement {
  @target declare list: HTMLUListElement;
  @target declare autocomplete: AutocompleteElement;
  @target declare valueInput: HTMLInputElement;
  @target declare autocompleteInput: HTMLInputElement;
  @target declare autocompleteInputItem: HTMLLIElement;

  connectedCallback() {
    document.body.addEventListener('focus', this, { capture: true });
    document.body.addEventListener('click', this);
  }

  disconnectedCallback() {
    document.body.removeEventListener('focus', this, { capture: true });
    document.body.removeEventListener('click', this);
  }

  getValue() {
    const current: LookupItem[] = Array.from(
      this.list.querySelectorAll('[data-list-box-value]')
    ).map(el => JSON.parse(el.getAttribute('data-list-box-value')!));
    if (current.length === 0) {
      return null;
    }
    return current;
  }

  synchronize() {
    if (
      this.hasAttribute('required') &&
      !this.list.querySelectorAll('[data-list-box-value]').length
    ) {
      this.autocompleteInput.required = true;
    } else {
      this.autocompleteInput.required = false;
    }
    this.valueInput.value = JSON.stringify(this.getValue());
    dispatchChange(this.valueInput);
  }

  handleInputKeyDown(event: KeyboardEvent) {
    if (event.key === 'Enter' && this.autocompleteInput.value.length) {
      event.preventDefault();
      return;
    }

    if (
      event.key === 'Backspace' &&
      this.autocompleteInput.value === '' &&
      this.autocompleteInputItem.previousElementSibling instanceof
        HTMLElement &&
      !event.metaKey &&
      !event.altKey &&
      !event.ctrlKey &&
      !event.shiftKey
    ) {
      this.autocompleteInputItem.previousElementSibling.click();
      this.list.dispatchEvent(new KeyboardEvent(event.type, event));
    }
  }

  async comboboxCommit(event: CustomEvent) {
    this.autocomplete.value = '';

    if (!(event.target instanceof Element)) {
      return;
    }

    let value = JSON.parse(
      event.target.getAttribute('data-autocomplete-data')!
    ) as LookupItem | AddressSuggestion;

    let item: LookupItem;

    if ('magicKey' in value) {
      const params = new URLSearchParams({
        q: value.text,
        'magic-key': value.magicKey,
        'for-storage': 'true'
      });

      this.autocompleteInputItem.insertAdjacentHTML(
        'beforebegin',
        render(
          html`<li
            class="autocomplete-list-box-item autocomplete-list-box-item-option"
          >
            <span class="spinner-border color-fg-muted"></span>
          </li>`
        )
      );
      const spinner = this.autocompleteInputItem.previousElementSibling!;
      const response = await fetch(`/api/addresses/candidates?${params}`);
      spinner.remove();
      if (isSessionExpiredResponse(response)) {
        return;
      }
      if (!response.ok) {
        throw new DependencyError(response);
      }
      const [candidate] = (await response.json()) as AddressFields[];
      const category = new URL(
        this.autocomplete.src,
        location.href
      ).searchParams.get('category') as AddressCategory;
      const field = addressCategoryToField[category];
      item = {
        id: candidate[field],
        title: candidate[field]
      };
    } else {
      item = value;
    }

    const current = this.getValue() || [];

    if (current.find(x => x.id === item.id)) {
      return;
    }

    this.autocompleteInputItem.insertAdjacentHTML(
      'beforebegin',
      render(autocompleteListBoxItemTemplate(item, false))
    );

    this.synchronize();
    this.focusAutocompleteInput();
  }

  listBoxItemDelete() {
    if (this.list.querySelectorAll('[data-list-box-value]').length === 0) {
      this.autocompleteInput.focus();
    }
    Promise.resolve().then(() => this.synchronize());
  }

  focusAutocompleteInput() {
    this.autocompleteInput.focus();
  }

  handleEvent(event: Event) {
    if (
      ['focus', 'click'].includes(event.type) &&
      !this.contains(event.target as Element)
    ) {
      this.autocompleteInput.value = '';
      this.autocomplete.open = false;
    }
  }
}

declare global {
  interface Window {
    AutocompleteListBoxElement: typeof AutocompleteListBoxElement;
  }
  interface HTMLElementTagNameMap {
    'autocomplete-list-box': AutocompleteListBoxElement;
  }
}
