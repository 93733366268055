import { Locale } from '@cumu/strings';
import { checkIcon, icon } from '../icons';
import {
  ActivityResponseValue,
  Property,
  getPropertyDisplayValue
} from '../model';
import { html } from '../template';

export function renderCalculatedFieldDependencyList(
  locale: Locale,
  responses: Record<string, null | ActivityResponseValue>,
  propertyMap: Record<number, Property>,
  dependencies: number[]
) {
  return dependencies.map(property_id => {
    const property = propertyMap[property_id];
    if (!property) {
      return html`<li class="color-fg-danger">
        Unknown property ${property_id}
      </li>`;
    }
    const label = property.label;
    const response = responses[`p${property_id}`];
    const answered = response !== undefined && response !== null;
    const displayValue = getPropertyDisplayValue({
      property,
      value: response,
      locale,
      showScore: true,
      activities: []
    });
    return html`
      <li class="${answered ? 'color-fg-success' : ''}">
        ${icon(checkIcon, answered ? '' : 'v-hidden')} ${label}:
        <em>${displayValue}</em>
      </li>
    `;
  });
}
