import { LookupItem } from '../model';
import { booleanAttr, html } from '../template';
import { newElementId } from './element-id';

export function autocompleteListBoxItemTemplate(
  item: LookupItem,
  disabled: boolean
) {
  return html`
    <li
      role="option"
      aria-selected="true"
      id="${newElementId()}"
      class="autocomplete-list-box-item autocomplete-list-box-item-option"
      data-list-box-value="${JSON.stringify(item)}"
    >
      ${item.title}
      <button
        class="btn-close"
        tabindex="-1"
        type="button"
        aria-label="Delete ${item.title}"
        title="Delete"
        data-list-box-delete
        ${booleanAttr('hidden', disabled)}
      ></button>
    </li>
  `;
}

export function autocompleteListBoxElementTemplate({
  id,
  label,
  name,
  value,
  required,
  autocompleteSrc,
  disabled
}: {
  id: string;
  label: string;
  name: string;
  value: LookupItem[] | null;
  required: boolean;
  autocompleteSrc: string;
  disabled: boolean;
}) {
  if (!name.endsWith(':json')) {
    throw new Error(
      `Unexpected <list-box> input name "${name}". Name should end with ":json" directive.`
    );
  }

  const autocompleteListId = newElementId();
  return html`
    <autocomplete-list-box
      class="d-block"
      ${booleanAttr('required', required)}
      data-action="
        combobox-commit:autocomplete-list-box#comboboxCommit
        list-box-item-delete:autocomplete-list-box#listBoxItemDelete
      "
    >
      <auto-complete
        class="d-block position-relative"
        src="${autocompleteSrc}"
        for="${autocompleteListId}"
        data-target="autocomplete-list-box.autocomplete"
        empty-fetch
      >
        <list-box
          class="d-block form-control input-sm use-focus-within p-0 cursor-text"
          data-action="click:autocomplete-list-box#focusAutocompleteInput"
          multiple
          ${booleanAttr('disabled', disabled)}
        >
          <ul
            aria-label="${label}"
            class="autocomplete-list-box-list"
            data-target="autocomplete-list-box.list"
          >
            ${(value ?? []).map(x =>
              autocompleteListBoxItemTemplate(x, disabled)
            )}
            <li
              class="autocomplete-list-box-item flex-1"
              data-target="autocomplete-list-box.autocompleteInputItem"
            >
              <input
                class="autocomplete-list-box-input"
                id="${id}"
                type="search"
                maxlength="200"
                ${booleanAttr(
                  'required',
                  required && (value ?? []).length === 0
                )}
                ${booleanAttr('disabled', disabled)}
                aria-label="Value"
                autocomplete="none"
                autocorrect="off"
                autocapitalize="off"
                spellcheck="false"
                data-target="autocomplete-list-box.autocompleteInput"
                data-action="keydown:autocomplete-list-box#handleInputKeyDown"
              />
            </li>
          </ul>
        </list-box>
        <ul id="${autocompleteListId}" class="autocomplete-results" hidden></ul>
      </auto-complete>
      <input
        type="hidden"
        name="${name}"
        value="${JSON.stringify(value)}"
        data-target="autocomplete-list-box.valueInput"
      />
    </autocomplete-list-box>
  `;
}
