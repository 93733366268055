import { target, targets } from '@github/catalyst';
import { controller } from './controller';

@controller('overflow-nav')
export class SideBarElement extends HTMLElement {
  @target declare more: HTMLDetailsElement;
  @target declare moreMenuList: HTMLElement;
  @target declare itemsContainer: HTMLElement;
  @targets declare items: HTMLElement[];
  #observer = new ResizeObserver(() => this.scheduleUpdate());
  #timeout = 0;

  connectedCallback() {
    this.#observer.observe(this);
    this.update();
  }

  disconnectedCallback() {
    this.#observer.disconnect();
  }

  scheduleUpdate() {
    clearTimeout(this.#timeout);
    this.#timeout = setTimeout(() => this.update(), 0);
  }

  update() {
    clearTimeout(this.#timeout);
    for (const item of this.items) {
      item.hidden = false;
    }
    this.more.hidden = true;
    this.moreMenuList.innerHTML = '';
    if (this.itemsContainer.scrollWidth <= this.itemsContainer.clientWidth) {
      return;
    }
    this.more.hidden = false;
    for (let i = this.items.length - 1; i >= 0; i--) {
      const item = this.items[i];
      const overflowItem = item.cloneNode(true) as HTMLElement;
      overflowItem.removeAttribute('data-targets');
      overflowItem.role = 'menuitem';
      overflowItem.className = 'SelectMenu-item';
      this.moreMenuList.prepend(overflowItem);
      item.hidden = true;
      if (this.itemsContainer.scrollWidth <= this.itemsContainer.clientWidth) {
        break;
      }
    }
  }
}
