export class ParamInputElement extends HTMLElement {
  connectedCallback() {
    this.addEventListener('input', setParam);
  }

  disconnectedCallback() {
    this.removeEventListener('input', setParam);
  }
}

function setParam({ target }: Event) {
  if (
    target instanceof HTMLInputElement ||
    target instanceof HTMLTextAreaElement
  ) {
    const url = new URL(location.href);
    if (target.value === '') {
      url.searchParams.delete(target.name);
    } else {
      url.searchParams.set(target.name, target.value);
    }
    if (url.href !== location.href) {
      history.replaceState(null, '', url.href);
    }
  }
}

declare global {
  interface Window {
    ParamInputElement: typeof ParamInputElement;
  }
  interface HTMLElementTagNameMap {
    'param-input': ParamInputElement;
  }
}

if (!window.customElements.get('param-input')) {
  window.ParamInputElement = ParamInputElement;
  window.customElements.define('param-input', ParamInputElement);
}
