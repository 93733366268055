import { isRelativeDate } from '../date';
import {
  Choice,
  getReportFilterOperators,
  getReportFilterValueType,
  identifierRegex,
  Property,
  propertyIdToAddressCategory,
  ReportEntityType,
  ReportFilter,
  wellKnownProperties
} from '../model';
import { booleanAttr, html } from '../template';
import { autocompleteListBoxElementTemplate } from './autocomplete-list-box';
import { dateInputTemplate } from './date-input';
import { newElementId } from './element-id';

function booleanFilterValueTemplate(
  value: boolean,
  choices: Choice[],
  name: string,
  controlId: string,
  disabled: boolean
) {
  choices = choices || [
    {
      choice_id: 0,
      exclusive: 0,
      label: 'No'
    },
    {
      choice_id: 1,
      exclusive: 0,
      label: 'Yes'
    }
  ];
  return html`
    <select
      id="${controlId}"
      class="form-select select-sm"
      name="${name}:boolean"
      aria-label="Value"
      ${booleanAttr('disabled', disabled)}
    >
      ${choices.map(
        c => html`
          <option
            value="${Boolean(c.choice_id)}"
            ${booleanAttr('selected', value === Boolean(c.choice_id))}
          >
            ${c.label}
          </option>
        `
      )}
    </select>
  `;
}

function dateFilterValueTemplate(
  value: Date | string | null,
  name: string,
  controlId: string,
  disabled: boolean
) {
  return html`
    <date-input class="d-flex position-relative" relative parametric>
      ${dateInputTemplate({
        name: `${name}:date:relative:parametric`,
        required: true,
        ariaLabel: 'Value',
        value,
        small: true,
        id: controlId,
        disabled
      })}
    </date-input>
  `;
}

function stringFilterValueTemplate(
  value: string,
  name: string,
  controlId: string,
  disabled: boolean
) {
  return html`<input
    id="${controlId}"
    type="text"
    name="${name}"
    class="form-control input-sm"
    aria-label="Value"
    value="${value}"
    maxlength="100"
    required
    autocomplete="none"
    autocorrect="off"
    autocapitalize="on"
    spellcheck="false"
    ${booleanAttr('disabled', disabled)}
  />`;
}

function numberFilterValueTemplate(
  value: string | number | null,
  type: 'int' | 'decimal' | 'currency',
  name: string,
  controlId: string,
  disabled: boolean
) {
  const listId = newElementId();
  return html`<number-input
    minimum-fraction-digits="${type === 'currency' ? 2 : 0}"
    maximum-fraction-digits="${type === 'int' ? 0 : 2}"
    parametric
    ><input
      id="${controlId}"
      type="text"
      name="${name}:integer:parametric"
      class="form-control input-sm"
      aria-label="Value"
      value="${value === null ? '' : value}"
      required
      ${booleanAttr('disabled', disabled)}
      list="${listId}" />
    <datalist id="${listId}"></datalist
  ></number-input>`;
}

function listBoxFilterValueTemplate(
  organization_id: string,
  entity_type: ReportEntityType,
  property: Property,
  filter: ReportFilter,
  name: string,
  controlId: string,
  disabled: boolean,
  wildcards: boolean
) {
  if (
    property.choice_type !== 'big-single' &&
    property.choice_type !== 'big-multiple' &&
    !(
      property.response && ['single', 'multiple'].includes(property.choice_type)
    ) &&
    property.property_id !== wellKnownProperties.person.gender &&
    property.property_id !== wellKnownProperties.person.gender_identity &&
    property.property_id !== wellKnownProperties.person.sexual_orientation &&
    property.property_id !== wellKnownProperties.person.race &&
    property.property_id !== wellKnownProperties.person.ethnicity &&
    property.property_id !== wellKnownProperties.person.marital_status &&
    property.property_id !== wellKnownProperties.person.primary_language &&
    property.property_id !== wellKnownProperties.activity.type &&
    property.property_id !== wellKnownProperties.activity.create_dow
  ) {
    throw new Error(
      `Property ${property.property_id} is not supported in list box.`
    );
  }
  let src: string;
  switch (property.property_id) {
    case wellKnownProperties.activity.primary_physical.city:
    case wellKnownProperties.activity.primary_physical.postal_code:
    case wellKnownProperties.activity.primary_physical.region_abbr:
    case wellKnownProperties.activity.primary_physical.subregion:
    case wellKnownProperties.activity.assignee_organization_primary_physical
      .city:
    case wellKnownProperties.activity.assignee_organization_primary_physical
      .postal_code:
    case wellKnownProperties.activity.assignee_organization_primary_physical
      .region_abbr:
    case wellKnownProperties.activity.assignee_organization_primary_physical
      .subregion:
    case wellKnownProperties.activity.create_organization_primary_physical.city:
    case wellKnownProperties.activity.create_organization_primary_physical
      .postal_code:
    case wellKnownProperties.activity.create_organization_primary_physical
      .region_abbr:
    case wellKnownProperties.activity.create_organization_primary_physical
      .subregion:
    case wellKnownProperties.person.primary_physical.city:
    case wellKnownProperties.person.primary_physical.postal_code:
    case wellKnownProperties.person.primary_physical.region_abbr:
    case wellKnownProperties.person.primary_physical.subregion:
    case wellKnownProperties.organization_project.primary_physical.city:
    case wellKnownProperties.organization_project.primary_physical.postal_code:
    case wellKnownProperties.organization_project.primary_physical.region_abbr:
    case wellKnownProperties.organization_project.primary_physical.subregion: {
      const params = new URLSearchParams({
        category: propertyIdToAddressCategory[property.property_id],
        wildcards: wildcards.toString()
      });
      src = `/api/addresses/suggestions?${params}`;
      break;
    }
    case wellKnownProperties.activity.sctid: {
      const params = new URLSearchParams({
        'semantic-tag': 'procedure',
        control: 'autocomplete'
      });
      src = `/api/snomed/concepts?${params}`;
      break;
    }
    default: {
      const params = new URLSearchParams({
        property_id: property.property_id.toString(),
        operator: filter.operator,
        wildcards: wildcards.toString(),
        entity_type
      });
      src = `/api/organizations/${organization_id}/reports/filter-suggestions?${params}`;
      break;
    }
  }
  return autocompleteListBoxElementTemplate({
    id: controlId,
    label: 'Value',
    name: `${name}:json`,
    value: filter.value as any,
    required: true,
    autocompleteSrc: src,
    disabled
  });
}

export function reportFilterValueTemplate(
  organization_id: string,
  entity_type: ReportEntityType,
  property: Property | null,
  filter: ReportFilter,
  name: string,
  controlId: string,
  disabled: boolean,
  wildcards: boolean
) {
  if (!getReportFilterOperators(filter, property).includes(filter.operator)) {
    return null;
  }
  const info = getReportFilterValueType(filter, property, filter.operator);
  if (info === null) {
    return null;
  }
  if (info === 'blank') {
    return html`<input
      type="hidden"
      name="${name}"
      value="${filter.operator}"
    />`;
  }
  switch (info.type) {
    case 'date': {
      let value: Date | string | null;
      if (filter.value === null) {
        value = null;
      } else if (typeof filter.value === 'number') {
        value = new Date(filter.value);
      } else if (typeof filter.value === 'string') {
        if (isRelativeDate(filter.value)) {
          value = filter.value;
        } else if (identifierRegex.test(filter.value.toUpperCase())) {
          // toUpperCase handles dev data before uppercase was enforced.
          value = filter.value.toUpperCase();
        } else {
          value = new Date(filter.value);
        }
      } else {
        throw new Error(
          `Unexpected ${info.type} filter value ${JSON.stringify(filter.value)} in filter ${JSON.stringify(filter)} resolving to value info ${JSON.stringify(info)}.`
        );
      }
      return dateFilterValueTemplate(value, name, controlId, disabled);
    }
    case 'bit':
      if (filter.value !== null && typeof filter.value !== 'boolean') {
        throw new Error(
          `Unexpected ${info.type} filter value ${JSON.stringify(filter.value)} in filter ${JSON.stringify(filter)} resolving to value info ${JSON.stringify(info)}.`
        );
      }
      if (!property) {
        throw new Error(
          `Expected property for ${info.type} filter filter ${JSON.stringify(filter)} resolving to value info ${JSON.stringify(info)}.`
        );
      }
      return booleanFilterValueTemplate(
        filter.value === null ? true : filter.value,
        property.choices!,
        name,
        controlId,
        disabled
      );
    case 'varchar100':
    case 'varcharmax':
      if (info.choice_type === 'none') {
        if (filter.value !== null && typeof filter.value !== 'string') {
          throw new Error(
            `Unexpected ${info.type} filter value ${JSON.stringify(filter.value)} in filter ${JSON.stringify(filter)} resolving to value info ${JSON.stringify(info)}.`
          );
        }
        return stringFilterValueTemplate(
          filter.value || '',
          name,
          controlId,
          disabled
        );
      } else {
        if (!property) {
          throw new Error(
            `Expected property for ${info.type} filter filter ${JSON.stringify(filter)} resolving to value info ${JSON.stringify(info)}.`
          );
        }
        return listBoxFilterValueTemplate(
          organization_id,
          entity_type,
          property,
          filter,
          name,
          controlId,
          disabled,
          wildcards
        );
      }
    case 'activity':
    case 'uniqueidentifier':
      if (info.choice_type === 'none') {
        throw new Error(
          `Unexpected ${info.type} filter value ${JSON.stringify(filter.value)} in filter ${JSON.stringify(filter)} resolving to value info ${JSON.stringify(info)}.`
        );
      } else {
        if (!property) {
          throw new Error(
            `Expected property for ${info.type} filter filter ${JSON.stringify(filter)} resolving to value info ${JSON.stringify(info)}.`
          );
        }
        return listBoxFilterValueTemplate(
          organization_id,
          entity_type,
          property,
          filter,
          name,
          controlId,
          disabled,
          wildcards
        );
      }
    case 'int':
      if (info.choice_type === 'none') {
        if (
          filter.value !== null &&
          typeof filter.value !== 'number' &&
          !(
            typeof filter.value === 'string' &&
            identifierRegex.test(filter.value)
          )
        ) {
          throw new Error(
            `Unexpected ${info.type} filter value ${JSON.stringify(filter.value)} in filter ${JSON.stringify(filter)} resolving to value info ${JSON.stringify(info)}.`
          );
        }
        return numberFilterValueTemplate(
          filter.value,
          'int',
          name,
          controlId,
          disabled
        );
      } else {
        if (!property) {
          throw new Error(
            `Expected property for ${info.type} filter filter ${JSON.stringify(filter)} resolving to value info ${JSON.stringify(info)}.`
          );
        }
        return listBoxFilterValueTemplate(
          organization_id,
          entity_type,
          property,
          filter,
          name,
          controlId,
          disabled,
          wildcards
        );
      }
    case 'decimal':
    case 'currency':
      if (
        filter.value !== null &&
        typeof filter.value !== 'number' &&
        !(
          typeof filter.value === 'string' && identifierRegex.test(filter.value)
        )
      ) {
        throw new Error(
          `Unexpected ${info.type} filter value ${JSON.stringify(filter.value)} in filter ${JSON.stringify(filter)} resolving to value info ${JSON.stringify(info)}.`
        );
      }
      return numberFilterValueTemplate(
        filter.value,
        info.type,
        name,
        controlId,
        disabled
      );
    default:
      throw new Error(
        `Unexpected ${info.type} filter value ${JSON.stringify(filter.value)} in filter ${JSON.stringify(filter)} resolving to value info ${JSON.stringify(info)}.`
      );
  }
}
