import { CoreExpression, Identifier, MemberExpression } from 'jsep';
import { standardProperties } from '../expressions/activities';

/*
in-use:
Identifier
Literal
CallExpression
BinaryExpression
*/

const binops: Record<string, { (a: any, b: any): any }> = {
  '||': function (a, b) {
    return a || b;
  },
  '&&': function (a, b) {
    return a && b;
  },
  '|': function (a, b) {
    return a | b;
  },
  '^': function (a, b) {
    return a ^ b;
  },
  '&': function (a, b) {
    return a & b;
  },
  '==': function (a, b) {
    return a == b;
  }, // jshint ignore:line
  '!=': function (a, b) {
    return a != b;
  }, // jshint ignore:line
  '===': function (a, b) {
    return a === b;
  },
  '!==': function (a, b) {
    return a !== b;
  },
  '<': function (a, b) {
    return a < b;
  },
  '>': function (a, b) {
    return a > b;
  },
  '<=': function (a, b) {
    return a <= b;
  },
  '>=': function (a, b) {
    return a >= b;
  },
  '<<': function (a, b) {
    return a << b;
  },
  '>>': function (a, b) {
    return a >> b;
  },
  '>>>': function (a, b) {
    return a >>> b;
  },
  '+': function (a, b) {
    return a + b;
  },
  '-': function (a, b) {
    return a - b;
  },
  '*': function (a, b) {
    return a * b;
  },
  '/': function (a, b) {
    return a / b;
  },
  '%': function (a, b) {
    return a % b;
  }
};

const unops: Record<string, { (a: any): any }> = {
  '-': function (a) {
    return -a;
  },
  '+': function (a) {
    return +a;
  },
  '~': function (a) {
    return ~a;
  },
  '!': function (a) {
    return !a;
  }
};

function evaluateArray(list: any[], context: object) {
  return list.map(function (v) {
    return legacyEvaluate(v, context);
  });
}

function evaluateMember(node: MemberExpression, context: object) {
  const object = legacyEvaluate(node.object as CoreExpression, context);
  let key: string;
  if (node.computed) {
    key = legacyEvaluate(node.property as CoreExpression, context);
  } else {
    key = (node.property as Identifier).name;
  }
  if (/^(?:__proto__|prototype|constructor)$/.test(key)) {
    throw Error(`Access to member "${key}" disallowed.`);
  }
  return [object, object[key]];
}

export function legacyEvaluate(node: CoreExpression, context: object): any {
  switch (node.type) {
    case 'ArrayExpression':
      return evaluateArray(node.elements, context);

    case 'BinaryExpression': {
      const left = legacyEvaluate(node.left as CoreExpression, context);
      if (node.operator === '||') {
        return left || legacyEvaluate(node.right as CoreExpression, context);
      } else if (node.operator === '&&') {
        return left && legacyEvaluate(node.right as CoreExpression, context);
      }
      if ((typeof left === 'number' && isNaN(left)) || left === null) {
        return null;
      }
      const right = legacyEvaluate(node.right as CoreExpression, context);
      if ((typeof right === 'number' && isNaN(right)) || right === null) {
        return null;
      }
      return binops[node.operator](left, right);
    }
    case 'CallExpression':
      // let caller, fn, assign;
      // if (node.callee.type === 'MemberExpression') {
      //   assign = evaluateMember(node.callee as MemberExpression, context);
      //   caller = assign[0];
      //   fn = assign[1];
      // } else {
      const caller = undefined;
      const callee = node.callee as Identifier;
      if (callee.type !== 'Identifier') {
        return undefined;
      }
      // Coerce callee name to uppercase.
      callee.name = callee.name.toUpperCase();
      const fn = legacyEvaluate(callee, context);
      // }
      if (typeof fn !== 'function') {
        return undefined;
      }
      return fn.apply(caller, evaluateArray(node.arguments, context));

    case 'ConditionalExpression':
      return legacyEvaluate(node.test as CoreExpression, context)
        ? legacyEvaluate(node.consequent as CoreExpression, context)
        : legacyEvaluate(node.alternate as CoreExpression, context);

    case 'Identifier': {
      const name = node.name.toUpperCase();
      if (Object.hasOwn(standardProperties, name)) {
        return standardProperties[name].property_id;
      }
      if (Object.hasOwn(context, name)) {
        return (context as any)[name];
      }
      return null;
    }
    case 'Literal':
      return node.value;

    case 'MemberExpression':
      return evaluateMember(node, context)[1];

    case 'ThisExpression':
      return context;

    case 'UnaryExpression':
      return unops[node.operator](
        legacyEvaluate(node.argument as CoreExpression, context)
      );

    default:
      throw new Error(`Unknown node type "${(node as any).type}".`);
  }
}
