import { FORM_AFTER_SUBMIT_DATA_EVENT_TYPE } from '../forms/behavior-element';
import { dispatchChange, focusSomething } from './util';

class InsertAdjacentHtmlElement extends HTMLElement {
  constructor() {
    super();
  }

  connectedCallback() {
    this.addEventListener(FORM_AFTER_SUBMIT_DATA_EVENT_TYPE, this.afterSubmit);
  }

  disconnectedCallback() {
    this.removeEventListener(
      FORM_AFTER_SUBMIT_DATA_EVENT_TYPE,
      this.afterSubmit
    );
  }

  afterSubmit = async (event: CustomEvent<Response>) => {
    const { detail: response } = event;
    const element = this.ownerDocument.getElementById(
      this.getAttribute('element')!
    )!;
    const position = this.getAttribute('position') as
      | InsertPosition
      | 'replace';
    const setFocus = this.hasAttribute('set-focus');
    const text = await response.text();
    const template = document.createElement('template');
    template.innerHTML = text;
    const content = template.content.cloneNode(true) as DocumentFragment;
    const firstControl = content.querySelector('input,select,textarea');
    const firstElement = content.firstElementChild;
    switch (position) {
      case 'afterbegin':
        element.prepend(content);
        break;
      case 'afterend':
        element.after(content);
        break;
      case 'beforebegin':
        element.before(content);
        break;
      case 'beforeend':
        element.append(content);
        break;
      case 'replace':
        element.after(content);
        element.remove();
        break;
      default:
        throw new Error(`Unexpected position: "${position}"`);
    }
    if (firstControl) {
      dispatchChange(firstControl);
    }
    if (setFocus) {
      focusSomething(firstElement);
    }
  };
}

declare global {
  interface Window {
    InsertAdjacentHtmlElement: typeof InsertAdjacentHtmlElement;
  }
  interface HTMLElementTagNameMap {
    'insert-adjacent-html': InsertAdjacentHtmlElement;
  }
}

if (!window.customElements.get('insert-adjacent-html')) {
  window.InsertAdjacentHtmlElement = InsertAdjacentHtmlElement;
  window.customElements.define(
    'insert-adjacent-html',
    InsertAdjacentHtmlElement
  );
}
