import { AddressSuggestion } from '@cumu/shared';
import AutoCompleteElement from '@github/auto-complete-element';
import { target } from '@github/catalyst';
import { controller } from './controller';

@controller('geolocation-controller')
export class GeolocationControllerElement extends HTMLElement {
  @target declare button: HTMLButtonElement | undefined;
  @target declare autocomplete: AutoCompleteElement | undefined;

  connectedCallback() {
    if ('geolocation' in navigator) {
      this.button?.addEventListener('click', this);
    } else {
      this.button?.remove();
    }
    this.autocomplete?.addEventListener('combobox-commit', this);
  }

  disconnectedCallback() {
    this.button?.removeEventListener('click', this);
    this.autocomplete?.removeEventListener('combobox-commit', this);
  }

  handleEvent({ type, target }: Event) {
    switch (type) {
      case 'click':
        this.geolocate();
        return;
      case 'combobox-commit':
        if (!(target instanceof HTMLElement)) {
          return;
        }
        const value = JSON.parse(
          target.getAttribute('data-autocomplete-data')!
        ) as AddressSuggestion;
        const form = document.createElement('form');
        form.method = 'POST';
        form.action = '/api/location/magic-key';
        form.hidden = true;
        form.onformdata = ({ formData }) => {
          formData.set('q', value.text);
          formData.set('magic_key', value.magicKey);
          formData.set('redirect_uri', location.pathname + location.search);
        };
        this.append(form);
        form.submit();
        return;
      default:
        throw new Error(`Unhandled event type: ${type}`);
    }
  }

  async geolocate() {
    let position: GeolocationPosition;
    try {
      position = await new Promise((resolve, reject) =>
        navigator.geolocation.getCurrentPosition(resolve, reject, {
          enableHighAccuracy: true,
          maximumAge: 1000 * 60 * 5
        })
      );
    } catch (err) {
      let code = -1;
      if (err instanceof GeolocationPositionError) {
        code = err.code;
      }
      switch (code) {
        case GeolocationPositionError.PERMISSION_DENIED:
          // do nothing.
          break;
        case GeolocationPositionError.POSITION_UNAVAILABLE:
          alert('Position unavailable');
          break;
        case GeolocationPositionError.TIMEOUT:
          alert('Timed out while retrieving position.');
          break;
        default:
          alert('Error getting position.');
          break;
      }
      return;
    }
    const form = document.createElement('form');
    form.method = 'POST';
    form.action = '/api/location/reverse-geocode';
    form.hidden = true;
    form.onformdata = ({ formData }) => {
      formData.set('latitude:number', position.coords.latitude.toString());
      formData.set('longitude:number', position.coords.longitude.toString());
      formData.set('redirect_uri', location.pathname + location.search);
    };
    this.append(form);
    form.submit();
  }
}

declare global {
  interface Window {
    GeolocationButtonElement: typeof GeolocationControllerElement;
  }
  interface HTMLElementTagNameMap {
    'gelocation-controller': GeolocationControllerElement;
  }
}
