export type Locale =
  | 'en'
  | 'ar'
  | 'de'
  | 'es'
  | 'fa'
  | 'fil'
  | 'fr'
  | 'hi'
  | 'ht'
  | 'it'
  | 'ja'
  | 'ko'
  | 'mww'
  | 'ps'
  | 'pt'
  | 'pt-pt'
  | 'ru'
  | 'sm'
  | 'so'
  | 'th'
  | 'ur'
  | 'vi'
  | 'zh-hans'
  | 'zh-hant';

export interface LocaleInfo {
  locale: Locale;
  dir: 'ltr' | 'rtl';
  en_name: string;
  name: string;
}

export const locales: LocaleInfo[] = [
  {
    locale: 'ar',
    dir: 'rtl',
    en_name: 'Arabic',
    name: 'العربية'
  },
  {
    locale: 'de',
    dir: 'ltr',
    en_name: 'German',
    name: 'Deutsch'
  },
  {
    locale: 'en',
    dir: 'ltr',
    en_name: 'English',
    name: 'English'
  },
  {
    locale: 'es',
    dir: 'ltr',
    en_name: 'Spanish',
    name: 'Español'
  },
  {
    locale: 'fil',
    dir: 'ltr',
    en_name: 'Filipino',
    name: 'Filipino'
  },
  {
    locale: 'fr',
    dir: 'ltr',
    en_name: 'French',
    name: 'Français'
  },
  {
    locale: 'ht',
    dir: 'ltr',
    en_name: 'Haitian Creole',
    name: 'Kreyòl ayisyen'
  },
  {
    locale: 'hi',
    dir: 'ltr',
    en_name: 'Hindi',
    name: 'हिंदी'
  },
  {
    locale: 'mww',
    dir: 'ltr',
    en_name: 'Hmong Daw (Latin)',
    name: 'Hmoob'
  },
  {
    locale: 'it',
    dir: 'ltr',
    en_name: 'Italian',
    name: 'Italiano'
  },
  {
    locale: 'ja',
    dir: 'ltr',
    en_name: 'Japanese',
    name: '日本語'
  },
  {
    locale: 'ko',
    dir: 'ltr',
    en_name: 'Korean',
    name: '한국어'
  },
  {
    locale: 'fa',
    dir: 'rtl',
    en_name: 'Persian',
    name: 'فارسی'
  },
  {
    locale: 'ps',
    dir: 'rtl',
    en_name: 'Pashto',
    name: 'پښتو'
  },
  {
    locale: 'pt',
    dir: 'ltr',
    en_name: 'Portuguese (Brazil)',
    name: 'Português (Brasil)'
  },
  {
    locale: 'pt-pt',
    dir: 'ltr',
    en_name: 'Portuguese (Portugal)',
    name: 'Português (Portugal)'
  },
  {
    locale: 'ru',
    dir: 'ltr',
    en_name: 'Russian',
    name: 'Русский'
  },
  {
    locale: 'sm',
    dir: 'ltr',
    en_name: 'Samoan',
    name: 'Sāmoa'
  },
  {
    locale: 'so',
    dir: 'ltr',
    en_name: 'Somali',
    name: 'Soomaali'
  },
  {
    locale: 'th',
    dir: 'ltr',
    en_name: 'Thai',
    name: 'ไทย'
  },
  {
    locale: 'ur',
    dir: 'rtl',
    en_name: 'Urdu',
    name: 'اردو'
  },
  {
    locale: 'vi',
    dir: 'ltr',
    en_name: 'Vietnamese',
    name: 'TiếngViệt'
  },
  {
    locale: 'zh-hans',
    dir: 'ltr',
    en_name: 'Chinese (Simplified)',
    name: '中文 (简体)'
  },
  {
    locale: 'zh-hant',
    dir: 'ltr',
    en_name: 'Chinese (Traditional)',
    name: '中文 (繁體)'
  }
];

export const localeMap: Record<Locale, LocaleInfo> = locales.reduce(
  (p, c) => {
    p[c.locale] = c;
    return p;
  },
  {} as Record<Locale, LocaleInfo>
);

export const defaultLocale: Locale = 'en';
