let elementId = 0;

const prefix = 'document' in self ? 'c' : 's';

export function newElementId() {
  if (elementId > 99999) {
    elementId = 0;
  }
  const time = Date.now().toString().substr(7);
  return `${prefix}-${time}-${elementId++}`;
}
