import {
  DependencyError,
  attachmentGroupTemplate,
  render,
  signWithDefaultSignatureTemplate
} from '@cumu/shared';
import { target } from '@github/catalyst';
import { Attachment } from '@github/file-attachment-element';
import { uploadAttachments } from '../attachments';
import { getLocale } from '../locale';
import { isSessionExpiredResponse } from '../session';
import { controller } from './controller';
import { SignatureAcceptedEventDetail } from './signature-pad';
import { dispatchChange } from './util';

const locale = getLocale();

@controller('signature-question')
class SignatureQuestionElement extends HTMLElement {
  @target declare signaturePadButton: HTMLButtonElement;
  @target declare valueInput: HTMLInputElement;
  @target declare content: HTMLElement;
  @target declare emptyContent: HTMLElement;
  @target declare selectMenuList: HTMLElement;
  objectUrls: string[] = [];

  get property_id() {
    return parseInt(this.getAttribute('property-id')!);
  }

  get user_id() {
    return this.getAttribute('user-id')!;
  }

  get uploadUrl() {
    return new URL(this.getAttribute('upload-href')!, location.origin);
  }

  connectedCallback() {
    addEventListener('default-signature-updated', this);
  }

  disconnectedCallback() {
    this.objectUrls.forEach(URL.revokeObjectURL);
    removeEventListener('default-signature-updated', this);
  }

  handleEvent(event: CustomEvent<{ href: string | null }>) {
    if (event.type === 'default-signature-updated') {
      this.selectMenuList.innerHTML = render(
        signWithDefaultSignatureTemplate(locale, event.detail.href)
      );
    }
  }

  async signatureAccepted({
    detail: { set_default_signature, blob }
  }: CustomEvent<SignatureAcceptedEventDetail>) {
    try {
      this.signaturePadButton.classList.add('btn-busy');
      const file = new File([blob], 'signature.png', { type: 'image/png' });
      const attachment = new Attachment(file);
      attachment.name = file.name;
      await uploadAttachments({
        locale,
        attachments: [attachment],
        uploadUrl: this.uploadUrl,
        attachmentSaved: () => {}
      });

      const href = URL.createObjectURL(blob);
      this.objectUrls.unshift(href);
      this.appendAttachment(attachment.id!, attachment.name, href);
      if (set_default_signature) {
        const userAttachment = new Attachment(file);
        userAttachment.name = file.name;
        uploadAttachments({
          locale,
          attachments: [userAttachment],
          uploadUrl: new URL(
            `/attachments?owner_type=user&owner_id=${this.user_id}`,
            location.origin
          ),
          attachmentSaved: () => {}
        })
          .then(() =>
            fetch('/api/settings/default-signature', {
              method: 'POST',
              headers: {
                'content-type': 'application/json'
              },
              body: JSON.stringify({ attachment_id: userAttachment.id })
            })
          )
          .then(async response => {
            if (isSessionExpiredResponse(response)) {
              return;
            }
            if (!response.ok) {
              throw new DependencyError(response, await response.text());
            }
            this.dispatchEvent(
              new CustomEvent<{ href: string | null }>(
                'default-signature-updated',
                {
                  bubbles: true,
                  detail: {
                    href
                  }
                }
              )
            );
          });
      }
    } finally {
      this.signaturePadButton.classList.remove('btn-busy');
      this.signaturePadButton.focus();
    }
  }

  appendAttachment(id: string, name: string, href: string) {
    this.emptyContent.hidden = true;
    this.content.insertAdjacentHTML(
      'afterbegin',
      render(
        attachmentGroupTemplate({
          locale,
          readonly: false,
          display: 'signature',
          inserted_property_id: this.property_id,
          versions: [
            {
              id,
              name,
              date_iso: new Date().toISOString(),
              href,
              user_unsafe_html: 'you'
            }
          ]
        })
      )
    );
    let count = this.valueInput.value ? this.valueInput.valueAsNumber : 0;
    count++;
    this.valueInput.value = count.toString();
    dispatchChange(this.valueInput);
  }

  async signWithDefaultSignature() {
    try {
      this.signaturePadButton.classList.add('btn-busy');
      const { owner_id, owner_type } = Object.fromEntries(
        this.uploadUrl.searchParams
      );
      const response = await fetch(
        `${location.origin}/api/sign-with-default-signature`,
        {
          method: 'POST',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify({ owner_type, owner_id })
        }
      );
      if (isSessionExpiredResponse(response)) {
        return;
      }
      if (!response.ok) {
        throw new DependencyError(response, await response.text());
      }
      const { id, href, download_href, name } = await response.json();
      this.appendAttachment(id, name, download_href ?? href);
    } finally {
      this.signaturePadButton.classList.remove('btn-busy');
    }
  }

  async resetDefaultSignature() {
    try {
      this.signaturePadButton.classList.add('btn-busy');
      const response = await fetch(
        `${location.origin}/api/settings/default-signature`,
        { method: 'DELETE' }
      );
      if (isSessionExpiredResponse(response)) {
        return;
      }
      if (!response.ok) {
        throw new DependencyError(response, await response.text());
      }
      this.dispatchEvent(
        new CustomEvent<{ href: string | null }>('default-signature-updated', {
          bubbles: true,
          detail: {
            href: null
          }
        })
      );
    } finally {
      this.signaturePadButton.classList.remove('btn-busy');
    }
  }

  valueInputFocused() {
    this.signaturePadButton.focus();
  }

  decrementCount() {
    if (this.valueInput.value === '1') {
      this.valueInput.value = '';
      this.emptyContent.hidden = false;
    } else {
      this.valueInput.value = (parseInt(this.valueInput.value) - 1).toString();
    }
    dispatchChange(this.valueInput);
  }
}

declare global {
  interface Window {
    SignatureQuestionElement: typeof SignatureQuestionElement;
  }

  interface HTMLElementTagNameMap {
    'signature-question': SignatureQuestionElement;
  }
}
