import { Contact } from './contact';
import { EntityWithContactInfo } from './contact-info';
import { ProjectInfo } from './project';
import {
  OrganizationExclusiveResource,
  OrganizationSharedResource,
  ProjectResource,
  ResourceSelection
} from './resource';

export interface CreateOrganizationArgs {
  name: string;
  login: string;
  fein: string | null;
  classification: number | null;
  description: string;
  project_invitation_id?: string;
}

export interface UpdateOrganizationArgs extends EntityWithContactInfo {
  name: string;
  login: string;
  fein: string | null;
  classification: number | null;
  description: string;
  contacts?: Contact[];
  sites?: Contact[];
}

export interface UpdateUserOrganizationArgs {
  role_id: Role;
  is_active: boolean;
  projects: ResourceSelection[];
}

export interface OrganizationInfo {
  organization_id: string;
  name: string;
  login: string;
  avatar_id: string | null;
}

export interface Organization
  extends OrganizationInfo,
    OrganizationExclusiveResource,
    EntityWithContactInfo {
  description: string;
  contacts?: Contact[];
  classification: number | null;
  fein: string | null;
}

export const organizationClassifications = [
  { choice_id: 2, label: 'Non-profit' },
  { choice_id: 1, label: 'For-profit' },
  { choice_id: 0, label: 'Government' }
];

export type Role = 'Owner' | 'Admin' | 'Writer' | 'Reader';

export const roles: Role[] = ['Owner', 'Admin', 'Writer', 'Reader'];

export const roleIds: Record<Role, string> = {
  Owner: 'a0000000-0000-0000-0000-000000000001',
  Admin: 'a0000000-0000-0000-0000-000000000002',
  Writer: 'a0000000-0000-0000-0000-000000000003',
  Reader: 'a0000000-0000-0000-0000-000000000004'
};

export function hasRoleLevel(
  user: { role_id: string },
  expected_role: Exclude<Role, 'Reader'>
): boolean {
  switch (expected_role) {
    case 'Owner':
      return user.role_id === roleIds.Owner;
    case 'Admin':
      return user.role_id === roleIds.Owner || user.role_id === roleIds.Admin;
    case 'Writer':
      return (
        user.role_id === roleIds.Owner ||
        user.role_id === roleIds.Admin ||
        user.role_id === roleIds.Writer
      );
    default:
      throw new Error(
        `Invalid expected_role: ${JSON.stringify(expected_role)}`
      );
  }
}

export function sqlRoleLevel(
  alias: string,
  expected_role: Exclude<Role, 'Reader'>
) {
  switch (expected_role) {
    case 'Owner':
      return `[${alias}].role_id = convert(uniqueidentifier, '${roleIds.Owner}')`;
    case 'Admin':
      return `[${alias}].role_id in (convert(uniqueidentifier, '${roleIds.Owner}'), convert(uniqueidentifier, '${roleIds.Admin}'))`;
    case 'Writer':
      return `[${alias}].role_id <> convert(uniqueidentifier, '${roleIds.Reader}')`;
    default:
      throw new Error(
        `Invalid expected_role: ${JSON.stringify(expected_role)}`
      );
  }
}

export interface OrganizationListItem
  extends OrganizationInfo,
    EntityWithContactInfo {
  users: {
    user_id: string;
    login: string;
    avatar_id: string;
    is_ooo: boolean;
  }[];
}

export interface ProjectOrganizationListItem extends OrganizationListItem {
  subordinate_count: number;
  contract_count: number;
  is_active: boolean;
  is_admin: boolean;
  is_supervisor: boolean;
}

export interface UserOrganization {
  user_organization_id: string;
  user_id: string;
  organization_id: string;
  role_id: string;
  is_active: boolean;
  update_user_id: string;
}

export interface UserOrganizationProject extends OrganizationExclusiveResource {
  user_organization_project_id: string;
  user_id: string;
  project_id: string;
}

export interface BasicUserOrganization {
  organization_id: string;
  organization_login: string;
  organization_name: string;
  organization_avatar_id: string | null;
  user_id: string;
  user_login: string;
  user_name: string;
  user_avatar_id: string | null;
  is_active: boolean;
  is_ooo: boolean;
}

export type UsersByOrganization = {
  [user_id: string]: {
    [organization_id: string]: UserOrganizationListItem;
  };
};

export function keyByUserAndOrganization(
  users: UserOrganizationListItem[]
): UsersByOrganization {
  return users.reduce<UsersByOrganization>((p, c) => {
    p[c.user_id] ??= {};
    p[c.user_id][c.organization_id] = c;
    return p;
  }, {});
}

export function keyByUserAndOrganizationGeneric<
  T extends { user_id: string; organization_id: string }
>(
  users: T[]
): {
  [user_id: string]: {
    [organization_id: string]: T;
  };
} {
  return users.reduce<{
    [user_id: string]: {
      [organization_id: string]: T;
    };
  }>((p, c) => {
    p[c.user_id] ??= {};
    p[c.user_id][c.organization_id] = c;
    return p;
  }, {});
}

export function createUserOrganization(entity: {
  create_user_id: string;
  create_user_login: string;
  create_user_name: string;
  create_user_avatar_id: string | null;
  create_user_is_active: boolean;
  create_user_is_ooo: boolean;
  create_organization_id: string;
  create_organization_login: string;
  create_organization_name: string;
  create_organization_avatar_id: string | null;
}): BasicUserOrganization {
  return {
    user_id: entity.create_user_id,
    user_login: entity.create_user_login,
    user_name: entity.create_user_name,
    user_avatar_id: entity.create_user_avatar_id,
    organization_id: entity.create_organization_id,
    organization_login: entity.create_organization_login,
    organization_name: entity.create_organization_name,
    organization_avatar_id: entity.create_organization_avatar_id,
    is_active: entity.create_user_is_active,
    is_ooo: entity.create_user_is_ooo
  };
}

export function assigneeUserOrganization(entity: {
  assignee_user_id: string | null;
  assignee_user_login: string | null;
  assignee_user_name: string | null;
  assignee_user_avatar_id: string | null;
  assignee_user_is_active: boolean;
  assignee_user_is_ooo: boolean;
  assignee_organization_id: string | null;
  assignee_organization_login: string | null;
  assignee_organization_name: string | null;
  assignee_organization_avatar_id: string | null;
}): BasicUserOrganization {
  return {
    user_id: entity.assignee_user_id!,
    user_login: entity.assignee_user_login!,
    user_name: entity.assignee_user_name!,
    user_avatar_id: entity.assignee_user_avatar_id!,
    organization_id: entity.assignee_organization_id!,
    organization_login: entity.assignee_organization_login!,
    organization_name: entity.assignee_organization_name!,
    organization_avatar_id: entity.assignee_organization_avatar_id!,
    is_active: entity.assignee_user_is_active,
    is_ooo: entity.assignee_user_is_ooo
  };
}

export interface UserOrganizationListItem {
  user_organization_id: string;
  organization_id: string;
  organization_login: string;
  organization_name: string;
  organization_description: string;
  organization_avatar_id: string | null;
  user_id: string;
  user_login: string;
  user_name: string;
  user_avatar_id: string | null;
  email: string;
  role_id: string;
  role: Role;
  is_active: boolean;
  is_ooo: boolean;
  signature_attachment_id: string | null;
}

export interface UserOrganizationListItemWithProjects
  extends UserOrganizationListItem {
  projects: ProjectInfo[];
}

export interface UserOrganizationListItemWithLog
  extends UserOrganizationListItem {
  activity_end: string;
}

export interface UserOrganizationProjectListItem
  extends UserOrganizationListItem {
  project_id: string;
  project_name: string;
  project_login: string;
  project_features: number;
  organization_is_active: boolean;
  organization_is_admin: boolean;
}

export type AssigneeListItem =
  | AssigneeUserListItem
  | AssigneeOrganizationListItem;

export interface AssigneeUserListItem {
  type: 'user';
  organization_id: string;
  organization_name: string;
  organization_login: string;
  organization_avatar_id: string | null;
  organization_primary_physical_city: string | null;
  organization_primary_physical_region_abbr: string | null;
  organization_primary_physical_subregion: string | null;
  user_organization_id: string;
  user_id: string;
  user_login: string;
  user_name: string;
  user_avatar_id: string | null;
  role_id: string;
  user_role: Role;
  user_email: string;
  is_active: boolean;
  is_ooo: boolean;
}

export interface AssigneeOrganizationListItem {
  type: 'organization';
  organization_id: string;
  organization_name: string;
  organization_login: string;
  organization_avatar_id: string | null;
  organization_primary_physical_city: string | null;
  organization_primary_physical_region_abbr: string | null;
  organization_primary_physical_subregion: string | null;
  user_organization_id: null;
  user_id: null;
  user_login: null;
  user_name: null;
  user_avatar_id: null;
  role_id: null;
  user_role: null;
  user_email: null;
  is_active: null;
  is_ooo: null;
}

export interface OrganizationProject
  extends OrganizationSharedResource,
    ProjectResource {
  organization_project_id: string;
  is_admin: boolean;
  is_supervisor: boolean;
  is_active: boolean;
}

export type UpdateOrganizationProjectArgs =
  | {
      type: 'is_admin';
      value: boolean;
    }
  | {
      type: 'is_supervisor';
      value: boolean;
    }
  | {
      type: 'is_active';
      value: boolean;
    }
  | {
      type:
        | 'supervisors_add'
        | 'subordinates_add'
        | 'supervisors_delete'
        | 'subordinates_delete';
      organization_id: string;
    }
  | {
      type: 'contract_edit';
      contract_id: string;
      is_active: boolean;
    }
  | {
      type: 'contract_add';
      contract_id: string;
      organization_id: boolean;
    }
  | {
      type: 'contract_delete';
      contract_id: string;
    };
