// https://laurentpayot.github.io/minidenticons/
// https://github.com/laurentpayot/minidenticons/blob/main/minidenticons.js

import { AvatarEntity, AvatarSize } from './avatar';
import { attr, html, unsafeHTML } from './template';

// density of 4 for the lowest probability of collision
const SQUARE_DENSITY = 4;
// 18 different colors only for easy distinction
const COLORS_NB = 18;
const DEFAULT_SATURATION = 80;
const DEFAULT_LIGHTNESS = 50;

// 32 bit FNV-1a hash parameters
const FNV_PRIME = 16777619;
const OFFSET_BASIS = 2166136261;

// based on the FNV-1a hash algorithm, modified for *signed* 32 bit integers http://www.isthe.com/chongo/tech/comp/fnv/index.html
function simpleHash(str: string) {
  return (
    str
      .split('')
      // >>> 0 for 32 bit unsigned integer conversion https://2ality.com/2012/02/js-integers.html
      .reduce(
        (hash, char) => ((hash ^ char.charCodeAt(0)) >>> 0) * FNV_PRIME,
        OFFSET_BASIS
      )
  );
}

export function identicon(
  entity: AvatarEntity,
  size: AvatarSize,
  classes: string | null = null,
  id: string | null = null,
  fluid = false,
  saturation = DEFAULT_SATURATION,
  lightness = DEFAULT_LIGHTNESS
) {
  const hash = simpleHash(entity.id);
  // dividing hash by FNV_PRIME to get last XOR result for better color randomness (will be an integer except for empty string hash)
  const hue = ((hash / FNV_PRIME) % COLORS_NB) * (360 / COLORS_NB);
  const rects = [...Array(entity.id ? 25 : 0).keys()]
    // 2 + ((3 * 5 - 1) - modulo) to concentrate squares at the center
    .map(i =>
      hash % (16 - (i % 15)) < SQUARE_DENSITY
        ? `<rect x="${i > 14 ? 7 - ~~(i / 5) : ~~(i / 5)}" y="${
            i % 5
          }" width="1" height="1"/>`
        : ''
    )
    .join('');

  return html`<svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="-1.5 -1.5 8 8"
    style="fill: hsl(${hue} ${saturation}% ${lightness}%)"
    fill="hsl(${hue} ${saturation}% ${lightness}%)"
    ${attr('class', classes)}
    ${attr('id', id)}
    alt="${entity.login}"
    ${attr('width', fluid ? null : size.toFixed())}
    ${attr('height', fluid ? null : size.toFixed())}
  >
    ${unsafeHTML(rects)}
  </svg>`;
}
