import { avatarConfig, DependencyError, html } from '@cumu/shared';
import { insertModal } from '../insert-modal';
import { isSessionExpiredResponse } from '../session';
import { controller } from './controller';

@controller('edit-avatar')
export class EditAvatarElement extends HTMLElement {
  async fileSelected({ target }: Event) {
    if (
      !(target instanceof HTMLInputElement) ||
      !target.files ||
      target.files.length === 0
    ) {
      return;
    }
    const file = target.files[0];
    const url = URL.createObjectURL(file);
    try {
      target.disabled = true;
      const result = await insertModal(avatarCropModalTemplate(url));
      if (!result.submitted) {
        return;
      }
      target.labels?.item(1).classList.add('btn-busy');
      const { x, y, width, height } = result.data;
      if (width !== height) {
        throw new Error('Dimensions must be square.');
      }

      const sourceImage = new Image();
      await new Promise<void>(resolve => {
        const reader = new FileReader();
        reader.onloadend = () => {
          sourceImage.src = reader.result as string;
          resolve();
        };
        reader.readAsDataURL(file);
      });

      const size = avatarConfig.maxSize;
      const quality = 1;
      const type = avatarConfig.type;
      const canvas = document.createElement('canvas');
      canvas.width = size;
      canvas.height = size;
      const ctx = canvas.getContext('2d')!;
      ctx.fillStyle = '#fff';
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(sourceImage, x, y, width, height, 0, 0, size, size);
      const blob = await new Promise<Blob | null>(resolve =>
        canvas.toBlob(resolve, type, quality)
      );
      if (!blob) {
        throw new Error('canvas.toBlob resolved with null');
      }
      const body = new FormData();
      body.set('file', blob, file.name);
      const action = this.getAttribute('action');
      if (!action) {
        throw new Error('action attribute required.');
      }
      const actionUrl = new URL(action, location.origin);
      let response = await fetch(actionUrl, { method: 'PUT', body });
      if (response.status >= 500 && response.status < 600) {
        response.text().then(text => {
          throw new DependencyError(response, text);
        });
        await new Promise(resolve => setTimeout(resolve, 3000));
        actionUrl.searchParams.set('retry', '1');
        response = await fetch(actionUrl, { method: 'PUT', body });
      }
      if (isSessionExpiredResponse(response)) {
        return;
      }
      if (!response.ok) {
        alert('Upload failed. Please try again later.');
        throw new DependencyError(response, await response.text());
      }
      location.reload();
    } finally {
      target.disabled = false;
      target.value = '';
      URL.revokeObjectURL(url);
      target.labels?.item(1).classList.remove('btn-busy');
    }
  }
}

function avatarCropModalTemplate(url: string) {
  return html`<form
    class="Box d-flex flex-column anim-fade-in fast"
    style="max-height: calc(100vh - 48px - 48px)"
  >
    <form-behavior new></form-behavior>
    <div class="Box-header">
      <h3 class="Box-title">Crop your new profile picture</h3>
    </div>
    <div class="Box-body overflow-auto">
      <image-crop src="${url}" rounded style="max-width: 400px">
        <input type="hidden" data-image-crop-input="x" name="x" />
        <input type="hidden" data-image-crop-input="y" name="y" />
        <input type="hidden" data-image-crop-input="width" name="width" />
        <input type="hidden" data-image-crop-input="height" name="height" />
      </image-crop>
    </div>
    <div class="Box-footer">
      <button type="submit" class="btn btn-primary" autofocus>
        <span>Save profile picture</span>
      </button>
      <button type="button" class="btn" modal-dismiss>Cancel</button>
    </div>
  </form>`;
}

declare global {
  interface Window {
    EditAvatarElement: typeof EditAvatarElement;
  }
  interface HTMLElementTagNameMap {
    'edit-avatar': EditAvatarElement;
  }
}
