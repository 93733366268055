/// <reference path="declarations.d.ts" />

/**
 * Unexpected response from a dependency.
 */
export class DependencyError extends Error {
  public readonly status: number;

  /**
   * @param {Response} response
   * @param {string?} text
   */
  constructor({ url, status, statusText, headers }: Response, text?: string) {
    super(
      `${sanitizeUrl(
        url
      )} responded with ${status}: ${statusText}\nHeaders: ${JSON.stringify(
        Object.fromEntries(headers),
        null,
        2
      )}${text ? '\n' + text : ''}`
    );
    this.name = this.constructor.name;
    this.status = status;
    if (typeof Error.captureStackTrace === 'function') {
      Error.captureStackTrace(this, this.constructor);
    }
  }
}

function sanitizeUrl(url: string): string {
  try {
    const parsed = new URL(url);
    for (const key of [
      'token',
      'key',
      'access_token',
      'client_id',
      'client_secret'
    ]) {
      if (parsed.searchParams.has(key)) {
        parsed.searchParams.set(key, 'redacted');
      }
    }
    url = parsed.href;
  } catch {}
  if (url.length > 2500) {
    return (
      url.substr(0, 2500) +
      ` [URL truncated to 2500 characters. Original length: ${url.length}]`
    );
  }
  return url;
}
