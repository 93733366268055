import { html, parseForm, render, TemplateResult } from '@cumu/shared';
import {
  MODAL_DISMISS_EVENT_TYPE,
  ModalContentElement
} from './components/modal-content';
import { FORM_AFTER_SUBMIT_EVENT_TYPE } from './forms/behavior-element';

export function insertModal(
  content: TemplateResult
): Promise<
  { submitted: false } | { submitted: true; formData: FormData; data: any }
> {
  return new Promise(resolve => {
    document.body.insertAdjacentHTML(
      'beforeend',
      render(html`<modal-content>${content}</modal-content>`)
    );
    const modal = document.body.lastElementChild as ModalContentElement;
    const handleSubmit = (event: CustomEvent<FormData>) => {
      dispose();
      modal.remove();
      resolve({
        submitted: true,
        formData: event.detail,
        data: parseForm(event.detail)
      });
    };
    const handleClose = () => {
      dispose();
      resolve({ submitted: false });
    };
    const dispose = () => {
      modal.removeEventListener(FORM_AFTER_SUBMIT_EVENT_TYPE, handleSubmit);
      modal.removeEventListener(MODAL_DISMISS_EVENT_TYPE, handleClose);
    };
    modal.addEventListener(FORM_AFTER_SUBMIT_EVENT_TYPE, handleSubmit);
    modal.addEventListener(MODAL_DISMISS_EVENT_TYPE, handleClose);
  });
}
