import { newElementId } from '@cumu/shared';
import { controller } from './controller';

@controller('list-box')
export class ListBoxElement extends HTMLElement {
  declare list: Element;
  declare observer: MutationObserver;

  get disabled() {
    return this.hasAttribute('disabled');
  }

  connectedCallback() {
    this.list = this.firstElementChild!;
    this.list.setAttribute('role', 'listbox');
    this.list.setAttribute(
      'aria-multiselectable',
      this.hasAttribute('multiple').toString()
    );
    this.ensureActiveDescendent();
    this.list.addEventListener('keydown', this);
    this.list.addEventListener('click', this);
    this.observer = new MutationObserver(() => this.ensureActiveDescendent());
    this.observer.observe(this.list, { childList: true });
  }

  disconnectedCallback() {
    this.list.removeEventListener('keydown', this);
    this.list.removeEventListener('click', this);
    this.observer.disconnect();
  }

  handleEvent(event: Event) {
    switch (event.type) {
      case 'keydown':
        this.handleKeyDown(event as KeyboardEvent);
        break;
      case 'click':
        this.handleClick(event);
        break;
      default:
        throw new Error(`Unexpected event type: "${event.type}".`);
    }
  }

  handleKeyDown(event: KeyboardEvent) {
    if (event.target !== this.list) {
      return;
    }
    if (event.shiftKey || event.metaKey || event.altKey || event.ctrlKey) {
      return;
    }
    if (!this.activeDescendant) {
      return;
    }
    switch (event.key) {
      case 'Enter':
        if (this.clickActiveDescendant()) {
          event.preventDefault();
        }
        break;
      case 'ArrowDown':
      case 'ArrowRight':
        this.navigate(1);
        event.preventDefault();
        break;
      case 'ArrowUp':
      case 'ArrowLeft':
        this.navigate(-1);
        event.preventDefault();
        break;
      case 'Delete':
      case 'Backspace':
        if (this.disabled) {
          return;
        }
        if (this.deleteActiveDescendant()) {
          event.preventDefault();
        }
        break;
    }
  }

  handleClick(event: Event) {
    const del =
      event.target instanceof Element &&
      event.target.closest('[data-list-box-delete]') &&
      !this.disabled;
    const option =
      event.target instanceof Element &&
      event.target.closest<HTMLElement>('[role="option"]');
    if (!option) {
      return;
    }
    this.identifyOptions();
    this.list.setAttribute('aria-activedescendant', option.id);
    let result: boolean;
    if (del) {
      result = this.deleteActiveDescendant();
    } else {
      result = this.clickActiveDescendant();
    }
    if (result) {
      event.preventDefault();
    }
  }

  clickActiveDescendant() {
    const active = this.activeDescendant;
    if (!active) {
      return false;
    }
    const event = new CustomEvent('list-box-item-click', {
      bubbles: true,
      detail: active
    });
    this.dispatchEvent(event);
    // todo: set aria-selected
    return !event.defaultPrevented;
  }

  deleteActiveDescendant() {
    const active = this.activeDescendant;
    if (!active) {
      return false;
    }
    const event = new CustomEvent('list-box-item-delete', {
      bubbles: true,
      detail: active
    });
    this.dispatchEvent(event);
    if (event.defaultPrevented) {
      return false;
    }
    this.navigate(-1);
    active.remove();
    this.ensureActiveDescendent();
    return true;
  }

  get activeDescendant() {
    const id = this.list.getAttribute('aria-activedescendant');
    if (!id) {
      return null;
    }
    return this.list.querySelector('#' + id);
  }

  activate(option: Element) {
    Array.from(this.list.querySelectorAll('[role="option"]'))
      .filter(x => x !== option)
      .forEach(x => x.removeAttribute('data-list-box-active'));
    this.list.setAttribute('aria-activedescendant', option.id);
    option.setAttribute('data-list-box-active', 'true');
  }

  navigate(delta: -1 | 1) {
    this.identifyOptions();
    const options = Array.from(this.list.querySelectorAll('[role="option"]'));
    const active = this.activeDescendant!;
    let index = options.indexOf(active);
    index = Math.min(options.length - 1, Math.max(0, index + delta));
    this.activate(options[index]);
  }

  identifyOptions() {
    this.list
      .querySelectorAll('[role="option"]:not([id])')
      .forEach(x => (x.id = newElementId()));
  }

  ensureActiveDescendent() {
    this.identifyOptions();
    const option =
      this.activeDescendant ||
      this.list.querySelector('[role="option"][aria-selected="true"]') ||
      this.list.querySelector('[role="option"]');
    if (option) {
      this.activate(option);
      this.list.setAttribute('tabindex', '0');
    } else {
      this.list.removeAttribute('aria-activedescendant');
      this.list.removeAttribute('tabindex');
    }
  }
}

declare global {
  interface Window {
    ListBoxElement: typeof ListBoxElement;
  }
  interface HTMLElementTagNameMap {
    'list-box': ListBoxElement;
  }
}
