export function groupBy<T, K extends string | number>(
  array: T[],
  f: (item: T) => K
) {
  return array.reduce(
    (a, b) => ((a[f(b)] = a[f(b)] || []).push(b), a),
    {} as Record<K, T[]>
  );
}

export type KeysOfType<T, U> = {
  [P in keyof T]: T[P] extends U ? P : never;
}[keyof T];

export function keyBy<
  T extends Record<K, PropertyKey>,
  K extends KeysOfType<T, PropertyKey>
>(array: T[], key: K): Record<T[K], T> {
  return array.reduce(
    (accumulator, current) => {
      accumulator[current[key]] = current;
      return accumulator;
    },
    {} as Record<T[K], T>
  );
}

export function keyBy2<
  T extends Record<K1 | K2, PropertyKey>,
  K1 extends KeysOfType<T, PropertyKey>,
  K2 extends KeysOfType<T, PropertyKey>
>(array: T[], k1: K1, k2: K2): Record<T[K1], Record<T[K2], T>> {
  return array.reduce(
    (a, b) => {
      a[b[k1]] ??= {} as Record<T[K2], T>;
      a[b[k1]][b[k2]] ??= b;
      return a;
    },
    {} as Record<T[K1], Record<T[K2], T>>
  );
}

export function keyBy3<
  T extends Record<K1 | K2 | K3, PropertyKey>,
  K1 extends KeysOfType<T, PropertyKey>,
  K2 extends KeysOfType<T, PropertyKey>,
  K3 extends KeysOfType<T, PropertyKey>
>(
  array: T[],
  k1: K1,
  k2: K2,
  k3: K3
): Record<T[K1], Record<T[K2], Record<T[K3], T>>> {
  return array.reduce(
    (a, b) => {
      a[b[k1]] ??= {} as Record<T[K2], Record<T[K3], T>>;
      a[b[k1]][b[k2]] ??= {} as Record<T[K3], T>;
      a[b[k1]][b[k2]][b[k3]] ??= b;
      return a;
    },
    {} as Record<T[K1], Record<T[K2], Record<T[K3], T>>>
  );
}

export function ensurePropertyDefinedOnItems<T>(
  array: T[] | undefined,
  property: KeysOfType<T, Array<any>>
): T[] | undefined {
  if (!array) {
    return array;
  }
  for (const item of array) {
    item[property] ??= [] as any;
  }
  return array;
}
