import {
  loc_unverifiedAddress,
  loc_verifiedAddress,
  Locale
} from '@cumu/strings';
import { alertIcon, copyIcon, geoIcon, icon } from '../icons';
import {
  AddressFields,
  AddressPrecision,
  isPreciseAddress
} from '../model/address';
import { Contact, ContactType } from '../model/contact';
import { EmailType } from '../model/email';
import { PhoneType } from '../model/phone';
import { html, TemplateResult } from '../template';

export function personFullName(person: {
  last_name: string;
  first_name: string;
  middle_name: string;
}) {
  return `${person.last_name}, ${person.first_name} ${person.middle_name}`.trim();
}

function getAddressQuery(address: Partial<AddressFields>) {
  const {
    street_address,
    sub_address,
    city,
    region_abbr,
    postal_code,
    postal_code_ext
  } = address;
  let query = street_address || '';
  if (street_address && sub_address) {
    query += (query ? ', ' : '') + sub_address;
  }
  if (city) {
    query += (query ? ', ' : '') + city;
  }
  if (region_abbr) {
    query += (query ? ', ' : '') + region_abbr;
  }
  if (postal_code) {
    query +=
      (query ? ' ' : '') +
      postal_code +
      (postal_code_ext ? '-' + postal_code_ext : '');
  }
  return query;
}

function getMapUrl(
  address: Partial<AddressFields>,
  type: 'search' | 'directions'
) {
  const query = getAddressQuery(address);
  const url = new URL(
    type === 'search'
      ? 'https://www.google.com/maps/search/'
      : 'https://www.google.com/maps/dir/'
  );
  url.searchParams.set('api', '1');
  url.searchParams.set(type === 'search' ? 'query' : 'destination', query);
  return url;
}

export function addressTemplate(
  address: Partial<AddressFields>,
  link: boolean | 'primary' | 'secondary' = true
): (string | TemplateResult)[] {
  const {
    street_address,
    sub_address,
    city,
    region_abbr,
    postal_code,
    postal_code_ext,
    subregion
  } = address;
  let formatted = street_address || '';
  if (sub_address) {
    formatted += (formatted ? ', ' : '') + sub_address;
  }
  if (city) {
    formatted += (formatted ? ', ' : '') + city;
  }
  if (region_abbr) {
    formatted += (formatted ? ', ' : '') + region_abbr;
  }
  if (postal_code) {
    formatted +=
      (formatted ? ' ' : '') +
      postal_code +
      (postal_code_ext ? '-' + postal_code_ext : '');
  }
  const parts: (string | TemplateResult)[] = [];
  if (formatted) {
    if (link) {
      if (link === true) {
        link = 'primary';
      }
      parts.push(
        html`<a class="Link--${link}" href="${getMapUrl(address, 'search')}"
          >${formatted}</a
        >`
      );
    } else {
      parts.push(formatted);
    }
  }
  if (subregion) {
    if (parts.length) {
      parts.push(' - ');
    }
    parts.push(html`<span class="text-nowrap">County: ${subregion}</span>`);
  }
  return parts;
}

export function addressTextTemplate(address: Partial<AddressFields>): string {
  const {
    street_address,
    sub_address,
    city,
    region_abbr,
    postal_code,
    postal_code_ext,
    subregion
  } = address;
  let formatted = street_address || '';
  if (sub_address) {
    formatted += (formatted ? ', ' : '') + sub_address;
  }
  if (city) {
    formatted += (formatted ? ', ' : '') + city;
  }
  if (region_abbr) {
    formatted += (formatted ? ', ' : '') + region_abbr;
  }
  if (postal_code) {
    formatted +=
      (formatted ? ' ' : '') +
      postal_code +
      (postal_code_ext ? '-' + postal_code_ext : '');
  }
  if (subregion) {
    if (formatted) {
      formatted += ' - ';
    }
    formatted += `County: ${subregion}`;
  }
  return formatted;
}

export function phoneTemplate(
  phone: {
    type: PhoneType;
    number: string;
    extension: string;
  },
  link: boolean | 'primary' | 'secondary' = true
) {
  if (link) {
    if (link === true) {
      link = 'primary';
    }
    return html`${phone.type}:
      <a
        class="Link--${link}"
        href="tel:${phone.number.replaceAll(/[^\d]/g, '')}"
        >${phone.number}${phone.extension ? ' ext ' + phone.extension : ''}</a
      >`;
  }
  return `${phone.type}: ${phone.number}${
    phone.extension ? ' ext ' + phone.extension : ''
  }`;
}

export function emailTemplate(
  email: { type: EmailType; email: string },
  link: boolean | 'primary' | 'secondary' = true,
  copy = true
) {
  const copyButton = copy
    ? html`&nbsp;<clipboard-copy
          class="btn-octicon d-print-none ml-n1 mt-n1 mb-n1 mr-n1 f6"
          style="vertical-align: baseline;"
          aria-label="Copy email"
          value="${email.email}"
          >${icon(copyIcon)}</clipboard-copy
        >`
    : null;
  if (link) {
    if (link === true) {
      link = 'primary';
    }
    return html`${email.type}:
      <a class="Link--${link}" href="mailto:${email.email}">${email.email}</a
      >${copyButton}`;
  }
  return html`${email.type}: ${email.email}${copyButton}`;
}

export function contactTemplate(
  locale: Locale,
  item: Contact,
  contactTypes: { id: ContactType; label: Record<Locale, string> }[],
  link: boolean | 'primary' | 'secondary' = 'secondary'
) {
  return html`<span class="d-flex flex-column gap-1 lh-condensed">
    <span class="d-flex gap-1 flex-items-center flex-wrap">
      <span>${item.name}</span>
      ${contactTypes
        .filter(x => x.id & item.type)
        .map(
          ({ id, label }) =>
            html`<span
              class="Label${id === ContactType.PrimaryEmergencyContact
                ? ' d-item-has-primary-emergency-contact-checked'
                : ''}"
              >${label[locale]}</span
            >`
        )}
    </span>
    ${item.description
      ? html`<span class="f6">${item.description}</span>`
      : null}
    <span class="f6 color-fg-muted d-flex flex-wrap gap-2 d-none-empty"
      >${item.phones?.map(
        x => html`<span>${phoneTemplate(x, link)}</span>`
      )}${item.emails?.map(
        x => html`<span>${emailTemplate(x, link)}</span>`
      )}</span
    >
    ${item.addresses?.map(
      x =>
        html`<span class="f6 color-fg-muted">${addressTemplate(x, link)}</span>`
    )}
  </span>`;
}

export function addressPrecisionButtonContent(
  locale: Locale,
  precision: string | undefined
) {
  const precise = isPreciseAddress((precision as AddressPrecision) ?? '');
  const text = precise
    ? loc_verifiedAddress[locale]
    : loc_unverifiedAddress[locale];
  const iconContent = precise ? geoIcon : alertIcon;
  const classname = precise ? 'color-fg-success' : 'color-fg-attention';
  return html`<span
    class="d-inline-flex gap-1 flex-items-center text-bold ${classname}"
    >${text} ${icon(iconContent)}</span
  >`;
}
