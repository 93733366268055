import { EntityWithContactInfo } from './contact-info';
import { Phone } from './phone';

export const anonymousUserId = 'aaaaaaaa-aaaa-aaaa-aaaa-aaaaaaaaaaaa';
export const cumulusSystemUserId = '00000000-0000-0000-0000-000000000000';

export interface ClaimsIdentity {
  type: 'user';
  anonymous: boolean;
  id: string;
  auth0_id: string;
  login: string;
  roles: string[];
  email: string;
  email_verified: boolean;
  given_name: string;
  family_name: string;
  name: string;
  avatar_id: string | null;
  is_ooo: boolean;
  eula_accepted: string | null;
}

export const anonymousIdentity: ClaimsIdentity = {
  type: 'user',
  anonymous: true,
  id: anonymousUserId,
  auth0_id: 'auth0|000000000000000000000000',
  login: 'External',
  roles: [],
  email: 'external@cumulus.care',
  email_verified: true,
  given_name: 'External',
  family_name: 'External',
  name: 'External',
  avatar_id: '',
  is_ooo: false,
  eula_accepted: new Date().toISOString()
};

export interface User extends EntityWithContactInfo {
  user_id: string;
  auth0_id: string;
  login: string;
  name: string;
  email: string;
  task_emails: number;
  given_name: string;
  family_name: string;
  description: string;
  update_user_id: string;
  update_datetime?: string;
  avatar_id: string | null;
  is_ooo: boolean;
  ooo_message: string;
}

export interface CreateUserArgs {
  login: string;
  email: string;
  password: string;
  given_name: string;
  family_name: string;
}

export interface UpdateUserArgs {
  given_name: string;
  family_name: string;
  description: string;
  phones: Phone[];
  is_ooo: boolean;
  ooo_message: string;
}

export const taskEmailFlags = {
  status: 1,
  assignee: 2,
  comment: 4
};

export interface UserSession {
  user_session_id: string;
  user_id: string;
  exp: string;
  ip_address: string;
  is_support: boolean;
  browser_name: string;
  browser_version: string;
  os_name: string;
  os_version: string;
  os_version_name: string;
  platform_type: string;
  platform_vendor: string;
  latitude: number | null;
  longitude: number | null;
  city: string;
  country: string;
  region_code: string;
  region: string;
  postal_code: string;
  create_user_id: string;
  create_datetime: string;
  update_user_id: string;
  update_datetime: string;
}

export type UserSessionListItem = Exclude<
  UserSession,
  | 'user_session_id'
  | 'user_id'
  | 'create_user_id'
  | 'update_user_id'
  | 'update_datetime'
>;
