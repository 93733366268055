import { newElementId } from '@cumu/shared';
import IncludeFragmentElement from '@github/include-fragment-element';
import { FORM_AFTER_SUBMIT_EVENT_TYPE } from '../forms/behavior-element';
import { ModalControllerElement } from './modal-controller';

export const MODAL_DISMISS_EVENT_TYPE = 'modal-dismiss';

function focusable(el: any): el is HTMLElement {
  const result =
    (el instanceof HTMLButtonElement ||
      el instanceof HTMLInputElement ||
      el instanceof HTMLSelectElement ||
      el instanceof HTMLTextAreaElement ||
      el instanceof HTMLAnchorElement ||
      (el instanceof HTMLElement && el.tagName === 'SUMMARY')) &&
    el.isConnected &&
    el.tabIndex >= 0 &&
    !(el as any).disabled &&
    !el.hidden &&
    el.offsetWidth > 0 &&
    el.offsetHeight > 0 &&
    (el as any).type !== 'hidden';
  return result;
}

function autofocus(container: HTMLElement): void {
  let target = Array.from(container.querySelectorAll('[autofocus]')).filter(
    focusable
  )[0];
  if (
    !target &&
    container.firstElementChild instanceof IncludeFragmentElement
  ) {
    container.firstElementChild.addEventListener(
      'include-fragment-replaced',
      () => autofocus(container),
      { once: true }
    );
    return;
  }
  if (!target) {
    target = container;
    container.setAttribute('tabindex', '-1');
  }
  target.focus();
}

let hideCount = 0;

function hideBodyScrollbar() {
  hideCount++;
  if (hideCount > 1) {
    return;
  }
  // https://developer.mozilla.org/en-US/docs/Web/API/Window/innerWidth#usage_notes
  const documentWidth = document.documentElement.clientWidth;
  const bodyStyle = document.body.style;
  bodyStyle.paddingRight = `${Math.abs(window.innerWidth - documentWidth)}px`;
  bodyStyle.overflow = 'hidden';
}

function unhideBodyScrollbar() {
  hideCount--;
  if (hideCount > 0) {
    return;
  }
  const bodyStyle = document.body.style;
  bodyStyle.paddingRight = '';
  bodyStyle.overflow = '';
}

export class ModalContentElement extends HTMLElement {
  activeElement: Element | null | undefined;

  get controller() {
    const id = this.getAttribute('controller-id');
    return id ? (document.getElementById(id) as ModalControllerElement) : null;
  }

  connectedCallback() {
    this.setAttribute('role', 'dialog');
    this.setAttribute('aria-modal', 'true');
    this.setAriaLabelledBy();
    hideBodyScrollbar();
    this.activeElement = document.activeElement;
    setTimeout(autofocus, 0, this);
    this.addEventListener('keydown', this);
    this.addEventListener('click', this);
    this.addEventListener(FORM_AFTER_SUBMIT_EVENT_TYPE, this);
    addEventListener('focus', this, true);
    this.addEventListener('include-fragment-replaced', this, true);
  }

  disconnectedCallback() {
    unhideBodyScrollbar();
    if (
      this.activeElement instanceof HTMLElement &&
      this.activeElement.isConnected &&
      focusable(this.activeElement)
    ) {
      // this.ownerDocument.documentElement.style.scrollBehavior = 'auto';
      this.activeElement.focus();
      // this.ownerDocument.documentElement.style.scrollBehavior = '';
    }
    this.removeEventListener('keydown', this);
    this.removeEventListener('click', this);
    this.removeEventListener(FORM_AFTER_SUBMIT_EVENT_TYPE, this);
    removeEventListener('focus', this, true);
    this.removeEventListener('include-fragment-replaced', this, true);
  }

  async handleEvent(event: Event) {
    const { target } = event;
    switch (event.type) {
      case FORM_AFTER_SUBMIT_EVENT_TYPE:
        await new Promise(resolve => setTimeout(resolve));
        this.remove();
        break;
      case 'keydown':
        if (!(event instanceof KeyboardEvent)) {
          return;
        }
        if (this.hasAttribute('force-submit')) {
          return;
        }
        if (event.key === 'Escape' || event.key === 'Esc') {
          event.stopPropagation();
          if (this.canDismiss()) {
            this.remove();
          }
        }
        break;
      case 'click':
        if (
          !this.hasAttribute('force-submit') &&
          (target === this ||
            (target instanceof Element && target.closest('[modal-dismiss]'))) &&
          this.canDismiss()
        ) {
          if (
            target instanceof Element &&
            target.closest('button[type="submit"]')
          ) {
            await new Promise(resolve => setTimeout(resolve));
          }
          this.remove();
        }
        break;
      case 'focus':
        if (
          target instanceof Element &&
          (this.compareDocumentPosition(target) &
            Node.DOCUMENT_POSITION_CONTAINED_BY) ===
            0 &&
          target.closest('modal-content') === null
        ) {
          event.preventDefault();
          Array.from(
            this.querySelectorAll('a,button,input,select,summary,textarea')
          )
            .filter(focusable)[0]
            ?.focus();
        }
        break;
      case 'include-fragment-replaced':
        this.setAriaLabelledBy();
        break;
      default:
        throw new Error(`Unexpected event type "${event.type}".`);
    }
  }

  setAriaLabelledBy() {
    const heading = this.querySelector('h1,h2,h3,h4,h5,h6');
    if (heading) {
      if (!heading.id) {
        heading.id = newElementId();
      }
      this.setAttribute('aria-labelledby', heading.id);
    }
  }

  canDismiss() {
    return this.dispatchEvent(
      new CustomEvent(MODAL_DISMISS_EVENT_TYPE, {
        bubbles: true,
        cancelable: true
      })
    );
  }
}

declare global {
  interface Window {
    ModalContentElement: typeof ModalContentElement;
  }
  interface HTMLElementTagNameMap {
    'modal-content': ModalContentElement;
  }
  interface ElementEventMap {
    [MODAL_DISMISS_EVENT_TYPE]: CustomEvent;
  }
}

if (!window.customElements.get('modal-content')) {
  window.ModalContentElement = ModalContentElement;
  window.customElements.define('modal-content', ModalContentElement);
}
