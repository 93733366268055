var __classPrivateFieldSet = (this && this.__classPrivateFieldSet) || function (receiver, state, value, kind, f) {
    if (kind === "m") throw new TypeError("Private method is not writable");
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a setter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot write private member to an object whose class did not declare it");
    return (kind === "a" ? f.call(receiver, value) : f ? f.value = value : state.set(receiver, value)), value;
};
var __classPrivateFieldGet = (this && this.__classPrivateFieldGet) || function (receiver, state, kind, f) {
    if (kind === "a" && !f) throw new TypeError("Private accessor was defined without a getter");
    if (typeof state === "function" ? receiver !== state || !f : !state.has(receiver)) throw new TypeError("Cannot read private member from an object whose class did not declare it");
    return kind === "m" ? f : kind === "a" ? f.call(receiver) : f ? f.value : state.get(receiver);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var _TabContainerChangeEvent_panel, _TabContainerChangeEvent_tab, _TabContainerElement_onTabContainerChange, _TabContainerElement_onTabContainerChanged;
const HTMLElement = globalThis.HTMLElement || null;
function getTabs(el) {
    return Array.from(el.querySelectorAll('[role="tablist"] [role="tab"]')).filter(tab => tab instanceof HTMLElement && tab.closest(el.tagName) === el);
}
export class TabContainerChangeEvent extends Event {
    constructor(type, _a) {
        var { tab, panel } = _a, init = __rest(_a, ["tab", "panel"]);
        super(type, init);
        _TabContainerChangeEvent_panel.set(this, null);
        _TabContainerChangeEvent_tab.set(this, null);
        __classPrivateFieldSet(this, _TabContainerChangeEvent_tab, tab || null, "f");
        __classPrivateFieldSet(this, _TabContainerChangeEvent_panel, panel || null, "f");
    }
    get detail() {
        console.warn('TabContainerElement.detail is deprecated, please use .panel instead');
        return { relatedTarget: __classPrivateFieldGet(this, _TabContainerChangeEvent_panel, "f") };
    }
    get panel() {
        return __classPrivateFieldGet(this, _TabContainerChangeEvent_panel, "f");
    }
    get tab() {
        return __classPrivateFieldGet(this, _TabContainerChangeEvent_tab, "f");
    }
}
_TabContainerChangeEvent_panel = new WeakMap(), _TabContainerChangeEvent_tab = new WeakMap();
function getNavigationKeyCodes(vertical) {
    if (vertical) {
        return [
            ['ArrowDown', 'ArrowRight'],
            ['ArrowUp', 'ArrowLeft'],
        ];
    }
    else {
        return [['ArrowRight'], ['ArrowLeft']];
    }
}
export class TabContainerElement extends HTMLElement {
    constructor() {
        super(...arguments);
        _TabContainerElement_onTabContainerChange.set(this, null);
        _TabContainerElement_onTabContainerChanged.set(this, null);
    }
    static define(tag = 'tab-container', registry = customElements) {
        registry.define(tag, this);
        return this;
    }
    get onTabContainerChange() {
        return __classPrivateFieldGet(this, _TabContainerElement_onTabContainerChange, "f");
    }
    set onTabContainerChange(listener) {
        if (__classPrivateFieldGet(this, _TabContainerElement_onTabContainerChange, "f")) {
            this.removeEventListener('tab-container-change', __classPrivateFieldGet(this, _TabContainerElement_onTabContainerChange, "f"));
        }
        __classPrivateFieldSet(this, _TabContainerElement_onTabContainerChange, typeof listener === 'object' || typeof listener === 'function' ? listener : null, "f");
        if (typeof listener === 'function') {
            this.addEventListener('tab-container-change', listener);
        }
    }
    get onTabContainerChanged() {
        return __classPrivateFieldGet(this, _TabContainerElement_onTabContainerChanged, "f");
    }
    set onTabContainerChanged(listener) {
        if (__classPrivateFieldGet(this, _TabContainerElement_onTabContainerChanged, "f")) {
            this.removeEventListener('tab-container-changed', __classPrivateFieldGet(this, _TabContainerElement_onTabContainerChanged, "f"));
        }
        __classPrivateFieldSet(this, _TabContainerElement_onTabContainerChanged, typeof listener === 'object' || typeof listener === 'function' ? listener : null, "f");
        if (typeof listener === 'function') {
            this.addEventListener('tab-container-changed', listener);
        }
    }
    connectedCallback() {
        this.addEventListener('keydown', (event) => {
            var _a;
            const target = event.target;
            if (!(target instanceof HTMLElement))
                return;
            if (target.closest(this.tagName) !== this)
                return;
            if (target.getAttribute('role') !== 'tab' && !target.closest('[role="tablist"]'))
                return;
            const tabs = getTabs(this);
            const currentIndex = tabs.indexOf(tabs.find(tab => tab.matches('[aria-selected="true"]')));
            const [incrementKeys, decrementKeys] = getNavigationKeyCodes(((_a = target.closest('[role="tablist"]')) === null || _a === void 0 ? void 0 : _a.getAttribute('aria-orientation')) === 'vertical');
            if (incrementKeys.some(code => event.code === code)) {
                let index = currentIndex + 1;
                if (index >= tabs.length)
                    index = 0;
                this.selectTab(index);
            }
            else if (decrementKeys.some(code => event.code === code)) {
                let index = currentIndex - 1;
                if (index < 0)
                    index = tabs.length - 1;
                this.selectTab(index);
            }
            else if (event.code === 'Home') {
                this.selectTab(0);
                event.preventDefault();
            }
            else if (event.code === 'End') {
                this.selectTab(tabs.length - 1);
                event.preventDefault();
            }
        });
        this.addEventListener('click', (event) => {
            const tabs = getTabs(this);
            if (!(event.target instanceof Element))
                return;
            if (event.target.closest(this.tagName) !== this)
                return;
            const tab = event.target.closest('[role="tab"]');
            if (!(tab instanceof HTMLElement) || !tab.closest('[role="tablist"]')) {
                return;
            }
            const index = tabs.indexOf(tab);
            this.selectTab(index);
        });
        for (const tab of getTabs(this)) {
            if (!tab.hasAttribute('aria-selected')) {
                tab.setAttribute('aria-selected', 'false');
            }
            if (!tab.hasAttribute('tabindex')) {
                if (tab.getAttribute('aria-selected') === 'true') {
                    tab.setAttribute('tabindex', '0');
                }
                else {
                    tab.setAttribute('tabindex', '-1');
                }
            }
        }
    }
    selectTab(index) {
        const tabs = getTabs(this);
        const panels = Array.from(this.querySelectorAll('[role="tabpanel"]')).filter(panel => panel.closest(this.tagName) === this);
        if (index > tabs.length - 1) {
            throw new RangeError(`Index "${index}" out of bounds`);
        }
        const selectedTab = tabs[index];
        const selectedPanel = panels[index];
        const cancelled = !this.dispatchEvent(new TabContainerChangeEvent('tab-container-change', {
            bubbles: true,
            cancelable: true,
            tab: selectedTab,
            panel: selectedPanel,
        }));
        if (cancelled)
            return;
        for (const tab of tabs) {
            tab.setAttribute('aria-selected', 'false');
            tab.setAttribute('tabindex', '-1');
        }
        for (const panel of panels) {
            panel.hidden = true;
            if (!panel.hasAttribute('tabindex') && !panel.hasAttribute('data-tab-container-no-tabstop')) {
                panel.setAttribute('tabindex', '0');
            }
        }
        selectedTab.setAttribute('aria-selected', 'true');
        selectedTab.setAttribute('tabindex', '0');
        selectedTab.focus();
        selectedPanel.hidden = false;
        this.dispatchEvent(new TabContainerChangeEvent('tab-container-changed', {
            bubbles: true,
            tab: selectedTab,
            panel: selectedPanel,
        }));
    }
}
_TabContainerElement_onTabContainerChange = new WeakMap(), _TabContainerElement_onTabContainerChanged = new WeakMap();
