import {
  FORM_AFTER_SUBMIT_EVENT_TYPE,
  FORM_BEFORE_SUBMIT_EVENT_TYPE
} from '../forms/behavior-element';

export class SubmitAlertElement extends HTMLElement {
  connectedCallback() {
    this.setAttribute('role', 'alert');
    addEventListener(FORM_BEFORE_SUBMIT_EVENT_TYPE, this);
    addEventListener(FORM_AFTER_SUBMIT_EVENT_TYPE, this);
  }

  disconnectedCallback() {
    removeEventListener(FORM_BEFORE_SUBMIT_EVENT_TYPE, this);
    removeEventListener(FORM_AFTER_SUBMIT_EVENT_TYPE, this);
  }

  handleEvent(event: Event) {
    if (
      event.target instanceof Element &&
      event.target.closest(`[id="${this.getAttribute('for')}"]`)
    ) {
      this.hidden = event.type === FORM_BEFORE_SUBMIT_EVENT_TYPE;
    }
  }
}

declare global {
  interface Window {
    SubmitAlertElement: typeof SubmitAlertElement;
  }
  interface HTMLElementTagNameMap {
    'submit-alert': SubmitAlertElement;
  }
}

if (!window.customElements.get('submit-alert')) {
  window.SubmitAlertElement = SubmitAlertElement;
  window.customElements.define('submit-alert', SubmitAlertElement);
}
